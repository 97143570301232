import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { Card, Box, Link, Table } from 'atoms'
import { capitalizeFirstLetter } from 'lib/utils'
import PageHeader from 'molecules/PageHeader'
import MappingModal from './mappingModal'

export interface MappingType {
  id: number
  attribute: {
    id: number
    name: string
  }
  header: any
  isRequired: boolean
  level: string
  priority: number | string
}

type ChildProps = {
  direction: any
  loading: boolean
  mappings: MappingType[]
  loadMappings: (quiet?: boolean) => void
  totalCount: number
}

const MappingsTable: FC<ChildProps> = ({
  direction,
  loading,
  mappings,
  loadMappings,
  totalCount,
}) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showMappingModal, setShowMappingModal] = useState(false)
  const [mappingModalType, setMappingModalType] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if (!loading) {
      formatSetTableData({ data: mappings })
    }
  }, [loading, mappings])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Column Header',
        width: '25%',
      },
      {
        title: 'Attribute',
        width: '25%',
      },
      {
        title: 'Target',
        width: '15%',
      },
      {
        title: 'Priority',
        width: '15%',
      },
    ]
    if (!loading && direction === 'import') {
      columns.push({
        title: 'Required?',
        width: '15%',
      })
    }
    return columns
  }, [loading, direction])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((mapping, id) => {
      return {
        id,
        data: {
          'Column Header': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowMappingModal(true)
                  setMappingModalType('Edit')
                  setSelectedRow(mapping)
                }}
                label={mapping?.title}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Attribute': { value: mapping?.attribute?.name },
          'Target': { value: capitalizeFirstLetter(mapping?.target) },
          'Priority': { value: mapping?.priority },
          'Required?': { value: mapping?.is_required ? 'Yes' : 'No' },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <Box dataTestid='template-mappings-table' margin={{ top: 4, bottom: 7 }}>
      {
        <MappingModal
          type={mappingModalType}
          showModal={showMappingModal}
          setShowModal={setShowMappingModal}
          selectedMapping={selectedRow}
          loadMappings={() => {
            loadMappings()
            setSelectedRow(null)
          }}
        />
      }
      <Card
        showCollapse={false}
        headerContent={
          <Box width='100%'>
            <PageHeader
              h1Text={<span className='h5'>Template Mappings</span>}
              h2Text={
                <span className='body1-regular grey-600'>
                  Review the template columns.
                </span>
              }
              primaryButtons={[
                {
                  onClick: () => {
                    setShowMappingModal(true)
                    setMappingModalType('Add')
                  },
                  text: 'Add Mapping',
                  variant: 'primary',
                },
              ]}
            />
          </Box>
        }
        bodyContent={
          <>
            <Table
              columns={tableColumns}
              data={tableData}
              showPagination
              disableTableBorder
              totalRecords={totalCount}
              perPageRecords={10}
              loading={loading}
            />
          </>
        }
      ></Card>
    </Box>
  )
}

export default MappingsTable
