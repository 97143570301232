import React, { useState, useEffect } from 'react'
import { getTenantType } from 'api/helper'
import Card4Columns from 'molecules/cards/4Columns'
import CardWithTable from 'molecules/cards/cardWithTable'
import ActionsMenu from 'molecules/presenters/actionMenu'
import ConnectionAttributesModal from '../modals/ConnectionAttributes'

type AttributeDetailsProps = {
  details: any
  loading: boolean
  reload?: () => {}
}

const AttributeDetails: React.FC<AttributeDetailsProps> = ({
  details,
  loading,
  reload,
}) => {
  const [attributes, setAttributes] = useState<any[]>([])
  const [attributesTableData, setAttributesTableData] = useState<any[]>([])
  const [selectedRow, setSelectedRow] = useState({
    name: '',
    value: '',
    code: '',
    id: null,
  })

  // edit modal states
  const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
  const [showEditAttributeModal, setShowEditAttributeModal] = useState(false)
  const [showDeleteAttributeModal, setShowDeleteAttributeModal] =
    useState(false)

  const { isRetailer } = getTenantType()

  useEffect(() => {
    formatData(details)
  }, [loading, details])

  const formatData = ({ connection_credentials }) => {
    if (isRetailer) {
      let tableData = connection_credentials?.map((credential) => ({
        id: credential.id,
        data: {
          Attributes: { value: credential.name },
          Value: { value: credential.value },
          Actions: {
            onRender: () => (
              <ActionsMenu
                data={credential}
                setShowEditModal={(val) => setShowEditAttributeModal(val)}
                setShowDeleteModal={(val) => setShowDeleteAttributeModal(val)}
                setSelected={() => setSelectedRow(credential)}
              />
            ),
          },
        },
      }))
      setAttributesTableData(tableData)
    } else {
      let formattedAttributes = connection_credentials?.map((credential) => ({
        label: credential.name,
        value: credential.value,
      }))
      setAttributes(formattedAttributes)
    }
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Attributes',
    },
    {
      sortable: false,
      title: 'Value',
    },
    {
      sortable: false,
      title: 'Actions',
      width: 80,
    },
  ]

  const RetailerContent = () => {
    return (
      <CardWithTable
        header='Attributes'
        loading={loading}
        tableProps={{
          emptyTableText: 'You have no attributes yet',
          columns: tableColumns,
          data: attributesTableData,
        }}
        headerWithButtons={true}
        headerButtonText='Add Attribute'
        onEdit={() => setShowAddAttributeModal(true)}
      />
    )
  }

  const SupplierContent = () => {
    return (
      <Card4Columns
        header='Connection Attributes'
        loading={loading}
        data={attributes}
        customClassName='mb-4'
        noResultsText='You have no attributes yet'
      />
    )
  }

  return (
    <div className='mt-5'>
      {isRetailer ? <RetailerContent /> : <SupplierContent />}
      {/* add attribute */}
      {showAddAttributeModal && (
        <ConnectionAttributesModal
          onClose={() => setShowAddAttributeModal(false)}
          showModal={showAddAttributeModal}
          connectionId={details.id}
          reload={() => reload()}
          action='add'
        />
      )}
      {/* edit attribute */}
      {showEditAttributeModal && (
        <ConnectionAttributesModal
          onClose={() => setShowEditAttributeModal(false)}
          showModal={showEditAttributeModal}
          connectionId={details.id}
          reload={() => reload()}
          action='edit'
          initialValues={selectedRow}
        />
      )}
      {/* delete attribute */}
      {showDeleteAttributeModal && (
        <ConnectionAttributesModal
          onClose={() => setShowDeleteAttributeModal(false)}
          showModal={showDeleteAttributeModal}
          connectionId={details.id}
          reload={() => reload()}
          action='delete'
          initialValues={selectedRow}
        />
      )}
    </div>
  )
}

export default AttributeDetails
