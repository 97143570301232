import API, { RETAILER_API_URL, API_URL } from 'api'

export const getShopifyTemplate = async ({ platformAccountId, params }) => {
  return await API.get(
    RETAILER_API_URL.SHOPIFY_TEMPLATE({ platformAccountId }),
    { params }
  )
}

export const getShopifyLookupOrder = async ({ id, params }) => {
  return await API.get(API_URL.SHOPIFY_LOOKUP_ORDER({ id }), { params })
}

export const getShopifyWebhooks = async ({ platformAccountId, params }) => {
  return await API.get(API_URL.GET_SHOPIFY_WEBHOOKS({ platformAccountId }), { params })
}

export const getShopifyWebhookHistory = async ({ platformAccountId, params }) => {
  return await API.get(API_URL.GET_SHOPIFY_WEBHOOK_HISTORY({ platformAccountId }), { params })
}

export const getShopifyLocations = async ({ platformAccountId, params }) => {
  return await API.get(API_URL.GET_SHOPIFY_LOCATIONS({ platformAccountId }), { params })
}

export const registerShopifyWebhook = async ({ platformAccountId, body }) => {
  return await API.put(API_URL.REGISTER_SHOPIFY_WEBHOOKS({ platformAccountId }), body)
}

export const deleteShopifyWebhook = async ({ platformAccountId, webhookId }) => {
  return await API.delete(API_URL.GET_SHOPIFY_WEBHOOKS_BY_ID({ platformAccountId, webhookId }))
}

export const getShopifyOrders = async ({ platformAccountId, params }) => {
  return await API.get(API_URL.GET_SHOPIFY_ORDERS({ platformAccountId }), { params })
}

export const shopifyRetryOrder = async ({ platformAccountId, body }) => {
  return await API.put(API_URL.SHOPIFY_RETRY_ORDER({ platformAccountId }), body)
}

export const shopifyBulkUpdateTracking = async ({ platformAccountId, body }) => {
  return await API.post(API_URL.SHOPIFY_BULK_UPDATE_TRACKING({ platformAccountId }), body)
}

export const shopifySyncProducts = async ({ platformAccountId, body }) => {
  return await API.put(API_URL.SHOPIFY_SYNC_PRODUCTS({ platformAccountId }), body)
}

export const shopifySyncVariant = async ({ platformAccountId, body }) => {
  return await API.put(API_URL.SHOPIFY_SYNC_VARIANT({ platformAccountId }), body)
}
