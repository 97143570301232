import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getActivity } from 'api/orders'
import { formatDate } from 'lib/utils/date/formatDate'
import CardWithTable from 'molecules/cards/cardWithTable'

const OrderActivity: React.FC = () => {
  const { id } = useParams()
  const [tableData, setTableData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { data } = await getActivity({ id: id, params: {} })
      formatData({ data })
      setLoading(false)
    })()
  }, [])

  const tableColumns = [
    {
      sortable: false,
      title: 'Date',
    },
    {
      sortable: false,
      title: 'Activity',
    },
  ]

  const formatData = ({ data }) => {
    const formattedData = data.map(({ id, created_at, activity_event }) => {
      return {
        id,
        data: {
          Date: { value: formatDate({ date: created_at }) },
          Activity: { value: activity_event.name },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <CardWithTable
      header='Order Activity'
      loading={loading}
      tableProps={{
        emptyTableText: 'No order activity found',
        columns: tableColumns,
        data: tableData,
      }}
      customClassName='mb-4'
    />
  )
}

export default OrderActivity
