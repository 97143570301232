//TODO: make this dynamic similar to CurrencyLocale
const getOptions = (): Object => {
  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
    signDisplay: 'auto',
  }
}

const formatCurrency = (amount: number) => {
  return Intl.NumberFormat('en-US', getOptions()).format(amount)
}

export { formatCurrency }
