import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage as setProposalItemsCurrentPage } from 'store/proposalItemsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import ItemStatusPresenter from '../presenters/itemStatus'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import { Badge, GridCol, GridRow, Link, Modal } from 'atoms'
import ProductMediaCard from '../presenters/mediaCards'
import CartonDetails from '../presenters/cartonDetails'
import { getMargin, getRetailerCost } from 'modules/proposals/utils'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  proposal: any
  isRetailer: boolean
  isProductDetail?: boolean
  params: any
  handleOpenDrawer: (id: string) => void
}

const ProposalItemsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const setCurrentPage = setProposalItemsCurrentPage

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [mediaData, setMediaData] = useState([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      title: 'Item',
      defaultVisibility: true,
      width: '40%',
    },
    {
      title: 'Cost',
      minWidth: 100,
    },
    {
      title: 'Sugg. retail',
      minWidth: 100,
    },
    {
      title: 'Est. margin',
      minWidth: 100,
    },
    {
      title: 'Status',
      minWidth: 100,
    },
    {
      title: 'Product SKU',
      minWidth: 120,
    },
    {
      title: 'Merchant SKU',
      minWidth: 120,
    },
    {
      title: 'Images',
      minWidth: 100,
    },
    {
      title: '# Cartons',
      minWidth: 100,
    },
    {
      title: 'Measurements',
      minWidth: 180,
    },
    {
      title: 'Issues',
      minWidth: 100,
    },
  ]

  const renderItemColumn = (item) =>
    VariantItem({
      variant: item,
      onClick: () => props.handleOpenDrawer(item.id),
    })

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((item) => {
      return {
        id: item?.id,
        proposalVariantId: item?.proposal_variant?.id,
        data: {
          'Item': {
            onRender: () => renderItemColumn(item),
          },
          'Cost': {
            value: item?.proposal_variant?.pricing?.retailer_cost
              ? getRetailerCost(item)
              : 'Not Set',
          },
          'Sugg. retail': {
            value: item?.proposal_variant?.pricing?.retailer_price
              ? formatCurrency(
                  item?.proposal_variant?.pricing?.retailer_price?.values[0]
                    ?.value
                )
              : 'Not Set',
          },
          'Est. margin': { value: getMargin(item?.proposal_variant?.pricing) },
          'Status': {
            onRender: () => <ItemStatusPresenter item={item} />,
          },
          'Product SKU': {
            value: item?.attributes?.parent_identifier
              ? item?.attributes.parent_identifier?.values[0]?.value
              : '--',
          },
          'Merchant SKU': {
            value:
              item?.retailer_identifiers.length > 0
                ? item?.retailer_identifiers[0]?.identifier
                : 'New SKU',
          },
          'Images': {
            onRender: () =>
              item.media?.length > 0 ? (
                <Link
                  label={'View'}
                  variant='primary'
                  onClick={() => {
                    setShowMediaModal(true)
                    setMediaData(item?.media)
                  }}
                  mode='inline'
                />
              ) : (
                '--'
              ),
          },
          '# Cartons': {
            value: item?.cartons.length > 0 ? item.cartons.length : '--',
          },
          'Measurements':
            item?.cartons.length > 0
              ? {
                  onRender: () => (
                    <CartonDetails dimensions={item?.cartons?.[0]} />
                  ),
                }
              : { value: '--' },
          'Issues': !item?.proposal_variant?.issues.length
            ? {
                onRender: () => (
                  <Badge label='No open Issues' status={'primary'} />
                ),
              }
            : { value: item?.proposal_variant.issues.length },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <>
      {/* media modal */}
      <Modal
        headerText='Item Images'
        onClose={() => setShowMediaModal(false)}
        isVisible={showMediaModal}
        footerButtons={[
          {
            onClick: () => setShowMediaModal(false),
            text: 'Close',
            variant: 'tertiary',
          },
        ]}
        onBackdropClick={() => setShowMediaModal(false)}
      >
        <>
          <ProductMediaCard media={mediaData} onlyMedia />
        </>
      </Modal>
      <GridRow padding={false}>
        <GridCol>
          <Table
            showPagination
            columnVisibilityFilter
            columns={tableColumns}
            data={tableData}
            loading={props.loading}
            customPaginationProps={{
              handlePagination: (pageNumber: number) => {
                setPage(pageNumber)
              },
              activePageNumber: props.currentPage,
              perPage: 10,
              totalRecords: props.totalRows,
            }}
          />
        </GridCol>
      </GridRow>
    </>
  )
}

export default ProposalItemsTable
