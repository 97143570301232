import API, { API_URL } from 'api'

export const postEdiOnboarding = async ({ body }) => {
  return await API.post(API_URL.EDI_ONBOARDING(), body)
}

export const postEdiTestOrders = async ({ body }) => {
  return await API.post(API_URL.EDI_CREATE_TEST_ORDER(), body)
}

export const getEdiAccounts = async () => {
  return await API.get(API_URL.EDI_ACCOUNTS())
}
