import { FC, useState, useEffect } from 'react'
import { getOrderDetail } from 'api/orders'
import PostedMessages from './parts/postedMessages'
import MessageInput from './parts/messageInput'
import { MemoDataProps } from 'modules/orders/components/modal/orders/memoModal'

type ChildProps = {
  updateMemoCount?: (orderId: number) => void
  memoData: MemoDataProps
  emptyTableText?: string
  showAddMemo?: boolean
}

const Message: FC<ChildProps> = ({
  memoData,
  updateMemoCount,
  emptyTableText,
  showAddMemo = true,
}) => {
  const [memoArray, setMemoArray] = useState<any>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (memoData.memos_count === 0 || memoData?.memos?.length === 0) {
      setMemoArray([])
      return
    }
    if (memoData?.memos?.length > 0) {
      setMemoArray(memoData.memos)
      return
    }
    ;(async () => {
      setLoading(true)
      const {
        data: { memos },
      } = await getOrderDetail({
        id: memoData.orderId,
        params: { fields: 'memos' },
      })
      setMemoArray(memos)
      setLoading(false)
    })()
  }, [])

  return (
    <>
      {showAddMemo ? (
        <div className='mb-5'>
          <MessageInput
            orderId={memoData.orderId}
            setLoading={setLoading}
            loading={loading}
            setMemos={(data) => {
              const copiedMemos = [...memoArray]
              copiedMemos.unshift(data)
              if (updateMemoCount) {
                updateMemoCount(memoData.orderId)
              }
              setMemoArray(copiedMemos)
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <PostedMessages
        memoData={memoArray}
        loading={loading}
        orderId={memoData.orderId}
        setMemoData={setMemoArray}
        emptyTableText={emptyTableText}
      />
    </>
  )
}

export default Message
