import React, { useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Input, Button } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { shopifySyncProducts } from 'api/shopify'

type ChildProps = {
}

const SyncVariantsByVendorCard: React.FC<ChildProps> = ({
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [job, setJob] = useState(null)
  const [vendorName, setVendorName] = useState(null)

  const onSync = async () => {
    setSaving(true)
    const body = {
      use_celery: true,
      vendor: vendorName
    }
    try {
      const { data } = await shopifySyncProducts({ platformAccountId: id, body })
      setJob(data)
      showToast({
        label: 'Sync scheduled!',
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'Sync failed!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  return (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Sync Variants by Vendor</span>}
            h2Text={
              <span className='body2-regular'>
                Attempt to sync variants between Shopify and fabric using a vendor name.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            This utility searches your Shopify store for all items matching the supplied vendor name
            created within the last 30 days and attempts to sync them with variants in fabric.
            Please enter the vendor name EXACTLY as it appears in Shopify.
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <Input
                width='100%'
                label='Enter Shopify Vendor Name'
                required
                inputProps={{
                  name: 'vendorName',
                  value: vendorName,
                  placeholder: 'Shopify vendor name',
                  onChange: ({ target: { value } }) => {
                    setVendorName(value)
                  },
                }}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onSync()}
                text='Run Sync'
                variant='secondary'
                isDisabled={
                  saving || !vendorName
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default SyncVariantsByVendorCard
