import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSorting } from 'store/proposalDepartmentsSlice'
import Link from 'atoms/link/link'
import Box from 'atoms/box/box'
import { Badge } from 'atoms'
import { SORTING_MAPS } from '../constants'
import { useNavigate } from 'react-router-dom'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const DepartmentsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.data])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: 120,
        sortable: true,
      },
      {
        title: 'Proposal Department',
        width: '50%',
      },
      {
        title: 'Updated At',
        width: '25%',
      },
      {
        title: 'Status',
        width: 80,
      },
    ]
    return columns
  }, [])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((department, id) => {
      return {
        id,
        data: {
          'ID': { value: department.id },
          'Proposal Department': {
            onRender: () => (
              <Link
                onClick={() => navigate(`${department.id}`)}
                label={department?.name}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Updated At': { value: formatDate({ date: new Date() }) },
          'Status': {
            onRender: () => (
              <Badge
                label={department.is_active ? 'Active' : 'Inactive'}
                status={department.is_active ? 'success' : 'alert'}
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleSort = (key: string) => {
    const mappedKey = SORTING_MAPS.DEPARTMENT[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <>
      <Box dataTestid='departments-table' margin={{ bottom: 7 }}>
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
          onAscendingSort={(key: string) => handleSort(key)}
          onDescendingSort={(key: string) => handleSort(key)}
        />
      </Box>
    </>
  )
}

export default DepartmentsTable
