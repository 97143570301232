import { FC, useEffect, useState } from 'react'
import { upperFirst } from 'lodash'
import { DataProps } from '@teamfabric/copilot-ui'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/suppliersSlice'
import { Table } from 'atoms'
import { getTableCaption } from 'lib/utils/table'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
}

const SupplierLocationsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      title: 'ID',
      width: '8%',
    },
    {
      title: 'Supplier',
      width: '22%',
    },
    {
      title: 'Type',
      width: '10%',
    },
    {
      title: 'Address',
      width: '25%',
    },
    {
      title: 'City',
      width: '15%',
    },
    {
      title: 'State/Province',
      width: '10%',
    },
    {
      title: 'Country',
      width: '10%',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(({ id, address, brand, type }) => {
      return {
        id,
        data: {
          'ID': { value: id },
          'Supplier': { value: brand.name },
          'Type': { value: upperFirst(type) },
          'Address': { value: address.street1 },
          'City': { value: address.city },
          'State/Province': { value: address.province },
          'Country': { value: address.country },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (currentPage: number) => {
    dispatch(
      setCurrentPage({
        page: 'locations',
        currentPage,
      })
    )
  }

  return (
    <div className='mt-4'>
      <Table
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        selectable={false}
        tableCaption={getTableCaption(props.currentPage, props.totalRows)}
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </div>
  )
}

export default SupplierLocationsTable
