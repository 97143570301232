import { useEffect, useMemo, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { getPlatformAccountById } from 'api/platformAccount'
import { getShopifyWebhooks } from 'api/shopify'
import StoreDetailsCardFabric from 'modules/settings/components/integrations/fabric/configuration/storeDetails'
import StoreDetailsCardShopify from 'modules/settings/components/integrations/shopify/configuration/storeDetails'
import ReceiveOrdersCard from 'modules/settings/components/integrations/shopify/configuration/receiveOrders'
import ShipmentNotificationsCard from 'modules/settings/components/integrations/shopify/configuration/shipmentNotifications'
import SkipFraudOrdersCard from 'modules/settings/components/integrations/shopify/configuration/skipFraudOrder'
import LocationsCard from 'modules/settings/components/integrations/shopify/configuration/locations'
import InventoryCard from 'modules/settings/components/integrations/shopify/configuration/inventory'

const PlatformAccountConfiguration: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const { id, platform } = useParams()

  const [platformAccount, setPlatformAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingWebhooks, setLoadingWebhooks] = useState(true)
  const [webhooks, setWebhooks] = useState(null)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingIntegrations({ page: 'Configuration' })
        ),
      0
    )
  }, [])

  const fetchPlatformAccounts = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getPlatformAccountById({ id: id })
    setPlatformAccount(data)
    setLoading(false)
  }

  const fetchWebhooks = async (quiet?: boolean) => {
    if (!quiet) setLoadingWebhooks(true)
    const { data } = await getShopifyWebhooks({
      platformAccountId: id,
      params: {},
    })
    setWebhooks(data.results)
    setLoadingWebhooks(false)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (platform === 'shopify') {
          await fetchWebhooks()
          await fetchPlatformAccounts()
        } else {
          await fetchPlatformAccounts()
        }
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
        setLoadingWebhooks(false)
      }
    })()
  }, [])

  const storeCredentials = useMemo(() => {
    if (!loading && platformAccount?.credentials?.length) {
      return platformAccount.credentials.reduce((acc, obj) => {
        const key = Object.keys(obj)[0]
        const { label, values } = obj[key]
        acc[label] = values[0]?.value || ''
        return acc
      }, {})
    }
    return {}
  }, [loading, platformAccount])

  // Choose the correct StoreDetailsCard component based on the platform
  const StoreDetailsCard =
    platform === 'shopify' ? StoreDetailsCardShopify : StoreDetailsCardFabric

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }} dataTestid={`${platform}-configuration`}>
      <PageHeaderComponent
        h1Text={`Configuration - ${platform} Settings`}
        customClassName='mb-4'
        dataTestid={`${platform}-configuration-header`}
        primaryButtons={[
          {
            onClick: () => navigate('/settings/integrations'),
            text: 'Back',
            variant: 'secondary',
            dataTestid: 'back-button'
          },
        ]}
      />
      <StoreDetailsCard loading={loading} storeCredentials={storeCredentials} />
      {platform === 'shopify' && (
        <>
          <div className='divider-4' />
          <ReceiveOrdersCard
            loading={loadingWebhooks}
            webhooks={webhooks}
            loadWebhooks={() => fetchWebhooks(true)}
          />
          <div className='divider-4' />
          <SkipFraudOrdersCard
            loading={loading}
            account={platformAccount}
            loadAccount={() => fetchPlatformAccounts(true)}
          />
          <div className='divider-4' />
          <ShipmentNotificationsCard
            loading={loading}
            account={platformAccount}
            loadAccount={() => fetchPlatformAccounts(true)}
          />
          <div className='divider-4' />
          <LocationsCard
            loading={loading}
            account={platformAccount}
            loadAccount={() => fetchPlatformAccounts(true)}
          />
          <div className='divider-4' />
          <InventoryCard
            loading={loadingWebhooks}
            webhooks={webhooks}
            loadWebhooks={() => fetchWebhooks(true)}
          />
        </>
      )}
    </Box>
  )
}

export default PlatformAccountConfiguration
