import {
  SkeletonAvatar as SkeletonAvatarUI,
  AvatarSize,
} from '@teamfabric/copilot-ui'

type SkeletonProps = {
  variant?: 'light'
  size?: AvatarSize
  style?: any
}

const SkeletonAvatar: React.FC<SkeletonProps> = ({ ...props }) => {
  return (
    <div style={props.style}>
      <SkeletonAvatarUI {...props} />
    </div>
  )
}

export default SkeletonAvatar
