import React, { useMemo, useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Button, Dropdown } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { pushInventory } from 'api/platformAccount'
import { dropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  loading: boolean
  connections: any
}

const PushInventoryCard: React.FC<ChildProps> = ({
  loading,
  connections
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [selectedConnection, setSelectedConnection] =
    useState(dropDownInitialObj)
  const [job, setJob] = useState(null)

  const onPushInventory = async () => {
    setSaving(true)
    const body = {
      connection_id: parseInt(selectedConnection.id)
    }
    try {
      const { data } = await pushInventory({ platformAccountId: id, body })
      setJob(data)
      showToast({
        label: 'Sync scheduled!',
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'Sync failed!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const connectionOptions = useMemo(() => {
    return connections?.map(option => (
      {
        id: option.id,
        label: option?.brand?.name
      }
    ))
  }, [!loading, connections])

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Push Inventory</span>}
            h2Text={
              <span className='body2-regular'>
                Push inventory from fabric to Shopify.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            Use this utility to push inventory for all synced items from the specified connection.
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <Dropdown
                width='100%'
                label=''
                placeholder='Select a connection'
                required={true}
                value={selectedConnection}
                onChange={(option) => setSelectedConnection(option)}
                options={connectionOptions}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onPushInventory()}
                text='Push Inventory'
                variant='secondary'
                isDisabled={
                  saving || loading || !selectedConnection.id
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default PushInventoryCard
