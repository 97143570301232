import React, { ChangeEvent } from 'react'
import Filter from 'atoms/filter/filter'
import RadioGroup from 'atoms/input/radioGroup'
import Link from 'atoms/link/link'

type FilterProps = {
  dataTestid: string
  name?: string
  label: string
  onClear: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: any
  defaultValue?: string
  filterCount?: string
  wrapperClass?: string
  value?: string
}

const RadioGroupFilter: React.FC<FilterProps> = ({ name, ...props }) => {
  return (
    <div className={props.wrapperClass}>
      <Filter
        {...props}
        renderResults={
          <div className='width-100px p-3'>
            <RadioGroup
              {...props}
              name={name ? name : ''}
              label=''
              onChange={(e) => props.onChange(e)}
            />
            <div className='pt-3 flex-end'>
              {(props?.defaultValue || props?.value) && (
                <Link
                  onClick={() => props?.onClear()}
                  label={'Reset'}
                  variant='primary'
                />
              )}
            </div>
          </div>
        }
      />
    </div>
  )
}

export default RadioGroupFilter
