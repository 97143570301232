import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  params: {
    added_to_proposal: 1,
    is_rejected: 0,
    ruleset_variant_summary: 1,
    short: 1,
    order_by: '-id',
    page: 1,
    limit: 10,
  },
  selected: [],
}
const proposalProductItemsSlice = createSlice({
  name: 'proposal_product_items',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
  },
})

export const { setSorting, setCurrentPage, setParams, setSelected } =
  proposalProductItemsSlice.actions

export default proposalProductItemsSlice.reducer
