import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import GridRow from 'atoms/grid/gridRow'
import GridCol from 'atoms/grid/gridCol'
import VerticalProgressStepper from 'atoms/verticalProgressStepper'
import OnboardingQuestionnaire from '../components/questionnaire'
import { ONBOARDING_TASKS } from '../constants'
import OnboardingCallIntro from '../components/call/intro'
import SkipCallModal from '../components/call/skipCallModal'
import { RootState } from 'store'
import Billing from '../components/info/billing'
import SkipCallCompleted from '../components/call/skipCallCompleted'
import Header from '../components/progress/header'
import { setCurrentStep } from 'store/onboardingSlice'
import { useSelector, useDispatch } from 'react-redux'
import Locations from '../components/info/locations'
import Contact from '../components/info/contact'
import TransactionNotification from '../components/notification/transaction'
import Digest from '../components/notification/digest'
import { getState, isStepAvaiableToClick } from '../utils'
import IntegrationProgress from '../components/integration/integrationProgress'
import CompleteOnboarding from '../components/complete'
import LeaveWithoutSavingModal from '../components/integration/modal/leaveWithoutSavingModal'
import { resetSliceByCurrentStepParent } from './progressHelper/updateSlices'
import { getIntegrationSubSteps } from './progressHelper/integrationSteps'
import { PATHS } from 'routes/pageRoutes'
import {
  resetInfoTasks,
  resetIntegrationTask,
} from './progressHelper/resetTasks'
import { resetAssignTask } from 'api/onboarding'

const OnboardingProgress: React.FC = () => {
  const { name } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)
  const currentStep = state.onboarding.currentStep
  const questionnaire = state.onboarding.questionnaire
  const call = state.onboarding.call
  const info = state.onboarding.info
  const notifications = state.onboarding.notifications
  const integration = state.onboarding.integration
  const nextAvaiableStep = state.onboarding.nextAvaiableStep
  const process = {
    questionnaire,
    call,
    info,
    notifications,
    integration,
    complete: {
      processNum: 0,
      completed: false,
    },
  }
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [clearForm, setClearForm] = useState(false)
  const [isNextClicked, setIsNextClicked] = useState(false)
  const [isResetClicked, setIsResetClicked] = useState(false)
  const [isPreviousClicked, setIsPreviousClicked] = useState(false)
  const [isSaveExitClicked, setIsSaveExitClicked] = useState(false)
  const [verticalProgressStepperSteps, setVerticalProgressStepperSteps] =
    useState([])
  // Onboarding Call
  const [displaySkipModal, setDisplaySkipModal] = useState(false)
  // Leave without saving modal
  const [showLeaveWithoutSavingModal, setShowLeaveWithoutSavingModal] =
    useState(false)
  const [pageMoveTo, setPageMoveTo] = useState({
    value: '',
    parent: '',
  })

  /**
   * Use the values for VerticalProgressStepper taskSteps()
   * @param parentName string sub task parent name
   * @param subTaskName string sub task name
   * @returns boolean true if the task is completed
   */
  const getIsCompletedBySubTaskName = ({ parentName, subTaskName }) => {
    if (parentName === ONBOARDING_TASKS.CALL.VALUE) {
      return call
    }
    switch (subTaskName) {
      case ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.TASK_NAME:
        return questionnaire.experienceLevel !== ''
      case ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.TASK_NAME:
        return questionnaire.integrationMethod !== ''
      case ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME:
        return info[ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]
      case ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME:
        return info[ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]
      case ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME:
        return info[ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]
      case ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.TASK_NAME:
        return notifications.transactionDone
      case ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.TASK_NAME:
        return notifications.digestDone
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.TASK_NAME:
        return integration.introDone
      default:
        return false
    }
  }

  /**
   * Sub steps that use for VeryicalProgress
   * @param key
   * @returns
   */
  const getVeryicalProgressSubSteps = ({ key }) => {
    if (key === ONBOARDING_TASKS.COMPLETE.VALUE.toUpperCase()) {
      return []
    }
    const subSteps = Object.keys(ONBOARDING_TASKS[key].SUB_TASKS)
      .filter((subKey) => ONBOARDING_TASKS[key].SUB_TASKS[subKey].IS_STEP)
      .map((subKey) => {
        const subTask = ONBOARDING_TASKS[key].SUB_TASKS[subKey]
        const isSubTaskCompleted = getIsCompletedBySubTaskName({
          parentName: ONBOARDING_TASKS[key].VALUE,
          subTaskName: subTask.TASK_NAME,
        })
        return {
          name: subTask.NAME,
          selected: currentStep.value === subTask.TASK_NAME,
          onClick: () => {
            if (currentStep.parent === ONBOARDING_TASKS.INFO.VALUE) {
              setShowLeaveWithoutSavingModal(true)
              setPageMoveTo({
                value: subTask.TASK_NAME,
                parent: ONBOARDING_TASKS[key].VALUE,
              })
              return
            }
            if (isSubTaskCompleted) {
              dispatch(
                setCurrentStep({
                  value: subTask.TASK_NAME,
                  parent: ONBOARDING_TASKS[key].VALUE,
                })
              )
            }
          },
          state: getState({
            isSubState: true,
            isCompleted: isSubTaskCompleted,
          }),
        }
      })
    // Integration sub steps
    if (currentStep.parent === ONBOARDING_TASKS.INTEGRATION.VALUE) {
      return getIntegrationSubSteps({
        integration,
        currentStep,
        dispatch,
      })
    }
    return subSteps
  }

  /**
   * VerticalProgressStepper steps values
   */
  const setTaskSteps = () => {
    const steps = Object.keys(ONBOARDING_TASKS).map((key) => {
      return {
        name: ONBOARDING_TASKS[key].NAME,
        selected: name === ONBOARDING_TASKS[key].VALUE,
        onClick: () => {
          if (
            process[key.toLowerCase()].completed ||
            isStepAvaiableToClick({ key, nextAvaiableStep, process })
          ) {
            navigate(ONBOARDING_TASKS[key].LINK)
            const firstTask = Object.values(ONBOARDING_TASKS[key].SUB_TASKS)[0]
            dispatch(
              setCurrentStep({
                value: firstTask['TASK_NAME'],
                parent: ONBOARDING_TASKS[key].VALUE,
              })
            )
          }
        },
        state: getState({
          isSubState: false,
          isCompleted: process[key.toLowerCase()]?.completed || false,
          isNext: ONBOARDING_TASKS[key].VALUE === nextAvaiableStep,
        }),
        description: `${process[key.toLowerCase()].processNum} of ${
          ONBOARDING_TASKS[key].TASK_NUM
        } steps complete`,
        subSteps: getVeryicalProgressSubSteps({ key }),
      }
    })
    setVerticalProgressStepperSteps(steps)
  }

  // update VerticalProgressStepper
  useEffect(() => {
    setTaskSteps()
  }, [currentStep.value, name, integration.selectedMethod, state])

  // onboardingProgress previous button clicked
  useEffect(() => {
    if (
      !isPreviousClicked ||
      currentStep.parent === ONBOARDING_TASKS.INTEGRATION.VALUE
    ) {
      // integration previous steps are in integrationProgress
      return
    }
    setIsPreviousClicked(false)
    const prev =
      ONBOARDING_TASKS[currentStep.parent.toUpperCase()].SUB_TASKS[
        currentStep.value.toUpperCase()
      ]
    dispatch(
      setCurrentStep({
        value: prev.PREVIOUS_TASK,
        parent: prev.PREVIOUS_PARENT_TASK,
      })
    )
    if (currentStep.parent !== prev.PREVIOUS_PARENT_TASK) {
      navigate(`/onboarding/${prev.PREVIOUS_PARENT_TASK}`)
    }
  }, [isPreviousClicked])

  // Save and Exit button clicked
  useEffect(() => {
    if (
      currentStep.parent === ONBOARDING_TASKS.CALL.VALUE &&
      isSaveExitClicked
    ) {
      setIsSaveExitClicked(false)
      navigate(PATHS.Onboarding)
    }
  }, [isSaveExitClicked])

  // onboardingProgress reset button clicked
  useEffect(() => {
    ;(async () => {
      if (!isResetClicked) {
        return
      }
      setReseting(true)
      setIsResetClicked(false)
      setClearForm(true)
      resetSliceByCurrentStepParent({
        dispatch,
        state,
      })
      switch (currentStep.parent) {
        case ONBOARDING_TASKS.INFO.VALUE:
          await resetInfoTasks({ currentStep, process })
          break
        case ONBOARDING_TASKS.INTEGRATION.VALUE:
          await resetIntegrationTask({ integration })
          break
        default:
          const taskId = process[currentStep.parent].taskId
          await resetAssignTask({ taskId, body: { info: {} } })
      }
      setReseting(false)
      dispatch(
        setCurrentStep({
          value: Object.values(
            ONBOARDING_TASKS[currentStep.parent.toUpperCase()].SUB_TASKS
          )[0]['TASK_NAME'],
          parent: currentStep.parent,
        })
      )
    })()
  }, [isResetClicked])

  return (
    <div className='container'>
      {/* Modals */}
      <SkipCallModal
        isVisible={
          displaySkipModal ||
          currentStep.value === ONBOARDING_TASKS.CALL.SUB_TASKS.SKIP.TASK_NAME
        }
        setDisplaySkipModal={setDisplaySkipModal}
      />
      <LeaveWithoutSavingModal
        isVisible={showLeaveWithoutSavingModal}
        setIsDisplayModal={setShowLeaveWithoutSavingModal}
        pageMoveTo={pageMoveTo}
      />
      {/* Title and actions */}
      {Object.keys(ONBOARDING_TASKS)
        .filter((key) => ONBOARDING_TASKS[key].VALUE === name)
        .map((key) => {
          return (
            <Header
              key={key}
              setIsNextClicked={setIsNextClicked}
              setIsResetClicked={setIsResetClicked}
              setDisplaySkipModal={setDisplaySkipModal}
              isNextButtonDisabled={isNextButtonDisabled}
              setIsPreviousClicked={setIsPreviousClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              process={process}
              loading={reseting}
            />
          )
        })}
      <GridRow padding={false}>
        {/* Left side Progress stepper */}
        <GridCol lg={3} md={3} sm={3}>
          <VerticalProgressStepper
            key={currentStep.value}
            steps={verticalProgressStepperSteps}
          />
        </GridCol>
        {/* Right side component render based on the router name */}
        <GridCol lg={9} md={9} sm={9}>
          {name === ONBOARDING_TASKS.QUESTIONNAIRE.VALUE && (
            <OnboardingQuestionnaire
              isNextClicked={isNextClicked}
              setNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              setIsSaveExitClicked={setIsSaveExitClicked}
              isSaveExitClicked={isSaveExitClicked}
              reseting={reseting}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_INTRO.TASK_NAME && (
            <OnboardingCallIntro
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_COMPLETED.TASK_NAME && (
            <SkipCallCompleted
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME && (
            <Billing
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              setClearForm={setClearForm}
              clearForm={clearForm}
              reseting={reseting}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME && (
            <Locations
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              reseting={reseting}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME && (
            <Contact
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              reseting={reseting}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.TASK_NAME && (
            <TransactionNotification
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              setClearForm={setClearForm}
              clearForm={clearForm}
              reseting={reseting}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
            />
          )}
          {currentStep.value ===
            ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.TASK_NAME && (
            <Digest
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              reseting={reseting}
              isSaveExitClicked={isSaveExitClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
            />
          )}
          {/* Integrations */}
          {currentStep.parent === ONBOARDING_TASKS.INTEGRATION.VALUE && (
            <IntegrationProgress
              isNextClicked={isNextClicked}
              setIsNextButtonDisabled={setIsNextButtonDisabled}
              setIsNextClicked={setIsNextClicked}
              isPreviousClicked={isPreviousClicked}
              setIsPreviousClicked={setIsPreviousClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              isSaveExitClicked={isSaveExitClicked}
              reseting={reseting}
            />
          )}
          {currentStep.value === ONBOARDING_TASKS.COMPLETE.VALUE && (
            <CompleteOnboarding
              isNextClicked={isNextClicked}
              setIsNextClicked={setIsNextClicked}
              setIsSaveExitClicked={setIsSaveExitClicked}
              isSaveExitClicked={isSaveExitClicked}
            />
          )}
        </GridCol>
      </GridRow>
    </div>
  )
}

export default OnboardingProgress
