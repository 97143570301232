import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledWrapper } from '../../styles'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import PageHeaderComponent from 'molecules/PageHeader'
import OrderDetailsCard from './components/orderDetails'
import ShipTo from './components/shipTo'
import RequestedShippingMethod from './components/shippingMethod'
import Notes from './components/notes'
import OrderLines from './components/orderLines'
import { createOrder } from 'api/orders'
import { useToast } from '@teamfabric/copilot-ui'

const OrderCreateTemplate: FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const [formData, setFormData] = useState({
    order_details: null,
    ship_to: null,
    shipping_method: null,
    notes: '',
    order_lines: [],
  })

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.ORDER_CREATE)
  }, [])

  const handleEdit = (key, data) => {
    setFormData({
      ...formData,
      [key]: data,
    })
    validateForm()
  }

  const validateForm = () => {
    let valid =
      formData.order_details &&
      formData.ship_to &&
      formData.shipping_method &&
      formData.order_lines.length > 0
        ? true
        : false
    setIsReady(valid)
  }

  const CreateOrder = async () => {
    const json = {
      purchase_order_number: formData.order_details?.purchaseOrderNumber,
      customer_order_number: formData.order_details?.customerOrderNumber,
      retailer_order_number: formData.order_details?.retailerOrderNumber,
      // ordered_at: formData.order_details?.orderDate,
      ordered_at: new Date().toISOString(),
      ship_to: formData.ship_to,
      requested_shipping_method_id: formData.shipping_method.id,
      memos: [
        {
          text: formData.notes,
        },
      ],
      order_lines: formData.order_lines,
    }
    try {
      await createOrder(json)
      showToast({
        label: 'Order Created.',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      let errorMessage =
        'Your attempt to create this order failed. Please refresh and try again.'
      if (error.response && error.response.data.detail) {
        errorMessage = error.response.data.detail
      }
      showToast({
        label: errorMessage,
        isDismissable: true,
        variant: error,
        id: '1',
      })
    } finally {
      navigate('/orders')
    }
  }

  return (
    <StyledWrapper>
      <PageHeaderComponent
        h1Text='Create purchase order'
        primaryButtons={[
          {
            onClick: () => navigate('/orders'),
            text: 'Cancel',
            variant: 'secondary',
          },
          {
            onClick: () => CreateOrder(),
            text: 'Create Purchase Order',
            variant: 'primary',
            isDisabled: !isReady,
          },
        ]}
        customClassName='mb-4'
      />
      <OrderDetailsCard
        handleEdit={(data) => handleEdit('order_details', data)}
      />
      <div className='mb-4' />
      <ShipTo handleEdit={(data) => handleEdit('ship_to', data)} />
      <div className='mb-4' />
      <RequestedShippingMethod
        handleEdit={(data) => handleEdit('shipping_method', data)}
      />
      <div className='mb-4' />
      <Notes handleEdit={(data) => handleEdit('notes', data)} />
      <div className='mb-4' />
      <OrderLines handleEdit={(data) => handleEdit('order_lines', data)} />
    </StyledWrapper>
  )
}

export default OrderCreateTemplate
