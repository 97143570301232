import React from 'react'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import { formatDate } from 'lib/utils/date/formatDate'

interface ChildProps {
  data: any
}

const OrderLineItems: React.FC<ChildProps> = ({ data }) => {
  const getStatus = (status: string) => {
    let label = ''
    // open orders are now those that are 'open' or 'partial'
    switch (status) {
      case 'partial':
        label = 'In Progress'
        break
      case 'canceled':
        label = 'Canceled'
        break
      case 'closed':
        label = 'In Progress'
        break
      case 'backordered':
        label = 'Backordered'
        break
      default:
        label = 'Open'
    }
    return label
  }

  return (
    <div>
      {data.length > 0 ? (
        data.map(
          ({
            id,
            variant,
            quantity,
            status,
            quantity_shipped,
            fulfill_by,
            quantity_canceled,
            net_price,
            quantity_returned,
            price,
          }) => (
            <div key={id}>
              <div className='mb-5 mt-3'>
                <GridRow padding={false}>
                  <GridCol alignSelf='center' lg={4} md={4} sm={4}>
                    <img style={{ width: '50px' }} src={variant.media[0].url} />
                  </GridCol>
                  <GridCol lg={8} md={8} sm={8}>
                    <div>
                      <p>{variant.name}</p>
                      {/* if (this.context.isSupplier()) return null */}
                      <p className='grey-700'>
                        Supplier SKU{' '}
                        {variant.retailer_identifiers[0].identifier}
                      </p>
                    </div>
                  </GridCol>
                </GridRow>
              </div>
              <Card
                showDivider={false}
                headerContent=''
                bodyContent={
                  <div className='mb-1'>
                    <div className='border-b'>
                      <GridRow padding={false}>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Quantity Ordered</p>
                          <p className='grey-700'>{quantity}</p>
                        </GridCol>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Line Status</p>
                          <p className='grey-700'>{getStatus(status)}</p>
                        </GridCol>
                      </GridRow>
                    </div>
                    <div className='border-b'>
                      <GridRow padding={false}>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Quantity Shipped</p>
                          <p className='grey-700'>{quantity_shipped}</p>
                        </GridCol>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Due Date</p>
                          <p className='grey-700'>
                            {formatDate({ date: fulfill_by })}
                          </p>
                        </GridCol>
                      </GridRow>
                    </div>
                    <div className='border-b'>
                      <GridRow padding={false}>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Quantity Canceled</p>
                          <p className='grey-700'>{quantity_canceled}</p>
                        </GridCol>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Cost (ea.)</p>
                          <p className='grey-700'>{net_price}</p>
                        </GridCol>
                      </GridRow>
                    </div>
                    <div>
                      <GridRow padding={false}>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Quantity Returned</p>
                          <p className='grey-700'>{quantity_returned}</p>
                        </GridCol>
                        <GridCol lg={6} md={6} sm={6}>
                          <p>Price (ea.)</p>
                          <p className='grey-700'>{price}</p>
                        </GridCol>
                      </GridRow>
                    </div>
                  </div>
                }
              />
            </div>
          )
        )
      ) : (
        <p className='grey-400'>No Order Line Items</p>
      )}
    </div>
  )
}

export default OrderLineItems
