import React, { useEffect, useState } from 'react'
import { ONBOARDING_TASKS } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentStep } from 'store/onboardingSlice'
import PageHeader from 'atoms/pageHeader'
import { RootState } from 'store'

type HeaderProps = {
  setIsNextClicked: (value: boolean) => void
  setDisplaySkipModal: (value: boolean) => void
  isNextButtonDisabled: boolean
  setIsResetClicked: (value: boolean) => void
  setIsPreviousClicked: (value: boolean) => void
  setIsSaveExitClicked: (value: boolean) => void
  process: any
  loading: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsNextClicked,
  setDisplaySkipModal,
  isNextButtonDisabled,
  setIsResetClicked,
  setIsPreviousClicked,
  setIsSaveExitClicked,
  process,
  loading,
}) => {
  const dispatch = useDispatch()
  const currentStep = useSelector(
    (state: RootState) => state.onboarding.currentStep
  )
  const [badgeStatus, setBadgeStatus] = useState('default')
  const [badgeLabel, setBadgeLabel] = useState('Not Started')

  const getStatus = () => {
    if (process[currentStep.parent].completed) {
      return {
        status: 'success',
        label: 'Complete',
      }
    } else if (process[currentStep.parent].processNum === 0) {
      return {
        status: 'default',
        label: 'Not Started',
      }
    }
    return {
      status: 'alert',
      label: 'In Progress',
    }
  }
  // First loaded and currentStep is changed
  useEffect(() => {
    if (currentStep.value === ONBOARDING_TASKS.COMPLETE.VALUE) {
      // if all completed => the user cannot access the onboarding tasks?
      setBadgeStatus('default')
      setBadgeLabel('Not Started')
    } else {
      const { status, label } = getStatus()
      setBadgeStatus(status)
      setBadgeLabel(label)
    }
  }, [process, currentStep])

  const nextButtonAvaiableSteps = [
    ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.TASK_NAME,
    ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.TASK_NAME,
    ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_COMPLETED.TASK_NAME,
    ...Object.keys(ONBOARDING_TASKS.INFO.SUB_TASKS).map((key) =>
      key.toLocaleLowerCase()
    ),
    ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.TASK_NAME,
    ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.TASK_NAME,
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.TASK_NAME,
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
      .BIGCOMMERCE.TASK_NAME,
    ...Object.keys(ONBOARDING_TASKS.INTEGRATION.EDI_TASKS).map((key) =>
      key.toLocaleLowerCase()
    ),
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.TASK_NAME,
    ONBOARDING_TASKS.COMPLETE.VALUE,
  ]

  const previousAvaiableStepsParentValue = [
    ONBOARDING_TASKS.INFO.VALUE,
    ONBOARDING_TASKS.NOTIFICATIONS.VALUE,
    ONBOARDING_TASKS.INTEGRATION.VALUE,
    ONBOARDING_TASKS.COMPLETE.VALUE,
  ]

  const resetButtonAvaiableStepsParentValue = [
    ONBOARDING_TASKS.QUESTIONNAIRE.VALUE,
    ONBOARDING_TASKS.INFO.VALUE,
    ONBOARDING_TASKS.NOTIFICATIONS.VALUE,
    ONBOARDING_TASKS.INTEGRATION.VALUE,
  ]

  const getButtonsArray = () => {
    const array = []
    array.push({
      onClick: () => {
        setIsSaveExitClicked(true)
      },
      text: 'Save & Exit',
      isDisabled: loading,
      variant: 'tertiary',
    })
    if (
      currentStep.value === ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_INTRO.TASK_NAME
    ) {
      array.push({
        onClick: () => {
          setDisplaySkipModal(true)
          dispatch(
            setCurrentStep({
              value: ONBOARDING_TASKS.CALL.SUB_TASKS.SKIP.TASK_NAME,
              parent: ONBOARDING_TASKS.CALL.VALUE,
            })
          )
        },
        text: 'Skip Call',
        variant: 'secondary',
      })
      array.push({
        onClick: () => setIsNextClicked(true),
        text: 'Schedule Call',
        variant: 'primary',
      })
    }
    if (previousAvaiableStepsParentValue.includes(currentStep.parent)) {
      array.push({
        onClick: () => setIsPreviousClicked(true),
        text: 'Previous Task',
        variant: 'secondary',
      })
    }
    if (nextButtonAvaiableSteps.includes(currentStep.value)) {
      array.push({
        onClick: () => {
          setIsNextClicked(true)
        },
        isDisabled: isNextButtonDisabled || loading,
        text:
          currentStep.value === ONBOARDING_TASKS.COMPLETE.VALUE
            ? 'Complete Onboarding'
            : 'Next',
        variant: 'primary',
      })
    }
    return array
  }

  const getTertiaryButtonArray = () => {
    const array = []
    if (resetButtonAvaiableStepsParentValue.includes(currentStep.parent)) {
      array.push({
        onClick: () => {
          setIsResetClicked(true)
        },
        icon: 'Refresh',
        isDisabled: process[currentStep.parent].processNum === 0 || loading,
        text: `Reset ${
          ONBOARDING_TASKS[currentStep.parent.toUpperCase()].NAME
        }`,
        variant: 'tertiary',
      })
    }
    return array
  }

  return (
    <div className='mb-4'>
      <PageHeader
        badgeProps={{
          status: badgeStatus,
          label: badgeLabel,
        }}
        h1Text={ONBOARDING_TASKS[currentStep.parent.toUpperCase()].TITLE}
        h2Text={ONBOARDING_TASKS[currentStep.parent.toUpperCase()].DESCRIPTION}
        primaryButtons={getButtonsArray()}
        tertiaryButtons={getTertiaryButtonArray()}
      />
    </div>
  )
}

export default Header
