import React, { useState, useEffect } from 'react'
import GridRow from 'atoms/grid/gridRow'
import GridCol from 'atoms/grid/gridCol'
import Card from 'atoms/card/card'
import { useNavigate } from 'react-router-dom'
import { ONBOARDING_TASKS } from '../constants'
import PageHeader from 'atoms/pageHeader'
import {
  setConnectionId,
  setCurrentStep,
  setNextAvaiableStep,
  setRetailerName,
} from 'store/onboardingSlice'
import WithIconButton from 'molecules/box/withIconButton'
import { getConnections } from 'api/connection'
import { useToast } from '@teamfabric/copilot-ui'
import { formatDate } from 'lib/utils/date/formatDate'
import { getAssignTask } from 'api/onboarding'
import { useSelector, useDispatch } from 'react-redux'
import { initSlices } from './progressHelper/updateSlices'
import { RootState } from 'store'
import Button from 'atoms/button/button'
import { getCompletedDate, getNextTaskCodeByTasks } from '../utils'
import Link from 'atoms/link/link'
import { PATHS } from 'routes/pageRoutes'
import { EmptyPage } from 'atoms'

const OnboardingDashboardTemplate: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const showToast = useToast()

  const state = useSelector((state: RootState) => state)
  const questionnaire = state.onboarding.questionnaire
  const call = state.onboarding.call
  const info = state.onboarding.info
  const notifications = state.onboarding.notifications
  const integration = state.onboarding.integration
  const nextAvailableStep = state.onboarding.nextAvaiableStep

  const onboardingSlices = {
    questionnaire,
    call,
    info,
    notifications,
    integration,
    complete: {
      processNum: 0,
      completed: false,
    },
  }

  const [badgeStatus, setBadgeStatus] = useState('default')
  const [badgeLabel, setBadgeLabel] = useState('Not Started')
  const [expectedDate, setExpectedDate] = useState('')
  const [assignedTasks, setAssignedTasks] = useState([])
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(true)
  const [showEmptyPage, setShowEmptyPage] = useState(false)

  const updateBadge = (results: any) => {
    const isFirstStarted =
      results.filter(({ completed_at }) => completed_at !== null).length ===
      0 && questionnaire.processNum === 0
    // pending or complete
    setBadgeLabel(isFirstStarted ? 'Not Started' : 'In progress')
    setBadgeStatus(isFirstStarted ? 'default' : 'alert')
  }

  const loadConnections = async () => {
    try {
      const {
        data: { results },
      } = await getConnections({
        params: { fields: 'id', status: 'onboarding' },
      })
      if (results.length <= 0) {
        // No onboarding => move the user to order page?
        setShowEmptyPage(true)
        return
      }
      setName(results[0].retailer.name)
      dispatch(setRetailerName(results[0].retailer.name))
      dispatch(setConnectionId(results[0].id))
      setExpectedDate(results[0].onboarding_target_date)
      const selectedMethod =
        results[0].integration_type === ONBOARDING_TASKS.INTEGRATION.EDI_CODE
          ? ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
            .EDI.TASK_NAME
          : results[0].integration_type
      await loadTasks({ selectedMethod })
    } catch (error) {
      showToast({
        label: "Couldn't load connections",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  const loadTasks = async ({ selectedMethod }) => {
    try {
      const {
        data: { results },
      } = await getAssignTask()
      setAssignedTasks(results)
      initSlices({
        selectedMethod,
        tasks: results,
        dispatch,
        integration,
      })
      const nextStepCode = getNextTaskCodeByTasks({
        tasks: results,
        selectedMethod,
      })
      dispatch(setNextAvaiableStep(nextStepCode))
      updateBadge(results)
    } catch (error) {
      console.log(error)
      showToast({
        label: "Couldn't load tasks",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    }
  }

  useEffect(() => {
    ; (async () => {
      await loadConnections()
    })()
  }, [])

  const getButtonText = ({ key }) => {
    if (onboardingSlices[key.toLowerCase()]?.processNum > 0) {
      return 'In Progress'
    }
    return 'Start'
  }

  const getEndElement = ({ isCompleted, key }) => {
    const firstTask = Object.values(ONBOARDING_TASKS[key].SUB_TASKS)[0]
    const completedDate = getCompletedDate({
      assignedTasks,
      parentTask: key,
      selectedMethod: integration?.selectedMethod,
    })
    return isCompleted ? (
      <>
        <p className='m-0'>Done: {completedDate}</p>
        <div className='flex-end'>
          <Link
            label='Review'
            mode='inline'
            variant='primary'
            onClick={() => {
              navigate(ONBOARDING_TASKS[key].LINK)
              dispatch(
                setCurrentStep({
                  value: firstTask['TASK_NAME'],
                  parent: ONBOARDING_TASKS[key].VALUE,
                })
              )
            }}
          />
        </div>
      </>
    ) : (
      <div className='v-center'>
        <Button
          variant='secondary'
          text={getButtonText({ key })}
          isDisabled={nextAvailableStep !== key.toLowerCase()}
          onClick={() => {
            navigate(ONBOARDING_TASKS[key].LINK)
            dispatch(
              setCurrentStep({
                value: firstTask['TASK_NAME'],
                parent: ONBOARDING_TASKS[key].VALUE,
              })
            )
          }}
        />
      </div>
    )
  }

  const getTitleElement = ({ isCompleted, inProgress, key }) => {
    const textColor = !inProgress && !isCompleted ? 'grey-500' : ''
    return (
      <h5 className={`${textColor} h5 m-0 mb-2`}>
        {ONBOARDING_TASKS[key].TITLE}
      </h5>
    )
  }

  const getDescription = ({ isCompleted, key }) => {
    const textColor = !isCompleted ? 'grey-500' : ''
    return (
      <p className={`${textColor} body1-regular m-0`}>
        {ONBOARDING_TASKS[key].DESCRIPTION}
      </p>
    )
  }

  return (
    <div className='container'>
      <div className='mb-3'>
        <PageHeader
          badgeProps={!loading && !showEmptyPage && {
            status: badgeStatus,
            label: badgeLabel,
          }}
          h1Text={`${name} Onboarding`}
          h2Text={!loading && !showEmptyPage && 'Complete all tasks begin receiving orders'}
        />
      </div>
      {showEmptyPage
        ? (<EmptyPage
          headerText="You don't currently have any connections to onboard!"
          description='Click "Return to Dashboard" below to navigate to dashboard.'
          buttons={[
            {
              onClick: () => navigate(PATHS.OrdersDashboard),
              text: 'Return to Dashboard',
              variant: 'primary',
            },
          ]}
          customClassName="p-6"
        />)
        : (
          <GridRow padding={false}>
            <GridCol lg={9} md={9} sm={9}>
              {Object.keys(ONBOARDING_TASKS).map((key) => {
                const isCompleted = onboardingSlices[key.toLowerCase()]?.completed
                const inProgress = nextAvailableStep === key.toLowerCase()
                return (
                  <div key={ONBOARDING_TASKS[key].VALUE} className='mt-4'>
                    <WithIconButton
                      title={{
                        onRender: () =>
                          getTitleElement({ isCompleted, inProgress, key }),
                      }}
                      description={{
                        onRender: () => getDescription({ isCompleted, key }),
                      }}
                      endElement={getEndElement({ isCompleted, key })}
                      iconName='Check'
                      disabled={!isCompleted}
                      iconColor={isCompleted ? '#358C4D' : ''}
                      loading={loading}
                    />
                  </div>
                )
              })}
            </GridCol>
            {!loading && <GridCol lg={3} md={3} sm={3}>
              <div className='mt-4'>
                <Card
                  showCollapse
                  showDivider
                  headerContent={
                    <div>
                      <PageHeader h1Text='Expected Completion...' />
                      <p className='body2-regular color-red'>
                        {formatDate({ date: expectedDate })}
                      </p>
                    </div>
                  }
                  bodyContent={
                    <>
                      <p className='mb-2 body2-regular'>
                        {name} is expecting to launch your products near the
                        expected completion date.
                      </p>
                      <p className='body2-regular'>
                        Avoid unexpected delays by completing onboarding by this
                        date.
                      </p>
                    </>
                  }
                />
              </div>
            </GridCol>}
          </GridRow>
        )}

    </div>
  )
}

export default OnboardingDashboardTemplate
