import React, { useEffect, useState } from 'react'
import { Table } from 'atoms'
import VariantItem from 'molecules/lineItem/variantItem'
import Status from 'molecules/lineItem/status'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import OrderLinesActionsMenu from '../presenters/orderLinesActions'

type PricingLineItemsProps = {
  loading: boolean
  details: any
  reloadOrder: () => void
}

const PricingLineItems: React.FC<PricingLineItemsProps> = ({
  loading,
  details,
  reloadOrder,
}) => {
  const [lineItemDetails, setLineItemDetails] = useState<any[]>([])

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const formatData = ({ order_lines }) => {
    const formattedData = order_lines.map(
      ({ id, variant, quantity, status, net_price, backordered_until }) => {
        return {
          id,
          data: {
            Item: {
              onRender: () => (
                <VariantItem variant={variant} showInventoryDetailsModal />
              ),
            },
            Ordered: {
              value: quantity,
            },
            Cost: {
              value: formatCurrency(parseFloat(net_price)),
            },
            Status: {
              onRender: () => (
                <Status
                  line={{
                    status: status,
                    backordered_until: backordered_until,
                  }}
                  hideDate
                />
              ),
            },
            Actions: {
              onRender: () => (
                <OrderLinesActionsMenu
                  orderId={details.id}
                  status={status}
                  variant={variant}
                  lineId={id}
                  lineBackorderedUntil={backordered_until}
                  reloadOrder={reloadOrder}
                />
              ),
            },
          },
        }
      }
    )
    setLineItemDetails(formattedData)
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Item',
      width: 400,
    },
    {
      sortable: false,
      title: 'Ordered',
      width: 100,
    },
    {
      sortable: false,
      title: 'Cost',
      width: 100,
    },
    {
      sortable: false,
      title: 'Status',
      width: 120,
    },
    {
      title: 'Actions',
      width: 200,
    },
  ]

  return (
    <>
      <Table
        columns={tableColumns}
        loading={loading}
        selectable={false}
        data={lineItemDetails}
      />
    </>
  )
}

export default PricingLineItems
