import { FC, useEffect, useMemo, useState } from 'react'
import { StyledWrapper } from '../../styles'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { getConnectionDetail } from 'api/connection'
import { getTenantType } from 'api/helper'
import { useNavigate, useParams } from 'react-router-dom'
import HorizontalTab from 'atoms/tab'
import PageHeaderComponent from 'molecules/PageHeader'
import SummaryDetails from 'organisms/connections/detail/Summary'
import AttributeDetails from 'organisms/connections/detail/Attributes'
import CarrierDetails from 'organisms/connections/detail/Carriers'
import LocationDetails from 'organisms/connections/detail/Locations'
import SuspendSupplierModal from 'organisms/connections/modals/SuspendSupplier'
import BadgeStatusPresenter from 'molecules/connections/presenters/status'
import { Box } from 'atoms'

const SupplierDetailsTemplate: FC = () => {
  const navigate = useNavigate()
  // const params = useSelector((state: RootState) => state.merchant.params)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [label, setLabel] = useState(0)
  const [showSuspendModal, setShowSuspendModal] = useState(false)

  useEffect(() => {
    ;(async () => {
      const { data } = await getConnectionDetail({ id })
      setData(data)
      setLoading(false)
    })()
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.manageSupplierDetails({ supplierID: id })
        ),
      0
    )
  }, [])

  const reload = async () => {
    const { data } = await getConnectionDetail({ id })
    setData(data)
  }

  const { isRetailer } = getTenantType()

  const headerActions = useMemo(() => {
    let actions = []
    if (isRetailer) {
      actions = [
        {
          onClick: () => navigate('/list'),
          text: 'Cancel',
          variant: 'tertiary',
        },
      ]
      if (data?.status !== 'suspended') {
        actions.push({
          onClick: () => setShowSuspendModal(true),
          text: 'Suspend Supplier',
          variant: 'secondary',
        })
      }
    }
    return actions
  }, [isRetailer, data])

  return (
    <>
      <Box padding={{ top: 6, bottom: 6, left: 6, right: 6 }}>
        <PageHeaderComponent
          loading={loading}
          badgeProps={BadgeStatusPresenter(data.status)}
          h1Text={data?.brand?.name}
          h2Text={`Connection ID: ${id}`}
          customClassName='mb-4'
          primaryButtons={headerActions}
        />
        <HorizontalTab
          onTabChange={setLabel}
          data={{
            tabs: [
              {
                label: 'Summary',
                content: (
                  <SummaryDetails
                    loading={loading}
                    details={data}
                    reload={() => reload()}
                  />
                ),
              },
              {
                label: 'Locations',
                content: (
                  <LocationDetails
                    loading={loading}
                    details={data}
                    reload={() => reload()}
                  />
                ),
              },
              {
                label: 'Attributes',
                content: (
                  <AttributeDetails
                    loading={loading}
                    details={data}
                    reload={() => reload()}
                  />
                ),
              },
              {
                label: 'Carriers',
                content: (
                  <CarrierDetails
                    loading={loading}
                    details={data}
                    reload={() => reload()}
                  />
                ),
              },
            ],
          }}
        />
      </Box>
      {showSuspendModal && (
        <SuspendSupplierModal
          reload={() => reload()}
          onClose={() => setShowSuspendModal(false)}
          showModal={showSuspendModal}
          connectionId={id}
        />
      )}
    </>
  )
}

export default SupplierDetailsTemplate
