import React, { useState } from 'react'
import Modal from 'atoms/modal/modal'
import { MODALS } from './constants'
import { useToast } from '@teamfabric/copilot-ui'

type ExportModalProps = {
  type: {
    NAME: string
    COPY: string
  }
  setShowExportModal: (value: boolean) => void
  showExportModal: boolean
  exportCall: any
  params?: any
}

const ExportModal: React.FC<ExportModalProps> = ({
  type,
  setShowExportModal,
  showExportModal,
  exportCall,
  params,
}) => {
  const [loading, setLoading] = useState(false)
  const showToast = useToast()

  const scheduleExport = async () => {
    let callParams = params ? params : {}
    try {
      setLoading(true)
      await exportCall({ params: callParams })
      showToast({
        label: MODALS.EXPORT.TOAST.SUCCESS,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: MODALS.EXPORT.TOAST.FAILURE,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setShowExportModal(false)
      setLoading(false)
    }
  }

  return (
    <Modal
      headerText={`Export ${type.NAME}`}
      description=''
      onClose={() => setShowExportModal(false)}
      isVisible={showExportModal}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => setShowExportModal(false),
          text: 'Close',
          variant: 'secondary',
        },
        {
          dataTestid: '',
          onClick: () => scheduleExport(),
          text: 'Export',
          variant: 'primary',
          isDisabled: loading,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => setShowExportModal(false)}
    >
      <div>
        <p>
          {type.COPY} {MODALS.EXPORT.COMMON.COPY}
        </p>
      </div>
    </Modal>
  )
}

export default ExportModal
