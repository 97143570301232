import React from 'react'
import { PageHeader } from 'atoms'

type ChildProps = {
  header: any
  description?: any
  customClassName?: string
  showSwitch?: boolean
  onToggle?: (value: string) => void
  initialValue?: string
}

const defaultProps: ChildProps = {
  header: undefined,
  description: undefined,
  showSwitch: true,
  initialValue: '0',
}

const PageHeaderWithSwitch: React.FC<ChildProps> = (props) => {
  return (
    <div className={props.customClassName}>
      <PageHeader
        h1Text={<span className='h5'>{props.header}</span>}
        h2Text={<span className='body2-regular'>{props.description}</span>}
        switchProps={{
          label: '',
          stateLabels: {
            OFF: 'No',
            ON: 'Yes',
          },
          checked: props.initialValue === '0' ? false : true,
          onChange: ({ target: { value } }) => {
            if (value) {
              props.onToggle('1')
            } else {
              props.onToggle('0')
            }
          },
        }}
      />
    </div>
  )
}

PageHeaderWithSwitch.defaultProps = defaultProps

export default PageHeaderWithSwitch
