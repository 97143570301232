import API, { API_URL } from 'api'

export const postOrderMemo = async ({ text, orderId }) => {
  return await API.post(API_URL.POST_ORDER_MEMO({ orderId }), { text })
}

export const acknowledgeMemo = async ({ memo_id, body }) => {
  return await API.put(API_URL.PUT_MEMO_ACKNOWLEDGED({ memo_id }), body)
}

export const postIssueMemo = async ({ module, moduleId, issueId, text }) => {
  return await API.post(
    API_URL.POST_ISSUE_MEMO({ module, moduleId, issueId }),
    { text }
  )
}
