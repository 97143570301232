import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import FilterChips from 'organisms/filterChips'
import {
  setFilters,
  setModuleOptions,
  setCreatedAt,
  resetAllFilters,
} from 'store/jobsSlice'
import {
  handleCheckboxFilterChange,
  handleDateFilterChange,
  handleRadioFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { JOBS_LIST } from './constants'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'

type ChildProps = {
  params: any
}

const JobsFilter: React.FC<ChildProps> = ({ params }) => {
  const [moduleOptionsCounter, setModuleOptionsCounter] = useState(0)
  const [createdAtCounter, setCreatedAtCounter] = useState(0)
  const [hasErrorsCounter, setHasErrorsCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const moduleOptionsCheckBoxOptions = useSelector(
    (state: RootState) => state.jobs.moduleOptions
  )

  const createdAt = useSelector(
    (state: RootState) => state.jobs.createdAt
  )

  const isAFilterApplied = useMemo(() => {
    return moduleOptionsCounter + hasErrorsCounter + createdAtCounter > 0
  }, [moduleOptionsCounter, hasErrorsCounter, createdAtCounter])

  // Define respective filterKey fns
  const filterActions = {
    module_name: {
      action: setModuleOptions,
      options: moduleOptionsCheckBoxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setModuleOptionsCounter,
      chipName: JOBS_LIST.JOB.NAME,
      useLabelForChip: true,
    },
    has_errors: {
      setFilterCount: setHasErrorsCounter,
      filterType: FILTERS.TYPES.RADIO,
      label: JOBS_LIST.HAS_ERRORS.FILTER_MODAL_NAME,
      chipName: JOBS_LIST.HAS_ERRORS.FILTER_MODAL_NAME
    },
    created_at_gte: {
      setFilterCount: setCreatedAtCounter,
      dateRange: createdAt,
      action: setCreatedAt,
      filterType: FILTERS.TYPES.DATE,
      chipName: JOBS_LIST.CREATED_AT.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${JOBS_LIST.JOB.NAME}-filter`}
          label={JOBS_LIST.JOB.NAME}
          data={moduleOptionsCheckBoxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: JOBS_LIST.JOB.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={moduleOptionsCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: JOBS_LIST.JOB.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <RadioGroupFilter
          dataTestid={`${JOBS_LIST.HAS_ERRORS.NAME}-filter`}
          label={JOBS_LIST.HAS_ERRORS.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: JOBS_LIST.HAS_ERRORS.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: JOBS_LIST.HAS_ERRORS.PARAM,
              setFilters,
            })
          }}
          value={params?.has_errors?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={hasErrorsCounter > 0 ? hasErrorsCounter?.toString() : ''}
          wrapperClass='mb-3'
        />
        <DateFilter
          dataTestid={`${JOBS_LIST.CREATED_AT.NAME}-filter`}
          label={JOBS_LIST.CREATED_AT.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: JOBS_LIST.CREATED_AT.PARAM_START,
              date,
            })
          }}
          defaultValue={createdAt}
          filterCount={createdAtCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: JOBS_LIST.CREATED_AT.PARAM_START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default JobsFilter
