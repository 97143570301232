import React, { useEffect, useState } from 'react'
import { Table } from 'atoms'
import VariantItem from 'molecules/lineItem/variantItem'
import Status from 'molecules/lineItem/status'
import OrderLinesActionsMenu from '../presenters/orderLinesActions'

type FulfillmentLineItemProps = {
  loading: boolean
  details: any
  reloadOrder: () => void
}

const FulfillmentLineItems: React.FC<FulfillmentLineItemProps> = ({
  loading,
  details,
  reloadOrder,
}) => {
  const [lineItemDetails, setLineItemDetails] = useState<any[]>([])

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const formatData = ({ order_lines }) => {
    const formattedData = order_lines.map(
      ({
        id,
        variant,
        quantity,
        status,
        quantity_open,
        quantity_shipped,
        quantity_canceled,
        backordered_until,
      }) => {
        return {
          id,
          data: {
            Item: {
              onRender: () => (
                <VariantItem variant={variant} showInventoryDetailsModal />
              ),
            },
            Ordered: {
              value: quantity,
            },
            Open: {
              value: quantity_open,
            },
            Shipped: {
              value: quantity_shipped,
            },
            Cancelled: {
              value: quantity_canceled,
            },
            Status: {
              onRender: () => (
                <Status
                  line={{
                    status: status,
                    backordered_until: backordered_until,
                  }}
                  hideDate
                />
              ),
            },
            Actions: {
              onRender: () => (
                <OrderLinesActionsMenu
                  reloadOrder={reloadOrder}
                  orderId={details.id}
                  status={status}
                  variant={variant}
                  lineId={id}
                  lineBackorderedUntil={backordered_until}
                />
              ),
            },
          },
        }
      }
    )

    setLineItemDetails(formattedData)
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Item',
      width: 400,
    },
    {
      sortable: false,
      title: 'Ordered',
      width: 100,
      pivotPosition: 'right',
    },
    {
      sortable: false,
      title: 'Open',
      width: 100,
    },
    {
      sortable: false,
      title: 'Shipped',
      width: 100,
    },
    {
      sortable: false,
      title: 'Cancelled',
      width: 100,
    },
    {
      sortable: false,
      title: 'Status',
      width: 120,
    },
    {
      title: 'Actions',
      width: 200,
    },
  ]

  return (
    <Table
      columns={tableColumns}
      loading={loading}
      selectable={false}
      data={lineItemDetails}
    />
  )
}

export default FulfillmentLineItems
