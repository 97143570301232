import API, { API_URL } from 'api'

export const getPlatformAccounts = async ({ params = {} }) => {
  return await API.get(API_URL.PLATFORM_ACCOUNTS(), { params })
}

export const createPlatformAccounts = async ({ body }) => {
  return await API.post(API_URL.PLATFORM_ACCOUNTS(), body)
}

export const updatePlatformAccounts = async ({ body, id }) => {
  return await API.patch(API_URL.GET_PLATFORM_ACCOUNT_BY_ID({ id }), body)
}

export const getPlatforms = async ({ params = {} }) => {
  return await API.get(API_URL.PLATFORMS(), { params })
}

export const getPlatformAccountById = async ({ id }) => {
  return await API.get(API_URL.GET_PLATFORM_ACCOUNT_BY_ID({ id }))
}

export const pushInventory = async ({ platformAccountId, body }) => {
  return await API.post(API_URL.PLATFORM_ACCOUNT_PUSH_INVENTORY({ platformAccountId }), body)
}
