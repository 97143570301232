export interface DropDownInitialObj {
  id: string
  label: string
  value?: string // value is optional
}

export const dropDownInitialObj: DropDownInitialObj = {
  id: '',
  label: '',
}
