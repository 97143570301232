import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { ORDERS } from 'modules/orders/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    skip_attributes: 1,
  },
  connectionOptions: [],
  statusOptions: ORDERS.RETURN_STATUS.CHECKBOX_OPTIONS,
  // TODO: differentiate between supplier nad mefchant return reasons
  reasonOptions: ORDERS.RETURN_REASONS_SUPPLIER.CHECKBOX_OPTIONS,
  createdOn: null,
  selected: [],
}
const returnsSlice = createSlice({
  name: 'returns',
  initialState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload
    },
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.statusOptions = ORDERS.RETURN_STATUS.CHECKBOX_OPTIONS
      } else {
        state.statusOptions = action.payload
      }
    },
    setReturnReasonOptions(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        state.reasonOptions = ORDERS.RETURN_REASONS_SUPPLIER.CHECKBOX_OPTIONS
      } else {
        state.reasonOptions = action.payload
      }
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.RETURN_CREATED_ON.PARAMS.START]
        delete state.params[ORDERS.RETURN_CREATED_ON.PARAMS.END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.RETURN_CREATED_ON.PARAMS.START] = start
        state.params[ORDERS.RETURN_CREATED_ON.PARAMS.END] = end
        state.createdOn = action.payload
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []
      state.statusOptions = ORDERS.RETURN_STATUS.CHECKBOX_OPTIONS
      state.reasonOptions = ORDERS.RETURN_REASONS_SUPPLIER.CHECKBOX_OPTIONS

      delete state.params[ORDERS.CONNECTION.PARAM]
      delete state.params[ORDERS.RETURN_STATUS.PARAM]
      delete state.params[ORDERS.RETURN_REASONS_SUPPLIER.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED.PARAM]
      delete state.params[ORDERS.RETURN_CREATED_ON.PARAMS.START]
      delete state.params[ORDERS.RETURN_CREATED_ON.PARAMS.END]
      delete state.createdOn
    },
  },
})

export const {
  setParams,
  setSorting,
  setSearch,
  setFilters,
  setConnectionOptions,
  setStatusOptions,
  setReturnReasonOptions,
  setCreatedOn,
  setCurrentPage,
  setSelected,
  resetAllFilters,
} = returnsSlice.actions

export default returnsSlice.reducer
