import { putCompleteTask } from 'api/onboarding'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import { setInfo } from 'store/onboardingSlice'

export const completeTask = async ({ info, key, dispatch, updateInfo }) => {
  if (info[key.toLowerCase()]) {
    return
  }
  await putCompleteTask({
    taskId: info.taskIds[ONBOARDING_TASKS.INFO.SUB_TASKS[key].TASK_NAME],
  })
  dispatch(
    setInfo({
      taskIds: info.taskIds,
      ...updateInfo,
    })
  )
}

export const isAddressNoNeedToUpdate = ({ initValues, address }) => {
  const valueNotMatch = Object.keys(initValues).filter(
    (key) => initValues[key] !== address[key]
  )
  return valueNotMatch.length === 0
}
