import React, { useState, useEffect } from 'react'
import { capitalize } from 'lodash'
import Card2Col from 'molecules/cards/2Col'
import Availability from 'molecules/inventory/availability'
import EstimatedAvailabilityDate from 'molecules/inventory/estimatedAvailabilityDate'

type ItemInventoryProps = {
  item: any
  loading: boolean
  updating: boolean
}

const ItemInventory: React.FC<ItemInventoryProps> = ({
  item,
  loading,
  updating,
}) => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    if (!loading && !updating && item.id) {
      formatData(item)
    }
  }, [loading, updating])

  const formatData = ({ inventory, inventory_policy }) => {
    let formattedData = [
      {
        label: 'Policy',
        value: capitalize(inventory_policy),
      },
      {
        label: 'Availability',
        value: {
          onRender: () => (
            <Availability
              inventory={inventory.inventory}
              discontinued={inventory.discontinued}
            />
          ),
        },
      },
      {
        label: 'Last Update',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate date={inventory.inventory_updated_at} />
          ),
        },
      },
      ,
      {
        label: 'Last Submission',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate
              date={inventory.inventory_last_submitted_at}
            />
          ),
        },
      },
    ]

    // if no inventory, insert an estimated return line to a specific spot
    if (!inventory && !loading) {
      formattedData.splice(3, 0, {
        label: 'Estimated Return',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate
              date={inventory.estimated_availability_date}
              isEstimatedReturn={true}
            />
          ),
        },
      })
    }

    // add empty lines of the default gridRow height to match required height of the card. Number be made a prop in the future for reuse of the component
    const currentRowNumber = formattedData.length
    for (let i = 0; i <= 7 - currentRowNumber; ++i) {
      formattedData.push({
        label: '',
        value: { onRender: () => <div style={{ height: '46px' }}> </div> },
      })
    }
    setData(formattedData)
  }

  return (
    <div className='mt-3 mb-3 width-49'>
      <Card2Col
        loading={loading}
        header='Inventory'
        data={data}
        skeletonRowsNumber={7}
        showCollapse={false}
        open={true}
      />
    </div>
  )
}

export default ItemInventory
