import { InputStepper as InputStepperUI } from '@teamfabric/copilot-ui'
import React, { useEffect, useState } from 'react'

type InputStepperProps = {
  label: string
  max: number
  min: number
  onValueChange: (newVal: number | string) => void
  stepSize: number
  value: number
  ariaLabel?: string
  message?: string
  width?: string
  isDisabled?: boolean
  required?: boolean
  messageType?: 'error' | 'normal' | 'success'
}

const InputStepper: React.FC<InputStepperProps> = (props) => {
  return <InputStepperUI {...props} />
}

export default InputStepper
