import React from 'react'
import { CopilotUIProvider, InlineBanner } from '@teamfabric/copilot-ui'

type BannerProps = {
  label: string | JSX.Element | JSX.Element[]
  type?: string
  variant?: any
  onClose?: () => void
}

const Banner: React.FC<BannerProps> = ({ ...props }) => {
  return (
    <CopilotUIProvider {...props}>
      <div
        style={{
          width: '100%',
        }}
      >
        <InlineBanner {...props} />
      </div>
    </CopilotUIProvider>
  )
}

export default Banner
