import { FC, useEffect, useState } from 'react'
import { getTenantType } from 'api/helper'
import { Link } from 'atoms'

type OrderNumberProps = {
  isRetailerOrderNumberCol: boolean
  retailer_order_number: string
  brand_identifier: string
  platformAccounts: any
  setShowPlatformOrderModal: (value: boolean) => void
}

const OrderNumberPresenter: FC<OrderNumberProps> = ({
  isRetailerOrderNumberCol,
  retailer_order_number,
  brand_identifier,
  platformAccounts,
  setShowPlatformOrderModal,
}) => {
  const { isRetailer } = getTenantType()
  const [isLink, setIsLink] = useState(false)
  const [isText, setIsText] = useState(false)

  useEffect(() => {
    setIsLink(
      isRetailerOrderNumberCol
        ? isRetailer &&
            retailer_order_number !== null &&
            platformAccounts[0]?.platform?.name === 'Shopify'
        : !isRetailer &&
            brand_identifier !== null &&
            platformAccounts[0]?.platform?.name === 'Shopify'
    )
    setIsText(
      isRetailerOrderNumberCol
        ? retailer_order_number !== null
        : brand_identifier !== null
    )
  }, [
    isRetailerOrderNumberCol,
    platformAccounts,
    retailer_order_number,
    brand_identifier,
  ])

  return (
    <>
      {isLink && (
        <Link
          label={retailer_order_number}
          onClick={() => setShowPlatformOrderModal(true)}
          padding={false}
          theme='light'
          mode='inline'
          variant='primary'
        />
      )}
      {isText && (
        <p>
          {isRetailerOrderNumberCol ? retailer_order_number : brand_identifier}
        </p>
      )}
      {!isLink && !isText && <p>Not Set</p>}
    </>
  )
}

export default OrderNumberPresenter
