import { FC } from 'react'
import { Box, Button, Card, Dropdown, GridCol, GridRow, Input } from 'atoms'
import { capitalizeFirstLetter } from 'lib/utils'
import { DropDownInitialObj } from 'lib/utils/initialValue'
import PageHeader from 'molecules/PageHeader'

const directionOptions: DropDownInitialObj[] = [
  {
    id: '1',
    label: 'Import',
    value: 'import',
  },
  {
    id: '2',
    label: 'Export',
    value: 'export',
  },
]

interface BasicSettingsProps {
  name: string
  setName: (value: string) => void
  templateDetails: any
  showCloneTemplate: boolean
  showDownloadSample: boolean
  downloadSample?: () => {}
  setShowCloneModal?: (value: boolean) => void
}

const BasicSettings: FC<BasicSettingsProps> = ({
  name,
  setName,
  templateDetails,
  showCloneTemplate,
  showDownloadSample,
  downloadSample,
  setShowCloneModal,
}) => {
  return (
    <Box dataTestid='template-basic-settings' margin={{ top: 4, bottom: 7 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='100%'>
            <PageHeader
              h1Text={<span className='h5'>Template Settings</span>}
              h2Text={
                <span className='body1-regular grey-600'>
                  Configure basic settings for this template.
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <>
            <GridRow padding={false}>
              <GridCol lg={6} md={6} sm={6}>
                <Input
                  width='100%'
                  label='Template Name'
                  required
                  inputProps={{
                    value: name,
                    onChange: ({ target: { value } }) => setName(value),
                    dataTestid: 'template-name-input',
                  }}
                />
              </GridCol>
            </GridRow>
            <br />
            <GridRow padding={false}>
              <GridCol lg={3} md={3} sm={3}>
                <Input
                  width='100%'
                  label='Data type'
                  inputProps={{
                    disabled: true,
                    value: capitalizeFirstLetter(templateDetails?.data_type),
                    dataTestid: 'template-data-type-input',
                  }}
                />
              </GridCol>
              <GridCol lg={3} md={3} sm={3}>
                <Dropdown
                  width='100%'
                  dataTestid='template-direction-level-dropdown'
                  label='Direction'
                  disabled
                  value={directionOptions.find(
                    (el) =>
                      el.value.toLowerCase() ===
                      templateDetails?.direction.toLowerCase()
                  )}
                  onChange={() => null}
                  options={directionOptions}
                />
              </GridCol>
            </GridRow>
            <br />
            <GridRow padding={false}>
              {showCloneTemplate && setShowCloneModal && (
                <GridCol lg={3} md={3} sm={3}>
                  <Button
                    text='Clone Template'
                    variant='secondary'
                    onClick={() => setShowCloneModal(true)}
                    dataTestid='clone-template-button'
                  />
                </GridCol>
              )}
              {showDownloadSample && downloadSample && (
                <GridCol lg={3} md={3} sm={3}>
                  <Button
                    text='Download Sample'
                    variant='tertiary'
                    onClick={() => downloadSample()}
                    dataTestid='download-sample-button'
                  />
                </GridCol>
              )}
            </GridRow>
          </>
        }
      ></Card>
    </Box>
  )
}

export default BasicSettings
