import React, { useState } from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import MultiLineTextArea from 'atoms/textField/multiLineTextArea'

interface ChildProps {
  handleEdit: (data: any) => void
}

const Notes: React.FC<ChildProps> = ({ handleEdit }) => {
  const [value, setValue] = useState('')

  const handleUpdate = (note) => {
    setValue(note)
    handleEdit(note)
  }

  return (
    <Card
      headerContent={
        <div className='styledCardHeader'>
          <h5 className='heading'>Notes</h5>
          <div className='subHeading'>Add a note for the purchase order</div>
        </div>
      }
      showCollapse={false}
      open
      showDivider={false}
      bodyContent={
        <div className='mb-4'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <MultiLineTextArea
                label='Type your message'
                width='100%'
                inputProps={{
                  value: value,
                  onChange: (e) => handleUpdate(e.target.value),
                  placeholder: 'Message...',
                }}
              />
            </GridCol>
          </GridRow>
        </div>
      }
    />
  )
}

export default Notes
