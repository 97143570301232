import React, { useState, useEffect, useMemo } from 'react'
import { find, upperFirst } from 'lodash'
import { tenantDetails, getTenantType } from 'api/helper'
import { formatDate } from 'lib/utils/date/formatDate'
import Badge from 'atoms/badge/badge'
import Card4Columns from 'molecules/cards/4Columns'
import CardWithTable from 'molecules/cards/cardWithTable'
import {
  FulfillmentSlaPresenter,
  NetPriceMethodPresenter,
  PaymentTermsPresenter,
  IntegrationTypePresenter,
  PackingSlipPresenter,
  ContactPresenter,
} from 'molecules/connections/presenters'
import UpdateDetailsModal from '../modals/UpdateDetails'
import UpdateSettingsModal from '../modals/UpdateSettings'
import UpdateFulfillmentSlaModal from '../modals/UpdateFulfillmentSla'
import { EditableSettingsOptions } from '../constants'
import UpdateConnectionFees from '../modals/UpdateConnectionFees'
import AddNoteModal from '../modals/AddNote'

type SummaryDetailsProps = {
  details: any
  loading: boolean
  reload?: () => {}
}

const SummaryDetails: React.FC<SummaryDetailsProps> = ({
  details,
  loading,
  reload,
}) => {
  const [connectionDetails, setConnectionDetails] = useState<any[]>([])
  const [paymentSettings, setPaymentSettings] = useState<any[]>([])
  const [contactsData, setContactsData] = useState<any[]>([])
  const [slaData, setSlaData] = useState<any[]>([])
  const [settings, setSettings] = useState<any[]>([])
  const [adjustments, setAdjustments] = useState<any[]>([])
  const [notes, setNotes] = useState<any[]>([])
  // edit modal states
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false)
  const [showUpdateSettingsModal, setShowUpdateSettingsModal] = useState(false)
  const [showUpdateFulfillmentSlaModal, setShowUpdateFulfillmentSlaModal] =
    useState(false)
  const [showUpdatePaymentSettingsModal, setShowUpdatePaymentSettingsModal] =
    useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  // context id either retailer id or supplier id
  const { id } = tenantDetails()
  const { isRetailer } = getTenantType()

  useEffect(() => {
    formatData(details)
  }, [loading, details])

  const getLabel = (option) => {
    const str = option.name ? option.name : option.code.replace(/_/g, ' ')
    return str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())
  }

  const CatalogImportMethod = useMemo(() => {
    let type = '---'
    if (!loading && details && Object.keys(details.options).length > 0) {
      const isProposal = find(details.options, [
        'code',
        'merchandise_via_proposals',
      ])
      const isImportRequest = find(details.options, [
        'code',
        'merchandise_via_import_request',
      ])
      type = isProposal
        ? 'Proposals'
        : isImportRequest
        ? 'Import Request'
        : '--'
    }
    return type
  }, [loading, details])

  const formatData = ({
    brand,
    retailer,
    options,
    intro_call_date,
    connection_adjustments,
    retailer_identifier,
    level,
    memos,
  }) => {
    let formattedConnectionDetailsData = [
      {
        label: isRetailer ? 'Supplier' : 'Retailer',
        value: isRetailer ? brand?.name : retailer?.name,
      },
      {
        label: 'Supplier #',
        value: isRetailer
          ? retailer_identifier
            ? retailer_identifier
            : '---'
          : id,
      },
      {
        label: isRetailer ? 'fabric Supplier ID' : 'Retailer ID',
        value: isRetailer ? brand?.id : retailer?.id,
      },
      {
        label: 'Integration Type',
        value: <IntegrationTypePresenter connection={details} />,
      },
    ]

    if (isRetailer) {
      const retailerFields = [
        {
          label: 'Connection Level',
          value:
            level === 'products_only'
              ? 'Products Only'
              : 'Transactions & Products',
        },
        {
          label: 'Catalog Import Method',
          value: CatalogImportMethod,
        },
        {
          label: 'Packing Slip Template',
          value: <PackingSlipPresenter connection={details} />,
        },
      ]
      formattedConnectionDetailsData = [
        ...formattedConnectionDetailsData,
        ...retailerFields,
      ]
    }

    if (!isRetailer) {
      const brandFields = [
        {
          label: 'Catalog Import Method',
          value: CatalogImportMethod,
        },
        {
          label: 'Welcome Call Date',
          value: intro_call_date
            ? formatDate({ date: intro_call_date })
            : '---',
        },
        {
          label: 'Packing Slip Template',
          value: <PackingSlipPresenter connection={details} />,
        },
      ]
      formattedConnectionDetailsData = [
        ...formattedConnectionDetailsData,
        ...brandFields,
      ]
    }

    let formattedPaymentDetailsData = [
      {
        label: 'Cost Tracking',
        value: <NetPriceMethodPresenter connection={details} />,
      },
      {
        label: 'Payment Terms',
        value: <PaymentTermsPresenter connection={details} />,
      },
    ]

    let formattedSlaDetailsData = [
      {
        label: 'Fulfillment SLA',
        showInfoIcon: true,
        value: <FulfillmentSlaPresenter connection={details} />,
        tooltipText:
          'The number of days a vendor has to process an order before it is considered late (may be overridden by item level setting).',
      },
    ]

    let formattedSettingDetailsData =
      options &&
      Object.keys(options).map((key) => ({
        label: getLabel(options[key]),
        value: (
          <Badge
            label='Active'
            variant='secondary'
            status='success'
            asset='bullet'
          />
        ),
      }))

    let formattedContactsData = [
      {
        label: 'Primary',
        value: <ContactPresenter connection={details} type='connections' />,
      },
      {
        label: 'Merchandising',
        value: <ContactPresenter connection={details} type='merchandising' />,
      },
      {
        label: 'Fulfillment',
        value: <ContactPresenter connection={details} type='transaction' />,
      },
    ]

    let connectionAdjustments = connection_adjustments?.map((adjustment) => ({
      id: adjustment.id,
      data: {
        Description: { value: adjustment.description },
        Type: { value: upperFirst(adjustment.adjustment_type) },
        Amount: {
          value:
            adjustment.adjustment_amount_type === 'percent'
              ? `${adjustment.adjustment_amount} % per order`
              : `${adjustment.adjustment_amount} per order`,
        },
      },
    }))

    let notesData = memos?.map((memo) => ({
      id: memo.id,
      data: {
        'Date added': {
          value: formatDate({ date: memo?.created_at, withTime: true }),
        },
        'Added by': { value: upperFirst(memo?.created_by?.first_name) },
        'Note': {
          value: memo?.text,
        },
      },
    }))
    setConnectionDetails(formattedConnectionDetailsData)
    setPaymentSettings(formattedPaymentDetailsData)
    setSlaData(formattedSlaDetailsData)
    setSettings(formattedSettingDetailsData)
    setContactsData(formattedContactsData)
    setAdjustments(connectionAdjustments)
    setNotes(notesData)
  }

  const notesTableColumns = [
    {
      sortable: false,
      title: 'Date added',
    },
    {
      sortable: false,
      title: 'Added by',
    },
    {
      sortable: false,
      title: 'Note',
    },
  ]

  const tableColumns = [
    {
      sortable: false,
      title: 'Description',
    },
    {
      sortable: false,
      title: 'Type',
    },
    {
      sortable: false,
      title: 'Amount',
    },
  ]

  return (
    <div className='mt-5'>
      <Card4Columns
        header='Connection details'
        loading={loading}
        data={connectionDetails}
        customClassName='mb-4'
        headerWithButtons={isRetailer}
        onEdit={() => setShowUpdateDetailsModal(true)}
      />
      <Card4Columns
        header='Payment settings'
        loading={loading}
        data={paymentSettings}
        customClassName='mb-4'
        noResultsText='You have no payments configured yet'
        headerWithButtons={isRetailer}
        onEdit={() => setShowUpdatePaymentSettingsModal(true)}
      />
      <Card4Columns
        header='Contacts'
        loading={loading}
        data={contactsData}
        customClassName='mb-4'
        noResultsText='You have no contacts configured yet'
      />
      <Card4Columns
        header="SLA's"
        loading={loading}
        data={slaData}
        customClassName='mb-4'
        noResultsText="You have no SLA's configured yet"
        headerWithButtons={isRetailer}
        onEdit={() => setShowUpdateFulfillmentSlaModal(true)}
      />
      <Card4Columns
        header='Settings'
        loading={loading}
        data={settings}
        customClassName='mb-4'
        noResultsText='You have no settings configured yet'
        headerWithButtons={isRetailer}
        onEdit={() => setShowUpdateSettingsModal(true)}
      />
      <CardWithTable
        header='Connection notes'
        loading={loading}
        tableProps={{
          emptyTableText: 'You have no connection notes yet',
          columns: notesTableColumns,
          data: notes,
        }}
        headerWithButtons={true}
        headerButtonText='Add Note'
        onEdit={() => setShowNotesModal(true)}
        customClassName='mb-4'
      />
      <CardWithTable
        header='Invoice Adjustments'
        loading={loading}
        tableProps={{
          emptyTableText: 'You have no adjustments yet',
          columns: tableColumns,
          data: adjustments,
        }}
      />
      {/* Connection details modal */}
      {showUpdateDetailsModal && (
        <UpdateDetailsModal
          onClose={() => setShowUpdateDetailsModal(false)}
          showModal={showUpdateDetailsModal}
          connectionId={details.id}
          reload={() => reload()}
          initialValues={details}
        />
      )}
      {/* Connection fees modal */}
      <UpdateConnectionFees
        onClose={() => setShowUpdatePaymentSettingsModal(false)}
        showModal={showUpdatePaymentSettingsModal}
        connectionId={details.id}
        reload={() => reload()}
        initialValues={details}
      />
      {/* Connection SLA modal */}
      <UpdateFulfillmentSlaModal
        onClose={() => setShowUpdateFulfillmentSlaModal(false)}
        showModal={showUpdateFulfillmentSlaModal}
        connectionId={details.id}
        reload={() => reload()}
        initialValues={details}
      />
      {/* Connection options settings modal */}
      {showUpdateSettingsModal && (
        <UpdateSettingsModal
          onClose={() => setShowUpdateSettingsModal(false)}
          showModal={showUpdateSettingsModal}
          connectionId={details.id}
          reload={() => reload()}
          initialValues={details}
        />
      )}
      {/* Note modal */}
      {showNotesModal && (
        <AddNoteModal
          onClose={() => setShowNotesModal(false)}
          showModal={showNotesModal}
          connectionId={details.id}
          reload={() => reload()}
          initialValues={details}
        />
      )}
    </div>
  )
}

export default SummaryDetails
