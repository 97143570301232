import React, { useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Input, Button } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { getShopifyOrders, shopifyRetryOrder } from 'api/shopify'

type ChildProps = {

}

const RetryOrderCard: React.FC<ChildProps> = ({

}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [shopifyOrderNumber, setShopifyOrderNumber] = useState(null)
  const [bannerMessage, setBannerMessage] = useState('')
  const [processing, setProcessing] = useState(false)
  const [job, setJob] = useState(null)

  const onRetryOrder = async () => {
    setSaving(true)
    setBannerMessage('')
    const params = {
      order_number: shopifyOrderNumber
    }
    // look for order first...
    try {
      const orderResponse = await getShopifyOrders({ platformAccountId: id, params })
      // if we have exactly 1 result, attempt to retry the order using the Shopify Order ID
      if (orderResponse.data?.length === 1) {
        setProcessing(true)
        const shopifyOrderId = orderResponse.data.length[0].id
        try {
          const body = {
            shopify_order_id: parseInt(shopifyOrderId),
            ignore_order_risk: 1
          }
          const { data } = await shopifyRetryOrder({ platformAccountId: id, body })
          setJob(data)
          showToast({
            label: 'Order import scheduled!',
            isDismissable: true,
            id: '1',
          })
        } catch ({ response }) {
          showToast({
            label: response?.status === 400 && response?.data === 'Invalid Request'
              ? 'An error occurred while attempting to import your order. It is likely that the order Number you supplied is not correct. Please double check your Shopify Order Number and try again.'
              : 'Order import failed!',
            variant: 'error',
            isDismissable: true,
            id: '1',
          })
        }
      } else {
        // no order could be found
        setBannerMessage('Shopify order number not found! Please check your order number and try again.')
      }
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'An error occurred while fetching order, please try again!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setShopifyOrderNumber(null)
      setProcessing(false)
      setSaving(false)
    }

  }

  return (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Retry Order</span>}
            h2Text={
              <span className='body2-regular'>
                Attempt to retry an order import.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            If an order didn't import into fabric, its likely there
            was a problem routing the order to the correct supplier. If you
            have doubled checked the SKUs and the Shopify mappings, you
            can attempt to import the order again by supplying the
            Shopify Order Number.
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <Input
                width='100%'
                label='Enter shopify Order Number'
                required
                inputProps={{
                  name: 'shopifyOrderNumber',
                  value: shopifyOrderNumber,
                  placeholder: 'Shopify Order Number',
                  onChange: ({ target: { value } }) => {
                    setShopifyOrderNumber(value)
                  },
                }}
                maskOptions={{
                  regex: '\\d*(\\.)?\\d*',
                }}
                message={bannerMessage}
                messageType={bannerMessage.length > 0 && 'error'}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onRetryOrder()}
                text='Retry Order'
                variant='secondary'
                isDisabled={
                  saving || !shopifyOrderNumber
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default RetryOrderCard
