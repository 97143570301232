import React from 'react'
import { Card, GridRow, GridCol, Button, ToolTip, Icon } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'

type ChildProps = {
  loading?: boolean
  header: any
  data: any[]
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  customClassName?: string
  noResultsText?: string
  headerWithButtons?: boolean
  headerButtonText?: string
  onEdit?: () => void
}

const Card4Columns: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return (
      <h5 className='h5 grey-500 textAlignCenter'>
        {props.noResultsText ? props.noResultsText : 'You have no results'}
      </h5>
    )
  }

  const RowContent = () => {
    return (
      <GridRow padding={false}>
        {props.data?.map(({ label, value, showInfoIcon, tooltipText }, key) => (
          <GridCol sm={3} key={key}>
            <div className='grey-700 label v-center'>
              <span>{label}</span>
              {showInfoIcon && tooltipText && (
                <ToolTip text={tooltipText}>
                  <div className='ml-1'>
                    <Icon iconName='Info' size={14} />
                  </div>
                </ToolTip>
              )}
            </div>
            {value?.onRender ? (
              <div className='pt-2'>{value.onRender()}</div>
            ) : (
              <p
                className={
                  value === 'Not Specified'
                    ? 'grey-700 body1-regular'
                    : 'grey-1000 body1-regular'
                }
              >
                {value}
              </p>
            )}
          </GridCol>
        ))}
      </GridRow>
    )
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          open={props.open}
        />
      ) : props.headerWithButtons ? (
        <Card
          headerContent={
            <div className='cardHeaderWithButton'>
              <div className='heading'>{props.header}</div>
              <Button
                onClick={() => props.onEdit()}
                text={props.headerButtonText ? props.headerButtonText : 'Edit'}
                variant='secondary'
              />
            </div>
          }
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            props?.data.length ? <RowContent /> : <NoResultContent />
          }
        />
      ) : (
        <Card
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            props?.data.length ? <RowContent /> : <NoResultContent />
          }
        />
      )}
    </div>
  )
}

export default Card4Columns
