import React, { useState, useEffect, useMemo } from 'react'
import { Box, Input, Modal } from 'atoms'
import SelectTemplates from 'molecules/selectDropdowns/selectTemplates'
import SelectRuleset from 'molecules/selectDropdowns/selectRuleset'
import _ from 'lodash'
import { postDepartments } from 'api/departments'
import { useToast } from '@teamfabric/copilot-ui'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectUser from 'molecules/selectDropdowns/selectUser'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  loadDepartments: (quiet?: boolean) => void
}

const CreateDepartmentModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  loadDepartments,
}) => {
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [departmentName, setDepartmentName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(dropDownInitialObj)
  const [templateURL, setTemplateURL] = useState('')
  const [selectedSupplierRuleset, setSelectedSupplierRuleset] =
    useState(dropDownInitialObj)
  const [selectedMerchantRuleset, setSelectedMerchantRuleset] =
    useState(dropDownInitialObj)
  const [selectedMerchandiserUser, setSelectedMerchandiserUser] =
    useState(dropDownInitialObj)
  const [selectedManagerUser, setSelectedManagerUser] =
    useState(dropDownInitialObj)

  const createDepartment = async () => {
    const body = {
      name: departmentName,
      code: _.kebabCase(departmentName),
      owner_user_id: selectedMerchandiserUser?.id,
      manager_user_id: selectedManagerUser?.id,
      template_id: selectedTemplate?.id,
      brand_ruleset_id: selectedSupplierRuleset?.id,
      retailer_ruleset_id: selectedMerchantRuleset?.id,
      sample_template_url: templateURL,
      description: null,
      is_active: 1,
    }
    try {
      setSaving(true)
      await postDepartments({ body })
      setShowModal(false)
      loadDepartments()
      showToast({
        label: 'Department created!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const isReady = useMemo(() => {
    return (
      departmentName !== '' &&
      !!selectedTemplate.id &&
      !!selectedMerchantRuleset.id &&
      !!selectedSupplierRuleset.id &&
      !!selectedMerchandiserUser.id &&
      !!selectedManagerUser.id
    )
  }, [
    departmentName,
    selectedTemplate,
    selectedMerchantRuleset,
    selectedSupplierRuleset,
    selectedMerchandiserUser,
    selectedManagerUser,
  ])

  return (
    <Modal
      headerText='Create Department'
      description=''
      size='small'
      onClose={() => setShowModal(false)}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => setShowModal(false),
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => createDepartment(),
          text: 'Save',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => setShowModal(false)}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Department Name'
          required
          inputProps={{
            value: departmentName,
            onChange: ({ target: { value } }) => setDepartmentName(value),
          }}
        />
        <SelectTemplates
          handleUpdate={(option) => setSelectedTemplate(option)}
          selectedTemplate={selectedTemplate}
          direction='import'
          fieldLabel='Product Import Template'
        />
        <SelectRuleset
          handleUpdate={(option) => setSelectedSupplierRuleset(option)}
          selectedRuleset={selectedSupplierRuleset}
          fieldLabel='Supplier Ruleset'
          fieldPlaceholder='Select the attribute validations that suppliers must meet'
        />
        <SelectRuleset
          handleUpdate={(option) => setSelectedMerchantRuleset(option)}
          selectedRuleset={selectedMerchantRuleset}
          fieldLabel='Merchant Ruleset'
          fieldPlaceholder='Select the attribute validations that you must meet'
        />
        <SelectUser
          handleUpdate={(option) => setSelectedMerchandiserUser(option)}
          selectedUser={selectedMerchandiserUser}
          fieldLabel='Department Merchandiser'
        />
        <SelectUser
          handleUpdate={(option) => setSelectedManagerUser(option)}
          selectedUser={selectedManagerUser}
          fieldLabel='Department Manager'
        />
        <Input
          width='100%'
          label='Sample template URL'
          inputProps={{
            value: templateURL,
            onChange: ({ target: { value } }) => setTemplateURL(value),
          }}
        />
      </Box>
    </Modal>
  )
}

export default CreateDepartmentModal
