import { ONBOARDING_TASKS, WELCOME_TASKS } from 'modules/onboarding/constants'
import {
  setQuestionnaire,
  setCall,
  setInfo,
  setNotifications,
  setIntegration,
} from 'store/onboardingSlice'
import { AssignedTaskProps } from 'modules/onboarding/utils/types'
import {
  getBigCommerceTaskNum,
  getEdiProcessNum,
} from 'modules/onboarding/utils'

const INTEGRATION_METHOD =
  ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS

/**
 * Reset Slices
 * @param param0
 */
export const resetSliceByCurrentStepParent = ({ dispatch, state }) => {
  const questionnaire = state.onboarding.questionnaire
  const call = state.onboarding.call
  const info = state.onboarding.info
  const notifications = state.onboarding.notifications
  const currentStep = state.onboarding.currentStep
  const integration = state.onboarding.integration
  switch (currentStep.parent) {
    case ONBOARDING_TASKS.QUESTIONNAIRE.VALUE:
    case ONBOARDING_TASKS.CALL.VALUE:
      dispatch(
        setQuestionnaire({
          taskId: questionnaire.taskId,
          processNum: 0,
          experienceLevel: '',
          integrationMethod: '',
          completed: false,
        })
      )
      dispatch(
        setCall({
          taskId: call.taskId,
          processNum: 0,
          completed: false,
        })
      )
      break
    case ONBOARDING_TASKS.INFO.VALUE:
      dispatch(
        setInfo({
          taskIds: info.taskIds,
          processNum: 0,
          [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: false,
          [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: false,
          [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: false,
          completed: false,
        })
      )
      break
    case ONBOARDING_TASKS.NOTIFICATIONS.VALUE:
      dispatch(
        setNotifications({
          taskId: notifications.taskId,
          processNum: 0,
          transactionDone: false,
          digestDone: false,
          completed: false,
        })
      )
      break
    case ONBOARDING_TASKS.INTEGRATION.VALUE:
      dispatch(
        setIntegration({
          taskId: integration.taskId,
          selectedIntegrationInfo: {
            taskIds: {
              ['self-serve-edi']: '',
              bigcommerce: '',
              shipstation: '',
            },
            variants: [],
            orderIds: [],
          },
          processNum: 0,
          introDone: false,
          completed: false,
          selectedMethod: '',
        })
      )
      break
  }
}

const welcomeSliceInit = ({ task, dispatch }) => {
  const isFirst =
    task.completed_at === null && Object.keys(task.info).length === 0
  if (isFirst) {
    dispatch(
      setQuestionnaire({
        taskId: task.id,
        processNum: 0,
        experienceLevel: '',
        integrationMethod: '',
        completed: false,
      })
    )
    dispatch(
      setCall({
        taskId: task.id,
        processNum: 0,
        completed: false,
      })
    )
    return
  }
  dispatch(
    setQuestionnaire({
      taskId: task.id,
      processNum: task.info.integrationMethod !== '' ? 2 : 1,
      experienceLevel: task.info.experienceLevel,
      integrationMethod: task.info.integrationMethod,
      completed:
        task.info.experienceLevel !== '' && task.info.integrationMethod !== '',
    })
  )
  dispatch(
    setCall({
      taskId: task.id,
      processNum: task.completed_at !== null ? 1 : 0,
      completed: task.completed_at !== null,
    })
  )
}

const notificationSliceInit = ({ task, dispatch }) => {
  if (task.completed_at) {
    dispatch(
      setNotifications({
        taskId: task.id,
        processNum: 2,
        transactionDone: true,
        digestDone: true,
        completed: true,
      })
    )
    return
  }
  const isStarted = Object.keys(task.info).length > 0
  dispatch(
    setNotifications({
      taskId: task.id,
      processNum: isStarted ? 1 : 0,
      transactionDone: isStarted,
      digestDone: false,
      completed: false,
    })
  )
}

const infoCodes = [
  ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME,
  ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME,
  ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME,
]

const infoSliceInit = ({ tasks, dispatch }) => {
  let processNum = 0
  const taskIds = {
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: '',
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: '',
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: '',
  }
  const isDone = {
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: false,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: false,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: false,
  }
  tasks
    .filter(({ onboarding_task: { code } }) => infoCodes.includes(code))
    .forEach(({ id, completed_at, onboarding_task: { code } }) => {
      if (completed_at) {
        processNum += 1
      }
      taskIds[ONBOARDING_TASKS.INFO.SUB_TASKS[code.toUpperCase()].TASK_NAME] =
        id
      isDone[ONBOARDING_TASKS.INFO.SUB_TASKS[code.toUpperCase()].TASK_NAME] =
        completed_at !== null
    })
  dispatch(
    setInfo({
      taskIds,
      processNum,
      ...isDone,
      completed: processNum === 3,
    })
  )
}

const integrationSliceInit = ({
  selectedMethod,
  dispatch,
  tasks,
  integration,
}) => {
  const result = {
    taskId: '',
    processNum: 0,
    introDone: false,
    completed: false,
    selectedIntegrationInfo: {
      taskIds: {
        ['self-serve-edi']: '',
        bigcommerce: '',
        shipstation: '',
      },
      variants: [],
      orderIds: [],
    },
    selectedMethod,
  }
  const intro = tasks.find(
    ({ onboarding_task: { code } }) =>
      code === ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.CODE
  )
  if (intro) {
    result.taskId = intro.id
    result.processNum = intro.completed_at !== null ? 1 : 0
    result.introDone = intro.completed_at !== null
    if (
      selectedMethod ===
      ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.API
        .TASK_NAME
    ) {
      const isDone = intro.info?.api
      result.selectedIntegrationInfo = {
        ...integration?.selectedIntegrationInfo,
        completed_at: isDone,
      }
      result.completed = isDone !== ''
    }
  }
  const methods = tasks.find(
    ({ onboarding_task: { code } }) => code === selectedMethod
  )
  if (selectedMethod === INTEGRATION_METHOD.BIGCOMMERCE.TASK_NAME) {
    result.selectedIntegrationInfo.taskIds = {
      ...result.selectedIntegrationInfo.taskIds,
      bigcommerce: methods.id,
    }
    result.selectedIntegrationInfo = {
      ...integration.selectedIntegrationInfo,
      ...methods.info,
    }
    result.processNum = getBigCommerceTaskNum({
      result,
      info: methods.info,
      completed_at: methods.completed_at,
    })
    result.completed = methods.completed_at !== null
  } else if (selectedMethod === INTEGRATION_METHOD.EDI.TASK_NAME) {
    result.selectedIntegrationInfo.taskIds = {
      ...result.selectedIntegrationInfo.taskIds,
      ['self-serve-edi']: methods.id,
    }
    result.selectedIntegrationInfo = {
      ...integration.selectedIntegrationInfo,
      ...methods.info,
    }
    result.completed = methods.completed_at !== null
    result.processNum = getEdiProcessNum(integration.selectedIntegrationInfo)
  }
  dispatch(setIntegration(result))
}

export const initSlices = ({
  selectedMethod,
  tasks,
  dispatch,
  integration,
}) => {
  tasks.forEach((task: AssignedTaskProps) => {
    infoSliceInit({
      tasks,
      dispatch,
    })
    integrationSliceInit({
      selectedMethod,
      dispatch,
      tasks,
      integration,
    })
    switch (task.onboarding_task.code) {
      case WELCOME_TASKS.CODE:
        // check info
        welcomeSliceInit({
          task,
          dispatch,
        })
        break
      case ONBOARDING_TASKS.NOTIFICATIONS.CODE:
        // check info
        notificationSliceInit({
          task,
          dispatch,
        })
        break
    }
  })
}
