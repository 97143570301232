import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import {
  JOBS_LIST,
  JOBS_LIST_FILTER_OPTIONS
} from 'modules/jobs/components/list/constants'

const initialState = {
  params: {
    short: 1,
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  moduleOptions: JOBS_LIST_FILTER_OPTIONS.MODULE_OPTIONS,
  createdAt: null,
}

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setModuleOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.moduleOptions = JOBS_LIST_FILTER_OPTIONS.MODULE_OPTIONS
      } else {
        state.moduleOptions = action.payload
      }
    },
    setCreatedAt(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[JOBS_LIST.CREATED_AT.PARAM_START]
        delete state.params[JOBS_LIST.CREATED_AT.PARAM_END]
        delete state.createdAt
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[JOBS_LIST.CREATED_AT.PARAM_START] = start
        state.params[JOBS_LIST.CREATED_AT.PARAM_END] = end
        state.createdAt = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.moduleOptions = JOBS_LIST_FILTER_OPTIONS.MODULE_OPTIONS
      delete state.params[JOBS_LIST.JOB.PARAM]
      delete state.params[JOBS_LIST.HAS_ERRORS.PARAM]
      delete state.params[JOBS_LIST.CREATED_AT.PARAM_START]
      delete state.params[JOBS_LIST.CREATED_AT.PARAM_END]
    },
  },
})

export const {
  setFilters,
  setCurrentPage,
  setModuleOptions,
  setCreatedAt,
  resetAllFilters,
} = jobsSlice.actions

export default jobsSlice.reducer
