// TODO: Unify table functions with others (add pagination etc.), after that implement SearchBar instead of plain Search, refer src/pages/orders/invoices.tsx
import React, { useEffect, useState } from 'react'
import { exportShippingTable, getShipments } from 'api/shipments'
import ExportModal from '../../../molecules/modals/exportModal'
import TrackingTable from '../components/table/tracking/trackingTable'
import { MODALS } from 'molecules/modals/constants'
import Filters from 'modules/orders/components/filters/tracking'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchBar from 'molecules/search/searchBarTable'
import { BUTTONS, PAGE_TYPE, SEARCH } from 'lib/constants'
import { DataProps } from '@teamfabric/copilot-ui'
import { setSearch } from 'store/shipmentsSlice'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'

const OrderTrackingTemplate: React.FC = () => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const params = useSelector(
    (state: RootState) => state.shipments.params as any
  )

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getShipments({ params })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <ExportModal
            type={MODALS.EXPORT.TYPES.TRACKING}
            setShowExportModal={setShowExportModal}
            showExportModal={showExportModal}
            exportCall={exportShippingTable}
            params={params}
          />
          <PageHeaderComponent
            h1Text='Tracking'
            primaryButtons={[
              {
                onClick: () => setShowExportModal(true),
                text: BUTTONS.EXPORT.LABEL,
                icon: BUTTONS.EXPORT.LABEL,
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.GENERIC}
            setSearch={setSearch}
            className='mt-4'
            value={params.search}
          />
          <div className='filterDiv'>
            <Filters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.TRACKING}
          />
          <TrackingTable
            data={tableData}
            loading={loading}
            totalRows={totalRows}
            currentPage={params.page}
            currentSortBy={params.order_by}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OrderTrackingTemplate
