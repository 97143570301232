import API, { API_URL } from 'api'

export const getShipments = async ({ params = {} }) => {
  return await API.get(API_URL.GET_SHIPMENTS(), { params })
}

export const exportShippingTable = async ({ params }) => {
  return await API.get(API_URL.EXPORT_SHIPMENTS(), { params })
}

export const getShipmentDetail = async ({ id }) => {
  return await API.get(API_URL.GET_SHIPMENTS_DETAIL({ id }))
}

export const getPackingSlip = async ({ id }) => {
  return await API.get(API_URL.GET_SHIPMENTS_PACKING_SLIP({ id }))
}
