import { deleteAssignTask, resetAssignTask } from 'api/onboarding'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'

export const resetInfoTasks = async ({ currentStep, process }) => {
  const infoIds = process[currentStep.parent].taskIds
  if (
    infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME] === '' ||
    infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME] === '' ||
    infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME] === ''
  ) {
    return
  }
  await Promise.all([
    resetAssignTask({
      taskId: infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME],
      body: { info: {} },
    }),
    resetAssignTask({
      taskId: infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME],
      body: { info: {} },
    }),
    resetAssignTask({
      taskId: infoIds[ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME],
      body: { info: {} },
    }),
  ])
}

export const resetIntegrationTask = async ({ integration }) => {
  if (
    integration.selectedMethod !==
      ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.API
        .TASK_NAME &&
    integration.selectedIntegrationInfo.taskIds[integration.selectedMethod] !==
      ''
  ) {
    // API doesn't have assigned task to delete
    await deleteAssignTask({
      taskId:
        integration.selectedIntegrationInfo.taskIds[integration.selectedMethod],
    })
  }
  const taskId = integration.taskId
  if (taskId !== '') {
    await resetAssignTask({ taskId, body: { info: {} } })
  }
}
