const ProposalStatus = ({ status, acknowledgedAt, requirePricingApproval }) => {
  if (status === 'completed') {
    if (acknowledgedAt === null) {
      return 'Ready to Add'
    } else {
      return 'Completed'
    }
  }
  if (status === 'approved') {
    if (requirePricingApproval) {
      return 'Pending Approval'
    } else {
      return 'Ready to Add'
    }
  }
  switch (status) {
    case 'proposed':
      return 'In Review'
    case 'revised':
      return 'Revised'
    case 'pricing_approved':
      return 'Pending Approval'
    case 'declined':
      return 'Declined'
    case 'archived':
      return 'Archived'
    default:
      return 'Draft'
  }
}

export default ProposalStatus
