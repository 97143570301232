import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { DropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  handleUpdate?: (data: any) => void
  fieldLabel?: string
  validatorType?: string
}

const validatorTypeOptions: DropDownInitialObj[] = [
  { label: 'In', id: 'in' },
  { label: 'Not In', id: 'not_in' },
  { label: 'Exact', id: 'exact' },
  { label: 'Max Length', id: 'max_length' },
  { label: 'Min Length', id: 'min_length' },
  { label: 'Max Value', id: 'max_value' },
  { label: 'Min Value', id: 'min_value' },
  { label: 'Integer Only', id: 'integer_only' },
  { label: 'Numeric Only', id: 'numeric_only' },
  { label: 'Alpha Only', id: 'alpha_only' },
  { label: 'Alphanumeric Only', id: 'alphanumeric_only' },
  { label: 'Allowed Characters', id: 'allowed_characters' },
]

const SelectRuleValidatorType: React.FC<ChildProps> = ({
  handleUpdate,
  fieldLabel,
  validatorType,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    validatorTypeOptions.find((el) => el.id === validatorType)
  )
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  useEffect(() => {
    if (!initialEffectRun && validatorType) {
      const selectedType = validatorTypeOptions.find(
        (el) => el.id === validatorType
      )
      handleUpdate(selectedType)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, validatorType])

  return (
    <Dropdown
      dataTestid='rule-validator-type-dropdown'
      required
      label={fieldLabel || ''}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        const selected = validatorTypeOptions.find((el) => el.id === option.id)
        setSelectedOption(selected)
        handleUpdate(option)
      }}
      options={validatorTypeOptions}
      placeholder='Select type'
    />
  )
}

export default SelectRuleValidatorType
