import { SkeletonH5 as SkeletonH5UI } from '@teamfabric/copilot-ui'

type SkeletonH5Props = {
  variant?: 'light'
  width: string
  style?: any
}

const SkeletonH5: React.FC<SkeletonH5Props> = ({ ...props }) => {
  return (
    <div style={props.style}>
      <SkeletonH5UI {...props} />
    </div>
  )
}

export default SkeletonH5
