import React from 'react'
import Badge from 'atoms/badge/badge'

type ChildProps = {
  inventory: string
}

const OnHand: React.FC<ChildProps> = ({ inventory }) => {
  return (
    <>
      {Number(inventory) == 999 ? (
        <Badge label={inventory} variant='primary' status='error' />
      ) : (
        <span className='ui-card-content-style-match'>{inventory}</span>
      )}
    </>
  )
}

export default OnHand
