import { ORDERS } from 'modules/orders/constants'
import { PRODUCTS } from 'modules/products/constants'
import {
  handleDateChange,
  handleSelectedChange,
  removeCheckboxOption,
} from '../singleFilters'
import { getConnections } from 'api/connection'

const date = (filterActions, filterKey) => {
  const { setLocalState, date, label, setFilterCount } =
    filterActions[filterKey]
  let parsedDefaultValue = {
    value: null,
    dateRange: {
      from: null,
      to: null,
    },
  }
  parsedDefaultValue.value = date?.value
  parsedDefaultValue.dateRange.from = new Date(date?.dateRange?.from)
  parsedDefaultValue.dateRange.to = new Date(date?.dateRange?.to)

  return {
    fieldLabel: label,
    handleResetClick: () => {
      setLocalState(null)
      setFilterCount(0)
    },
    params: {
      onChange: (date) => handleDateChange(date, setLocalState, setFilterCount),
    },
    type: ORDERS.FILTER_MODAL.ITEM_TYPES.DATE,
    value: parsedDefaultValue.value ? parsedDefaultValue : '',
  }
}

const radio = (filterActions, filterKey) => {
  const { setLocalState, selectedValue, label, setFilterCount } =
    filterActions[filterKey]
  return {
    fieldLabel: label,
    handleResetClick: () => {
      setLocalState(null)
      setFilterCount(0)
    },
    params: {
      name: label,
      onChange: (e) => {
        setLocalState(e.target.value)
        setFilterCount(1)
      },
      options: [
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
      ],
      value: selectedValue,
    },
    type: ORDERS.FILTER_MODAL.ITEM_TYPES.RADIO,
  }
}

const simpleCheckbox = (filterActions, filterKey) => {
  const { label, setLocalState, options, setFilterCount, resetState } =
    filterActions[filterKey]
  return {
    type: ORDERS.FILTER_MODAL.ITEM_TYPES.CHECKBOX,
    fieldLabel: label,
    handleResetClick: () => {
      setLocalState(resetState), setFilterCount(0)
    },
    params: {
      isFlat: true,
      onChange: (id, selected) =>
        handleSelectedChange(
          id,
          setLocalState,
          options,
          setFilterCount,
          selected
        ),
      width: '450px',
      checkboxTree: options,
    },
  }
}

// TODO: this is still WIP - This component doesn't yet have the ability to show results of a server-side search in a dropdown, UI Team will take up the implementation
const serverSideSearchWithCheckbox = (
  label: string,
  setterHook: (options: any) => void,
  checkBoxOptions: any,
  params: any
) => {
  const search = async (value, fetchDataCall) => {
    if (value) {
      // TODO: set some sort of loading
      const { data: data } = await fetchDataCall({
        params: { search: value, ...params },
      })
      console.log('data: ', data)
      // TODO: feed data to a data formatter and to a dropdown hopefully
      // setResults(data?.results)
      // setLoadingResults(false)
    }
  }

  const fetchDataBasedOnFilterType = (searchText) => {
    if (label === PRODUCTS.CONNECTIONS.NAME) {
      search(searchText, getConnections)
    }
  }

  return {
    type: ORDERS.FILTER_MODAL.ITEM_TYPES.CHECKBOX,
    fieldLabel: label,
    alwaysShowSearchHeader: true,
    handleResetClick: () => setterHook([]),
    onSearchClicked: (searchText) => {
      fetchDataBasedOnFilterType(searchText)
    },
    onSearchChange: (searchText) => {
      fetchDataBasedOnFilterType(searchText)
    },
    params: {
      isFlat: true,
      onChange: (id) => {
        removeCheckboxOption(id, setterHook, checkBoxOptions)
      },
      width: '450px',
      checkboxTree: checkBoxOptions,
    },
  }
}

export { date, radio, simpleCheckbox, serverSideSearchWithCheckbox }
