import React, { useEffect, useState } from 'react'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import Dropdown from 'atoms/dropdown/dropdown'
import { Input } from '@teamfabric/copilot-ui'

type ItemInfoProps = {
  item: any
  loading: boolean
  onProductInventoryChange: (inventory: number[], status: any) => void
}

const UpdateInventory: React.FC<ItemInfoProps> = ({
  item,
  loading,
  onProductInventoryChange,
}) => {
  const [data, setItemInfoData] = useState<any[]>([])
  const [inputValues, setInputValues] = useState<number[]>([])
  const [selectedStatus, setSelectedStatus] = useState<any>()

  const statusOptions = [
    { id: 'available', label: 'Available', value: 0 },
    { id: 'discontinued', label: 'Discontinued', value: 1 },
  ]

  useEffect(() => {
    formatProductDetailData(item)
    if (item?.inventory?.discontinued !== undefined) {
      const value = item.inventory.discontinued ? 1 : 0
      const option = statusOptions.find((option) => option.value === value)
      setSelectedStatus(option)
    }
    const inventoryValues = item.inventory.locations.map(
      (location) => location.inventory || 0
    )
    setInputValues(inventoryValues)
  }, [item, loading])

  useEffect(() => {
    if (selectedStatus === 1) {
      setInputValues([])
    }
    onProductInventoryChange(inputValues, selectedStatus)
  }, [selectedStatus, inputValues])

  const formatProductDetailData = ({ id, brand, parent_identifier }) => {
    let formattedData = [
      {
        label: 'Item ID',
        value: id,
      },
      {
        label: 'Supplier',
        value: brand?.name,
      },
      {
        label: 'Supplier product SKU',
        value: parent_identifier ? parent_identifier : '---',
      },
    ]
    setItemInfoData(formattedData)
  }

  const handleInputChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) {
      value = inputValues[index] ? inputValues[index].toString() : ''
    }

    const numValue = Number(value)
    const updatedValues = [...inputValues]
    updatedValues[index] = numValue >= 0 ? numValue : 0
    setInputValues(updatedValues)
  }

  const totalInventory = inputValues.reduce((acc, val) => acc + (val || 0), 0)

  return (
    <>
      <div className='mb-4'>
        <ModalCard2Col loading={loading} header='Item details' data={data} />
        <br />
        <Dropdown
          label='Status'
          width='100%'
          value={selectedStatus}
          options={statusOptions}
          onChange={(option: any) => setSelectedStatus(option)}
        />
        <br />
        <div className='space-between width-100'>
          <p>Location</p>
          <p>On Hand</p>
        </div>
        {item.inventory.locations.map((location, index) => (
          <div key={location.id} className='space-between width-100'>
            <p>{location.nickname}</p>
            <Input
              width='50%'
              required={false}
              label={''}
              maskOptions={{
                regex: '\\d*(\\.)?\\d*',
              }}
              inputProps={{
                value: inputValues[index] ? inputValues[index].toString() : '',
                onChange: (e) => handleInputChange(index, e.target.value),
                disabled: selectedStatus?.value === 1,
              }}
            />
          </div>
        ))}
        <br />
        <div className='space-between width-100'>
          <p>
            <strong>Total On Hand</strong>
          </p>
          <p>
            <strong>{totalInventory}</strong>
          </p>
        </div>
      </div>
    </>
  )
}

export default UpdateInventory
