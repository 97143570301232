import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, PageHeader } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const ConnectionLevel: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [preference, setPreference] = useState(null)

  const defaultConnectionLevel = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_connection_level'])
    }
    return null
  }, [preferences, loading])

  const preferenceOptions = useMemo(() => {
    return (
      defaultConnectionLevel?.options?.map((option) => ({
        id: option.id,
        label: option.name,
        value: option.value,
      })) || []
    )
  }, [defaultConnectionLevel])

  useEffect(() => {
    if (defaultConnectionLevel) {
      setPreference(defaultConnectionLevel)
      setOptions(preferenceOptions)

      let selectedPreferenceOption = preferenceOptions.find(
        (el) => el.value === 'transactions_and_products'
      )
      if (defaultConnectionLevel.retailer_preference) {
        selectedPreferenceOption = preferenceOptions.find(
          (el) => el.value === defaultConnectionLevel.retailer_preference.value
        )
      }
      setSelectedOption(selectedPreferenceOption)
    }
  }, [defaultConnectionLevel, preferenceOptions])

  const handleUpdate = (option) => {
    const json = {
      preference: { id: preference.id },
      option: { id: option.id },
    }
    handleChange(json)
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Default Connection Level</span>}
              h2Text={
                <span className='body2-regular'>
                  If you plan to onboard suppliers with transactional
                  capabilities (i.e. products, inventory, orders, fulfillments,
                  etc.), select Transactions & Products. If you're only using
                  fabric Marketplace for product onboarding, select Products
                  Only
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <Dropdown
            required
            label='Select a type'
            width='50%'
            value={selectedOption}
            onChange={(option: any) => {
              setSelectedOption(option)
              handleUpdate(option)
            }}
            options={options}
            placeholder='Select a type'
            disabled={loading}
          />
        }
      ></Card>
    </Box>
  )
}

ConnectionLevel.defaultProps = defaultProps
export default ConnectionLevel
