import { StyledActionButtonWrapper, StyledActionButton } from 'styles/common'

interface Action {
  name: string
  handleClick: (arg: boolean) => void
  isDisabled?: boolean
}

interface ChildProps {
  actions: Action[]
}

export default function MoreActionButtonChildren({ actions }: ChildProps) {
  return (
    <StyledActionButtonWrapper>
      {actions.map((action) => (
        <StyledActionButton
          onClick={() => action.handleClick(true)}
          data-testid={`${action.name}-dropdown-item`}
          key={action.name}
          disabled={action.isDisabled}
        >
          {action.name}
        </StyledActionButton>
      ))}
    </StyledActionButtonWrapper>
  )
}
