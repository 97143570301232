import React, { useEffect, useState } from 'react'
import { PRODUCTS } from 'modules/constants/products'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'
import { useDispatch } from 'react-redux'
import {
  removeParams as removeProposalProductParams,
  setIsRejected as setProposalProductIsRejected,
} from 'store/proposalProductsSlice'
import {
  removeParams as removeProposalItemParams,
  setIsRejected as setProposalItemIsRejected,
} from 'store/proposalItemsSlice'
import FilterChip from 'molecules/filter/filterChip'

type ChildProps = {
  params: any
  isProduct?: boolean
}

const ProposalItemFilters: React.FC<ChildProps> = ({ params, isProduct }) => {
  const [statusCounter, setProposalTypeCounter] = useState(0)
  const [filterChipLabel, setFilterChipLabel] = useState('')

  const removeParams = isProduct
    ? removeProposalProductParams
    : removeProposalItemParams
  const setIsRejected = isProduct
    ? setProposalProductIsRejected
    : setProposalItemIsRejected

  const dispatch = useDispatch()

  useEffect(() => {
    if (params) {
      if (params?.is_rejected == 0) {
        setFilterChipLabel('status: included')
        setProposalTypeCounter(1)
      } else if (params?.is_rejected == 1) {
        setFilterChipLabel('status: removed')
        setProposalTypeCounter(1)
      }
    }
  }, [params])

  const handleFilter = (value: string) => {
    dispatch(setIsRejected(value))
    setProposalTypeCounter(1)
  }

  const handleResetFilter = () => {
    dispatch(removeParams('is_rejected'))
    setFilterChipLabel('')
    setProposalTypeCounter(0)
  }

  return (
    <>
      <RadioGroupFilter
        dataTestid={`${PRODUCTS.PROPOSAL_ITEM_STATUS.NAME_PRODUCT}-filter`}
        label={PRODUCTS.PROPOSAL_ITEM_STATUS.NAME_PRODUCT}
        onChange={(e) => handleFilter(e.target.value)}
        onClear={() => {
          handleResetFilter()
        }}
        value={params?.is_rejected?.toString()}
        options={PRODUCTS.PROPOSAL_ITEM_STATUS.RADIO_OPTIONS}
        filterCount={statusCounter > 0 ? statusCounter?.toString() : ''}
        wrapperClass='mt-3 mb-3'
      />
      {filterChipLabel && (
        <FilterChip
          primaryLabel={filterChipLabel}
          onRemove={() => {
            handleResetFilter()
          }}
        />
      )}
    </>
  )
}

export default ProposalItemFilters
