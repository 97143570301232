import React from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import Dropzone from 'atoms/dropzone'
import { uploadTempfile } from 'api/products'

type ChildProps = {
  setTempfileId: (value: string) => void
}

const UploadFileModal: React.FC<ChildProps> = ({ setTempfileId }) => {
  const showToast = useToast()

  const setFileStatus = ({ setFiles, status, file }) => {
    setFiles((prevState) =>
      prevState.map((item) =>
        item.name === file.name ? { ...item, status } : item
      )
    )
  }

  const onDrop = (acceptedFiles, setFiles) => {
    acceptedFiles.forEach(async (file) => {
      setFileStatus({ setFiles, status: 'loading', file })
      try {
        const {
          data: { id },
        } = await uploadTempfile(file)
        setTempfileId(id)
        setFileStatus({ setFiles, status: 'success', file })
      } catch (error) {
        showToast({
          label: 'Error Uploading File ',
          isDismissable: true,
          id: 'error-uploading-file',
          variant: 'error',
        })
        setFileStatus({ setFiles, status: 'error', file })
      }
    })
  }

  const onRemove = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'loading', file })
    try {
      setFileStatus({ setFiles, status: 'success', file })
      setTempfileId('')
      return true
    } catch (error) {
      showToast({
        label: 'Error Removing File: ' + error,
        isDismissable: true,
        id: 'error-removing-file',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  const onReplace = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'loading', file })
    try {
      const response = await uploadTempfile(file)
      setTempfileId(response?.data?.id)
      setFileStatus({ setFiles, status: 'success', file })
      return true
    } catch (error) {
      showToast({
        label: 'Error Replacing File: ' + error,
        isDismissable: true,
        id: 'error-replacing-file',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  const onRetry = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'retrying', file })
    try {
      await uploadTempfile(file)
      setFileStatus({ setFiles, status: 'success', file })
      return true
    } catch (error) {
      showToast({
        label: 'Error Retrying File Upload: ' + error,
        isDismissable: true,
        id: 'error-retrying-file-upload',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  return (
    <Dropzone
      accept={{
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'text/csv': ['.csv'],
        'text/plain': ['.txt'],
      }}
      dataTestId='import-template-dropzone'
      maxFiles={1}
      onDrop={onDrop}
      onRemove={onRemove}
      onReplace={onReplace}
      onRetry={onRetry}
    />
  )
}

export default UploadFileModal
