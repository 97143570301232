import React, { useEffect, useState } from 'react'
import Badge from 'atoms/badge/badge'

type ChildProps = {
  status: string
  acknowledged_at: string
}

export const getMerchantStatus = ({ status, acknowledged_at }) => {
  // if the shipment is closed, but not acknowledged
  if (status === 'closed' && acknowledged_at === null) {
    return {
      name: 'Sent',
      statusName: 'alert',
    }
  }
  // if the shipment is closed, but acknowledged
  if (status === 'closed' && acknowledged_at !== null) {
    return {
      name: 'Received',
      statusName: 'success',
    }
  }
  return {
    name: 'Not Finalized',
    statusName: 'info',
  }
}

const MerchantStatus: React.FC<ChildProps> = ({ status, acknowledged_at }) => {
  const [label, setLabel] = useState('')
  const [statusVariant, setStatusVariant] = useState('')

  useEffect(() => {
    setMerchantStatus()
  })

  const setMerchantStatus = () => {
    const { name, statusName } = getMerchantStatus({ status, acknowledged_at })
    setLabel(name)
    setStatusVariant(statusName)
  }

  return <Badge label={label} variant='primary' status={statusVariant} />
}

export default MerchantStatus
