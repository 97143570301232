import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentPage,
  setSelected,
  setSelectedProposalProductIds,
} from 'store/proposalProductsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import { Badge, Table } from 'atoms'
import ProductsActionsMenu from '../presenters/productActionsMenu'
import { getBadgeStatus } from 'modules/proposals/utils'
import { approveProposalProduct } from 'api/proposals'
import { useToastUtils } from 'lib/utils/toast'
import { RootState, store } from 'store'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
  options: any
  loadProposal: (quiet?: boolean) => void
  loadProducts: (quiet?: boolean) => void
  handleOpenDrawer: (id: string) => void
}

const ProposalProductsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<DataProps[]>([])
  const { showSuccessToast, showErrorToast } = useToastUtils()

  const selectedProposalProductIds = useSelector(
    (state: RootState) => state.proposalProducts.selectedProposalProductIds
  )

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const tableColumns = [
    {
      title: 'Product',
      width: '50%',
    },
    {
      title: 'Status',
      width: '15%',
    },
    {
      title: '# of Items',
      width: '12%',
    },
    {
      title: 'Comments',
      width: '10%',
    },
    {
      title: '',
      width: '12%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }

  const handleApproveProduct = async ({ productId }) => {
    try {
      const { data } = await approveProposalProduct({
        id: props?.options?.proposalId,
        productId: productId,
      })
      if (data.id) {
        showSuccessToast('Product has been approved successfully.')
      }
      props?.loadProducts?.()
    } catch (error) {
      showErrorToast(
        `Error approving the product: ${error?.response?.data?.detail}`
      )
    }
  }

  const renderProduct = (product) =>
    VariantItem({
      variant: product,
      onClick: () => {
        dispatch(setSelected([]))
        props.handleOpenDrawer(product.id)
      },
      isProduct: true,
    })

  // Separate function for rendering the actions menu
  const renderActionsMenu = (product, status) => (
    <ProductsActionsMenu
      product={product}
      options={props.options}
      isProduct={true}
      loadProposal={props.loadProposal}
      loadProducts={props.loadProducts}
      onApproveProduct={() => handleApproveProduct({ productId: product.id })}
      productStatus={status}
    />
  )

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(({ id, status, product, memos }) => {
      return {
        id: product.id,
        selected: isSelected(product.id),
        data: {
          'Product': {
            onRender: () => renderProduct(product),
          },
          'Status': {
            onRender: () => <Badge {...getBadgeStatus(status)} />,
          },
          '# of Items': { value: product.num_variants },
          'Comments': { value: memos.length },
          '': {
            onRender: () => renderActionsMenu(product, status),
          },
          'proposalProductId': id,
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      selectable={true}
      onRowSelect={(e, rowDetails, selected) => {
        if (selected) {
          dispatch(setSelected([rowDetails.id, ...props.selected]))
          dispatch(
            setSelectedProposalProductIds([
              rowDetails.data.proposalProductId,
              ...selectedProposalProductIds,
            ])
          )
        } else {
          const newSelectedProductIds = props.selected.filter(
            (selectedId) => selectedId !== rowDetails.id
          )
          store.dispatch(setSelected(newSelectedProductIds))
          const newSelectedProposalProductIds =
            selectedProposalProductIds.filter(
              (selectedId) => selectedId !== rowDetails.id
            )
          store.dispatch(
            setSelectedProposalProductIds(newSelectedProposalProductIds)
          )
        }
      }}
      onAllRowSelect={(e, allRowsSelected) => {
        if (allRowsSelected) {
          const allProductIds = tableData.map((el) => el.id)
          const allProposalProductIds = tableData.map(
            (el) => el.data.proposalProductId
          )
          dispatch(setSelected(allProductIds))
          dispatch(setSelectedProposalProductIds(allProposalProductIds))
        } else {
          dispatch(setSelected([]))
          dispatch(setSelectedProposalProductIds([]))
        }
      }}
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
      dataTestidPrefix='proposal-products-'
    />
  )
}

export default ProposalProductsTable
