import React, { useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import {
  getShippingAccounts,
  patchConnectionShippingAccount,
} from 'api/connection'
import { CheckboxGroup } from 'atoms'
import { CheckboxGroupSelectedValue } from '@teamfabric/copilot-ui'

type ShippingMethods = {
  name: string
  id: number
  code: string
}

type formDataTypes = {
  nickname: string
  id: number
  carrier: {
    name: string
  }
  shipping_methods: ShippingMethods[]
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues?: formDataTypes
  action: string
}

const ConnectionCarrierAccountModal: React.FC<ChildProps> = ({
  initialValues,
  action,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [shippingAccounts, setShippingAccounts] = useState([])
  const [options, setOptions] = useState([])
  const [updatedOptions, setUpdatedOptions] = useState([])

  useEffect(() => {
    ;(async () => {
      await fetchAttributes()
    })()
  }, [])

  const fetchAttributes = async () => {
    const { data } = await getShippingAccounts({})
    setShippingAccounts(data.results)
    setLoading(false)
  }

  useEffect(() => {
    if (!loading && initialValues && shippingAccounts.length) {
      const selectedAccount = initialValues?.id
        ? shippingAccounts.find((el) => el.id === initialValues.id)
        : shippingAccounts[0]
      const formattedOptions = selectedAccount.shipping_methods?.map(
        (item1) => {
          const matchedItem = initialValues?.shipping_methods.find(
            (item2) => item1.id === item2.id
          )
          return {
            id: item1.id,
            value: item1.id,
            label: item1.name,
            checked: matchedItem ? true : false,
          }
        }
      )
      setOptions(formattedOptions)
      const label = [initialValues?.carrier?.name, initialValues?.nickname]
      setName(label.join('-'))
    }
  }, [!loading, initialValues, shippingAccounts])

  const SaveCredential = async () => {
    let body = {
      shipping_account: {
        id: initialValues.id,
        shipping_methods: updatedOptions
          .map((el) => {
            if (el.checked) {
              return {
                id: el.value,
              }
            }
          })
          .filter((item) => item),
      },
    }
    try {
      setLoading(true)
      await patchConnectionShippingAccount({
        connectionId: props.connectionId,
        body: body,
      })
    } catch (error) {
      console.error('Error updating connection shipping method:', error)
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText={'Edit connection shipping methods'}
        description='Please select the shipping methods that the connection is authorized to use via 3rd party billing.
        If you don’t see a shipping account or method in the list below, please configure your logistics settings.'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => SaveCredential(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
            isDisabled: !updatedOptions.length,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <div className='pt-6'>
          {options.length ? (
            <CheckboxGroup
              label={name}
              name='methods'
              options={options}
              onChange={(_, currentValue: CheckboxGroupSelectedValue[]) =>
                setUpdatedOptions(currentValue)
              }
            />
          ) : null}
        </div>
      </Modal>
    </>
  )
}

export default ConnectionCarrierAccountModal
