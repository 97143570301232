import React from 'react'
import { GridCol as GridColUI } from '@teamfabric/copilot-ui'

type GridRowProps = {
  alignSelf?: string
  justifySelf?: string
  lg?: any
  md?: any
  sm?: any
  children: React.ReactNode
}

const GridCol: React.FC<GridRowProps> = ({ ...props }) => {
  return <GridColUI {...props} />
}

export default GridCol
