import React, { useEffect, useMemo, useState } from 'react'
import Banner from 'atoms/banner/banner'
import { getTenantType } from 'api/helper'
import { pushOrder } from 'api/orders'
import { SkeletonH4 } from 'atoms/skeleton/index'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  loading: boolean
  details: any
  platformAccounts: any
  reloadOrder: () => void
}

const ShopifyExceptionBanner: React.FC<ChildProps> = ({
  loading,
  details,
  platformAccounts,
  reloadOrder,
}) => {
  const showToast = useToast()
  const [platformAccount, setPlatformAccount] = useState(null)
  const [shouldRenderContent, setShouldRenderContext] = useState(false)
  const [pushOrderJob, setPushOrderJob] = useState(null)
  const { isBrand } = getTenantType()

  const triggerOrderPush = async () => {
    const reqBody = {
      order_id: details.id,
    }
    try {
      const { data } = await pushOrder({
        id: platformAccount.id,
        body: reqBody,
      })
      setPushOrderJob(data)
      showToast({
        label: 'Retrying order push...',
        isDismissable: true,
        id: '1',
      })
      setTimeout(() => {
        reloadOrder()
      }, 5000)
    } catch (error) {
      showToast({
        label: 'Failed order push, please check the job logs...',
        isDismissable: true,
        id: '1',
      })
    }
  }

  useEffect(() => {
    if (!loading && details && platformAccounts) {
      if (platformAccounts.length > 0) {
        setPlatformAccount(platformAccounts[0])
      } else setShouldRenderContext(false)
    }
  }, [platformAccounts])

  const orderPushStatus = useMemo(() => {
    let pushOrderCredential = null
    let didPush = false
    let error = null
    // TO:DO - retailer context pass connectionPlatformAccount
    const account = isBrand ? platformAccount : null

    if (account && 'credentials' in account) {
      account.credentials.forEach((credential) => {
        if ('push_order_on_order_create' in credential) {
          pushOrderCredential =
            credential.push_order_on_order_create.values[0].value
        }
      })
    }
    if (details.platform_account_transactions) {
      error = details.platform_account_transactions.errors
        .replace('ore', 'or')
        .replace("['", '')
        .replace("']", '')
        .replace('shopify', 'Shopify')
      if (details.platform_account_transactions.status === 'success') {
        didPush = true
      } else if (details.brand_identifier) {
        didPush = true
      }
    }
    setShouldRenderContext(true)
    return {
      shouldPush: account && pushOrderCredential === '1',
      didPush: didPush,
      error: error,
      platformName: account?.platform?.name || '',
    }
  }, [platformAccount])

  const supplierBannerLabel = [
    <div>
      {orderPushStatus.error}{' '}
      <a className='ml-2 mr-2' href='#' onClick={triggerOrderPush}>
        Retry push order
      </a>{' '}
      |{' '}
      <a className='ml-2' href='#' onClick={reloadOrder}>
        {' '}
        Refresh
      </a>
    </div>,
  ]

  const retailerBannerLabel = `This order was not pushed to ${details?.brands?.name}`

  const successLabel = isBrand
    ? `This order was pushed to ${orderPushStatus.platformName}.`
    : 'Retailer message to be added'

  return (
    <div className='mb-4'>
      {shouldRenderContent ? (
        orderPushStatus.shouldPush ? (
          // if error and order is not pushed show actions, else show success
          orderPushStatus.error && !orderPushStatus.didPush ? (
            <Banner
              label={isBrand ? supplierBannerLabel : retailerBannerLabel}
              variant='actions'
            />
          ) : (
            <Banner label={successLabel} variant='success' />
          )
        ) : (
          <SkeletonH4 width='100%' />
        )
      ) : null}
    </div>
  )
}

export default ShopifyExceptionBanner
