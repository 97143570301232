import React, { useEffect, useState } from 'react'
import { MODALS } from 'molecules/modals/constants'
import { getTemplateMappings, getTemplates } from 'api/templates'
import { getTransformers } from 'api/transformers'
import Dropdown from 'atoms/dropdown/dropdown'
import ModalCard1Col from 'molecules/modals/cards/modalCard1Col'
import { useDispatch } from 'react-redux'
import { setTemplate, setTransformer } from 'store/productsSlice'

type ExportItemsProps = {
  template: any
  transformer: any
  templateOptions: any
  setTemplateOptions: (e: any) => void
  transformerOptions: any
  setTransformerOptions: (e: any) => void
  loading: boolean
  setLoading: (e: any) => void
  hasFetchedTemplatesAndTransformers: boolean
  setHasFetchedTemplatesAndTransformers: (e: any) => void
}

const ExportItems: React.FC<ExportItemsProps> = ({
  template,
  transformer,
  templateOptions,
  setTemplateOptions,
  transformerOptions,
  setTransformerOptions,
  loading,
  setLoading,
  hasFetchedTemplatesAndTransformers,
  setHasFetchedTemplatesAndTransformers,
}) => {
  const [loadingMappings, setLoadingMappings] = useState(true)
  const [mappings, setMappings] = useState([])

  const dispatch = useDispatch()

  const loadTransformers = async () => {
    const {
      data: { results },
    } = await getTransformers({ params: { pagination: 0 } })
    setTransformerOptions(results)
  }

  const loadTemplates = async () => {
    const exportTemplatesParams = {
      pagination: 0,
      order_by: 'name',
      data_type: 'products',
      direction: 'export',
    }
    const {
      data: { results },
    } = await getTemplates({ params: exportTemplatesParams })
    setTemplateOptions(results)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(false)
      if (!hasFetchedTemplatesAndTransformers) {
        await loadTemplates()
        await loadTransformers()
        setHasFetchedTemplatesAndTransformers(true)
      }
      setLoading(false)
    })()
  }, [])

  const loadMappings = async () => {
    setLoadingMappings(true)
    const {
      data: { results },
    } = await getTemplateMappings({
      id: template?.id,
      params: {
        pagination: 0,
      },
    })
    const mappingColumns = results?.map((mapping) => mapping?.title)
    setMappings(mappingColumns)
    setLoadingMappings(false)
  }

  // get template mappings only if a (new) template has been selected
  useEffect(() => {
    if (template) {
      ;(async () => {
        await loadMappings()
        setLoading(false)
      })()
    }
  }, [template])

  return (
    <>
      <p className='mb-4'>{MODALS.EXPORT.TYPES.ITEMS.COPY_2}</p>
      <div className='mb-4'>
        <Dropdown
          dataTestid='export-template-dropdown'
          disabled={loading}
          options={templateOptions}
          placeholder='Select Export Template'
          label='Export Template'
          value={template ? template : ''}
          width='100%'
          onChange={(e: any) => {
            dispatch(setTemplate(e))
          }}
        />
      </div>
      <div className='mb-4'>
        <Dropdown
          dataTestid='transformer-dropdown'
          disabled={loading}
          options={transformerOptions}
          placeholder='Select Transformer (optional)'
          label='Transformer'
          value={transformer ? transformer : ''}
          width='100%'
          onChange={(e: any) => {
            dispatch(setTransformer(e))
          }}
        />
      </div>
      {template && (
        <ModalCard1Col
          loading={loadingMappings}
          header={
            <div>
              <span>Column Headers</span>
              {!loadingMappings && (
                <p className='mt-1 mb-1'>
                  Your file will contain {mappings.length} column headers
                </p>
              )}
            </div>
          }
          data={mappings}
          showDivider={true}
          showCollapse={true}
          open={false}
        />
      )}
    </>
  )
}

export default ExportItems
