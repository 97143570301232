import React, { useMemo, useState } from 'react'
import { Box, Input, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { createPlatformAccounts } from 'api/platformAccount'
import { useNavigate } from 'react-router-dom'

type ChildProps = {
  showModal: boolean
  onClose: () => void
  loadPlatformAccounts: (quiet?: boolean) => void
  selectedPlatform: any
}

const PlatformAccountModal: React.FC<ChildProps> = ({
  showModal,
  onClose,
  loadPlatformAccounts,
  selectedPlatform,
}) => {
  const showToast = useToast()
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [shopifyStoreURL, setShopifyStoreURL] = useState('')
  const [shopifyApiKey, setShopifyApiKey] = useState('')
  const [fabricAccount, setFabricAccount] = useState('')
  const [locationNumber, setLocationNumber] = useState('')

  const onSave = async () => {
    setSaving(true)
    try {
      let body: any = {}
      if (selectedPlatform.code === 'shopify') {
        body = {
          platform: { code: 'shopify' },
          credentials: [
            {
              credential: { code: 'shopify_shop_name' },
              value: shopifyStoreURL,
            },
            {
              credential: { code: 'shopify_token' },
              value: shopifyApiKey,
            },
            {
              credential: { code: 'shopify_private_app' },
              value: 1,
            },
          ],
        }
      } else if (selectedPlatform.code === 'fabric') {
        const credentials = [
          {
            credential: { code: 'fabric_account' },
            value: fabricAccount,
          },
        ]
        if (locationNumber && locationNumber.length > 0) {
          credentials.push({
            credential: { code: 'location_num' },
            value: locationNumber,
          })
        }
        body = {
          platform: { code: 'fabric' },
          credentials: credentials,
        }
      }

      await createPlatformAccounts({ body })
      showToast({
        label: `${selectedPlatform.name} integration added successfully`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: `Couldn't add ${selectedPlatform.name} integration, please review inputs and try again.`,
        isDismissable: true,
        variant: 'error',
        id: '2',
      })
    } finally {
      setSaving(false)
      onClose()
      loadPlatformAccounts(true)
      navigate('/settings/integrations')
    }
  }

  const isReady = useMemo(() => {
    if (selectedPlatform.code === 'shopify') {
      return shopifyStoreURL !== '' && shopifyApiKey !== ''
    } else if (selectedPlatform.code === 'fabric') {
      return fabricAccount !== ''
    }
    return false
  }, [selectedPlatform, shopifyStoreURL, shopifyApiKey, fabricAccount])

  return (
    <Modal
      headerText={`Add ${selectedPlatform.name} Integration`}
      size='small'
      onClose={onClose}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: onClose,
          text: 'Cancel',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: 'Add Integration',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ]}
      dataTestid={`${selectedPlatform.code}-integration-modal`}
      onBackdropClick={onClose}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        {selectedPlatform.code === 'shopify' ? (
          <>
            <Input
              width='100%'
              label='Shopify store URL'
              required
              prefix='https://'
              suffix='.myshopify.com'
              inputProps={{
                value: shopifyStoreURL,
                onChange: ({ target: { value } }) => setShopifyStoreURL(value),
                dataTestid:'shopify-url-input'
              }}
            />
            <Input
              width='100%'
              label='API Key'
              required
              inputProps={{
                value: shopifyApiKey,
                tooltipInfo: {
                  text: 'Shopify API Key',
                },
                onChange: ({ target: { value } }) => setShopifyApiKey(value),
                dataTestid:'shopify-key-input'
              }}
            />
          </>
        ) : (
          <>
            <Input
              width='100%'
              label='fabric Account'
              required
              inputProps={{
                value: fabricAccount,
                onChange: ({ target: { value } }) => setFabricAccount(value),
                dataTestid:'fabric-account-input'
              }}
            />
            <Input
              width='100%'
              label='Location Number'
              inputProps={{
                value: locationNumber,
                placeholder: '(Optional)',
                onChange: ({ target: { value } }) => setLocationNumber(value),
                dataTestid:'fabric-location-number-input'
              }}
            />
          </>
        )}
      </Box>
    </Modal>
  )
}

export default PlatformAccountModal
