import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { ORDERS } from 'modules/orders/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    skip_attributes: 1,
    short: 1,
  },
  connectionOptions: [],
  canceledByOptions: ORDERS.CANCELLED_BY.CHECKBOX_OPTIONS,
  reasonOptions: ORDERS.CANCELLATION_REASONS.CHECKBOX_OPTIONS,
  createdOn: null,
  acknowledgedOn: null,
  selected: [],
}
const cancellationsSlice = createSlice({
  name: 'cancellations',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setCanceledByOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.canceledByOptions = ORDERS.CANCELLED_BY.CHECKBOX_OPTIONS
      } else {
        state.canceledByOptions = action.payload
      }
    },
    setCancellationReasonOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.reasonOptions = ORDERS.CANCELLATION_REASONS.CHECKBOX_OPTIONS
      } else {
        state.reasonOptions = action.payload
      }
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CANCEL_REGISTERED.PARAMS.START]
        delete state.params[ORDERS.CANCEL_REGISTERED.PARAMS.END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.CANCEL_REGISTERED.PARAMS.START] = start
        state.params[ORDERS.CANCEL_REGISTERED.PARAMS.END] = end
        state.createdOn = action.payload
      }
    },
    setAcknowledgedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START]
        delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END]
        delete state.acknowledgedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START] = start
        state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END] = end
        state.acknowledgedOn = action.payload
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []
      state.canceledByOptions = ORDERS.CANCELLED_BY.CHECKBOX_OPTIONS
      state.reasonOptions = ORDERS.CANCELLATION_REASONS.CHECKBOX_OPTIONS

      delete state.params[ORDERS.CONNECTION.PARAM]
      delete state.params[ORDERS.CANCELLED_BY.PARAM]
      delete state.params[ORDERS.CANCELLATION_REASONS.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START]
      delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END]
      delete state.params[ORDERS.CANCEL_REGISTERED.PARAMS.START]
      delete state.params[ORDERS.CANCEL_REGISTERED.PARAMS.END]
      delete state.createdOn
      delete state.acknowledgedOn
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setConnectionOptions,
  setCanceledByOptions,
  setCancellationReasonOptions,
  setCreatedOn,
  setAcknowledgedOn,
  setCurrentPage,
  setSelected,
  resetAllFilters,
} = cancellationsSlice.actions

export default cancellationsSlice.reducer
