import { InlineDatePicker as InlineDatePickerUI } from '@teamfabric/copilot-ui'
import React from 'react'

type DateEvents = Array<number>

type DateEventType = {
  id: string
  label: string
  eventName: string
  secondaryText: string
}

type DateProps = {
  label?: string
  onSelect: (date: Date) => void
  minDate: Date | undefined
  /** Defines the valid end date and disables selecting dates after it */
  maxDate: Date | undefined
  required?: boolean
  format?: string
  events?: Record<number, DateEvents>
  eventsLegend?: Array<DateEventType>
}

const InlineDatePicker: React.FC<DateProps> = ({ ...props }) => {
  return <InlineDatePickerUI {...props} />
}

export default InlineDatePicker
