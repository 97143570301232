import { Modal, MultiLineTextArea } from 'atoms'
import { capitalizeFirstLetter } from 'lib/utils'
import { formatDate } from 'lib/utils/date/formatDate'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import React, { useMemo } from 'react'

type ChildProps = {
  webhookDetails: any
  showModal: boolean
  closeModal: () => void
}

const InspectWebhookModal: React.FC<ChildProps> = ({
  webhookDetails,
  showModal,
  closeModal,
}) => {
  const formattedJobDetails = useMemo(() => {
    let data = []
    data = [
      { label: 'Id', value: webhookDetails?.id },
      { label: 'Topic', value: webhookDetails?.topic },
      {
        label: 'Received',
        value: formatDate({
          date: new Date(webhookDetails?.created_at),
          withTime: true,
        }),
      },
      {
        label: 'Status',
        value: capitalizeFirstLetter(webhookDetails?.status) || '--',
      },
    ]
    return data
  }, [webhookDetails])

  return (
    <Modal
      headerText={'Inspect Webhook'}
      onClose={() => closeModal()}
      dataTestid={'inspect-webhook-modal'}
      onBackdropClick={() => closeModal()}
      isVisible={showModal}
      variant='normal'
    >
      <ModalCard2Col
        header='Webhook Details'
        open={true}
        showCollapse={false}
        data={formattedJobDetails}
        addGap
      />
      <div className='divider-4' />
      <MultiLineTextArea
        label='Webhook json'
        width='100%'
        inputProps={{
          value: JSON.stringify(JSON.parse(webhookDetails.payload), null, 2),
          disabled: true,
          dataTestid: 'webhook-json-textarea',
        }}
        rows={20}
      />
    </Modal>
  )
}

export default InspectWebhookModal
