import React, { useState } from 'react'
import Modal from 'atoms/modal/modal'
import Dropdown from 'atoms/dropdown/dropdown'
import { ONBOARDING_TASKS } from '../../constants'
import { patchContextDetails } from 'api/context'
import ModalLoading from 'molecules/loading/modalLoading'
import {
  setCall,
  setCurrentStep,
  setNextAvaiableStep,
} from 'store/onboardingSlice'
import { useToast } from '@teamfabric/copilot-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

type SkipCallModalProps = {
  isVisible: boolean
  setDisplaySkipModal: (value: boolean) => void
}

const SkipCallModal: React.FC<SkipCallModalProps> = ({
  isVisible,
  setDisplaySkipModal,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const state = useSelector((state: RootState) => state)
  const call = state.onboarding.call

  const [reason, setReason] = useState({
    id: ONBOARDING_TASKS.CALL.SUB_TASKS.SKIP.REASONS[0].VALUE,
    label: ONBOARDING_TASKS.CALL.SUB_TASKS.SKIP.REASONS[0].NAME,
  })
  const [loading, setLoading] = useState(false)
  const goBackStep = {
    value: ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_INTRO.TASK_NAME,
    parent: ONBOARDING_TASKS.CALL.VALUE,
  }

  const updateSlice = () => {
    dispatch(
      setCurrentStep({
        value: ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_COMPLETED.TASK_NAME,
        parent: ONBOARDING_TASKS.CALL.VALUE,
      })
    )
    dispatch(
      setCall({
        taskId: call.taskId,
        processNum: 1,
        completed: true,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.INFO.VALUE))
  }

  const saveSkipCall = async () => {
    try {
      setLoading(true)
      const body = {
        metadata: [
          {
            name: 'ob_skip_call_reason',
            display_name: 'Onboarding Skip Call Reason',
            type: 'string',
            value: reason.id,
          },
        ],
      }
      await patchContextDetails({ body })
      updateSlice()
      setLoading(false)
      setDisplaySkipModal(false)
    } catch (error) {
      showToast({
        label: "Couldn't save",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  return (
    <>
      <ModalLoading
        isVisible={loading}
        headerText='Skip Introductory Call'
        buttonLabel='Skip Call'
        skeltonNum={2}
      />
      <Modal
        headerText='Skip Introductory Call'
        description={
          <div>
            <p className='mb-3'>
              Are you sure you want to skip the introductory call? If you're new
              to fabric Marketplace, we highly recommend scheduling a training
              and demo session.
            </p>
            <p>
              If you'd like to skip this call, please select a reason below:
            </p>
            <div>
              <Dropdown
                label='Reason'
                width='100%'
                value={reason}
                placeholder='Select a Reason'
                options={ONBOARDING_TASKS.CALL.SUB_TASKS.SKIP.REASONS.map(
                  ({ NAME, VALUE }) => ({
                    disabled: false,
                    id: VALUE,
                    label: NAME,
                  })
                )}
                onChange={(e: any) => {
                  setReason(e)
                }}
              />
            </div>
          </div>
        }
        onClose={() => {
          setDisplaySkipModal(false)
          dispatch(setCurrentStep(goBackStep))
        }}
        isVisible={!loading && isVisible}
        footerButtons={[
          {
            onClick: () => {
              setDisplaySkipModal(false)
              dispatch(setCurrentStep(goBackStep))
            },
            text: 'Cancel',
            variant: 'secondary',
          },
          {
            onClick: () => saveSkipCall(),
            isVisible: reason !== null,
            text: 'Skip Call',
            variant: 'primary',
          },
        ]}
        size='small'
        onBackdropClick={() => {
          setDisplaySkipModal(false)
          dispatch(setCurrentStep(goBackStep))
        }}
        dataTestid={''}
      />
    </>
  )
}

export default SkipCallModal
