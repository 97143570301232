import { ORDERS } from '../../constants'

/**
 * get Badge status
 * @param status string return order status
 * @returns status
 */
const getBadgeStatusByOrderStatus = (status: string) => {
  switch (status) {
    case ORDERS.STATUS.CHECKBOX_OPTIONS[0].value:
      return 'success'
    case ORDERS.STATUS.CHECKBOX_OPTIONS[1].value:
      return 'success'
    case ORDERS.STATUS.CHECKBOX_OPTIONS[2].value:
    case ORDERS.STATUS.CHECKBOX_OPTIONS[3].value:
      return 'error'
    default:
      return 'info'
  }
}

export { getBadgeStatusByOrderStatus }
