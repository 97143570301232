import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledContainer = styled.div`
  padding: 0 ${theme.space[6]};
  .titleDiv {
    display: flex;
    justify-content: space-between;
  }
  .title {
    ${theme.typography.h4}
    margin-top: ${theme.space[5]};
    margin-bottom: ${theme.space[4]};
  }
  .exportBtnDiv {
    align-self: center;
  }
  .filterDiv {
    margin: ${theme.space[4]} 0 ${theme.space[7]} 0;
  }
`

export const StyledWrapper = styled.div`
  padding: ${theme.space[6]};
  .filterDiv {
    margin: ${theme.space[3]} 0 ${theme.space[4]} 0;
  }
`

export const StyledFilterContainer = styled.div`
  margin: ${theme.space[3]} 0 ${theme.space[4]} 0;
`
