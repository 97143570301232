import { FC, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import {
  GlobalStyle,
  theme,
  CopilotUIProvider,
  BreadcrumbProps,
} from '@teamfabric/copilot-ui'
import { BrandPageRoutes, RetailerPageRoutes } from './routes/pageRoutes'
import { GlobalStyled } from './globalStyle'
import { authenticateIdV2 } from 'api/auth'
import { tenantDetails } from 'api/helper'
import { fetchUserData } from 'store/userSlice'
import { setContext } from 'store/contextSlice'
declare global {
  interface Window {
    updateBreadcrumb: (data: BreadcrumbProps['data']) => void
  }
}

const App: FC = () => {
  const mkpTenant = sessionStorage.getItem('mkp_tenant')
  const selectedTenantID = sessionStorage.getItem('accountId')
  // const selectedTenant = sessionStorage.getItem('tenantDetail')
  // const selectedTenantDetails = JSON.parse(selectedTenant)
  const [loading, setLoading] = useState(false)
  const [tenantType, setTenantType] = useState('')

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Authenticate id v2
        const response = await authenticateIdV2()
        setTenantType(response?.tenant_type || '')
        store.dispatch<any>(setContext(response))
      } catch (error) {
        // To-Do: Handle authentication error, redirect to login page.
        console.error('Authentication error:', error)
      } finally {
        setLoading(false)
      }
    }

    const fetchUser = async () => {
      store.dispatch<any>(fetchUserData())
    }

    // Case: User is not authenticated (mkpTenant is null)
    if (!mkpTenant && !loading) {
      setLoading(true)
      checkAuthentication()
    } else if (mkpTenant && !loading) {
      // this is trigged due to selectedTenantDetails
      const { type, fabric_tenant_id } = tenantDetails()
      // Check if the authenticated tenant matches the selected tenant
      if (fabric_tenant_id === selectedTenantID) {
        setTenantType(type)
        setLoading(false)
      } else {
        setLoading(true)
        checkAuthentication()
      }
    }
    fetchUser()
  }, [mkpTenant, selectedTenantID])

  const RoutesToRender =
    tenantType === 'brand' ? BrandPageRoutes : RetailerPageRoutes

  if (loading && tenantType === '') {
    // Show a loader while checking authentication
    return <div>Loading...</div>
  }

  return (
    <Provider store={store}>
      <CopilotUIProvider>
        <ThemeProvider theme={theme}>
          {/* <CssReset /> */}
          <GlobalStyle />
          <GlobalStyled />
          {/* note: we might want to delay the rendering of our app’s UI until the persisted data is available in the Redux store */}
          {/* For that, Redux Persist includes the PersistGate component.  */}
          <PersistGate loading={null} persistor={persistor}>
            <div>
              {/* <div>Side Nav placeholder</div> */}
              <BrowserRouter basename='/suppliers'>
                <Routes>
                  {RoutesToRender?.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                  {/* Redirect to a page saying "Access Denied" if the route is not allowed */}
                  <Route
                    path='/access-denied'
                    element={<div>Access Denied</div>}
                  />
                  {/* Redirect to a default page or login page if no matching route is found */}
                  <Route path='*' element={<Navigate to='/access-denied' />} />
                </Routes>
              </BrowserRouter>
            </div>
          </PersistGate>
        </ThemeProvider>
      </CopilotUIProvider>
    </Provider>
  )
}

export default App
