import FilterModal from 'atoms/filter/filterModal'
import {
  applyFilters,
  resetLocalState,
  setInitialState,
} from 'lib/utils/filter/filterModal/actions'
import { date } from 'lib/utils/filter/filterModal/itemTypes'
import { ORDERS } from 'modules/orders/constants'
import { FILTERS } from 'molecules/filter/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  setAcknowledgedOn,
  setDueOn,
  setFilters,
  setPaidOn,
} from 'store/invoicesSlice'

type AllFiltersModalProps = {
  params: any
}

const InvoicesAllFiltersModal: React.FC<AllFiltersModalProps> = ({
  params,
}) => {
  const [localDueOn, setLocalDueOn] = useState(null)
  const [localAcknowledgedOn, setLocalAcknowledgedOn] = useState(null)
  const [localPaidOn, setLocalPaidOn] = useState(null)

  const [dueOnCounter, setDueOnCounter] = useState(0)
  const [acknowledgedOnCounter, setAcknowledgedOnCounter] = useState(0)
  const [paidOnCounter, setPaidOnCounter] = useState(0)

  const dueOn = useSelector((state: RootState) => state.invoices.dueOn)
  const acknowledgedOn = useSelector(
    (state: RootState) => state.invoices.acknowledgedOn
  )
  const paidOn = useSelector((state: RootState) => state.invoices.paidOn)

  const allFiltersCount = useMemo(() => {
    return dueOnCounter + acknowledgedOnCounter + paidOnCounter
  }, [dueOnCounter, acknowledgedOnCounter, paidOnCounter])

  // Define respective filterKey fns
  const filterActions = {
    terms_date_due_gte: {
      setFilterCount: setDueOnCounter,
      setLocalState: setLocalDueOn,
      initialState: dueOn,
      date: localDueOn,
      action: setDueOn,
      filterType: FILTERS.TYPES.DATE,
      label: ORDERS.INVOICE_DUE.NAME,
    },
    acknowledged_at_gte: {
      setFilterCount: setAcknowledgedOnCounter,
      setLocalState: setLocalAcknowledgedOn,
      initialState: acknowledgedOn,
      date: localAcknowledgedOn,
      action: setAcknowledgedOn,
      filterType: FILTERS.TYPES.DATE,
      label: ORDERS.ACKNOWLEDGED_ON.NAME,
    },
    paid_at_gte: {
      setFilterCount: setPaidOnCounter,
      setLocalState: setLocalPaidOn,
      initialState: paidOn,
      date: localPaidOn,
      action: setPaidOn,
      filterType: FILTERS.TYPES.DATE,
      label: ORDERS.INVOICE_PAID_ON.NAME,
    },
  }

  useEffect(() => {
    setInitialState(filterActions)
  }, [params])

  return (
    <FilterModal
      filters={[
        date(filterActions, ORDERS.INVOICE_DUE.PARAMS.START),
        date(filterActions, ORDERS.ACKNOWLEDGED_ON.PARAMS.START),
        date(filterActions, ORDERS.INVOICE_PAID_ON.PARAMS.START),
      ]}
      onApply={() => applyFilters(filterActions, setFilters)}
      onCancel={() => setInitialState(filterActions)}
      onResetAll={() => resetLocalState(filterActions)}
      primaryButtonText={`Apply (${allFiltersCount})`}
    />
  )
}

export default InvoicesAllFiltersModal
