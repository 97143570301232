export const MODALS = {
  EXPORT: {
    COMMON: {
      COPY: 'Please note that the export will only include the items that match your applied filters. Please double check your filters before exporting.',
    },
    TOAST: {
      SUCCESS: 'Export scheduled! Please allow 5-10 mins for delivery.',
      FAILURE: 'Something went wrong.',
    },
    TYPES: {
      ITEMS: {
        LABEL: 'Export Items',
        COPY_1:
          'The export will be delivered on your email. Please note this export will only include the items that match your applied filters.',
        COPY_2:
          'You can choose the required template and apply an optional transformer on the export, if needed.',
        RADIO_GROUP_OPTIONS: [
          {
            label: 'Items & Attributes',
            value: 'items',
          },
          {
            label: 'Current Inventory',
            value: 'inventory',
          },
        ],
      },
      INVOICES: {
        LABEL: 'Export Invoices',
        COPY_1:
          "Click 'Export' below to have an invoices export delivered to your email. Please note that the export will only include the items that match your applied filters. Please double check your filters before exporting.",
        COPY_2:
          'Your invoice line export may contain monetary columns related to the invoice (e.g. subtotals, adjustments, etc) as well as the invoice lines. If you summarize invoice-level columns, it may lead to discrepancy.',
        RADIO_GROUP_OPTIONS: [
          {
            label: 'Export Invoices Only',
            value: '1',
          },
          {
            label: 'Export Invoices with Invoice Lines',
            value: '0',
          },
        ],
      },
      ORDERS: {
        NAME: 'orders',
        COPY: "Click 'Export' below to have an orders export delivered to your email.",
      },
      OFFERS: {
        NAME: 'offers',
        COPY: "Click 'Export' below to have an offers export delivered to your email.",
      },
      TRACKING: {
        NAME: 'tracking',
        COPY: "Click 'Export' below to have a tracking number export delivered to your email.",
      },
      CANCELLATIONS: {
        NAME: 'cancellations',
        COPY: "Click 'Export' below to have a cancellations export delivered to your email.",
      },
    },
  },
  REMOVE_PROPOSAL_ITEMS: {
    COPY: 'If you remove items from this proposal, they cannot be restored (and will have to be added in a different list).',
    REASON_DROPDOWN: {
      PLACEHOLDER: 'Select reason',
      OPTIONS: [
        {
          id: 'market',
          label: 'Market fit',
        },
        {
          id: 'pricing',
          label: 'Costs, pricing & margin concerns',
        },
        {
          id: 'inventory',
          label: 'Inventory concerns',
        },
      ],
    },
    HEADER_TEXT: 'Remove items',
    CANCEL_TEXT: "No, Don't remove",
    CONFIRM_TEXT: 'Yes, Remove items',
    TOAST: {
      SUCCESS: 'Items removed!',
      FAILURE:
        'These items cannot be removed (likely because of the status of the proposal).',
    },
  },
}
