import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
}

const feedRequestSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['id'] = action.payload
      } else delete state.params['id']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
  },
})

export const { setParams, setSearch } = feedRequestSlice.actions

export default feedRequestSlice.reducer
