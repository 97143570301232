import React, { useEffect, useMemo, useState } from 'react'
import { ORDERS } from 'modules/orders/constants'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import {
  handleCheckboxFilterChange,
  handleDateFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import FilterChips from 'organisms/filterChips'
import Box from 'atoms/box/box'
import {
  resetAllFilters,
  setBackorderedUntil,
  setClosedOn,
  setConnectionOptions,
  setDueOn,
  setFilters,
  setReceivedOn,
  setRetailerSkuOptions,
  setStatusOptions,
  setSupplierSkuOptions,
  setTagOptions,
} from 'store/orderSlice'
import { FILTERS } from 'molecules/filter/constants'
import OrdersAllFiltersModal from 'organisms/allFiltersModal/orders'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [statusCounter, setStatusCounter] = useState(0)
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [tagsCounter, setTagsCounter] = useState(0)
  const [
    retailerSKUCheckboxOptionsCounter,
    setRetailerSKUCheckboxOptionsCounter,
  ] = useState(0)
  const [
    supplierSKUCheckboxOptionsCounter,
    setSupplierSKUCheckboxOptionsCounter,
  ] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [backorderedCounter, setBackorderedCounter] = useState(0)
  const [backorderedAcknowledgedCounter, setBackorderedAcknowledgedCounter] =
    useState(0)
  const [requiresFulfillmentCounter, setRequiresFulfillmentCounter] =
    useState(0)
  const [requiresInvoiceCounter, setRequiresInvoiceCounter] = useState(0)
  const [hasUnreadMessagesCounter, setHasUnreadMessagesCounter] = useState(0)
  const [dueOnCounter, setDueOnCounter] = useState(0)
  const [closedOnCounter, setClosedOnCounter] = useState(0)
  const [backorderedUntilCounter, setBackorderedUntilCounter] = useState(0)
  const [receivedOnCounter, setReceivedOnCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.orders.statusOptions
  )

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.orders.connectionOptions
  )

  const tagCheckboxOptions = useSelector(
    (state: RootState) => state.orders.tagOptions
  )

  const supplierSkuOptionsCheckboxOptions = useSelector(
    (state: RootState) => state.orders.supplierSkuOptions
  )

  const retailerSkuOptionsCheckboxOptions = useSelector(
    (state: RootState) => state.orders.retailerSkuOptions
  )

  const dueOn = useSelector((state: RootState) => state.orders.dueOn)

  const receivedOn = useSelector((state: RootState) => state.orders.receivedOn)

  const closedOn = useSelector((state: RootState) => state.orders.closedOn)

  const backorderedUntil = useSelector(
    (state: RootState) => state.orders.backorderedUntil
  )

  const isAFilterApplied = useMemo(() => {
    return (
      statusCounter +
        connectionsCounter +
        tagsCounter +
        retailerSKUCheckboxOptionsCounter +
        supplierSKUCheckboxOptionsCounter +
        acknowledgedCounter +
        backorderedCounter +
        backorderedAcknowledgedCounter +
        requiresFulfillmentCounter +
        requiresFulfillmentCounter +
        requiresInvoiceCounter +
        hasUnreadMessagesCounter +
        dueOnCounter +
        closedOnCounter +
        backorderedUntilCounter +
        receivedOnCounter >
      0
    )
  }, [
    statusCounter,
    connectionsCounter,
    tagsCounter,
    retailerSKUCheckboxOptionsCounter,
    supplierSKUCheckboxOptionsCounter,
    acknowledgedCounter,
    backorderedCounter,
    backorderedAcknowledgedCounter,
    requiresFulfillmentCounter,
    requiresFulfillmentCounter,
    requiresInvoiceCounter,
    hasUnreadMessagesCounter,
    dueOnCounter,
    closedOnCounter,
    backorderedUntilCounter,
    receivedOnCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    // checkbox types
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: ORDERS.CONNECTION.NAME,
    },
    status: {
      action: setStatusOptions,
      options: statusCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setStatusCounter,
      chipName: ORDERS.STATUS.NAME,
    },
    tags: {
      action: setTagOptions,
      options: tagCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setTagsCounter,
      chipName: ORDERS.TAGS.NAME,
    },
    variant_identifier: {
      action: setSupplierSkuOptions,
      options: supplierSkuOptionsCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setSupplierSKUCheckboxOptionsCounter,
      chipName: ORDERS.SUPPLIER_SKU.NAME,
    },
    retailer_identifier: {
      action: setRetailerSkuOptions,
      options: retailerSkuOptionsCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setRetailerSKUCheckboxOptionsCounter,
      chipName: ORDERS.RETAILER_SKU.NAME,
    },
    // date types
    fulfill_by_gte: {
      setFilterCount: setDueOnCounter,
      dateRange: dueOn,
      action: setDueOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.DUE_DATE.NAME,
    },
    closed_at_gte: {
      setFilterCount: setClosedOnCounter,
      dateRange: closedOn,
      action: setClosedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.ORDER_CLOSED_DATE.NAME,
    },
    backordered_until_gte: {
      setFilterCount: setBackorderedUntilCounter,
      dateRange: backorderedUntil,
      action: setBackorderedUntil,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.BACKORDERED_UNTIL.NAME,
    },
    created_at_gte: {
      setFilterCount: setReceivedOnCounter,
      dateRange: receivedOn,
      action: setReceivedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.ORDER_RECEIVED.NAME,
    },
    // boolean types
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.ACKNOWLEDGED.NAME,
    },
    is_backordered: {
      setFilterCount: setBackorderedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.BACKORDERED.NAME,
    },
    backorder_is_acknowledged: {
      setFilterCount: setBackorderedAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.BACKORDERED_ACKNOWLEDGED.NAME,
    },
    not_fully_shipped: {
      setFilterCount: setRequiresFulfillmentCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.REQUIRES_FULFILLMENT.NAME,
    },
    not_fully_invoiced: {
      setFilterCount: setRequiresInvoiceCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.REQUIRES_INVOICE.NAME,
    },
    has_unread_memos: {
      setFilterCount: setHasUnreadMessagesCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.UNREAD_MESSAGES.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${ORDERS.STATUS.NAME}-filter`}
          label={ORDERS.STATUS.NAME}
          data={statusCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={statusCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.DUE_DATE.NAME}-filter`}
          label={ORDERS.DUE_DATE.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.DUE_DATE.PARAMS.START,
              date,
            })
          }}
          defaultValue={dueOn}
          filterCount={dueOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.DUE_DATE.PARAMS.START,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.ORDER_RECEIVED.NAME}-filter`}
          label={ORDERS.ORDER_RECEIVED.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.ORDER_RECEIVED.PARAMS.START,
              date,
            })
          }}
          defaultValue={receivedOn}
          filterCount={receivedOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.ORDER_RECEIVED.PARAMS.START,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.ORDER_CLOSED_DATE.NAME}-filter`}
          label={ORDERS.ORDER_CLOSED_DATE.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.ORDER_CLOSED_DATE.PARAMS.START,
              date,
            })
          }}
          defaultValue={closedOn}
          filterCount={closedOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.ORDER_CLOSED_DATE.PARAMS.START,
              isReset: true,
            })
          }
        />
        <OrdersAllFiltersModal params={params} />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
