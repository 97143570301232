import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import CompleteSetup from './completeSetup'
import EdiProgress from './edi/ediProgress'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import IntegrationIntro from './intro'
import { setCurrentStep } from 'store/onboardingSlice'
import { useNavigate } from 'react-router-dom'
import ShipstationSetup from './shipstation/setup'
import BigCommerceSetup from './bigcommerce/setup'

type IntegrationProgressProps = {
  isNextClicked: boolean
  setIsNextButtonDisabled: (value: boolean) => void
  setIsNextClicked: (value: boolean) => void
  isPreviousClicked: boolean
  setIsPreviousClicked: (value: boolean) => void
  setIsSaveExitClicked: (value: boolean) => void
  isSaveExitClicked: boolean
  reseting: boolean
}

const IntegrationProgress: React.FC<IntegrationProgressProps> = ({
  isNextClicked,
  setIsNextButtonDisabled,
  setIsNextClicked,
  isPreviousClicked,
  setIsSaveExitClicked,
  isSaveExitClicked,
  setIsPreviousClicked,
  reseting,
}) => {
  const integrationTask = ONBOARDING_TASKS.INTEGRATION.SUB_TASKS
  const integration_methods = integrationTask.INTEGRATION_METHOD.METHODS
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentStep = useSelector(
    (state: RootState) => state.onboarding.currentStep
  )
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )

  const getCompleteSetUpTask = () => {
    if (integration.selectedMethod === integration_methods.EDI.TASK_NAME) {
      return {
        value: ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVOICE.TASK_NAME,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      }
    } else if (
      integration.selectedMethod === integration_methods.API.TASK_NAME ||
      integration.selectedMethod === ''
    ) {
      return {
        value: integrationTask.INTEGRATION_METHOD.TASK_NAME,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      }
    }
    return {
      value:
        integration_methods[integration.selectedMethod.toUpperCase()].TASK_NAME,
      parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
    }
  }

  const getPreviousTask = () => {
    if (
      Object.keys(ONBOARDING_TASKS.INTEGRATION.EDI_TASKS).includes(
        currentStep.value.toUpperCase()
      )
    ) {
      return {
        value:
          ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[
            currentStep.value.toUpperCase()
          ].PREVIOUS_TASK,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      }
    }
    switch (currentStep.value) {
      case integrationTask.INTEGRATION_METHOD.TASK_NAME:
        navigate(ONBOARDING_TASKS.NOTIFICATIONS.LINK)
        return {
          value: ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.TASK_NAME,
          parent: ONBOARDING_TASKS.NOTIFICATIONS.VALUE,
        }
      case integrationTask.COMPLETE_SETUP.TASK_NAME:
        return getCompleteSetUpTask()
      default:
        return {
          value: integrationTask.INTEGRATION_METHOD.TASK_NAME,
          parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
        }
    }
  }

  // previous button clicked
  useEffect(() => {
    if (!isPreviousClicked) {
      return
    }
    setIsPreviousClicked(false)
    const { value, parent } = getPreviousTask()
    dispatch(
      setCurrentStep({
        value,
        parent,
      })
    )
  }, [isPreviousClicked])

  return (
    <>
      {currentStep.value === integrationTask.INTEGRATION_METHOD.TASK_NAME && (
        <IntegrationIntro
          isNextClicked={isNextClicked}
          setIsNextClicked={setIsNextClicked}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          reseting={reseting}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
        />
      )}
      {Object.keys(ONBOARDING_TASKS.INTEGRATION.EDI_TASKS).includes(
        currentStep.value.toUpperCase()
      ) && (
        <EdiProgress
          isNextClicked={isNextClicked}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          setIsNextClicked={setIsNextClicked}
          reseting={reseting}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
        />
      )}
      {currentStep.value === integration_methods.SHIPSTATION.TASK_NAME && (
        <ShipstationSetup
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          setIsNextClicked={setIsNextClicked}
          isNextClicked={isNextClicked}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
        />
      )}
      {currentStep.value === integration_methods.BIGCOMMERCE.TASK_NAME && (
        <BigCommerceSetup
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          setIsNextClicked={setIsNextClicked}
          isNextClicked={isNextClicked}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
          reseting={reseting}
        />
      )}
      {currentStep.value === integrationTask.COMPLETE_SETUP.TASK_NAME && (
        <CompleteSetup
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          isNextClicked={isNextClicked}
          setIsNextClicked={setIsNextClicked}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
        />
      )}
    </>
  )
}

export default IntegrationProgress
