import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'

type ChildProps = {
  handleUpdate?: (data: any) => void
  params?: any
  initialOption?: any
}

const defaultOption = {
  id: '',
  label: '',
  value: '',
}

const PaymentTermOptions = [
  { id: '1', value: 'net7', label: 'Net 7 Days' },
  { id: '2', value: 'net15', label: 'Net 15 Days' },
  { id: '3', value: 'net30', label: 'Net 30 Days' },
  { id: '4', value: 'net45', label: 'Net 45 Days' },
  { id: '5', value: 'net60', label: 'Net 60 Days' },
]

const SelectPaymentTerms: React.FC<ChildProps> = ({
  handleUpdate,
  params,
  initialOption,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  useEffect(() => {
    if (!initialOption.id && initialOption.value) {
      const option = PaymentTermOptions.find(
        (el) => el.value === initialOption.value
      )
      setSelectedOption(option)
    } else {
      setSelectedOption(initialOption)
    }
  }, [initialOption])

  return (
    <Dropdown
      width='100%'
      label={'Payment Terms'}
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={PaymentTermOptions}
    />
  )
}

export default SelectPaymentTerms
