import { Button as ButtonUI } from '@teamfabric/copilot-ui'
import React from 'react'

export const ButtonVariants = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DESTRUCTIVE: 'destructive',
  TERTIARY_DARK: 'tertiary_dark',
} as const

export type Variants = (typeof ButtonVariants)[keyof typeof ButtonVariants]

type ButtonType = 'button' | 'submit'

export type ButtonProps = {
  dataTestid?: string
  text: string
  isDisabled?: boolean
  variant?: Variants
  onClick: () => void
  icon?: string
  type?: ButtonType
  tooltipInfo?: any
  isLoading?: boolean
  width?: string
  popoverProps?: any
}

const Button: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <ButtonUI
      variant={props.variant ? props.variant : ButtonVariants.PRIMARY}
      {...props}
    />
  )
}

export default Button
