import { FC, useEffect, useMemo, useState } from 'react'
import { getProposalProductDetail } from 'api/proposals'
import { useParams } from 'react-router-dom'
import { getTemplateMappings } from 'api/templates'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import ProductMediaCard from '../presenters/mediaCards'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type ChildProps = {
  selected: any
}

const DrawerProductAttributes: FC<ChildProps> = ({ selected }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [proposalProduct, setProposalProduct] = useState<any>({})
  const [template, setTemplate] = useState<any>(null)
  const [loadingTemplate, setLoadingTemplate] = useState(true)

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  // new proposal product endpoint
  const { product } = selected

  const fetchProposalProduct = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalProductDetail({
      id: id,
      productId: product.id,
    })
    setProposalProduct(data)
    setLoading(false)
  }

  const fetchTemplateMappings = async () => {
    // TO-DO:  template id to be picked from selected product department
    const { data } = await getTemplateMappings({
      id: proposalDetails?.department?.template?.id,
      params: {
        pagination: 0,
      },
    })
    setTemplate(data)
    setLoadingTemplate(false)
  }

  useEffect(() => {
    ;(async () => {
      await fetchProposalProduct()
      await fetchTemplateMappings()
    })()
  }, [])

  const media = useMemo(() => {
    const media = []
    if (proposalProduct) {
      if (
        proposalProduct.primary_media !== null &&
        proposalProduct.primary_media?.media !== 'default'
      ) {
        media.push(proposalProduct.primary_media)
      }
      proposalProduct?.media?.forEach((image) => {
        if (image?.media !== 'default') {
          media.push(image)
        }
      })
    }
    return media
  }, [proposalProduct])

  const attributeGroups = useMemo(() => {
    const initialGroups = {
      description: [],
      general: [],
      images: [],
      inventory: [],
      misc: [],
      retailer: [],
      shipping: [],
      identifiers: [],
      pricing: [],
    }
    if (!template) return null
    else {
      template.results.forEach((mapping) => {
        if (mapping.target === 'product') {
          if (
            mapping.attribute.code in proposalProduct.attributes &&
            mapping.attribute.grouping in initialGroups
          ) {
            initialGroups[mapping.attribute.grouping].push({
              label: mapping.attribute.name,
              value:
                proposalProduct.attributes[mapping.attribute.code].values[0]
                  .value,
            })
          }
        }
      })
    }
    return initialGroups
  }, [!loadingTemplate, template, !loading, proposalProduct])

  return (
    <>
      <ProductMediaCard
        header='Product Media'
        loading={loading}
        media={media}
      />
      <ModalCard2Col
        header='Product Description'
        loading={loading}
        open
        showCollapse
        data={attributeGroups?.description}
        addGap
        customClassName='mt-4'
      />
      {attributeGroups?.misc.length > 0 && (
        <ModalCard2Col
          header='Additional Attributes'
          data={attributeGroups?.misc}
          showCollapse
          addGap
          customClassName='mt-4'
        />
      )}
    </>
  )
}

export default DrawerProductAttributes
