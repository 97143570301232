import React, { useState } from 'react'
import Modal from 'atoms/modal/modal'
import { patchConnections } from 'api/connection'

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
}

const SuspendSupplierModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)

  const SuspendConnection = async () => {
    try {
      setLoading(true)
      await patchConnections({
        connectionId: props.connectionId,
        body: {
          status: 'suspended',
        },
      })
    } catch (error) {
      console.error('Error suspending connection:', error)
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Are you sure you want to Suspend supplier?'
        description='Are you sure you are ready to suspend this supplier?
        Suspended suppliers cannot receive orders or update inventory.'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: "No, Don't suspend",
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => SuspendConnection(),
            text: 'Yes Suspend',
            variant: 'destructive',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      ></Modal>
    </>
  )
}

export default SuspendSupplierModal
