import { useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { getShopifyWebhookHistory } from 'api/shopify'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/shopifySlice'
import WebhookFilter from 'modules/settings/components/integrations/shopify/webhooks/filters'
import WebhooksTable from 'modules/settings/components/integrations/shopify/webhooks/listTable'

const WebhookHistory: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const params = useSelector(
    (state: RootState) => state.shopify.params
  )

  const [results, setResults] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingIntegrations({ page: 'Webhook History' })
        ),
      0
    )
  }, [])

  const fetchWebhookHistory = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getShopifyWebhookHistory({ platformAccountId: id, params: { ...params } })
    setResults(data?.results)
    setTotalRows(data?.count)
    setLoading(false)
  }

  useEffect(() => {
    if (params) {
      ; (async () => {
        await fetchWebhookHistory()
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Shopify Webhook History'
        customClassName='mb-4'
        dataTestid='webhook-history-header'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/integrations'),
            text: 'Back',
            variant: 'secondary',
            dataTestid: 'webhook-back-button'
          }
        ]}
      />
      <SearchBar dataTestid='webhook-search-input' placeholder='Search within results...' setSearch={setSearch} />
      <div className='filterDiv'>
        <WebhookFilter params={params} />
      </div>
      <WebhooksTable
        data={results}
        loading={loading}
        currentPage={params.page}
        currentSortBy={params.order_by}
        totalRows={totalRows}
      />
    </Box>
  )
}

export default WebhookHistory
