import { createSlice } from '@reduxjs/toolkit'
import {
  MERCHANTS,
  FILTER_OPTIONS,
} from 'modules/merchants/components/constants'
const initialState = {
  params: {
    order_by: 'brand__name',
    status: 'active',
    limit: 10,
    page: 1,
  },
  statusOptions: FILTER_OPTIONS.STATUS_OPTIONS,
  integrationOptions: FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS,
}
const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.statusOptions = FILTER_OPTIONS.STATUS_OPTIONS
      } else {
        state.statusOptions = action.payload
      }
    },
    setIntegrationOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.integrationOptions = FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS
      } else {
        state.integrationOptions = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.statusOptions = FILTER_OPTIONS.STATUS_OPTIONS
      state.integrationOptions = FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS

      delete state.params[MERCHANTS.STATUS.PARAM]
      delete state.params[MERCHANTS.INTEGRATION_TYPE.PARAM]
    },
  },
})

export const {
  setCurrentPage,
  setSorting,
  setSearch,
  setFilters,
  setStatusOptions,
  setIntegrationOptions,
  resetAllFilters,
} = merchantSlice.actions
export default merchantSlice.reducer
