import React, { useMemo, useState } from 'react'
import Box from 'atoms/box/box'
import Button, { ButtonVariants } from 'atoms/button/button'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ToolTip from 'atoms/tooltip/tooltip'
import { MODALS } from 'molecules/modals/constants'
import { ICONS } from 'lib/constants'
import { getTenantType } from 'api/helper'
import CreateIssueModal from 'modules/proposals/components/modals/createIssue'
import AddToOpenIssueModal from 'modules/proposals/components/modals/addToOpenIssue'
import RemoveProposalItemsModal from 'modules/proposals/components/modals/removeProposalItem'

type BulkActionsProposalItemsProps = {
  options: any
  reload?: (quiet?: boolean) => void
  loadProducts?: (quiet?: boolean) => void
}

const BulkActionsProposalItems: React.FC<BulkActionsProposalItemsProps> = ({
  options,
  reload,
  loadProducts,
}) => {
  const [showRemoveItemsModal, setShowRemoveItemsModal] = useState(false)
  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)

  const selected = useSelector(
    (state: RootState) => state.proposalItems.selected
  )

  const { isRetailer } = getTenantType()

  const canRemoveItems = useMemo(() => {
    if (!isRetailer) {
      const allowed = ['draft', 'revised']
      if (allowed.includes(options?.status)) {
        return true
      }
    } else {
      const allowed = ['proposed']
      if (allowed.includes(options?.status)) {
        return true
      }
    }
    return false
  }, [options?.status])

  const canAddToOpenIssue = useMemo(() => {
    if (options.issueSummary.open > 0) return true
    return false
  }, [options?.issueSummary])

  const renderRemoveItemsButton = () => {
    return (
      <Button
        variant={ButtonVariants.TERTIARY}
        text={MODALS.REMOVE_PROPOSAL_ITEMS.HEADER_TEXT}
        icon={ICONS.TRASH}
        isDisabled={!canRemoveItems}
        dataTestid='remove-proposal-items-bulk-action'
        onClick={() => setShowRemoveItemsModal(true)}
      />
    )
  }

  const renderAddToOpenIssue = () => {
    return (
      <Button
        variant={ButtonVariants.TERTIARY}
        text='Add To Open Issue'
        dataTestid='add-to-open-issue-bulk-action'
        isDisabled={!canAddToOpenIssue}
        onClick={() => setShowAddToOpenIssue(true)}
      />
    )
  }

  const renderAddToOpenIssueWithTooltip = useMemo(() => {
    return (
      <>
        {!canAddToOpenIssue ? (
          <ToolTip
            showOnDisabled={true}
            text='No open issues, create one instead'
          >
            <div> {renderAddToOpenIssue()} </div>
          </ToolTip>
        ) : (
          <> {renderAddToOpenIssue()} </>
        )}
      </>
    )
  }, [canAddToOpenIssue])

  const renderRemoveItemsButtonWithTooltip = useMemo(() => {
    return (
      <>
        {!canRemoveItems ? (
          <ToolTip
            showOnDisabled={true}
            text='You cannot remove items due to the proposal status'
          >
            <div> {renderRemoveItemsButton()} </div>
          </ToolTip>
        ) : (
          <> {renderRemoveItemsButton()} </>
        )}
      </>
    )
  }, [canRemoveItems])

  return (
    <>
      <RemoveProposalItemsModal
        count={selected?.length}
        showModal={showRemoveItemsModal}
        setShowModal={setShowRemoveItemsModal}
        reload={loadProducts}
      />
      {/* Add to open issue modal */}
      <AddToOpenIssueModal
        count={selected?.length}
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProducts}
      />
      {/* Create new issue modal */}
      <CreateIssueModal
        count={selected?.length}
        isProduct={false}
        showModal={showCreateIssueModal}
        setShowModal={setShowCreateIssueModal}
        loadProposal={loadProducts}
      />
      <Box flex={{ alignItems: 'center' }}>
        {renderAddToOpenIssueWithTooltip}
        <Button
          variant={ButtonVariants.TERTIARY}
          text='Create Issues'
          dataTestid='create-issues-bulk-action'
          onClick={() => setShowCreateIssueModal(true)}
        />
        {renderRemoveItemsButtonWithTooltip}
      </Box>
    </>
  )
}

export default BulkActionsProposalItems
