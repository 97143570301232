import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { Box, GridCol, GridRow, Input, MultiLineTextArea } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { theme, useToast } from '@teamfabric/copilot-ui'
import CardLoading from 'molecules/loading/cardLoading'
import { getCompatibilityRulesetById, patchRuleset } from 'api/rulesets'
import RuleGroupsTable from '../components/rulesets/ruleGroupsTable'

const ProductRulesetDetails: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [rulesetDetails, setRulesetDetails] = useState(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.rulesets({ page: `Ruleset #${id}` })
        ),
      0
    )
  }, [])

  const onSave = async () => {
    const body = {
      name: name,
      description: description,
    }
    try {
      setSaving(true)
      await patchRuleset({ id, body })
      showToast({
        label: 'Ruleset updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchRuleset = async () => {
    const { data } = await getCompatibilityRulesetById({ id })
    setRulesetDetails(data)
    setName(data.name)
    setDescription(data.description)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchRuleset()
      })()
    }
  }, [id])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        loading={loading}
        h1Text={'Ruleset Settings'}
        h2Text={'Configure basic settings for this ruleset.'}
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/rulesets'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !name,
          },
        ]}
      />
      {loading ? (
        <CardLoading />
      ) : (
        <Box
          border={{ radius: 2, width: '2px', color: theme.color.grey[200] }}
          padding={{ top: 4, bottom: 4, left: 4, right: 4 }}
        >
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Name'
                required
                inputProps={{
                  value: name,
                  onChange: ({ target: { value } }) => setName(value),
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <MultiLineTextArea
                label='Description'
                width='100%'
                inputProps={{
                  value: description,
                  onChange: ({ target: { value } }) => setDescription(value),
                  placeholder: 'Description',
                }}
              />
            </GridCol>
          </GridRow>
          <br />
        </Box>
      )}
      <RuleGroupsTable />
    </Box>
  )
}

export default ProductRulesetDetails
