import { getOrderDetail } from 'api/orders'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import { FC, useEffect, useState } from 'react'
import { CardProps } from 'lib/utils/props/cardProps'
import CardLoading from 'molecules/loading/cardLoading'

type ChildProps = {
  orderId: string
  orderNumber: string
}

const ItemDetail: FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<CardProps[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data } = await getOrderDetail({ id: props.orderId })
      formatData(data)
      setLoading(false)
    })()
  }, [])

  const formatData = ({
    id,
    purchase_order_number,
    retailer_order_number,
    brand,
    shipment_count,
    invoice_count,
  }) => {
    const formattedData = [
      {
        label: 'Order ID',
        value: id,
      },
      {
        label: 'Purchase Order #',
        value: purchase_order_number ? purchase_order_number : '---',
      },
      {
        label: 'Retailer Order #',
        value: retailer_order_number ? retailer_order_number : '---',
      },
      {
        label: 'Supplier',
        value: brand.name,
      },
      {
        label: '# Shipments',
        value: shipment_count,
      },
      {
        label: '# Invoices',
        value: invoice_count,
      },
    ]
    setData(formattedData)
  }

  return (
    <div>
      {loading ? (
        <CardLoading label={`Order #${props.orderNumber}`} />
      ) : (
        <Card
          showCollapse={false}
          showDivider={true}
          headerContent={`Order #${props.orderNumber}`}
          bodyContent={data.map(({ label, value }) => (
            <div key={label}>
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <p className='grey-700'>{label}</p>
                </GridCol>
                <GridCol lg={6} md={6} sm={6} justifySelf='end'>
                  <p className='grey-700'>{value}</p>
                </GridCol>
              </GridRow>
            </div>
          ))}
        />
      )}
    </div>
  )
}

export default ItemDetail
