import { Tab as TabUI, TabItem } from '@teamfabric/copilot-ui'

type HorizontalTabProps = {
  data: {
    tabs: { label: string; content: React.ReactNode; isDisabled?: boolean; dataTestid?: string }[]
  }
  onTabChange?: (num: number) => void
  selectedTab?: number
}

const HorizontalTab: React.FC<HorizontalTabProps> = ({ data, onTabChange, selectedTab }) => {
  return (
    <TabUI tabChangeHandler={(num) => onTabChange(num)} customActiveIndex={selectedTab}>
      {data.tabs.map(({ label, content, isDisabled, dataTestid }, index) => (
        <TabItem label={label} key={`${label}-${index}`} disabled={isDisabled} dataTestid={dataTestid}>
          {content}
        </TabItem>
      ))}
    </TabUI>
  )
}

export default HorizontalTab
