import { FC, useEffect, useMemo, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { Box, GridCol, GridRow, Input, RadioGroup } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { theme, useToast } from '@teamfabric/copilot-ui'
import UploadFileModal from '../components/templates/uploadFile'
import {
  analyzeHeaders,
  createPlatformTemplate,
  createTemplate,
} from 'api/templates'
import ColumnMappings, {
  MappingType,
} from '../components/templates/columnMappings'

const CreateProductInventoryTemplate: FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const { id, platform, templateId } = useParams()
  const [name, setName] = useState('')
  const [selectedDirection, setSelectedDirection] = useState('import')
  const [tempfileId, setTempfileId] = useState(null)
  const [headers, setHeaders] = useState([])
  const [columnMappings, setColumnMappings] = useState<MappingType[]>([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.productInventoryTemplate({ page: 'Create Template' })
        ),
      0
    )
  }, [])

  const isPlatformCreate = useMemo(() => {
    if (platform === 'fabric' && templateId === 'create') {
      return true
    }
  }, [platform, templateId])

  useEffect(() => {
    if (isPlatformCreate) {
      setSelectedDirection('export')
    }
  }, [isPlatformCreate])

  const onSave = async () => {
    const body = {
      name: name,
      direction: selectedDirection,
      data_type: 'products',
      template_mappings: isPlatformCreate ? [] : undefined,
      mappings: !isPlatformCreate ? [] : undefined,
    }
    for (const mapping in columnMappings) {
      const columnMapping: any = {
        attribute: {
          [isPlatformCreate ? 'code' : 'id']:
            columnMappings[mapping]?.attribute[
              isPlatformCreate ? 'code' : 'id'
            ],
        },
        title: columnMappings[mapping].header,
        priority: columnMappings[mapping].priority,
        target: columnMappings[mapping].target,
      }

      if (selectedDirection === 'import') {
        columnMapping.is_required = columnMappings[mapping].isRequired ? 1 : 0
      }
      body[isPlatformCreate ? 'template_mappings' : 'mappings'].push(
        columnMapping
      )
    }
    const path = isPlatformCreate
      ? `/settings/integrations/${platform}/${id}/templates`
      : '/settings/templates'
    try {
      setSaving(true)
      if (isPlatformCreate) {
        await createPlatformTemplate({
          platformCode: platform,
          platformId: id,
          body,
        })
      } else {
        await createTemplate({ body })
      }
      showToast({
        label: 'Template created successfully.',
        isDismissable: true,
        id: '1',
      })
      navigate(path)
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchHeaders = async () => {
    const body = {
      file: {
        tempfile: {
          id: tempfileId,
        },
      },
    }
    const { data: data } = await analyzeHeaders({ body })
    setHeaders(data[0].row_array)
  }

  useEffect(() => {
    if (tempfileId && tempfileId !== null && tempfileId !== undefined) {
      ;(async () => {
        await fetchHeaders()
      })()
    } else {
      setHeaders([])
    }
  }, [tempfileId])

  const isReady = useMemo(() => {
    // Check if name, selectedDirection, and tempfileId are truthy
    const basicCheck = name && selectedDirection && tempfileId
    // Check if every item in columnMappings has attribute, target, and priority set
    const mappingsCheck = columnMappings.every(
      (item) => item.attribute && item.target && item.priority
    )
    return basicCheck && mappingsCheck
  }, [name, selectedDirection, tempfileId, columnMappings])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        dataTestid='template-create-header'
        h1Text='Templates'
        h2Text='To create a custom import or export template, please complete the steps below.'
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/templates'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
            dataTestid: 'back-button',
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: !isReady || saving,
            dataTestid: 'save-button',
          },
        ]}
      />
      <Box
        border={{ radius: 2, width: '2px', color: theme.color.grey[200] }}
        padding={{ top: 4, bottom: 4, left: 4, right: 4 }}
      >
        <GridRow padding={false}>
          <GridCol lg={6} md={6} sm={6}>
            <Input
              width='100%'
              label='Template Name'
              required
              inputProps={{
                value: name,
                onChange: ({ target: { value } }) => setName(value),
                dataTestid: 'template-name-input',
              }}
            />
          </GridCol>
        </GridRow>
        <br />
        <GridRow padding={false}>
          <GridCol lg={6} md={6} sm={6}>
            <RadioGroup
              label='Data Type'
              name='dataType'
              value={'products'}
              onChange={({ target: { value } }) => {}}
              required
              options={[
                {
                  label: 'Products',
                  value: 'products',
                },
              ]}
            />
          </GridCol>
        </GridRow>
        <br />
        <GridRow padding={false}>
          <GridCol lg={6} md={6} sm={6}>
            <RadioGroup
              label='Direction'
              name='direction'
              value={selectedDirection}
              onChange={(e) => {
                setSelectedDirection(e.target.value)
              }}
              required
              options={[
                ...(!isPlatformCreate
                  ? [
                      {
                        label: 'Import to fabric marketplace',
                        value: 'import',
                      },
                    ]
                  : []),
                {
                  label: 'Export from fabric marketplace',
                  value: 'export',
                },
              ]}
            />
          </GridCol>
        </GridRow>
        <GridRow padding={false}>
          <GridCol lg={6} md={6} sm={6}>
            <p className='h5 pt-4 pb-2'>Sample File</p>
            <p className='body1-regular grey-600 pb-4'>
              Please upload a sample data file to the box below so that we can
              check the column headers
            </p>
            <UploadFileModal setTempfileId={setTempfileId} />
          </GridCol>
        </GridRow>
      </Box>
      {tempfileId !== null && headers.length > 0 ? (
        <ColumnMappings
          headers={headers}
          direction={selectedDirection}
          setColumnMappings={setColumnMappings}
        />
      ) : null}
    </Box>
  )
}

export default CreateProductInventoryTemplate
