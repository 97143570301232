import React, { useState } from 'react'
import Input from 'atoms/input/input'
import Button from 'atoms/button/button'
import { Password } from '@teamfabric/copilot-ui'
import { StyledContainer, StyledInput } from '../styles'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { getUser } from 'api/auth'
import { useDispatch } from 'react-redux'
import { setIsStaff, setUser } from 'store/userSlice'

// import { authenticateUser } from '../../../api/auth'

const AuthenticateLoginTemplate: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const authenticate = async () => {
    const form = new FormData()
    form.set('username', email)
    form.set('password', password)
    form.set('grant_type', 'password')
    form.set('client_id', 'XZuZyWFYbb6NAXEb9SOetrbZYIfKlfRWQLpo9WAs')
    const endpoint = 'https://marketplace-api-staging.fabric.inc/auth/token/'

    axios({
      method: 'post',
      url: endpoint,
      data: form,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then(async function (response) {
        //handle success
        sessionStorage.setItem('mkpAccessToken', response.data.access_token)
        sessionStorage.setItem('mkpRefreshToken', response.data.refresh_token)
        const { data } = await getUser()
        dispatch(setUser(data))
        dispatch(setIsStaff(data && data.is_revcascade))
        navigate('/account')
      })
      .catch(function (response) {
        //handle error
        console.log('err', response)
      })
  }
  return (
    <StyledContainer>
      <h1>Marketplace Login</h1>
      <StyledInput>
        <Input
          label='Email'
          required={true}
          inputProps={{
            onChange: (ev) => setEmail(ev.target.value),
            placeholder: 'Email',
          }}
          placeholder='Email'
          onChangeSet={undefined}
        />
      </StyledInput>
      <StyledInput>
        <Password
          label='Password'
          required={true}
          inputProps={{
            dataTestid: 'password',
            onChange: (ev) => setPassword(ev.target.value),
            id: 'my-password',
          }}
        />
      </StyledInput>
      <Button text='Sign In' onClick={() => authenticate()} />
    </StyledContainer>
  )
}

export default AuthenticateLoginTemplate
