import { useParams } from 'react-router-dom'
import ProductInventoryTemplateCreateTemplate from 'modules/settings/templates/create'
import ExternalPlatformTemplate from 'modules/settings/integrations/externalTemplates/details'

const ExternalPlatformTemplateDetails = () => {
  const { templateId } = useParams()

  // Check if templateId is 'create'
  if (templateId === 'create') {
    return <ProductInventoryTemplateCreateTemplate />
  }

  // Default case: Render ExternalPlatformTemplate
  return <ExternalPlatformTemplate />
}

export default ExternalPlatformTemplateDetails
