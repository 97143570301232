import React, { useMemo, useState } from 'react'
import { MODALS } from 'molecules/modals/constants'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { VARIANTS } from 'lib/constants'
import UploadCostsFile from './uploadCostsFile'
import { getFeedQueue, queueFile } from 'api/proposals'
import UpdateFinished from './updateFinished'

type UpdateCostsModalProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const UpdateCostsModal: React.FC<UpdateCostsModalProps> = ({
  showModal,
  setShowModal,
  proposal,
  loadProposal,
}) => {
  const [loading, setLoading] = useState(false)
  const [tempfileId, setTempfileId] = useState(null)
  const [feed, setFeed] = useState(null)

  const showToast = useToast()

  const handleImportFile = async () => {
    try {
      setLoading(true)
      const queueResponse = await queueFile(tempfileId, proposal?.id)
      const feedQueueResponse = await getFeedQueue({
        id: queueResponse?.data?.id,
      })

      setFeed(feedQueueResponse?.data)
    } catch (error) {
      showToast({
        label: MODALS.EXPORT.TOAST.FAILURE,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setLoading(false)
    }
  }

  const closeModal = (reload?: boolean) => {
    if (reload) {
      loadProposal(true)
    }
    setFeed(null)
    setTempfileId(null)
    setShowModal(false)
  }

  const footerButtons = useMemo(() => {
    if (feed) {
      return [
        {
          dataTestid: '',
          onClick: () => closeModal(true),
          text: 'Close And Refresh',
          isLoading: loading,
        },
      ]
    } else {
      return [
        {
          dataTestid: '',
          onClick: () => closeModal(),
          text: 'Close',
          variant: VARIANTS.SECONDARY,
          isDisabled: loading,
        },
        {
          dataTestid: '',
          onClick: handleImportFile,
          text: 'Import File',
          isDisabled: loading || !tempfileId,
        },
      ]
    }
  }, [loading, feed, tempfileId])

  return (
    <>
      <Modal
        headerText='Update costs'
        size='small'
        onClose={() => closeModal()}
        isVisible={showModal}
        footerButtons={footerButtons}
        dataTestid={''}
        onBackdropClick={() => closeModal()}
      >
        {feed ? (
          <UpdateFinished feed={feed} />
        ) : (
          <UploadCostsFile setTempfileId={setTempfileId} />
        )}
      </Modal>
    </>
  )
}

export default UpdateCostsModal
