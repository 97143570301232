import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import SearchBar from 'molecules/search/searchBarTable'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { BUTTONS, PAGE_TYPE, SEARCH } from 'lib/constants'
import Filters from '../components/filters/cancellations'
import { setSearch } from 'store/cancellationsSlice'
import PageHeaderComponent from 'molecules/PageHeader'
import { GridCol, GridRow, Box } from 'atoms'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import BulkActionsBar from 'organisms/bulkActions'
import { exportCancels, getCancels } from 'api/cancels'
import CancellationsTable from '../components/table/cancellations/cancellationsTable'
import { MODALS } from 'molecules/modals/constants'
import ExportModal from 'molecules/modals/exportModal'

const CancellationsTemplate: FC = () => {
  const params = useSelector((state: RootState) => state.cancellations.params)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const [
    showAcknowledgeCancellationModal,
    setShowAcknowledgeCancellationModal,
  ] = useState(false)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getCancels({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.CANCELLATION_LIST)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <ExportModal
            type={MODALS.EXPORT.TYPES.CANCELLATIONS}
            setShowExportModal={setShowExportModal}
            showExportModal={showExportModal}
            exportCall={exportCancels}
            params={params}
          />
          <PageHeaderComponent
            h1Text='Cancellations'
            primaryButtons={[
              {
                onClick: () => setShowExportModal(true),
                text: BUTTONS.EXPORT.LABEL,
                icon: BUTTONS.EXPORT.LABEL,
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.ORDER}
            setSearch={setSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <Filters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            // TODO: flip to true once bulk actions for invoices implemented
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.CANCELLATIONS}
          />
          <CancellationsTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            currentSortBy={params.order_by}
            totalRows={totalRows}
            showAcknowledgeCancellationModal={showAcknowledgeCancellationModal}
            setShowAcknowledgeCancellationModal={
              setShowAcknowledgeCancellationModal
            }
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default CancellationsTemplate
