import React, { useEffect, useState } from 'react'
import { Modal } from 'atoms'
import { formatDate } from 'lib/utils/date/formatDate'
import CardWithTable from 'molecules/cards/cardWithTable'
import MerchantStatus, {
  getMerchantStatus,
} from 'molecules/shipment/merchantStatus'
import CarrierStatus, {
  getCarrierStatus,
} from 'molecules/shipment/carrierStatus'
import TrackingNumberCol from 'modules/orders/components/table/order/detail/TrackingNumberCol'
import ShipmentDetail from 'modules/orders/components/modal/orders/shipmentDetails'
import PackingSlip from 'modules/orders/components/modal/tracking/packingSlip'
import { useToast } from '@teamfabric/copilot-ui'

type TrackingNumberProps = {
  loading: boolean
  details: any
}

const TrackingNumbers: React.FC<TrackingNumberProps> = ({
  loading,
  details,
}) => {
  const [trackingDetails, setTrackingDetails] = useState<any[]>([])
  const [selectedTrackingIDs, setSelectedTrackingIDs] = useState<any[]>([])
  const [shipment, setShipment] = useState(null)
  const [displayShipmentDetails, setDisplayShipmentDetails] = useState(false)
  const [displayPackingSlip, setDisplayPackingSlip] = useState(false)
  const showToast = useToast()

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const getService = ({ shipping_method, shipping_provider_identifier }) => {
    if (shipping_provider_identifier !== null) {
      return shipping_method.carrier.name
      // otherwise, check for the presence of a shipping method name
    } else if (shipping_method.name) {
      return shipping_method.name
      // if shipping method is not set, check for a carrier
    } else if ('carrier' in shipping_method && shipping_method.carrier.name) {
      return shipping_method.carrier.name
    }
    return 'Not Specified'
  }

  const formatData = ({ shipments }) => {
    const formattedDetails = shipments.map((shipment) => ({
      id: shipment.id,
      data: {
        'Tracking Number': {
          onRender: () => (
            <TrackingNumberCol
              shipment={shipment}
              onClick={() => {
                setDisplayShipmentDetails(true)
                setShipment(shipment)
              }}
            />
          ),
        },
        'Service': {
          value: getService(shipment),
        },
        'Registered': {
          value: shipment.shipped_at
            ? formatDate({ date: shipment.shipped_at })
            : 'N/A',
        },
        'Merchant Status': {
          onRender: () => (
            <MerchantStatus
              status={shipment.status}
              acknowledged_at={shipment.acknowledged_at}
            />
          ),
        },
        'Carrier Status': {
          onRender: () => (
            <CarrierStatus
              tracking={shipment.tracking}
              tracking_url={shipment.tracking_url}
            />
          ),
        },
      },
    }))
    setTrackingDetails(formattedDetails)
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Tracking Number',
    },
    {
      sortable: false,
      title: 'Service',
    },
    {
      sortable: false,
      title: 'Registered',
    },
    {
      sortable: false,
      title: 'Merchant Status',
    },
    {
      sortable: false,
      title: 'Carrier Status',
    },
  ]

  const getFooterButtons = (shipment) => {
    // if no valid packing slip, only show the Close button
    let footerButtons = [
      {
        onClick: () => setDisplayShipmentDetails(false),
        text: 'Close',
        variant: 'secondary',
      },
    ]
    if (shipment?.valid_packing_slip) {
      footerButtons.push({
        onClick: () => setDisplayPackingSlip(true),
        text: 'View packing slip',
        variant: 'primary',
      })
    }
    return footerButtons
  }

  const getSelectedTrackingData = () => {
    return details.shipments
      .filter(({ id }) => selectedTrackingIDs.includes(id))
      .map((shipment) => {
        return JSON.stringify({
          ID: shipment.id,
          Service: getService(shipment),
          Registered: shipment.shipped_at
            ? formatDate({ date: shipment.shipped_at })
            : 'N/A',
          MerchantStatus: getMerchantStatus({
            status: shipment.status,
            acknowledged_at: shipment.acknowledged_at,
          }).name,
          CarrierStatus: getCarrierStatus({ tracking: shipment.tracking }).name,
        })
      })
  }

  const copyTrackingInfo = async () => {
    try {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(getSelectedTrackingData())
        showToast({
          label: 'copied!',
          isDismissable: true,
          id: 'copied-tracking',
        })
      }
    } catch (error) {
      showToast({
        label: 'something went wrong',
        variant: 'error',
        isDismissable: true,
        id: 'error-copy-tracking',
      })
    }
  }

  const onClear = () => {
    const updatedData = trackingDetails.map((tracking) => ({
      ...tracking,
      selected: false,
    }))
    setTrackingDetails(updatedData)
    setSelectedTrackingIDs([])
  }

  return (
    <>
      <CardWithTable
        header='Tracking Numbers'
        loading={loading}
        tableProps={{
          emptyTableText: 'You have no results.',
          data: trackingDetails,
          columns: tableColumns,
          selectable: true,
          onRowSelect: (e, rowDetails, selected) =>
            setSelectedTrackingIDs([...selectedTrackingIDs, rowDetails.id]),
          bulkActions: {
            selectedItemCount: 0,
            onClear,
            actions: [
              {
                text: 'Delete',
                icon: 'Trash',
                onClick: () => {},
              },
              {
                text: 'Copy',
                icon: 'Copy',
                onClick: () => copyTrackingInfo(),
              },
            ],
          },
        }}
        customClassName='mb-4'
      />
      {/* Shipment Detail Modal */}
      <Modal
        headerText='Shipment Details'
        description=''
        size='small'
        onClose={() => setDisplayShipmentDetails(false)}
        isVisible={displayShipmentDetails}
        footerButtons={getFooterButtons(shipment)}
        onBackdropClick={() => setDisplayShipmentDetails(false)}
      >
        <ShipmentDetail shipmentId={shipment?.id} />
      </Modal>
      {/* Packing slip Modal */}
      <Modal
        headerText='Packing Slip'
        size='small'
        onClose={() => setDisplayPackingSlip(false)}
        isVisible={displayPackingSlip}
        footerButtons={[
          {
            onClick: () => setDisplayPackingSlip(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        onBackdropClick={() => setDisplayPackingSlip(false)}
      >
        <PackingSlip shipmentId={shipment?.id} />
      </Modal>
    </>
  )
}

export default TrackingNumbers
