import React, { useEffect, useState } from 'react'
import { GridCol, GridRow, PageHeader } from 'atoms'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import VerticalProgressStepper from 'atoms/verticalProgressStepper'
import SelectSupplier from './selectSupplier'
import UploadFile from './uploadFile'
import { addFeedRequest } from 'api/products'
import { useToast } from '@teamfabric/copilot-ui'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'routes/pageRoutes'

const STEPS = {
  SELECT_SUPPLIER: 'select-supplier',
  FILE_UPLOAD: 'file-upload',
  SUBMIT: 'submit',
}

const ImportProductData: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [submitData, setSubmitData] = useState({
    connectionBrand: {
      id: '',
      label: '',
    },
    templateId: '',
    referenceNumber: null,
    behavior: 'merge',
    postProcessing: '',
    exportTemplateId: '',
  })
  const [tempfileId, setTempfileId] = useState('')

  const showToast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.IMPORT_REQUESTS)
  }, [])

  const getSelected = (stepName: string) => {
    return (
      stepName === STEPS.SELECT_SUPPLIER ||
      (stepName === STEPS.FILE_UPLOAD && isSelectSupplierDone()) ||
      (stepName === STEPS.SUBMIT && tempfileId !== '')
    )
  }

  const getState = ({ isDone, stepName }) => {
    if (isDone) {
      return 'COMPLETED'
    }
    if (getSelected(stepName)) {
      return 'STARTED'
    }
    return 'NOT_STARTED'
  }

  const isSelectSupplierDone = () => {
    return submitData.connectionBrand.id !== '' && submitData.templateId !== ''
  }

  const isReady = () => {
    if (
      submitData.postProcessing === 'publish' &&
      submitData.exportTemplateId === ''
    ) {
      return false
    }
    return !loading && submitData.templateId !== '' && tempfileId !== ''
  }

  const getVerticalProgressStepperSteps = [
    {
      name: 'Select Supplier',
      onClick: () => {},
      state: getState({
        isDone: isSelectSupplierDone(),
        stepName: STEPS.SELECT_SUPPLIER,
      }),
      selected: getSelected(STEPS.SELECT_SUPPLIER),
      stepInfo: 'Select approving import partner',
      subSteps: [],
    },
    {
      name: 'Select a file to upload',
      onClick: () => {},
      state: getState({
        isDone: tempfileId !== '',
        stepName: STEPS.FILE_UPLOAD,
      }),
      selected: getSelected(STEPS.FILE_UPLOAD),
      stepInfo: '',
      subSteps: [],
    },
    {
      name: 'Submit your request to your partner',
      onClick: () => {},
      selected: getSelected(STEPS.SUBMIT),
      state: isReady() ? 'STARTED' : 'NOT_STARTED',
      stepInfo: '',
      subSteps: [],
    },
  ]

  const body = {
    brand: {
      id: submitData.connectionBrand.id,
    },
    template: {
      id: submitData.templateId,
    },
    reference_number:
      submitData.referenceNumber === '' ? null : submitData.referenceNumber,
    direction: 'import',
    data_type: 'products',
    source: 'console',
    delimiter: 'comma',
    behavior: submitData.behavior,
    info: {},
    feed_file_id: tempfileId,
    push_to_shopify: submitData.postProcessing === 'publish' ? 1 : 0,
    shopify_template_id:
      submitData.postProcessing === 'publish'
        ? submitData.exportTemplateId
        : null,
  }

  const queueFile = async () => {
    try {
      setLoading(true)
      await addFeedRequest({ body })
      showToast({
        label: `Import request successfully created for ${submitData.connectionBrand.label}`,
        isDismissable: true,
        id: 'queue-file',
      })
      navigate(PATHS.ImportRequests)
    } catch ({
      response: {
        data: { detail },
      },
    }) {
      showToast({
        label: detail,
        isDismissable: true,
        id: 'error-queue',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='container'>
      <div className='mb-3'>
        <PageHeader
          h1Text='Import Product Data'
          h2Text={
            <div style={{ width: '70%' }}>
              <p>
                As a merchant, you may request approval to upload products on
                behalf of a connected supplier. When you submit a request, the
                supplier will have a chance to review and approve (or decline)
                the request.
              </p>
            </div>
          }
          primaryButtons={[
            {
              onClick: queueFile,
              text: isReady() ? 'Submit Request' : 'Next',
              isDisabled: !isReady(),
              variant: 'primary',
            },
            {
              onClick: () => navigate(PATHS.ImportRequests),
              text: 'Cancel',
              variant: 'secondary',
            },
          ]}
        />
      </div>
      <GridRow padding={false}>
        <GridCol lg={3} md={3} sm={3}>
          <VerticalProgressStepper steps={getVerticalProgressStepperSteps} />
        </GridCol>
        <GridCol lg={9} md={9} sm={9}>
          <SelectSupplier setSubmitData={setSubmitData} />
          {isSelectSupplierDone() && (
            <UploadFile setTempfileId={setTempfileId} />
          )}
        </GridCol>
      </GridRow>
    </div>
  )
}

export default ImportProductData
