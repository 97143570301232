import React from 'react'
import { Checkbox as CheckboxUI } from '@teamfabric/copilot-ui'

type CheckboxProps = {
  label: string
  name: string
  value: string
  checked?: boolean
  disabled?: boolean
  onChange: (e: any) => void
  onClick?: (e: any) => void
}

const Checkbox: React.FC<CheckboxProps> = ({ ...props }) => {
  return <CheckboxUI {...props} />
}

export default Checkbox
