import React from 'react'
import Image from 'atoms/image/image'
import Skeleton from 'atoms/skeleton/skeleton'

type ProductHeaderProps = {
  variant: any
  loading: boolean
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ variant, loading }) => {
  return (
    <div className='width-100'>
      {loading ? (
        <div className='flex-start width-100'>
          <Skeleton height='64px' width='64px' />
          <div className='mr-2'></div>
          <Skeleton height='64px' width='80%' />
        </div>
      ) : (
        <div className='v-center gap-2 is-flex align-start'>
          <Image alt='item' size='medium' src={variant?.media[0]?.url} />
          <div className='flex-column-start mb-2'>
            <span className='font-l'> {variant.name} </span>
            <span className='grey-sm'>
              Supplier SKU {variant.brand_identifier}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductHeader
