import React, { useState, ChangeEvent, useEffect } from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import Input from 'atoms/input/input'
import SingleDatePicker from 'atoms/input/singleDatePicker'
import MultiLineTextArea from 'atoms/textField/multiLineTextArea'
import Checkbox from 'atoms/input/checkbox'
import Dropdown from 'atoms/dropdown/dropdown'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import moment from 'moment'

type BasicDetailsProps = {
  setSupplierName: (e: any) => void
  setSupplierNumber: (e: any) => void
  targetDate: any
  setTargetDate: (e: any) => void
  setFirstName: (e: any) => void
  setLastName: (e: any) => void
  setEmailAddress: (e: any) => void
  messageVisibility: any
  setMessageVisibility: (e: any) => void
  setConnectionNote: (e: any) => void
}

const BasicDetailsCard: React.FC<BasicDetailsProps> = ({
  setSupplierName,
  setSupplierNumber,
  targetDate,
  setTargetDate,
  setFirstName,
  setLastName,
  setEmailAddress,
  messageVisibility,
  setMessageVisibility,
  setConnectionNote,
}) => {
  const isStaff = useSelector((state: RootState) => state.user.isStaff)
  const [addConnectionNote, setAddConnectionNote] = useState(false)

  const messageVisibilityOptions = [
    { id: 'retailer', label: 'Retailer only' },
    { id: 'any', label: 'Retailer & Supplier' },
    ...(isStaff ? [{ id: 'brand', label: 'Supplier only' }] : []),
  ]

  return (
    <Card
      headerContent='Basic Details'
      showCollapse={false}
      bodyContent={
        <div className='p-0'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Supplier Name'
                required={true}
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setSupplierName(value)
                  },
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={3} md={3} sm={3}>
              <Input
                width='100%'
                label='Supplier Number'
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setSupplierNumber(value)
                  },
                }}
              />
            </GridCol>
            <GridCol lg={3} md={3} sm={3}>
              <SingleDatePicker
                label='Target Launch Date'
                minDate={new Date()}
                value={targetDate}
                defaultValue={undefined}
                format='MM/dd/yyyy'
                onSelect={(date: Date) => setTargetDate(date)}
                inputWidth='100%'
              />
            </GridCol>
          </GridRow>
          <br />
          <div className='line-separator'></div>
          <p className='body1-medium pt-4 pb-4'>Primary Contact</p>
          <GridRow padding={false}>
            <GridCol lg={3} md={3} sm={3}>
              <Input
                width='100%'
                label='First Name'
                required={true}
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setFirstName(value)
                  },
                }}
              />
            </GridCol>
            <GridCol lg={3} md={3} sm={3}>
              <Input
                width='100%'
                label='Last Name'
                required={true}
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setLastName(value)
                  },
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Email Address'
                required={true}
                inputProps={{
                  onChange: ({ target: { value } }) => {
                    setEmailAddress(value)
                  },
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <div className='line-separator'></div>
          <p className='body1-medium pt-4 pb-4'>Connection note</p>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Checkbox
                label={'Include Connection notes.'}
                name={''}
                value={''}
                checked={addConnectionNote}
                onChange={(e) => setAddConnectionNote(e.target.checked)}
              />
            </GridCol>
          </GridRow>
          <br />
          {addConnectionNote && (
            <React.Fragment>
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <MultiLineTextArea
                    width='100%'
                    inputProps={{
                      onChange: ({ target: { value } }) => {
                        setConnectionNote(value)
                      },
                    }}
                    label={''}
                  />
                </GridCol>
              </GridRow>
              <br />
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <Dropdown
                    width='100%'
                    label={'Connection Level'}
                    value={messageVisibility}
                    onChange={(option) => setMessageVisibility(option)}
                    options={messageVisibilityOptions}
                  />
                </GridCol>
              </GridRow>
            </React.Fragment>
          )}
        </div>
      }
    />
  )
}

export default BasicDetailsCard
