import React, { useEffect, useRef, useState } from 'react'
import { exportOrder, getAgeSummary, getOrders } from 'api/orders'
import OrderTable from '../components/table/order/orderTable'
import SearchBar from 'molecules/search/searchBarTable'
import Tab from 'atoms/tab/tab'
import ExportModal from 'molecules/modals/exportModal'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { MODALS } from 'molecules/modals/constants'
import { BUTTONS, PAGE_TYPE, SEARCH } from 'lib/constants'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import Filters from '../components/filters/orders/index'
import { resetAllFilters, setSearch } from 'store/orderSlice'
import { ORDERS } from '../constants'
import { sortBy } from 'lodash'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import BulkAddTag from '../components/modal/tags/bulkAddTags'
import BulkRemoveTag from '../components/modal/tags/bulkRemoveTag'

const OrderListTemplate: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const [loadingAgeSummary, setLoadingAgeSummary] = useState(true)
  const [currentOrders, setCurrentOrders] = useState(0)
  const [pastFulfillmentOrders, setPastFulfillmentOrders] = useState(0)
  const [lateInvoices, setLateInvoices] = useState(0)
  const [showAddTagModal, setShowAddTagModal] = useState(false)
  const [showRemoveTagModal, setShowRemoveTagModal] = useState(false)
  const [isBulkAddedTags, setIsBulkAddedTags] = useState(false)

  const params = useSelector((state: RootState) => state.orders.params as any)
  const selected = useSelector((state: RootState) => state.orders.selected)
  const selectedTabPreset = useSelector(
    (state: RootState) => state.orders.selectedTabPresetIndex
  )

  const [activeTabIndex, setActiveTabIndex] = useState(selectedTabPreset)

  const dispatch = useDispatch()

  const isInitialRender = useRef(true)

  useEffect(() => {
    //  Fetch age summary data
    const fetchAgeSummary = async () => {
      setLoadingAgeSummary(true)
      const { data } = await getAgeSummary({})
      const vendors = sortBy(data, 'name')
      let [pastFulfillment, awaitingInvoices, totalCurrent, totalOpen] = [
        0, 0, 0, 0,
      ]
      vendors.forEach((vendor) => {
        pastFulfillment += vendor.late_orders_awaiting_fulfillment
        awaitingInvoices += vendor.late_orders_awaiting_invoicing
        totalCurrent +=
          vendor.current_orders_awaiting_fulfillment +
          vendor.current_orders_awaiting_invoicing
      })
      setCurrentOrders(totalCurrent)
      setPastFulfillmentOrders(pastFulfillment)
      setLateInvoices(awaitingInvoices)
      setLoadingAgeSummary(false)
    }

    // Fetch age summary data when component mounts
    fetchAgeSummary()
  }, [])

  useEffect(() => {
    // Skip the first load
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    if (activeTabIndex === 0) {
      dispatch(resetAllFilters({}))
    } else if (activeTabIndex === 1) {
      dispatch(resetAllFilters({ tabPresets: ORDERS.TAB_PRESETS.CURRENT }))
    } else if (activeTabIndex === 2) {
      dispatch(resetAllFilters({ tabPresets: ORDERS.TAB_PRESETS.PAST_SLA }))
    } else if (activeTabIndex === 3) {
      dispatch(
        resetAllFilters({ tabPresets: ORDERS.TAB_PRESETS.REQUIRE_INVOICE })
      )
    } else if (activeTabIndex === 4) {
      dispatch(resetAllFilters({ tabPresets: ORDERS.TAB_PRESETS.COMPLETED }))
    }
  }, [activeTabIndex])

  useEffect(() => {
    if (params || isBulkAddedTags) {
      ;(async () => {
        setIsBulkAddedTags(false)
        setLoading(true)
        const { data } = await getOrders({ params })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params, isBulkAddedTags])

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.ORDER_LISTING)
  }, [])

  const removeTableTags = (removedTags: string[]) => {
    const updatedOrderData = tableData.map((obj) => {
      if (selected.includes(obj.id)) {
        return {
          ...obj,
          tags: obj.tags.filter(({ id }) => !removedTags.includes(id)),
        }
      }
      return obj
    })
    setTableData(updatedOrderData)
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      {/* Modals */}
      {selected.length > 0 && (
        <BulkAddTag
          setDisplayModal={setShowAddTagModal}
          isVisible={showAddTagModal}
          addedTags={(isAdded) => setIsBulkAddedTags(isAdded)}
        />
      )}
      {selected.length > 0 && tableData && (
        <BulkRemoveTag
          setDisplayModal={setShowRemoveTagModal}
          isVisible={showRemoveTagModal}
          tableData={tableData}
          removeTableTags={removeTableTags}
        />
      )}
      <GridRow>
        <GridCol md={12}>
          <ExportModal
            type={MODALS.EXPORT.TYPES.ORDERS}
            setShowExportModal={setShowExportModal}
            showExportModal={showExportModal}
            exportCall={exportOrder}
          />
          <Tab
            data={[
              { label: ORDERS.TAB_PRESETS.ALL },
              {
                label:
                  ORDERS.TAB_PRESETS.CURRENT +
                  (loadingAgeSummary ? '' : ` (${currentOrders})`),
              },
              {
                label:
                  ORDERS.TAB_PRESETS.PAST_SLA +
                  (loadingAgeSummary ? '' : ` (${pastFulfillmentOrders})`),
              },
              {
                label:
                  ORDERS.TAB_PRESETS.REQUIRE_INVOICE +
                  (loadingAgeSummary ? '' : ` (${lateInvoices})`),
              },
              { label: ORDERS.TAB_PRESETS.COMPLETED },
            ]}
            setLabel={setActiveTabIndex}
            activeIndex={activeTabIndex}
            component={
              <Box width='100%' padding={{ top: 6, bottom: 4 }}>
                <PageHeaderComponent
                  h1Text='Orders'
                  primaryButtons={[
                    {
                      onClick: () => setShowExportModal(true),
                      text: BUTTONS.EXPORT.LABEL,
                      icon: BUTTONS.EXPORT.LABEL,
                      variant: 'primary',
                    },
                  ]}
                  customClassName='mb-4'
                />
                <SearchBar
                  placeholder={SEARCH.PLACEHOLDERS.ORDER_NUMBER}
                  setSearch={setSearch}
                  className='mt-4'
                  value={
                    params.search ||
                    params.customer_order_number ||
                    params.purchase_order_number ||
                    params.purchase_order_number_sw ||
                    params.customer_order_number_sw
                  }
                />
                <div className='filterDiv'>
                  <Filters params={params} />
                </div>
                <BulkActionsBar
                  loading={loading}
                  hasBulkActions={true}
                  totalRows={totalRows}
                  currentPage={params.page}
                  pageType={PAGE_TYPE.ORDERS}
                  selectedItems={selected}
                  moreActions={[
                    {
                      label: 'Add Tags',
                      onClick: () => setShowAddTagModal(true),
                    },
                    {
                      label: 'Remove Tags',
                      onClick: () => setShowRemoveTagModal(true),
                    },
                  ]}
                />
                <OrderTable
                  data={tableData}
                  loading={loading}
                  totalRows={totalRows}
                  currentPage={params.page}
                  currentSortBy={params.order_by}
                />
              </Box>
            }
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OrderListTemplate
