import { FC, useEffect, useState } from 'react'
import { StyledWrapper } from '../../styles'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import { getConnectionDetail } from 'api/connection'
import { useParams } from 'react-router-dom'
import HorizontalTab from 'atoms/tab'
import PageHeaderComponent from 'molecules/PageHeader'
import SummaryDetails from 'organisms/connections/detail/Summary'
import AttributeDetails from 'organisms/connections/detail/Attributes'
import CarrierDetails from 'organisms/connections/detail/Carriers'

const MerchantDetailsTemplate: FC = () => {
  // const params = useSelector((state: RootState) => state.merchant.params)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [label, setLabel] = useState(0)

  useEffect(() => {
    ;(async () => {
      const { data } = await getConnectionDetail({ id })
      setData(data)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.CONNECTION_DETAILS)
  }, [])

  return (
    <StyledWrapper>
      <PageHeaderComponent
        loading={loading}
        badgeProps={{
          label: 'Active',
          status: 'success',
        }}
        h1Text={data?.retailer?.name}
        h2Text={`Connection ID: ${id}`}
        customClassName='mb-4'
      />
      <HorizontalTab
        onTabChange={setLabel}
        data={{
          tabs: [
            {
              label: 'Summary',
              content: <SummaryDetails loading={loading} details={data} />,
            },
            {
              label: 'Attributes',
              content: <AttributeDetails loading={loading} details={data} />,
            },
            {
              label: 'Carriers',
              content: <CarrierDetails loading={loading} details={data} />,
            },
          ],
        }}
      />
    </StyledWrapper>
  )
}

export default MerchantDetailsTemplate
