import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataProps } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import { PageHeader } from 'atoms'
import SupplierInvitationsFilter from '../components/filter/invitations'
import SearchBar from 'molecules/search/searchBarTable'
import { getConnectionInvites } from 'api/connection'
import SupplierInvitationsTable from '../components/table/invitations'
import { setInvitationsSearch } from 'store/suppliersSlice'
import { GridCol, GridRow, Box } from 'atoms'
import {
  SupplierPageTypes,
  breadcrumbs,
} from 'lib/constants/breadcrumbsHandler'

const SuppliersInvitationsTemplate: FC = () => {
  const params = useSelector(
    (state: RootState) => state.suppliers.invitationParams
  )

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)

  const [totalRows, setTotalRows] = useState(0)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const filteredParams = {}
        // skipping empty keys
        for (const key in params) {
          if (params[key] !== '') {
            filteredParams[key] = params[key]
          }
        }
        const { data: data } = await getConnectionInvites({
          params: { ...filteredParams },
        })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  useEffect(() => {
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.supplierPages({ type: SupplierPageTypes.Invite })
        ),
      0
    )
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeader h1Text='Invitations' />
          <SearchBar
            placeholder='Search within results'
            setSearch={setInvitationsSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <SupplierInvitationsFilter params={params} />
          </div>
          <SupplierInvitationsTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            currentSortBy={params.order_by}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default SuppliersInvitationsTemplate
