import { ProgressBar as ProgressBarUI } from '@teamfabric/copilot-ui'
import React from 'react'

export type ProgressBarProps = {
  label?: any
  progress: any
  variant?: 'horizontal' | 'circular'
  size?: number
  fullWidth?: boolean
}

const ProgressBar: React.FC<ProgressBarProps> = ({ ...props }) => {
  return <ProgressBarUI {...props} />
}

export default ProgressBar
