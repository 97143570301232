import { FC, useEffect, useState } from 'react'
import { CardProps } from 'lib/utils/props/cardProps'
import { formatDate } from 'lib/utils/date/formatDate'
import Card2Col from 'molecules/cards/2Col'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import { formatAddress } from 'modules/orders/utils/index'
import VariantItem from 'molecules/lineItem/variantItem'
import { Table } from 'atoms'
import Box from 'atoms/box/box'
import InvoiceStatusPresenter from './invoiceStatusPresenter'

type ChildProps = {
  invoice: any
}

const InvoiceDetail: FC<ChildProps> = ({ invoice }) => {
  const [loading, setLoading] = useState(false)
  const [invoiceDetailData, setInvoiceDetailData] = useState<CardProps[]>([])
  const [totalsData, setTotalsData] = useState<CardProps[]>([])
  const [invoiceLinesData, setInvoiceLinesData] = useState<CardProps[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      formatInvoiceDetailData(invoice)
      formatTotalsData(invoice)
      formatInvoiceLinesData(invoice?.invoice_lines)
      setLoading(false)
    })()
  }, [invoice])

  const getAdjustments = (due_amount: number, subtotal: number) => {
    return due_amount - subtotal
  }

  const getLineTotals = (quantity, price) => {
    return parseInt(quantity) * parseFloat(price)
  }

  const formatInvoiceDetailData = (invoice) => {
    const formattedInvoiceDetail = [
      {
        label: 'Invoice number',
        value: invoice?.invoice_number,
      },
      {
        label: 'Invoice date',
        value: formatDate({ date: invoice?.invoiced_at }),
      },
      {
        label: 'ID',
        value: invoice?.id,
      },
      {
        label: 'Customer order number',
        value: invoice?.customer_order_number
          ? invoice.customer_order_number
          : '---',
      },
      {
        label: 'PO number',
        value: invoice?.purchase_order_number
          ? invoice.purchase_order_number
          : '---',
      },
      {
        label: 'Acknowledged',
        value: invoice?.acknowledged_at
          ? formatDate({ date: invoice.acknowledged_at })
          : 'Not Acknowledged',
      },
      {
        label: 'Status',
        value: {
          onRender: () => <InvoiceStatusPresenter invoice={invoice} />,
        },
      },
    ]
    setInvoiceDetailData(formattedInvoiceDetail)
  }

  const formatTotalsData = (invoice) => {
    const formattedTotals = [
      {
        label: 'Subtotal',
        value: formatCurrency(invoice?.locale_subtotal),
      },
      {
        label: 'Adjustments',
        value: formatCurrency(
          getAdjustments(invoice?.locale_due_amount, invoice?.locale_subtotal)
        ),
      },
      {
        label: 'Due Amount',
        value: formatCurrency(invoice?.locale_due_amount),
      },
    ]
    setTotalsData(formattedTotals)
  }

  const invoiceLinesColumns = [
    // setting definitive width to columns to prevent horizontal scroll inside a modal
    {
      sortable: false,
      title: 'Item',
      width: 150,
    },
    {
      sortable: false,
      title: 'Qty',
      width: 30,
    },
    {
      sortable: false,
      title: 'Unit cost',
      width: 75,
    },
    {
      sortable: false,
      title: 'Line total',
      width: 75,
    },
  ]

  const formatInvoiceLinesData = (data: any) => {
    const formattedData = data.map(
      ({ id, variant, quantity, locale_price }) => {
        return {
          id,
          data: {
            'Item': {
              onRender: () => <VariantItem variant={variant} />,
            },
            'Qty': { value: quantity },
            'Unit cost': { value: formatCurrency(locale_price) },
            'Line total': {
              value: formatCurrency(getLineTotals(quantity, locale_price)),
            },
          },
        }
      }
    )
    setInvoiceLinesData(formattedData)
  }

  return (
    <Box flex={{ flexDirection: 'column' }} gap={6}>
      <Card2Col
        loading={loading}
        showCollapse={false}
        header='Invoice Details'
        data={invoiceDetailData}
      />
      <div>
        <span className='h5'>Remit to</span>
        <p className='ui-card-content-style-match-no-margin'>
          {formatAddress(invoice?.remit_to)}
        </p>
      </div>
      <Box flex={{ flexDirection: 'column' }} gap={2}>
        <span className='h5'>Invoice lines</span>
        <Table
          columns={invoiceLinesColumns}
          data={invoiceLinesData}
          loading={loading}
          activePage={1}
          selectable={false}
          totalRecords={invoiceLinesData?.length}
        />
      </Box>
      <Card2Col
        loading={loading}
        showCollapse={false}
        header='Totals'
        data={totalsData}
        customClassName='mb-4'
      />
    </Box>
  )
}

export default InvoiceDetail
