import React from 'react'
import Modal from 'atoms/modal/modal'
import { useDispatch } from 'react-redux'
import { setCurrentStep } from 'store/onboardingSlice'

type PageMoveToProps = {
  value: string
  parent: string
}

type LeaveWithoutSavingModalProps = {
  isVisible: boolean
  setIsDisplayModal: (value: boolean) => void
  pageMoveTo: PageMoveToProps
}

const LeaveWithoutSavingModal: React.FC<LeaveWithoutSavingModalProps> = ({
  isVisible,
  setIsDisplayModal,
  pageMoveTo,
}) => {
  const dispatch = useDispatch()

  return (
    <Modal
      headerText='Leave page without saving?'
      description='You haven’t finished saving your changes, your changes will be lost if you leave. Do you want to leave without saving?'
      onClose={() => setIsDisplayModal(false)}
      isVisible={isVisible}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => {
            setIsDisplayModal(false)
          },
          text: 'No, stay on the page',
          variant: 'secondary',
        },
        {
          dataTestid: '1',
          onClick: () => {
            dispatch(
              setCurrentStep({
                value: pageMoveTo.value,
                parent: pageMoveTo.parent,
              })
            )
            setIsDisplayModal(false)
          },
          text: 'Yes, leave now',
          variant: 'destructive',
        },
      ]}
      dataTestid={''}
      size='medium'
      onBackdropClick={() => setIsDisplayModal(false)}
    />
  )
}

export default LeaveWithoutSavingModal
