//importing directly from the lib as the ToolTip doesn't render otherwise
import { InteractiveIcon } from '@teamfabric/copilot-ui'
import { ToolTip } from 'atoms'
import { FC, useMemo, useState } from 'react'
import RemoveProposalItemsModal from '../modals/removeProposalItem'
import CreateIssueModal from '../modals/createIssue'
import AddToOpenIssueModal from '../modals/addToOpenIssue'
import RemoveProposalProductModal from '../modals/removeProposalProduct'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface ProductsActionsProps {
  product: any
  options: any
  isProduct?: boolean
  loadProposal: (quiet?: boolean) => void
  loadProducts: (quiet?: boolean) => void
  onApproveProduct?: () => void
  productStatus?: string
}

const ProductsActionsMenu: FC<ProductsActionsProps> = ({
  product,
  isProduct,
  loadProposal,
  loadProducts,
  onApproveProduct,
  productStatus,
}) => {
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [showRemoveProduct, setShowRemoveProduct] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const canAddToOpenIssue = useMemo(() => {
    if (proposalDetails?.issues_summary.open > 0) return true
    return false
  }, [proposalDetails?.issues_summary])

  const quickActions = useMemo(() => {
    let actions = []
    if (!isProduct) {
      actions.push(
        {
          onClick: () => {
            setShowAddToOpenIssue(true)
          },
          icon: 'PaperClip',
          tooltipText: canAddToOpenIssue
            ? 'Add to open issue'
            : 'No open issues, create one instead',
          disabled: !canAddToOpenIssue,
        },
        {
          onClick: () => {
            setShowCreateIssue(true)
          },
          icon: 'Add',
          tooltipText: 'Create issue',
        }
      )
      if (proposalDetails?.status === 'proposed') {
        actions.push({
          onClick: () => {
            setShowRemoveProduct(true)
          },
          icon: 'Trash',
          tooltipText: 'Remove item',
        })
      }
    } else {
      actions.push(
        {
          onClick: () => {
            setShowRemoveProduct(true)
          },
          icon: 'Trash',
          tooltipText:
            productStatus && productStatus === 'rejected'
              ? 'Product already rejected'
              : 'Remove product',
          disabled: productStatus && productStatus === 'rejected',
        },
        {
          onClick: () => {
            onApproveProduct?.()
          },
          icon: 'Check',
          tooltipText:
            productStatus && productStatus === 'approved'
              ? 'Product already approved'
              : 'Approve product',
          disabled: productStatus && productStatus === 'approved',
        }
      )
    }
    return actions
  }, [proposalDetails, productStatus])

  return (
    <>
      <div className='show-on-row-hover table-action-row'>
        {quickActions.map((option, index) => (
          <ToolTip text={option.tooltipText} key={index}>
            <InteractiveIcon
              onClick={() => !option.disabled && option.onClick()}
              iconName={option.icon}
              size={16}
              disabled={option.disabled}
            />
          </ToolTip>
        ))}
      </div>
      {/* Add to open issue modal */}
      <AddToOpenIssueModal
        count={1}
        itemIdArray={[product?.proposal_variant?.id]}
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProposal}
      />
      {/* Create new issue modal */}
      <CreateIssueModal
        count={1}
        itemIdArray={[product?.proposal_variant?.id]}
        showModal={showCreateIssue}
        setShowModal={setShowCreateIssue}
        isProduct={isProduct}
        loadProposal={loadProposal}
      />
      {/* product and variant has different endpoints */}
      {isProduct ? (
        <RemoveProposalProductModal
          showModal={showRemoveProduct}
          setShowModal={setShowRemoveProduct}
          product={product}
          loadProducts={loadProducts}
        />
      ) : (
        /* Remove items modal */
        <RemoveProposalItemsModal
          count={1}
          itemIdArray={[product.id]}
          showModal={showRemoveProduct}
          setShowModal={setShowRemoveProduct}
          reload={loadProducts}
        />
      )}
    </>
  )
}

export default ProductsActionsMenu
