import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { Card, Box, Link, Table, ToolTip, InteractiveIcon, Button } from 'atoms'
import { capitalizeFirstLetter } from 'lib/utils'
import PageHeader from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { getCompatibilityRulesetRules, putRuleValidator } from 'api/rulesets'
import { formatDate } from 'lib/utils/date/formatDate'
import BulkActionsBar from 'organisms/bulkActions'
import RulesModal from './rulesModal'
import RuleValidatorModal from './ruleValidatorModal'

export interface RuleGroupType {
  id: number
  name: string
  is_required: boolean
  updated_at: string
  position: number | string
  target: string
  rule_validators: any[]
  rule_conditions: any[]
}

type ChildProps = {}

const RulesTable: FC<ChildProps> = ({}) => {
  const { id, ruleGroupId } = useParams()
  const [loading, setLoading] = useState(true)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [rules, setRules] = useState<RuleGroupType[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [showRulesModal, setShowRulesModal] = useState(false)
  const [rulesModalType, setRulesModalType] = useState(null)
  const [showRuleValidatorModal, setShowRuleValidatorModal] = useState(false)
  const [ruleValidatorModalType, setRuleValidatorModalType] = useState(null)

  const fetchRules = async () => {
    const { data } = await getCompatibilityRulesetRules({
      id: id,
      params: {
        rule_group_id: ruleGroupId,
        order_by: '-id',
        limit: 10,
        page: currentPage,
      },
    })
    setRules(data.results)
    setTotalCount(data.count)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchRules()
      })()
    }
  }, [id, currentPage])

  useEffect(() => {
    if (!loading) {
      formatSetTableData({ data: rules })
    }
  }, [loading, rules])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: '10%',
      },
      {
        title: 'Name',
        width: '20%',
      },
      {
        title: 'Position',
        width: '15%',
      },
      {
        title: 'Target',
        width: '15%',
      },
      {
        title: 'Is Required?',
        width: '15%',
      },
      {
        title: 'Updated At',
        width: '15%',
      },
      {
        title: 'Validators',
        width: '10%',
      },
    ]
    return columns
  }, [loading, rules])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((rule, id) => {
      return {
        id,
        data: {
          'ID': { value: rule?.id },
          'Name': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowRulesModal(true)
                  setSelectedRow(rule)
                  setRulesModalType('Edit')
                }}
                label={rule?.name}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Position': { value: rule?.position || '--' },
          'Target': { value: capitalizeFirstLetter(rule?.target) },
          'Is Required?': { value: rule?.is_required ? 'Yes' : 'No' },
          'Updated At': { value: formatDate({ date: rule.updated_at }) },
          'Validators': {
            value: { ...rule },
            onRender: (data) =>
              !rule?.rule_validators.length ? (
                <Button
                  text='Add'
                  variant='tertiary'
                  onClick={() => {
                    setShowRuleValidatorModal(true)
                    setRuleValidatorModalType('Add')
                    setSelectedRow(data.value)
                  }}
                />
              ) : (
                <Button
                  text='Edit'
                  variant='tertiary'
                  onClick={() => {
                    setShowRuleValidatorModal(true)
                    setRuleValidatorModalType('Edit')
                    setSelectedRow(data.value)
                  }}
                />
              ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <>
      <Box dataTestid='rules-table' margin={{ top: 4, bottom: 7 }}>
        {
          <RulesModal
            showModal={showRulesModal}
            setShowModal={setShowRulesModal}
            type={rulesModalType}
            reload={() => {
              fetchRules()
              setSelectedRow(null)
            }}
            selectedRow={selectedRow}
            rules={rules}
          />
        }
        {
          <RuleValidatorModal
            showModal={showRuleValidatorModal}
            setShowModal={setShowRuleValidatorModal}
            type={ruleValidatorModalType}
            reload={() => {
              fetchRules()
              setSelectedRow(null)
            }}
            selectedRow={selectedRow}
            rules={rules}
          />
        }
        <Card
          showCollapse={false}
          headerContent={
            <Box width='100%'>
              <PageHeader
                h1Text={<span className='h5'>Rules</span>}
                h2Text={
                  <span className='body1-regular grey-600'>
                    Add & configure validation rules for individual attributes.
                  </span>
                }
                primaryButtons={[
                  {
                    onClick: () => {
                      setShowRulesModal(true)
                      setRulesModalType('Create')
                      setSelectedRow(null)
                    },
                    text: 'Create Rule',
                    variant: 'tertiary',
                    icon: 'Add',
                    iconPosition: 'left',
                  },
                ]}
              />
            </Box>
          }
          bodyContent={
            <>
              <BulkActionsBar
                loading={loading}
                hasBulkActions={false}
                totalRows={totalCount}
                currentPage={currentPage}
              />
              <Table
                columns={tableColumns}
                data={tableData}
                loading={loading}
                disableTableBorder
                emptyTableText='Rule are not yet added to this rulegroup!'
                customPaginationProps={{
                  handlePagination: (pageNumber: number) => {
                    setCurrentPage(pageNumber)
                  },
                  activePageNumber: currentPage,
                  perPage: 10,
                  totalRecords: totalCount,
                }}
              />
            </>
          }
        ></Card>
      </Box>
    </>
  )
}

export default RulesTable
