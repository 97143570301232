import React, { useMemo } from 'react'
import Box from 'atoms/box/box'
import Link from 'atoms/link/link'
import { StyledTableCaption, StyledTableSelectInfoText } from 'styles/common'
import { useDispatch } from 'react-redux'
import { PAGE_TYPE } from 'lib/constants'
import { setSelected as setSelectedProducts } from 'store/productsSlice'
import { setSelected as setSelectedProposals } from 'store/proposalsSlice'
import { setSelected as setSelectedProposalProducts } from 'store/proposalProductsSlice'
import {
  setSelected as setSelectedProposalItems,
  setSelectedProposalVariantIds,
} from 'store/proposalItemsSlice'
import { setSelected as setSelectedOrders } from 'store/orderSlice'
// import BulkActionsProducts from 'molecules/bulkActions/products';
// import BulkActionsProposals from 'molecules/bulkActions/proposals';
import BulkActionsProposalItems from 'molecules/bulkActions/proposalItems'
import BulkActionsProposalProducts from 'molecules/bulkActions/proposalProducts'
import Button from 'atoms/button/button'
import { InteractiveIcon, Popover } from '@teamfabric/copilot-ui'
import { SkeletonH5 } from 'atoms/skeleton'

type MoreActionProps = {
  label: string
  onClick: () => void
}

type BulkActionsProps = {
  loading: boolean
  hasBulkActions: boolean
  selectedItems?: any
  totalRows: number
  currentPage: number
  pageType?: string
  options?: any
  advanceSearch?: any
  dataName?: string
  moreActions?: MoreActionProps[]
  reload?: (quiet?: boolean) => void
  loadProducts?: (quiet?: boolean) => void
}

const BulkActionsBar: React.FC<BulkActionsProps> = ({
  loading,
  hasBulkActions,
  selectedItems,
  totalRows,
  currentPage,
  pageType,
  options,
  advanceSearch,
  dataName,
  moreActions,
  reload,
  loadProducts,
}) => {
  const dispatch = useDispatch()

  const allItemsSelected = selectedItems?.length == totalRows

  const clearSelection = (pageType) => {
    switch (pageType) {
      case PAGE_TYPE.ORDERS:
        dispatch(setSelectedOrders([]))
        return
      case PAGE_TYPE.PRODUCTS:
        dispatch(setSelectedProducts([]))
        return
      case PAGE_TYPE.PROPOSALS:
        dispatch(setSelectedProposals([]))
        return
      case PAGE_TYPE.PROPOSAL_PRODUCTS:
        dispatch(setSelectedProposalProducts([]))
        return
      // to revisit for items
      case PAGE_TYPE.PROPOSAL_ITEMS:
        dispatch(setSelectedProposalItems([]))
        dispatch(setSelectedProposalVariantIds([]))
        return
    }
  }

  const renderBulkActions = (pageType) => {
    switch (pageType) {
      case PAGE_TYPE.PRODUCTS:
        // TODO: enable and implement this once Bulk Discontinue is available on BE https://yottadv.atlassian.net/browse/MKP-3322
        // return <BulkActionsProducts />
        break
      case PAGE_TYPE.PROPOSALS:
      // TODO: enable and implement this once Bulk Archive Proposals is available on BE https://yottadv.atlassian.net/browse/MKP-3354
      // return <BulkActionsProposals />
      // break
      case PAGE_TYPE.PROPOSAL_PRODUCTS:
        return <BulkActionsProposalProducts loadProducts={loadProducts} loadProposal={reload}/>
        break
      case PAGE_TYPE.PROPOSAL_ITEMS:
        return (
          <BulkActionsProposalItems
            options={options}
            reload={reload}
            loadProducts={loadProducts}
          />
        )
        break
      default:
        break
    }
  }

  const pageTypeName = useMemo(() => {
    switch (pageType) {
      case PAGE_TYPE.PROPOSAL_PRODUCTS:
        return 'products'
        break
      case PAGE_TYPE.PROPOSAL_ITEMS:
        return 'SKUs'
        break
      default:
        return ''
        break
    }
  }, [pageType])

  const RenderLoadingSkeleton = () => {
    return (
      <div className='flex-end p-1'>
        <SkeletonH5 width={'150px'} />
      </div>
    )
  }

  return loading ? (
    <RenderLoadingSkeleton />
  ) : (
    <Box
      as='div'
      flex={{
        justifyContent:
          (selectedItems?.length > 0 && hasBulkActions) || advanceSearch
            ? 'space-between'
            : '',
        alignItems: 'center',
      }}
      height={'32px'}
      margin={hasBulkActions && { top: 1, bottom: 1 }}
      dataTestid='proposal-products-bulk-actions'
    >
      {advanceSearch && (
        <div className='is-flex'>
          <h5 className='ml-3 h5'>{advanceSearch.title}</h5>
          <div className='v-center'>
            <Button
              text='Clear Search'
              variant='tertiary'
              isDisabled={advanceSearch.isDisabled}
              onClick={advanceSearch.onClick}
            />
          </div>
        </div>
      )}
      {hasBulkActions && selectedItems?.length > 0 ? (
        <Box width={'80%'} flex={{ alignItems: 'center' }} gap={2}>
          <StyledTableSelectInfoText>
            {allItemsSelected
              ? `All ${totalRows} items selected`
              : `${selectedItems.length} ${
                  selectedItems.length > 1 ? 'items' : 'item'
                } selected`}
          </StyledTableSelectInfoText>
          <Link
            label={'Clear selection'}
            dataTestid='link-clear-selection'
            onClick={() => clearSelection(pageType)}
            mode='inline'
          />
          {renderBulkActions(pageType)}
          {moreActions && (
            <Popover
              children={
                <div className='pr-2 pl-2' style={{ width: '338px' }}>
                  {moreActions.map(({ label, onClick }) => (
                    <p
                      className='first-mt-0 last-mb-0 clickableCells'
                      key={label}
                      onClick={onClick}
                    >
                      {label}
                    </p>
                  ))}
                </div>
              }
              triggerEvent='click'
              placement='bottom-start'
              trigger={<InteractiveIcon size={20} iconName='MoreActions' />}
            />
          )}
        </Box>
      ) : (
        hasBulkActions && (
          <Box width={'90%'} dataTestid='select-label-text'>
            <div className='body1-regular grey-1000'>
              Select single or multiple {pageTypeName} to view additional
              actions
            </div>
          </Box>
        )
      )}
      <StyledTableCaption>
        {`Showing ${10 * (currentPage - 1) + 1} - ${
          10 * currentPage > totalRows ? totalRows : 10 * currentPage
        } of ${totalRows}${dataName ? ` ${dataName}` : ''}`}
      </StyledTableCaption>
    </Box>
  )
}

export default BulkActionsBar
