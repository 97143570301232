import React from 'react'
import Modal from 'atoms/modal/modal'
import Skeleton from 'atoms/skeleton/skeleton'

type ModalLoadingProps = {
  headerText: string
  buttonLabel: string
  skeltonNum: number
  isVisible: boolean
  size?: any
}

const ModalLoading: React.FC<ModalLoadingProps> = ({
  headerText,
  buttonLabel,
  skeltonNum,
  isVisible,
  size,
}) => {
  return (
    <Modal
      headerText={headerText}
      description={
        <>
          {new Array(skeltonNum).fill('').map((_, index) => (
            <div className='mb-2' key={index}>
              <Skeleton height='15px' width='100%' />
            </div>
          ))}
        </>
      }
      onClose={() => {}}
      isVisible={isVisible}
      footerButtons={[
        {
          onClick: () => {},
          isDisabled: true,
          text: 'Cancel',
          variant: 'secondary',
        },
        {
          onClick: () => {},
          isDisabled: true,
          text: buttonLabel,
          variant: 'primary',
        },
      ]}
      dataTestid='modal-loading'
      size={size || 'medium'}
      onBackdropClick={() => {}}
    />
  )
}

export default ModalLoading
