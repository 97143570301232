import FilterModal from 'atoms/filter/filterModal'
import {
  applyFilters,
  resetLocalState,
  setInitialState,
} from 'lib/utils/filter/filterModal/actions'
import { date, radio } from 'lib/utils/filter/filterModal/itemTypes'
import { PRODUCTS } from 'modules/products/constants'
import { FILTERS } from 'molecules/filter/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { setSupplierLastUpdate, setFilters } from 'store/productsSlice'

type AllFiltersModalProps = {
  params: any
}

const ProductsAllFiltersModal: React.FC<AllFiltersModalProps> = ({
  params,
}) => {
  const [localInStock, setLocalInStock] = useState(null)
  const [localDiscontinued, setLocalDiscontinued] = useState(null)
  const [localSupplierLastUpdate, setLocalSupplierLastUpdate] = useState(null)
  const [localIsSyncedToShopify, setLocalIsSyncedToShopify] = useState(null)
  const [localIsSyncedToBigCommerce, setLocalIsSyncedToBigCommerce] =
    useState(null)

  const [inStockCounter, setInStockCounter] = useState(0)
  const [discontinuedCounter, setDiscontinuedCounter] = useState(0)
  const [supplierLastUpdateCounter, setSupplierLastUpdateCounter] = useState(0)
  const [isSyncedToShopifyCounter, setIsSyncedToShopifyCounter] = useState(0)
  const [isSyncedToBigCommerceCounter, setIsSyncedToBigCommerceCounter] =
    useState(0)

  const supplierLastUpdate = useSelector(
    (state: RootState) => state.products.supplierLastUpdate
  )

  const inStock = useMemo(() => {
    if (params?.in_stock === '0') {
      return 'No'
    } else if (params?.in_stock === '1') {
      return 'Yes'
    } else return null
  }, [params?.in_stock])

  const discontinued = useMemo(() => {
    if (params?.is_discontinued === '0') {
      return 'No'
    } else if (params?.is_discontinued === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_discontinued])

  const isSyncedToShopify = useMemo(() => {
    if (params?.is_on_shopify === '0') {
      return 'No'
    } else if (params?.is_on_shopify === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_on_shopify])

  const isSyncedToBigCommerce = useMemo(() => {
    if (params?.is_on_bigcommerce === '0') {
      return 'No'
    } else if (params?.is_on_bigcommerce === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_on_bigcommerce])

  const allFiltersCount = useMemo(() => {
    return (
      inStockCounter +
      discontinuedCounter +
      supplierLastUpdateCounter +
      isSyncedToShopifyCounter +
      isSyncedToBigCommerceCounter
    )
  }, [
    inStockCounter,
    discontinuedCounter,
    supplierLastUpdateCounter,
    isSyncedToShopifyCounter,
    isSyncedToBigCommerceCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    in_stock: {
      setFilterCount: setInStockCounter,
      setLocalState: setLocalInStock,
      filterType: FILTERS.TYPES.RADIO,
      label: PRODUCTS.IN_STOCK.NAME + '?',
      initialState: inStock,
      selectedValue: localInStock,
    },
    is_discontinued: {
      setFilterCount: setDiscontinuedCounter,
      setLocalState: setLocalDiscontinued,
      filterType: FILTERS.TYPES.RADIO,
      label: PRODUCTS.IS_DISCONTINUED.NAME + '?',
      initialState: discontinued,
      selectedValue: localDiscontinued,
    },
    brand_inventory_updated_at_gte: {
      setFilterCount: setSupplierLastUpdateCounter,
      setLocalState: setLocalSupplierLastUpdate,
      initialState: supplierLastUpdate,
      date: localSupplierLastUpdate,
      action: setSupplierLastUpdate,
      filterType: FILTERS.TYPES.DATE,
      label: PRODUCTS.SUPPLIER_LAST_UPDATE.NAME,
    },
    is_on_shopify: {
      setFilterCount: setIsSyncedToShopifyCounter,
      setLocalState: setLocalIsSyncedToShopify,
      filterType: FILTERS.TYPES.RADIO,
      label: PRODUCTS.IS_SYNCED_TO_SHOPIFY.NAME + '?',
      initialState: isSyncedToShopify,
      selectedValue: localIsSyncedToShopify,
    },
    is_on_bigcommerce: {
      setFilterCount: setIsSyncedToBigCommerceCounter,
      setLocalState: setLocalIsSyncedToBigCommerce,
      filterType: FILTERS.TYPES.RADIO,
      label: PRODUCTS.IS_SYNCED_TO_BIG_COMMERCE.NAME + '?',
      initialState: isSyncedToBigCommerce,
      selectedValue: localIsSyncedToBigCommerce,
    },
  }

  useEffect(() => {
    setInitialState(filterActions)
  }, [params])

  return (
    <FilterModal
      filters={[
        radio(filterActions, PRODUCTS.IN_STOCK.PARAM),
        radio(filterActions, PRODUCTS.IS_DISCONTINUED.PARAM),
        date(filterActions, PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_START),
        radio(filterActions, PRODUCTS.IS_SYNCED_TO_SHOPIFY.PARAM),
        radio(filterActions, PRODUCTS.IS_SYNCED_TO_BIG_COMMERCE.PARAM),
      ]}
      onApply={() => applyFilters(filterActions, setFilters)}
      onCancel={() => setInitialState(filterActions)}
      onResetAll={() => resetLocalState(filterActions)}
      primaryButtonText={`Apply (${allFiltersCount})`}
    />
  )
}

export default ProductsAllFiltersModal
