import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getContext } from 'api/context'

const initialState = {
  metadata: null,
  status: 'idle',
}

export const fetchContext = createAsyncThunk('fetchContext', async () => {
  const { data } = await getContext()
  return data
})

const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext(state, action) {
      state.metadata = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContext.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchContext.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.metadata = action.payload
      })
      .addCase(fetchContext.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { setContext } = contextSlice.actions
export default contextSlice.reducer
