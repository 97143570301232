import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const ManagerApproveModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user)
  const showToast = useToast()

  const approvePricing = async () => {
    await patchProposal({
      id: props.proposal?.id,
      body: {
        status: 'pricing_approved',
        sign_off: user.user.first_name + ' ' + user.user.last_name,
      },
    })
  }

  const completeProposal = async () => {
    await patchProposal({
      id: props.proposal?.id,
      body: {
        status: 'completed',
      },
    })
  }

  const managerApproveProposal = async () => {
    try {
      setLoading(true)
      await approvePricing()
      await completeProposal()
      showToast({
        label: `Your proposal has been approved successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error approving the proposal: ${errorMessage}`
          : 'Error occurred while approving the proposal, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Approve Products'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Close',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => managerApproveProposal(),
            text: 'Approve Products',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <div className='body1-regular grey-600'>
            Are you sure you want to provide products approval for this
            proposal?
          </div>
        </>
      </Modal>
    </>
  )
}

export default ManagerApproveModal
