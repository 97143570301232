export const EditableSettingsOptions = [
  {
    name: 'Ship From Retailer',
    code: 'return_to_retailer_warehouse',
    description:
      'Populate the "ship from" on all shipping labels generated from the platform with a merchant return address. Some carriers do no respect this address, so it should be viewed as cosmetic only.',
    value: '0',
  },
  {
    name: 'Auto-populate Invoice Data',
    code: 'enforce_invoice_integrity',
    description:
      'Auto-populate all supplier invoice data except Invoice Number. Enabling this feature may reduce invoicing discrepancies, but suppliers will not be able to submit custom adjustments.',
    value: '0',
  },
  {
    name: 'Merchandise via Proposals?',
    code: 'merchandise_via_proposals',
    description:
      'Require suppliers to submit proposals to onboard product information.',
    value: '0',
  },
  {
    name: 'Require Pricing Approval On Proposals?',
    code: 'require_pricing_approval_on_proposals',
    description:
      'Add an additional pricing approval step to the proposal workflow.',
    value: '0',
  },
  {
    name: 'Include 850 In Packing Slip?',
    code: 'include_850_packing_slip',
    description:
      'Include a link to the packing slip in the 850 PO (EDI Suppliers only)',
    value: '0',
  },
  {
    name: 'Create Permits Automatically (Staff)',
    code: 'create_permits_on_product_create',
    description: 'Gain access to products as soon as they are created.',
    value: '0',
    restricted: true,
  },
  {
    name: 'Auto Invoice And Close Order When Fulfilled',
    code: 'auto_invoice_and_close_on_order_fulfilled',
    description:
      'As soon as an order is fully shipped, automatically generate an invoice and close the order.',
    value: '0',
  },
]
