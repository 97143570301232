import { DataProps } from '@teamfabric/copilot-ui'
import { store } from 'store'

export const handleSingleRowSelect = (
  e,
  rowDetails: any,
  selected: boolean,
  currentlySelectedItems: string[],
  setterHook: any
) => {
  if (selected) {
    store.dispatch(setterHook([rowDetails.id, ...currentlySelectedItems]))
  } else {
    const newSelectedItemsIds = currentlySelectedItems.filter(
      (selectedItemId) => selectedItemId !== rowDetails.id
    )
    store.dispatch(setterHook(newSelectedItemsIds))
  }
}

// Common function to select items
const selectAllItems = (setterHook, allItemIds: any[]) => {
  store.dispatch(setterHook(allItemIds))
}

// Common function to deselect all items
const deselectAllItems = (setterHook: any) => {
  store.dispatch(setterHook([]))
}

export const handleAllRowSelect = (
  e,
  allRowsSelected: boolean,
  setterHook: any,
  tableData: DataProps[]
) => {
  if (!allRowsSelected) {
    deselectAllItems(setterHook)
  } else {
    const allItemIds = tableData.map((item) => item.id)
    selectAllItems(setterHook, allItemIds)
  }
}

export const getTableCaption = (
  currentPage: number,
  totalRows: number,
  dataName?: string
) => {
  return totalRows
    ? `Showing ${10 * (currentPage - 1) + 1} - ${
        10 * currentPage > totalRows ? totalRows : 10 * currentPage
      } of ${totalRows}${dataName ? ` ${dataName}` : ''}`
    : 'Showing 0 of 0'
}
