import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import CardLoading from 'molecules/loading/cardLoading'
import Input from 'atoms/input/input'
import { useNavigate } from 'react-router-dom'
import {
  getPlatformAccounts,
  updatePlatformAccounts,
  createPlatformAccounts,
} from 'api/platformAccount'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import { useDispatch } from 'react-redux'
import { setCurrentStep } from 'store/onboardingSlice'
import { useToast } from '@teamfabric/copilot-ui'
import CardHeader from 'molecules/cards/cardHeader'
import { PATHS } from 'routes/pageRoutes'

type ShipstationSetupProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const ShipstationSetup: React.FC<ShipstationSetupProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()

  const integration =
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
  const [loading, setLoading] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [shipstationAccount, setShipstationAccounts] = useState({
    id: '',
  })
  const [isAccountExist, setIsAccountExist] = useState(false)
  const [apiSecret, setApiSecret] = useState('')
  const [tagId, setTagId] = useState('')
  const [storeIdObj, setStoreIdObj] = useState({
    value: '',
    id: '',
  })

  const loadPlatformAccounts = async () => {
    try {
      setLoading(true)
      const {
        data: { count, results },
      } = await getPlatformAccounts({
        params: { code: integration.SHIPSTATION.TASK_NAME },
      })
      if (count > 0) {
        setShipstationAccounts(results[0])
        setIsAccountExist(true)
        const credential = results[0].credentials.find(
          (credential) => Object.keys(credential)[0] === 'shipstation_api_key'
        )
        if (credential) {
          setApiKey(credential.shipstation_api_key.values[0].value)
        }
        const storeIdObject = results[0].credentials.filter(
          (el) => el.shipstation_store_id
        )
        if (storeIdObject.length > 0) {
          setStoreIdObj(storeIdObject[0].shipstation_store_id.values[0])
        }
      }
    } catch (error) {
      showToast({
        label: "Couldn't load ShiStation account",
        isDismissable: true,
        variant: 'error',
        id: '3',
      })
    } finally {
      setLoading(false)
    }
  }

  // first loaded
  useEffect(() => {
    ;(async () => {
      setIsNextButtonDisabled(false)
      await loadPlatformAccounts()
    })()
  }, [])

  const addPlatformAccounts = async () => {
    try {
      setLoading(true)
      const body = {
        credentials: [],
      }
      if (shipstationAccount.id === '') {
        ;(body['platform'] = { code: integration.SHIPSTATION.TASK_NAME }),
          (body.credentials = [
            {
              credential: { code: 'push_order_on_order_create' },
              value: 0,
            },
            {
              credential: { code: 'shipstation_api_key' },
              value: apiKey,
            },
            {
              credential: { code: 'shipstation_api_secret' },
              value: apiSecret,
            },
          ])
      }
      if (storeIdObj.value !== '' && storeIdObj.id !== '') {
        body.credentials.push({
          credential: { code: 'shipstation_store_id' },
          value: storeIdObj.value,
          id: storeIdObj.id,
        })
      }
      if (shipstationAccount.id === '') {
        await createPlatformAccounts({ body })
      } else {
        await updatePlatformAccounts({ id: shipstationAccount.id, body })
      }
      showToast({
        label: 'ShipStation setup step added',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: "Couldn't add ShipStation setup step",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setLoading(false)
    }
  }

  // next button is clicked
  useEffect(() => {
    ;(async () => {
      if (isNextClicked) {
        setIsNextClicked(false)
        await addPlatformAccounts()
        dispatch(
          setCurrentStep({
            value:
              ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.TASK_NAME,
            parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
          })
        )
      }
    })()
  }, [isNextClicked])

  // Save and exit
  useEffect(() => {
    ;(async () => {
      try {
        if (isSaveExitClicked) {
          setLoading(true)
          setIsSaveExitClicked(false)
          await addPlatformAccounts()
          navigate(PATHS.Onboarding)
        }
      } catch (error) {
        showToast({
          label: "Couldn't add ShipStation setup step",
          isDismissable: true,
          variant: 'error',
          id: '2',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {loading && <CardLoading label={integration.SHIPSTATION.NAME} num={3} />}
      {!loading && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={integration.SHIPSTATION.NAME}
              h2Text='fabric can be configured to automatically push purchase orders into and pull tracking numbers from your ShipStation account.'
              banner={{
                label:
                  'After adding this integration it can be configured in settings',
              }}
            />
          }
          bodyContent={
            <>
              <Input
                width='100%'
                label='API Key'
                required
                inputProps={{
                  value: apiKey,
                  disabled: isAccountExist,
                  placeholder: 'API Key',
                  onChange: ({ target: { value } }) => {
                    setApiKey(value)
                  },
                }}
              />
              <div className='mt-3'>
                <Input
                  width='100%'
                  label='API Secret'
                  required
                  inputProps={{
                    value: apiSecret,
                    disabled: isAccountExist,
                    placeholder: 'API Secret',
                    onChange: ({ target: { value } }) => {
                      setApiSecret(value)
                    },
                  }}
                />
              </div>
              <div className='mt-3'>
                <Input
                  width='100%'
                  label='Tag ID (optional)'
                  inputProps={{
                    value: tagId,
                    placeholder: 'Tag ID (optional)',
                    onChange: ({ target: { value } }) => {
                      setTagId(value)
                    },
                  }}
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}

export default ShipstationSetup
