import React from 'react'
import { ButtonProps, EmptyPage as EmptyPageUI } from '@teamfabric/copilot-ui'

export type EmptyPageProps = {
  headerText: string
  description?: string
  buttons?: ButtonProps[]
  footerContent?: JSX.Element
  customClassName?: string
}

const EmptyPage: React.FC<EmptyPageProps> = ({ customClassName, ...props }) => {
  return (
    <div className={customClassName ? customClassName : ''}>
      <EmptyPageUI {...props} />
    </div>
  )
}

export default EmptyPage
