import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { DropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  handleUpdate?: (data: any) => void
  fieldLabel?: string
  targetValue?: 'variant' | 'product'
  isRequired?: boolean
}

const targetOptions: DropDownInitialObj[] = [
  {
    id: '1',
    label: 'Product',
    value: 'product',
  },
  {
    id: '2',
    label: 'Item',
    value: 'variant',
  },
]

const SelectTemplateMappingsTarget: React.FC<ChildProps> = ({
  handleUpdate,
  fieldLabel,
  targetValue,
  isRequired,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    targetOptions.find((el) => el.value === targetValue)
  )
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  useEffect(() => {
    if (!initialEffectRun && targetValue) {
      const target = targetOptions.find((el) => el.value === targetValue)
      handleUpdate(target)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, targetValue])

  return (
    <Dropdown
      dataTestid='target-dropdown'
      required={isRequired}
      label={fieldLabel || ''}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        const selected = targetOptions.find((el) => el.id === option.id)
        setSelectedOption(selected)
        handleUpdate(option)
      }}
      options={targetOptions}
      placeholder='Select target'
    />
  )
}

export default SelectTemplateMappingsTarget
