import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, Input, PageHeader } from 'atoms'
import {
  patchPushNotification,
  postPushNotification,
} from 'api/pushNotification'
import { patchNotification, postNotification } from 'api/notifications'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  action: string
  header: any
  description: any
  pushNotifications?: any[]
  customClassName?: string
  crontab?: string
  type?: 'digest'
  reloadNotifications: () => void
}

const defaultProps: ChildProps = {
  action: '',
  header: undefined,
  description: undefined,
  pushNotifications: [],
  reloadNotifications: () => {},
}

const PushNotification: React.FC<ChildProps> = ({
  header,
  description,
  pushNotifications,
  action,
  customClassName,
  crontab,
  type,
  reloadNotifications,
}) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [toggleValue, setToggleValue] = useState('0')
  const [notification, setNotification] = useState({
    id: undefined,
  })

  const showToast = useToast()
  const isInitialRender = useRef(true)

  useEffect(() => {
    const foundNotification = pushNotifications.find((notification) => {
      if (type === 'digest') {
        return notification.type && notification.type.name === action
      } else {
        return notification.action.name === action
      }
    })
    if (foundNotification) {
      setNotification(foundNotification)
      setEmailAddress(foundNotification.recipient_email)
      setToggleValue(foundNotification.status === 'enabled' ? '1' : '0')
    }
  }, [action, pushNotifications])

  const updateNotification = async (updatedToggleValue) => {
    try {
      if (type === 'digest') {
        await updateDigestNotification(updatedToggleValue)
      } else {
        await updatePushNotification(updatedToggleValue)
      }
      showToast({
        label: 'Preference updated!',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  const updateDigestNotification = async (updatedToggleValue) => {
    const statusValue = updatedToggleValue ? updatedToggleValue : toggleValue
    const body = {
      crontab: crontab,
      type: { name: action },
      recipient_email: emailAddress,
      status: statusValue === '1' ? 'enabled' : 'disabled',
    }
    if (notification && notification.id !== undefined) {
      await patchNotification({ id: notification.id, body })
    } else {
      await postNotification({ body })
      reloadNotifications()
    }
  }

  const updatePushNotification = async (updatedToggleValue) => {
    const statusValue = updatedToggleValue ? updatedToggleValue : toggleValue
    const body = {
      action: { name: action },
      name: action,
      delivery_method: 'recipient',
      recipient_email: emailAddress,
      status: statusValue === '1' ? 'enabled' : 'disabled',
    }
    if (notification && notification.id !== undefined) {
      await patchPushNotification({ id: notification.id, body })
    } else {
      await postPushNotification({ body })
      reloadNotifications()
    }
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return email?.length ? re.test(email) : true
  }

  return (
    <div className={customClassName}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='100%'>
            <PageHeader
              h1Text={<span className='h5'>{header}</span>}
              h2Text={<span className='body2-regular'>{description}</span>}
              switchProps={{
                label: '',
                stateLabels: {
                  OFF: 'Off',
                  ON: 'On',
                },
                disabled:
                  emailAddress?.length === 0 || !validateEmail(emailAddress),
                checked: toggleValue === '1',
                onChange: ({ target: { checked } }) => {
                  const value = checked ? '1' : '0'
                  setToggleValue(value)
                  updateNotification(value)
                },
              }}
            />
          </Box>
        }
        bodyContent={
          <Input
            required
            label='Email address or distribution list'
            messageType={!validateEmail(emailAddress) && 'error'}
            message={
              !validateEmail(emailAddress) && 'Provide a valid email address'
            }
            inputProps={{
              name: 'email',
              value: emailAddress,
              onChange: ({ target: { value } }) => {
                setEmailAddress(value)
              },
              placeholder: 'Email address or distribution list',
              // disabled: toggleValue === '1' ? true : false
            }}
            iconProps={[
              toggleValue === '0' && {
                dataTestid: 'cancel-icon',
                iconName: 'Close',
                onClick: () => {
                  setEmailAddress('')
                },
                tooltip: {
                  placement: 'top',
                  text: 'Clear',
                },
                type: 'interactive',
              },
              {
                dataTestid: 'save-icon',
                iconName: 'Check',
                onClick: () => {
                  updateNotification('')
                },
                tooltip: {
                  placement: 'top',
                  text: 'Save',
                },
                type: 'interactive',
              },
            ]}
          />
        }
      ></Card>
    </div>
  )
}

PushNotification.defaultProps = defaultProps

export default PushNotification
