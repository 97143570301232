import { FC, useMemo, useState } from 'react'
import { updateOrderById } from 'api/orders'
import { Button, Modal, PageHeader, SingleDatePicker, Banner } from 'atoms'
import SingleColumnItemTable from 'molecules/lineItem/singleColumnItemTable'
import { useToast } from '@teamfabric/copilot-ui'
import moment from 'moment'

interface ChildProps {
  variant: any
  lineId: string
  status: string
  orderId: string
  lineBackorderedUntil: string
  reloadOrder: () => void
}

const OrderLinesActionsMenu: FC<ChildProps> = ({
  variant,
  lineId,
  status,
  orderId,
  lineBackorderedUntil,
  reloadOrder,
}) => {
  const showToast = useToast()
  const [showBackorderedModal, setShowBackorderedModal] = useState(false)
  const [showReadyToShipModal, setReadyToShipModal] = useState(false)
  const [shipDate, setShipDate] = useState(null)
  const [saving, setSaving] = useState(false)

  const backorderMessage =
    'Please Note: when you mark this item as backordered, your action will be processed immediately and communications may be sent to the merchant to inform them of the backorder.'
  const readyToShipMessage =
    'Please Note: when you remove the backorder date, you action will be processed immediately and communications may be sent to the merchant to inform them the order is ready to ship.'

  const mode = useMemo(() => {
    return lineBackorderedUntil ? 'reset' : 'set'
  }, [lineBackorderedUntil])

  const actionOptions = useMemo(() => {
    let label = 'No Actions'
    let isDisabled = true
    let modalType = null
    if (['open', 'partial'].includes(status)) {
      label = 'Mark as Backordered'
      isDisabled = false
      modalType = 'backorder_modal'
    } else if (['backordered'].includes(status)) {
      label = 'Mark as Ready to Ship'
      isDisabled = false
      modalType = 'ready_to_ship'
    }
    return {
      text: label,
      isDisabled: isDisabled,
      modalType: modalType,
    }
  }, [status])

  const triggerRespectiveAction = (type, value) => {
    if (type === 'backorder_modal') {
      return setShowBackorderedModal(value)
    } else return setReadyToShipModal(value)
  }

  const handleSubmit = async () => {
    const body = {
      order_lines: [
        {
          id: lineId,
          backordered_until:
            mode === 'set' ? moment.utc(shipDate).local().format() : null,
        },
      ],
    }
    setSaving(true)
    try {
      await updateOrderById({ id: orderId, body })
      showToast({
        label: 'Order updated...',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label:
          'Your attempt to backorder this line item failed. Please try again.',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setShowBackorderedModal(false)
      setReadyToShipModal(false)
      setSaving(false)
      setTimeout(() => {
        reloadOrder()
      }, 2000)
    }
  }
  return (
    <>
      <Button
        onClick={() => triggerRespectiveAction(actionOptions.modalType, true)}
        text={actionOptions.text}
        variant='tertiary'
        isDisabled={actionOptions.isDisabled}
      />
      {/* Backorder Modal */}
      <Modal
        headerText='Mark Order Line as Backordered'
        size='small'
        onClose={() => setShowBackorderedModal(false)}
        isVisible={showBackorderedModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowBackorderedModal(false),
            text: 'Close',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            dataTestid: '',
            onClick: () => handleSubmit(),
            text: 'Update Order Line',
            variant: 'primary',
            isDisabled: !shipDate || saving,
          },
        ]}
        onBackdropClick={() => setShowBackorderedModal(false)}
      >
        <Banner type='inline' label={backorderMessage} variant='error' />
        <SingleColumnItemTable
          variant={[variant]}
          customClassName='mt-5 mb-5'
        />
        <PageHeader
          h1Text={<span className='h5'>Ship Date</span>}
          h2Text={
            <span className='body1-regular grey-600'>
              Since you are marking this order line as backordered due to no
              stock being available, please enter a date when you expect the
              items to ship.{' '}
            </span>
          }
        />
        <div className='mt-4'>
          <SingleDatePicker
            label='Ship date'
            minDate={new Date()}
            value={shipDate}
            defaultValue={shipDate}
            format='MM/dd/yyyy'
            onSelect={(date: Date) => setShipDate(date)}
          />
        </div>
      </Modal>
      {/* Ready To Ship Modal */}
      <Modal
        headerText='Mark as ready to ship'
        size='small'
        onClose={() => setReadyToShipModal(false)}
        isVisible={showReadyToShipModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setReadyToShipModal(false),
            text: 'Close',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            dataTestid: '',
            onClick: () => handleSubmit(),
            text: 'Update Order Line',
            variant: 'primary',
            isDisabled: saving,
          },
        ]}
        onBackdropClick={() => setReadyToShipModal(false)}
      >
        <Banner type='inline' label={readyToShipMessage} variant='info' />
        <SingleColumnItemTable variant={[variant]} customClassName='mt-5' />
      </Modal>
    </>
  )
}

export default OrderLinesActionsMenu
