import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import {
  getTemplateById,
  getTemplateMappings,
  updateTemplate,
} from 'api/templates'
import { capitalizeFirstLetter } from 'lib/utils'
import CardLoading from 'molecules/loading/cardLoading'
import MappingsTable, {
  MappingType,
} from '../components/templates/mappingsTable'
import { downloadSampleFile } from 'api/products'
import CloneTemplateModal from '../components/templates/cloneTemplateModal'
import BasicSettings from '../components/templates/basicDetails'

const ProductInventoryTemplateDetails: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)
  const [loadingMappings, setLoadingMappings] = useState(true)

  const [name, setName] = useState('')
  const [selectedDirection, setSelectedDirection] = useState('import')
  const [saving, setSaving] = useState(false)
  const [templateDetails, setTemplateDetails] = useState(null)
  const [templateMappings, setTemplateMappings] = useState<MappingType[]>([])
  const [templateMappingsCount, setTemplateMappingsCount] = useState(0)
  const [showCloneModal, setShowCloneModal] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.productInventoryTemplate({ page: `Template #${id}` })
        ),
      0
    )
  }, [])

  const onSave = async () => {
    const body = {
      name: name,
      direction: templateDetails.direction,
      data_type: templateDetails.data_type,
    }
    try {
      setSaving(true)
      await updateTemplate({ id, body })
      showToast({
        label: 'Template updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchTemplate = async () => {
    const { data } = await getTemplateById({ id })
    setTemplateDetails(data)
    setName(data.name)
    setSelectedDirection(data.direction)
    setLoading(false)
  }

  const fetchTemplateMappings = async (quiet?: boolean) => {
    if (!quiet) setLoadingMappings(true)
    const { data } = await getTemplateMappings({
      id: id,
      params: {
        pagination: 0,
      },
    })
    setTemplateMappings(data.results)
    setTemplateMappingsCount(data.count)
    setLoadingMappings(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchTemplate()
        await fetchTemplateMappings()
      })()
    }
  }, [id])

  const downloadSample = async () => {
    const link = document.createElement('a')
    link.target = '_blank'

    if (templateDetails?.sample_location) {
      link.href = templateDetails.sample_location
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Remove the link element after clicking
    } else {
      // Download template mapping, if sample location is not provided
      await downloadSampleFile(templateDetails.id, templateDetails.name)
    }
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      {
        <CloneTemplateModal
          showModal={showCloneModal}
          setShowModal={setShowCloneModal}
        />
      }
      <PageHeaderComponent
        loading={loading}
        dataTestid='template-details-header'
        h1Text={`${capitalizeFirstLetter(templateDetails?.name || '')} ${id}`}
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/templates'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
            dataTestid: 'back-button',
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !name,
            dataTestid: 'save-button',
          },
        ]}
      />
      {loading ? (
        <CardLoading />
      ) : (
        <BasicSettings
          name={name}
          setName={setName}
          templateDetails={templateDetails}
          showCloneTemplate
          showDownloadSample
          downloadSample={downloadSample}
          setShowCloneModal={setShowCloneModal}
        />
      )}
      <MappingsTable
        direction={selectedDirection}
        loading={loadingMappings}
        mappings={templateMappings}
        loadMappings={() => fetchTemplateMappings(true)}
        totalCount={templateMappingsCount}
      />
    </Box>
  )
}

export default ProductInventoryTemplateDetails
