import React, { useEffect, useMemo, useState } from 'react'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxWithSearchFilterChange,
  setCountersAndChips,
  handleRadioFilterChange,
  handleCheckboxFilterChange,
} from 'lib/utils/filter/singleFilters'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  setFilters,
  setConnectionOptions,
  setCreatedOn,
  resetAllFilters,
  setCanceledByOptions,
  setCancellationReasonOptions,
} from 'store/cancellationsSlice'
import Box from 'atoms/box/box'
import { FILTERS } from 'molecules/filter/constants'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import { ORDERS } from 'modules/orders/constants'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [cancelledByCounter, setCancelledByCounter] = useState(0)
  const [cancellationReasonCounter, setCancellationReasonCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)

  const [filterChips, setFilterChips] = useState([])

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.cancellations.connectionOptions
  )
  const createdOn = useSelector(
    (state: RootState) => state.cancellations.createdOn
  )
  const cancelledByCheckboxOptions = useSelector(
    (state: RootState) => state.cancellations.canceledByOptions
  )
  const cancellationReasonsCheckboxOptions = useSelector(
    (state: RootState) => state.cancellations.reasonOptions
  )

  const isAFilterApplied = useMemo(() => {
    return (
      connectionsCounter +
        cancelledByCounter +
        cancellationReasonCounter +
        acknowledgedCounter +
        createdOnCounter >
      0
    )
  }, [
    connectionsCounter,
    cancelledByCounter,
    cancellationReasonCounter,
    acknowledgedCounter,
    createdOnCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: ORDERS.CONNECTION.NAME,
    },
    canceled_by: {
      action: setCanceledByOptions,
      options: cancelledByCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setCancelledByCounter,
      chipName: ORDERS.CANCELLED_BY.NAME,
    },
    reason_id: {
      action: setCancellationReasonOptions,
      options: cancellationReasonsCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setCancellationReasonCounter,
      chipName: ORDERS.CANCELLATION_REASONS.NAME,
      useLabelForChip: true,
    },
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.ACKNOWLEDGED.NAME + '?',
      chipName: ORDERS.ACKNOWLEDGED.NAME,
    },
    created_at_gte: {
      setFilterCount: setCreatedOnCounter,
      dateRange: createdOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.CANCEL_REGISTERED.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${ORDERS.CONNECTION.NAME}-filter`}
          label={ORDERS.CONNECTION.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <CheckboxFilter
          dataTestid={`${ORDERS.CANCELLATION_REASONS.NAME}-filter`}
          label={ORDERS.CANCELLATION_REASONS.NAME}
          data={cancellationReasonsCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.CANCELLATION_REASONS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={cancellationReasonCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.CANCELLATION_REASONS.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <CheckboxFilter
          dataTestid={`${ORDERS.CANCELLED_BY.NAME}-filter`}
          label={ORDERS.CANCELLED_BY.NAME}
          data={cancelledByCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.CANCELLED_BY.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={cancelledByCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.CANCELLED_BY.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <RadioGroupFilter
          dataTestid={`${ORDERS.ACKNOWLEDGED.NAME}-filter`}
          label={ORDERS.ACKNOWLEDGED.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              setFilters,
            })
          }}
          value={params?.is_acknowledged?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={
            acknowledgedCounter > 0 ? acknowledgedCounter?.toString() : ''
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.CANCEL_REGISTERED.NAME}-filter`}
          label={ORDERS.CANCEL_REGISTERED.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.CANCEL_REGISTERED.PARAMS.START,
              date,
            })
          }}
          defaultValue={createdOn}
          filterCount={createdOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.CANCEL_REGISTERED.PARAMS.START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
