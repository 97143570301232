import React, { useState } from 'react'
import Modal from 'atoms/modal/modal'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import Dropdown from 'atoms/dropdown/dropdown'
import GridRow from 'atoms/grid/gridRow'
import GridCol from 'atoms/grid/gridCol'
import Box from 'atoms/box/box'
import { theme } from '@teamfabric/copilot-ui'

type LearnMoreModalProps = {
  isVisible: boolean
  setIsDisplayModal: (value: boolean) => void
}

const LearnMoreModal: React.FC<LearnMoreModalProps> = ({
  isVisible,
  setIsDisplayModal,
}) => {
  const methodType =
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
  const [title, setTitle] = useState('Learn about integration methods')
  const [method, setMethod] = useState({
    id: methodType.EDI.TASK_NAME,
    label: methodType.EDI.NAME,
  })

  const methodOptions = Object.keys(methodType).map((key) => ({
    id: methodType[key].TASK_NAME,
    label: methodType[key].NAME,
  }))

  const info = [
    {
      label: methodType.EDI.NAME,
      inventory: 'Upload 846 IAs to your EDI outbox.',
      order: '850 POs will be uploaded to your EDI inbox in real time.',
      fulfillment: '10/2Upload 856 ASNs to your EDI outbox.',
      cancel: 'Upload 860 Change Orders to your EDI outbox.',
      invoices: 'Upload 810 Invoices to your EDI outbox.',
    },
    // Currently FABRIC method is not available
    // {
    //   label: methodType.FABRIC.NAME,
    //   inventory:
    //     'Upload inventory as needed through the supplier portal using a CSV/Excel template.',
    //   order:
    //     'Access and export orders via the portal (and receive real-time notifications when new orders are received).',
    //   fulfillment:
    //     'Print shipping labels or register tracking numbers for each order (or upload files for bulk operations)',
    //   cancel:
    //     'Update invidual orders to reflect cancellations or provide estimated shipment dates.',
    //   invoices:
    //     'Register invoices individually (or upload files for bulk operations). You may also choose to use our auto-invoice feature.',
    // },
    {
      label: methodType.SHIPSTATION.NAME,
      inventory:
        'ShipStation does not provide inventory webhooks, so you will need to upload inventory as needed through the supplier portal using a CSV/Excel template.',
      order:
        'New orders will be automatically sent to your ShipStation account (and you may specify a specific Store ID)',
      fulfillment:
        'We will listen for shipment notifications from ShipStation and automatically register tracking #s',
      cancel:
        'ShipStation does not provide webhooks for order change events, so you will need to update individual orders in the supplier portal to reflect cancellations or provide estimated shipment dates for backorders.',
      invoices:
        'ShipStation does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).',
    },
    // Currently SHOPIFY method is not available
    // {
    //   label: methodType.SHOPIFY.NAME,
    //   inventory: 'Shopify can provide inventory updates automatically.',
    //   order: 'New orders will be automatically sent to your Shopify account.',
    //   fulfillment:
    //     'We will listen for shipment notifications from Shopify and automatically register tracking #s',
    //   cancel:
    //     'We will listen for full order cancels only; partial cancels must be registered through the supplier portal.',
    //   invoices:
    //     'Shopify does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).',
    // },
    {
      label: methodType.BIGCOMMERCE.NAME,
      inventory:
        'New orders will be automatically sent to your BigCommerce store.',
      order: 'New orders will be automatically sent to your BigCommerce store.',
      fulfillment:
        'We can listen for shipment notifications from BigCommerce and automatically register tracking #s.',
      cancel:
        'We do not listen to order changes from BigCommerce; full & partial cancels must be registered through the supplier portal.',
      invoices:
        'BigCommerce does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).',
    },
    // Currently WOOCOMMERCE method is not available
    // {
    //   label: methodType.WOOCOMMERCE.NAME,
    //   inventory: 'WooCommerce can provide inventory updates automatically.',
    //   order: 'New orders will be automatically sent to your WooCommerce store.',
    //   fulfillment:
    //     'We can listen for shipment notifications from WooCommerce and automatically register tracking #s provided you use the official WooCommerce Shipping plugin',
    //   cancel:
    //     'We do not listen to order changes from WooCommerce; full & partial cancels must be registered through the supplier portal.',
    //   invoices:
    //     'WooCommerce does not provide invoices, so you will need to register invoices manually via the supplier portal (or choose to use our auto-invoice feature).',
    // },
    {
      label: methodType.API.NAME,
      inventory: 'Update inventory via an Inventory API PATCH request.',
      order:
        'Pull new orders using an Orders API GET request (or listen to our Order/Create webhook)',
      fulfillment:
        'Push shipment notifications via a Shipments API POST request.',
      cancel: 'Push order change notifications via an Orders PATCH request.',
      invoices: 'Push invoice notifications via an Invoices API POST request.',
    },
  ]

  return (
    <Modal
      headerText={title}
      description={
        <div>
          <Dropdown
            width={'100%'}
            label='Integration Method'
            options={methodOptions}
            value={method}
            onChange={(e) => {
              setMethod(e)
              setTitle('Explore Integration Options')
            }}
          />
          <div className='border-line mt-4'></div>
          <div>
            <h5 className='h5'>{method.label}</h5>
          </div>
          {method.label === methodType.EDI.NAME && (
            <div>
              <p className='mb-4'>
                fabric trades EDI documents over SFTP through our proprietary,
                in-house EDI system. There are no additional setup or monthly
                fees to use EDI. To administer the best possible customer
                experience, fabric requires exchange & testing of the following
                documents.
              </p>
              <GridRow padding={false}>
                <GridCol sm={6} md={6} lg={6}>
                  <ul>
                    <li>846 Inventory Advice</li>
                    <li>850 Purchase Order</li>
                    <li>850 Purchase Order</li>
                    <li>856 Advance Ship Notice</li>
                  </ul>
                </GridCol>
                <GridCol sm={6} md={6} lg={6}>
                  <ul>
                    <li>860 PO Change Request</li>
                    <li>810 Invoice</li>
                  </ul>
                </GridCol>
              </GridRow>
            </div>
          )}
          {/* {method.label === methodType.FABRIC.NAME && (
            <p className='body1-regular'>
              All mandatory functions can be performed via the Supplier Portal.
              There are easy workflows for uploading inventory and processing
              orders.
            </p>
          )} */}
          {method.label === methodType.SHIPSTATION.NAME && (
            <div>
              <p className='body1-regular mb-4'>
                Fabric can be configured to automatically push purchase orders
                into and pull tracking numbers from your ShipStation account -
                we just need a set of ShipStation API Keys.
              </p>
              <p className='body1-regular'>
                While ShipStation is excellent for exchanging fulfillment data,
                it is often not the source of inventory data. It also doesn't
                handle exception flows well (e.g. cancellations), so some manual
                intervention via the Supplier Portal is often required.
              </p>
            </div>
          )}
          {[
            // methodType.SHOPIFY.NAME,
            methodType.BIGCOMMERCE.NAME,
            // methodType.WOOCOMMERCE.NAME,
            methodType.API.NAME,
          ].includes(method.label) && (
            <p className='body1-regular'>
              fabric can be configured to connect with {method.label}.
            </p>
          )}
          <div>
            <h5 className='h5 mb-2'>How It Works</h5>
            <Box
              border={{
                color: theme.color.grey[200],
                radius: 2,
                width: '2px',
              }}
            >
              <div className='pt-4 pb-4'>
                {info
                  .filter(({ label }) => label === method.label)
                  .map(
                    ({
                      label,
                      inventory,
                      order,
                      fulfillment,
                      cancel,
                      invoices,
                    }) => (
                      <div key={label}>
                        <GridRow padding={true}>
                          <GridCol sm={3} md={3} lg={3}>
                            Inventory
                          </GridCol>
                          <GridCol sm={9} md={9} lg={9}>
                            {inventory}
                          </GridCol>
                        </GridRow>
                        <div className='border-line mt-2 mb-2'></div>
                        <GridRow padding={true}>
                          <GridCol sm={3} md={3} lg={3}>
                            Orders
                          </GridCol>
                          <GridCol sm={9} md={9} lg={9}>
                            {order}
                          </GridCol>
                        </GridRow>
                        <div className='border-line mt-2 mb-2'></div>
                        <GridRow padding={true}>
                          <GridCol sm={3} md={3} lg={3}>
                            Fultillments
                          </GridCol>
                          <GridCol sm={9} md={9} lg={9}>
                            {fulfillment}
                          </GridCol>
                        </GridRow>
                        <div className='border-line mt-2 mb-2'></div>
                        <GridRow padding={true}>
                          <GridCol sm={3} md={3} lg={3}>
                            Cancels & Backorders
                          </GridCol>
                          <GridCol sm={9} md={9} lg={9}>
                            {cancel}
                          </GridCol>
                        </GridRow>
                        <div className='border-line mt-2 mb-2'></div>
                        <GridRow padding={true}>
                          <GridCol sm={3} md={3} lg={3}>
                            Invoices
                          </GridCol>
                          <GridCol sm={9} md={9} lg={9}>
                            {invoices}
                          </GridCol>
                        </GridRow>
                      </div>
                    )
                  )}
              </div>
            </Box>
          </div>
        </div>
      }
      onClose={() => setIsDisplayModal(false)}
      isVisible={isVisible}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => {
            setIsDisplayModal(false)
          },
          text: 'Cancel',
          variant: 'primary',
        },
      ]}
      dataTestid={''}
      size='medium'
      onBackdropClick={() => setIsDisplayModal(false)}
    />
  )
}

export default LearnMoreModal
