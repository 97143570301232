import React, { useEffect, useState } from 'react'
import { getPlatformTemplates, getTemplates } from 'api/templates'
import Dropdown from 'atoms/dropdown/dropdown'

type ChildProps = {
  handleUpdate?: (data: any) => void
  selectedPlatform?: any
  selectedTemplate?: any
  direction: 'export' | 'import'
  fieldLabel?: string
}

const SelectTemplates: React.FC<ChildProps> = ({
  handleUpdate,
  selectedPlatform,
  selectedTemplate,
  direction,
  fieldLabel,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(selectedTemplate)
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  const fetchFileTemplates = async () => {
    setLoading(true)
    const { data } = await getTemplates({
      params: { data_type: 'products', direction: direction },
    })
    const options = []
    if (data.count > 0) {
      data.results.forEach((el) => {
        if (el.code !== '' && el.retailer !== null) {
          options.push({
            id: el.id,
            label: el.name,
          })
        }
      })
    }
    setOptions(options)
    setLoading(false)
  }

  const fetchPlatformTemplates = async () => {
    setLoading(true)
    const { data } = await getPlatformTemplates({
      platformCode: selectedPlatform.label,
      platformId: selectedPlatform.id,
      params: { direction: 'export' },
    })
    const options = []
    if (data.count > 0) {
      data.results.forEach((el) => {
        if (el.code !== '') {
          options.push({
            id: el.id,
            label: el.name,
          })
        }
      })
    }
    setOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    if (selectedPlatform) {
      fetchPlatformTemplates()
    } else {
      fetchFileTemplates()
    }
  }, [selectedPlatform])

  useEffect(() => {
    if (!initialEffectRun && selectedTemplate?.id) {
      setSelectedOption(selectedTemplate)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, selectedTemplate])

  return (
    <Dropdown
      required
      label={fieldLabel || 'Template'}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder='Select Template'
      disabled={loading}
    />
  )
}

export default SelectTemplates
