import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import Banner from 'atoms/banner/banner'
import { Modal, Table } from 'atoms'
import VariantItem from 'molecules/lineItem/variantItem'
import InputStepper from 'atoms/inputStepper/inputStepper'
import { cancelOrderLines } from 'api/orders'
import { useToast } from '@teamfabric/copilot-ui'

type CancelItemProps = {
  orderDetails: any
  setShowCancelItemsModal: (arg: boolean) => void
  showCancelItemsModal: boolean
  reloadOrder?: () => void
}

const CancelItemsModal: React.FC<CancelItemProps> = ({
  orderDetails,
  setShowCancelItemsModal,
  showCancelItemsModal,
  reloadOrder,
}) => {
  const showToast = useToast()
  const [orderLinesData, setOrderLinesData] = useState<any[]>([])
  const [selectedReason, setSelectedReason] = useState<any>()
  const [cancelLines, setCancelLines] = useState<any[]>(
    orderDetails.order_lines
  )

  // retailer reasons - enable post context switching
  // const retailerReasonOptions = [
  //   { id: 'customer_requested_cancellation', label: 'Customer Requested Cancellation', value: 1 },
  //   { id: 'suspected_fraud', label: 'Suspected Fraud', value: '6' },
  // ]

  const supplierReasonOptions = [
    { id: 'not_enough_inventory', label: 'Not Enough Inventory', value: '2' },
    {
      id: 'retailer_requested_cancellation',
      label: 'Retailer Requested Cancellation',
      value: '1',
    },
    { id: 'unfulfillable_address', label: 'Unfulfillable Address', value: '5' },
    {
      id: 'incorrect_product_information',
      label: 'Incorrect Product Information',
      value: '10',
    },
    { id: 'price_discrepancy', label: 'Price Discrepancy', value: '8' },
    { id: 'product_discontinued', label: 'Product Discontinued', value: '9' },
    { id: 'suspected_fraud', label: 'Suspected Fraud', value: '6' },
  ]

  useEffect(() => {
    formatData(orderDetails)
  }, [orderDetails])

  const lineItemsColumn = [
    {
      sortable: false,
      title: 'Item',
    },
    {
      sortable: false,
      title: 'Ordered',
    },
    {
      sortable: false,
      title: 'Return Quantity',
    },
  ]

  const formatData = ({ order_lines }) => {
    const formattedData = order_lines.map(
      ({ id, variant, quantity, quantity_shipped }) => {
        return {
          id,
          data: {
            'Item': {
              onRender: () => (
                <VariantItem variant={variant} showInventoryDetailsModal />
              ),
            },
            'Ordered': {
              value: quantity,
            },
            'Return Quantity': {
              onRender: () => (
                <InputStepper
                  label=''
                  max={quantity - quantity_shipped}
                  min={0}
                  onValueChange={(newVal) => {
                    updatedQty(variant, newVal)
                  }}
                  stepSize={1}
                  value={quantity - quantity_shipped}
                />
              ),
            },
          },
        }
      }
    )

    setOrderLinesData(formattedData)
  }

  // adding new key return_quantity to pass it to request
  const updatedQty = (variant, quantity) => {
    const lineItem = cancelLines.map((line) => {
      if (line.variant.id === variant.id) {
        return {
          ...line,
          ['return_quantity']: quantity,
        }
      }
    })
    setCancelLines(lineItem)
  }

  // update the cancelLines
  const handleSelection = (e, rowDetails, selected) => {
    const lineItem =
      selected &&
      cancelLines.map((line) => {
        if (line.id === rowDetails.id) {
          return {
            ...line,
            ['return_quantity']: line.return_quantity
              ? line.return_quantity
              : line.quantity_open,
          }
        }
      })
    setCancelLines(lineItem)
  }

  // set all line item return qty to qty open
  const handleSelectAll = (e, allRowsSelected) => {
    let lineItems = cancelLines
    if (allRowsSelected) {
      lineItems = cancelLines.map((line) => {
        return {
          ...line,
          ['return_quantity']: line.quantity_open,
        }
      })
    }
    setCancelLines(lineItems)
  }

  const handleSubmit = async () => {
    const json = {
      order_id: orderDetails.id,
      reason_id: selectedReason ? parseInt(selectedReason) : null,
      cancel_lines: [],
    }
    const lines = cancelLines.map(
      ({ variant, return_quantity, brand_identifier }) => ({
        variant: { id: variant.id },
        quantity: return_quantity,
        ...(brand_identifier && { brand_identifier }),
      })
    )
    json.cancel_lines = lines
    try {
      await cancelOrderLines(json)
      showToast({
        label: 'Cancel Created.',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      let errorMessage =
        'Your attempt to cancel this order failed. Please refresh and try again.'
      if (error.response && error.response.data.detail) {
        errorMessage = error.response.data.detail
      }
      showToast({
        label: errorMessage,
        isDismissable: true,
        variant: error,
        id: '1',
      })
    } finally {
      setShowCancelItemsModal(false)
      reloadOrder()
    }
  }

  return (
    <>
      <Modal
        headerText='Cancel Items'
        size='medium'
        onClose={() => setShowCancelItemsModal(false)}
        isVisible={showCancelItemsModal}
        footerButtons={[
          {
            onClick: () => setShowCancelItemsModal(false),
            text: 'Close',
            variant: 'secondary',
          },
          {
            onClick: () => handleSubmit(),
            text: 'Cancel Items',
            variant: 'primary',
            isDisabled: !selectedReason,
          },
        ]}
        onBackdropClick={() => setShowCancelItemsModal(false)}
      >
        <>
          <div className='mb-4'>
            <Banner
              label='Please Note: When you click submit, your cancelation request will be processed immediately and communications may be sent to the retailer to inform them of cancelation. This action cannot be reversed.'
              variant='error'
            />
          </div>
          {/* To-Do - NOTE: In current platform if selected reason is 2, we ask for return Date */}
          {/* <div v-if="reason === '2'" class="mb-md">
          <p class="has-text-weight-bold mb-xs">Estimated Return Date</p>
          <p class="has-text-grey is-size-7 mb-md">
            Since you are cancelling this order due to no stock being available,
            please enter a date when you expect the items to return to stock.
            If the items have been discontinued, please update the status
            of the item in the Catalog manager.
          </p>
          <b-field expanded>
            <b-input type="date" v-model="returnDate"></b-input>
          </b-field>
        </div> */}
          <div className='mb-4'>
            <Dropdown
              label='Reason'
              width='100%'
              required
              value={selectedReason}
              options={supplierReasonOptions}
              onChange={(option: any) => setSelectedReason(option.value)}
            />
          </div>
          <Table
            columns={lineItemsColumn}
            data={orderLinesData}
            loading={false}
            selectable
            onRowSelect={(e, rowDetails, selected) =>
              handleSelection(e, rowDetails, selected)
            }
            onAllRowSelect={(e, allRowsSelected) =>
              handleSelectAll(e, allRowsSelected)
            }
          />
        </>
      </Modal>
    </>
  )
}

export default CancelItemsModal
