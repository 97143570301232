import React, { useState } from 'react'
import Card from 'atoms/card/card'
import Button from 'atoms/button/button'
import { GridCol, GridRow, Icon, PageHeader } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { EDIAccountProps } from 'modules/onboarding/utils/types'

type FTPLoginDetailCardProps = {
  account: EDIAccountProps
}

const FTPLoginDetailCard: React.FC<FTPLoginDetailCardProps> = ({
  account: { qualifierId, senderID, username, password },
}) => {
  const showToast = useToast()

  const [showPassword, setShowPassword] = useState(false)

  const getPassword = () => {
    const hiddenPassword = new Array(password.length).fill('*')
    return (
      <div className='is-flex'>
        <p>{showPassword ? password : hiddenPassword}</p>
        <Button
          text=''
          variant='tertiary'
          icon={showPassword ? 'ViewOff' : 'View'}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
    )
  }

  const copyTextToClipboard = async () => {
    try {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(
          JSON.stringify({
            qualifier: qualifierId,
            host: 'ftp.revcascade.com (port 22)',
            username,
            password,
          })
        )
        showToast({
          label: 'copied!',
          isDismissable: true,
          id: '1',
        })
      }
    } catch (error) {
      showToast({
        label: 'something went wrong',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    }
  }

  return (
    <Card
      open={true}
      showCollapse={true}
      showDivider={true}
      headerContent={
        <GridRow padding={false}>
          <GridCol sm={11} md={11} lg={11}>
            <PageHeader h1Text='FPT Login Details' />
          </GridCol>
          <GridCol sm={1} md={1} lg={1}>
            <div className='mt-3 hover-cursor' onClick={copyTextToClipboard}>
              <Icon iconName='Copy' size={20} />
            </div>
          </GridCol>
        </GridRow>
      }
      bodyContent={
        <>
          <div className='mb-4'>
            <p className='label'>EDI Qualifier / ID</p>
            <p>{`${qualifierId} / ${senderID}`}</p>
          </div>
          <div className='mb-4'>
            <p className='label'>SFTP Host</p>
            <p>ftp.revcascade.com (port 22)</p>
          </div>
          <div className='mb-4'>
            <p className='label'>SFTP Username</p>
            <p>{username}</p>
          </div>
          <div>
            <p className='label'>SFTP Password</p>
            <p>{getPassword()}</p>
          </div>
        </>
      }
    />
  )
}

export default FTPLoginDetailCard
