import React from 'react'
import { Input as InputUI, MaskOptions } from '@teamfabric/copilot-ui'

export type InputProps = {
  label: string
  required?: boolean
  inputProps: any
  // maskOptions?: any
  placeholder?: string
  onChangeSet?: Function
  width?: string
  message?: string
  maskOptions?: MaskOptions
  messageType?: any
  iconProps?: any
  prefix?: string
  suffix?: string
}

const Input: React.FC<InputProps> = ({ ...props }) => {
  return <InputUI {...props} />
}

export default Input
