import createGenericSlice from './genericSlice'

const initialState = {
  fabricTemplateParams: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  shopifyTemplateParams: {
    is_public: 0,
    order_by: '-id',
    limit: 10,
    page: 1,
  },
}

const externalPlatformTemplatesSlice = createGenericSlice(
  'externalPlatformTemplates',
  initialState
)

export const { setCurrentPage } = externalPlatformTemplatesSlice.actions

export default externalPlatformTemplatesSlice.reducer
