import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSorting } from 'store/invoicesSlice'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import { ORDERS } from 'modules/orders/constants'
import ItemDetail from '../../modal/ItemDetail'
import { getTenantType } from 'api/helper'
import Link from 'atoms/link/link'
import { BUTTONS } from 'lib/constants'
import { VARIANTS } from 'lib/constants/index'
import InvoiceDetail from '../../modal/invoices/InvoiceDetail'
import InvoicePaidPresenter from './parts/invoiceStatusPresenter'
import Box from 'atoms/box/box'
import InvoicesActionsMenu from './parts/invoicesActionsMenu'
import AcceptInvoicesModal from '../../modal/invoices/acceptInvoicesModal'
import MarkInvoicesPaidModal from '../../modal/invoices/markInvoicesPaidModal'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  showAcceptInvoiceModal: boolean
  setShowAcceptInvoiceModal: any
  showMarkInvoicePaidModal: boolean
  setShowMarkInvoicePaidModal: any
}

const InvoicesTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showInvoiceDetailModal, setShowInvoiceDetailModal] = useState(false)
  const [invoice, setInvoice] = useState({})
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [orderNumber, setOrderNumber] = useState('')

  const { isRetailer } = getTenantType()

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'Invoice #',
      },
      {
        title: 'PO #',
      },
      {
        sortable: true,
        title: 'Submitted',
        width: '13%',
      },
      {
        sortable: true,
        title: 'Acknowledged',
        width: '13%',
      },
      {
        sortable: true,
        title: 'Subtotal',
        width: '10%',
      },
      {
        title: 'Adjustments',
        width: '10%',
      },
      {
        sortable: true,
        title: 'Total',
        width: '10%',
      },
      {
        title: 'Actions',
        width: '80',
      },
    ]
    if (isRetailer) {
      columns.splice(2, 0, { title: 'Supplier' })
      columns.splice(4, 0, { title: 'Due', width: '100' })
      columns.splice(9, 0, { title: 'Paid', width: '70' })
    } else {
      columns.splice(2, 0, { title: 'Merchant' })
    }
    return columns
  }, [isRetailer])

  const getAdjustments = (due_amount: number, subtotal: number) => {
    return due_amount - subtotal
  }

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((invoice, id) => {
      return {
        id,
        data: {
          'Invoice #': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowInvoiceDetailModal(true)
                  setInvoice(invoice)
                }}
                label={invoice?.invoice_number}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'PO #': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowOrderDetailModal(true)
                  setOrderId(invoice?.order_id)
                  setOrderNumber(invoice?.purchase_order_number)
                }}
                label={invoice?.purchase_order_number}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Merchant': { value: invoice?.retailer?.name },
          'Supplier': { value: invoice?.brand?.name },
          'Due': { value: formatDate({ date: invoice?.terms_date_due }) },
          'Submitted': { value: formatDate({ date: invoice?.invoiced_at }) },
          'Acknowledged': {
            value: formatDate({ date: invoice?.acknowledged_at }),
          },
          'Subtotal': { value: formatCurrency(invoice?.subtotal) },
          'Adjustments': {
            value: formatCurrency(
              getAdjustments(invoice?.due_amount, invoice?.subtotal)
            ),
          },
          'Total': { value: formatCurrency(invoice?.due_amount) },
          'Paid': {
            onRender: () => <InvoicePaidPresenter paid_at={invoice?.paid_at} />,
          },
          'Actions': {
            onRender: () => (
              <InvoicesActionsMenu
                isRetailer={isRetailer}
                invoice={invoice}
                setShowAcceptInvoiceModal={props.setShowAcceptInvoiceModal}
                setShowMarkInvoicePaidModal={props.setShowMarkInvoicePaidModal}
                setInvoice={setInvoice}
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleSort = (key: string) => {
    const mappedKey = ORDERS.SORTING_MAPS.INVOICES[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <>
      <Box dataTestid='invoices-table' margin={{ bottom: 7 }}>
        {/* Modal Invoice Detail */}
        <Modal
          headerText={`Invoice Details`}
          description=''
          size='small'
          onClose={() => setShowInvoiceDetailModal(false)}
          isVisible={showInvoiceDetailModal}
          footerButtons={[
            {
              dataTestid: '',
              onClick: () => setShowInvoiceDetailModal(false),
              text: BUTTONS.CLOSE.LABEL,
              variant: VARIANTS.PRIMARY,
            },
          ]}
          dataTestid={'invoice-details-modal'}
          onBackdropClick={() => setShowInvoiceDetailModal(false)}
        >
          <InvoiceDetail invoice={invoice} />
        </Modal>
        {/* Modal Order Detail */}
        <Modal
          headerText={`Order Details`}
          description=''
          size='small'
          onClose={() => setShowOrderDetailModal(false)}
          isVisible={showOrderDetailModal}
          footerButtons={[
            {
              dataTestid: '',
              onClick: () => setShowOrderDetailModal(false),
              text: BUTTONS.CLOSE.LABEL,
              variant: VARIANTS.PRIMARY,
            },
          ]}
          dataTestid={'order-details-modal'}
          onBackdropClick={() => setShowOrderDetailModal(false)}
        >
          <ItemDetail orderId={orderId} orderNumber={orderNumber} />
        </Modal>
        <AcceptInvoicesModal
          invoice={invoice}
          showAcceptInvoiceModal={props.showAcceptInvoiceModal}
          setShowAcceptInvoiceModal={props.setShowAcceptInvoiceModal}
        />
        <MarkInvoicesPaidModal
          invoice={invoice}
          showMarkInvoicePaidModal={props.showMarkInvoicePaidModal}
          setShowMarkInvoicePaidModal={props.setShowMarkInvoicePaidModal}
        />
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          // TODO: flip to true once bulk actions for invoices implemented
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
          onAscendingSort={(key: string) => handleSort(key)}
          onDescendingSort={(key: string) => handleSort(key)}
        />
      </Box>
    </>
  )
}

export default InvoicesTable
