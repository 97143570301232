import React from 'react'
import { SkeletonFieldLabel, SkeletonBadge, SkeletonH4 } from 'atoms/skeleton'
import PageHeader from 'atoms/pageHeader'
import { PageHeaderProps } from 'atoms/pageHeader'

type PageHeaderComponentProps = {
  loading?: boolean
  customClassName?: string
} & { [field in keyof PageHeaderProps]: PageHeaderProps[field] }

const PageHeaderComponent: React.FC<PageHeaderComponentProps> = ({
  ...props
}) => {
  const { loading, ...restWithoutLoading } = props
  return (
    <>
      {loading ? (
        <div className='width-100'>
          <SkeletonH4 width='50%' style={{ marginBottom: '12px' }} />
          <SkeletonFieldLabel width='25%' style={{ marginBottom: '12px' }} />
          <SkeletonBadge width='200px' style={{ marginBottom: '12px' }} />
        </div>
      ) : (
        <div className={props.customClassName}>
          <PageHeader {...restWithoutLoading} />
        </div>
      )}
    </>
  )
}

export default PageHeaderComponent
