import { FC, useMemo } from 'react'
import { getTenantType } from 'api/helper'

interface ContactProps {
  connection: {
    retailer: {
      connections_manager: any
      transaction_manager: any
      merchandising_manager: any
    }
    brand: {
      connections_manager: any
      transaction_manager: any
      merchandising_manager: any
    }
  }
  type: string
}

const ContactPresenter: FC<ContactProps> = ({ connection, type }) => {
  const { isRetailer } = getTenantType()
  const manager = isRetailer
    ? connection.brand[type + '_manager']
    : connection.retailer[type + '_manager']
  const { email, first_name: firstName, last_name: lastName } = manager || {}

  const content = useMemo(() => {
    if (email) {
      if (firstName && lastName) {
        return `${firstName} ${lastName}: ${email}`
      } else if (firstName) {
        return `${firstName}: ${email}`
      } else if (lastName) {
        return `${lastName}: ${email}`
      }
      return email
    }
    return '---'
  }, [email, firstName, lastName])

  return <span>{content}</span>
}

export default ContactPresenter
