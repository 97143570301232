import { FC, useState } from 'react'
import { Box, Button, HorizontalTab, Link } from 'atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { formatDate } from 'lib/utils/date/formatDate'
import ProposalIssueStatusPresenter from '../../presenters/issueStatus'
import IssueComments from './comments'
import PageHeaderComponent from 'molecules/PageHeader'
import GridLabelValuePair from 'molecules/modal/gridLabelValuePair'
import CloseIssueModal from '../../modals/issues/closeIssue'
import ArchiveIssueModal from '../../modals/issues/archiveIssue'
import IssueProducts from './products'

type ChildProps = {
  selected: any
  handleCloseDrawer: () => void
  loadIssues?: () => void
  isProductDrawer?: boolean
}

const IssueDetails: FC<ChildProps> = ({
  selected,
  handleCloseDrawer,
  ...props
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showCloseIssueModal, setShowCloseIssueModal] = useState(false)
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const issueTabs = [
    {
      label: 'Products',
      dataTestid: 'issue-products-tab',
      content: <IssueProducts selected={selected} />
    },
    {
      label: 'Notes',
      dataTestid: 'issue-notes-tab',
      content: <IssueComments selected={selected} />,
    },
  ]

  const row1Data = [
    {
      label: 'Title',
      value: selected?.title,
    },
    {
      label: 'Status',
      value: <ProposalIssueStatusPresenter issue={selected} />,
    },
  ]

  const row2Data = [
    {
      label: 'Created By',
      value:
        selected?.created_by?.first_name +
        ' ' +
        selected?.created_by?.last_name,
    },
    {
      label: 'Created Date',
      value: formatDate({ date: selected?.created_at }),
    },
  ]

  const row3Data = [
    {
      label: 'Description',
      value: selected?.message,
    },
  ]

  const issueIsClosed = selected?.is_closed && !selected?.is_archived
  const issueIsArchived = selected?.is_archived

  return (
    <>
      {props?.isProductDrawer ? (
        <div className='h5 mb-3 space-between'>
          <span>Inspect Issue</span>
          <Link
            dataTestid='close-link'
            label='Close Issue'
            onClick={handleCloseDrawer}
          />
        </div>
      ) : (
        <PageHeaderComponent
          h1Text={'Inspect Issue'}
          primaryButtons={[
            {
              onClick: handleCloseDrawer,
              icon: 'Close',
              variant: 'tertiary_dark',
              dataTestid: 'close-drawer-icon',
            },
          ]}
        />
      )}
      <div className='divider-4' />
      <Box dataTestid='proposal-inspect-issue-info'>
        <GridLabelValuePair
          defaultColSize={6}
          data={row1Data}
          customClassName='mb-4'
        />
        <GridLabelValuePair
          defaultColSize={6}
          data={row2Data}
          customClassName='mb-4'
        />
        <GridLabelValuePair defaultColSize={12} data={row3Data} />
      </Box>
      <HorizontalTab
        onTabChange={setActiveTabIndex}
        selectedTab={activeTabIndex}
        data={{
          tabs: issueTabs,
        }}
      />
      <div className='drawer-footer'>
        {
          <Button
            text='Cancel'
            dataTestid='cancel-button'
            onClick={handleCloseDrawer}
            variant='secondary'
          />
        }
        {!issueIsArchived && (
          <Button
            text={issueIsClosed ? 'Archive Issue' : 'Mark Issue Closed'}
            dataTestid='issue-closed-button'
            onClick={() =>
              issueIsClosed
                ? setShowArchiveModal(true)
                : setShowCloseIssueModal(true)
            }
          />
        )}
        {/* close issue modal */}
        <CloseIssueModal
          showModal={showCloseIssueModal}
          onComplete={handleCloseDrawer}
          setShowModal={setShowCloseIssueModal}
          proposal={proposalDetails}
          issue={selected}
          loadProposalIssue={props?.loadIssues}
        />
        {/* Archive modal */}
        <ArchiveIssueModal
          showModal={showArchiveModal}
          onComplete={handleCloseDrawer}
          setShowModal={setShowArchiveModal}
          proposal={proposalDetails}
          issue={selected}
          loadProposalIssue={props?.loadIssues}
        />
      </div>
    </>
  )
}

export default IssueDetails
