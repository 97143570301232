import { formatDate } from 'lib/utils/date/formatDate'
import { ONBOARDING_TASKS, WELCOME_TASKS } from '../constants'

const METHODS =
  ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS

const getCodeByParent = ({ parentTask, selectedMethod }) => {
  switch (parentTask.toLowerCase()) {
    case ONBOARDING_TASKS.QUESTIONNAIRE.VALUE:
    case ONBOARDING_TASKS.CALL.VALUE:
      return WELCOME_TASKS.CODE
    case ONBOARDING_TASKS.INFO.VALUE:
      return ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME
    case ONBOARDING_TASKS.INTEGRATION.VALUE:
      return selectedMethod
    case ONBOARDING_TASKS.NOTIFICATIONS.VALUE:
      return ONBOARDING_TASKS.NOTIFICATIONS.CODE
  }
  return ''
}

export const getCompletedDate = ({
  assignedTasks,
  parentTask,
  selectedMethod,
}) => {
  if (
    assignedTasks.length > 0 &&
    parentTask.toLowerCase() === ONBOARDING_TASKS.QUESTIONNAIRE.VALUE &&
    assignedTasks[0]?.completed_at === null &&
    assignedTasks[0]?.info?.completedDate !== ''
  ) {
    // QUESTIONNAIRE is done but not call
    return formatDate({
      date: assignedTasks[0].info.completedDate,
      withYear: false,
    })
  }
  if (selectedMethod === METHODS.API.TASK_NAME) {
    const integrationMethod = assignedTasks.find(
      ({ onboarding_task: { code } }) =>
        code === ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.CODE
    )
    return formatDate({
      date: integrationMethod?.info?.api,
      withYear: false,
    })
  }
  const foundTask = assignedTasks.find(
    ({ onboarding_task: { code } }) =>
      code === getCodeByParent({ parentTask, selectedMethod })
  )
  if (foundTask) {
    return formatDate({ date: foundTask.completed_at, withYear: false })
  }
  return '--'
}

/**
 * VerticalProgressStepper step state & sub step
 * if completed task, state is COMPLETE(D)
 * if next step, NOT_STARTED (INCOMPLETE)
 * otherwise LOCKED
 * @param isSubState boolean step or sub step
 * @param isCompleted boolean completed the task
 * @param isNext boolean is next step
 * @returns string of state
 */
export const getState = ({ isSubState, isCompleted, isNext = false }) => {
  if (isCompleted) {
    return isSubState ? 'COMPLETE' : 'COMPLETED'
  }
  if (isNext) {
    return isSubState ? 'INCOMPLETE' : 'STARTED'
  }
  return isSubState ? 'INCOMPLETE' : 'NOT_STARTED'
}

const getParentTaskByTask = (nextTask) => {
  if (!nextTask) {
    return ONBOARDING_TASKS.COMPLETE.VALUE
  }
  let parentTaskName = ONBOARDING_TASKS.QUESTIONNAIRE.VALUE
  switch (nextTask.onboarding_task.code) {
    case WELCOME_TASKS.CODE:
      if (
        nextTask?.info?.completedDate === undefined ||
        nextTask?.info?.completedDate === ''
      ) {
        return ONBOARDING_TASKS.QUESTIONNAIRE.VALUE
      }
      return ONBOARDING_TASKS.CALL.VALUE
    case ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME:
    case ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME:
    case ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME:
      parentTaskName = ONBOARDING_TASKS.INFO.VALUE
      break
    case ONBOARDING_TASKS.NOTIFICATIONS.CODE:
      parentTaskName = ONBOARDING_TASKS.NOTIFICATIONS.VALUE
      break
    case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.CODE:
    case METHODS.EDI.TASK_NAME:
    case METHODS.API.TASK_NAME:
    case METHODS.BIGCOMMERCE.TASK_NAME:
    case METHODS.SHIPSTATION.TASK_NAME:
      parentTaskName = ONBOARDING_TASKS.INTEGRATION.VALUE
      break
  }
  return parentTaskName
}
/**
 * @param tasks GET /assigned-tasks/
 */
export const getNextTaskCodeByTasks = ({ tasks, selectedMethod }) => {
  let nextTask = null
  const notificationTask = tasks.find(
    ({ onboarding_task: { code } }) =>
      code === ONBOARDING_TASKS.NOTIFICATIONS.VALUE
  )
  const integrationCodes = [
    METHODS.EDI.TASK_NAME,
    METHODS.API.TASK_NAME,
    METHODS.BIGCOMMERCE.TASK_NAME,
    METHODS.SHIPSTATION.TASK_NAME,
  ]
  const skipTasks = integrationCodes.filter((code) => code !== selectedMethod)
  for (const el of tasks) {
    if (
      !el.completed_at &&
      el.onboarding_task.code !== 'feedback' &&
      !skipTasks.includes(el.onboarding_task.code)
    ) {
      nextTask = el
      break
    }
  }
  if (
    nextTask &&
    (nextTask.onboarding_task.code === ONBOARDING_TASKS.INTEGRATION.VALUE ||
      integrationCodes.includes(nextTask.onboarding_task.code)) &&
    notificationTask.completed_at === null
  ) {
    // order of the tasks is wrong it should be notification
    nextTask = notificationTask
  }
  return getParentTaskByTask(nextTask)
}

export const getEdiProcessNum = (info) => {
  let processNum = 1
  Object.keys(info).forEach((key) => {
    switch (key) {
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVOICE.CODE:
        processNum = 7
        break
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_FULFILLMENT.CODE:
        processNum = 6
        break
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_ORDERS.CODE:
        processNum = 5
        break
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVENTORY.CODE:
        processNum = 4
        break
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_SAMPLE_ITEMS.CODE:
        processNum = 3
        break
      case ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_EDI.CODE:
        processNum = 2
        break
    }
  })
  return processNum
}

export const getBigCommerceTaskNum = ({ result, info, completed_at }) => {
  if (completed_at) {
    return 3
  }
  if (Object.keys(info).includes(METHODS.BIGCOMMERCE.TASK_NAME)) {
    return 2
  }
  if (result.introDone) {
    return 1
  }
  return 0
}

export const isStepAvaiableToClick = ({ key, nextAvaiableStep, process }) => {
  const prevTask = ONBOARDING_TASKS[nextAvaiableStep.toUpperCase()].PREV
  return (
    key === nextAvaiableStep.toUpperCase() &&
    process[prevTask.toLowerCase()].completed
  )
}

export const ediAccountInitData = {
  qualifierId: '',
  senderID: '',
}

export const ftpAccountInitData = {
  username: '',
  password: '',
}
