import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import SearchBar from 'molecules/search/searchBarTable'
import InvoicesTable from '../components/table/invoices/invoicesTable'
import { getInvoices } from 'api/invoices'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { BUTTONS, PAGE_TYPE, SEARCH } from 'lib/constants'
import Filters from '../components/filters/invoices'
import { setSearch } from 'store/invoicesSlice'
import PageHeaderComponent from 'molecules/PageHeader'
import InvoiceExportModal from '../components/modal/invoices/invoiceExport'
import { GridCol, GridRow, Box } from 'atoms'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import BulkActionsBar from 'organisms/bulkActions'

const InvoicesTemplate: FC = () => {
  const params = useSelector((state: RootState) => state.invoices.params as any)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const [showAcceptInvoiceModal, setShowAcceptInvoiceModal] = useState(false)
  const [showMarkInvoicePaidModal, setShowMarkInvoicePaidModal] =
    useState(false)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getInvoices({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.INVOICE_LIST)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <InvoiceExportModal
            showModal={showExportModal}
            setShowModal={setShowExportModal}
            params={params}
          />
          <PageHeaderComponent
            h1Text='Invoices'
            primaryButtons={[
              {
                onClick: () => setShowExportModal(true),
                text: BUTTONS.EXPORT.LABEL,
                icon: BUTTONS.EXPORT.LABEL,
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.ORDER}
            setSearch={setSearch}
            className='mt-4'
            value={params.search}
          />
          <div className='filterDiv'>
            <Filters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            // TODO: flip to true once bulk actions for invoices implemented
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.INVOICES}
          />
          <InvoicesTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            currentSortBy={params.order_by}
            totalRows={totalRows}
            showAcceptInvoiceModal={showAcceptInvoiceModal}
            setShowAcceptInvoiceModal={setShowAcceptInvoiceModal}
            showMarkInvoicePaidModal={showMarkInvoicePaidModal}
            setShowMarkInvoicePaidModal={setShowMarkInvoicePaidModal}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default InvoicesTemplate
