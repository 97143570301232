import React, { useEffect, useState } from 'react'
import { getTransformers } from 'api/transformers'
import Dropdown from 'atoms/dropdown/dropdown'

type ChildProps = {
  handleUpdate?: (data: any) => void
}

const SelectTransformer: React.FC<ChildProps> = ({ handleUpdate }) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState({
    id: '',
    label: '',
  })

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data } = await getTransformers({
        params: { pagination: 0 },
      })
      const options = []
      if (data.count > 0) {
        data.results.map((el) => {
          options.push({
            id: el.id,
            label: el.name,
          })
        })
      }
      setOptions(options)
      setLoading(false)
    })()
  }, [])

  return (
    <Dropdown
      required
      label='Transformer'
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder='Select a transformer'
      disabled={loading}
    />
  )
}

export default SelectTransformer
