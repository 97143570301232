import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { getAgeSummary, getCancels, getOrders } from 'api/orders'
import { getReturns } from 'api/returns'
import { getProposalSummary } from 'api/proposals'
import { getFeedRequests } from 'api/products'
import { StyledWrapper } from '../../styles'
import Search from 'atoms/search/search'
import SelectableCardGrid from 'molecules/selectableCardGrid'
import { keyBy, sortBy } from 'lodash'
import AdvanceSearch, { identifierOptions } from './components/advanceSearch'
import { useDispatch, useSelector } from 'react-redux'
import { setSearch as setOrderSearch } from 'store/orderSlice'
import { setSearch as setInvoiceSearch } from 'store/invoicesSlice'
import { setSearch as setShipmentSearch } from 'store/shipmentsSlice'
import OrderOverviewHeader from 'molecules/order/overviewHeader'
import { setParams as setCancellationParams } from 'store/cancellationsSlice'
import { setParams as setReturnParams } from 'store/returnsSlice'
import {
  resetAllFilters as resetAllOrderFilters,
  setParams as setOrderParams,
} from 'store/orderSlice'
import { resetAllFilters as resetAllProposalFilters } from 'store/proposalsSlice'
import { Skeleton } from 'atoms/skeleton'
import { ORDERS } from '../constants'
import { PRODUCTS } from 'modules/products/constants'

const orderIdentifiers = [
  identifierOptions[0].id,
  identifierOptions[1].id,
  identifierOptions[2].id,
  identifierOptions[3].id,
]

const OrderDashboardTemplate: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loadingAgeSummary, setLoadingAgeSummary] = useState(true)
  const [loadingCancels, setLoadingCancels] = useState(true)
  const [loadingProposalSummary, setLoadingProposalSummary] = useState(true)

  const [openOrders, setOpenOrders] = useState(0)
  const [currentOrders, setCurrentOrders] = useState(0)
  const [pastFulfillmentOrders, setPastFulfillmentOrders] = useState(0)
  const [lateInvoices, setLateInvoices] = useState(0)

  const [cancels, setCancels] = useState(0)
  const [returns, setReturns] = useState(0)
  const [messagesCount, setMessagesCount] = useState(0)

  const [revisedCount, setRevisedCount] = useState(0)
  const [importRequestCount, setImportRequestCount] = useState(0)

  // Advanced Search
  const [searchValue, setSearchValue] = useState('')
  const [advancedSearchValues, setAdvancedSearchValues] = useState({
    identifier: '',
    purchaseOrderNum: '',
    status: '',
  })

  // default params
  const cancellationParams = useSelector(
    (state: RootState) => state.cancellations.params
  )
  const returnDefaultParams = useSelector(
    (state: RootState) => state.returns.params
  )
  const orderDefaultParams = useSelector(
    (state: RootState) => state.orders.params
  )

  useEffect(() => {
    ;(async () => {
      setLoadingAgeSummary(true)
      const { data } = await getAgeSummary({})
      const vendors = sortBy(data, 'name')
      let [pastFulfillment, awaitingInvoices, totalCurrent, totalOpen] = [
        0, 0, 0, 0,
      ]
      vendors.forEach((vendor) => {
        pastFulfillment += vendor.late_orders_awaiting_fulfillment
        awaitingInvoices += vendor.late_orders_awaiting_invoicing
        totalCurrent +=
          vendor.current_orders_awaiting_fulfillment +
          vendor.current_orders_awaiting_invoicing
        totalOpen = pastFulfillment + awaitingInvoices + totalCurrent
      })
      setOpenOrders(totalOpen)
      setCurrentOrders(totalCurrent)
      setPastFulfillmentOrders(pastFulfillment)
      setLateInvoices(awaitingInvoices)
      setLoadingAgeSummary(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoadingCancels(true)
      const cancelsResponse = await getCancels({
        params: {
          canceled_by: 'retailer',
          is_acknowledged: 0,
          fields: 'id',
          limit: 1,
        },
      })
      setCancels(cancelsResponse.data.count)

      const returnsResponse = await getReturns({
        params: {
          status: 'pending',
          fields: 'id',
          limit: 1,
        },
      })
      setReturns(returnsResponse.data.count)

      const ordersResponse = await getOrders({
        params: {
          fields: 'id',
          has_unread_memos: 1,
        },
      })
      setMessagesCount(ordersResponse.data.count)
      setLoadingCancels(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoadingProposalSummary(true)
      const { data } = await getProposalSummary({})
      const statuses = keyBy(data, (o) => o.status)
      const revisedCount = 'revised' in statuses ? statuses.revised.count : 0
      setRevisedCount(revisedCount)

      const feedRequestResponse = await getFeedRequests({
        params: {
          status: 'pending,open',
        },
      })
      setImportRequestCount(feedRequestResponse.data.count)
      setLoadingProposalSummary(false)
    })()
  }, [])

  useEffect(() => {
    if (advancedSearchValues.identifier !== '') {
      let name = ''
      let path = '/orders'
      if (orderIdentifiers.includes(advancedSearchValues.identifier)) {
        name = 'PO'
      } else if (advancedSearchValues.identifier === identifierOptions[4].id) {
        name = 'Tracking'
        path = '/orders/tracking'
      } else {
        name = 'Invoice'
        path = '/orders/invoices'
      }
      setSearchValue(
        `${name} Number, ${advancedSearchValues.purchaseOrderNum}, ${advancedSearchValues.status}`
      )
      navigate(path)
    }
  }, [advancedSearchValues])

  const resetSearch = () => {
    switch (advancedSearchValues.identifier) {
      case identifierOptions[0].id:
      case identifierOptions[1].id:
      case identifierOptions[2].id:
      case identifierOptions[3].id:
        dispatch(setOrderSearch(''))
        break
      case identifierOptions[4].id:
        dispatch(setShipmentSearch(''))
        break
      case identifierOptions[5].id:
        dispatch(setInvoiceSearch(''))
        break
      default:
    }
  }

  const [searchData, setSearchData] = useState([])

  const searchOrders = async ({ searchText }) => {
    setLoading(true)
    const orderParams = {
      order_by: '-id',
      fields: 'id,purchase_order_number,customer_order_number',
    }
    const {
      data: { results },
    } = await getOrders({
      params: { ...orderParams, purchase_order_number_sw: searchText },
    })
    setSearchData(results)
    setLoading(false)
  }

  const getResults = () => {
    if (loading) {
      return (
        <div className='p-3 width-100'>
          <Skeleton height='20px' width='100%' />
        </div>
      )
    } else if (!loading && searchData.length > 0) {
      return searchData.map((order: any) => {
        return (
          <Link key={order.id} to={`/orders/${order.id}/`}>
            <div className='searchResultListItem'>
              <div className='heading'>PO #{order.purchase_order_number}</div>
              <div className='subHeading'>
                {order.customer_order_number
                  ? `Customer Order #${order.customer_order_number}`
                  : '- Not set -'}
              </div>
            </div>
          </Link>
        )
      })
    } else {
      return <div className='label p-3'> No results found </div>
    }
  }

  return (
    <StyledWrapper>
      <OrderOverviewHeader />
      <div className='mb-4'>
        {/* TO-DO: update the search similar to connections dashboard */}
        <Search
          width='357px'
          value={searchValue}
          onClear={resetSearch}
          onSearch={(searchText: string) => searchOrders({ searchText })}
          onChange={(searchText: string) => {
            setTimeout(() => {
              searchOrders({ searchText })
            }, 2000)
          }}
          results={getResults()}
          advancedSearch={
            <AdvanceSearch
              setAdvancedSearchValues={setAdvancedSearchValues}
              resetSearch={() => resetSearch()}
            />
          }
          placeholder='Search by order IDs'
        />
      </div>
      <div>
        <h5 className='h5 mb-4'>Open Orders</h5>
        <SelectableCardGrid
          loading={loadingAgeSummary}
          gridSize={3}
          data={[
            {
              label: 'All Open Orders',
              value: openOrders,
              handleClick: () => {
                dispatch(
                  resetAllOrderFilters({ tabPresets: ORDERS.TAB_PRESETS.ALL })
                )
                navigate('/orders')
              },
            },
            {
              label: 'Current orders',
              value: currentOrders,
              handleClick: () => {
                dispatch(
                  resetAllOrderFilters({
                    tabPresets: ORDERS.TAB_PRESETS.CURRENT,
                  })
                )
                navigate('/orders')
              },
            },
            {
              label: 'Past Fulfillment SLA',
              value: pastFulfillmentOrders,
              handleClick: () => {
                dispatch(
                  resetAllOrderFilters({
                    tabPresets: ORDERS.TAB_PRESETS.PAST_SLA,
                  })
                )
                navigate('/orders')
              },
            },
            {
              label: 'Late invoices',
              value: lateInvoices,
              handleClick: () => {
                dispatch(
                  resetAllOrderFilters({
                    tabPresets: ORDERS.TAB_PRESETS.REQUIRE_INVOICE,
                  })
                )
                navigate('/orders')
              },
            },
          ]}
        />
        <h5 className='h5 mb-4 mt-4'>Orders requiring attention</h5>
        <SelectableCardGrid
          loading={loadingCancels}
          gridSize={4}
          data={[
            {
              label: 'New cancels',
              value: cancels,
              handleClick: () => {
                dispatch(
                  setCancellationParams({
                    ...cancellationParams,
                    is_acknowledged: '0',
                    canceled_by: 'retailer',
                  })
                )
                navigate('/orders/cancellations')
              },
            },
            {
              label: 'New returns',
              value: returns,
              handleClick: () => {
                dispatch(
                  setReturnParams({
                    ...returnDefaultParams,
                    status: 'pending',
                  })
                )
                navigate('/orders/returns')
              },
            },
            {
              label: 'Order with new messages',
              value: messagesCount,
              handleClick: () => {
                dispatch(
                  setOrderParams({
                    ...orderDefaultParams,
                    has_unread_memos: 1,
                  })
                )
                navigate('/orders')
              },
            },
          ]}
        />
        <h5 className='h5 mb-4 mt-4'>Proposals</h5>
        <SelectableCardGrid
          loading={loadingProposalSummary}
          gridSize={6}
          data={[
            {
              label: 'Review your proposals?',
              value: revisedCount,
              handleClick: () => {
                dispatch(
                  resetAllProposalFilters({
                    tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.RETURNED,
                  })
                )
                navigate('/products/proposals')
              },
            },
            {
              label: 'Review import requests',
              value: importRequestCount,
              handleClick: () => navigate('/products/import-requests'),
            },
          ]}
        />
      </div>
    </StyledWrapper>
  )
}

export default OrderDashboardTemplate
