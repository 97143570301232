import API, { API_URL, RETAILER_API_URL } from 'api'

export const getOrders = async ({ params = {} }) => {
  return await API.get(API_URL.GET_ORDERS(), { params })
}

export const getOrderDetail = async ({ id, params = {} }) => {
  return await API.get(API_URL.GET_ORDER_BY_ID({ id }), { params })
}

export const getAgeSummary = async ({ params = {} }) => {
  return await API.get(API_URL.GET_ORDER_AGE_SUMMARY(), { params })
}

export const exportOrder = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_ORDERS(), { params })
}

export const getActivity = async ({ params = {}, id }) => {
  return await API.get(API_URL.ORDER_ACTIVITY({ id }), { params })
}

export const cancelOrderLines = async (body) => {
  return await API.post(API_URL.CANCEL_ITEMS(), body)
}

export const updateOrderById = async ({ id, body }) => {
  return await API.patch(API_URL.GET_ORDER_BY_ID({ id }), body)
}

export const getCancels = async ({ params = {} }) => {
  return await API.get(API_URL.CANCEL_ITEMS(), { params })
}

export const createOrder = async (body) => {
  return await API.post(RETAILER_API_URL.ORDERS(), body)
}

export const pushOrder = async ({ id, body }) => {
  return await API.post(API_URL.PUSH_ORDER({ id }), body)
}
