import Dropzone from 'atoms/dropzone'
import React from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { uploadTempfile } from 'api/products'
import Papa from 'papaparse'

type UploadCostsFileProps = {
  setTempfileId: (value: string) => void
}

const UploadCostsFile: React.FC<UploadCostsFileProps> = ({ setTempfileId }) => {
  const showToast = useToast()

  const setFileStatus = ({ setFiles, status, file }) => {
    setFiles((prevState) =>
      prevState.map((item) =>
        item.name === file.name ? { ...item, status } : item
      )
    )
  }

  const onDrop = (acceptedFiles, setFiles) => {
    acceptedFiles.forEach(async (file) => {
      setFileStatus({ setFiles, status: 'loading', file })
      try {
        const {
          data: { id },
        } = await uploadTempfile(file)
        setTempfileId(id)
        setFileStatus({ setFiles, status: 'success', file })
      } catch (error) {
        showToast({
          label: 'Error Uploading File ',
          isDismissable: true,
          id: 'error-uploading-file',
          variant: 'error',
        })
        setFileStatus({ setFiles, status: 'error', file })
      }
    })
  }

  const onRemove = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'loading', file })
    try {
      setFileStatus({ setFiles, status: 'success', file })
      setTempfileId(null)
      return true
    } catch (error) {
      showToast({
        label: 'Error Removing File: ' + error,
        isDismissable: true,
        id: 'error-removing-file',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  const onReplace = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'loading', file })
    try {
      const response = await uploadTempfile(file)
      setTempfileId(response?.data?.id)
      setFileStatus({ setFiles, status: 'success', file })
      return true
    } catch (error) {
      showToast({
        label: 'Error Replacing File: ' + error,
        isDismissable: true,
        id: 'error-replacing-file',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  const onRetry = async (file, setFiles) => {
    setFileStatus({ setFiles, status: 'retrying', file })
    try {
      await uploadTempfile(file)
      setFileStatus({ setFiles, status: 'success', file })
      return true
    } catch (error) {
      showToast({
        label: 'Error Retrying File Upload: ' + error,
        isDismissable: true,
        id: 'error-retrying-file-upload',
        variant: 'error',
      })
      setFileStatus({ setFiles, status: 'error', file })
      return false
    }
  }

  const downloadSample = async () => {
    // Convert the data to CSV format using PapaParse
    const csv = Papa.unparse([
      {
        'SKU': '',
        'Retailer Cost': '',
        'Retailer Price': '',
      },
    ])

    // Create a Blob containing the CSV data
    const csvBlob = new Blob([csv], { type: 'text/csv' })

    // Create a URL for the Blob
    const csvUrl = URL.createObjectURL(csvBlob)

    // Create an invisible link element to trigger the download
    const link = document.createElement('a')
    link.href = csvUrl
    link.download = 'sample_cost_update_template.csv'

    link.click()

    // Clean up by revoking the URL to release resources
    URL.revokeObjectURL(csvUrl)
  }

  return (
    <>
      <p>
        Upload a cost update template containing the proposal items costs and
        suggest retail prices you’d like to update.
      </p>
      <Dropzone
        accept={{
          'application/vnd.ms-excel': ['.xls', '.xlsx'],
          'text/csv': ['.csv'],
          'text/plain': ['.txt'],
        }}
        dataTestId='cost-update-dropzone'
        maxFiles={1}
        onDrop={onDrop}
        onRemove={onRemove}
        onReplace={onReplace}
        onRetry={onRetry}
        secondaryLink={{
          label: 'Download a cost update sample',
          mode: 'inline',
          size: 'medium',
          theme: 'light',
          variant: 'primary',
          onClick: () => {
            downloadSample()
          },
        }}
      />
    </>
  )
}

export default UploadCostsFile
