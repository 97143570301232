import React from 'react'
import { StyledReturnItemCol } from '../../styles'
import Image from 'atoms/image/image'

type ReturnItemProps = {
  variant: any
}

const ReturnItemCol: React.FC<ReturnItemProps> = ({ variant }) => {
  return (
    <StyledReturnItemCol>
      <div className='container v-center'>
        <Image src={variant.media[0].url} alt='item' size='small' />
        <div>
          <p className='variantName'>{variant.name}</p>
          <p className='grey-400'>{variant.brand_identifier}</p>
        </div>
      </div>
    </StyledReturnItemCol>
  )
}

export default ReturnItemCol
