import { getTenantType } from 'api/helper'
const { isRetailer } = getTenantType()

export const PROPOSALS = {
  STATUS: {
    NAME: 'Status',
    PARAM: 'status',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'status',
        label: 'Draft',
        value: 'draft',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'status',
        label: 'Proposed',
        value: 'proposed',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'status',
        label: 'Approved',
        value: 'approved',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'status',
        label: 'Pricing Approved',
        value: 'pricing_approved',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '4',
        key: 'status',
        label: 'Revised',
        value: 'revised',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '5',
        key: 'status',
        label: 'Completed',
        value: 'completed',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '6',
        key: 'status',
        label: 'Declined',
        value: 'declined',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  TYPE: {
    NAME_SHORT: 'Type',
    NAME_FULL: 'Proposal Type',
    PARAM: 'proposal_type',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'proposal_type',
        label: 'New Products',
        value: 'new_product',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'proposal_type',
        label: 'Cost Updates',
        value: 'cost_update',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'proposal_type',
        label: 'Attribute Updates',
        value: 'attribute_update',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'proposal_type',
        label: 'Image Updates',
        value: 'image_update',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  TAB_PRESETS: {
    ALL: {
      label: 'All',
      value: 'all',
      tabIndex: 0,
    },
    DRAFT: {
      label: 'Draft',
      value: 'draft',
      tabIndex: 1,
    },
    PROPOSED: {
      label: 'Proposed',
      value: 'proposed, approved, pricing_approved',
      tabIndex: isRetailer ? 1 : 2,
    },
    RETURNED: {
      label: 'Returned',
      value: 'revised',
      tabIndex: isRetailer ? 2 : 3,
    },
    PUBLISHING: {
      label: 'Publishing',
      value: 'completed',
      tabIndex: isRetailer ? 3 : 4,
    },
    COMPLETED: {
      label: 'Completed',
      value: 'completed',
      tabIndex: isRetailer ? 4 : 5,
    },
  },
}
