import React, { useState, useEffect } from 'react'
import Table from 'atoms/table/table'
import { Box } from 'atoms'
import AcceptInvoiceStatusPresenter from './acceptInvoiceStatusPresenter'

type ChildProps = {
  invoice: any
  isBulkAction?: boolean
}

const AcceptInvoices: React.FC<ChildProps> = ({ invoice, isBulkAction }) => {
  const [invoicesData, setInvoicesData] = useState<any[]>([])

  useEffect(() => {
    if (!isBulkAction) {
      formatSetTableData({ data: [invoice] })
    }
    // else formatData(selected) - finish for bulkactions
  }, [])

  const columns = [
    {
      title: 'Invoice #',
    },
    {
      title: 'Status',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((invoice, id) => {
      return {
        id,
        data: {
          'Invoice #': { value: invoice?.invoice_number },
          'Status': {
            onRender: () => (
              <AcceptInvoiceStatusPresenter
                acknowledged_at={invoice?.acknowledged_at}
              />
            ),
          },
        },
      }
    })
    setInvoicesData(formattedData)
  }

  return (
    <Box flex={{ flexDirection: 'column' }} gap={4}>
      <p>Are you sure you want to accept the following invoice?</p>
      <Table columns={columns} data={invoicesData} selectable={false} />
    </Box>
  )
}

export default AcceptInvoices
