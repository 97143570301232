import API, { API_URL } from 'api'

export const getCancelDetail = async ({ id }) => {
  return await API.get(API_URL.GET_CANCEL_DETAIL({ id }))
}

export const getRmsReturns = async ({ id }) => {
  return await API.get(API_URL.GET_RMAS_RETURNS({ id }))
}

export const postReturnLabel = async ({ id, body }) => {
  return await API.post(API_URL.CANCEL_DETAIL({ id }), body)
}

export const getReturnLabel = async ({ rmaId, rmaReturnId }) => {
  return await API.get(API_URL.RETURN_LABEL({ rmaId, rmaReturnId }))
}

export const getCancels = async ({ params = {} }) => {
  return await API.get(API_URL.GET_CANCELS(), { params })
}

export const exportCancels = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_CANCELS(), { params })
}

export const accknowledgeCancel = async ({ id }) => {
  return await API.put(API_URL.ACKNOWLEDGE_CANCEL({ id }))
}
