import React, { useState } from 'react'
import Input from 'atoms/input/input'
import Button from 'atoms/button/button'
import { postOrderMemo } from 'api/memo'
import { useToast } from '@teamfabric/copilot-ui'

type MemoInputProps = {
  orderId: number
  setMemos: (newData: any) => void
  loading: boolean
  setLoading: (value: boolean) => void
}

const MessageInput: React.FC<MemoInputProps> = ({
  orderId,
  setMemos,
  setLoading,
  loading,
}) => {
  const [memoText, setMemoText] = useState('')
  const [saving, setSaving] = useState(false)
  const showToast = useToast()

  const addMemo = async (memoText: string) => {
    if (memoText === '') {
      return
    }
    try {
      setSaving(true)
      setLoading(true)
      const { data } = await postOrderMemo({ text: memoText, orderId })
      setMemos(data)
      setLoading(false)
      setSaving(false)
      setMemoText('')
      showToast({
        label: 'Successfully added Memo',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      showToast({
        label: 'Something went wrong',
        isDismissable: true,
        variant: error,
        id: '1',
      })
    }
  }

  return (
    <div className='space-between gap-4'>
      <Input
        label=''
        width='100%'
        required
        inputProps={{
          dataTestid: '',
          value: memoText,
          placeholder: 'Type your message here',
          onChange: ({ target: { value } }) => {
            setMemoText(value)
          },
        }}
      />
      <div className='v-center'>
        <Button
          text='Post'
          onClick={() => addMemo(memoText)}
          isDisabled={memoText === ''}
          isLoading={saving || loading}
        />
      </div>
    </div>
  )
}

export default MessageInput
