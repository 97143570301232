import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentStep,
  setNextAvaiableStep,
  setNotifications,
} from 'store/onboardingSlice'
import InputWithSwitch from 'molecules/input/inputWithSwitch'
import CardLoading from 'molecules/loading/cardLoading'
import {
  getNotification,
  patchNotification,
  postNotification,
} from 'api/notifications'
import { STATUS_TYPES } from './transaction'
import { useNavigate } from 'react-router-dom'
import { putCompleteTask } from 'api/onboarding'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import { useToast } from '@teamfabric/copilot-ui'
import { PATHS } from 'routes/pageRoutes'

export const DIGEST_DATA = {
  TITLE: 'order digest',
  NAME: 'Orders Digest',
  LABEL:
    'A daily export and email of any new or open orders. Digests are sent at 5 AM Eastern.',
}

export type DigestDataType = {
  notificationId: string
  recipient_email: string
  status: string
}

type DigestProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  reseting: boolean
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const Digest: React.FC<DigestProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  reseting,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()
  const state = useSelector((state: RootState) => state)
  const notifications = state.onboarding.notifications

  const [loading, setLoading] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(false)
  const [email, setEmail] = useState('')
  const [updated, setUpdated] = useState(false)
  const [isNew, setIsNew] = useState(true)
  const [notificationId, setNotificationsId] = useState('')

  // First loaded
  useEffect(() => {
    ;(async () => {
      try {
        setIsNextButtonDisabled(false)
        const {
          data: { results },
        } = await getNotification({})
        if (results.length > 0) {
          results.forEach(({ id, type: { name }, recipient_email, status }) => {
            if (name === DIGEST_DATA.NAME) {
              setIsNew(false)
              setEmail(recipient_email)
              setSwitchChecked(status === STATUS_TYPES.ENABLED)
              setNotificationsId(id)
            }
          })
        }
      } catch (error) {
        showToast({
          label: "Couldn't load",
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      }
    })()
  }, [])

  const createPushNotification = async () => {
    const body = {
      type: { name: DIGEST_DATA.NAME },
      crontab: '0 9 * * *',
      recipient_email: email,
      status: switchChecked ? STATUS_TYPES.ENABLED : STATUS_TYPES.DISABLED,
    }
    if (isNew) {
      await postNotification({ body })
    } else {
      await patchNotification({ body, id: notificationId })
    }
  }

  const updateSlices = () => {
    dispatch(
      setNotifications({
        taskId: notifications.taskId,
        processNum: 2,
        transactionDone: true,
        digestDone: true,
        completed: true,
      })
    )
    dispatch(
      setCurrentStep({
        value:
          ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.TASK_NAME,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.INTEGRATION.VALUE))
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      if (updated) {
        setLoading(true)
        await createPushNotification()
        setLoading(false)
      }
      await putCompleteTask({ taskId: notifications.taskId })
      updateSlices()
      navigate(ONBOARDING_TASKS.INTEGRATION.LINK)
    })()
  }, [isNextClicked])

  // save and exit clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      setLoading(true)
      if (updated) {
        await createPushNotification()
      }
      await putCompleteTask({ taskId: notifications.taskId })
      setLoading(false)
      updateSlices()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {(loading || reseting) && (
        <CardLoading
          label={ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.NAME}
          num={3}
        />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={true}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.NAME}
            />
          }
          bodyContent={
            <div className='pb-5'>
              <h2 className='kicker'>{DIGEST_DATA.TITLE}</h2>
              <div>
                <InputWithSwitch
                  switchProps={{
                    label: '',
                    checked: switchChecked,
                    value: '',
                    name: '',
                    onChange: () => {
                      setUpdated(true)
                      setSwitchChecked(!switchChecked)
                    },
                  }}
                  inputProps={{
                    label: DIGEST_DATA.LABEL,
                    required: false,
                    inputProps: {
                      value: email,
                      placeholder: 'email@business.com',
                      onChange: ({ target: { value } }) => {
                        setUpdated(true)
                        setEmail(value)
                      },
                    },
                    width: '80%',
                    maskOptions: {
                      alias: 'email',
                    },
                  }}
                />
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export default Digest
