import React, { useState, useEffect, useMemo } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, RadioGroup } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { updatePlatformAccounts } from 'api/platformAccount'

type ChildProps = {
  loading: boolean
  account: any
  loadAccount: (quiet?: boolean) => void
}

const SkipFraudOrdersCard: React.FC<ChildProps> = ({
  loading,
  account,
  loadAccount
}) => {
  const { id } = useParams()
  const showToast = useToast()
  // enable if using switch
  // const [toggleValue, setToggleValue] = useState('0')
  const [optionValue, setSelectedOptionValue] = useState(null)

  useEffect(() => {
    if (!loading && account.credentials) {
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'order_risk_skipping_policy' && credential[key].values[0].value) {
            // setToggleValue('1')
            setSelectedOptionValue(credential[key].values[0].value)
          }
        })
      })
    }
  }, [!loading, account])

  const credential = useMemo(() => {
    if (!loading && account.credentials) {
      if (account.credentials.length === 0) return null
      let found = null
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'order_risk_skipping_policy') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    }
  }, [!loading, account])

  const updateNotification = async () => {
    const body = {
      credentials: [{
        credential: {
          code: 'order_risk_skipping_policy',
        },
        ...(credential !== null && { id: credential.id }),
        value: optionValue
      }]
    }
    const toastMessage = 'Preference saved!'
    try {
      await updatePlatformAccounts({ id: id, body })
      showToast({
        label: toastMessage,
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data ? response.data : 'An error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      loadAccount(true)
    }
  }

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Skip Fraud Order</span>}
            h2Text={
              <span className='body2-regular'>
                Use this setting to configure order skipping based on Shopify Fraud recommendation.
              </span>
            }
            primaryButtons={[
              {
                onClick: () => updateNotification(),
                text: 'Save',
                variant: 'secondary',
              },
            ]}
          // switchProps={{
          //   label: '',
          //   stateLabels: {
          //     OFF: 'Off',
          //     ON: 'On',
          //   },
          //   checked: toggleValue === '1',
          //   onChange: ({ target: { checked } }) => {
          //     const value = checked ? '1' : '0'
          //     setToggleValue(value)
          //     updateNotification(value)
          //   },
          // }}
          dataTestid='shopify-skip-fraud-order-header'
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            <RadioGroup
              label='Options'
              name='fraud_orders'
              value={optionValue}
              onChange={({ target: { value } }) => setSelectedOptionValue(value)}
              required
              options={[
                {
                  label: "Disabled - all orders will be imported to fabric regardless of Shopify's fraud recommendation",
                  value: 'none',
                },
                {
                  label: 'Enabled: Medium - orders that are flagged with high or medium fraud risk will NOT be automatically imported to fabric',
                  value: 'medium',
                },
                {
                  label: 'Enabled: High - orders that are flagged with high fraud risk will not be automatically imported to fabric',
                  value: 'high',
                },
              ]}
            />
          </div>
        </Box>
      }
    />
  )
}

export default SkipFraudOrdersCard
