import { useToast } from '@teamfabric/copilot-ui'
import { getProposalIssues, patchProposalIssue } from 'api/proposals'
import { Dropdown, Modal } from 'atoms'
import { ButtonVariants } from 'atoms/button/button'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'store'

type AddToOpenIssueModalProps = {
  count: number
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  itemIdArray?: any
  loadProposal?: (quiet?: boolean) => void | undefined
  isProduct?: boolean
}

const AddToOpenIssueModal: React.FC<AddToOpenIssueModalProps> = ({
  count,
  showModal,
  setShowModal,
  itemIdArray,
  ...props
}) => {
  const { id } = useParams()

  const [saving, setSaving] = useState(false)
  const [loadingIssues, setLoadingIssues] = useState(true)
  const [issues, setIssues] = useState<any>([])
  const [issueOptions, setIssueOptions] = useState<any>([])
  const [selectedIssue, setSelectedIssue] = useState<any>({})
  const [issueSummary, setIssueSummary] = useState(undefined)

  const showToast = useToast()

  const variantsSelected = useSelector(
    (state: RootState) => state.proposalItems.selectedProposalVariantIDs
  )

  const productsSelected = useSelector(
    (state: RootState) => state.proposalProducts.selectedProposalProductIds
  )

  const selectedArray = props?.isProduct ? productsSelected : variantsSelected

  // need to read proposal_variant.id
  const selected = selectedArray?.length ? selectedArray : itemIdArray

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  useEffect(() => {
    if (id && showModal) {
      fetchProposalIssues()
    }
  }, [id, showModal])

  const fetchProposalIssues = async () => {
    setLoadingIssues(true)
    const { data } = await getProposalIssues({ proposalId: id })
    const options = data?.results
      .filter((el) => !el.is_archived && !el.is_closed && !el.is_resolved)
      .map((el) => {
        return {
          id: el.id,
          label: el.title,
        }
      })
    setIssueOptions(options)
    setIssues(data.results)
    setLoadingIssues(false)
  }

  const onAddToIssue = async () => {
    const body = {
      [props?.isProduct ? 'proposal_products' : 'proposal_variants']: [],
    }
    selected.forEach((itemId) => {
      const item = { id: itemId }
      if (props?.isProduct) {
        body.proposal_products.push(item)
      } else {
        body.proposal_variants.push(item)
      }
    })

    try {
      setSaving(true)
      await patchProposalIssue({
        proposalId: proposalDetails?.id,
        issueId: selectedIssue?.id,
        body: body,
      })
      showToast({
        label: 'Issue created successfully!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error creating the issue: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setShowModal(false)
      setSaving(false)
      props.loadProposal?.(true)
    }
  }

  return (
    <>
      <Modal
        headerText='Add to open issue'
        description=''
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowModal(false),
            text: 'Close',
            variant: ButtonVariants.SECONDARY,
            isDisabled: saving,
          },
          {
            dataTestid: '',
            onClick: () => onAddToIssue(),
            text: 'Add To Issue',
            variant: ButtonVariants.PRIMARY,
            isDisabled: saving,
          },
        ]}
        dataTestid={'add-issue-modal'}
        onBackdropClick={() => setShowModal(false)}
      >
        <p className='body1_regular grey-600 mb-4'>
          {count} item will be added when this issue is created.
        </p>
        <Dropdown
          label='Issue'
          required={true}
          loading={loadingIssues}
          placeholder='Select Issue'
          onChange={(e) => {
            setSelectedIssue(e)
            const summary = issues.find((el) => el.id === e.id)
            setIssueSummary(summary.message)
          }}
          options={issueOptions}
          value={selectedIssue}
          width='100%'
        />
        <div className='divider-4' />
        {selectedIssue?.id && issueSummary !== undefined && (
          <div>
            <p className='h5 grey-900 mb-4'> Issue Summary</p>
            <span className='body1-regular grey-900'>{issueSummary}</span>
          </div>
        )}
      </Modal>
    </>
  )
}

export default AddToOpenIssueModal
