import { Badge } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import { FC, useMemo } from 'react'

interface InvoiceStatusProps {
  invoice: any
}

const InvoiceStatusPresenter: FC<InvoiceStatusProps> = ({ invoice }) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: 'Not Finalized',
      status: 'default',
      tooltipText:
        'This invoice has been registered, but because the order is not closed, is subject to change.',
    }

    if (invoice?.status === 'closed' && invoice?.acknowledged_at === null) {
      presenter.label = 'Sent to Retailer'
      presenter.tooltipText =
        'This invoice has been sent to the retailer, but the retailer has not yet acknowledged receipt.'
      presenter.status = 'alert'
    } else if (
      invoice.status === 'closed' &&
      invoice.acknowledged_at !== null
    ) {
      // abbreviating 'Received' to not have the badge concatenate the text, which also messes with the tooltip. Raised this to the UI team
      presenter.label = "Rec'd by Retailer"
      presenter.tooltipText = 'The retailer has received this invoice.'
      presenter.status = 'success'
    }

    return presenter
  }, [invoice])

  return (
    <ToolTip text={presenter.tooltipText} placement='bottomEnd'>
      <div className='hover-cursor'>
        <Badge
          label={presenter.label}
          variant='primary'
          status={presenter.status}
        />
      </div>
    </ToolTip>
  )
}

export default InvoiceStatusPresenter
