import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import CardLoading from 'molecules/loading/cardLoading'
import Button from 'atoms/button/button'
import Table from 'atoms/table/table'
import FTPLoginDetailCard from './fptLoginDetailCard'
import { EDIAccountProps } from 'modules/onboarding/utils/types'
import { getLocations } from 'api/locations'
import { getInventories } from 'api/inventory'
import Banner from 'atoms/banner/banner'
import { useToast } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type UploadInventoryProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  account: EDIAccountProps
  reseting: boolean
}

const UploadInventory: React.FC<UploadInventoryProps> = ({
  setIsNextButtonDisabled,
  account,
  reseting,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const showToast = useToast()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )

  const [loadingInventory, setLoadingInventory] = useState(false)
  const [loadingAddresses, setLoadingAddresses] = useState(false)
  const [locationExists, setLocationExists] = useState(false)
  const [inventoryExists, setInventoryExists] = useState(false)
  const [inventories, setInventories] = useState([])

  const loadInventory = async () => {
    try {
      setLoadingInventory(true)
      const {
        data: { results },
      } = await getInventories({ params: { page: 1, limit: 12 } })

      const variants = integration.selectedIntegrationInfo.variants.map(
        ({ id }) => id
      )
      const filteredInventories = results.filter(({ variant: { id } }) =>
        variants.includes(id)
      )
      const formattedInventory = filteredInventories.map(
        ({ variant, inventory }) => ({
          id: variant.identifier,
          data: {
            'SKU': {
              value: variant.identifier,
            },
            'Inventory Count': {
              value: inventory,
            },
          },
        })
      )
      setInventories(formattedInventory)
      const isExist =
        filteredInventories.filter(({ inventory }) => inventory > 0).length ===
        2
      setInventoryExists(isExist)
    } catch (error) {
      showToast({
        label: "Couldn't load inventories",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoadingInventory(false)
    }
  }

  const loadWareHouseLocations = async () => {
    try {
      setLoadingAddresses(true)
      const {
        data: { results },
      } = await getLocations({ params: { type: 'warehouse' } })
      setLocationExists(results.length > 0)
      if (results.length > 0) {
        await loadInventory()
      }
    } catch (error) {
      showToast({
        label: "Couldn't load address",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setLoadingAddresses(false)
    }
  }

  // First loaded
  useEffect(() => {
    ;(async () => {
      await loadWareHouseLocations()
    })()
  }, [])

  useEffect(() => {
    setIsNextButtonDisabled(!inventoryExists || !locationExists)
  }, [inventoryExists, locationExists])

  const tableColumns = [
    {
      sortable: false,
      title: 'SKU',
    },
    {
      sortable: false,
      title: 'Inventory Count',
    },
  ]

  return (
    <>
      {!loadingAddresses && !locationExists && (
        <div className='mb-4'>
          <Banner
            label='You do not have a fulfillment locations setup. Please add a fulfillment location in tasks to continue.'
            variant='error'
          />
        </div>
      )}
      {(loadingAddresses || reseting) && (
        <CardLoading label={currentTask.UPLOAD_INVENTORY.NAME} num={3} />
      )}
      {!loadingAddresses && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <h1 className='h5 mb-2'>{currentTask.UPLOAD_INVENTORY.NAME}</h1>
              <p className='body1-regular'>
                1. Open the FTP page and upload the 846 inventory file into your
                outbox.
              </p>
              <p className='body1-regular'>
                2. Wait 10-15 minutes for the file to process. Click refresh to
                update inventory count.
              </p>
              <p className='body1-regular'>
                3. Click Next Step when both items have a positive inventory
                count.
              </p>
            </div>
          }
          bodyContent={
            <>
              {account.qualifierId !== '' && (
                <FTPLoginDetailCard account={account} />
              )}
              <div className='mt-4 mb-4'>
                <Button
                  icon='Refresh'
                  isDisabled={loadingAddresses && !locationExists}
                  text='Refresh Inventory'
                  onClick={loadInventory}
                  variant='secondary'
                />
              </div>
              <Table
                columns={tableColumns}
                data={inventories}
                loading={loadingInventory}
                activePage={1}
                selectable={false}
                totalRecords={inventories.length}
              />
            </>
          }
        />
      )}
    </>
  )
}

export default UploadInventory
