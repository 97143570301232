import React from 'react'
import {
  Dropdown as DropdownUI,
  DropdownOptionProps,
} from '@teamfabric/copilot-ui'

type SelectDropdownProps = {
  dataTestid?: string
  disabled?: boolean
  options?: any
  value?: DropdownOptionProps
  placeholder?: string
  label: string
  width?: string
  serverSideFiltering?: any
  onChange: (e: DropdownOptionProps) => void
  required?: boolean
  tooltipInfo?: any
  loading?: boolean
  variant?: 'small' | 'large' | 'tiny'
}

const Dropdown: React.FC<SelectDropdownProps> = ({ ...props }) => {
  return <DropdownUI {...props} />
}

export default Dropdown
