import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import PageHeaderComponent from 'molecules/PageHeader'
import { getTenantType } from 'api/helper'
import { PATHS } from 'routes/pageRoutes'
import { StyledActionButtonWrapper, StyledActionButton } from 'styles/common'

const OrderOverviewHeader: React.FC = () => {
  const navigate = useNavigate()
  const { isRetailer } = getTenantType()

  const retailerActions = [
    {
      name: 'Review Tracking Numbers',
      link: `/${PATHS.OrderTracking}`,
    },
    {
      name: 'Review Invoices',
      link: `/${PATHS.OrderInvoices}`,
    },
    {
      name: 'Review Cancels',
      link: `/${PATHS.OrderCancellations}`,
    },
    {
      name: 'Review Returns',
      link: `/${PATHS.OrderReturns}`,
    },
    {
      name: 'Review Credit Memos',
      link: `/${PATHS.OrderCreditMemos}`,
    },
  ]

  const supplierActions = [
    {
      name: 'Review Invoices',
      link: `/${PATHS.OrderInvoices}`,
    },
    {
      name: 'Review Returns',
      link: `/${PATHS.OrderReturns}`,
    },
    {
      name: 'Review Credit Memos',
      link: `/${PATHS.OrderCreditMemos}`,
    },
    {
      name: 'Review Cancels',
      link: `/${PATHS.OrderCancellations}`,
    },
  ]

  const importActions = [
    {
      name: 'Import Tracking Numbers',
      link: `/${PATHS.OrdersDashboard}`,
    },
    {
      name: 'Import Invoices',
      link: `/${PATHS.OrdersDashboard}`,
    },
  ]

  const children = (actions) => {
    return (
      <StyledActionButtonWrapper>
        {actions.map((action) => (
          <StyledActionButton
            onClick={() => navigate(action.link)}
            data-testid={`${action.name}-dropdown-item`}
            key={action.name}
          >
            {action.name}
          </StyledActionButton>
        ))}
      </StyledActionButtonWrapper>
    )
  }

  const primaryButtons = useMemo(() => {
    let buttons = []
    if (isRetailer) {
      buttons.push({
        text: 'More actions',
        icon: 'ArrowDown',
        iconPosition: 'right',
        variant: 'secondary',
        onClick: () => {},
        popoverProps: {
          children: children(retailerActions),
          placement: 'bottom-start',
        },
      })
      buttons.push({
        onClick: () => navigate('/orders/create'),
        text: 'Create purchase order',
        variant: 'primary',
      })
    } else {
      buttons.push({
        text: 'Review',
        icon: 'ArrowDown',
        variant: 'secondary',
        onClick: () => {},
        popoverProps: {
          children: children(supplierActions),
          placement: 'bottom-start',
        },
      })
      buttons.push({
        text: 'Import',
        icon: 'ArrowDown',
        variant: 'secondary',
        onClick: () => {},
        popoverProps: {
          children: children(importActions),
          placement: 'bottom-start',
        },
      })
    }
    return buttons
  }, [isRetailer])

  return (
    <PageHeaderComponent
      loading={false}
      h1Text='Overview'
      primaryButtons={primaryButtons}
      customClassName='mb-4'
    />
  )
}

export default OrderOverviewHeader
