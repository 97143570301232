import { Badge as BadgeUI } from '@teamfabric/copilot-ui'
import React from 'react'

export type BadgeProps = {
  label: string
  variant?: any
  status: any
  asset?: any
  onClick?: () => void
}

const Badge: React.FC<BadgeProps> = ({ ...props }) => {
  return <BadgeUI {...props} />
}

export default Badge
