import { FC, useEffect, useState } from 'react'
import { capitalize } from 'lodash'
import { DataProps, useToast } from '@teamfabric/copilot-ui'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/suppliersSlice'
import { Badge, Box, InteractiveIcon, Table, ToolTip } from 'atoms'
import { formatDate } from 'lib/utils/date/formatDate'
import { resendConnectionInvite } from 'api/connection'
import { getTableCaption } from 'lib/utils/table'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const SupplierInvitationsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const [tableData, setTableData] = useState<DataProps[]>([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      title: 'ID',
      width: 120,
    },
    {
      title: 'Company',
    },
    {
      title: 'Contact',
    },
    {
      title: 'Created',
    },
    {
      title: 'Status',
    },
    {
      title: '',
      width: 80,
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        company_name,
        first_name,
        last_name,
        email,
        invite_sent_at,
        status,
      }) => {
        return {
          id,
          data: {
            'ID': { value: id },
            'Company': { value: company_name },
            'Contact': {
              onRender: () => (
                <div>
                  <p>
                    {capitalize(first_name)} {capitalize(last_name)}
                  </p>
                  <span className='grey-700 body2-regular'>{email}</span>
                </div>
              ),
            },
            'Created': {
              value: formatDate({ date: invite_sent_at }),
            },
            'Status': {
              onRender: () => (
                <Badge
                  label={status}
                  status={status === 'accepted' ? 'success' : 'default'}
                />
              ),
            },
            '': {
              onRender: () =>
                status === 'pending' ? (
                  <Box flex gap={16}>
                    <ToolTip text='Resend'>
                      <InteractiveIcon
                        onClick={() => onResend(id, company_name)}
                        iconName='Refresh'
                        size={16}
                      />
                    </ToolTip>
                  </Box>
                ) : null,
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const onResend = async (id, companyName) => {
    try {
      await resendConnectionInvite({ connectionId: id })
      showToast({
        label: `Invitation for ${companyName} sent successfully`,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: "Couldn't resend the invite, please try again.",
        variant: 'error',
        id: '1',
      })
    }
  }

  const setPage = (currentPage: number) => {
    dispatch(
      setCurrentPage({
        page: 'invitations',
        currentPage,
      })
    )
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      selectable={false}
      tableCaption={getTableCaption(props.currentPage, props.totalRows)}
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
    />
  )
}

export default SupplierInvitationsTable
