import { theme, TypographyType } from '@teamfabric/copilot-ui'
import { Box, Button, Input } from 'atoms'
import MultiSelect from 'atoms/select/multiSelect'
import React, { useState } from 'react'

type ChildProps = {
  onPost: (memo, taggedUsers) => void
  dataTestId?: string
  placeholderText?: string
  showSecondaryInfo?: boolean
  saving?: boolean
  userOptions?: any[]
}

const PostCommentInput: React.FC<ChildProps> = ({
  onPost,
  dataTestId,
  placeholderText,
  showSecondaryInfo,
  saving,
  userOptions,
}) => {
  const [memoText, setMemoText] = useState(undefined)
  const [showUserTagInput, setShowUserTagInput] = useState(false)
  const [selectedTagUsers, setSelectedTagUsers] = useState([])

  const handlePost = () => {
    onPost(memoText, selectedTagUsers)
    resetFields()
  }

  const resetFields = () => {
    setMemoText('')
    setSelectedTagUsers([])
    setShowUserTagInput(false)
  }

  const isInvalidMemo = (text: string) => /^\s*$/.test(text)

  return (
    <Box dataTestid={dataTestId}>
      <Box flex={{ alignItems: 'center' }} gap={2}>
        <Input
          label=''
          width='100%'
          inputProps={{
            value: memoText,
            onChange: (e) => {
              setMemoText(e.target.value)
            },
            placeholder: placeholderText || 'Add a comment',
            dataTestid: 'add-a-comment',
          }}
          message={memoText && isInvalidMemo(memoText) ? 'Please type in a valid comment' : ''}
          messageType={memoText && isInvalidMemo(memoText) ? 'error' : ''}
        />
        <Button
          text='Post'
          onClick={handlePost}
          isDisabled={isInvalidMemo(memoText) || saving}
          dataTestid='post-note-btn'
        />
      </Box>
      {showUserTagInput && (
        <Box flex={{ alignItems: 'center' }} gap={2} margin={{top: 2}}>
          <MultiSelect
            placeholder='Tag someone in your comment.'
            name='values'
            width='100%'
            onChange={(val) => setSelectedTagUsers(val)}
            onClear={async () => setSelectedTagUsers([])}
            options={userOptions}
            value={selectedTagUsers}
          />
          <div style={{ width: '62px'}} />
        </Box>
      )}
      <div className='mt-1 mb-2 body2-regular v-center'>
        {showSecondaryInfo && (
          <span className='grey-600 mr-1'>Comments can be seen by all parties.</span>
        )}
        {!showUserTagInput && (
          <Box as='a' typography={theme.typography.body2.regular as TypographyType} color={theme.color.blue[500]} onClick={() => setShowUserTagInput(true)} dataTestid='comment-tag-someone'>
            Tag someone in your comment.
          </Box>
        )}
      </div>
    </Box>
  )
}

export default PostCommentInput
