import React, { useState, useEffect, useMemo } from 'react'
import { formatDate } from 'lib/utils/date/formatDate'
import Card4Columns from 'molecules/cards/4Columns'
import { formatProposalType } from '../../utils'
import CardWithHorizontalStepper from 'molecules/cards/cardWithHorizontalStepper'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import ProposalStatus from '../presenters/proposalStatus'

type SummaryDetailsProps = {
  details: any
  loading: boolean
  isRetailer: boolean
  requirePricingApproval: boolean
}

const SummaryDetails: React.FC<SummaryDetailsProps> = ({
  details,
  loading,
  isRetailer,
  requirePricingApproval,
}) => {
  const [proposalInfoData, setProposalInfoData] = useState<any[]>([])
  const [timestampsData, setTimestampsData] = useState<any[]>([])
  const [statusData, setStatusData] = useState<any[]>([])

  useEffect(() => {
    formatData(details)
  }, [loading, details])

  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname.includes('items')) {
      const path = isRetailer ? `/proposals/${id}` : `/products/proposals/${id}`
      navigate(path, { replace: true })
    }
  }, [pathname])

  // useEffect(() => {
  //   if (pathname.includes('proposals/+[A-Za-z0-9]+/products')) {
  //     navigate(`/products/proposals/${id}`, { replace: true })
  //   }
  // }, [pathname])

  const formatData = ({
    sender,
    recipient,
    connection,
    proposal_type,
    department,
    proposed_at,
    approved_at,
    completed_at,
    acknowledged_at,
    status,
    action_required,
    has_price_changes,
    has_revision,
    item_count,
    item_rejected_count,
    item_ready_count,
    item_failed_count,
  }) => {
    let formattedProposalInfoData = [
      {
        label: isRetailer ? 'Supplier Owner' : 'Owner',
        value:
          sender?.user?.first_name && connection
            ? `${sender.user.first_name} ${sender.user.last_name} (${connection.brand.name})`
            : 'Not Specified',
      },
      {
        label: 'Merchant Owner',
        value:
          recipient?.user?.first_name && connection
            ? `${recipient.user.first_name} ${recipient.user.last_name} (${connection.retailer.name})`
            : 'Not Specified',
      },
      {
        label: 'Proposal type',
        value: formatProposalType(proposal_type),
      },
      {
        label: 'Category',
        value: department?.name ? department.name : 'Not Specified',
      },
      {
        label: 'Items',
        value: item_count,
      },
      {
        label: 'Items Rejected',
        value: item_rejected_count,
      },
      {
        label: 'Validations Passed',
        value: item_ready_count,
      },
      {
        label: 'Validations Failing',
        value: item_failed_count,
      },
    ]

    let formattedDatesData = [
      {
        label: 'Proposed',
        value: formatDate({ date: proposed_at }),
      },
      {
        label: 'Assortment approved',
        value: formatDate({ date: approved_at }),
      },
      {
        label: 'Completed',
        value: formatDate({ date: completed_at }),
      },
      {
        label: 'Received',
        value: formatDate({ date: acknowledged_at }),
      },
    ]

    let formattedStatusData = [
      {
        label: 'Status',
        value: ProposalStatus({
          status,
          acknowledgedAt: acknowledged_at,
          requirePricingApproval: requirePricingApproval,
        }),
      },
      {
        label: 'Requires action',
        value: action_required ? 'Yes' : 'No',
      },
      {
        label: 'Has price change',
        value: has_price_changes ? 'Yes' : 'No',
      },
      {
        label: 'Is revision',
        value: has_revision ? 'Yes' : 'No',
      },
    ]

    setProposalInfoData(formattedProposalInfoData)
    setTimestampsData(formattedDatesData)
    setStatusData(formattedStatusData)
  }

  const stepperSteps = useMemo(() => {
    let steps = [
      {
        variant: 'started',
        label: 'Draft',
      },
      {
        variant: 'notStarted',
        label: 'In Review',
      },
      requirePricingApproval
        ? {
            variant: 'notStarted',
            label: 'Pending Approval',
            labelWidth: '112px',
          }
        : null,
      {
        variant: 'notStarted',
        label: 'Ready to Add',
      },
      {
        variant: 'notStarted',
        label: 'Completed',
      },
    ].filter((step) => step !== null)

    const updateVariantByLabel = (label, newVariant) => {
      const index = steps.findIndex((step) => step.label === label)
      if (index !== -1) {
        steps[index].variant = newVariant
      }
    }

    if (details.status === 'completed') {
      if (details.acknowledged_at === null) {
        updateVariantByLabel('Draft', 'complete')
        updateVariantByLabel('In Review', 'complete')
        updateVariantByLabel('Pending Approval', 'complete')
        updateVariantByLabel('Ready to Add', 'started')
      } else {
        updateVariantByLabel('Draft', 'complete')
        updateVariantByLabel('In Review', 'complete')
        updateVariantByLabel('Pending Approval', 'complete')
        updateVariantByLabel('Ready to Add', 'complete')
        updateVariantByLabel('Completed', 'complete')
      }
    }

    switch (details?.status) {
      case 'proposed':
        updateVariantByLabel('Draft', 'complete')
        updateVariantByLabel('In Review', 'started')
        break
      case 'approved':
        updateVariantByLabel('Draft', 'complete')
        updateVariantByLabel('In Review', 'complete')
        if (requirePricingApproval) {
          updateVariantByLabel('Pending Approval', 'started')
        } else {
          updateVariantByLabel('Ready to Add', 'started')
        }
        break
      case 'pricing_approved':
        updateVariantByLabel('Draft', 'complete')
        updateVariantByLabel('In Review', 'complete')
        updateVariantByLabel('Pending Approval', 'complete')
        updateVariantByLabel('Ready to Add', 'started')
        break
      case 'archived':
      case 'declined':
        steps = []
        break
    }

    return steps
  }, [
    isRetailer,
    details?.status,
    details?.acknowledged_at,
    requirePricingApproval,
  ])

  return (
    <div className='mt-5'>
      <CardWithHorizontalStepper
        header='Status'
        loading={loading}
        data={statusData}
        stepperSteps={stepperSteps}
        customClassName='mb-4'
      />
      <Card4Columns
        header='Proposal information'
        loading={loading}
        data={proposalInfoData}
        customClassName='mb-4'
        showCollapse={false}
      />
      <Card4Columns
        header='Dates'
        loading={loading}
        data={timestampsData}
        customClassName='mb-4'
        showCollapse={false}
      />
    </div>
  )
}

export default SummaryDetails
