import React, { useState, useEffect } from 'react'
import Card from 'atoms/card/card'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import CardLoading from 'molecules/loading/cardLoading'
import { PATHS } from 'routes/pageRoutes'

type CompleteOnboardingProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const CompleteOnboarding: React.FC<CompleteOnboardingProps> = ({
  isNextClicked,
  setIsNextClicked,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const showToast = useToast()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  // next is clicked
  useEffect(() => {
    ;(async () => {
      try {
        if (!isNextClicked) {
          return
        }
        setLoading(true)
        setIsNextClicked(false)
        // I think the endpoint needs to update. Feedback needs to remove from assignedTask
        // await updateOnboardingComplete({ connectionId })
        // showToast({
        //   label: 'Onboarding completed!',
        //   isDismissable: true,
        //   id: '1',
        // })
        setLoading(false)
        navigate(PATHS.OrdersDashboard)
      } catch (error) {
        showToast({
          label: "Couldn't save",
          variant: 'error',
          isDismissable: true,
          id: 'could-not-save',
        })
      }
    })()
  }, [isNextClicked])

  // save and exit is clicked
  useEffect(() => {
    ;(async () => {
      try {
        if (!isSaveExitClicked) {
          return
        }
        setLoading(true)
        setIsSaveExitClicked(false)
        // I think the endpoint needs to update. Feedback needs to remove from assignedTask
        // await updateOnboardingComplete({ connectionId })
        // showToast({
        //   label: 'Onboarding completed!',
        //   isDismissable: true,
        //   id: '1',
        // })
        setLoading(false)
        navigate(PATHS.Onboarding)
      } catch (error) {
        showToast({
          label: "Couldn't save",
          variant: 'error',
          isDismissable: true,
          id: '2',
        })
      }
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {loading && <CardLoading />}
      {!loading && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <p className='body1-regular'>
                You&apos;ve completed all initial setup tasks!{' '}
              </p>
              <p className='body1-regular mb-0'>
                Mark onboarding as complete to request account connection to
                (Merchant Name).
              </p>
            </div>
          }
          bodyContent={
            <>
              <h5 className='h5 m-0'>What to expect next:</h5>
              <ul className='m-0 padding-inline-start-20'>
                <li className='body1-regular'>
                  Your account will remain as “awaiting connection” until
                  (Merchant Name) confirms your items and your order processing
                  setup
                </li>
                <li className='body1-regular'>
                  You will not receive any orders until your account is
                  connected
                </li>
                <li className='body1-regular'>
                  We encourage you to review your account settings and
                  notification preferences
                </li>
              </ul>
            </>
          }
        />
      )}
    </>
  )
}

export default CompleteOnboarding
