import { theme } from '@teamfabric/copilot-ui'
import styled from 'styled-components'

export const StyledCheckboxFooter = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 1px;
  border-radius: 0px 0px ${theme.space[1]} ${theme.space[1]};
`
