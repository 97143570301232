import React, { useEffect, useState } from 'react'
import { formatDate } from 'lib/utils/date/formatDate'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import MerchantStatus from 'molecules/shipment/merchantStatus'
import CardWithTable from 'molecules/cards/cardWithTable'

type InvoicesProps = {
  loading: boolean
  details: any
}

const Invoices: React.FC<InvoicesProps> = ({ loading, details }) => {
  const [invoiceDetails, setInvoiceDetails] = useState<any[]>([])

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const tableColumns = [
    {
      sortable: false,
      title: 'ID',
    },
    {
      sortable: false,
      title: 'Invoice Number',
    },
    {
      sortable: false,
      title: 'Registered',
    },
    {
      sortable: false,
      title: 'Merchant Status',
    },
    {
      sortable: false,
      title: 'Total',
    },
  ]

  const formatData = ({ invoices }) => {
    const formattedData = invoices.map(
      ({
        id,
        invoice_number,
        invoiced_at,
        status,
        due_amount,
        acknowledged_at,
      }) => {
        return {
          id,
          data: {
            'ID': { value: id },
            'Invoice Number': {
              value: invoice_number,
            },
            'Registered': {
              value: formatDate({ date: invoiced_at }),
            },
            'Merchant Status': {
              onRender: () => (
                <MerchantStatus
                  status={status}
                  acknowledged_at={acknowledged_at}
                />
              ),
            },
            'Total': {
              value: formatCurrency(parseFloat(due_amount)),
            },
          },
        }
      }
    )
    setInvoiceDetails(formattedData)
  }

  return (
    <>
      <CardWithTable
        header='Invoices'
        loading={loading}
        tableProps={{
          emptyTableText: 'No invoice have been registered yet',
          columns: tableColumns,
          data: invoiceDetails,
          selectable: true,
        }}
        customClassName='mb-4'
      />
    </>
  )
}

export default Invoices
