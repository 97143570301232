import React, { useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { BUTTONS, VARIANTS } from 'lib/constants'
import { markInvoicePaid } from 'api/invoices'
import MarkInvoicesPaid from './markInvoicesPaid'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setParams } from 'store/invoicesSlice'

type MarkInvoicesPaidModalProps = {
  invoice: any
  isBulkAction?: boolean
  showMarkInvoicePaidModal: boolean
  setShowMarkInvoicePaidModal: any
}

const MarkInvoicesPaidModal: React.FC<MarkInvoicesPaidModalProps> = ({
  invoice,
  isBulkAction,
  showMarkInvoicePaidModal,
  setShowMarkInvoicePaidModal,
}) => {
  const showToast = useToast()
  const dispatch = useDispatch()

  const params = useSelector((state: RootState) => state.invoices.params)

  const [saving, setSaving] = useState(false)

  const isActionable = useMemo(() => {
    return !invoice?.paid_at
  }, [invoice])

  const markPaid = async () => {
    setSaving(true)
    try {
      await markInvoicePaid({ id: invoice?.id })
      showToast({
        label: 'Invoice has been successfully marked as paid',
        isDismissable: true,
        id: '1',
      })
      // replace invoice params with a copy of itself to trigger a fetch and rerender of the list
      dispatch(setParams({ ...params }))
    } catch (error) {
      const message = error?.response?.data ? error.response.data : error
      showToast({
        label: message,
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
    setShowMarkInvoicePaidModal(false)
    setSaving(false)
  }

  return (
    <Modal
      headerText={`Mark Invoice as Paid`}
      description=''
      size='small'
      onClose={() => setShowMarkInvoicePaidModal(false)}
      isVisible={showMarkInvoicePaidModal}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => setShowMarkInvoicePaidModal(false),
          text: BUTTONS.CLOSE.LABEL,
          variant: VARIANTS.SECONDARY,
        },
        {
          dataTestid: '',
          onClick: markPaid,
          isLoading: saving,
          isDisabled: !isActionable,
          text: 'Mark Invoice as Paid',
          variant: VARIANTS.PRIMARY,
        },
      ]}
      dataTestid={'accept-invoice-modal'}
      onBackdropClick={() => setShowMarkInvoicePaidModal(false)}
    >
      <MarkInvoicesPaid invoice={invoice} />
    </Modal>
  )
}

export default MarkInvoicesPaidModal
