import { configureStore } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
})

export type AppStore = typeof store
// Export the type of the root state
export type RootState = ReturnType<AppStore['getState']>

// Export the type of the dispatch function
export type AppDispatch = AppStore['dispatch']
export const persistor = persistStore(store)
