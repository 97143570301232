import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import SearchBar from 'molecules/search/searchBarTable'
import MerchantTable from '../components/table'
import { getMerchants } from 'api/connection'
import MerchantFilter from '../components/filters'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import PageHeader from 'atoms/pageHeader'
import { setSearch } from 'store/merchantSlice'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import { PAGE_TYPE } from 'lib/constants'

const MerchantsListTemplate: FC = () => {
  const params = useSelector((state: RootState) => state.merchant.params)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)

  const [totalRows, setTotalRows] = useState(0)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const filteredParams = {}
        // skipping empty keys
        for (const key in params) {
          if (params[key] !== '') {
            filteredParams[key] = params[key]
          }
        }
        const { data: data } = await getMerchants({
          params: { ...filteredParams },
        })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.CONNECTION_LISTING)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeader h1Text='Merchants' />
          <SearchBar
            placeholder='Search for a merchant by name'
            setSearch={setSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <MerchantFilter params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.MERCHANTS}
          />
          <MerchantTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            currentSortBy={params.order_by}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default MerchantsListTemplate
