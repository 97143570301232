import { Link } from 'atoms'

type PurchaseOrderNumColProps = {
  onClick: () => void
  lines: any
}

const PurchaseOrderNumCol: React.FC<PurchaseOrderNumColProps> = ({
  lines,
  onClick,
}) => {
  const numOrdered = () => {
    let items = 0
    lines.forEach((line) => {
      items += line.quantity - line.quantity_canceled
    })
    return items
  }

  const numShipped = () => {
    let items = 0
    lines.forEach((line) => {
      items += line.quantity_shipped
    })
    return items
  }

  return (
    <Link
      label={`${numShipped()} of ${numOrdered()} ${
        numOrdered() === 1 ? 'item' : 'items'
      }`}
      variant='primary'
      theme='light'
      mode='inline'
      onClick={() => onClick()}
    />
  )
}

export default PurchaseOrderNumCol
