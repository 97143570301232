import { Box } from 'atoms'
import { FC } from 'react'

const EmptyPageTemplate: FC = () => {
  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6 }}>
      {' '}
      Page coming soon...
    </Box>
  )
}

export default EmptyPageTemplate
