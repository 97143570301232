import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setIntegration } from 'store/onboardingSlice'
import CardLoading from 'molecules/loading/cardLoading'
import Input from 'atoms/input/input'
import { postProduct } from 'api/products'
import { patchAssignTask } from 'api/onboarding'
import { useToast } from '@teamfabric/copilot-ui'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'routes/pageRoutes'

type CreateSampleItemsProps = {
  isNextClicked: boolean
  setIsNextButtonDisabled: (value: boolean) => void
  setIsNextClicked: (value: boolean) => void
  reseting: boolean
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const CreateSampleItems: React.FC<CreateSampleItemsProps> = ({
  isNextClicked,
  setIsNextButtonDisabled,
  setIsNextClicked,
  reseting,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const ediTasks = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )

  const [loading, setLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const [itemSKU1, setItemSKU1] = useState('')
  const [itemName1, setItemName1] = useState('')
  const [itemSKU2, setItemSKU2] = useState('')
  const [itemName2, setItemName2] = useState('')

  useEffect(() => {
    if (
      integration.selectedIntegrationInfo &&
      integration.selectedIntegrationInfo.variants.length > 0
    ) {
      setIsDone(true)
      setItemSKU1(integration.selectedIntegrationInfo.variants[0].sku)
      setItemName1(integration.selectedIntegrationInfo.variants[0].name)
      setItemSKU2(integration.selectedIntegrationInfo.variants[1].sku)
      setItemName2(integration.selectedIntegrationInfo.variants[1].name)
    }
  }, [])

  useEffect(() => {
    setIsNextButtonDisabled(
      !(
        itemSKU1 !== '' &&
        itemName1 !== '' &&
        itemSKU2 !== '' &&
        itemName2 !== ''
      )
    )
  }, [itemSKU1, itemName1, itemSKU2, itemName2])

  const updateSlices = ({ info }) => {
    dispatch(
      setIntegration({
        taskId: integration.taskId,
        processNum: 3,
        introDone: true,
        completed: false,
        selectedIntegrationInfo: info,
        selectedMethod: integration.selectedMethod,
      })
    )
  }

  const createItems = async () => {
    try {
      if (isDone) {
        return
      }
      setLoading(true)
      const body = [
        {
          identifier: itemSKU1,
          name: itemName1,
        },
        {
          identifier: itemSKU2,
          name: itemName2,
        },
      ]
      const { data } = await postProduct({ body })
      const assignBody = {
        info: {
          ...integration.selectedIntegrationInfo,
          createSampleItems: true,
          variants: data.map(({ id, name, brand_identifier }) => ({
            id,
            name,
            sku: brand_identifier,
          })),
        },
      }
      await patchAssignTask({
        taskId:
          integration.selectedIntegrationInfo.taskIds[
            integration.selectedMethod
          ],
        body: assignBody,
      })
      updateSlices(assignBody)
    } catch (error) {
      showToast({
        label: "Couldn't create sample items",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      dispatch(
        setCurrentStep({
          value:
            ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVENTORY.TASK_NAME,
          parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
        })
      )
      await createItems()
    })()
  }, [isNextClicked])

  // onboardingProgress save and exit button clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      await createItems()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {(loading || reseting) && (
        <CardLoading label={ediTasks.CREATE_SAMPLE_ITEMS.NAME} num={3} />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={ediTasks.CREATE_SAMPLE_ITEMS.NAME}
              h2Text='Create sample items to test your EDI integration setup.'
            />
          }
          bodyContent={
            <>
              <h2 className='kicker'>item 1</h2>
              <div className='is-flex gap-3'>
                <Input
                  width='100%'
                  label='Item SKU'
                  required={true}
                  inputProps={{
                    value: itemSKU1,
                    placeholder: 'ABCABC',
                    disabled: isDone,
                    onChange: ({ target: { value } }) => {
                      setItemSKU1(value)
                    },
                  }}
                />
                <Input
                  width='100%'
                  label='Item Name'
                  required={true}
                  inputProps={{
                    value: itemName1,
                    placeholder: 'ItemXYZ',
                    disabled: isDone,
                    onChange: ({ target: { value } }) => {
                      setItemName1(value)
                    },
                  }}
                />
              </div>
              <div className='border-line mt-5 mb-2'></div>
              <h2 className='kicker'>item 2</h2>
              <div className='is-flex gap-3'>
                <Input
                  width='100%'
                  label='Item SKU'
                  required={true}
                  inputProps={{
                    value: itemSKU2,
                    placeholder: 'ABCABC',
                    disabled: isDone,
                    onChange: ({ target: { value } }) => {
                      setItemSKU2(value)
                    },
                  }}
                />
                <Input
                  width='100%'
                  label='Item Name'
                  required={true}
                  inputProps={{
                    value: itemName2,
                    placeholder: 'ItemXYZ',
                    disabled: isDone,
                    onChange: ({ target: { value } }) => {
                      setItemName2(value)
                    },
                  }}
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}

export default CreateSampleItems
