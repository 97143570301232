import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { DropDownInitialObj, dropDownInitialObj } from 'lib/utils/initialValue'
import { getShopifyLocations } from 'api/shopify'
import { useParams } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  handleUpdate?: (data: any) => void
  SelectLocationId?: number
}

const SelectLocations: React.FC<ChildProps> = ({
  handleUpdate,
  SelectLocationId,
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState([])
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)

  const fetchLocations = async () => {
    try {
      const { data } = await getShopifyLocations({ platformAccountId: id, params: {} })
      setLocations(data?.results)
      const formattedOptions: DropDownInitialObj[] = data?.results.map((el) => {
        return {
          id: el.id,
          label: el.name,
        }
      })
      setOptions(formattedOptions)
    } catch ({ response }) {
      showToast({
        label: 'An error occurred while fetching location, please check account configuration.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  const eligibleLocations = useMemo(() => {
    if (!loading && options.length === 0) return []
    const eligible = []
    options.forEach(location => {
      // discard inactive locations
      if (location.active === false) return
      // discard incomplete locations
      if (location.address1 === null) return
      if (location.city === null) return
      // not all shopify locations support province_code
      // if (location.province_code === null) return
      if (location.zip === null) return
      if (location.country_code === null) return
      eligible.push(location)
    })
    return eligible
  }, [!loading, options])

  useEffect(() => {
    ; (async () => {
      await fetchLocations()
    })()
  }, [])

  return (
    <Dropdown
      dataTestid='location-dropdown'
      required
      label={'Select Shopify Location'}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={eligibleLocations}
      placeholder='Select Location'
      disabled={loading}
    />
  )
}

export default SelectLocations
