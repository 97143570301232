import { Box, ToolTip } from 'atoms'
import Tag from 'atoms/tag/tag'
import React, { useState } from 'react'

type OrderTagDataProps = {
  created_at: string
  created_by: any
  id: string
  tag: string
  updated_at: string
}

type TagColProps = {
  tags: OrderTagDataProps[]
}

const TagCol: React.FC<TagColProps> = ({ tags }) => {
  const [tooltipHover, setTooltipHover] = useState(false)
  return (
    <>
      {tags.length === 0 && <p>--</p>}
      <ToolTip
        isVisible={tags.length > 0 && tooltipHover}
        text={tags.map(({ tag }) => tag).join(', ')}
        placement='topStart'
        showArrow
      >
        <Box
          flex
          onMouseEnter={() => setTooltipHover(true)}
          onMouseLeave={() => setTooltipHover(false)}
        >
          {tags.length > 0 && (
            <Tag id={tags[0].id} primaryLabel={tags[0].tag} />
          )}
          {tags.length > 1 && (
            <div className='ml-1'>
              <Tag id={tags[1].id} primaryLabel={`+ ${tags.length - 1}`} />
            </div>
          )}
        </Box>
      </ToolTip>
    </>
  )
}

export default TagCol
