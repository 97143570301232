import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, Card, Input, Dropdown } from 'atoms'
import { getUsers } from 'api/user'
import { getContextDetails } from 'api/context'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import { useToast } from '@teamfabric/copilot-ui'
import CardLoading from 'molecules/loading/cardLoading'

type KeyDetailsProps = {
  setFormData: (data: any) => void
  setInitialValues: (data: any) => void
}

const KeyDetailsCard: React.FC<KeyDetailsProps> = ({
  setFormData,
  setInitialValues,
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [website, setWebsite] = useState('')
  const [users, setUsers] = useState([])
  const [selectedConnectionManager, setSelectedConnectionManager] =
    useState(dropDownInitialObj)
  const [selectedMerchandiseUser, setSelectedMerchandiseUser] =
    useState(dropDownInitialObj)
  const [selectedTransactionUser, setSelectedTransactionUser] =
    useState(dropDownInitialObj)
  const [originalContactData, setOriginalContactData] = useState({
    connections_manager_id: '',
    merchandising_manager_id: '',
    transaction_manager_id: '',
    name: '',
    website: '',
  })

  const loadUsers = async () => {
    const {
      data: { results },
    } = await getUsers({
      params: {
        mini: 1,
        pagination: 0,
        order_by: 'first_name',
      },
    })
    const formattedUser = results.map(({ id, first_name, last_name }) => ({
      id,
      label: `${first_name} ${last_name}`,
    }))
    setUsers(formattedUser)
  }

  const loadContextDetails = async () => {
    const { data } = await getContextDetails()
    setName(data.name)
    setWebsite(data.website)
    setOriginalContactData({
      connections_manager_id: data.connections_manager.id,
      merchandising_manager_id: data.merchandising_manager.id,
      transaction_manager_id: data.transaction_manager.id,
      name: data.name,
      website: data.website,
    })
    if (data.connections_manager) {
      setSelectedConnectionManager({
        id: data.connections_manager.id,
        label: `${data.connections_manager.first_name} ${data.connections_manager.last_name}`,
      })
    }
    if (data.merchandising_manager) {
      setSelectedMerchandiseUser({
        id: data.merchandising_manager.id,
        label: `${data.merchandising_manager.first_name} ${data.merchandising_manager.last_name}`,
      })
    }
    if (data.transaction_manager) {
      setSelectedTransactionUser({
        id: data.transaction_manager.id,
        label: `${data.transaction_manager.first_name} ${data.transaction_manager.last_name}`,
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await Promise.all([loadUsers(), loadContextDetails()])
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    const data = {
      name: name,
      website: website,
      connections_manager_id: selectedConnectionManager.id,
      merchandising_manager_id: selectedMerchandiseUser.id,
      transaction_manager_id: selectedTransactionUser.id,
    }
    if (!loading) {
      setFormData((prevFormData) => ({ ...prevFormData, ...data }))
    }
  }, [
    !loading,
    selectedTransactionUser,
    selectedConnectionManager,
    selectedMerchandiseUser,
    name,
    website,
  ])

  useEffect(() => {
    if (!loading && originalContactData) {
      setInitialValues(originalContactData)
    }
  }, [!loading, originalContactData])

  return loading ? (
    <CardLoading num={5} width={'50%'} />
  ) : (
    <Card
      headerContent='Key Details'
      showCollapse={false}
      bodyContent={
        <div className='p-0'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Full Name'
                required={true}
                inputProps={{
                  value: name,
                  onChange: ({ target: { value } }) => setName(value),
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Website URL'
                required={true}
                inputProps={{
                  value: website,
                  onChange: ({ target: { value } }) => setWebsite(value),
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <div className='line-separator'></div>
          <p className='h5 pt-4 pb-2'>Connections Manager</p>
          <p className='body1-regular pb-4'>
            The Connections Manager is the key contact for all vendor business
            inquiries.
          </p>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Dropdown
                width='100%'
                label='Select connections manager'
                required={true}
                value={selectedConnectionManager}
                onChange={(option) => setSelectedConnectionManager(option)}
                options={users}
              />
            </GridCol>
          </GridRow>
          <br />
          <div className='line-separator'></div>
          <p className='h5 pt-4 pb-2'>Merchandising Manager</p>
          <p className='body1-regular pb-4'>
            The Merchandising Manager is the key contact for all product &
            inventory inquiries.
          </p>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Dropdown
                width='100%'
                label='Select merchandising manager'
                required={true}
                value={selectedMerchandiseUser}
                onChange={(option) => setSelectedMerchandiseUser(option)}
                options={users}
              />
            </GridCol>
          </GridRow>
          <br />
          <div className='line-separator'></div>
          <p className='h5 pt-4 pb-2'>Transactions Manager </p>
          <p className='body1-regular pb-4'>
            The Transactions Manager is the key contact for all order &
            fulfillment inquiries.
          </p>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Dropdown
                width='100%'
                label='Select transactions manager'
                required={true}
                value={selectedTransactionUser}
                onChange={(option) => setSelectedTransactionUser(option)}
                options={users}
              />
            </GridCol>
          </GridRow>
        </div>
      }
    />
  )
}

export default KeyDetailsCard
