import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import createGenericSlice from './genericSlice'
import { createAction } from '@reduxjs/toolkit'
import { PROPOSALS } from 'modules/constants/proposals'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    short: 1,
  },
  proposedProductsParams: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  proposedProductVariantParams: {
    order_by: '-id',
    limit: 10,
    page: 1,
    short: 1,
    ruleset_variant_summary: 1,
    added_to_proposal: 1,
    is_rejected: 0
  },
  connectionOptions: [],
  statusOptions: PROPOSALS.STATUS.CHECKBOX_OPTIONS,
  proposalTypeOptions: PROPOSALS.TYPE.CHECKBOX_OPTIONS,
  proposedOn: null,
  createdOn: null,
  searchKey: 'search'
}

const extraReducers = {
  setStatusOptions(state, action) {
    if (action.payload.type === 'reset') {
      state.statusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS
    } else {
      state.statusOptions = action.payload
    }
  },
  setConnectionOptions(state, action) {
    if (action.payload.type === 'reset') {
      delete state.params['connection_id']
      state.connectionOptions = []
    } else {
      state.connectionOptions = action.payload
    }
  },
  setProposalTypeOptions(state, action) {
    state.params.page = 1
    if (action.payload.type === 'reset') {
      state.proposalTypeOptions = PROPOSALS.TYPE.CHECKBOX_OPTIONS
    } else {
      state.proposalTypeOptions = action.payload
    }
  },
  setProposedOn(state, action) {
    state.params.page = 1
    if (action.payload.type === 'reset') {
      delete state.params['proposed_at_gte']
      delete state.params['proposed_at_lte']
      delete state.proposedOn
    } else {
      const start = getUnixTimestamp(action.payload?.dateRange?.from)
      const end = getUnixTimestamp(action.payload?.dateRange?.to)
      state.params['proposed_at_gte'] = start
      state.params['proposed_at_lte'] = end
      state.proposedOn = action.payload
    }
  },
  setCreatedOn(state, action) {
    state.params.page = 1
    if (action.payload.type === 'reset') {
      delete state.params['created_at_gte']
      delete state.params['created_at_lte']
      delete state.createdOn
    } else {
      const start = getUnixTimestamp(action.payload?.dateRange?.from)
      const end = getUnixTimestamp(action.payload?.dateRange?.to)
      state.params['created_at_gte'] = start
      state.params['created_at_lte'] = end
      state.createdOn = action.payload
    }
  },
  setCardPreFilters(state, action) {
    state.params.page = 1
    delete state.params['is_acknowledged']
    const { preFilterKey } = action.payload
    let status = ''
    let acceptedStatuses = []
    if (preFilterKey === 'proposed,approved,pricing_approved') {
      acceptedStatuses = [
        'proposed',
        'approved',
        'pricing_approved',
      ]
    } else if(preFilterKey === 'revised') {
      acceptedStatuses = [
        'revised'
      ]
    } else if(preFilterKey === 'publishing') {
      acceptedStatuses = [
        'completed'
      ]
      state.params['is_acknowledged'] = 0
    } else if(preFilterKey === 'completed') {
      acceptedStatuses = [
        'completed'
      ]
      state.params['is_acknowledged'] = 1
    }
    status = acceptedStatuses.join(',')
    const updatedStatusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS.map(
      (option) => {
          if (acceptedStatuses.includes(option.value)) {
            return {
              ...option,
              selected: true,
            }
          }
        return option
      }
    )
    state.statusOptions = updatedStatusOptions
    if (status.length > 0) {
      state.params[PROPOSALS.STATUS.PARAM] = status
    } else {
      delete state.params[PROPOSALS.STATUS.PARAM]
    }
  },
  resetAllFilters(state) {
    // state.params = initialState.params
    state.params.page = 1
    state.connectionOptions = []
    state.statusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS
    state.proposalTypeOptions = PROPOSALS.TYPE.CHECKBOX_OPTIONS
    delete state.params['connection_id']
    delete state.params['status']
    delete state.params['proposal_type']
    delete state.params['proposed_at_gte']
    delete state.params['proposed_at_lte']
    delete state.params['is_acknowledged']
    delete state.params['created_at_gte']
    delete state.params['created_at_lte']
    delete state.proposedOn
    delete state.createdOn
  },
}

const singleScreenProposalSlice = createGenericSlice('singleScreenProposal', initialState, extraReducers)

// Manually create action creators for extra reducers
export const setStatusOptions = createAction('singleScreenProposal/setStatusOptions')
export const setConnectionOptions = createAction('singleScreenProposal/setConnectionOptions')
export const setProposalTypeOptions = createAction('singleScreenProposal/setProposalTypeOptions')
export const setProposedOn = createAction('singleScreenProposal/setProposedOn')
export const setCreatedOn = createAction('singleScreenProposal/setCreatedOn')
export const setCardPreFilters = createAction<{ preFilterKey: string }>('singleScreenProposal/setCardPreFilters')
export const resetAllFilters = createAction('singleScreenProposal/resetAllFilters')

export const { setSearch, setFilters, setCurrentPage } = singleScreenProposalSlice.actions

export default singleScreenProposalSlice.reducer
