//importing directly from the lib as the ToolTip doesn't render otherwise
import { InteractiveIcon } from '@teamfabric/copilot-ui'
import { Box, ToolTip } from 'atoms'
import { FC, useMemo } from 'react'

interface InvoicesActionsProps {
  isRetailer: boolean
  invoice: any
  setShowAcceptInvoiceModal: any
  setShowMarkInvoicePaidModal: any
  setInvoice: any
}

const InvoicesActionsMenu: FC<InvoicesActionsProps> = ({
  isRetailer,
  invoice,
  setShowAcceptInvoiceModal,
  setShowMarkInvoicePaidModal,
  setInvoice,
}) => {
  const options = useMemo(() => {
    let options = [
      {
        onClick: () => {
          setShowAcceptInvoiceModal(true)
          setInvoice(invoice)
        },
        icon: 'MerchantExperience',
        tooltipText: 'Accept Invoice',
        disabledTooltipText: 'Invoice has already been accepted',
        isDisabled: invoice?.acknowledged_at,
      },
      {
        onClick: () => {
          setShowMarkInvoicePaidModal(true)
          setInvoice(invoice)
        },
        icon: 'Check',
        tooltipText: 'Mark As Paid',
        disabledTooltipText: 'Invoice has already been marked as paid',
        isDisabled: invoice?.paid_at,
      },
    ]

    if (!isRetailer) {
      options = [
        {
          onClick: () => {
            // TODO: finish for supplier - setEditShowEditInvoiceNumberModal
            setInvoice(invoice)
          },
          icon: 'Pencil',
          tooltipText: 'Edit Invoice Number',
          disabledTooltipText: '',
          isDisabled: false,
        },
      ]
    }
    return options
  }, [isRetailer, invoice])

  return (
    <Box flex gap={16}>
      {options.map((option) => (
        <ToolTip
          text={
            option.isDisabled ? option.disabledTooltipText : option.tooltipText
          }
        >
          <InteractiveIcon
            onClick={() => option.onClick()}
            iconName={option.icon}
            size={16}
            disabled={option.isDisabled}
          />
        </ToolTip>
      ))}
    </Box>
  )
}

export default InvoicesActionsMenu
