import React from 'react'
import { Skeleton as SkeletonUI } from '@teamfabric/copilot-ui'

type SkeletonProps = {
  height: string
  width: string
}

const Skeleton: React.FC<SkeletonProps> = ({ ...props }) => {
  return <SkeletonUI {...props} />
}

export default Skeleton
