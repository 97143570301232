import { createSlice } from '@reduxjs/toolkit'
import { getTenantType } from 'api/helper'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { PRODUCTS } from 'modules/constants/products'
import { PROPOSALS } from 'modules/constants/proposals'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    short: 1,
  },
  connectionOptions: [],
  statusOptions: PROPOSALS.STATUS.CHECKBOX_OPTIONS,
  proposalTypeOptions: PROPOSALS.TYPE.CHECKBOX_OPTIONS,
  proposedOn: null,
  createdOn: null,
  selected: [],
  selectedTabPresetIndex: 0,
  proposalDetails: null,
}
const proposalsSlice = createSlice({
  name: 'proposals',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
        delete state.params.limit
        delete state.params.page
      } else {
        delete state.params['search']
        state.params.page = 1
        state.params.limit = 10
        state.params.order_by = initialState.params.order_by
      }
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.CONNECTIONS.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.statusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS
      } else {
        state.statusOptions = action.payload
      }
    },
    setProposalTypeOptions(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        state.proposalTypeOptions = PROPOSALS.TYPE.CHECKBOX_OPTIONS
      } else {
        state.proposalTypeOptions = action.payload
      }
    },
    setProposedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.PROPOSED_ON.PARAM_START]
        delete state.params[PRODUCTS.PROPOSED_ON.PARAM_END]
        delete state.proposedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.PROPOSED_ON.PARAM_START] = start
        state.params[PRODUCTS.PROPOSED_ON.PARAM_END] = end
        state.proposedOn = action.payload
      }
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.CREATED_ON.PARAM_START]
        delete state.params[PRODUCTS.CREATED_ON.PARAM_END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.CREATED_ON.PARAM_START] = start
        state.params[PRODUCTS.CREATED_ON.PARAM_END] = end
        state.createdOn = action.payload
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    setProposalDetails(state, action) {
      state.proposalDetails = action.payload
    },
    resetAllFilters(state, action) {
      state.proposalDetails = null
      state.connectionOptions = []
      state.statusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS
      state.proposalTypeOptions = PROPOSALS.TYPE.CHECKBOX_OPTIONS
      state.selectedTabPresetIndex = 0
      delete state.params['search']
      delete state.params[PRODUCTS.CONNECTIONS.PARAM]
      delete state.params[PROPOSALS.STATUS.PARAM]
      delete state.params[PROPOSALS.TYPE.PARAM]
      delete state.params[PRODUCTS.PROPOSED_ON.PARAM_START]
      delete state.params[PRODUCTS.PROPOSED_ON.PARAM_END]
      delete state.params[PRODUCTS.ACKNOWLEDGED.PARAM]
      delete state.params[PRODUCTS.CREATED_ON.PARAM_START]
      delete state.params[PRODUCTS.CREATED_ON.PARAM_END]
      delete state.proposedOn
      delete state.createdOn

      const { isRetailer } = getTenantType()

      const tabPresets = action.payload?.tabPresets
      if (tabPresets) {
        state.params.page = 1

        let status = ''
        if (tabPresets === PRODUCTS.PROPOSAL_TAB_PRESETS.DRAFT) {
          status = 'draft'
          state.selectedTabPresetIndex = 1
        } else if (tabPresets === PRODUCTS.PROPOSAL_TAB_PRESETS.PROPOSED) {
          status = 'proposed, approved, pricing_approved'
          state.selectedTabPresetIndex = isRetailer ? 1 : 2
        } else if (tabPresets === PRODUCTS.PROPOSAL_TAB_PRESETS.RETURNED) {
          status = 'revised'
          state.selectedTabPresetIndex = isRetailer ? 2 : 3
        } else if (tabPresets === PRODUCTS.PROPOSAL_TAB_PRESETS.PUBLISHING) {
          status = 'completed'
          state.params[PRODUCTS.ACKNOWLEDGED.PARAM] = '0'
          state.selectedTabPresetIndex = isRetailer ? 3 : 4
        } else if (tabPresets === PRODUCTS.PROPOSAL_TAB_PRESETS.COMPLETED) {
          status = 'completed'
          state.params[PRODUCTS.ACKNOWLEDGED.PARAM] = '1'
          state.selectedTabPresetIndex = isRetailer ? 4 : 5
        }

        const updatedStatusOptions = PROPOSALS.STATUS.CHECKBOX_OPTIONS.map(
          (option) => {
            if (option.value === status) {
              return {
                ...option,
                selected: true,
              }
            } else if (status === 'proposed, approved, pricing_approved') {
              const acceptedStatuses = [
                'proposed',
                'approved',
                'pricing_approved',
              ]
              if (acceptedStatuses.includes(option.value)) {
                return {
                  ...option,
                  selected: true,
                }
              }
            }
            return option
          }
        )
        state.statusOptions = updatedStatusOptions
        state.params[PROPOSALS.STATUS.PARAM] = status
      }
    },
    setTabPresetIndex(state, action) {
      state.selectedTabPresetIndex = action.payload.value
    },
  },
})

export const {
  setSorting,
  setSearch,
  setFilters,
  setConnectionOptions,
  setStatusOptions,
  setProposalTypeOptions,
  setProposedOn,
  setCreatedOn,
  setCurrentPage,
  setSelected,
  resetAllFilters,
  setTabPresetIndex,
  setProposalDetails,
} = proposalsSlice.actions

export default proposalsSlice.reducer
