import React from 'react'
import Icon from 'atoms/icon/icon'
import Box from 'atoms/box/box'
import CardLoading from 'molecules/loading/cardLoading'
import { theme } from '@teamfabric/copilot-ui'

type WithIconButtonProps = {
  title: any
  description: any
  endElement: any
  iconName: string
  iconColor?: string
  disabled?: boolean
  loading?: boolean
}

const WithIconButton: React.FC<WithIconButtonProps> = ({
  title,
  description,
  endElement,
  iconName,
  iconColor,
  disabled = false,
  loading,
}) => {
  return (
    <>
      {loading && <CardLoading num={1} showDivider={false} />}
      {!loading && (
        <Box
          border={{
            color: theme.color.grey[200],
            radius: 2,
            width: '2px',
          }}
          width='100%'
        >
          <div className='is-flex p-5'>
            <Icon
              color={disabled ? theme.color.grey[200] : iconColor}
              iconName={iconName}
              size={17}
            />
            <div className='pl-5'>
              {title.onRender ? title.onRender() : { title }}
              {description.onRender ? description.onRender() : { description }}
            </div>
            <div className='right'>{endElement}</div>
          </div>
        </Box>
      )}
    </>
  )
}

export default WithIconButton
