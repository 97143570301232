import { useEffect, useMemo, useState } from 'react'
import { Box, HorizontalTab } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import TransactionNotifications from '../components/notifications/transactionNotifications'
import { getPushNotification } from 'api/pushNotification'
import { getPlatformAccounts } from 'api/platformAccount'
import OnboardingNotifications from '../components/notifications/onboardingNotifications'
import DigestNotifications from '../components/notifications/digestNotifications'
import ShopifyNotifications from '../components/notifications/shopifyNotifications'

const Notifications: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()

  const [label, setLabel] = useState(0)
  const [pushNotifications, setPushNotifications] = useState([])
  const [platformAccounts, setPlatformAccounts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Notifications' })
        ),
      0
    )
  }, [])

  const fetchPushNotifications = async () => {
    const { data } = await getPushNotification({
      params: { has_recipient_email: 1 },
    })
    setPushNotifications(data.results)
  }

  const fetchPlatformAccounts = async () => {
    const { data } = await getPlatformAccounts({})
    setPlatformAccounts(data.results)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await Promise.all([fetchPushNotifications(), fetchPlatformAccounts()])
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const hasShopify = useMemo(() => {
    return platformAccounts.some(
      (account) => account.platform.code === 'shopify'
    )
  }, [platformAccounts])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Notification Settings'
        h2Text='Manage general information about your business.'
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings'),
            text: 'Back',
            variant: 'secondary',
          },
        ]}
      />
      <HorizontalTab
        onTabChange={setLabel}
        data={{
          tabs: [
            {
              label: 'Onboarding',
              content: (
                <OnboardingNotifications
                  pushNotifications={pushNotifications}
                  fetchNotifications={fetchPushNotifications}
                />
              ),
            },
            {
              label: 'Transactions',
              content: (
                <TransactionNotifications
                  pushNotifications={pushNotifications}
                  fetchNotifications={fetchPushNotifications}
                />
              ),
            },
            {
              label: 'Digest',
              content: (
                <DigestNotifications pushNotifications={pushNotifications} />
              ),
            },
            hasShopify && {
              label: 'Shopify',
              content: (
                <ShopifyNotifications
                  pushNotifications={pushNotifications}
                  fetchNotifications={fetchPushNotifications}
                />
              ),
            },
          ],
        }}
      />
    </Box>
  )
}

export default Notifications
