import { Icon as IconUI } from '@teamfabric/copilot-ui'
import React from 'react'

type IconProps = {
  iconName: string
  color?: string
  size: number
  variant?: any
}

const Icon: React.FC<IconProps> = ({ ...props }) => {
  return <IconUI {...props} />
}

export default Icon
