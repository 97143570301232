import React, { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { getCancelDetail } from 'api/cancels'
import Table from 'atoms/table/table'
import ReturnItemCol from './parts/ReturnItemCol'
import MessageCol from './parts/MessageCol'
import OptionCol from './parts/OptionsCol'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import { CardProps } from '../../../../../lib/utils/props/cardProps'
import Badge from 'atoms/badge/badge'
import { getBadgeStatusByOrderReturnStatus } from '../../../utils/returns'
import CardLoading from '../../../../../molecules/loading/cardLoading'

type ChildProps = {
  id: string
}

const ReturnsDetail: FC<ChildProps> = ({ id }) => {
  const [returnData, setReturnData] = useState<CardProps[]>([])
  const [lineItemData, setLineItemData] = useState<DataProps[]>([])
  const [memoData, setMemoData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { data } = await getCancelDetail({ id })
      formatSetTableData(data)
      formatLineItemData(data.rma_lines)
      formatMemoData(data.memos)
      setLoading(false)
    })()
  }, [])

  const formatSetTableData = ({
    id,
    rma_number,
    order_id,
    locale_total_amount,
    reason,
    status,
  }) => {
    const formattedData = [
      {
        label: 'Item ID',
        value: id,
      },
      {
        label: 'RMA number',
        value: rma_number ? rma_number : 'None Provided',
      },
      {
        label: 'PO #',
        value: order_id,
      },
      {
        label: 'Total RMA Value',
        value: `$ ${locale_total_amount}`,
      },
      {
        label: 'Reason',
        value: reason,
      },
      {
        label: 'Status',
        value: (
          <Badge
            label={status}
            variant='primary'
            status={getBadgeStatusByOrderReturnStatus(status)}
          />
        ),
      },
    ]
    setReturnData(formattedData)
  }

  const lineItemColumns = [
    {
      sortale: false,
      title: 'Item',
    },
    {
      sortale: false,
      title: 'Qty',
    },
  ]

  const formatLineItemData = (lines: any) => {
    const formattedData = lines.map(({ id, quantity, variant }) => {
      return {
        id,
        data: {
          Item: { onRender: () => <ReturnItemCol variant={variant} /> },
          Qty: { value: quantity },
        },
      }
    })
    setLineItemData(formattedData)
  }

  const memoColumns = [
    {
      sortale: false,
      title: 'Messages',
    },
    {
      sortale: false,
      title: 'Options',
    },
  ]

  const formatMemoData = (memos: any) => {
    const formattedData = memos.map(({ id, created_by, created_at, text }) => {
      return {
        id,
        data: {
          Messages: {
            onRender: () => (
              <MessageCol
                created_by={created_by}
                created_at={created_at}
                text={text}
              />
            ),
          },
          Options: { onRender: () => <OptionCol /> },
        },
      }
    })
    setMemoData(formattedData)
  }

  return (
    <div>
      {loading ? (
        <CardLoading label='Return Details' />
      ) : (
        <Card
          showCollapse={false}
          showDivider={true}
          headerContent='Return Details'
          bodyContent={
            returnData &&
            returnData.map(({ label, value }) => (
              <div key={label} className='mb-1'>
                <GridRow padding={false}>
                  <GridCol lg={6} md={6} sm={6}>
                    <p className='grey-700'>{label}</p>
                  </GridCol>
                  <GridCol lg={6} md={6} sm={6} justifySelf='end'>
                    <p className='grey-700'>{value}</p>
                  </GridCol>
                </GridRow>
              </div>
            ))
          }
        />
      )}

      <h3 className='h3'>Return Line Items</h3>
      <Table
        columns={lineItemColumns}
        data={lineItemData}
        loading={loading}
        activePage={1}
        selectable={false}
        totalRecords={lineItemData.length}
      />
      <h3 className='h3'>Return Messages</h3>
      <Table
        columns={memoColumns}
        data={memoData}
        loading={loading}
        activePage={1}
        selectable={false}
        totalRecords={memoData.length}
      />
    </div>
  )
}

export default ReturnsDetail
