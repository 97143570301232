import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { VARIANTS } from 'lib/constants'
import Banner from 'atoms/banner/banner'
import { useToast } from '@teamfabric/copilot-ui'

type ConfirmDeleteModalProps = {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  headerText: string
  body1: string
  bannerText?: string
  handleClick: () => Promise<void>
  dataTestId?: string
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  showModal,
  setShowModal,
  headerText,
  body1,
  bannerText,
  handleClick,
  dataTestId
}) => {
  const [loading, setLoading] = useState(false)
  const showToast = useToast()

  const onRemove = async () => {
    setLoading(true)
    try {
      await handleClick()
      showToast({
        label: 'Deleted successfully!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: `Error removing: ${error?.response?.data?.detail}`,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setShowModal(false)
      setLoading(false)
    }
  }

  return (
    <Modal
      headerText={headerText}
      onClose={() => setShowModal(false)}
      isVisible={showModal}
      footerButtons={[
        {
          onClick: () => setShowModal(false),
          text: "No, Don't Delete",
          variant: VARIANTS.TERTIARY,
          isDisabled: loading,
        },
        {
          onClick: onRemove,
          text: 'Yes, Delete',
          variant: VARIANTS.DESTRUCTIVE,
          icon: 'Trash',
          isDisabled: loading,
          dataTestId: {dataTestId}
        },
      ]}
      onBackdropClick={() => setShowModal(false)}
      dataTestid={dataTestId || 'confirm-delete-modal'}
    >
      <p className='body1_regular grey-600 mb-4'>{body1}</p>
      {bannerText && <Banner label={bannerText} variant='error' />}
    </Modal>
  )
}

export default ConfirmDeleteModal
