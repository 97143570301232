import { Button, ToolTip } from 'atoms'
import { ButtonVariants } from 'atoms/button/button'
import { ICONS } from 'lib/constants'

export const RenderAddToOpenIssue = ({
  canAddToOpenIssue,
  setShowAddToOpenIssue,
}) => {
  return (
    <Button
      variant={ButtonVariants.TERTIARY}
      text='Add To Open Issue'
      dataTestid='add-to-open-issue-bulk-action'
      isDisabled={!canAddToOpenIssue}
      onClick={setShowAddToOpenIssue}
    />
  )
}

export const RenderCreateIssue = ({ setShowCreateIssueModal }) => {
  return (
    <Button
      variant={ButtonVariants.TERTIARY}
      text='Create Issue'
      dataTestid='create-issue-bulk-action'
      onClick={setShowCreateIssueModal}
    />
  )
}

export const RenderRemoveButton = ({ canRemoveItems, setShowRemoveModal }) => {
  return (
    <Button
      variant={ButtonVariants.TERTIARY}
      text='Remove'
      icon={ICONS.TRASH}
      isDisabled={!canRemoveItems}
      dataTestid='remove-button-bulk-action'
      onClick={setShowRemoveModal}
    />
  )
}

export const RenderApproveButton = ({ onApprove }) => {
  return (
    <Button
      variant={ButtonVariants.TERTIARY}
      text='Approve'
      dataTestid='approve-button-bulk-action'
      onClick={onApprove}
    />
  )
}

export const RenderAddToOpenIssueWithTooltip = ({
  canAddToOpenIssue,
  setShowAddToOpenIssue,
}) => {
  return (
    <>
      {!canAddToOpenIssue ? (
        <ToolTip
          showOnDisabled={true}
          text='No open issues, create one instead'
        >
          <div>
            {' '}
            {
              <RenderAddToOpenIssue
                canAddToOpenIssue={canAddToOpenIssue}
                setShowAddToOpenIssue={setShowAddToOpenIssue}
              />
            }{' '}
          </div>
        </ToolTip>
      ) : (
        <RenderAddToOpenIssue
          canAddToOpenIssue={canAddToOpenIssue}
          setShowAddToOpenIssue={setShowAddToOpenIssue}
        />
      )}
    </>
  )
}

export const RenderRemoveButtonWithTooltip = ({
  canRemoveItems,
  setShowRemoveModal,
}) => {
  return (
    <>
      {!canRemoveItems ? (
        <ToolTip
          showOnDisabled={true}
          text='You cannot remove items due to the proposal status'
        >
          <div>
            {' '}
            {
              <RenderRemoveButton
                canRemoveItems={canRemoveItems}
                setShowRemoveModal={setShowRemoveModal}
              />
            }{' '}
          </div>
        </ToolTip>
      ) : (
        <RenderRemoveButton
          canRemoveItems={canRemoveItems}
          setShowRemoveModal={setShowRemoveModal}
        />
      )}
    </>
  )
}
