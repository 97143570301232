import { createSlice } from '@reduxjs/toolkit'

const createGenericSlice = (sliceName, initialState, extraReducers = {}) => {
  return createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setSorting(state, action) {
        state.params.order_by = action.payload
      },
      setParams(state, action) {
        state.params = action.payload
      },
      setSearch(state, action) {
        const key = initialState.searchKey || 'payload'
        const { target, value } = action.payload
        // Determine the value to use (use value if target is provided, otherwise use action.payload)
        const searchValue = target ? value : action.payload

        // Choose the appropriate object (targeted or general) to update
        const params = target ? state[target] : state.params

        if (searchValue) {
          // Set the search value
          params[key] = searchValue
        } else {
          // Delete the search value if it doesn't exist
          delete params[key]
        }
        // Reset pagination and ordering
        params.page = 1
        params.order_by = initialState.params.order_by
      },
      setFilters(state, action) {
        state.params.page = 1
        if (!action.payload.value) {
          delete state.params[action.payload.filter]
        } else {
          state.params[action.payload?.filter] = action.payload?.value
        }
      },
      setCurrentPage(state, action) {
        const { page, target } = action.payload
        if (target) {
          state[target].page = page
        } else {
          state.params.page = action.payload
        }
      },
      ...extraReducers, // Allow passing custom reducers
    },
  })
}

export default createGenericSlice
