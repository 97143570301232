import React from 'react'
import { GridRow as GridRowUI } from '@teamfabric/copilot-ui'

type GridRowProps = {
  padding?: boolean
  children: React.ReactNode
  alignItems?: any
  justifyItems?: any
}

const GridRow: React.FC<GridRowProps> = ({ ...props }) => {
  return <GridRowUI {...props} />
}

export default GridRow
