import { getConnectionPlatformAccounts } from 'api/connection'
import { Link, ToolTip, Modal } from 'atoms'
import Card2Col from 'molecules/cards/2Col'
import { FC, useEffect, useMemo, useState } from 'react'

interface IntegrationTypeProps {
  connection: {
    id: string
    integration_type: string
    showTooltip?: boolean
  }
}

const IntegrationTypePresenter: FC<IntegrationTypeProps> = ({ connection }) => {
  const [showModal, setShowModal] = useState(false)
  const [platformAccounts, setPlatformAccounts] = useState([])
  const [integrationDetails, setIntegrationDetails] = useState(null)
  const [loading, setLoading] = useState(false)

  const externalIntegrations = ['Shopify', 'BigCommerce', 'WooCommerce']

  const fetchPlatformAccounts = async () => {
    const { data } = await getConnectionPlatformAccounts({
      connectionId: connection?.id,
    })
    setPlatformAccounts(data.results)
    setIntegrationDetails(
      data.results.find(
        (el) => el?.platform?.code === connection?.integration_type
      )
    )
    setLoading(false)
  }

  useEffect(() => {
    if (showModal && connection) {
      setLoading(true)
      fetchPlatformAccounts()
    }
  }, [showModal])

  const presenter = useMemo(() => {
    const presenter: { label: string; tooltip: string } = {
      label: '',
      tooltip: '',
    }
    switch (connection?.integration_type) {
      case 'edi':
        presenter.label = 'EDI'
        presenter.tooltip =
          'The connection is using EDI as the primary integration.'
        break
      case 'shipstation':
        presenter.label = 'ShipStation'
        presenter.tooltip =
          'The connection is using ShipStation as the primary integration.'
        break
      case 'shopify':
        presenter.label = 'Shopify'
        presenter.tooltip =
          'The connection is using Shopify as the primary integration.'
        break
      case 'bigcommerce':
        presenter.label = 'BigCommerce'
        presenter.tooltip =
          'The connection is using BigCommerce as the primary integration.'
        break
      case 'woocommerce':
        presenter.label = 'WooCommerce'
        presenter.tooltip =
          'The connection is using WooCommerce as the primary integration.'
        break
      case 'storefront':
        presenter.label = 'Shopify'
        presenter.tooltip =
          'The connection is using Shopify as the primary integration.'
        break
      case 'console-print-labels':
        presenter.label = 'Console (Labels)'
        presenter.tooltip =
          'The connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
        break
      case 'console-register-tracking-numbers':
        presenter.label = 'Console (Register Tracking)'
        presenter.tooltip =
          'The connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
        break
      // compensate for bug in API (MKP-170)
      case 'console-register-tracking-number':
        presenter.label = 'Console (Register Tracking)'
        presenter.tooltip =
          'This connection is using an integration (e.g. Shopify or ShipStation) as the primary integration.'
        break
      case 'api':
        presenter.label = 'API'
        presenter.tooltip = 'This connection is using a custom API integration.'
        break
      default:
        presenter.label = 'Console'
        presenter.tooltip =
          'This connection is using the web console as the primary integration.'
    }
    return presenter
  }, [connection])

  const integrationTable = useMemo(() => {
    const formattedData = integrationDetails?.credentials?.map((item) => {
      const key = Object.keys(item)[0]
      const value = item[key].values[0]?.value
      return {
        label: item[key]?.label,
        value,
      }
    })
    return formattedData
  }, [integrationDetails])

  const InfoModal = () => {
    return (
      <Modal
        headerText='Integration Configuration'
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        footerButtons={[
          {
            onClick: () => setShowModal(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        onBackdropClick={() => setShowModal(false)}
      >
        <Card2Col
          loading={loading}
          showCollapse={false}
          header={presenter.label}
          data={integrationTable}
        />
      </Modal>
    )
  }
  const isExternal = externalIntegrations.includes(presenter.label)
  return (
    <>
      <ToolTip
        text={isExternal ? 'View info' : presenter.tooltip}
        placement='bottomStart'
      >
        <div className='hover-cursor'>
          {isExternal ? (
            <Link
              label={presenter.label}
              variant='primary'
              onClick={() => setShowModal(true)}
              mode='inline'
            />
          ) : (
            <span>{presenter.label}</span>
          )}
        </div>
      </ToolTip>
      {showModal && <InfoModal />}
    </>
  )
}

export default IntegrationTypePresenter
