import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import Button from 'atoms/button/button'
import Table from 'atoms/table/table'
import FTPLoginDetailCard from './fptLoginDetailCard'
import { EDIAccountProps } from 'modules/onboarding/utils/types'
import { getLocations } from 'api/locations'
import { useToast } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import CardLoading from 'molecules/loading/cardLoading'

type UploadInvoiceProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  orders: any
  account: EDIAccountProps
  loadOrders: () => void
  loadingOrders: boolean
  reseting: boolean
}

const UploadInvoice: React.FC<UploadInvoiceProps> = ({
  setIsNextButtonDisabled,
  account,
  orders,
  loadOrders,
  loadingOrders,
  reseting,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const showToast = useToast()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState([])
  const [billingAddressExists, setBillingAddressExists] = useState(false)

  const loadBillingAddresses = async () => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await getLocations({ params: { type: 'billing' } })
      setBillingAddressExists(results.length > 0)
      setLoading(false)
    } catch (error) {
      showToast({
        label: "Couldn't load billing addresses",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  // First Loaded
  useEffect(() => {
    ;(async () => {
      setIsNextButtonDisabled(true)
      await loadBillingAddresses()
    })()
  }, [])

  // Order is updated
  useEffect(() => {
    const ids = integration.selectedIntegrationInfo.orderIds.map(({ id }) => id)
    const formattedOrders = orders
      .filter(({ id }) => ids.includes(id))
      .map((data) => ({
        id: data.id,
        data: {
          'Order ID': {
            value: data.purchase_order_number,
          },
          'Invoice Status': {
            value: getInvoiceStatus(data),
          },
        },
      }))
    setOrderData(formattedOrders)
  }, [orders])

  // Update setIsNextButtonDisabled
  useEffect(() => {
    const filteredOrders = orders.filter(({ id }) =>
      integration.selectedIntegrationInfo.orderIds.includes(id)
    )
    const isInvoiced =
      filteredOrders.filter(({ status }) => status === 'closed').length ===
      filteredOrders.length

    setIsNextButtonDisabled(!billingAddressExists || !isInvoiced)
  }, [orders, billingAddressExists])

  const tableColumns = [
    {
      sortable: false,
      title: 'Order ID',
    },
    {
      sortable: false,
      title: 'Invoice Status',
    },
  ]

  const getInvoiceStatus = (data) => {
    let status = 'Not Finalized'
    if (data.invoice_count === 0) {
      status = 'Not invoiced'
    }
    // if the invoice is closed, but not acknowledged
    if (data.status === 'closed' && data.acknowledged_at === null) {
      status = 'Sent'
    }
    // if the invoice is closed, but acknowledged
    if (data.status === 'closed' && data.acknowledged_at !== null) {
      status = 'Received'
    }
    return status
  }

  return (
    <>
      {(loading || reseting) && (
        <CardLoading label={currentTask.UPLOAD_INVOICE.NAME} />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <h1 className='h5 mb-2'>{currentTask.UPLOAD_INVOICE.NAME}</h1>
              <p className='body1-regular mb-4'>
                Upload an 810 Invoice file for your sample items orders.
              </p>
              <p className='body1-regular'>
                1. Open the FTP page and upload the 856A5N fulfillment file into
                your outbox.
              </p>
              <p className='body1-regular'>
                2. Wait 10-15 minutes for the file to process. Click refresh to
                update invoice status.
              </p>
              <p className='body1-regular'>
                3. Click Confirm & Continue when both order statuses are
                invoiced
              </p>
            </div>
          }
          bodyContent={
            <>
              {/* Account Detail */}
              {account.qualifierId !== '' && (
                <div className='mb-4'>
                  <FTPLoginDetailCard account={account} />
                </div>
              )}
              <div className='mb-4'>
                <Button
                  icon='Refresh'
                  variant='secondary'
                  text='Refresh Orders'
                  onClick={() => {
                    loadOrders()
                  }}
                />
              </div>
              <Table
                columns={tableColumns}
                data={orderData}
                loading={loadingOrders}
                activePage={1}
                selectable={false}
                totalRecords={orderData.length}
              />
            </>
          }
        />
      )}
    </>
  )
}

export default UploadInvoice
