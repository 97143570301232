import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { Box } from 'atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { DataProps } from '@teamfabric/copilot-ui'
import { getJobs } from 'api/jobs'
import JobsTable from '../components/list/table'
import JobsFilter from '../components/list/filters'

const JobsList: FC = () => {
  const params = useSelector(
    (state: RootState) => state.jobs.params
  )

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Job Reports' })
        ),
      0
    )
  }, [])

  const fetchJobs = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data: data } = await getJobs({ params: { ...params } })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  useEffect(() => {
    if (params) {
      ; (async () => {
        await fetchJobs()
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Job Status'
        customClassName='mb-4'
        dataTestid='job-status-header'
      />
      <div className='filterDiv' data-testid="filter-div">
        <JobsFilter params={params} />
      </div>
      <JobsTable
        data={tableData}
        loading={loading}
        currentPage={params.page}
        currentSortBy={params.order_by}
        totalRows={totalRows}
      />
    </Box>
  )
}

export default JobsList
