import React from 'react'
import Tag from 'atoms/tag/tag'
import ToolTip from 'atoms/tooltip/tooltip'
import { capitalize } from 'lodash'

type ChildProps = {
  name?: string
  value?: string
  primaryLabel?: string
  tooltipText?: string
  onRemove: () => void
}

const FilterChip: React.FC<ChildProps> = ({
  name,
  value,
  primaryLabel,
  tooltipText,
  onRemove,
}) => {
  return (
    <div>
      {tooltipText && tooltipText.length ? (
        <ToolTip text={tooltipText}>
          <div>
            <Tag
              id={value}
              primaryLabel={
                primaryLabel
                  ? primaryLabel
                  : `${capitalize(name)}: ${capitalize(value)}`
              }
              isError={false}
              isRemovable={true}
              onRemove={() => {
                onRemove()
              }}
            />
          </div>
        </ToolTip>
      ) : (
        <Tag
          id={value}
          primaryLabel={
            primaryLabel
              ? primaryLabel
              : `${capitalize(name)}: ${capitalize(value)}`
          }
          isError={false}
          isRemovable={true}
          onRemove={() => {
            onRemove()
          }}
        />
      )}
    </div>
  )
}

export default FilterChip
