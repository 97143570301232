import React, { useState, useEffect } from 'react'
import Table from 'atoms/table/table'
import { Box } from 'atoms'
import AcknowledgeCancellationStatusPresenter from 'modules/orders/components/modal/cancellations/acknowledgeCancellationsStatusPresenter'

type ChildProps = {
  cancellation: any
  isRetailer: boolean
  isBulkAction?: boolean
}

const AcknowledgeCancellations: React.FC<ChildProps> = ({
  cancellation,
  isBulkAction,
  isRetailer,
}) => {
  const [cancellationsData, setInvoicesData] = useState<any[]>([])

  useEffect(() => {
    if (!isBulkAction) {
      formatSetTableData({ data: [cancellation] })
    }
    // else formatData(selected) - finish for bulkactions
  }, [])

  const columns = [
    {
      title: 'Cancel #',
    },
    {
      title: 'Status',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((cancellation, id) => {
      return {
        id,
        data: {
          'Cancel #': { value: cancellation?.id },
          'Status': {
            onRender: () => (
              <AcknowledgeCancellationStatusPresenter
                cancellation={cancellation}
                isRetailer={isRetailer}
              />
            ),
          },
        },
      }
    })
    setInvoicesData(formattedData)
  }

  return (
    <Box flex={{ flexDirection: 'column' }} gap={4}>
      <p>Are you sure you want to acknowledge the following cancellation?</p>
      <Table columns={columns} data={cancellationsData} selectable={false} />
    </Box>
  )
}

export default AcknowledgeCancellations
