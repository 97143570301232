import React, { useEffect, useState } from 'react'
import Badge from '../../atoms/badge/badge'

type ChildProps = {
  tracking: any
  tracking_url: string
}

export const getCarrierStatus = ({ tracking }) => {
  // if the shipment is marked as delivered, that's great!
  if (tracking !== null && tracking.delivered_at !== null) {
    return {
      name: 'Delivered',
      statusName: 'success',
    }
    // presenter.progress = 100
    // if the shipment is marked as picked up, that's ok...
  } else if (tracking !== null && tracking.delivered_at !== null) {
    return {
      name: 'Picked Up',
      statusName: 'success',
    }
    // presenter.progress = 50
  }
  return {
    name: 'Registered',
    statusName: 'info',
  }
}

const CarrierStatus: React.FC<ChildProps> = ({ tracking, tracking_url }) => {
  const [label, setLabel] = useState('')
  const [statusVariant, setStatusVariant] = useState('')

  useEffect(() => {
    setCarrierStatus()
  })

  const setCarrierStatus = () => {
    const { name, statusName } = getCarrierStatus({ tracking })
    setLabel(name)
    setStatusVariant(statusName)
  }

  return (
    <div>
      <Badge
        label={label}
        variant='primary'
        status={statusVariant}
        onClick={() => window.open(tracking_url, '_blank')}
      />
    </div>
  )
}

export default CarrierStatus
