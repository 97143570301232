import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const DeclineProposalModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)

  const showToast = useToast()

  const declineProposal = async () => {
    try {
      setLoading(true)
      await patchProposal({
        id: props.proposal?.id,
        body: {
          status: 'declined',
        },
      })
      showToast({
        label: `Your proposal has been declined successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error declining the proposal: ${errorMessage}`
          : 'Error occurred while declining the proposal, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Decline Proposal'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Close',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => declineProposal(),
            text: 'Decline Proposal',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <div className='body1-regular grey-600'>
            Are you sure you want to decline this entire proposal? This action
            cannot be undone and the supplier may receive a notification.
          </div>
        </>
      </Modal>
    </>
  )
}

export default DeclineProposalModal
