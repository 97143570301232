import { PRODUCTS } from 'modules/products/constants'

const getBadgeStatus = (status: string) => {
  switch (status) {
    case PRODUCTS.FEED_REQUESTS.STATUS.APPROVED.VALUE:
      return 'success'
    case PRODUCTS.FEED_REQUESTS.STATUS.REJECTED.VALUE:
      return 'error'
    case PRODUCTS.FEED_REQUESTS.STATUS.PENDING.VALUE:
      return 'alert'
    default:
      return ''
  }
}

export { getBadgeStatus }
