import React from 'react'
import { useNavigate } from 'react-router-dom'
import CardLoading from 'molecules/loading/cardLoading'
import { GridCol, GridRow, Icon, Card } from 'atoms'

type ChildProps = {
  loading?: boolean
  title: any
  description?: string
  skeletonRowsNumber?: number
  iconName: string
  path?: string
  iconSize?: number
  handleClick?: () => void
}
const OpenCardWithIcon: React.FC<ChildProps> = (props) => {
  const navigate = useNavigate()

  const CardContent = () => {
    return (
      <Card
        style={{ padding: 0 }}
        headerContent={
          <div style={{ width: '100%' }}>
            <GridRow padding={false} alignItems='center'>
              <GridCol sm={2} justifySelf='auto'>
                <Icon
                  iconName={props.iconName}
                  size={props.iconSize ? props.iconSize : 32}
                />
              </GridCol>
              <GridCol sm={10}>
                <p className='h5 m-0'>{props.title}</p>
                <p className='body2-regular m-0 grey-700'>
                  {props.description}
                </p>
              </GridCol>
            </GridRow>
          </div>
        }
        showCollapse={false}
        open={false}
        showDivider={false}
      />
    )
  }

  const LoadingCard = () => {
    return (
      <CardLoading
        label={props.title}
        num={2}
        showCollapse={false}
        open={false}
      />
    )
  }

  const CardWithLink = () => {
    return (
      <div
        onClick={() => {
          props?.handleClick?.()
          navigate(props.path)
        }}
        className='hover-cursor'
      >
        <CardContent />
      </div>
    )
  }

  if (props.loading) {
    return <LoadingCard />
  } else if (props.path) {
    return <CardWithLink />
  } else {
    return <CardContent />
  }
}

export default OpenCardWithIcon
