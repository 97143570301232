import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import CardLoading from 'molecules/loading/cardLoading'
import Input from 'atoms/input/input'
import Button from 'atoms/button/button'
import { postEdiOnboarding } from 'api/edi'
import FTPLoginDetailCard from './fptLoginDetailCard'
import { EDIAccountProps } from 'modules/onboarding/utils/types'
import CardHeader from 'molecules/cards/cardHeader'
import { useToast } from '@teamfabric/copilot-ui'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { setIntegration } from 'store/onboardingSlice'
import {
  ediAccountInitData,
  ftpAccountInitData,
} from 'modules/onboarding/utils'

type CreateEdiProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  account: EDIAccountProps
  reseting: boolean
}

const CreateEdi: React.FC<CreateEdiProps> = ({
  setIsNextButtonDisabled,
  account,
  reseting,
}) => {
  const ediTasks = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const showToast = useToast()
  const dispatch = useDispatch()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const connectionId = useSelector(
    (state: RootState) => state.onboarding.connectionId
  )
  const [loading, setLoading] = useState(false)
  const [isCreateAvailable, setIsCreateAvailable] = useState(true)

  const [ediAccount, setEdiAccount] = useState(ediAccountInitData)
  const [ftpAccount, setFtpAccount] = useState(ftpAccountInitData)

  const createEdiAccount = async () => {
    try {
      setLoading(true)
      const body = {
        qualifier: ediAccount.qualifierId,
        identifier: ediAccount.senderID,
        connection: { id: connectionId },
      }
      const {
        data: {
          ftp_account: { username, password },
        },
      } = await postEdiOnboarding({ body })
      dispatch(
        setIntegration({
          taskId: integration.taskId,
          selectedIntegrationInfo: {
            ...integration.selectedIntegrationInfo,
            [ediTasks.CREATE_EDI.CODE]: true,
          },
          processNum: 2,
          introDone: true,
          completed: false,
          selectedMethod: integration.selectedMethod,
        })
      )
      setIsCreateAvailable(false)
      setIsNextButtonDisabled(false)
      setFtpAccount({
        username,
        password,
      })
      setLoading(false)
    } catch (error) {
      showToast({
        label: "Couldn't create",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  useEffect(() => {
    if (
      account.username === '' ||
      account.password === '' ||
      account.qualifierId === '' ||
      account.senderID === ''
    ) {
      return
    }
    setIsCreateAvailable(false)
    setIsNextButtonDisabled(false)
    setEdiAccount({
      qualifierId: account.qualifierId,
      senderID: account.senderID,
    })
    setFtpAccount({
      username: account.username,
      password: account.password,
    })
  }, [account])

  return (
    <>
      {(loading || reseting) && (
        <CardLoading label={ediTasks.CREATE_EDI.NAME} num={3} />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={true}
          headerContent={
            <CardHeader
              h1Text={ediTasks.CREATE_EDI.NAME}
              h2Text='Provide your EDI Qualifier and Sender IDs to create your unique
            marketplace FTP login.'
            />
          }
          bodyContent={
            <>
              <h2 className='kicker'>integration method</h2>
              <div className='mb-3'>
                <Input
                  width='100%'
                  label='Qualifier ID'
                  required={true}
                  message={
                    ediAccount.qualifierId.length > 2
                      ? 'It must be 2 letters'
                      : ''
                  }
                  messageType='error'
                  inputProps={{
                    value: ediAccount.qualifierId,
                    placeholder: 'Qualifier ID',
                    onChange: ({ target: { value } }) => {
                      setEdiAccount({
                        qualifierId: value.toUpperCase(),
                        senderID: ediAccount.senderID,
                      })
                    },
                  }}
                />
              </div>
              <div className='mb-4'>
                <Input
                  width='100%'
                  label='Sender ID'
                  required={true}
                  inputProps={{
                    value: ediAccount.senderID,
                    placeholder: 'Sender ID',
                    onChange: ({ target: { value } }) => {
                      setEdiAccount({
                        qualifierId: ediAccount.qualifierId,
                        senderID: value,
                      })
                    },
                  }}
                />
              </div>
              {isCreateAvailable && (
                <Button
                  text='Create Account'
                  variant='tertiary'
                  isDisabled={
                    ediAccount.qualifierId.length > 2 ||
                    ediAccount.qualifierId === '' ||
                    ediAccount.senderID === ''
                  }
                  onClick={async () => {
                    await createEdiAccount()
                  }}
                />
              )}
              {/* Account Detail */}
              {!isCreateAvailable && (
                <FTPLoginDetailCard
                  account={{ ...ediAccount, ...ftpAccount }}
                />
              )}
            </>
          }
        />
      )}
    </>
  )
}

export default CreateEdi
