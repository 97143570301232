type TrackingColProps = {
  tracking_number: string
  onClick: () => void
}

const TrackingCol: React.FC<TrackingColProps> = ({
  tracking_number,
  onClick,
}) => {
  return (
    <div className='clickableCells' onClick={() => onClick()}>
      {tracking_number ? tracking_number : ''}
    </div>
  )
}

export default TrackingCol
