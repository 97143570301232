import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box, Card, Checkbox, Input, PageHeader, Table } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { DropDownInitialObj } from 'lib/utils/initialValue'
import { getAttributes } from 'api/attributes'
import SelectTemplateMappingsTarget from 'molecules/selectDropdowns/selectTemplateMappingsTarget'

export interface MappingType {
  id: number
  attributeName: any
  attribute: any
  header: any
  isRequired: boolean
  target: string
  priority: number | string
}

type ChildProps = {
  headers: any[]
  direction: any
  setColumnMappings: Dispatch<SetStateAction<MappingType[]>>
}

const ColumnMappings: React.FC<ChildProps> = ({
  headers,
  direction,
  setColumnMappings,
}) => {
  const [attributes, setAttributes] = useState([])
  const [attributeOptions, setAttributeOptions] = useState([])
  const [loadingAttributes, setLoadingAttributes] = useState(true)
  const [formattedData, setFormattedData] = useState([])
  const [mappings, setMappings] = useState([])

  const tableColumns = [
    {
      title: 'Column Header',
      width: '20%',
    },
    {
      title: 'Map To',
      width: '30%',
    },
    {
      title: 'Target',
      width: '20%',
    },
    {
      title: 'Priority',
      width: '15%',
    },
  ]

  if (direction === 'import') {
    tableColumns.push({
      title: '',
      width: '15%',
    })
  }

  const fetchAttributes = async () => {
    try {
      const { data } = await getAttributes({ params: { pagination: 0 } })
      setAttributes(data?.results)
      const formattedOptions: DropDownInitialObj[] = data?.results.map((el) => {
        return {
          id: el.id,
          label: el.name,
        }
      })
      setAttributeOptions(formattedOptions)
    } finally {
      setLoadingAttributes(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchAttributes()
    })()
  }, [])

  const AttributeDropdown = (id, attributeName) => {
    return (
      <>
        <Dropdown
          dataTestid='attribute-dropdown'
          label=''
          value={attributeOptions.find(
            (el) => el.label.toLowerCase() === attributeName.toLowerCase()
          )}
          onChange={(value) => {
            const selected = attributes.find((el) => el.id === value.id)
            handleChange(id, 'attribute', selected)
          }}
          options={attributeOptions}
          placeholder='Select Attribute'
          variant='large'
        />
      </>
    )
  }

  const PriorityInput = (id, priority) => {
    return (
      <>
        <Input
          label=''
          width='100px'
          inputProps={{
            value: priority,
            onChange: ({ target: { value } }) => {
              handleChange(id, 'priority', value)
            },
          }}
        />
      </>
    )
  }

  const RequiredCheckbox = (id, isRequired) => {
    return (
      <>
        <Checkbox
          label={'Required'}
          name={''}
          value={''}
          checked={isRequired}
          disabled={false}
          onChange={({ target: { checked } }) => {
            handleChange(id, 'isRequired', checked)
          }}
        />
      </>
    )
  }

  useEffect(() => {
    if (!loadingAttributes && attributes.length > 0) {
      const initialFormattedData = headers.map((header, index) => {
        const matchedAttribute = attributes.find(
          (attr) => attr.name.toLowerCase() === header.toLowerCase()
        )
        return {
          id: index,
          attributeName: matchedAttribute ? matchedAttribute.name : '',
          attribute: matchedAttribute || null,
          header,
          isRequired: false,
          target: 'variant',
          priority: (index + 1) * 10,
        }
      })
      setFormattedData(initialFormattedData)
      setMappings(initialFormattedData) // Initialize mappings with initial data
    }
  }, [headers, loadingAttributes, attributes])

  useEffect(() => {
    setFormattedData((prevFormattedData) =>
      prevFormattedData.map((item) => {
        const mappedItem = mappings.find((mapped) => mapped.id === item.id)
        if (mappedItem) {
          return {
            ...item,
            attribute: mappedItem.attribute,
            isRequired: mappedItem.isRequired,
          }
        }
        return item
      })
    )
    setColumnMappings(mappings)
  }, [mappings])

  const handleChange = (id, key, value) => {
    setMappings((prevMappings) => {
      // Map over prevMappings to create updatedData
      return prevMappings.map((item) => {
        if (item.id === id) {
          if (key === 'attribute') {
            return {
              ...item,
              attribute: value,
              attributeName: value.name,
            }
          } else {
            return { ...item, [key]: value }
          }
        }
        return item
      })
    })
  }

  const tableData = useMemo(() => {
    return formattedData.map(
      ({ id, header, attributeName, isRequired, target, priority }) => {
        const rowData = {
          id,
          data: {
            'Column Header': { value: header },
            'Map To': {
              onRender: () => AttributeDropdown(id, attributeName),
            },
            'Target': {
              onRender: () => (
                <SelectTemplateMappingsTarget
                  targetValue={target}
                  handleUpdate={(option) => {
                    handleChange(id, 'target', option?.value)
                  }}
                />
              ),
            },
            'Priority': {
              onRender: () => PriorityInput(id, priority),
            },
          },
        }
        if (direction === 'import') {
          rowData.data[''] = {
            onRender: () => RequiredCheckbox(id, isRequired),
          }
        }
        return rowData
      }
    )
  }, [formattedData, direction])

  return (
    <Box width='100%' margin={{ bottom: 4, top: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Column Mappings</span>}
              h2Text='For each column in your file, please map it to the closest available attribute.'
            />
          </Box>
        }
        bodyContent={
          <>
            <Table
              columns={tableColumns}
              data={tableData}
              showPagination
              disableTableBorder
              totalRecords={headers.length}
              perPageRecords={10}
              loading={loadingAttributes}
            />
          </>
        }
      ></Card>
    </Box>
  )
}

export default ColumnMappings
