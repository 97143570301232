import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import PageHeader from 'atoms/pageHeader'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import InventorySummaryTable from '../components/table/inventorySummaryTable'
import { getInventorySummary } from 'api/inventory'

const MerchantsListTemplate: FC = () => {
  const params = useSelector(
    (state: RootState) => state.inventorySummary.params
  )

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)

  const [totalRows, setTotalRows] = useState(0)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data: data } = await getInventorySummary({ params })
      setTotalRows(data?.length)
      setTableData(data)
      setLoading(false)
    })()
  }, [])

  // TODO: update breadcrumbs once retailer product dashboard available
  // useEffect(() => {
  //   window.updateBreadcrumb(BREADCRUMBS.CONNECTION_LISTING)
  // }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeader h1Text='Inventory by Vendor' />
          <BulkActionsBar
            loading={loading}
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
          />
          <InventorySummaryTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default MerchantsListTemplate
