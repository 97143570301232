import API, { API_URL } from 'api'

export const getTags = async ({ params = {} }) => {
  return await API.get(API_URL.TAGS(), { params })
}

export const addOrderTag = async ({ orderId, body }) => {
  return await API.post(API_URL.ORDER_TAGS({ orderId }), body)
}

export const deleteTagItems = async ({ tagId }) => {
  return await API.delete(API_URL.TAG_ITEM({ tagId }))
}

export const bulkRemoveTags = async ({ body }) => {
  return await API.post(API_URL.BULK_DELETE_TAGS(), body)
}

export const bulkAddTags = async ({ body }) => {
  return await API.post(API_URL.BULK_ADD_TAGS(), body)
}
