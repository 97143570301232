import React, { useEffect, useMemo, useState } from 'react'
import { PRODUCTS } from 'modules/products/constants'
import { FILTERS } from 'molecules/filter/constants'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxWithSearchFilterChange,
  handleRadioFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  resetAllFilters,
  setConnectionOptions,
  setFilters,
  setUpdatedOn,
} from 'store/permitsSlice'
import Box from 'atoms/box/box'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [updatedOnCounter, setUpdatedOnCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.permits.connectionOptions
  )

  const updatedOn = useSelector((state: RootState) => state.permits.updatedOn)

  const isAFilterApplied = useMemo(() => {
    return connectionsCounter + acknowledgedCounter + updatedOnCounter > 0
  }, [connectionsCounter, acknowledgedCounter, updatedOnCounter])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: PRODUCTS.CONNECTIONS.NAME,
    },
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: PRODUCTS.ACKNOWLEDGED.NAME,
    },
    updated_at_gte: {
      setFilterCount: setUpdatedOnCounter,
      dateRange: updatedOn,
      action: setUpdatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.UPDATED_ON.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${PRODUCTS.CONNECTIONS.NAME}-filter`}
          label={PRODUCTS.CONNECTIONS.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <RadioGroupFilter
          dataTestid={`${PRODUCTS.ACKNOWLEDGED.NAME}-filter`}
          label={PRODUCTS.ACKNOWLEDGED.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: PRODUCTS.ACKNOWLEDGED.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: PRODUCTS.ACKNOWLEDGED.PARAM,
              setFilters,
            })
          }}
          value={params?.is_acknowledged?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={
            acknowledgedCounter > 0 ? acknowledgedCounter?.toString() : ''
          }
        />
        <DateFilter
          dataTestid={`${PRODUCTS.UPDATED_ON.NAME}-filter`}
          label={PRODUCTS.UPDATED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.UPDATED_ON.PARAM_START,
              date,
            })
          }}
          defaultValue={updatedOn}
          filterCount={updatedOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.UPDATED_ON.PARAM_START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
