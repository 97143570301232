import Link from 'atoms/link/link'

type TrackingNumberColProps = {
  onClick: () => void
  shipment: any
}

const TrackingNumberCol: React.FC<TrackingNumberColProps> = ({
  shipment,
  onClick,
}) => {
  const trackingNumberLabel = (shipment) => {
    if (shipment?.tracking_number) {
      return shipment.tracking_number
    } else if (shipment?.error_logs?.length) {
      return 'Has Error!'
    }
    return 'Not Set'
  }

  return (
    <Link
      onClick={() => onClick()}
      label={trackingNumberLabel(shipment)}
      variant='primary'
    />
  )
}

export default TrackingNumberCol
