import React, { useState } from 'react'
import { MODALS } from 'molecules/modals/constants'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { BUTTONS, VARIANTS } from 'lib/constants'
import RadioGroup from 'atoms/input/radioGroup'
import { exportInvoices } from 'api/invoices'

type InvoiceExportModalProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  params: any
}

const InvoiceExportModal: React.FC<InvoiceExportModalProps> = ({
  showModal,
  setShowModal,
  params,
}) => {
  const [schedulingExport, setSchedulingExport] = useState(false)
  const [exportType, setExportType] = useState('1')

  const showToast = useToast()

  const scheduleExport = async () => {
    try {
      setSchedulingExport(true)
      // get rid of params redundant for export call
      let exportParamObject = { ...params }
      delete exportParamObject['skip_attributes']
      delete exportParamObject['limit']
      delete exportParamObject['page']

      await exportInvoices({
        params: {
          invoice_fields: exportType,
          ...exportParamObject,
        },
      })
      showToast({
        label: MODALS.EXPORT.TOAST.SUCCESS,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: MODALS.EXPORT.TOAST.FAILURE,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      closeModal()
      setSchedulingExport(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setExportType(MODALS.EXPORT.TYPES.INVOICES.RADIO_GROUP_OPTIONS[0].value)
  }

  return (
    <Modal
      headerText={MODALS.EXPORT.TYPES.INVOICES.LABEL}
      description=''
      size='small'
      onClose={() => closeModal()}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => closeModal(),
          text: BUTTONS.CLOSE.LABEL,
          variant: VARIANTS.SECONDARY,
        },
        {
          dataTestid: 'export-button',
          onClick: () => scheduleExport(),
          text: BUTTONS.EXPORT.LABEL,
          variant: VARIANTS.PRIMARY,
          isDisabled: schedulingExport,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => closeModal()}
    >
      <p className='mb-4'>{MODALS.EXPORT.TYPES.INVOICES.COPY_1}</p>
      <RadioGroup
        label='Export Settings'
        name='exportType'
        onChange={(e) => {
          setExportType(e.target.value)
        }}
        options={MODALS.EXPORT.TYPES.INVOICES.RADIO_GROUP_OPTIONS}
        defaultValue={exportType}
      />
      {exportType ===
        MODALS.EXPORT.TYPES.INVOICES.RADIO_GROUP_OPTIONS[1].value && (
        <p className='mb-4'>
          <span className='kicker'>Please note: </span>
          {MODALS.EXPORT.TYPES.INVOICES.COPY_2}
        </p>
      )}
    </Modal>
  )
}

export default InvoiceExportModal
