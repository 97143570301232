import React, { useEffect, useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { bulkRemoveTags } from 'api/tags'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useDispatch } from 'react-redux'
import { setSelected } from 'store/orderSlice'
import TagModal from 'molecules/modals/tag/tagModal'

type BulkRemoveTagProps = {
  setDisplayModal: (value: boolean) => void
  isVisible: boolean
  tableData: any
  removeTableTags: (value: any[]) => void
}

const BulkRemoveTag: React.FC<BulkRemoveTagProps> = ({
  setDisplayModal,
  isVisible,
  tableData,
  removeTableTags,
}) => {
  const [commonTags, setCommonTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const showToast = useToast()
  const dispatch = useDispatch()
  const selected = useSelector((state: RootState) => state.orders.selected)

  const deleteTags = async () => {
    try {
      const removingIds = []
      if (selectedTags.length > 1) {
        selectedTags.forEach(({ ids }) => removingIds.push(...ids))
      } else {
        removingIds.push(...selectedTags[0].ids)
      }
      const body = { object_tag_item_ids: removingIds }
      await bulkRemoveTags({ body })
      removeTableTags(removingIds)
      showToast({
        label: 'Bulk Removed Tag',
        isDismissable: true,
        id: 'bulk-remove-tags',
      })
    } catch (error) {
      showToast({
        label: 'Error Deleting Tag',
        isDismissable: true,
        id: 'error-delete-tags',
        variant: 'error',
      })
    } finally {
      dispatch(setSelected([]))
      setSelectedTags([])
      setDisplayModal(false)
    }
  }

  const getCommonTags = (filtered) => {
    // one order selected which has tags.length > 0
    if (!filtered || (filtered.length === 1 && filtered[0].length > 0)) {
      return []
    }
    return filtered
      .reduce((x, y) =>
        x.filter((obj) =>
          y.some((yTag) => {
            if (yTag.tag === obj.tag) {
              obj.ids = [obj.id, yTag.id]
            }
            return obj.tag === yTag.tag
          })
        )
      )
      .map(({ tag, ids }) => {
        return { id: tag, ids, label: tag }
      })
  }

  useEffect(() => {
    if (!tableData || selected.length === 0 || tableData.length === 0) {
      return
    }
    const filtered = tableData
      ?.filter(({ id }) => selected.includes(id))
      .map(({ tags }) => tags)
    setCommonTags(getCommonTags(filtered))
  }, [tableData, selected])

  return (
    <TagModal
      isBulk
      setDisplayModal={setDisplayModal}
      isVisible={isVisible}
      onClick={deleteTags}
      headerText='Bulk Remove Tags'
      inputLabel='Tags'
      addTagButtonLabel='Remove Tags'
      commonTags={commonTags}
      setUserSelectedTags={(tags) => {
        setSelectedTags(tags)
      }}
    />
  )
}

export default BulkRemoveTag
