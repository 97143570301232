import React, { useState } from 'react'
import CardWithHorizontalTabs from 'molecules/cards/cardWithHorizontalTabs'
import FulfillmentLineItems from './fulfillmentTable'
import PricingLineItems from './pricingTable'

type OrderLinesProps = {
  loading: boolean
  details: any
  reloadOrder: () => void
}

const OrderLines: React.FC<OrderLinesProps> = ({
  loading,
  details,
  reloadOrder,
}) => {
  const [label, setLabel] = useState('')

  return (
    <>
      <CardWithHorizontalTabs
        header='Order Lines'
        loading={loading}
        noResultsText='You have no results.'
        tabsData={[
          {
            label: 'Fulfillment',
            content: (
              <FulfillmentLineItems
                loading={loading}
                details={details}
                reloadOrder={reloadOrder}
              />
            ),
          },
          {
            label: 'Pricing',
            content: (
              <PricingLineItems
                loading={loading}
                details={details}
                reloadOrder={reloadOrder}
              />
            ),
          },
        ]}
        setLabel={() => setLabel}
        customClassName='mb-4'
      />
    </>
  )
}

export default OrderLines
