import React, { useMemo } from 'react'
import { Table } from 'atoms'
import { getTenantType } from 'api/helper'

type ChildProps = {
  variant: any
  customClassName?: string
}

const SingleColumnItemTable: React.FC<ChildProps> = ({
  variant,
  customClassName,
}) => {
  const { isBrand, isRetailer } = getTenantType()
  const lineItemDetails = useMemo(() => {
    const tableData = variant.map(
      ({ id, name, brand_identifier, retailer_identifiers }) => {
        return {
          id,
          data: {
            Item: {
              onRender: () => (
                <div className='flex-column-start body1-regular'>
                  <span>{name}</span>
                  {isBrand && (
                    <span className='grey-500'>
                      Supplier SKU {brand_identifier}
                    </span>
                  )}
                  {isRetailer && retailer_identifiers.length > 0 && (
                    <span className='grey-500'>
                      Merchant SKU {retailer_identifiers[0].identifier}
                    </span>
                  )}
                </div>
              ),
            },
          },
        }
      }
    )
    return tableData
  }, [variant])

  const tableColumns = [
    {
      title: 'Item',
    },
  ]

  return (
    <div className={customClassName}>
      <Table columns={tableColumns} selectable={false} data={lineItemDetails} />
    </div>
  )
}

export default SingleColumnItemTable
