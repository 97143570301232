import { GridCol, GridRow } from 'atoms'
import { useEffect, useState } from 'react'
import PushNotification from './pushNotification'
import { getTenantType } from 'api/helper'
import { getNotification } from 'api/notifications'

interface AvailableNotification {
  action: string
  title: string
  text: string
  context: string[]
  cron: string
}

const availableNotifications: AvailableNotification[] = [
  {
    action: 'Orders Digest',
    title: 'Late Orders Digest',
    text: 'If you have any late purchase orders, the platform will send an email digest to the address or distribution list specified below.',
    cron: '0 2 * * *',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'Inventory Digest',
    title: 'Inventory Stockout Digest',
    text: 'If you have products with no inventory, the platform will send an email digest to the address or distribution list specified below.',
    cron: '0 20 * * *',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'Discontinued Digest',
    title: 'Items Discontinued Digest',
    text: 'If you have items that were discontinued, the platform will send an email digest to the address or distribution list specified below.',
    cron: '0 20 * * *',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'Order Sync Error',
    title: 'Orders Failed To Sync Digest',
    text: 'If you have orders that have errors syncing with platform accounts, the platform will send an email digest to the address or distribution list specified below.',
    cron: '0 20 * * *',
    context: ['retailer'],
  },
  {
    action: 'Late Invoice Digest',
    title: 'Late Invoice Digest',
    text: 'If you have orders that have late invoices, the platform will send an email digest to the address or distribution list specified below.',
    cron: '0 20 * * *',
    context: ['supplier', 'retailer'],
  },
]

type ChildProps = {
  pushNotifications: any
}

const DigestNotifications: React.FC<ChildProps> = ({ pushNotifications }) => {
  const [retailerNotifications, setRetailerNotifications] = useState([])
  const [supplierNotifications, setSupplierNotifications] = useState([])
  const [digestNotifications, setDigestNotifications] = useState([])

  const fetchNotifications = async () => {
    const {
      data: { results },
    } = await getNotification({ params: { has_recipient_email: 1 } })
    setDigestNotifications(results)
  }

  useEffect(() => {
    ;(async () => {
      await fetchNotifications()
    })()
  }, [])

  useEffect(() => {
    const merchantNotifications = availableNotifications.filter(
      (notification) => notification.context.includes('retailer')
    )
    const brandNotifications = availableNotifications.filter((notification) =>
      notification.context.includes('supplier')
    )
    setRetailerNotifications(merchantNotifications)
    setSupplierNotifications(brandNotifications)
  }, [pushNotifications])

  const { isBrand } = getTenantType()

  const notificationsToRender = isBrand
    ? supplierNotifications
    : retailerNotifications

  return (
    <div>
      <GridRow padding={false}>
        <GridCol lg={6} md={6} sm={12}>
          {notificationsToRender.map((item) => (
            <PushNotification
              key={item.action}
              action={item.action}
              header={item.title}
              description={item.text}
              crontab={item.cron}
              type='digest'
              customClassName='mt-4 mb-4'
              pushNotifications={digestNotifications}
              reloadNotifications={fetchNotifications}
            />
          ))}
        </GridCol>
      </GridRow>
    </div>
  )
}

export default DigestNotifications
