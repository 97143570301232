import React, { useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { BUTTONS, VARIANTS } from 'lib/constants'
import { acceptInvoice } from 'api/invoices'
import AcceptInvoices from './acceptInvoices'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setParams } from 'store/invoicesSlice'

type AcceptInvoicesModalProps = {
  invoice: any
  isBulkAction?: boolean
  showAcceptInvoiceModal: boolean
  setShowAcceptInvoiceModal: any
}

const AcceptInvoicesModal: React.FC<AcceptInvoicesModalProps> = ({
  invoice,
  isBulkAction,
  showAcceptInvoiceModal,
  setShowAcceptInvoiceModal,
}) => {
  const showToast = useToast()
  const dispatch = useDispatch()

  const params = useSelector((state: RootState) => state.invoices.params)

  const [saving, setSaving] = useState(false)

  const isActionable = useMemo(() => {
    return !invoice?.acknowledged_at
  }, [invoice])

  const accept = async () => {
    setSaving(true)
    try {
      await acceptInvoice({ id: invoice?.id })
      showToast({
        label: 'Invoice has been accepted successfully',
        isDismissable: true,
        id: '1',
      })
      // replace invoice params with a copy of itself to trigger a fetch and rerender of the list
      dispatch(setParams({ ...params }))
    } catch (error) {
      const message = error.response.data.detail
        ? error.response.data.detail
        : error
      showToast({
        label: message,
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
    setShowAcceptInvoiceModal(false)
    setSaving(false)
  }

  return (
    <Modal
      headerText={`Accept Invoice`}
      description=''
      size='small'
      onClose={() => setShowAcceptInvoiceModal(false)}
      isVisible={showAcceptInvoiceModal}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => setShowAcceptInvoiceModal(false),
          text: BUTTONS.CLOSE.LABEL,
          variant: VARIANTS.SECONDARY,
        },
        {
          dataTestid: '',
          onClick: accept,
          isLoading: saving,
          isDisabled: !isActionable,
          text: 'Accept Invoice',
          variant: VARIANTS.PRIMARY,
        },
      ]}
      dataTestid={'accept-invoice-modal'}
      onBackdropClick={() => setShowAcceptInvoiceModal(false)}
    >
      <AcceptInvoices invoice={invoice} />
    </Modal>
  )
}

export default AcceptInvoicesModal
