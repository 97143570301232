import { Badge } from 'atoms'
import { FC, useMemo } from 'react'

interface ProposalItemStatusProps {
  item: any
}

const ProposalItemStatusPresenter: FC<ProposalItemStatusProps> = ({ item }) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: 'Included',
      status: 'success',
    }
    if (item?.proposal_variant?.rejected_at !== null) {
      presenter.label = 'Removed'
      presenter.status = 'default'
    }
    return presenter
  }, [item])

  return (
    <Badge
      label={presenter.label}
      variant='primary'
      status={presenter.status}
    />
  )
}

export default ProposalItemStatusPresenter
