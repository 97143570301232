import API, { API_URL } from 'api'

export const getBrands = async ({ params }) => {
  return await API.get(API_URL.GET_BRANDS(), { params })
}

export const getContextDetails = async () => {
  return await API.get(API_URL.GET_CONTEXT_DETAIL())
}

export const patchContextDetails = async ({ body }) => {
  return await API.patch(API_URL.GET_CONTEXT_DETAIL(), body)
}

export const getContext = async () => {
  return await API.get(API_URL.GET_CONTEXT())
}
