import React, { useEffect, useState } from 'react'
import { Box } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import Input from 'atoms/input/input'
import RadioGroup from 'atoms/input/radioGroup'
import { useToast } from '@teamfabric/copilot-ui'
import { getPlatformAccounts } from 'api/platformAccount'
import { getTemplates } from 'api/templates'
import { getShopifyTemplate } from 'api/shopify'
import SelectConnection from 'molecules/connections/selectConnectionDropdown'

type SelectSupplierProps = {
  setSubmitData: (value: any) => void
}

const SelectSupplier: React.FC<SelectSupplierProps> = ({ setSubmitData }) => {
  const [platformAccount, setPlatformAccount] = useState({
    id: '',
  })
  const [exportTemplates, setExportTemplates] = useState([])
  const [connection, setConnection] = useState(dropDownInitialObj)
  const [exportTemplate, setExportTemplate] = useState(dropDownInitialObj)
  const [templates, setTemplates] = useState([])
  const [template, setTemplate] = useState(dropDownInitialObj)
  const [referenceNumber, setReferenceNumber] = useState(null)
  const [behavior, setBehavior] = useState({
    id: 'merge',
    label: 'Add new items & update existing items',
  })
  const [postProcessing, setPostProcessing] = useState('')
  const [loading, setLoading] = useState(false)
  const behaviorOptions = [
    {
      id: 'merge',
      label: 'Add new items & update existing items',
    },
    {
      id: 'update',
      label: 'Update existing items only',
    },
  ]

  const postProcessingOptions = [
    {
      'data-testid': '',
      'disabled': false,
      'id': 1,
      'label': 'None',
      'value': 'none',
    },
    {
      'data-testid': '',
      'disabled': false,
      'id': 1,
      'label': 'Publish products to my storefront platform',
      'value': 'publish',
    },
  ]
  const showToast = useToast()

  const loadPlatformConnections = async () => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await getPlatformAccounts({
        params: {
          code: 'shopify',
        },
      })
      if (results.length === 1) {
        setPlatformAccount(results[0])
        loadExportTemplates(results[0].id)
      }
    } catch (error) {
      showToast({
        label: 'Error Loading Platform Connections',
        isDismissable: true,
        id: 'error-platform',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const loadExportTemplates = async (platformAccountId: string) => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await getShopifyTemplate({
        platformAccountId,
        params: { direction: 'export' },
      })
      const formattedData = results.map(({ id, name }) => ({
        id,
        label: name,
      }))
      setExportTemplates(formattedData)
    } catch (error) {
      showToast({
        label: 'Error Loading Export Templates',
        isDismissable: true,
        id: 'error-export-template',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const loadTemplates = async () => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await getTemplates({
        params: { direction: 'import', data_type: 'products', limit: 100 },
      })
      const formattedData = results.map(({ id, name }) => ({ id, label: name }))
      setTemplates(formattedData)
    } catch (error) {
      showToast({
        label: 'Error Loading Templates',
        isDismissable: true,
        id: 'error-template',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await Promise.all([loadPlatformConnections(), loadTemplates()])
    })()
  }, [])

  useEffect(() => {
    if (!connection || !template) {
      return
    }
    setSubmitData({
      connectionBrand: connection,
      templateId: template.id,
      referenceNumber,
      behavior: behavior.id,
      postProcessing,
      exportTemplateId: exportTemplate.id,
    })
  }, [
    connection,
    template,
    behavior,
    referenceNumber,
    postProcessing,
    exportTemplate,
  ])

  return (
    <Box
      border={{
        color: '#E7E7E7',
        radius: 2,
        width: '2px',
      }}
      padding={{ left: 4, right: 4, top: 4, bottom: 4 }}
    >
      <p>To get started, select a connection and file template below:</p>
      <SelectConnection
        width='70%'
        label='Connection'
        placeholder='Select connection'
        handleUpdate={({ brand }) => {
          setConnection({ id: brand.id, label: brand.name })
        }}
      />
      <div className='mt-3 mb-3'>
        <Dropdown
          required
          label='File Template'
          width='70%'
          value={template}
          onChange={(value) => setTemplate(value)}
          options={templates}
          placeholder='Select template'
        />
      </div>
      <div className='mb-3'>
        <Dropdown
          label='Purpose'
          width='70%'
          value={behavior}
          options={behaviorOptions}
          onChange={(e: any) => {
            setBehavior(e)
          }}
        />
      </div>
      <div className='mb-3'>
        <Input
          label='Reference Number'
          width='70%'
          inputProps={{
            placeholder: 'Reference Number',
            onChange: ({ target: { value } }) => {
              setReferenceNumber(value)
            },
          }}
        />
      </div>
      {!loading && platformAccount.id !== '' && (
        <RadioGroup
          label='Post-Processing Options'
          name='Post-Processing'
          value={postProcessing}
          onChange={({ target: { value } }) => {
            setPostProcessing(value)
          }}
          options={postProcessingOptions}
        />
      )}
      {postProcessing === 'publish' && (
        <div className='mt-3'>
          <Dropdown
            label='Export Template'
            width='70%'
            value={exportTemplate}
            options={exportTemplates}
            onChange={(e: any) => {
              setExportTemplate(e)
            }}
          />
        </div>
      )}
    </Box>
  )
}

export default SelectSupplier
