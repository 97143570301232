import React, { useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import {
  getConnectionCredentials,
  postConnectionCredential,
  patchConnectionCredential,
  deleteConnectionCredential,
} from 'api/connection'
import Dropdown from 'atoms/dropdown/dropdown'
import MultiLineTextArea from 'atoms/textField/multiLineTextArea'
import { capitalize } from 'lodash'

type formDataTypes = {
  name: string
  value: string
  code: string
  id: number
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues?: formDataTypes
  action: string
}

const ConnectionAttributesModal: React.FC<ChildProps> = ({
  initialValues,
  action,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [options, setOptions] = useState([])
  const [selectedAttribute, setSelectedAttribute] = useState({
    id: null,
    label: '',
  })
  const [value, setValue] = useState('')

  useEffect(() => {
    ;(async () => {
      await fetchAttributes()
    })()
  }, [])

  const fetchAttributes = async () => {
    const { data } = await getConnectionCredentials({})
    setData(data.results)
    setOptions(data.results.map((el) => ({ id: el.code, label: el.name })))
    if (['edit', 'delete'].includes(action) && initialValues) {
      const selected = data?.results.find(
        (el) => el.code === initialValues.code
      )
      setSelectedAttribute({
        id: selected.code,
        label: selected.name,
      })
      setValue(initialValues.value)
    }
    setLoading(false)
  }

  const SaveCredential = async () => {
    let body = {
      name: selectedAttribute.label,
      code: selectedAttribute.id,
      value: value,
    }
    try {
      setLoading(true)
      if (action === 'add') {
        await postConnectionCredential({
          connectionId: props.connectionId,
          body: body,
        })
      } else if (action === 'edit') {
        await patchConnectionCredential({
          connectionId: props.connectionId,
          credentialId: initialValues.id,
          body: body,
        })
      } else if (action === 'delete') {
        body['delete'] = 1
        await deleteConnectionCredential({
          connectionId: props.connectionId,
          credentialId: initialValues.id,
          body: body,
        })
      }
    } catch (error) {
      console.error('Error creating connection credential:', error)
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText={`${capitalize(action)} Connection Attribute`}
        description={
          action === 'delete'
            ? `Are you sure you want to delete Connection Attribute: ${selectedAttribute.label}: ${value}`
            : null
        }
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => SaveCredential(),
            text: action === 'delete' ? 'Delete' : 'Save',
            variant: action === 'delete' ? 'destructive' : 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <>
          <Dropdown
            width='100%'
            options={options}
            value={selectedAttribute}
            required
            placeholder='Select Attribute'
            label='Select Attribute'
            onChange={(option: any) => {
              setSelectedAttribute(option)
            }}
          />
          <div className='divider-4'></div>
          <MultiLineTextArea
            label='Attribute Value'
            width='100%'
            inputProps={{
              value: value,
              onChange: (e) => setValue(e.target.value),
              placeholder: 'Attribute value...',
            }}
          />
        </>
      </Modal>
    </>
  )
}

export default ConnectionAttributesModal
