import { Badge } from 'atoms'
import { FC, useMemo } from 'react'

interface UpdateCostsStatusProps {
  updateStatus: any
}

const UpdateCostsStatusPresenter: FC<UpdateCostsStatusProps> = ({
  updateStatus,
}) => {
  const presenter = useMemo(() => {
    let presenter = {
      label: 'Queued',
      status: 'info',
    }

    if (updateStatus === 'success') {
      presenter.label = 'Success'
      presenter.status = 'success'
    } else if (updateStatus === 'failure') {
      presenter.label = 'Failed'
      presenter.status = 'error'
    } else if (updateStatus === 'processing') {
      presenter.label = 'Processing'
      presenter.status = 'alert'
    }
    return presenter
  }, [updateStatus])

  return (
    <Badge
      label={presenter.label}
      variant='primary'
      status={presenter.status}
    />
  )
}

export default UpdateCostsStatusPresenter
