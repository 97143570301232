import React, { useState, useEffect } from 'react'
import { getInventory, getInventoryHistory } from 'api/inventory'
import Table from 'atoms/table/table'
import Tab from 'atoms/tab/tab'
import VariantItem from 'molecules/lineItem/variantItem'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import { capitalize } from 'lodash'
import Availability from 'molecules/inventory/availability'
import OnHand from 'molecules/inventory/onHand'
import EstimatedAvailabilityDate from 'molecules/inventory/estimatedAvailabilityDate'
import DateAndTimeOnTwoLines from 'molecules/dateAndTimeOnTwoLines'

type ChildProps = {
  variant: any
}

const InventoryDetailsModal: React.FC<ChildProps> = (props) => {
  const [inventoryDetailData, setInventoryDetailData] = useState<any[]>([])
  const [inventoryLocationData, setInventoryLocationData] = useState<any[]>([])
  const [inventoryHistoryEventsCount, setInventoryHistoryEventsCount] =
    useState<number>(0)
  const [inventoryHistoryData, setInventoryHistoryData] = useState<any[]>([])
  const [hasFetchedInventoryDetails, setHasFetchedInventoryDetails] =
    useState(false)
  const [hasFetchedInventoryHistory, setHasFetchedInventoryHistory] =
    useState(false)
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState(0)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (tab === 0 && !hasFetchedInventoryDetails) {
        const { data } = await getInventory({ variantId: props.variant?.id })
        formatInventoryDetailData(data)
        formatInventoryLocationData(data)
        setHasFetchedInventoryDetails(true)
      } else if (tab === 1 && !hasFetchedInventoryHistory) {
        const { data } = await getInventoryHistory({
          variantId: props.variant?.id,
        })
        setInventoryHistoryEventsCount(data?.count)
        formatInventoryHistoryData(data?.results)
        setHasFetchedInventoryHistory(true)
      }
      setLoading(false)
    })()
  }, [tab])

  const formatInventoryDetailData = ({
    variant,
    discontinued,
    inventory,
    inventory_policy,
    estimated_availability_date,
    inventory_updated_at,
    inventory_last_submitted_at,
  }) => {
    let formattedData = [
      {
        label: 'Item ID',
        value: variant?.id,
      },
      {
        label: 'Item SKU',
        value: variant?.brand_identifier,
      },
      {
        label: 'UPC',
        value: variant?.upc ? variant.upc : '--',
      },
      {
        label: 'Policy',
        value: capitalize(inventory_policy),
      },
      {
        label: 'Availability',
        value: {
          onRender: () => (
            <Availability inventory={inventory} discontinued={discontinued} />
          ),
        },
      },
      {
        label: 'On Hand',
        value: {
          onRender: () => <OnHand inventory={inventory} />,
        },
      },
      ,
      {
        label: 'Last Update',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate date={inventory_updated_at} />
          ),
        },
      },
      ,
      {
        label: 'Last Submission',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate date={inventory_last_submitted_at} />
          ),
        },
      },
    ]

    // if no iventory, insert an estimated return line to a specific spot
    if (!inventory) {
      formattedData.splice(6, 0, {
        label: 'Estimated Return',
        value: {
          onRender: () => (
            <EstimatedAvailabilityDate
              date={estimated_availability_date}
              isEstimatedReturn={true}
            />
          ),
        },
      })
    }

    setInventoryDetailData(formattedData)
  }

  const formatInventoryLocationData = ({ locations }) => {
    let formattedData = []
    if (locations?.length) {
      locations.forEach((location) => {
        formattedData.push({
          label: `${location.nickname}`,
          value: `${location.inventory}`,
        })
      })
    }
    setInventoryLocationData(formattedData)
  }

  const historyInventoryColumns = [
    {
      sortale: false,
      title: 'Date',
    },
    {
      sortale: false,
      title: 'Event',
    },
    {
      sortale: false,
      title: 'Inventory',
      width: '100px',
    },
  ]

  const formatInventoryHistoryData = (events) => {
    const formattedData = events.map(({ id, updated_at, event, inventory }) => {
      return {
        id,
        data: {
          Date: {
            onRender: () => <DateAndTimeOnTwoLines date={updated_at} />,
          },
          Event: event,
          Inventory: inventory ? inventory : '0',
        },
      }
    })
    setInventoryHistoryData(formattedData)
  }

  return (
    <>
      <VariantItem variant={props.variant} />
      <Tab
        data={[{ label: 'Current' }, { label: 'History' }]}
        setLabel={setTab}
        component={
          <>
            {tab === 0 ? (
              <>
                <div className='mt-3 mb-3'>
                  <ModalCard2Col
                    loading={loading}
                    header='Inventory details'
                    data={inventoryDetailData}
                  />
                </div>
                <ModalCard2Col
                  loading={loading}
                  header='Inventory by location'
                  data={inventoryLocationData}
                />
              </>
            ) : (
              <div className='mt-3'>
                <Table
                  columns={historyInventoryColumns}
                  data={inventoryHistoryData}
                  loading={loading}
                  activePage={1}
                  selectable={false}
                  totalRecords={inventoryHistoryEventsCount}
                />
              </div>
            )}
          </>
        }
      />
    </>
  )
}

export default InventoryDetailsModal
