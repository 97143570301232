import React from 'react'
import { Tooltip as ToolTipUI } from '@teamfabric/copilot-ui'

type ToolTipProps = {
  isVisible?: boolean
  showOnDisabled?: boolean
  text: string
  children: any
  placement?: any
  theme?: any
  showArrow?: boolean
}

const ToolTip: React.FC<ToolTipProps> = ({ ...props }) => {
  return <ToolTipUI {...props}></ToolTipUI>
}

export default ToolTip
