import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { getUsers } from 'api/user'

type ChildProps = {
  handleUpdate: (data: any) => void
  fieldLabel?: string
  fieldPlaceholder?: string
  selectedUser?: any
}

const SelectUser: React.FC<ChildProps> = ({
  fieldLabel,
  fieldPlaceholder,
  handleUpdate,
  selectedUser,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(selectedUser)
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const {
        data: { results },
      } = await getUsers({
        params: {
          mini: 1,
          pagination: 0,
          order_by: 'first_name',
          type: 'standard',
          status: 'active,invited',
        },
      })
      const formattedUser = results.map(({ id, first_name, last_name }) => ({
        id,
        label: `${first_name} ${last_name}`,
      }))
      setOptions(formattedUser)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!initialEffectRun && selectedUser?.id) {
      setSelectedOption(selectedUser)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, selectedUser])

  return (
    <Dropdown
      required
      label={fieldLabel || 'User'}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder={fieldPlaceholder || 'Select user'}
      disabled={loading}
    />
  )
}

export default SelectUser
