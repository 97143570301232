import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import { getLocations, patchLocation, postLocation } from 'api/locations'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentStep } from 'store/onboardingSlice'
import CardLoading from 'molecules/loading/cardLoading'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
} from 'molecules/address/constants'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import AddressForm2 from 'molecules/address/formWithStreet2'
import { completeTask, isAddressNoNeedToUpdate } from './utils'
import { PATHS } from 'routes/pageRoutes'

type LocationProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
  reseting: boolean
}

const Locations: React.FC<LocationProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
  reseting,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()

  const info = useSelector((state: RootState) => state.onboarding.info)

  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [address, setAddress] = useState<AddressFormDataType>(
    AddressFormDefaultValues
  )

  const [initValues, setInitValues] = useState({
    nickname: '',
    name1: '',
    street1: '',
    street2: '',
    postal_code: '',
    city: '',
    province: '',
    country: '',
    email: '',
    phone1: '',
  })

  const ADDRESS_TYPE = 'warehouse'
  const TASK_KEY = 'LOCATIONS'

  const updateInfo = {
    processNum: 2,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: true,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: true,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: false,
    completed: false,
  }

  const loadWarehouseLocation = async () => {
    try {
      setLoading(true)
      const { data } = await getLocations({ params: { type: ADDRESS_TYPE } })
      setLocation(data?.results[0])
      if (data?.results[0]) {
        setInitValues({
          nickname: data.results[0].nickname,
          name1: data.results[0].address.name1,
          street1: data.results[0].address.street1,
          street2: data.results[0].address.street2,
          postal_code: data.results[0].address.postal_code,
          city: data.results[0].address.city,
          province: data.results[0].address.province,
          country: data.results[0].address.country,
          email: data.results[0].address.email,
          phone1: data.results[0].address.phone1,
        })
      }
    } catch (error) {
      showToast({
        label: "Couldn't load",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  const submitBusinessAddress = async () => {
    try {
      if (isAddressNoNeedToUpdate({ initValues, address }) || !isReady) {
        return
      }
      const copied = structuredClone(address)
      delete copied['nickname']
      if (location?.id) {
        const body = {
          address: copied,
          nickname: address.nickname,
          id: location.id,
        }
        await patchLocation({ id: location.id, body })
      } else {
        const body = {
          address: copied,
          nickname: address.nickname,
          id: null,
          type: ADDRESS_TYPE,
        }
        await postLocation({ body })
      }
    } catch (error) {
      showToast({
        label: "Couldn't save location",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    }
  }

  useEffect(() => {
    setIsNextButtonDisabled(true)
    ;(async () => {
      await loadWarehouseLocation()
    })()
  }, [])

  useEffect(() => {
    setIsNextButtonDisabled(!isReady)
  }, [address])

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      await Promise.all([
        submitBusinessAddress(),
        completeTask({ info, key: TASK_KEY, dispatch, updateInfo }),
      ])
      dispatch(
        setCurrentStep({
          value: ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME,
          parent: ONBOARDING_TASKS.INFO.VALUE,
        })
      )
    })()
  }, [isNextClicked])

  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      if (!isReady) {
        navigate(PATHS.Onboarding)
      }
      await Promise.all([
        submitBusinessAddress(),
        completeTask({ info, key: TASK_KEY, dispatch, updateInfo }),
      ])
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  const customLabels = [
    'Location Nickname',
    'Country',
    'Business Name',
    'Street Address',
    'City',
    'State / Province',
    'Zip / Postal Code',
    'Contact Phone',
    'Conract Email',
  ]

  return (
    <>
      {(loading || reseting) && (
        <CardLoading
          label={ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.NAME}
          num={6}
        />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.NAME}
              h2Text='Enter the address of primary warehouse or distribution center. Additional locations can be added in settings at any time.'
            />
          }
          bodyContent={
            <div style={{ width: '70%' }}>
              {!loading && (
                <AddressForm2
                  handleUpdate={(data) => setAddress(data)}
                  showNickName
                  showEmail
                  showPhone
                  handleIsReady={(value) => setIsReady(value)}
                  initialValues={initValues}
                  customLabels={customLabels}
                />
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default Locations
