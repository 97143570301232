import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCurrentStep,
  setIntegration,
  setNextAvaiableStep,
} from 'store/onboardingSlice'
import CardLoading from 'molecules/loading/cardLoading'
import { patchAssignTask, putCompleteTask } from 'api/onboarding'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import { PATHS } from 'routes/pageRoutes'

type CompletesetupProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const CompleteSetup: React.FC<CompletesetupProps> = ({
  setIsNextButtonDisabled,
  isNextClicked,
  setIsNextClicked,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()
  const state = useSelector((state: RootState) => state)
  const integration = state.onboarding.integration
  const retailerName = state.onboarding.retailerName
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsNextButtonDisabled(false)
    })()
  }, [])

  const getCompletedProcessNum = () => {
    switch (integration.selectedMethod) {
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.EDI
        .TASK_NAME:
        return 8
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
        .BIGCOMMERCE.TASK_NAME:
        return 3
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
        .SHIPSTATION.TASK_NAME:
        return 4
    }
  }

  const updateSlices = () => {
    dispatch(
      setIntegration({
        taskId: integration.taskId,
        processNum: getCompletedProcessNum(),
        introDone: true,
        completed: true,
        selectedIntegrationInfo: integration.selectedIntegrationInfo,
        selectedMethod: integration.selectedMethod,
      })
    )
    dispatch(
      setCurrentStep({
        value: ONBOARDING_TASKS.COMPLETE.VALUE,
        parent: ONBOARDING_TASKS.COMPLETE.VALUE,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.COMPLETE.VALUE))
  }

  const updateIntegrationCompleteTask = async () => {
    try {
      setLoading(true)
      if (
        integration.selectedMethod ===
        ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.API
          .TASK_NAME
      ) {
        // save API completed_at in integration method
        const body = {
          info: {
            api: new Date().toJSON(),
          },
        }
        await patchAssignTask({ taskId: integration.taskId, body })
      } else {
        // API doesn't call complete task
        await putCompleteTask({
          taskId:
            integration.selectedIntegrationInfo.taskIds[
              integration.selectedMethod
            ],
        })
      }
    } catch (error) {
      showToast({
        label: "Couldn't complete task",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      await updateIntegrationCompleteTask()
      navigate(ONBOARDING_TASKS.COMPLETE.LINK)
      updateSlices()
    })()
  }, [isNextClicked])

  // save and exit button clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      await updateIntegrationCompleteTask()
      updateSlices()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {loading && <CardLoading label={currentTask.NAME} num={3} />}
      {!loading && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <h1 className='h5 mb-2'>{currentTask.NAME}</h1>
              <p className='body1-regular'>
                All transaction integration setup steps were successful.
                Complete setup task to begin receiving orders from{' '}
                {retailerName}.
              </p>
            </div>
          }
          bodyContent=''
        />
      )}
    </>
  )
}

export default CompleteSetup
