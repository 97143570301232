import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { getReturns } from 'api/returns'
import ReturnsTable from '../components/table/return/returnsTable'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchBar from 'molecules/search/searchBarTable'
import { PAGE_TYPE, SEARCH } from 'lib/constants'
import Filters from 'modules/orders/components/filters/returns'
import { setSearch } from 'store/returnsSlice'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'

const OrderReturnsTemplate: React.FC = () => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  const params = useSelector((state: RootState) => state.returns.params)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getReturns({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeaderComponent h1Text='Returns' customClassName='mb-4' />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.GENERIC}
            setSearch={setSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <Filters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.RETURNS}
          />
          <ReturnsTable
            data={tableData}
            loading={loading}
            totalRows={totalRows}
            currentPage={params?.page}
            currentSortBy={params?.order_by}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OrderReturnsTemplate
