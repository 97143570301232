import React from 'react'
import { Drawer } from '@teamfabric/copilot-ui'
import IssueDetails from './issues/details'

type ChildProps = {
  selectedLogData: any
  handleCloseDrawer: () => void
  loadProposalIssues?: () => void
}

const IssueDetailsDrawer: React.FC<ChildProps> = ({
  selectedLogData,
  handleCloseDrawer,
  loadProposalIssues,
}) => {
  return (
    <Drawer isVisible dataTestId='proposal-issue-drawer'>
      <IssueDetails
        selected={selectedLogData}
        handleCloseDrawer={handleCloseDrawer}
        loadIssues={loadProposalIssues}
      />
    </Drawer>
  )
}

export default IssueDetailsDrawer
