//importing directly from the lib as the ToolTip doesn't render otherwise
import { InteractiveIcon } from '@teamfabric/copilot-ui'
import { Box, ToolTip } from 'atoms'
import { FC, useMemo } from 'react'

interface CancellationsActionsProps {
  isRetailer: boolean
  cancellation: any
  setShowAcknowledgeCancellationModal: any
  setCancellation: any
}

const CancellationsActionsMenu: FC<CancellationsActionsProps> = ({
  isRetailer,
  cancellation,
  setShowAcknowledgeCancellationModal,
  setCancellation,
}) => {
  const getDisabledInfo = (isRetailer, cancellation) => {
    let disabledInfo = {
      text: '',
      isDisabled: false,
    }
    if (cancellation?.acknowledged_at != null) {
      disabledInfo.text = 'Cancellation has already been acknowledged'
      disabledInfo.isDisabled = true
    } else if (isRetailer && cancellation.canceled_by === 'retailer') {
      disabledInfo.text = 'Cancellation was created by your organization'
      disabledInfo.isDisabled = true
    } else if (!isRetailer && cancellation.canceled_by === 'brand') {
      disabledInfo.text = 'Cancellation was created by your organization'
      disabledInfo.isDisabled = true
    }

    return disabledInfo
  }

  const options = useMemo(() => {
    const disabledInfo = getDisabledInfo(isRetailer, cancellation)

    let options = [
      {
        onClick: () => {
          setShowAcknowledgeCancellationModal(true)
          setCancellation(cancellation)
        },
        icon: 'Check',
        tooltipText: 'Acknowledge',
        disabledTooltipText: disabledInfo.text,
        isDisabled: disabledInfo.isDisabled,
      },
    ]
    return options
  }, [isRetailer, cancellation])

  return (
    <Box flex gap={16}>
      {options.map((option, index) => (
        <div key={index}>
          <ToolTip
            text={
              option.isDisabled
                ? option.disabledTooltipText
                : option.tooltipText
            }
          >
            <InteractiveIcon
              onClick={() => option.onClick()}
              iconName={option.icon}
              size={16}
              disabled={option.isDisabled}
            />
          </ToolTip>
        </div>
      ))}
    </Box>
  )
}

export default CancellationsActionsMenu
