import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import {
  Box,
  GridCol,
  GridRow,
  Input,
  InputStepper,
  MultiLineTextArea,
} from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { theme, useToast } from '@teamfabric/copilot-ui'
import CardLoading from 'molecules/loading/cardLoading'
import { getRuleGroupById, patchRuleGroup } from 'api/rulesets'
import RulesTable from '../components/rulesets/rulesTable'

const ProductRuleGroupDetails: FC = () => {
  const { id, ruleGroupId } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [saving, setSaving] = useState(false)
  const [position, setPosition] = useState('')

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.rulesets({ page: 'editRuleGroup', id: id })
        ),
      0
    )
  }, [])

  const onSave = async () => {
    const body = {
      name: name,
      description: description,
      position: position,
    }
    try {
      setSaving(true)
      await patchRuleGroup({ ruleGroupId, body })
      showToast({
        label: 'Rule Group updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the rule group: ${errorMessage}`
          : 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchRuleGroup = async () => {
    const { data } = await getRuleGroupById({ ruleGroupId: ruleGroupId })
    setName(data.name)
    setDescription(data.description)
    setPosition(data.position)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchRuleGroup()
      })()
    }
  }, [id])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        loading={loading}
        h1Text={'Rule Group Settings'}
        h2Text={'Configure basic settings for this rule group.'}
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate(`/settings/rulesets/${id}`),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !name,
          },
        ]}
      />
      {loading ? (
        <CardLoading />
      ) : (
        <Box
          border={{ radius: 2, width: '2px', color: theme.color.grey[200] }}
          padding={{ top: 4, bottom: 4, left: 4, right: 4 }}
        >
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Input
                width='100%'
                label='Name'
                required
                inputProps={{
                  value: name,
                  onChange: ({ target: { value } }) => setName(value),
                }}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <InputStepper
                label='Position'
                width='50%'
                max={100}
                min={1}
                onValueChange={(newVal) => {
                  const parsedValue =
                    typeof newVal === 'string' ? newVal : newVal.toString()
                  setPosition(parsedValue)
                }}
                stepSize={1}
                value={parseInt(position)}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <MultiLineTextArea
                label='Description'
                width='100%'
                inputProps={{
                  value: description,
                  onChange: ({ target: { value } }) => setDescription(value),
                  placeholder: 'Description',
                }}
              />
            </GridCol>
          </GridRow>
          <br />
        </Box>
      )}
      <RulesTable />
    </Box>
  )
}

export default ProductRuleGroupDetails
