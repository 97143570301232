import React, { useState, useMemo } from 'react'
import { Box, Input, InputStepper, Modal, MultiLineTextArea } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { postRuleGroup } from 'api/rulesets'
import { useParams } from 'react-router-dom'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  loadRuleGroups: (quiet?: boolean) => void
  ruleGroups: any[]
}

const CreateRuleGroupModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  loadRuleGroups,
  ruleGroups,
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [position, setPosition] = useState('')

  const onCreate = async () => {
    const body = {
      name: name,
      description: description,
      position: position,
      is_required: false,
      ruleset: {
        id: id,
      },
    }
    try {
      setSaving(true)
      await postRuleGroup({ body })
      setShowModal(false)
      loadRuleGroups()
      showToast({
        label: 'Rule Group created successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error : ${errorMessage}`
          : 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      resetForm()
    }
  }

  const resetForm = () => {
    setName('')
    setDescription('')
    setPosition('')
  }

  const nextAvailablePosition = useMemo(() => {
    if (ruleGroups.length === 0) return 1
    const positions = ruleGroups.map((rule) => rule.position)
    const maxPosition = Math.max(...positions)
    return maxPosition + 1
  }, [ruleGroups])

  const isUniquePosition = useMemo(() => {
    if (ruleGroups && ruleGroups.length > 0) {
      return !ruleGroups.some((group) => group.position === parseInt(position))
    }
  }, [position])

  const isReady = useMemo(() => {
    return name !== ''
  }, [name])

  return (
    <Modal
      headerText='Create Rule groups'
      description=''
      size='small'
      onClose={() => {
        setShowModal(false)
        resetForm()
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            setShowModal(false)
            resetForm()
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onCreate(),
          text: 'Create',
          variant: 'primary',
          isDisabled: !isReady || saving || !isUniquePosition,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => {
        setShowModal(false)
        resetForm()
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Name'
          required
          inputProps={{
            value: name,
            onChange: ({ target: { value } }) => setName(value),
            placeholder: 'Name',
          }}
        />
        <InputStepper
          label='Position'
          width='50%'
          max={100}
          min={nextAvailablePosition}
          onValueChange={(newVal) => {
            const parsedValue =
              typeof newVal === 'string' ? newVal : newVal.toString()
            setPosition(parsedValue)
          }}
          stepSize={1}
          value={parseInt(position)}
          messageType={!isUniquePosition ? 'error' : null}
          message={
            !isUniquePosition &&
            `A rule_group with position ${position} already exists on ruleset_id: ${id}!`
          }
        />
        <MultiLineTextArea
          label='Description'
          width='100%'
          inputProps={{
            value: description,
            onChange: ({ target: { value } }) => setDescription(value),
            placeholder: 'Description',
          }}
        />
      </Box>
    </Modal>
  )
}

export default CreateRuleGroupModal
