import React, { useState, useEffect, useMemo } from 'react'
import { Box, Checkbox, Input, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectAttribute from 'molecules/selectDropdowns/selectAttribute'
import SelectTemplateMappingsTarget from 'molecules/selectDropdowns/selectTemplateMappingsTarget'
import { useParams } from 'react-router-dom'
import {
  createTemplateMapping,
  deleteTemplateMapping,
  updateTemplateMapping,
} from 'api/templates'

interface BaseProps {
  showModal: boolean
  setShowModal: (show: boolean) => void
  type: 'Edit' | 'Add'
  loadMappings: (quiet?: boolean) => void
}

type ChildProps = BaseProps &
  (
    | { type: 'Add'; selectedMapping?: never }
    | { type: 'Edit'; selectedMapping: any }
  )

const MappingModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  loadMappings,
  type,
  selectedMapping,
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [headerName, setHeaderName] = useState('')
  const [selectedAttribute, setSelectedAttribute] = useState(dropDownInitialObj)
  const [selectedTarget, setSelectedTarget] = useState(dropDownInitialObj)
  const [priority, setPriority] = useState('')
  const [isRequired, setIsRequired] = useState(false)

  useEffect(() => {
    if (type === 'Edit' && selectedMapping) {
      setHeaderName(selectedMapping?.title)
      setSelectedAttribute(selectedMapping?.attribute)
      setPriority(selectedMapping?.priority)
      setIsRequired(selectedMapping?.is_required)
    }
  }, [selectedMapping, type])

  const onCreate = async () => {
    try {
      await createTemplateMapping({ id, body })
      setShowModal(false)
      showToast({
        label: 'Template mapping created successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const onUpdate = async () => {
    const mappingId = selectedMapping.id
    try {
      await updateTemplateMapping({ id, mappingId, body })
      setShowModal(false)
      showToast({
        label: 'Template mapping updated successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const onDelete = async () => {
    setSaving(true)
    const mappingId = selectedMapping.id
    try {
      await deleteTemplateMapping({ id, mappingId, body })
      setShowModal(false)
      showToast({
        label: 'Template mapping deleted successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
      loadMappings()
      resetFields()
    }
  }

  const onSave = async () => {
    setSaving(true)
    try {
      if (type === 'Edit') {
        await onUpdate()
      } else if (type === 'Add') {
        await onCreate()
      }
    } finally {
      loadMappings()
      resetFields()
    }
  }

  const resetFields = () => {
    setHeaderName('')
    setPriority('')
    setIsRequired(false)
  }

  const isReady = useMemo(() => {
    return (
      headerName !== '' &&
      selectedAttribute?.id &&
      selectedTarget?.id &&
      priority
    )
  }, [headerName, selectedAttribute, selectedTarget, priority])

  const body = useMemo(() => {
    if (isReady) {
      return {
        attribute: { id: selectedAttribute.id },
        title: headerName,
        priority: priority,
        target: selectedTarget?.value,
        is_required: isRequired,
      }
    }
  }, [
    headerName,
    selectedAttribute,
    selectedTarget,
    priority,
    isRequired,
    isReady,
  ])

  return (
    <Modal
      headerText={`${type} Template Mapping`}
      description=''
      size='small'
      onClose={() => {
        setShowModal(false)
        resetFields()
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            setShowModal(false)
            resetFields()
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        type === 'Edit' && {
          dataTestid: 'delete-modal-button',
          onClick: () => onDelete(),
          text: 'Delete',
          variant: 'destructive',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: 'Save',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ].filter(Boolean)}
      dataTestid={''}
      onBackdropClick={() => {
        resetFields()
        setShowModal(false)
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Template Column Header'
          required
          inputProps={{
            value: headerName,
            onChange: ({ target: { value } }) => setHeaderName(value),
          }}
        />
        <SelectAttribute
          handleUpdate={(option) => {
            setSelectedAttribute(option)
          }}
          fieldLabel='Select platform attribute'
          selectedAttributeId={selectedMapping?.attribute.id}
        />
        <SelectTemplateMappingsTarget
          handleUpdate={(option) => {
            setSelectedTarget(option)
          }}
          fieldLabel='Select target'
          targetValue={selectedMapping?.target}
        />
        <Input
          width='100%'
          label='Priority'
          required
          inputProps={{
            value: priority,
            onChange: ({ target: { value } }) => setPriority(value),
          }}
        />
        <Checkbox
          label={'Mark as required?'}
          name={''}
          value={''}
          checked={isRequired}
          disabled={false}
          onChange={({ target: { checked } }) => {
            setIsRequired(checked)
          }}
        />
      </Box>
    </Modal>
  )
}

export default MappingModal
