import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { DropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  handleUpdate?: (data: any) => void
  fieldLabel?: string
  typeValue?:
    | 'single_line_text_field'
    | 'money'
    | 'dimension'
    | 'url'
    | 'boolean'
    | 'multi_line_text_field'
    | 'date_time'
}
const targetOptions: DropDownInitialObj[] = [
  {
    id: '1',
    label: 'Boolean',
    value: 'boolean',
  },
  {
    id: '2',
    label: 'Date time',
    value: 'date_time',
  },
  {
    id: '3',
    label: 'Dimension',
    value: 'dimension',
  },
  {
    id: '4',
    label: 'Money',
    value: 'money',
  },
  {
    id: '5',
    label: 'Multi line text field',
    value: 'multi_line_text_field',
  },
  {
    id: '6',
    label: 'Single line text field',
    value: 'single_line_text_field',
  },
  {
    id: '7',
    label: 'URL',
    value: 'url',
  },
]

const SelectTemplateMetafieldType: React.FC<ChildProps> = ({
  handleUpdate,
  fieldLabel,
  typeValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    targetOptions.find((el) => el.value === typeValue)
  )
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  useEffect(() => {
    if (!initialEffectRun && typeValue) {
      const target = targetOptions.find((el) => el.value === typeValue)
      handleUpdate(target)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, typeValue])

  return (
    <Dropdown
      dataTestid='metafield-type-dropdown'
      required
      label={fieldLabel || ''}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        const selected = targetOptions.find((el) => el.id === option.id)
        setSelectedOption(selected)
        handleUpdate(option)
      }}
      options={targetOptions}
      placeholder='Select type'
    />
  )
}

export default SelectTemplateMetafieldType
