import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import Dropdown from 'atoms/dropdown/dropdown'
import { getUsers } from 'api/user'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
  isRetailer: boolean
}

const ChangeOwnerModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>()
  const [userOptions, setUserOptions] = useState(null)

  const showToast = useToast()

  const fetchUsers = async () => {
    setLoading(true)
    const { data } = await getUsers({
      params: {
        mini: 1,
        pagination: 0,
        order_by: 'first_name',
        type: 'standard',
        status: 'active,invited',
      },
    })
    const options = []
    if (data.count > 0) {
      data.results.map((el) => {
        options.push({
          id: el.id,
          label: `${el.first_name} ${el.last_name}`,
        })
      })
    }
    setUserOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    if (props.showModal) {
      fetchUsers()
    }
  }, [props.showModal])

  const requestBody = useMemo(() => {
    if (props.proposal && props.proposal.id && selectedUser) {
      if (props.isRetailer && 'retailer' in props.proposal.sender) {
        return {
          sender: { user_id: selectedUser.id },
        }
      } else if (props.isRetailer && 'retailer' in props.proposal.recipient) {
        return {
          recipient: { user_id: selectedUser.id },
        }
      }
    }
  }, [selectedUser, props.isRetailer, props.proposal])

  const changeOwner = async () => {
    try {
      setLoading(true)
      await patchProposal({
        id: props.proposal?.id,
        body: requestBody,
      })
      showToast({
        label: `Your proposal has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error updating the proposal: ${errorMessage}`
          : 'Error occurred while updating the proposal, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Change Owner'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Cancel',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => changeOwner(),
            text: 'Change Owner',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <Dropdown
            label='Teammate'
            width='100%'
            placeholder='Select new owner'
            required
            value={selectedUser}
            options={userOptions}
            onChange={(option: any) => setSelectedUser(option)}
          />
        </>
      </Modal>
    </>
  )
}

export default ChangeOwnerModal
