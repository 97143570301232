import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import OffersFilters from 'modules/products/components/filters/offers'
import OffersTable from '../components/table/offersTable'
import SearchBar from 'molecules/search/searchBarTable'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getPermits, exportPermits } from 'api/permits'
import ExportModal from 'molecules/modals/exportModal'
import { MODALS } from 'molecules/modals/constants'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import { BUTTONS, SEARCH } from 'lib/constants'
import { setSearch } from 'store/permitsSlice'
import PageHeaderComponent from 'molecules/PageHeader'

const OfferList: React.FC = () => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const params = useSelector((state: RootState) => state.permits.params)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getPermits({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <ExportModal
            type={MODALS.EXPORT.TYPES.OFFERS}
            setShowExportModal={setShowExportModal}
            showExportModal={showExportModal}
            exportCall={exportPermits}
          />
          <PageHeaderComponent
            h1Text='All offers'
            primaryButtons={[
              {
                onClick: () => console.log('redirect to upload offers page'),
                text: 'Upload',
                icon: 'Import',
                variant: 'secondary',
              },
              {
                onClick: () => setShowExportModal(!showExportModal),
                text: BUTTONS.EXPORT.LABEL,
                icon: BUTTONS.EXPORT.LABEL,
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.GENERIC}
            setSearch={setSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <OffersFilters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            hasBulkActions={false}
            totalRows={totalRows}
            currentPage={params.page}
          />
          <OffersTable
            data={tableData}
            loading={loading}
            totalRows={totalRows}
            currentPage={params.page}
            currentSortBy={params.order_by}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OfferList
