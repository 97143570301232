import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { PRODUCTS } from 'modules/products/constants'
import { MODALS } from 'molecules/modals/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    fields:
      'id,name,brand_identifier,identifier,parent_identifier,parent_name,upc,brand,id,inventory,media,retailer_categories',
  },
  connectionOptions: [],
  updatedOn: null,
  createdOn: null,
  retailerSkuAssigned: null,
  supplierLastUpdate: null,
  selected: [],
  export_type: MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS[0].value,
  transformer: null,
  template: null,
}
const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.CONNECTIONS.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setUpdatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.UPDATED_ON.PARAM_START]
        delete state.params[PRODUCTS.UPDATED_ON.PARAM_END]
        delete state.updatedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.UPDATED_ON.PARAM_START] = start
        state.params[PRODUCTS.UPDATED_ON.PARAM_END] = end
        state.updatedOn = action.payload
      }
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.CREATED_ON.PARAM_START]
        delete state.params[PRODUCTS.CREATED_ON.PARAM_END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.CREATED_ON.PARAM_START] = start
        state.params[PRODUCTS.CREATED_ON.PARAM_END] = end
        state.createdOn = action.payload
      }
    },
    setRetailerSkuAssigned(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_START]
        delete state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_END]
        delete state.retailerSkuAssigned
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_START] = start
        state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_END] = end
        state.retailerSkuAssigned = action.payload
      }
    },
    setSupplierLastUpdate(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_START]
        delete state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_END]
        delete state.supplierLastUpdate
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_START] = start
        state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_END] = end
        state.supplierLastUpdate = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []

      delete state.params[PRODUCTS.CONNECTIONS.PARAM]
      delete state.params[PRODUCTS.UPDATED_ON.PARAM_START]
      delete state.params[PRODUCTS.UPDATED_ON.PARAM_END]
      delete state.params[PRODUCTS.CREATED_ON.PARAM_START]
      delete state.params[PRODUCTS.CREATED_ON.PARAM_END]
      delete state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_START]
      delete state.params[PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_END]
      delete state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_START]
      delete state.params[PRODUCTS.SUPPLIER_LAST_UPDATE.PARAM_END]
      delete state.params[PRODUCTS.IN_STOCK.PARAM]
      delete state.params[PRODUCTS.IS_DISCONTINUED.PARAM]
      delete state.params[PRODUCTS.IS_SYNCED_TO_SHOPIFY.PARAM]
      delete state.params[PRODUCTS.IS_SYNCED_TO_BIG_COMMERCE.PARAM]

      delete state.updatedOn
      delete state.createdOn
      delete state.retailerSkuAssigned
      delete state.supplierLastUpdate
    },
    setFields(state, action) {
      state.params.fields = action.payload
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setExportType: (state, action) => {
      state.export_type = action.payload
    },
    setTransformer: (state, action) => {
      state.transformer = action.payload
    },
    setTemplate: (state, action) => {
      state.template = action.payload
    },
  },
})

export const {
  setFields,
  setSorting,
  setSearch,
  setFilters,
  setConnectionOptions,
  setUpdatedOn,
  setCreatedOn,
  setRetailerSkuAssigned,
  setSupplierLastUpdate,
  resetAllFilters,
  setCurrentPage,
  setSelected,
  setExportType,
  setTransformer,
  setTemplate,
} = productSlice.actions

export default productSlice.reducer
