import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import Button from 'atoms/button/button'
import Table from 'atoms/table/table'
import { EDIAccountProps } from 'modules/onboarding/utils/types'
import FTPLoginDetailCard from './fptLoginDetailCard'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import CardLoading from 'molecules/loading/cardLoading'

type UploadFulfillmentProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  account: EDIAccountProps
  orders: any
  loadOrders: () => void
  loadingOrders: boolean
  reseting: boolean
}

const UploadFulfillment: React.FC<UploadFulfillmentProps> = ({
  setIsNextButtonDisabled,
  account,
  orders,
  loadOrders,
  loadingOrders,
  reseting,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const [orderData, setOrderData] = useState([])

  // orders changed
  useEffect(() => {
    setIsNextButtonDisabled(false)
    const ids = integration.selectedIntegrationInfo.orderIds.map(({ id }) => id)
    const filteredOrders = orders.filter(({ id }) => ids.includes(id))
    const targetOrders =
      filteredOrders.length === 0
        ? integration.selectedIntegrationInfo.orderIds
        : filteredOrders
    const formattedOrders = targetOrders.map(
      ({ id, purchase_order_number, status = 'open' }) => ({
        id,
        data: {
          'Order ID': {
            value: purchase_order_number,
          },
          'Fulfillment Status': {
            value: status,
          },
        },
      })
    )
    setOrderData(formattedOrders)
  }, [orders])

  const tableColumns = [
    {
      sortable: false,
      title: 'Order ID',
    },
    {
      sortable: false,
      title: 'Fulfillment Status',
    },
  ]

  return (
    <>
      {reseting && <CardLoading label={currentTask.UPLOAD_FULFILLMENT.NAME} />}
      {!reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <h1 className='h5 mb-2'>{currentTask.UPLOAD_FULFILLMENT.NAME}</h1>
              <p className='body1-regular mb-4'>
                FTP upload an 856A5N fulfillment file for your sample item
                orders.
              </p>
              <p className='body1-regular'>
                1. Open the FTP page and upload the 856A5N fulfillment file into
                your outbox.
              </p>
              <p className='body1-regular'>
                2. Wait 10-15 minutes for the file to process. Click refresh to
                update fulfillment status
              </p>
              <p className='body1-regular'>
                3. Click Confirm & Continue when both orders statuses are
                fulfilled.
              </p>
            </div>
          }
          bodyContent={
            <>
              {/* Account Detail */}
              {account.qualifierId !== '' && (
                <div className='mb-4'>
                  <FTPLoginDetailCard account={account} />
                </div>
              )}
              <div className='mb-4'>
                <Button
                  icon='Refresh'
                  variant='secondary'
                  text='Refresh Orders'
                  onClick={loadOrders}
                />
              </div>
              <Table
                columns={tableColumns}
                data={orderData}
                loading={loadingOrders}
                activePage={1}
                selectable={false}
                totalRecords={orderData.length}
              />
            </>
          }
        />
      )}
    </>
  )
}

export default UploadFulfillment
