import React, { ChangeEvent } from 'react'
import { Radio as RadioUI } from '@teamfabric/copilot-ui'

type RadioProps = {
  label: string
  value: string
  name: string
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  tabIndex?: number
  id?: string
  dataTestid?: string
  required?: boolean
}

const Radio: React.FC<RadioProps> = ({ ...props }) => {
  return <RadioUI {...props} />
}

export default Radio
