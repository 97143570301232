import React, { useMemo, useState } from 'react'
import Image from 'atoms/image/image'
import Link from 'atoms/link/link'
import { Modal } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import InventoryDetailsModal from 'molecules/modals/item/inventoryDetailsModal'

type VariantItemProps = {
  variant: any
  href?: string
  onClick?: () => void
  hideImage?: boolean
  isProduct?: boolean
  showInventoryDetailsModal?: boolean
  showUPC?: boolean
}

const VariantItem: React.FC<VariantItemProps> = ({
  variant,
  onClick,
  href,
  hideImage,
  isProduct,
  showInventoryDetailsModal,
  showUPC,
}) => {
  const [displayInventoryDetails, setDisplayInventoryDetails] = useState(false)

  const label = useMemo(() => {
    if (isProduct) {
      return variant?.parent_name ? variant?.parent_name : '--'
    } else return variant?.name ? variant?.name : '--'
  }, [variant, isProduct])

  const identifierLabel = useMemo(() => {
    if (showUPC) {
      return 'UPC'
    } else if (isProduct) {
      return 'Product SKU'
    } else return 'Supplier SKU'
  }, [variant, isProduct, showUPC])

  const identifier = useMemo(() => {
    if (showUPC) {
      return variant?.upc || 'Not set'
    } else if (isProduct) {
      return variant?.parent_identifier
    } else return variant?.brand_identifier
  }, [variant, isProduct, showUPC])

  const handleOnClick = () => {
    if (showInventoryDetailsModal) {
      setDisplayInventoryDetails(true)
    } else {
      onClick()
    }
  }

  return (
    <>
      <div className={!hideImage ? 'v-center gap-2' : ''}>
        {!hideImage && (
          <Image
            alt='variant'
            size='xsmall'
            src={variant?.media?.[0]?.url || variant?.primary_media?.url}
            aspectRatio='1:1'
          />
        )}
        <ToolTip placement='bottomStart' text={label}>
          <div className='flex-column-start truncate'>
            {onClick || href || showInventoryDetailsModal ? (
              <Link
                onClick={handleOnClick}
                label={label}
                variant='primary'
                mode='inline'
                size='medium'
                href={href}
              />
            ) : (
              <span>{label}</span>
            )}
            <span className='body2-regular grey-700 pr-1 pl-1'>
              {identifierLabel} {identifier}
            </span>
          </div>
        </ToolTip>
      </div>
      {/* Inventory Detail Modal */}
      <Modal
        headerText='Inventory Details'
        size='small'
        onClose={() => setDisplayInventoryDetails(false)}
        isVisible={displayInventoryDetails}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setDisplayInventoryDetails(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        onBackdropClick={() => setDisplayInventoryDetails(false)}
      >
        <InventoryDetailsModal variant={variant} />
      </Modal>
    </>
  )
}

export default VariantItem
