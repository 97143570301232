import { createSlice } from '@reduxjs/toolkit'
import { getLastXdays, getUnixTimestamp } from 'lib/utils/date/formatDate'
import { ORDERS } from 'modules/orders/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    short: 1,
    fields:
      'id,order_id,shipping_method,carrier,tracking_number,purchase_order_number,brand,retailer,created_at,shipped_at,acknowledged_at,tracking_url,valid_packing_slip',
    shipped_at_gte: getUnixTimestamp(getLastXdays(30)),
  },
  connectionOptions: [],
  createdOn: null,
  initialCreatedOnValueSet: false,
}
const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.START]
        delete state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.START] = start
        state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.END] = end
        state.createdOn = action.payload
      }
    },
    setInitialCreatedOn(state) {
      state['initialCreatedOnValueSet'] = true
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []

      delete state.params[ORDERS.CONNECTION.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED.PARAM]
      delete state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.START]
      delete state.params[ORDERS.SHIPPING_CREATED_ON.PARAMS.END]
      delete state.params[ORDERS.SEARCH.PARAM]
      delete state.createdOn
    },
    setAdvancedOptions: (state, action) => {
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
      // set tracking number
      if (action.payload?.tracking_number) {
        state.params['search'] = action.payload.tracking_number
      } else delete state.params['search']
      // set connection id
      if (action.payload?.connection_id) {
        state.params = {
          ...(state.params as any),
          connection_id: action.payload.connection_id,
        }
        const selectedConnection = [
          {
            id: action.payload.connection_id,
            label: action.payload.connection_label,
            selected: true,
          },
        ]
        state.connectionOptions = selectedConnection
      } else {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      }
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setConnectionOptions,
  setCurrentPage,
  setCreatedOn,
  setInitialCreatedOn,
  resetAllFilters,
  setAdvancedOptions,
} = shipmentsSlice.actions

export default shipmentsSlice.reducer
