import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, Input, InputStepper, PageHeader } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import _ from 'lodash'
import SelectCommissionProfile from 'molecules/selectDropdowns/selectCommissionProfile'

type PreferenceJson = {
  preference: { id: any }
  option?: { id: string }
  value?: any
}

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const CostDetermination: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [commission, setCommission] = useState(0)
  const [commissionProfile, setCommissionProfile] = useState(null)

  const methodPreference = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_net_price_method'])
    }
    return null
  }, [preferences, loading])

  const commissionPreference = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_commission_rate'])
    }
    return null
  }, [preferences, loading])

  const preferenceOptions = useMemo(() => {
    if (methodPreference !== undefined) {
      return (
        methodPreference?.options?.map((option) => ({
          id: option.id,
          label: option.name,
          value: option.value,
        })) || []
      )
    }
  }, [methodPreference])

  useEffect(() => {
    if (methodPreference) {
      setOptions(preferenceOptions)

      let selectedPreferenceOption
      if (methodPreference.retailer_preference) {
        selectedPreferenceOption = preferenceOptions.find(
          (el) => el.value === methodPreference.retailer_preference.value
        )
        if (
          typeof commissionPreference !== 'undefined' &&
          selectedPreferenceOption.value &&
          commissionPreference.retailer_preference
        ) {
          if (selectedPreferenceOption.value === 'revshare') {
            setCommission(commissionPreference.retailer_preference.value)
          }
          if (selectedPreferenceOption.value === 'commission_profile') {
            const value = {
              id: parseInt(commissionPreference.retailer_preference.value),
            }
            setCommissionProfile(value)
          }
        }
      }
      setSelectedOption(selectedPreferenceOption)
    }
  }, [methodPreference, commissionPreference, preferenceOptions])

  useEffect(() => {
    if (!loading) {
      const preferencesCopy: PreferenceJson[] = [
        {
          preference: { id: methodPreference?.id },
          option: { id: selectedOption?.id },
        },
      ]
      if (selectedOption && selectedOption.value === 'revshare') {
        preferencesCopy.push({
          preference: { id: commissionPreference?.id },
          value: commission,
        })
      } else if (
        selectedOption &&
        selectedOption.value === 'commission_profile'
      ) {
        preferencesCopy.push({
          preference: { id: commissionPreference?.id },
          value: commissionProfile?.id,
        })
      } else {
        preferencesCopy.push({
          preference: { id: commissionPreference?.id },
          value: 0,
        })
      }
      handleChange(preferencesCopy)
    }
  }, [loading, selectedOption, commissionProfile, commission])

  const handleCommissionValueChange = (newVal) => {
    const parsedValue = typeof newVal === 'string' ? parseInt(newVal) : newVal
    const parsedCommission =
      typeof commission === 'string' ? parseInt(commission) : commission
    if (parsedValue !== parsedCommission) {
      setCommission(parsedValue)
    }
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Default Cost Determination</span>}
              h2Text={
                <span className='body2-regular'>
                  All incoming items on a purchase order must have an associated
                  cost to fabric Merchant. Choose 'Specified on Order' if the
                  costs of items are to be maintained by fabric Merchant. If
                  fabric is maintaining costs, choose 'Specified by Platform'.
                  If fabric should calculate the cost using a percentage of
                  retail, choose 'Commission' (and specify a default commission
                  rate).
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <>
            <Dropdown
              required
              label='Cost Method'
              width='50%'
              value={selectedOption}
              onChange={(option: any) => {
                const filteredOption = preferenceOptions.find(
                  (el) => el.id === option.id
                )
                setSelectedOption(filteredOption)
              }}
              options={options}
              placeholder='Select a cost method'
              disabled={loading}
            />
            {selectedOption.value === 'revshare' ? (
              <div className='mt-4'>
                <InputStepper
                  width='50%'
                  label='Your Commission (%)'
                  max={100}
                  min={0}
                  onValueChange={(newVal) =>
                    handleCommissionValueChange(newVal)
                  }
                  stepSize={0.25}
                  value={commission}
                />
              </div>
            ) : selectedOption.value === 'commission_profile' ? (
              <div className='mt-4 '>
                <SelectCommissionProfile
                  handleUpdate={(option) => {
                    setCommissionProfile(option)
                  }}
                  selectedProfile={commissionProfile}
                  width='50%'
                />
              </div>
            ) : null}
          </>
        }
      ></Card>
    </Box>
  )
}

CostDetermination.defaultProps = defaultProps
export default CostDetermination
