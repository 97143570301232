import {
  get29YearsAgo,
  get29YearsFromNow,
  get30YearsAgo,
  getMMDDYYYY,
} from 'lib/utils/date/formatDate'
import { PRODUCTS } from 'modules/products/constants'
import { FILTERS } from 'molecules/filter/constants'
import { store } from 'store'
import { capitalizeFirstLetter } from '../../index'

const handleDateFilterChange = ({
  filterActions,
  filterKey,
  date = null,
  isReset = false,
}) => {
  const { action, setFilterCount } = filterActions[filterKey]
  if (isReset) {
    store.dispatch(action({ type: 'reset' }))
    setFilterCount(0)
  } else if (date?.dateRange?.to && date?.dateRange?.from) {
    store.dispatch(action(date))
    setFilterCount(1)
  }
}

const handleCheckboxFilterChange = ({
  filterActions,
  filterKey,
  setFilters,
  selectedFilterOptions = null,
  isReset = false,
}) => {
  const { action, setFilterCount } = filterActions[filterKey]
  if (isReset) {
    store.dispatch(setFilters({ filter: filterKey, value: '' }))
    store.dispatch(action({ type: 'reset' }))
    setFilterCount(0)
  } else if (selectedFilterOptions) {
    const { selectedOptions, checkboxOptions } = selectedFilterOptions
    store.dispatch(action(checkboxOptions))
    const filteredData = {
      filter: filterKey,
      value: selectedOptions.join(','),
    }
    store.dispatch(setFilters(filteredData))
    const selectedCount = checkboxOptions.filter(
      (option) => option.selected
    ).length
    setFilterCount(selectedCount)
  }
}

const handleCheckboxWithSearchFilterChange = ({
  checkboxOptions,
  filterKey,
  id,
  selectedOption,
  isHandleAdd,
  filterActions,
  setFilters,
  isReset,
}: {
  filterKey: string
  filterActions: any
  setFilters: any
  checkboxOptions?: any[]
  id?: any
  selectedOption?: any
  isHandleAdd?: boolean
  isReset?: boolean
}) => {
  const { action, setFilterCount } = filterActions[filterKey]
  if (isReset) {
    store.dispatch(setFilters({ filter: filterKey, value: '' }))
    store.dispatch(action({ type: 'reset' }))
  } else {
    let label = ''
    let updatedCheckboxOptions = []

    if (isHandleAdd) {
      if (filterKey === PRODUCTS.CONNECTIONS.PARAM) {
        label = selectedOption.retailer?.name
      }

      updatedCheckboxOptions = [
        ...checkboxOptions,
        {
          expanded: false,
          hasChildren: false,
          id: selectedOption?.id,
          label: label,
          selected: true,
        },
      ]
    } else {
      updatedCheckboxOptions = checkboxOptions.filter(
        (option) => option.id != id
      )
    }

    const filteredData = {
      filter: filterKey,
      value: updatedCheckboxOptions.map((option) => option.id).join(','),
    }

    store.dispatch(setFilters(filteredData))
    store.dispatch(action(updatedCheckboxOptions))
    setFilterCount(updatedCheckboxOptions.length)
  }
}

const handleRadioFilterChange = ({ filterKey, value = '', setFilters }) => {
  const filteredData = {
    filter: filterKey,
    value,
  }
  store.dispatch(setFilters(filteredData))
}

const handleFilterChipDeletion = (
  filterActions,
  filterKey,
  setFilters,
  value
) => {
  const { action, options, filterType } = filterActions[filterKey]

  if (
    [FILTERS.TYPES.CHECKBOX, FILTERS.TYPES.CHECKBOX_WITH_SEARCH].includes(
      filterType
    )
  ) {
    let updatedOptions = []
    let newFilterValues = []

    if (filterType === FILTERS.TYPES.CHECKBOX) {
      options.forEach((option) => {
        if (
          option.value === value ||
          (option.value === 'open,partial' && value === 'open')
        ) {
          updatedOptions.push({
            ...option,
            selected: false,
          })
        } else updatedOptions.push(option)
      })
      updatedOptions.forEach((option) => {
        if (option.selected) {
          newFilterValues.push(option.value)
        }
      })
    } else {
      updatedOptions = options.filter((option) => option.id != value)
      newFilterValues = updatedOptions.map((option) => option.id)
    }
    const filteredData = {
      filter: filterKey,
      value: newFilterValues.join(','),
    }
    store.dispatch(action(updatedOptions))
    store.dispatch(setFilters(filteredData))
  } else if (filterType === FILTERS.TYPES.DATE) {
    store.dispatch(action({ type: 'reset' }))
  } else if (filterType === FILTERS.TYPES.RADIO) {
    store.dispatch(setFilters({ filter: filterKey, value: '' }))
  }
}

const handleResetAllFilters = (resetAllFilters) => {
  store.dispatch(resetAllFilters())
}

export const handleDateChange = (dateObject, setLocalState, setFilterCount) => {
  setLocalState(dateObject)
  setFilterCount(1)
}

export const handleSelectedChange = (
  id,
  setLocalState,
  checkboxOptions,
  setFilterCount,
  selected?
) => {
  const updatedCheckboxOptions = checkboxOptions.map((option) => {
    if (option.id === id) {
      return {
        ...option,
        selected: selected,
      }
    }
    return option
  })
  const selectedCount = updatedCheckboxOptions.filter(
    (option) => option.selected
  ).length
  setLocalState(updatedCheckboxOptions)
  setFilterCount(selectedCount)
}

export const removeCheckboxOption = (id, setterHook, checkboxOptions) => {
  const updatedCheckboxOptions = checkboxOptions.filter(
    (option) => option.id != id
  )
  setterHook(updatedCheckboxOptions)
}

export const addCheckboxOptionFromSearchDropdown = (
  option,
  setterHook,
  checkboxOptions,
  filterType
) => {
  let label = ''
  if (filterType === PRODUCTS.CONNECTIONS.NAME) {
    label = option.retailer?.name
  }
  const newCheckboxOptions = [
    ...checkboxOptions,
    {
      expanded: false,
      hasChildren: false,
      id: option?.id,
      label: label,
      selected: true,
    },
  ]
  setterHook(newCheckboxOptions)
}

const createDateFilterChip = (filterActionsMap, key) => {
  const chipName = filterActionsMap[key].chipName
  const dateRange = filterActionsMap[key].dateRange?.dateRange
  // check if there effectively only is a start or an end date and behave accordingly
  let formattedStartDate = null
  let formattedEndDate = null

  if (new Date(dateRange?.from) > get29YearsAgo()) {
    formattedStartDate = getMMDDYYYY(dateRange.from)
  } else {
    formattedStartDate = getMMDDYYYY(new Date())
  }

  if (new Date(dateRange?.to) < get29YearsFromNow()) {
    formattedEndDate = getMMDDYYYY(dateRange.to)
  } else {
    formattedStartDate = getMMDDYYYY(new Date())
  }

  let label = ''
  if (formattedStartDate && formattedEndDate) {
    label = `${chipName}: from ${formattedStartDate} to ${formattedEndDate}`
  } else if (formattedStartDate) {
    label = `${chipName}: from ${formattedStartDate}`
  } else if (formattedEndDate) {
    label = `${chipName}: prior to ${formattedEndDate}`
  }

  const filterChip = {
    label: label,
    key,
  }

  return filterChip
}

export const setCountersAndChips = (
  filterActionsMap,
  params,
  setFilterChips
) => {
  let filterChips = []
  Object.keys(filterActionsMap).forEach((key, index) => {
    if (params[key]) {
      const selectedOptions = params[key]
        .toString()
        // we use two params for open orders' status, replace
        .replace('open,partial', 'open')
        .split(',')
      const filterType = filterActionsMap[key].filterType
      if (filterType === 'date') {
        const filterChip = createDateFilterChip(filterActionsMap, key)
        filterChips.push(filterChip)
      } else if (filterType === 'checkbox') {
        const chipName = filterActionsMap[key].chipName
        if (filterActionsMap[key].useLabelForChip) {
          selectedOptions.forEach((option) => {
            const value = filterActionsMap[key].options?.filter(
              (item) => item.id == option
            )[0]?.label
            const filterChip = {
              label: `${capitalizeFirstLetter(
                chipName
              )}: ${capitalizeFirstLetter(value)}`,
              key,
              value: option,
            }
            filterChips.push(filterChip)
          })
        } else {
          selectedOptions.forEach((option) => {
            const formattedOption = option.replace('_', ' ')
            const filterChip = {
              label: `${capitalizeFirstLetter(
                chipName
              )}: ${capitalizeFirstLetter(formattedOption)}`,
              key,
              value: option,
            }
            filterChips.push(filterChip)
          })
        }
      } else if (filterType === 'checkbox_with_search') {
        const chipName = filterActionsMap[key].chipName
        selectedOptions.forEach((option) => {
          const value = filterActionsMap[key].options?.filter(
            (item) => item.id == option
          )[0]?.label
          const filterChip = {
            label: `${chipName}: ${capitalizeFirstLetter(value)}`,
            key,
            value: option,
          }
          filterChips.push(filterChip)
        })
      } else if (filterType === 'radio') {
        const chipName = filterActionsMap[key].chipName
        const value = params[key] == 1 ? 'Yes' : 'No'
        const filterChip = {
          label: `${chipName}: ${capitalizeFirstLetter(value)}`,
          key,
          value,
        }
        filterChips.push(filterChip)
      }
      //set counter
      filterActionsMap[key].setFilterCount(selectedOptions.length)
    } else filterActionsMap[key].setFilterCount(0)
  })
  setFilterChips(filterChips)
}

export {
  handleDateFilterChange,
  handleCheckboxFilterChange,
  handleCheckboxWithSearchFilterChange,
  handleRadioFilterChange,
  handleFilterChipDeletion,
  handleResetAllFilters,
  capitalizeFirstLetter,
}
