import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Drawer } from '@teamfabric/copilot-ui'
import { Button, HorizontalTab } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import MoreActionButtonChildren from 'molecules/button/moreDropdownChildren'
import DrawerItemsTable from './variantsTable'
import DrawerItemAttributes from './itemAttributes'
import DrawerProductAttributes from './productAttributes'
import RemoveProposalProductModal from '../modals/removeProposalProduct'
import CreateIssueModal from '../modals/createIssue'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import AddToOpenIssueModal from '../modals/addToOpenIssue'
import DrawerComments from './comments'
import DrawerIssues from './issues'
import { approveProposalProduct } from 'api/proposals'
import { useToastUtils } from 'lib/utils/toast'
import { getBadgeStatus } from 'modules/proposals/utils'

type ChildProps = {
  selectedLogData: any
  handleCloseDrawer: () => void
  type?: 'product' | 'variant'
  loadProposal?: () => void
  setSelectedLogForSideDrawer?: Dispatch<SetStateAction<any>>
  loadProducts?: () => void
}

const ProposalProductDrawer: React.FC<ChildProps> = ({
  selectedLogData,
  handleCloseDrawer,
  loadProposal,
  setSelectedLogForSideDrawer,
  type,
  loadProducts,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [showRemoveProductsModal, setShowRemoveProductsModal] = useState(false)
  const [showCreateIssue, setShowCreateIssue] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)
  const [hideFooterActions, setHideFooterActions] = useState(false)

  const { showSuccessToast } = useToastUtils()

  const variantTabs = [
    {
      label: 'Attributes',
      dataTestid: 'item-attributes-tab',
      content: <DrawerItemAttributes selected={selectedLogData} />,
    },
  ]
  const productTabs = [
    {
      label: 'Attributes',
      dataTestid: 'product-attributes-tab',
      content: <DrawerProductAttributes selected={selectedLogData} />,
    },
    {
      label: 'Variants',
      dataTestid: 'product-variants-tab',
      content: <DrawerItemsTable selected={selectedLogData} />,
    },
    {
      label: 'Comments',
      dataTestid: 'product-comments-tab',
      content: <DrawerComments selected={selectedLogData} />,
    },
    {
      label: 'Issues',
      dataTestid: 'product-issues-tab',
      content: (
        <DrawerIssues
          selected={selectedLogData}
          setHideFooterActions={setHideFooterActions}
        />
      ),
    },
  ]

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )
  const approveProduct = async () => {
    const { data } = await approveProposalProduct({
      id: proposalDetails?.id,
      productId: selectedLogData?.product?.id,
    })
    if (data.id && data?.proposal_status?.id) {
      showSuccessToast(
        `Your product, "${selectedLogData.product?.name}", has been approved successfully.`
      )
      updatedSelectedLogData(data)
      loadProducts()
    }
  }

  const updatedSelectedLogData = (data) => {
    const { reject_reason, rejected_at, status } = data.proposal_status
    const updatedProduct = {
      ...selectedLogData,
      reject_reason,
      rejected_at,
      status,
    }
    setSelectedLogForSideDrawer?.(updatedProduct)
  }

  const canAddToOpenIssue = useMemo(() => {
    if (proposalDetails?.issues_summary.open > 0) return true
    return false
  }, [proposalDetails?.issues_summary])

  const MoreActions = [
    {
      name: 'Add To Open Issue',
      handleClick: () => setShowAddToOpenIssue(true),
      isDisabled: !canAddToOpenIssue,
    },
    {
      name: 'Create Issue',
      handleClick: () => setShowCreateIssue(true),
    },
  ]

  return (
    <Drawer isVisible dataTestId='proposal-product-drawer'>
      <PageHeaderComponent
        h1Text={selectedLogData?.name || selectedLogData?.product?.name}
        h2Text={
          type === 'product'
            ? `Product SKU ${selectedLogData?.product?.identifier}`
            : `Supplier SKU ${selectedLogData?.brand_identifier}`
        }
        badgeProps={
          type === 'product' && getBadgeStatus(selectedLogData?.status)
        }
        primaryButtons={[
          {
            onClick: handleCloseDrawer,
            icon: 'Close',
            variant: 'tertiary_dark',
            dataTestid: 'close-drawer-icon',
          },
        ]}
      />
      <div className='divider-4' />
      <HorizontalTab
        onTabChange={setActiveTabIndex}
        selectedTab={activeTabIndex}
        data={{
          tabs: type === 'product' ? productTabs : variantTabs,
        }}
      />
      {type === 'product' && !hideFooterActions && (
        <div className='drawer-footer'>
          <Button
            text='More'
            icon='ArrowDown'
            variant='secondary'
            dataTestid='more-options-button'
            onClick={() => null}
            popoverProps={{
              children: <MoreActionButtonChildren actions={MoreActions} />,
              placement: 'bottom-start',
            }}
          />
          {['approved', 'pending'].includes(selectedLogData?.status) && (
            <Button
              text='Decline'
              dataTestid='decline-button'
              onClick={() => setShowRemoveProductsModal(true)}
              variant='secondary'
            />
          )}
          {['rejected', 'pending'].includes(selectedLogData?.status) && (
            <Button
              text='Approve'
              dataTestid='approve-button'
              onClick={approveProduct}
            />
          )}
        </div>
      )}
      <RemoveProposalProductModal
        showModal={showRemoveProductsModal}
        setShowModal={setShowRemoveProductsModal}
        product={selectedLogData?.product}
        updateProduct={(data) => updatedSelectedLogData(data)}
        loadProducts={loadProducts}
      />
      {/* Create new issue modal - to do: modify for products */}
      <CreateIssueModal
        count={1}
        itemIdArray={[selectedLogData?.id]}
        showModal={showCreateIssue}
        setShowModal={setShowCreateIssue}
        loadProposal={loadProposal}
        isProduct
      />
      {/* Add to open issue modal - to do: modify for products*/}
      <AddToOpenIssueModal
        count={1}
        itemIdArray={[selectedLogData?.id]}
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProposal}
        isProduct
      />
    </Drawer>
  )
}

export default ProposalProductDrawer
