import API, { API_URL } from 'api'

export const putCompleteTask = async ({ taskId }) => {
  return await API.put(API_URL.COMPLETE_TASK({ taskId }))
}

export const patchAssignTask = async ({ taskId, body }) => {
  return await API.patch(API_URL.ASSIGN_TASK_DETAIL({ taskId }), body)
}

export const postAssignTask = async ({ body }) => {
  return await API.post(API_URL.ASSIGN_TASK(), body)
}

export const getAssignTask = async () => {
  return await API.get(API_URL.ASSIGN_TASK())
}

export const resetAssignTask = async ({ taskId, body }) => {
  return await API.put(API_URL.RESET_ASSIGN_TASK({ taskId }), body)
}

export const deleteAssignTask = async ({ taskId }) => {
  return await API.delete(API_URL.ASSIGN_TASK_DETAIL({ taskId }))
}

export const getOnboarding = async ({ params }) => {
  return await API.get(API_URL.ONBOARDING(), { params })
}
