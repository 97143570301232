import { FC, useEffect, useState } from 'react'
import { Box } from 'atoms'
import Comments from '../details/comments'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getProposalProductMemos } from 'api/proposals'

type ChildProps = {
  selected: any
}

const DrawerComments: FC<ChildProps> = ({ selected }) => {
  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )
  const [loading, setLoading] = useState(true)

  const { id: proposalProductId } = selected

  const [comments, setComments] = useState([])

  const fetchProductComments = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalProductMemos({
      id: proposalDetails.id,
      productId: proposalProductId,
    })
    setComments(data.results)
    setLoading(false)
  }

  useEffect(() => {
    fetchProductComments()
  }, [proposalDetails, proposalProductId])
 
  return (
    <Box dataTestid='comments-tab-content'>
      <Comments
        proposal={proposalDetails}
        isProductDrawer
        productMemos={comments}
        proposalProductId={proposalProductId}
        loadProductMemos={fetchProductComments}
        loadingProductMemos={loading}
      />
    </Box>
  )
}

export default DrawerComments
