import { getRmsReturns, getReturnLabel } from 'api/cancels'
import { getConnectionDetail } from 'api/connection'
import { getOrderDetail } from 'api/orders'
import React, { FC, useEffect, useState } from 'react'
import { formatOptionsData } from 'lib/utils/formatData/searchFilter'
import Dropdown from 'atoms/dropdown/dropdown'
import { getConnectionLocation } from 'api/connectionLocation'
import Input from 'atoms/input/input'
import { postReturnLabel } from 'api/cancels'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'

type ChildProps = {
  rmaId: string
  content: string
  orderId: string
  supplier: string
}

type ReturnLabelProp = {
  return_label: any
}

const GenerateRMA: FC<ChildProps> = ({ rmaId, content, orderId, supplier }) => {
  const [returns, setReturns] = useState([])
  const [accountOptions, setAccountOptions] = useState([])
  const [selectedAccountOption, setSelectAccountOption] = useState({
    id: '',
    label: '',
  })
  const [accounts, setAccounts] = useState([])
  const [methodsOptions, setMethodsOptions] = useState([])
  const [selectedMethodsOption, setSelectMethodsOption] = useState({
    id: '',
    label: '',
  })
  const [returnWarehouse, setReturnWarehouse] = useState({
    address: {
      name1: '',
      street1: '',
      city: '',
      province: '',
      postal_code: '',
      country: '',
      phone1: '',
    },
  })
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([])
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [weight, setWeight] = useState('')
  const [returnLabel, setReturnLabel] = useState<ReturnLabelProp>({
    return_label: null,
  })
  const [rmaReturnId, setRmaReturnId] = useState('')

  useEffect(() => {
    ;(async () => {
      if (content === 'generate') {
        const {
          data: { results },
        } = await getRmsReturns({ id: rmaId })
        setReturns(results)
      } else {
        const { data } = await getOrderDetail({ id: orderId })
        const { data: connectionData } = await getConnectionDetail({
          id: data.connection_id,
        })
        const formattedOption = formatOptionsData({
          data: connectionData.shipping_accounts,
          label: ['nickname'],
        })
        setAccounts(connectionData.shipping_accounts)
        setAccountOptions(formattedOption)
      }
    })()
  }, [content])

  const isValid = () => {
    if (!selectedAccountOption) return false
    if (!selectedMethodsOption) return false
    if (!returnWarehouse) return false
    if (!width || !length || !height) return false
    if (!weight) return false
    return true
  }

  const returnLabelJson = () => {
    if (!isValid) return {}
    return {
      shipping_account: {
        id: selectedAccountOption.id,
      },
      shipping_method: {
        id: selectedMethodsOption.id,
      },
      ship_to: {
        name1: returnWarehouse.address.name1,
        street1: returnWarehouse.address.street1,
        city: returnWarehouse.address.city,
        province: returnWarehouse.address.province,
        postal_code: returnWarehouse.address.postal_code,
        country: returnWarehouse.address.country,
        phone1: returnWarehouse.address.phone1,
      },
      ship_weight: weight,
      ship_width: width,
      ship_length: length,
      ship_height: height,
      label_format: 'PDF',
    }
  }

  const generateReturnLabel = async () => {
    await postReturnLabel({ id: '', body: returnLabelJson })
  }

  const getLabel = async () => {
    const { data } = await getReturnLabel({ rmaId, rmaReturnId })
    setReturnLabel(data)
  }

  return (
    <div>
      {content === 'generate' && returns.length === 0 && (
        <div>
          <p className='grey-700'>
            No return labels have been generated yet for this RMA
          </p>
        </div>
      )}
      {content === 'create' && (
        <div>
          <div className='mb-2'>
            <Dropdown
              width='450px'
              tooltipInfo={{ text: 'this is tooltip' }}
              options={accountOptions}
              label='Carrier account'
              onChange={({ id, label }) => {
                setSelectAccountOption({ id, label })
                const { shipping_methods } = accounts?.find(
                  ({ nickname }) => nickname === label
                )
                const groundMethods = shipping_methods.filter(
                  ({ generic_shipping_method: { name } }) => name === 'Ground'
                )
                const formattedMethodOptions = formatOptionsData({
                  data: groundMethods,
                  label: ['name'],
                })
                setMethodsOptions(formattedMethodOptions)
              }}
            />
            <Dropdown
              width='450px'
              tooltipInfo={{ text: 'this is tooltip' }}
              options={methodsOptions}
              label='Carrier service'
              onChange={({ id, label }) =>
                setSelectMethodsOption({ id, label })
              }
            />
            {/* <Dropdown
              width='450px'
              options={[]}
              label='Return to'
              placeholder='Please select a location'
              serverSideFiltering={{
                loading: loading,
                noSearchResults: {
                  show: true,
                },
                onInputChange: async () => {
                  setLoading(true)
                  const {
                    data: { results },
                  } = await getConnectionLocation({
                    params: { type: 'warehouse', search_locations: supplier },
                  })
                  const formattedLocations = formatOptionsData({
                    data: results,
                    label: [''],
                  })
                  setLocations(formattedLocations)
                  console.log(formattedLocations)
                  setLoading(false)
                },
              }}
              onChange={({ id, label }) =>
                setReturnWarehouse({ address: label })
              }
            /> */}
            <div className='mb-2'>
              <p className='label'>Carton dimension (in approximate inches)</p>
              <GridRow padding={false}>
                <GridCol lg={4} md={4} sm={4}>
                  <Input
                    label=''
                    width='140px'
                    required={true}
                    inputProps={{
                      placeholder: 'Width',
                      onChange: ({ target: { value } }) => {
                        setWidth(value)
                      },
                    }}
                  />
                </GridCol>
                <GridCol lg={4} md={4} sm={4}>
                  <Input
                    label=''
                    width='140px'
                    required={true}
                    inputProps={{
                      placeholder: 'Height',
                      onChange: ({ target: { value } }) => setHeight(value),
                    }}
                  />
                </GridCol>
                <GridCol lg={4} md={4} sm={4}>
                  <Input
                    label=''
                    width='140px'
                    required={true}
                    inputProps={{
                      placeholder: 'Length',
                      onChange: ({ target: { value } }) => setLength(value),
                    }}
                  />
                </GridCol>
              </GridRow>
              <div>
                <Input
                  width='450px'
                  label='Carton weight (in approximate pounds)'
                  required={true}
                  inputProps={{
                    placeholder: 'Weight',
                    onChange: ({ target: { value } }) => setWeight(value),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {content === 'label' && (
        <div>
          <p>Label will be here</p>
          {/* <object
            type='application/pdf'
            width='100%'
            height='500px'
            data="'data:application/pdf;base64,' + label.return_label "
          ></object> */}
        </div>
      )}
    </div>
  )
}

export default GenerateRMA
