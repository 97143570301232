import React from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'

type ChildProps = {
  loading: boolean
  storeCredentials: any
}

const StoreDetailsCard: React.FC<ChildProps> = ({
  loading,
  storeCredentials,
}) => {
  const { id } = useParams()
  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <PageHeaderComponent
          h1Text={<span className='h5'>Store Details</span>}
          h2Text={
            <span className='body1-regular'>
              Information about fabric connected with your Marketplace account.
            </span>
          }
        />
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <>
          <GridRow padding={false}>
            <GridCol sm={4} md={4}>
              <p className='grey-700 label'>fabric Account ID</p>
              <p className='grey-100 body1-regular'>
                {!loading && storeCredentials?.['fabric Account']
                  ? storeCredentials['fabric Account']
                  : '--'}
              </p>
            </GridCol>
            <GridCol sm={4} md={4}>
              <p className='grey-700 label'>Platform Account ID</p>
              <p className='grey-100 body1-regular'>{id}</p>
            </GridCol>
            <GridCol sm={4} md={4}>
              <p className='grey-700 label'>Location Number</p>
              <p className='grey-100 body1-regular'>
                {!loading && storeCredentials?.['location_num']
                  ? storeCredentials['location_num']
                  : '--'}
              </p>
            </GridCol>
          </GridRow>
        </>
      }
    />
  )
}

export default StoreDetailsCard
