import React, { useEffect, useState } from 'react'
import ProposalIssuesTable from '../table/issues'
import { getProposalIssues } from 'api/proposals'
import { useParams } from 'react-router-dom'
import { useManageSideDrawer } from 'modules/proposals/utils/drawer'
import IssueDetailsDrawer from '../drawer/issueDetails'
import { Box } from 'atoms'

type IssuesProps = {
  isRetailer: boolean
  loading: boolean
  loadProposal: (quiet?: boolean) => void
}

const Issues: React.FC<IssuesProps> = ({ loadProposal }) => {
  const [loadingIssues, setLoadingIssues] = useState(true)
  const [issues, setIssues] = useState<any>([])
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      fetchProposalIssues()
    }
  }, [id])

  const fetchProposalIssues = async () => {
    setLoadingIssues(true)
    const { data } = await getProposalIssues({ proposalId: id })
    loadProposal(true)
    setIssues(data.results)
    setLoadingIssues(false)
  }

  const { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer } =
    useManageSideDrawer({ eventLogsData: issues })

  return (
    <Box
      width='100%'
      padding={{ top: 5, bottom: 4 }}
      dataTestid='proposal-issues-content'
    >
      <ProposalIssuesTable
        data={issues}
        loading={loadingIssues}
        handleOpenDrawer={(id) => handleOpenDrawer(id)}
      />
      {selectedLogForSideDrawer && (
        <IssueDetailsDrawer
          selectedLogData={selectedLogForSideDrawer}
          handleCloseDrawer={handleCloseDrawer}
          loadProposalIssues={fetchProposalIssues}
        />
      )}
    </Box>
  )
}

export default Issues
