import { GridCol, GridRow } from 'atoms'
import { useEffect, useState } from 'react'
import PushNotification from './pushNotification'
import { getTenantType } from 'api/helper'

interface AvailableNotification {
  action: string
  title: string
  text: string
  context: string[]
}

const availableNotifications: AvailableNotification[] = [
  {
    action: 'Connection Memo Saved',
    title: 'Connection Notifications',
    text: 'Subscribe to push notifications to receive real-time alerts related to your connections & connection notes.',
    context: ['supplier'],
  },
  {
    action: 'Order Created',
    title: 'Order Received',
    text: 'If you want to be notified when a new order is received, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier'],
  },
  {
    action: 'Order Canceled',
    title: 'Order Canceled',
    text: 'If you want to be notified when a cancellation request is received, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'Order Backorder Date Updated',
    title: 'Order Backordered',
    text: 'If you want to be notified when a supplier marks an order as backordered',
    context: ['retailer'],
  },
  {
    action: 'Order Ship To Address Updated',
    title: 'Order Ship To Address Updated',
    text: 'If you want to be notified when a ship to address changed request is received, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier'],
  },
  {
    action: 'Order Memo Created',
    title: 'Message Received',
    text: 'If you want to be notified when a new message is received on one of your orders, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'Order Returned',
    title: 'Return Received',
    text: 'If you want to be notified when a new RMA is created on one of your orders, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier', 'retailer'],
  },
  {
    action: 'RMA Completed',
    title: 'Return Approved/Rejected',
    text: 'If you want to be notified when one of your RMAs is approved or rejected, the platform will send an email notification to the email address or distribution list specified below:',
    context: ['supplier', 'retailer'],
  },
]

type ChildProps = {
  pushNotifications: any
  fetchNotifications: () => {}
}

const TransactionNotifications: React.FC<ChildProps> = ({
  pushNotifications,
  fetchNotifications,
}) => {
  const [retailerNotifications, setRetailerNotifications] = useState([])
  const [supplierNotifications, setSupplierNotifications] = useState([])

  useEffect(() => {
    const merchantNotifications = availableNotifications.filter(
      (notification) => notification.context.includes('retailer')
    )
    const brandNotifications = availableNotifications.filter((notification) =>
      notification.context.includes('supplier')
    )
    setRetailerNotifications(merchantNotifications)
    setSupplierNotifications(brandNotifications)
  }, [pushNotifications])

  const { isBrand } = getTenantType()

  const notificationsToRender = isBrand
    ? supplierNotifications
    : retailerNotifications

  return (
    <div>
      <GridRow padding={false}>
        <GridCol lg={6} md={6} sm={12}>
          {notificationsToRender.map((item) => (
            <PushNotification
              key={item.action}
              action={item.action}
              header={item.title}
              description={item.text}
              customClassName='mt-4 mb-4'
              pushNotifications={pushNotifications}
              reloadNotifications={fetchNotifications}
            />
          ))}
        </GridCol>
      </GridRow>
    </div>
  )
}

export default TransactionNotifications
