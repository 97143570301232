import API, { API_URL, RETAILER_API_URL, baseURL } from 'api'
import axios from 'axios'

export const getProducts = async ({ params = {} }) => {
  return await API.get(API_URL.GET_PRODUCTS(), { params })
}

export const postProduct = async ({ body }) => {
  return await API.post(API_URL.GET_PRODUCTS(), body)
}

export const getProductDetail = async ({ id, params = {} }) => {
  return await API.get(API_URL.PRODUCT_DETAIL({ id }), { params })
}

export const updateProductDetail = async ({ id, attributes }) => {
  return await API.patch(API_URL.PRODUCT_DETAIL({ id }), {
    attributes,
  })
}

export const updateProductSKU = async ({ id, identifier }) => {
  return await API.patch(API_URL.PRODUCT_DETAIL({ id }), {
    identifier,
  })
}

export const exportProducts = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_PRODUCTS(), { params })
}

export const uploadTempfile = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  const accessToken = sessionStorage.getItem('accessToken')
  return await axios.post(`${baseURL}v1/tempfiles/upload/`, formData, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-fabric-tenant-id': sessionStorage.getItem('accountId'),
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const queueFile = async (
  templateId: number,
  tempfileId: number,
  transformerIds?: number[]
) => {
  const requestBody: {
    template: { id: number }
    direction: string
    data_type: string
    source: string
    behavior: string
    info: { tempfile: number; transformer_ids?: number[] }
    file: { tempfile: { id: number } }
  } = {
    template: {
      id: templateId,
    },
    direction: 'import',
    data_type: 'products',
    source: 'console',
    behavior: 'merge',
    info: {
      tempfile: tempfileId,
      ...(transformerIds &&
        transformerIds.length > 0 && { transformer_ids: transformerIds }),
    },
    file: {
      tempfile: {
        id: tempfileId,
      },
    },
  }

  try {
    const enqueueResponse = await API.post(
      API_URL.POST_FEED_QUEUE_ENQUEUE(),
      requestBody
    )
    if (enqueueResponse?.data?.id) {
      const feedQueueId = enqueueResponse.data.id
      const runResponse = await API.put(
        API_URL.PUT_FEED_QUEUE_RUN({ feedQueueId })
      )
      return runResponse
    } else {
      throw new Error('Enqueue response did not contain an id.')
    }
  } catch (error) {
    console.error('Failed to queue file', error)
    throw error
  }
}

export const getImportDetails = async (feedQueueId) => {
  return await API.get(API_URL.GET_FEED_QUEUE({ feedQueueId }))
}

export const downloadSampleFile = async (templateId, templateName) => {
  try {
    const response = await API.get(
      API_URL.DOWNLOAD_SAMPLE_FILE({ templateId }),
      { responseType: 'blob' }
    )

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', templateName + '.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setTimeout(() => window.URL.revokeObjectURL(url), 0)
  } catch (error) {
    console.error('Error downloading template mapping:', error)
  }
}

export const getRetailerProducts = async ({ params = {} }) => {
  return await API.get(RETAILER_API_URL.GET_PRODUCTS(), { params })
}

export const getFeedRequests = async ({ params = {} }) => {
  return await API.get(API_URL.FEED_REQUESTS(), { params })
}

export const getFeedRequestDetail = async ({ params = {}, id }) => {
  return await API.get(API_URL.FEED_REQUEST_DETAIL({ id }), { params })
}

export const addFeedRequest = async ({ body }) => {
  return await API.post(API_URL.FEED_REQUESTS(), body)
}
