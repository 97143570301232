import React, { useEffect, useState } from 'react'
import { RootState } from 'store'
import { useSelector, useDispatch } from 'react-redux'
import Card from 'atoms/card/card'
import CardLoading from 'molecules/loading/cardLoading'
import { useNavigate } from 'react-router-dom'
import Input from 'atoms/input/input'
import { setCurrentStep, setIntegration } from 'store/onboardingSlice'
import { useToast } from '@teamfabric/copilot-ui'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import {
  getPlatformAccounts,
  createPlatformAccounts,
} from 'api/platformAccount'
import CardHeader from 'molecules/cards/cardHeader'
import { patchAssignTask } from 'api/onboarding'
import { PATHS } from 'routes/pageRoutes'

type BigCommerceSetupProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
  reseting: boolean
}

const BigCommerceSetup: React.FC<BigCommerceSetupProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
  reseting,
}) => {
  const title = 'BigCommerce Setup'
  const INTEGRATION_METHOD =
    ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()

  const [bigcommerceAccount, setBigcommerceAccount] = useState({
    id: '',
  })
  const [loading, setLoading] = useState(false)
  const [hash, setHash] = useState('')
  const [token, setToken] = useState('')
  const [isAccountExist, setAccountExists] = useState(false)
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )

  const loadAccount = async () => {
    try {
      setLoading(true)
      const {
        data: { count, results },
      } = await getPlatformAccounts({
        params: { platform: INTEGRATION_METHOD.BIGCOMMERCE.TASK_NAME },
      })
      if (count > 0) {
        setAccountExists(true)
        setBigcommerceAccount(results[0])
        const hashCredential = results[0].credentials.find(
          (credential) => Object.keys(credential)[0] === 'bigcommerce_shop_hash'
        )
        const tokenCredential = results[0].credentials.find(
          (credential) => Object.keys(credential)[0] === 'bigcommerce_token'
        )
        setHash(hashCredential?.bigcommerce_shop_hash?.code || '')
        setToken(tokenCredential?.bigcommerce_token?.code || '')
      }
      setLoading(false)
    } catch (error) {
      showToast({
        label: "Couldn't load BigCommerce account",
        isDismissable: true,
        variant: 'error',
        id: '3',
      })
    }
  }

  // First loaded
  useEffect(() => {
    ;(async () => {
      await loadAccount()
    })()
  }, [])

  useEffect(() => {
    setIsNextButtonDisabled(hash === '' || token === '')
  }, [hash, token])

  const addPlatformAccount = async () => {
    try {
      const body = {
        platform: { code: INTEGRATION_METHOD.BIGCOMMERCE.TASK_NAME },
        credentials: [
          {
            credential: { code: 'bigcommerce_shop_hash' },
            value: hash,
          },
          {
            credential: { code: 'bigcommerce_token' },
            value: token,
          },
        ],
      }
      await createPlatformAccounts({ body })
      showToast({
        label: 'BigCommerce setup step added',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: "Couldn't add BigCommerce setup step",
        isDismissable: true,
        variant: 'error',
        id: '2',
      })
    }
  }

  const updateIntegrationSlice = () => {
    dispatch(
      setIntegration({
        taskId: integration.taskId,
        selectedIntegrationInfo: {
          ...integration.selectedIntegrationInfo,
          bigcommerce: true,
        },
        processNum: 2,
        introDone: true,
        completed: false,
        selectedMethod: integration.selectedMethod,
      })
    )
  }

  const updateBigcommerceTask = async () => {
    try {
      const body = {
        info: {
          bigcommerce: true,
        },
      }
      await patchAssignTask({
        taskId: integration.selectedIntegrationInfo.taskIds.bigcommerce,
        body,
      })
    } catch (error) {
      showToast({
        label: "Couldn't update assign task",
        isDismissable: true,
        variant: 'error',
        id: '3',
      })
    }
  }

  // next button is clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setLoading(true)
      setIsNextClicked(false)
      if (bigcommerceAccount.id === '') {
        await addPlatformAccount()
      }
      await updateBigcommerceTask()
      setLoading(false)
      updateIntegrationSlice()
      dispatch(
        setCurrentStep({
          value:
            ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.TASK_NAME,
          parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
        })
      )
    })()
  }, [isNextClicked])

  // Save and exit
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setLoading(true)
      setIsSaveExitClicked(false)
      if (bigcommerceAccount.id === '') {
        await addPlatformAccount()
      }
      await updateBigcommerceTask()
      updateIntegrationSlice()
      setLoading(false)
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {(loading || reseting) && <CardLoading label={title} num={3} />}
      {!loading && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={title}
              banner={{
                label:
                  'After adding this integration it can be configured in settings',
              }}
            />
          }
          bodyContent={
            <>
              <div className='mb-3'>
                <Input
                  width='100%'
                  label='Store Identifier'
                  required
                  inputProps={{
                    value: hash,
                    disabled: isAccountExist,
                    placeholder: 'Store Identifier',
                    onChange: ({ target: { value } }) => {
                      setHash(value)
                    },
                  }}
                />
              </div>
              <Input
                width='100%'
                label='Store Token'
                required
                inputProps={{
                  value: token,
                  disabled: isAccountExist,
                  placeholder: 'Store Token',
                  onChange: ({ target: { value } }) => {
                    setToken(value)
                  },
                }}
              />
            </>
          }
        />
      )}
    </>
  )
}

export default BigCommerceSetup
