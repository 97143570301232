import { FC, useEffect, useState } from 'react'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import { getOrderDetail } from 'api/orders'
import { getPlatformAccounts } from 'api/platformAccount'
import { useParams } from 'react-router-dom'
import OrderDetailsHeader from 'molecules/order/header'
import OrderIdentifiers from './components/orderIdentifiers'
import KeyDates from './components/keyDates'
import ExtraDetails from './components/extras'
import OrderLines from './components/orderLines'
import Shipping from './components/shipping'
import TrackingNumbers from './components/trackingNumbers'
import Invoices from './components/invoices'
import OrderActivity from './components/orderActivity'
import CancelItemsModal from '../components/modal/orders/cancelItems'
import ChangeShipToModal from '../components/modal/orders/changeShipTo'
import ShopifyExceptionBanner from './components/shopifyException'
import { GridCol, GridRow, Box } from 'atoms'
import CardWithMemos from 'molecules/cards/cardWithMemos'
import OrderTag from './components/orderTag'

const OrderDetailsTemplate: FC = () => {
  // const params = useSelector((state: RootState) => state.merchant.params)
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [platformLoading, setPlatformLoading] = useState(false)
  const [data, setData] = useState<any>({})

  // Modals
  const [showCancelItemsModal, setShowCancelItemsModal] = useState(false)
  const [showChangeShipToModal, setShowChangeShipToModal] = useState(false)

  useEffect(() => {
    ;(async () => {
      await fetchOrderDetails()
      await fetchPlatformAccounts()
    })()
  }, [])

  const fetchOrderDetails = async () => {
    const { data } = await getOrderDetail({ id })
    setData(data)
    setLoading(false)
  }

  const [platformAccounts, setPlatformAccounts] = useState([])

  const fetchPlatformAccounts = async () => {
    setPlatformLoading(true)
    const {
      data: { results },
    } = await getPlatformAccounts({ params: { platform: 'shopify' } })
    setPlatformAccounts(results)
    setPlatformLoading(false)
  }

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.ORDER_DETAILS)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <OrderDetailsHeader
            loading={loading}
            details={data}
            setShowCancelItemsModal={setShowCancelItemsModal}
            setShowChangeShipToModal={setShowChangeShipToModal}
          />
          <ShopifyExceptionBanner
            loading={loading}
            details={data}
            platformAccounts={platformAccounts}
            reloadOrder={fetchOrderDetails}
          />
          <OrderIdentifiers
            loading={loading || platformLoading}
            details={data}
            platformAccounts={platformAccounts}
          />
          <KeyDates loading={loading} details={data} />
          <Shipping loading={loading} details={data} />
          <TrackingNumbers loading={loading} details={data} />
          <ExtraDetails loading={loading} details={data} />
          <OrderTag loading={loading} details={data} />
          <OrderLines
            loading={loading}
            details={data}
            reloadOrder={fetchOrderDetails}
          />
          <Invoices loading={loading} details={data} />
          <CardWithMemos
            customClassName='mb-4'
            orderId={data?.id}
            memoData={data?.memos}
            loading={loading}
            cardProps={{
              headerContent: (
                <div>
                  <h5 className='m-0 h5 mb-1'>Messages</h5>
                  <p className='m-0'>
                    Messages that can be seen by all parties
                  </p>
                </div>
              ),
            }}
          />
          <OrderActivity />
          {/* Modals */}
          {showCancelItemsModal && (
            <CancelItemsModal
              orderDetails={data}
              setShowCancelItemsModal={setShowCancelItemsModal}
              showCancelItemsModal={showCancelItemsModal}
              reloadOrder={fetchOrderDetails}
            />
          )}
          {showChangeShipToModal && (
            <ChangeShipToModal
              orderDetails={data}
              setShowChangeShipToModal={setShowChangeShipToModal}
              showChangeShipToModal={showChangeShipToModal}
              reloadOrder={fetchOrderDetails}
            />
          )}
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OrderDetailsTemplate
