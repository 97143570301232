export const TASKS_VALUES = {
  QUESTIONNAIRE: {
    VALUE: 'questionnaire',
    TASKS: {
      EXPERIENCE: 'experience',
      INTEGRATION: 'integration',
    },
  },
  CALL: {
    VALUE: 'call',
    TASKS: {
      CALL_INTRO: 'call_intro',
      CALL: 'call',
      SKIP: 'skip',
      CALL_COMPLETED: 'call_completed',
    },
  },
  INFO: {
    VALUE: 'info',
    TASKS: {
      BILLING: 'billing',
      LOCATIONS: 'locations',
      CONTACTS: 'contacts',
    },
  },
  NOTIFICATION: {
    VALUE: 'notifications',
    TASKS: {
      TRANSACTION: 'transaction',
      DIGEST: 'digest',
    },
  },
  INTEGRATION: {
    VALUE: 'integration',
    TASKS: {
      INTEGRATION_METHOD: 'integration_method',
      CREATE_EDI: 'create_edi',
      CREATE_SAMPLE_ITEMS: 'create_sample_items',
      UPLOAD_INVENTORY: 'upload_inventory',
      CREATE_ORDERS: 'create_orders',
      UPLOAD_FULFILLMENT: 'upload_fulfillment',
      UPLOAD_INVOICE: 'upload_invoice',
      SELF_SERVE_EDI: 'self-serve-edi',
      FABRIC: 'console',
      SHOPIFY: 'shopify',
      API: 'api',
      BIGCOMMERCE: 'bigcommerce',
      WOOCOMMERCE: 'woocommerce',
      SHIPSTATION: 'shipstation',
      COMPLETE_SETUP: 'complete_setup',
    },
  },
  COMPLETE: {
    VALUE: 'complete',
  },
}

export const WELCOME_TASKS = {
  CODE: 'welcome',
  TASKS: [
    TASKS_VALUES.QUESTIONNAIRE.TASKS.EXPERIENCE,
    TASKS_VALUES.QUESTIONNAIRE.TASKS.INTEGRATION,
    TASKS_VALUES.CALL.TASKS.CALL_INTRO,
    TASKS_VALUES.CALL.TASKS.CALL,
    TASKS_VALUES.CALL.TASKS.CALL_COMPLETED,
    TASKS_VALUES.CALL.TASKS.SKIP,
  ],
}

export const ONBOARDING_TASKS = {
  QUESTIONNAIRE: {
    NAME: 'Questionnaire',
    VALUE: TASKS_VALUES.QUESTIONNAIRE.VALUE,
    NEXT: TASKS_VALUES.CALL.VALUE,
    TITLE: 'Introductory Questionnaire',
    DESCRIPTION: 'Note your experience level and preferences',
    LINK: `/onboarding/${TASKS_VALUES.QUESTIONNAIRE.VALUE}`,
    TASK_NUM: 2,
    SUB_TASKS: {
      EXPERIENCE: {
        IS_STEP: true,
        NAME: 'Experience Level',
        VALUES: {
          ADVANCED: {
            LABEL:
              'We currently work with other marketplaces or direct ship programs',
            VALUE: 'Advanced',
          },
          NEW: {
            LABEL:
              'This will be our first experience working with a marketplace or direct ship program',
            VALUE: 'New',
          },
        },
        TASK_NAME: TASKS_VALUES.QUESTIONNAIRE.TASKS.EXPERIENCE,
      },
      INTEGRATION: {
        IS_STEP: true,
        NAME: 'Integration Plan',
        VALUES: {
          EDI: {
            LABEL: 'EDI (850, 856, 810, 855, 860, & 846)',
            VALUE: 'EDI',
          },
          SHIP_STATION: {
            LABEL: 'ShipStation',
            VALUE: 'ShipStation',
          },
          SHOPIFY: {
            LABEL: 'Shopify',
            VALUE: 'Shopify',
          },
          WOOCOMMERCE: {
            LABEL: 'WooCommerce',
            VALUE: 'WooCommerce',
          },
          BIGCOMMERCE: {
            LABEL: 'BigCommerce',
            VALUE: 'BigCommerce',
          },
          SUPPLIER_PORTAL: {
            LABEL: 'Supplier Portal',
            VALUE: 'Supplier Portal',
          },
          API: {
            LABEL: 'API',
            VALUE: 'API',
          },
          UNKNOWN: {
            LABEL: "We don't know yet or we want to learn more",
            VALUE: 'Do Not Know',
          },
          OTHER: {
            LABEL: 'Other',
            VALUE: 'Other',
          },
        },
        TASK_NAME: TASKS_VALUES.QUESTIONNAIRE.TASKS.INTEGRATION,
      },
    },
  },
  CALL: {
    NAME: 'Onboarding Call',
    VALUE: TASKS_VALUES.CALL.VALUE,
    PREV: TASKS_VALUES.QUESTIONNAIRE.VALUE,
    NEXT: TASKS_VALUES.INFO.VALUE,
    TITLE: 'Schedule Onboarding Call',
    DESCRIPTION: 'Familiarize yourself with Marketplace and our support team',
    LINK: `/onboarding/${TASKS_VALUES.CALL.VALUE}`,
    TASK_NUM: 1,
    SUB_TASKS: {
      CALL_INTRO: {
        IS_STEP: true,
        NAME: 'Preparing for the Onboarding Call',
        TASK_NAME: TASKS_VALUES.CALL.TASKS.CALL_INTRO,
      },
      CALL: {
        IS_STEP: false,
        NAME: '',
        TASK_NAME: TASKS_VALUES.CALL.TASKS.CALL,
      },
      SKIP: {
        IS_STEP: false,
        NAME: '',
        TASK_NAME: TASKS_VALUES.CALL.TASKS.SKIP,
        REASONS: [
          {
            ID: 1,
            NAME: "I've already onboarded with {{ app.productName }}",
            VALUE: 'Already Onboarded',
          },
          {
            ID: 2,
            NAME: 'I prefer a self-serve experience',
            VALUE: 'Self-Serve',
          },
          {
            ID: 3,
            NAME: "I'm just testing",
            VALUE: 'Testing',
          },
          {
            ID: 4,
            NAME: 'Other',
            VALUE: 'Other',
          },
        ],
      },
      CALL_COMPLETED: {
        IS_STEP: false,
        NAME: 'completed',
        TASK_NAME: TASKS_VALUES.CALL.TASKS.CALL_COMPLETED,
      },
    },
  },
  INFO: {
    NAME: 'Business Info',
    VALUE: TASKS_VALUES.INFO.VALUE,
    PREV: TASKS_VALUES.CALL.VALUE,
    NEXT: TASKS_VALUES.NOTIFICATION.VALUE,
    TITLE: 'Business information',
    DESCRIPTION: 'Add business information and contacts',
    LINK: `/onboarding/${TASKS_VALUES.INFO.VALUE}`,
    TASK_NUM: 3,
    SUB_TASKS: {
      BILLING: {
        IS_STEP: true,
        NAME: 'Billing Address',
        TASK_NAME: TASKS_VALUES.INFO.TASKS.BILLING,
        PREVIOUS_TASK: TASKS_VALUES.CALL.TASKS.CALL_COMPLETED,
        PREVIOUS_PARENT_TASK: TASKS_VALUES.CALL.VALUE,
      },
      LOCATIONS: {
        IS_STEP: true,
        NAME: 'Default Fulfillment Location',
        TASK_NAME: TASKS_VALUES.INFO.TASKS.LOCATIONS,
        PREVIOUS_TASK: TASKS_VALUES.INFO.TASKS.BILLING,
        PREVIOUS_PARENT_TASK: TASKS_VALUES.INFO.VALUE,
      },
      CONTACTS: {
        IS_STEP: true,
        NAME: 'Contact Persons',
        TASK_NAME: TASKS_VALUES.INFO.TASKS.CONTACTS,
        PREVIOUS_TASK: TASKS_VALUES.INFO.TASKS.LOCATIONS,
        PREVIOUS_PARENT_TASK: TASKS_VALUES.INFO.VALUE,
      },
    },
  },
  NOTIFICATIONS: {
    NAME: 'Notifications',
    VALUE: TASKS_VALUES.NOTIFICATION.VALUE,
    PREV: TASKS_VALUES.INFO.VALUE,
    NEXT: TASKS_VALUES.INTEGRATION.VALUE,
    TITLE: 'Notification Preferences',
    DESCRIPTION: 'Configure notifications for critical events',
    CODE: 'notifications',
    LINK: `/onboarding/${TASKS_VALUES.NOTIFICATION.VALUE}`,
    TASK_NUM: 2,
    SUB_TASKS: {
      TRANSACTION: {
        IS_STEP: true,
        NAME: 'Transaction Notification',
        TASK_NAME: TASKS_VALUES.NOTIFICATION.TASKS.TRANSACTION,
        PREVIOUS_TASK: 'contacts',
        PREVIOUS_PARENT_TASK: TASKS_VALUES.INFO.VALUE,
      },
      DIGEST: {
        IS_STEP: true,
        NAME: 'Digest Notifications',
        TASK_NAME: TASKS_VALUES.NOTIFICATION.TASKS.DIGEST,
        PREVIOUS_TASK: TASKS_VALUES.NOTIFICATION.TASKS.TRANSACTION,
        PREVIOUS_PARENT_TASK: TASKS_VALUES.NOTIFICATION.VALUE,
      },
    },
  },
  INTEGRATION: {
    NAME: 'Integrations',
    VALUE: TASKS_VALUES.INTEGRATION.VALUE,
    PREV: TASKS_VALUES.NOTIFICATION.VALUE,
    NEXT: TASKS_VALUES.COMPLETE.VALUE,
    TITLE: 'Transaction Integration',
    DESCRIPTION:
      'Select and setup preferred transaction and inventory integration',
    LINK: `/onboarding/${TASKS_VALUES.INTEGRATION.VALUE}`,
    TASK_NUM: 2,
    EDI_CODE: 'edi',
    SUB_TASKS: {
      INTEGRATION_METHOD: {
        IS_STEP: false,
        CODE: 'integration',
        NAME: 'Select Integration Method',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.INTEGRATION_METHOD,
        METHODS: {
          EDI: {
            NAME: 'EDI: Managed Onboarding',
            TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.SELF_SERVE_EDI,
          },
          // FABRIC: {
          //   NAME: 'fabric Supplier Portal',
          //   TASK_NAME: TASKS_VALUES.INTEGRATIONS.TASKS.FABRIC,
          // },
          SHIPSTATION: {
            NAME: 'ShipStation Setup',
            TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.SHIPSTATION,
          },
          // SHOPIFY: {
          //   NAME: 'Shopify',
          //   TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.SHOPIFY,
          // },
          BIGCOMMERCE: {
            NAME: 'BigCommerce',
            TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.BIGCOMMERCE,
          },
          // WOOCOMMERCE: {
          //   NAME: 'WooCommerce',
          //   TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.WOOCOMMERCE,
          // },
          API: {
            NAME: 'API',
            TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.API,
          },
        },
      },
      COMPLETE_SETUP: {
        IS_STEP: false,
        NAME: 'Complete setup',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.COMPLETE_SETUP,
      },
    },
    EDI_TASKS: {
      CREATE_EDI: {
        NAME: 'Create EDI Account',
        CODE: 'createAccount',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.CREATE_EDI,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.CREATE_SAMPLE_ITEMS,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.INTEGRATION_METHOD,
      },
      CREATE_SAMPLE_ITEMS: {
        NAME: 'Create Sample Items',
        CODE: 'createSampleItems',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.CREATE_SAMPLE_ITEMS,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_INVENTORY,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.CREATE_EDI,
      },
      UPLOAD_INVENTORY: {
        NAME: 'Upload Inventory',
        CODE: 'uploadInventory',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_INVENTORY,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.CREATE_ORDERS,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.CREATE_SAMPLE_ITEMS,
      },
      CREATE_ORDERS: {
        NAME: 'Create Orders',
        CODE: 'createOrders',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.CREATE_ORDERS,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_FULFILLMENT,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_INVENTORY,
      },
      UPLOAD_FULFILLMENT: {
        NAME: 'Upload Fulfillment',
        CODE: 'uploadFulfillment',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_FULFILLMENT,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_INVOICE,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.CREATE_ORDERS,
      },
      UPLOAD_INVOICE: {
        NAME: 'Upload Invoice',
        CODE: 'uploadInvoices',
        TASK_NAME: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_INVOICE,
        NEXT_TASK: TASKS_VALUES.INTEGRATION.TASKS.COMPLETE_SETUP,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.UPLOAD_FULFILLMENT,
      },
    },
  },
  COMPLETE: {
    NAME: 'Complete Onboarding',
    VALUE: TASKS_VALUES.COMPLETE.VALUE,
    PREV: TASKS_VALUES.INTEGRATION.VALUE,
    TITLE: 'Complete Onboarding',
    DESCRIPTION: 'Confirm completion of all onboarding tasks',
    LINK: `/onboarding/${TASKS_VALUES.COMPLETE.VALUE}`,
    TASK_NUM: 1,
    SUB_TASKS: {
      COMPLETE: {
        IS_STEP: true,
        TASK_NAME: TASKS_VALUES.COMPLETE.VALUE,
        PREVIOUS_TASK: TASKS_VALUES.INTEGRATION.TASKS.COMPLETE_SETUP,
        PREVIOUS_PARENT_TASK: TASKS_VALUES.INTEGRATION.VALUE,
      },
    },
  },
}
