import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { getCompatibilityRulesets } from 'api/rulesets'

type ChildProps = {
  handleUpdate: (data: any) => void
  fieldLabel?: string
  fieldPlaceholder?: string
  selectedRuleset?: any
}

const SelectRuleset: React.FC<ChildProps> = ({
  fieldLabel,
  fieldPlaceholder,
  handleUpdate,
  selectedRuleset,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(selectedRuleset)
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data } = await getCompatibilityRulesets({
        params: { order_by: 'name' },
      })
      const options = []
      if (data.count > 0) {
        data.results.map((el) => {
          options.push({
            id: el.id,
            label: el.name,
          })
        })
      }
      setOptions(options)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!initialEffectRun && !loading && selectedRuleset?.id) {
      let option = options.find((el) => el.id === selectedRuleset.id)
      setSelectedOption({
        id: option.id,
        label: option.label,
      })
      setInitialEffectRun(true)
    }
  }, [!loading, selectedRuleset, initialEffectRun, options])

  return (
    <Dropdown
      required
      label={fieldLabel || 'Ruleset'}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder={fieldPlaceholder || 'Select a ruleset'}
      disabled={loading}
    />
  )
}

export default SelectRuleset
