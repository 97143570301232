import React from 'react'
import {
  CheckboxGroup as CheckboxGroupUI,
  CheckboxGroupSelectedValue,
} from '@teamfabric/copilot-ui'

type CheckboxGroupProps = {
  label: string
  name: string
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    currentValue: CheckboxGroupSelectedValue[]
  ) => void
  options: any
  required?: boolean
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ ...props }) => {
  return <CheckboxGroupUI {...props} />
}

export default CheckboxGroup
