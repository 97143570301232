import { SkeletonH4 as SkeletonH4UI } from '@teamfabric/copilot-ui'

type SkeletonH4Props = {
  variant?: 'light'
  width: string
  style?: any
}

const SkeletonH4: React.FC<SkeletonH4Props> = ({ ...props }) => {
  return (
    <div style={props.style}>
      <SkeletonH4UI {...props} />
    </div>
  )
}

export default SkeletonH4
