import React, { useMemo, useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, SingleDatePicker, Button, RadioGroup, Radio } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { shopifySyncProducts } from 'api/shopify'
import { calculateDaysDiff, getNextXDays, getXMonthAgo, getYYYYMMDD, minIsBeforeMax } from 'lib/utils/date/formatDate'

type ChildProps = {
}

const SyncVariantsByDateCard: React.FC<ChildProps> = ({
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [job, setJob] = useState(null)
  const [optionValue, setSelectedOptionValue] = useState(null)
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())

  const onSync = async () => {
    setSaving(true)
    const body = {
      use_celery: true,
      ...(optionValue === 'date_range' && {
        created_at_min: getYYYYMMDD(minDate),
        created_at_max: getYYYYMMDD(maxDate)
      })
    }
    try {
      const { data } = await shopifySyncProducts({ platformAccountId: id, body })
      setJob(data)
      showToast({
        label: 'Sync scheduled!',
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'Sync failed!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const isValidDateRange = useMemo(() => {
    const isMinBeforeMax = minIsBeforeMax(minDate, maxDate)
    const noOfDays = calculateDaysDiff(minDate, maxDate)
    return (noOfDays < 8 && isMinBeforeMax)
  }, [minDate, maxDate])

  const isReady = useMemo(() => {
    if (!optionValue) return false
    else if (optionValue === 'all_products') return true
    else if (optionValue === 'date_range') {
      return isValidDateRange
    }
    else false
  }, [optionValue, minDate, maxDate])

  return (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Sync Variants by Date</span>}
            h2Text={
              <span className='body2-regular'>
                Attempt to sync variants between Shopify and fabric using a date range.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            This utility searches your Shopify store for all items created
            within specified date range and attempts to sync them with
            variants in fabric. We will check if the Shopify variant's
            SKU field matches the Merchant SKU, the UPC
            or the Supplier SKU (in that order).
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <RadioGroup
                label='Select Date Range'
                name='sync_variants'
                value={optionValue}
                onChange={({ target: { value } }) => setSelectedOptionValue(value)}
                required
                options={[
                  {
                    label: "Look for products created between two given dates",
                    value: 'date_range',
                  }
                ]}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={12}>
              <SingleDatePicker
                label={'Select minimum date'}
                value={minDate}
                defaultValue={minDate}
                minDate={getXMonthAgo(1)}
                maxDate={getNextXDays(7)}
                format='MM/dd/yyyy'
                onSelect={(date: Date) => setMinDate(date)}
              />
            </GridCol>
            <GridCol lg={6} md={6} sm={12}>
              <SingleDatePicker
                label={'Select maximum date'}
                minDate={getXMonthAgo(1)}
                maxDate={getNextXDays(7)}
                value={maxDate}
                defaultValue={maxDate}
                format='MM/dd/yyyy'
                onSelect={(date: Date) => setMaxDate(date)}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <Radio
                name=''
                label='Run sync against all products (Can be very, very slow)'
                value='all_products'
                checked={optionValue === 'all_products'}
                onChange={({ target: { value } }) => setSelectedOptionValue(value)}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onSync()}
                text='Run Sync'
                variant='secondary'
                isDisabled={
                  saving || !isReady
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default SyncVariantsByDateCard
