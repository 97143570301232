import React, { useState, useEffect } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Banner, Dropdown } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import { deleteShopifyWebhook, registerShopifyWebhook } from 'api/shopify'

type ChildProps = {
  loading: boolean
  loadWebhooks: (quiet?: boolean) => void
  webhooks: any
}

const InventoryCard: React.FC<ChildProps> = ({
  loading,
  loadWebhooks,
  webhooks
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [toggleValue, setToggleValue] = useState('0')
  const [selectedTopic, setSelectedTopic] =
    useState(dropDownInitialObj)
  const [showSelectedTopic, setShowSelectedTopic] = useState(false)

  const topics = [{
    label: 'Product/Inventory Updated (Recommended)',
    id: 'products/update'
  }
  ]

  useEffect(() => {
    if (!loading && webhooks.length > 0) {
      const selectedTopics = webhooks
        .filter(el => ['products/update'].includes(el.topic))
        .map(webhook => ({
          id: webhook.id,
          label: 'Product/Inventory Updated',
          value: webhook.platform_identifier,
        }))
      if (selectedTopics.length > 0) {
        setToggleValue('1')
        setSelectedTopic(selectedTopics[0])
        setShowSelectedTopic(true)
      }
    }
  }, [!loading, webhooks])

  const updateNotification = async (updatedValue) => {
    try {
      const toastMessage = updatedValue === '1' ? 'Sync enabled successfully!' : 'Sync disabled successfully!'
      if (updatedValue === '1') {
        await enableWebhook()
      } else {
        await disableWebhook()
        setSelectedTopic(dropDownInitialObj)
        setShowSelectedTopic(false)
      }
      showToast({
        label: toastMessage,
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data ? response.data : 'An error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      loadWebhooks()
    }
  }

  const enableWebhook = async () => {
    const body = {
      webhook_topic: selectedTopic.id
    }
    await registerShopifyWebhook({ platformAccountId: id, body })
  }

  const disableWebhook = async () => {
    await deleteShopifyWebhook({ platformAccountId: id, webhookId: selectedTopic.id })
  }


  const SelectWebhook = () => (
    <div className='mt-4'>
      <GridRow padding={false}>
        <GridCol lg={12} md={12} sm={12}>
          <Dropdown
            width='100%'
            label='Select Webhook'
            placeholder='Select a topic'
            required={true}
            value={selectedTopic}
            onChange={(option) => setSelectedTopic(option)}
            options={topics}
          />
        </GridCol>
      </GridRow>
    </div>
  )

  const ShowSelectedWebhook = () => (
    <div className='mt-4'>
      <Banner
        label={
          <>
            <p>{selectedTopic.label}</p>
            <p>This integration is currently enabled via webhook id {selectedTopic.id} (Shopify ID: {selectedTopic.value})</p>
          </>
        }
        variant='success'
      />
    </div>
  )

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Inventory</span>}
            h2Text={
              <span className='body2-regular'>
                Configure how your Shopify store syncs inventory with fabric.
              </span>
            }
            switchProps={{
              label: '',
              stateLabels: {
                OFF: 'Off',
                ON: 'On',
              },
              disabled: !selectedTopic.id,
              checked: toggleValue === '1',
              onChange: ({ target: { checked } }) => {
                const value = checked ? '1' : '0'
                setToggleValue(value)
                updateNotification(value)
              },
            }}
            dataTestid='shopify-inventory-header'
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            fabric can automatically sync supplier inventory movements to your Shopify store.
          </div>
          {!showSelectedTopic ? <SelectWebhook /> : <ShowSelectedWebhook />}
        </Box>
      }
    />
  )
}

export default InventoryCard
