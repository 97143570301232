import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import {
  getProductDetail,
  updateProductDetail,
  updateProductSKU,
} from 'api/products'
import Button from 'atoms/button/button'
import ProductHeader from 'molecules/product/header'
import ItemInfo from './components/itemInfo'
import ItemInventory from './components/itemInventory'
import ItemAttributes from './components/itemAttributes'
import Modal from 'atoms/modal/modal'
import UpdateInventory from '../../components/modals/updateInventory'
import UpdateSKU from '../../components/modals/updateSKU'
import { useToast } from '@teamfabric/copilot-ui'

const ProductDetail: React.FC = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [data, setData] = useState<any>({})
  const [showUpdateInventoryModal, setShowUpdateInventoryModal] =
    useState(false)
  const [showUpdateSKUModal, setShowUpdateSKUModal] = useState(false)
  const [marketingAttributes, setMarketingAttributes] = useState([])
  const [imageAttributes, setImageAttributes] = useState([])
  const [pricingAttributes, setPricingAttributes] = useState([])
  const [shippingAttributes, setShippingAttributes] = useState([])
  const [miscAttributes, setMiscAttributes] = useState([])
  const [platformAccountIdentifiers, setPlatformAccountIdentifiers] = useState(
    []
  )
  const [updateSKUButtonDisabled, setUpdateSKUButtonDisabled] = useState(true)
  const [newSKU, setNewSKU] = useState('')
  const [inventoryAttributes, setInventoryAttributes] = useState(null)

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.PRODUCT_DETAILS)
  }, [])

  const showToast = useToast()

  const sortItemAttributes = (attributes) => {
    let marketingAtts = []
    let pricingAtts = []
    let imageAtts = []
    let shippingAttrs = []
    let miscAtts = []

    Object.keys(attributes).forEach((code) => {
      if (attributes[code].grouping === 'description') {
        marketingAtts.push(attributes[code])
      } else if (attributes[code].grouping === 'pricing') {
        pricingAtts.push(attributes[code])
      } else if (attributes[code].grouping === 'images') {
        imageAtts.push(attributes[code])
      } else if (attributes[code].grouping === 'shipping') {
        shippingAttrs.push(attributes[code])
      } else if (attributes[code].grouping === 'misc') {
        miscAtts.push(attributes[code])
      }
    })

    setMarketingAttributes(marketingAtts)
    setPricingAttributes(pricingAtts)
    setImageAttributes(imageAtts)
    setShippingAttributes(shippingAttrs)
    setMiscAttributes(miscAtts)
  }

  const formatPlatformAccountAttributes = (attributes) => {
    let platformIdentifiers = []

    attributes.forEach((attribute) => {
      const label = attribute['platform_account_id']
      const value = attribute['identifier']
      platformIdentifiers.push({
        [label]: value,
      })
    })

    setPlatformAccountIdentifiers(platformIdentifiers)
  }

  const handleUpdateSKU = async () => {
    setLoading(true)

    try {
      await updateProductSKU({ id: data.id, identifier: newSKU })
      setShowUpdateSKUModal(false)
    } catch (error) {
      console.error('Failed to update SKU:', error)
    }

    setData((prevData: any) => ({
      ...prevData,
      brand_identifier: newSKU,
    }))

    setLoading(false)

    showToast({
      label: 'Successfully updated SKU',
      isDismissable: true,
      id: '1',
    })
  }

  const updateInventoryAttributes = () => {
    if (inventoryAttributes) {
      handleUpdateProductDetail(inventoryAttributes)
    }
  }

  const handleUpdateProductDetail = async (attributes: any[]) => {
    setUpdating(true)
    try {
      await updateProductDetail({ id: data.id, attributes: attributes })
      setShowUpdateInventoryModal(false)
      showToast({
        label: 'Successfully updated inventory',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      console.error('Failed to update inventory:', error)
    } finally {
      await fetchProduct()
      setUpdating(false)
    }
  }

  const handleUpdate = (inventoryValues, status) => {
    let attributes = []
    attributes.push({
      attribute: {
        code: 'discontinued',
      },
      value: typeof status === 'number' ? status : status?.value,
    })

    data.inventory.locations.forEach((location, index) => {
      attributes.push({
        attribute: {
          code: 'inventory_warehouse',
        },
        location: {
          id: location.id,
        },
        value: inventoryValues[index] || 0,
      })
    })
    return attributes
  }

  const onProductInventoryChange = (inventory, status) => {
    const attributes = handleUpdate(inventory, status)
    setInventoryAttributes(attributes)
  }

  useEffect(() => {
    ;(async () => {
      await fetchProduct()
    })()
  }, [])

  const fetchProduct = async () => {
    const { data } = await getProductDetail({ id })
    if (data?.attributes) {
      sortItemAttributes(data.attributes)
    }
    if (data?.platform_account_identifiers?.length) {
      formatPlatformAccountAttributes(data.platform_account_identifiers)
    }
    setData(data)
    setLoading(false)
  }

  return (
    <div className='container'>
      {/* Update Inventory Modal */}
      <Modal
        headerText='Update Item Inventory'
        description=''
        size='small'
        onClose={() => setShowUpdateInventoryModal(false)}
        isVisible={showUpdateInventoryModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowUpdateInventoryModal(false),
            text: 'Cancel',
            variant: 'secondary',
            isLoading: updating,
          },
          {
            dataTestid: '',
            onClick: updateInventoryAttributes,
            text: 'Update Inventory',
            isLoading: updating,
          },
        ]}
        dataTestid={''}
        onBackdropClick={() => setShowUpdateInventoryModal(false)}
      >
        <UpdateInventory
          item={data}
          loading={loading}
          onProductInventoryChange={onProductInventoryChange}
        />
      </Modal>
      {/* Update SKU Modal */}
      <Modal
        headerText='Update Item SKU'
        description=''
        size='small'
        onClose={() => setShowUpdateSKUModal(false)}
        isVisible={showUpdateSKUModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowUpdateSKUModal(false),
            text: 'Cancel',
            variant: 'secondary',
            isLoading: loading,
          },
          {
            dataTestid: '',
            onClick: handleUpdateSKU,
            text: 'Update SKU',
            isDisabled: updateSKUButtonDisabled,
            isLoading: loading,
          },
        ]}
        dataTestid={''}
        onBackdropClick={() => setShowUpdateSKUModal(false)}
      >
        <UpdateSKU
          item={data}
          loading={loading}
          onSKUChange={(sku) => {
            setNewSKU(sku)
            setUpdateSKUButtonDisabled(!sku)
          }}
        />
      </Modal>
      <div className='space-between width-100 mt-5'>
        <ProductHeader variant={data} loading={loading} />
        <div className='gap-3 flex-end'>
          <Button
            onClick={() =>
              setShowUpdateInventoryModal(!showUpdateInventoryModal)
            }
            text='Update inventory'
            variant='secondary'
            isLoading={loading}
          />
          <Button
            onClick={() => setShowUpdateSKUModal(!showUpdateSKUModal)}
            text='Update SKU'
            isLoading={loading}
          />
        </div>
      </div>
      <div className='space-between width-100'>
        <ItemInfo item={data} loading={loading} />
        <ItemInventory item={data} loading={loading} updating={updating} />
      </div>
      <div className='space-between flex-wrap width-100'>
        {!loading && marketingAttributes.length != 0 && (
          <ItemAttributes
            label={'Marketing Attributes'}
            attributes={marketingAttributes}
          />
        )}
        {!loading && imageAttributes.length != 0 && (
          <ItemAttributes
            label={'Image Attributes'}
            attributes={imageAttributes}
          />
        )}
        {!loading && pricingAttributes.length != 0 && (
          <ItemAttributes
            label={'Pricing Attributes'}
            attributes={pricingAttributes}
          />
        )}
        {!loading && shippingAttributes.length != 0 && (
          <ItemAttributes
            label={'Shipping Attributes'}
            attributes={shippingAttributes}
          />
        )}
        {!loading && miscAttributes.length != 0 && (
          <ItemAttributes
            label={'Miscellaneous Attributes'}
            attributes={miscAttributes}
          />
        )}
        {!loading && platformAccountIdentifiers.length != 0 && (
          <ItemAttributes
            label={'Platform Account Identifiers'}
            attributes={platformAccountIdentifiers}
          />
        )}
      </div>
    </div>
  )
}

export default ProductDetail
