import API, { API_URL } from 'api'

export const postNotification = async ({ body }) => {
  return await API.post(API_URL.NOTIFICATIONS(), body)
}
export const patchNotification = async ({ id, body }) => {
  return await API.patch(API_URL.NOTIFICATIONS_DETAIL({ id }), body)
}

export const getNotification = async ({ params = {} }) => {
  return await API.get(API_URL.NOTIFICATIONS(), { params })
}
