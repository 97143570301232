import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, Input, PageHeader } from 'atoms'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const PackingSlipTemplate: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [value, setValue] = useState('')
  const [preference, setPreference] = useState(null)

  const defaultPackingSlipTemplate = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_packing_slip_template'])
    }
    return null
  }, [preferences, loading])

  useEffect(() => {
    if (typeof defaultPackingSlipTemplate !== 'undefined') {
      setPreference(defaultPackingSlipTemplate)
      if (defaultPackingSlipTemplate?.retailer_preference) {
        setValue(defaultPackingSlipTemplate?.retailer_preference?.value)
      }
    }
  }, [defaultPackingSlipTemplate])

  const handleUpdate = (template) => {
    if (preference && !loading) {
      const json = {
        preference: { id: preference?.id },
        value: template,
      }
      handleChange(json)
    }
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Packing Slip Template</span>}
              h2Text={
                <span className='body2-regular'>
                  Including a fabric Merchant-branded packing slip is often an
                  important component of the customer experience. The default
                  packing slip will include your logo and core order data
                  including the customer order number. If you have worked with
                  fabric Support to configure a custom packing slip, please
                  provide the template name.
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <Input
            width='50%'
            label='Default Packing Slip Template'
            inputProps={{
              placeholder: 'generic_rcn_packing_slip.html',
              onChange: ({ target: { value } }) => {
                setValue(value)
                handleUpdate(value)
              },
              value: value,
            }}
          />
        }
      ></Card>
    </Box>
  )
}

PackingSlipTemplate.defaultProps = defaultProps
export default PackingSlipTemplate
