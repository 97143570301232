import { SkeletonBadge as SkeletonBadgeUI } from '@teamfabric/copilot-ui'

type SkeletonBadgeProps = {
  variant?: 'light'
  width: string
  style?: any
}

const SkeletonBadge: React.FC<SkeletonBadgeProps> = ({ ...props }) => {
  return (
    <div style={props.style}>
      <SkeletonBadgeUI {...props} />
    </div>
  )
}

export default SkeletonBadge
