import React, { ReactNode } from 'react'
import Skeleton from 'atoms/skeleton/skeleton'
import Card from 'atoms/card/card'

type LoadingProps = {
  width?: string
  label?: ReactNode
  num?: number
  showCollapse?: boolean
  showDivider?: boolean
  open?: boolean
}

const CardLoading: React.FC<LoadingProps> = ({
  label,
  width = '100%',
  // number of Skeleton component lines, default to 3
  num = 3,
  showCollapse,
  showDivider = true,
  open = true,
}) => {
  const getSkeletonComponents = () => {
    return new Array(num).fill('').map((_, index) => (
      <div key={index} className='mb-3'>
        <Skeleton height='30px' width={width} />
      </div>
    ))
  }
  return (
    <Card
      showCollapse={showCollapse ? showCollapse : false}
      open={open}
      showDivider={showDivider}
      headerContent={label}
      bodyContent={getSkeletonComponents()}
    />
  )
}

export default CardLoading
