// TODO: add connections and tags once component available
// TODO: add supplier SKU and retailer SKU once component available/decided upon
// TODO: add is_merchandising_contact, is_department_owner and is_department_manager FOR MERCHANT ONLY once the above-mentioned components available refer to https://gitlab.com/fabric2/marketplace/platform/-/merge_requests/1230 for the new filters
import FilterModal from 'atoms/filter/filterModal'
import {
  applyFilters,
  resetLocalState,
  setInitialState,
} from 'lib/utils/filter/filterModal/actions'
import { date, radio } from 'lib/utils/filter/filterModal/itemTypes'
import { ORDERS } from 'modules/orders/constants'
import { FILTERS } from 'molecules/filter/constants'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { setBackorderedUntil, setFilters } from 'store/orderSlice'

type AllFiltersModalProps = {
  params: any
}

const OrdersAllFiltersModal: React.FC<AllFiltersModalProps> = ({ params }) => {
  const [localConnectionOptions, setLocalConnectionOptions] = useState([])
  const [localTagOptions, setLocalTagOptions] = useState([])
  const [localRetailerSKUCheckboxOptions, setLocalRetailerSKUCheckboxOptions] =
    useState([])
  const [localSupplierSKUCheckboxOptions, setLocalSupplierSKUCheckboxOptions] =
    useState([])
  const [localAcknowledged, setLocalAcknowledged] = useState(null)
  const [localBackordered, setLocalBackordered] = useState(null)
  const [localBackorderedAcknowledged, setLocalBackorderedAcknowledged] =
    useState(null)
  const [localRequiresFulfillment, setLocalRequiresFulfillment] = useState(null)
  const [localRequiresInvoice, setLocalRequiresInvoice] = useState(null)
  const [localHasUnreadMessages, setLocalHasUnreadMessages] = useState(null)
  const [localBackorderedUntil, setLocalBackorderedUntil] = useState(null)

  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [tagsCounter, setTagsCounter] = useState(0)
  const [retailerSKUCounter, setRetailerSKUCounter] = useState(0)
  const [supplierSKUCounter, setSupplierSKUCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [backorderedCounter, setBackorderedCounter] = useState(0)
  const [backorderedAcknowledgedCounter, setBackorderedAcknowledgedCounter] =
    useState(0)
  const [requiresFulfillmentCounter, setRequiresFulfillmentCounter] =
    useState(0)
  const [requiresInvoiceCounter, setRequiresInvoiceCounter] = useState(0)
  const [hasUnreadMessagesCounter, setHasUnreadMessagesCounter] = useState(0)
  const [backorderedUntilCounter, setBackorderedUntilCounter] = useState(0)

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.orders.connectionOptions
  )

  const tagCheckboxOptions = useSelector(
    (state: RootState) => state.orders.tagOptions
  )

  const supplierSkuOptionsCheckboxOptions = useSelector(
    (state: RootState) => state.orders.supplierSkuOptions
  )

  const retailerSkuOptionsCheckboxOptions = useSelector(
    (state: RootState) => state.orders.retailerSkuOptions
  )

  const backorderedUntil = useSelector(
    (state: RootState) => state.orders.backorderedUntil
  )

  const acknowledged = useMemo(() => {
    if (params?.is_acknowledged === '0') {
      return 'No'
    } else if (params?.is_acknowledged === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_acknowledged])

  const backordered = useMemo(() => {
    if (params?.is_backordered === '0') {
      return 'No'
    } else if (params?.is_backordered === '1') {
      return 'Yes'
    } else return null
  }, [params?.is_backordered])

  const isBackorderAcknowledged = useMemo(() => {
    if (params?.backorder_is_acknowledged === '0') {
      return 'No'
    } else if (params?.backorder_is_acknowledged === '1') {
      return 'Yes'
    } else return null
  }, [params?.backorder_is_acknowledged])

  const requiresFullfilment = useMemo(() => {
    if (params?.not_fully_shipped === '0') {
      return 'No'
    } else if (params?.not_fully_shipped === '1') {
      return 'Yes'
    } else return null
  }, [params?.not_fully_shipped])

  const requiresInvoice = useMemo(() => {
    if (params?.not_fully_invoiced === '0') {
      return 'No'
    } else if (params?.not_fully_invoiced === '1') {
      return 'Yes'
    } else return null
  }, [params?.not_fully_invoiced])

  const hasUnreadMemos = useMemo(() => {
    if (params?.has_unread_memos === '0') {
      return 'No'
    } else if (params?.has_unread_memos === '1') {
      return 'Yes'
    } else return null
  }, [params?.has_unread_memos])

  const allFiltersCount = useMemo(() => {
    return (
      connectionsCounter +
      tagsCounter +
      retailerSKUCounter +
      supplierSKUCounter +
      acknowledgedCounter +
      backorderedCounter +
      backorderedAcknowledgedCounter +
      requiresFulfillmentCounter +
      requiresInvoiceCounter +
      hasUnreadMessagesCounter +
      backorderedUntilCounter
    )
  }, [
    connectionsCounter,
    tagsCounter,
    retailerSKUCounter,
    supplierSKUCounter,
    acknowledgedCounter,
    backorderedCounter,
    backorderedAcknowledgedCounter,
    requiresFulfillmentCounter,
    requiresInvoiceCounter,
    hasUnreadMessagesCounter,
    backorderedUntilCounter,
  ])
  const filterActions = {
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      setLocalState: setLocalAcknowledged,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.ACKNOWLEDGED.NAME + '?',
      initialState: acknowledged,
      selectedValue: localAcknowledged,
    },
    is_backordered: {
      setFilterCount: setBackorderedCounter,
      setLocalState: setLocalBackordered,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.BACKORDERED.NAME + '?',
      initialState: backordered,
      selectedValue: localBackordered,
    },
    backorder_is_acknowledged: {
      setFilterCount: setBackorderedAcknowledgedCounter,
      setLocalState: setLocalBackorderedAcknowledged,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.BACKORDERED_ACKNOWLEDGED.NAME + '?',
      initialState: isBackorderAcknowledged,
      selectedValue: localBackorderedAcknowledged,
    },
    not_fully_shipped: {
      setFilterCount: setRequiresFulfillmentCounter,
      setLocalState: setLocalRequiresFulfillment,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.REQUIRES_FULFILLMENT.NAME + '?',
      initialState: requiresFullfilment,
      selectedValue: localRequiresFulfillment,
    },
    not_fully_invoiced: {
      setFilterCount: setRequiresInvoiceCounter,
      setLocalState: setLocalRequiresInvoice,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.REQUIRES_INVOICE.NAME + '?',
      initialState: requiresInvoice,
      selectedValue: localRequiresInvoice,
    },
    has_unread_memos: {
      setFilterCount: setHasUnreadMessagesCounter,
      setLocalState: setLocalHasUnreadMessages,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.UNREAD_MESSAGES.NAME + '?',
      initialState: hasUnreadMemos,
      selectedValue: localHasUnreadMessages,
    },
    backordered_until_gte: {
      setFilterCount: setBackorderedUntilCounter,
      setLocalState: setLocalBackorderedUntil,
      initialState: backorderedUntil,
      date: localBackorderedUntil,
      action: setBackorderedUntil,
      filterType: FILTERS.TYPES.DATE,
      label: ORDERS.BACKORDERED_UNTIL.NAME,
    },
  }

  useEffect(() => {
    setInitialState(filterActions)
  }, [params])

  return (
    <FilterModal
      filters={[
        // TODO: add connections and tags once component available
        // TODO: add supplier SKU and retailer SKU once component available/decided upon
        // TODO: add is_merchandising_contact, is_department_owner and is_department_manager FOR MERCHANT ONLY once the above-mentioned components available refer to https://gitlab.com/fabric2/marketplace/platform/-/merge_requests/1230 for the new filters
        radio(filterActions, ORDERS.ACKNOWLEDGED.PARAM),
        radio(filterActions, ORDERS.BACKORDERED.PARAM),
        // TODO: only show is_backorder_acknwoledged filter for merchant
        // radio(filterActions, ORDERS.BACKORDERED_ACKNOWLEDGED.PARAM),
        radio(filterActions, ORDERS.REQUIRES_FULFILLMENT.PARAM),
        radio(filterActions, ORDERS.REQUIRES_INVOICE.PARAM),
        radio(filterActions, ORDERS.UNREAD_MESSAGES.PARAM),
        date(filterActions, ORDERS.BACKORDERED_UNTIL.PARAMS.START),
      ]}
      onApply={() => applyFilters(filterActions, setFilters)}
      onCancel={() => setInitialState(filterActions)}
      onResetAll={() => resetLocalState(filterActions)}
      primaryButtonText={`Apply (${allFiltersCount})`}
    />
  )
}

export default OrdersAllFiltersModal
