import { Badge } from 'atoms'
import { FC, useMemo } from 'react'

interface ProposalIssuesStatusProps {
  issue: any
}

const ProposalIssueStatusPresenter: FC<ProposalIssuesStatusProps> = ({
  issue,
}) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: '',
      status: '',
    }
    if (!issue.is_closed && !issue.is_resolved) {
      presenter.label = 'Open'
      presenter.status = 'error'
    } else if (!issue?.is_closed && issue.is_resolved) {
      presenter.label = 'Resolved'
      presenter.status = 'alert'
    } else if (issue?.is_closed && !issue?.is_archived) {
      presenter.label = 'Closed'
      presenter.status = 'success'
    } else if (issue?.is_archived) {
      presenter.label = 'Archived'
      presenter.status = 'default'
    }
    return presenter
  }, [issue, issue.is_archived, issue.is_closed, issue.is_resolved])

  return (
    <Badge
      label={presenter.label}
      variant='primary'
      status={presenter.status}
    />
  )
}

export default ProposalIssueStatusPresenter
