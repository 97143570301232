import { FC, useEffect } from 'react'
import { StyledWrapper } from '../../styles'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import { PageHeader } from 'atoms'

const AttributeValueTransformer: FC = () => {
  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.SETTING_BASIC_DETAILS)
  }, [])

  return (
    <StyledWrapper>
      <PageHeader h1Text='Attribute Value Transformers' />
      <div className='mt-4'></div>
    </StyledWrapper>
  )
}

export default AttributeValueTransformer
