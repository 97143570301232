import React, { useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { BUTTONS, VARIANTS } from 'lib/constants'
import { accknowledgeCancel } from 'api/cancels'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setParams } from 'store/cancellationsSlice'
import AcknowledgeCancellations from './acknowledgeCancellations'

type AcknowledgeCancellationsModalProps = {
  cancellation: any
  isRetailer: boolean
  isBulkAction?: boolean
  showAcknowledgeCancellationModal: boolean
  setShowAcknowledgeCancellationModal: any
}

const AcknowledgeCancellationsModal: React.FC<
  AcknowledgeCancellationsModalProps
> = ({
  cancellation,
  isRetailer,
  isBulkAction,
  showAcknowledgeCancellationModal,
  setShowAcknowledgeCancellationModal,
}) => {
  const showToast = useToast()
  const dispatch = useDispatch()

  const params = useSelector((state: RootState) => state.cancellations.params)

  const [saving, setSaving] = useState(false)

  const isActionable = useMemo(() => {
    return !cancellation?.acknowledged_at
  }, [cancellation])

  const accept = async () => {
    setSaving(true)
    try {
      await accknowledgeCancel({ id: cancellation?.id })
      showToast({
        label: 'Cancellation has been acknowledged successfully',
        isDismissable: true,
        id: '1',
      })
      // replace cancellation params with a copy of itself to trigger a fetch and rerender of the list
      dispatch(setParams({ ...params }))
    } catch (error) {
      const message = error.response.data.detail
        ? error.response.data.detail
        : error
      showToast({
        label: message,
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
    setShowAcknowledgeCancellationModal(false)
    setSaving(false)
  }

  return (
    <Modal
      headerText={`Acknowledge Cancellation`}
      description=''
      size='small'
      onClose={() => setShowAcknowledgeCancellationModal(false)}
      isVisible={showAcknowledgeCancellationModal}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => setShowAcknowledgeCancellationModal(false),
          text: BUTTONS.CLOSE.LABEL,
          variant: VARIANTS.SECONDARY,
        },
        {
          dataTestid: '',
          onClick: accept,
          isLoading: saving,
          isDisabled: !isActionable,
          text: 'Acknowledge Cancellation',
          variant: VARIANTS.PRIMARY,
        },
      ]}
      dataTestid={'acknowledge-cancellation-modal'}
      onBackdropClick={() => setShowAcknowledgeCancellationModal(false)}
    >
      <AcknowledgeCancellations
        cancellation={cancellation}
        isRetailer={isRetailer}
      />
    </Modal>
  )
}

export default AcknowledgeCancellationsModal
