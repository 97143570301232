import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import {
  PRODUCT_RULESETS,
  PRODUCT_RULESET_FILTER_OPTIONS,
} from 'modules/settings/components/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  contentTypeOptions: PRODUCT_RULESET_FILTER_OPTIONS.CONTENT_TYPE_OPTIONS,
  updatedAt: null,
}

const productRulesetsSlice = createSlice({
  name: 'ProductRulesets',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['payload'] = action.payload
      } else delete state.params['payload']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setContentTypeOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.contentTypeOptions =
          PRODUCT_RULESET_FILTER_OPTIONS.CONTENT_TYPE_OPTIONS
      } else {
        state.contentTypeOptions = action.payload
      }
    },
    setUpdatedAt(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_START]
        delete state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_END]
        delete state.updatedAt
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_START] = start
        state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_END] = end
        state.updatedAt = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.contentTypeOptions =
        PRODUCT_RULESET_FILTER_OPTIONS.CONTENT_TYPE_OPTIONS
      delete state.params[PRODUCT_RULESETS.CONTENT_TYPE.PARAM]
      delete state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_START]
      delete state.params[PRODUCT_RULESETS.UPDATED_AT.PARAM_END]
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setCurrentPage,
  setContentTypeOptions,
  setUpdatedAt,
  resetAllFilters,
} = productRulesetsSlice.actions

export default productRulesetsSlice.reducer
