import Link from 'atoms/link/link'
import { StyledCheckboxFooter } from './styles'

const FilterFooter = ({ linkText, disableLink, onLinkClick }) => {
  return (
    <StyledCheckboxFooter data-testid='filter-footer'>
      <Link
        variant='primary'
        label={linkText}
        disabled={disableLink}
        onClick={onLinkClick}
      />
    </StyledCheckboxFooter>
  )
}

export default FilterFooter
