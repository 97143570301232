import React, { useEffect, useState } from 'react'
import Badge from 'atoms/badge/badge'

type ChildProps = {
  inventory: string
  discontinued?: boolean
  showCount?: boolean
  onClick?: () => void
}

const Availability: React.FC<ChildProps> = ({
  inventory,
  discontinued,
  showCount,
  onClick,
}) => {
  const [label, setLabel] = useState('')
  const [statusVariant, setStatusVariant] = useState('')

  useEffect(() => {
    setInventoryStatus()
  })

  const setInventoryStatus = () => {
    if (discontinued) {
      setLabel('Discontinued')
      setStatusVariant('error')
      return
    }
    if (Number(inventory) === 0) {
      setLabel('Out of stock')
      setStatusVariant('error')
      return
    } else {
      let label = 'In stock'
      if (showCount) {
        label += ` (${inventory})`
      }
      setLabel(label)
      setStatusVariant('success')
      return
    }
  }

  return (
    <div className={onClick ? 'hover-cursor' : ''}>
      <Badge
        label={label}
        variant='primary'
        status={statusVariant}
        onClick={onClick}
      />
    </div>
  )
}

export default Availability
