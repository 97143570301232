import React, { useEffect, useState } from 'react'
import { Link } from 'atoms'
import Card4Columns from 'molecules/cards/4Columns'

type ExtrasProps = {
  loading: boolean
  details: any
}

const ExtraDetails: React.FC<ExtrasProps> = ({ loading, details }) => {
  const [extraDetails, setExtraDetails] = useState<any[]>([])

  const PACKING_SLIP_FILE_NAME = 'packing_slip.pdf'

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const getLink = () => {
    return details?.attachments
      .filter(({ filename }) => filename === PACKING_SLIP_FILE_NAME)
      .map(({ file }) => file)
  }

  const giftMessage = () => {
    // verify order with gift message
    let messageText = 'No Gift Message'
    if (details.gift_message) {
      details.gift_message.map((message) => (messageText = message))
    }
    return messageText
  }
  const formatData = ({ is_gift }) => {
    let formattedDetails = [
      {
        label: 'Packing Slip',
        value: (
          <Link
            onClick={() => window.open(getLink(), '_blank')}
            label='View packing slip'
            variant='primary'
            padding={false}
          />
        ),
      },
      {
        label: 'Is Gift?',
        value: is_gift ? 'Yes' : 'No',
      },
      {
        label: 'Gift Message',
        value: giftMessage(),
      },
    ]
    setExtraDetails(formattedDetails)
  }

  return (
    <Card4Columns
      header='Extras'
      loading={loading}
      data={extraDetails}
      customClassName='mb-4'
    />
  )
}

export default ExtraDetails
