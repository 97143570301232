import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getBrands } from 'api/context'
import { Dropdown } from '@teamfabric/copilot-ui'
import { DropdownOptionProps } from '@teamfabric/copilot-ui/dist/components/dropdown/Dropdown.type'
import { StyledContainer, StyledTitle } from './styles'

const ContextDashboardTemplate: FC = () => {
  const params = {}

  const [loading, setLoading] = useState(true)

  const [options, setOptions] = useState<DropdownOptionProps[]>([])
  const [selectedOption, setSelectedOption] =
    useState<DropdownOptionProps>(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const { data: data } = await getBrands({ params: { ...params } })
        if (data && data.results) {
          setOptions(
            data.results.map((el) => {
              return {
                ...el,
                label: el.name,
                suffix: el.id,
              }
            })
          )
        }
        setLoading(false)
      })()
    }
  }, [params])

  const search = async ({ searchText: value }) => {
    if (value) {
      setLoading(true)
      const { data: data } = await getBrands({
        params: { search: value, ...params },
      })
      if (data && data.results) {
        setOptions(
          data.results.map((el) => {
            return {
              ...el,
              label: el.name,
              suffix: el.id,
            }
          })
        )
      }
      setLoading(false)
    }
  }

  return (
    <StyledContainer>
      <h1>Select Supplier</h1>
      <StyledTitle>
        Your user account has access to multiple fabric accounts.
        <br />
        Please select the account you wish to view.
      </StyledTitle>
      <Dropdown
        placeholder='Search for suppliers...'
        label='Select Supplier'
        onChange={(option) => {
          setSelectedOption(option)
          // temporary hack to update the brand in session storage
          // selecting account/tenant should be performed via left nav bar
          const tenant = sessionStorage.getItem('mkp_tenant')
          const { type } = JSON.parse(tenant)
          const newTenant = {
            type,
            id: option.id,
          }
          sessionStorage.setItem('mkp_tenant', JSON.stringify(newTenant))
          navigate('/orders')
        }}
        serverSideFiltering={{
          loading: loading,
          onInputChange: (searchText) => search({ searchText }),
        }}
        options={options}
        value={selectedOption}
      />
    </StyledContainer>
  )
}

export default ContextDashboardTemplate
