import React from 'react'
import Card from 'atoms/card/card'
import HorizontalTab from 'atoms/tab'
import CardLoading from 'molecules/loading/cardLoading'

type TabContent = {
  label: string
  content: any
}

type ChildProps = {
  loading?: boolean
  header: any
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  customClassName?: string
  noResultsText?: string
  tabsData: TabContent[]
  setLabel: () => void
}

const defaultProps: ChildProps = {
  open: true,
  tabsData: [],
  skeletonRowsNumber: 3,
  header: undefined,
  setLabel: () => {},
}

const CardWithHorizontalTabs: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return (
      <h5 className='h5 grey-500 textAlignCenter'>
        {props.noResultsText ? props.noResultsText : 'You have no results'}
      </h5>
    )
  }

  const CardContent = () => {
    return (
      <HorizontalTab
        onTabChange={props.setLabel}
        data={{
          tabs: props.tabsData,
        }}
      />
    )
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          showDivider={false}
          open={props.open}
        />
      ) : (
        <Card
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={false}
          bodyContent={
            props.tabsData?.length ? <CardContent /> : <NoResultContent />
          }
        />
      )}
    </div>
  )
}

CardWithHorizontalTabs.defaultProps = defaultProps

export default CardWithHorizontalTabs
