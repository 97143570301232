import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSelected } from 'store/productsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import Availability from 'molecules/inventory/availability'
import { getTenantType } from 'api/helper'
import { useNavigate } from 'react-router-dom'
import InventoryDetailsModal from 'molecules/modals/item/inventoryDetailsModal'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  selected: string[]
}

const ProductsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isRetailer } = getTenantType()
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [displayInventoryDetails, setDisplayInventoryDetails] = useState(false)
  const [variant, setVariant] = useState('')

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.selected])

  const supplierTableColumns = [
    {
      title: 'Product title',
    },
    {
      title: 'UPC',
      width: '15%',
    },
    {
      title: 'Product SKU',
      width: '15%',
    },
    {
      title: 'Availability',
      width: '15%',
    },
  ]

  const retailerTableColumns = [
    {
      title: 'Product title',
    },
    {
      title: 'Supplier',
      width: '20%',
    },
    {
      title: 'Category',
      width: '20%',
    },
    {
      title: 'UPC',
      width: '15%',
    },
    {
      title: 'Product SKU',
      width: '15%',
    },
    {
      title: 'Availability',
      width: '10%',
    },
  ]

  const isSelected = (id: string) => {
    return props?.selected?.includes(id)
  }

  const formatSetTableData = ({ data }) => {
    let formattedData = data.map((variant) => {
      return {
        id: variant?.id,
        selected: isSelected(variant?.id),
        data: {
          'Product title': {
            onRender: () =>
              VariantItem({
                variant,
                onClick: () => navigate(`/products/${variant?.id}`),
              }),
          },
          'UPC': { value: variant.upc ? variant.upc : '---' },
          'Product SKU': {
            value: variant.parent_identifier
              ? variant.parent_identifier
              : '---',
          },
          'Availability': {
            onRender: () => (
              <Availability
                inventory={variant?.inventory?.inventory}
                showCount={true}
                onClick={() => {
                  setDisplayInventoryDetails(true)
                  setVariant(variant)
                }}
              />
            ),
          },
        },
      }
    })
    if (isRetailer) {
      formattedData = formattedData.map((item, index) => ({
        ...item,
        data: {
          ...item.data,
          Supplier: { value: data[index].brand.name },
          Category: {
            value: data[index].retailer_categories
              ? data[index].retailer_categories[0].name
              : '---',
          },
        },
      }))
    }
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  //TODO: create custom hooks for selecting rows to handle this centrally?
  const handleSingleRowSelect = (e, rowDetails: any, selected: boolean) => {
    if (selected) {
      dispatch(setSelected([rowDetails.id, ...props.selected]))
    } else {
      const newSelectedItemsIds = props.selected.filter(
        (selectedItemId) => selectedItemId !== rowDetails.id
      )
      dispatch(setSelected(newSelectedItemsIds))
    }
  }

  const handleAllRowSelect = (e, allRowsSelected: boolean) => {
    if (!allRowsSelected) {
      dispatch(setSelected([]))
    } else {
      const allItemIds = tableData.map((item) => item.id)
      dispatch(setSelected(allItemIds))
    }
  }

  return (
    <>
      <Modal
        headerText='Inventory Details'
        description=''
        size='small'
        onClose={() => setDisplayInventoryDetails(false)}
        isVisible={displayInventoryDetails}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setDisplayInventoryDetails(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <InventoryDetailsModal variant={variant} />
      </Modal>
      <Table
        columns={isRetailer ? retailerTableColumns : supplierTableColumns}
        data={tableData}
        loading={props.loading}
        // TODO: switch selectable to true once Bulk Discontinue implemented on the BE https://yottadv.atlassian.net/browse/MKP-3322 and finalized on FE
        selectable={false}
        onRowSelect={(e, rowDetails, selected) =>
          handleSingleRowSelect(e, rowDetails, selected)
        }
        onAllRowSelect={(e, allRowsSelected) =>
          handleAllRowSelect(e, allRowsSelected)
        }
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </>
  )
}

export default ProductsTable
