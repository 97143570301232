export const tenantDetails = () => {
  const tenant = sessionStorage.getItem('mkp_tenant')
  const tenantDetails = JSON.parse(tenant)
  return tenantDetails
}

export const getTenantType = () => {
  const tenant = sessionStorage.getItem('mkp_tenant')
  const { type } = JSON.parse(tenant) || {}

  const isRetailer = type === 'retailer'
  const isBrand = type === 'brand'

  return { isRetailer, isBrand }
}
