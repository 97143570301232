import React, { useState } from 'react'
import Modal from 'atoms/modal/modal'
import ModalLoading from 'molecules/loading/modalLoading'
import Input from 'atoms/input/input'
import GridRow from 'atoms/grid/gridRow'
import GridCol from 'atoms/grid/gridCol'
import { inviteUser } from 'api/user'
import { useToast } from '@teamfabric/copilot-ui'

type InviteMemberModalProps = {
  isVisible: boolean
  setIsDisplayInviteModal: (value: boolean) => void
  addNewUser: (user: object) => void
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  isVisible,
  setIsDisplayInviteModal,
  addNewUser,
}) => {
  const showToast = useToast()

  const [saving, setSaving] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const title = 'Invite Teammate'

  const inviteTeammate = async () => {
    setSaving(true)
    const body = {
      first_name: firstName,
      last_name: lastName,
      email,
    }
    try {
      const {
        data: { id },
      } = await inviteUser({ body })
      addNewUser({
        id,
        label: `${firstName} ${lastName}`,
      })
    } catch (error) {
      const message = error.response.data.detail
        ? error.response.data.detail
        : error
      showToast({
        label: message,
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
    setIsDisplayInviteModal(false)
    setSaving(false)
  }

  return (
    <>
      <ModalLoading
        isVisible={saving}
        headerText={title}
        buttonLabel='Invite'
        skeltonNum={2}
      />
      <Modal
        headerText={title}
        description={
          <div>
            <p className='body1-regular'>
              Complete the form below to invite a new user to join your team on
              fabric. After you click 'invite', they will receive an email with
              instructions to activate their account.
            </p>
            <div className='mb-4'>
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <Input
                    label='First Name'
                    width='100%'
                    required={true}
                    inputProps={{
                      placeholder: 'First Name',
                      onChange: ({ target: { value } }) => {
                        setFirstName(value)
                      },
                    }}
                  />
                </GridCol>
                <GridCol lg={6} md={6} sm={6}>
                  <Input
                    label='Last Name'
                    width='100%'
                    required={true}
                    inputProps={{
                      placeholder: 'Last Name',
                      onChange: ({ target: { value } }) => {
                        setLastName(value)
                      },
                    }}
                  />
                </GridCol>
              </GridRow>
            </div>
            <div>
              <Input
                label='Email Address'
                required={true}
                width='100%'
                inputProps={{
                  placeholder: 'Email Address',
                  onChange: ({ target: { value } }) => {
                    setEmail(value)
                  },
                }}
                maskOptions={{
                  alias: 'email',
                }}
              />
            </div>
          </div>
        }
        onClose={() => setIsDisplayInviteModal(false)}
        isVisible={!saving && isVisible}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => {
              setIsDisplayInviteModal(false)
            },
            text: 'Cancel',
            variant: 'secondary',
          },
          {
            dataTestid: '',
            onClick: inviteTeammate,
            isDisabled: !firstName || !lastName || !email,
            text: 'Invite',
            variant: 'primary',
          },
        ]}
        dataTestid={''}
        size='small'
        onBackdropClick={() => setIsDisplayInviteModal(false)}
      />
    </>
  )
}

export default InviteMemberModal
