import React from 'react'
import { DateFilter as DateFilterUI } from '@teamfabric/copilot-ui'

type FilterContentDateProps = {
  name: string
  defaultValue?: any
  onChange?: (date: any) => any
  options?: any
}

const FilterContentDate: React.FC<FilterContentDateProps> = ({ ...props }) => {
  return <DateFilterUI {...props} />
}

export default FilterContentDate
