import API, { API_URL } from 'api'

export const getLocations = async ({ params = {} }) => {
  return await API.get(API_URL.LOCATIONS(), { params })
}

export const postLocation = async ({ body }) => {
  return await API.post(API_URL.LOCATIONS(), { ...body })
}

export const patchLocation = async ({ id, body }) => {
  return await API.patch(API_URL.LOCATION_DETAIL({ id }), body)
}
