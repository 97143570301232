import React, { useEffect, useState } from 'react'
import { patchConnections } from 'api/connection'
import { find } from 'lodash'
import { useToast } from '@teamfabric/copilot-ui'
import { Dropdown, Modal, Input } from 'atoms'

type formDataTypes = {
  retailer_identifier: string
  level: string
  integration_type: string
  options: any
  status: string
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const ConnectionLevelOptions = [
  {
    id: '1',
    label: 'Transactions & Products',
    value: 'transactions_and_products',
  },
  {
    id: '2',
    label: 'Products only',
    value: 'products_only',
  },
]

const IntegrationTypeOptions = [
  { id: '1', label: 'EDI', value: 'edi' },
  { id: '2', label: 'API', value: 'api' },
  { id: '3', label: 'Shopify', value: 'shopify' },
  { id: '4', label: 'ShipStation', value: 'shipstation' },
  { id: '5', label: 'Console', value: 'console' },
  { id: '6', label: 'Console (Print Labels)', value: 'console-print-labels' },
  {
    id: '7',
    label: 'Console (Register Tracking #s)',
    value: 'console-register-tracking-numbers',
  },
]

const CatalogImportMethodOptions = [
  { id: '1', label: 'Import Request', value: 'import_request' },
  { id: '2', label: 'Proposals', value: 'proposals' },
]

const UpdateDetailsModal: React.FC<ChildProps> = ({
  initialValues,
  ...props
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [retailerIdentifier, setRetailerIdentifier] = useState(
    initialValues.retailer_identifier || null
  )
  const [connectionLevel, setConnectionLevel] = useState(
    ConnectionLevelOptions[0]
  )
  const [integrationType, setIntegrationType] = useState(
    IntegrationTypeOptions[0]
  )
  const [importType, setImportType] = useState(CatalogImportMethodOptions[0])

  useEffect(() => {
    if (initialValues.integration_type) {
      const integrationOption = IntegrationTypeOptions.find(
        (el) => el.value === initialValues.integration_type
      )
      setIntegrationType(integrationOption)
    }

    if (initialValues.retailer_identifier) {
      setRetailerIdentifier(initialValues.retailer_identifier)
    }

    if (initialValues.level) {
      const connectionOption = ConnectionLevelOptions.find(
        (el) => el.value === initialValues.level
      )
      setConnectionLevel(connectionOption)
    }

    if (initialValues.options) {
      const isProposal = find(initialValues.options, [
        'code',
        'merchandise_via_proposals',
      ])
      // const isImportRequest = find(initialValues.options, ['code', 'merchandise_via_import_request'])
      const importOption = isProposal
        ? CatalogImportMethodOptions[1]
        : CatalogImportMethodOptions[0]
      setImportType(importOption)
    }
  }, [initialValues])

  const UpdateConnection = async () => {
    const body = {
      integration_type: integrationType.value,
      retailer_identifier: retailerIdentifier,
      connection_options: {},
      level: connectionLevel.value,
    }

    if (importType.value === 'proposals') {
      body.connection_options = {
        merchandise_via_proposals: true,
        merchandise_via_import_request: false,
      }
    }

    if (importType.value === 'import_request') {
      body.connection_options = {
        merchandise_via_import_request: true,
        merchandise_via_proposals: false,
      }
    }
    // add onboarding date for onboarding
    // if (initialValues.status === 'onboarding' && onboardingTargetDate) {
    //   const date = this.$dates(this.onboardingTargetDate)
    //   if (date.isValid()) {
    //     body.onboarding_target_date = date.format('YYYY-MM-DD') + 'T10:00'
    //   }
    // }

    // add introCallDate date for onboarding
    // if (this.connection.status === 'onboarding' && introCallDate) {
    //   const date = this.$dates(this.introCallDate)
    //   if (date.isValid()) {
    //     body.intro_call_date = date.format('YYYY-MM-DD') + 'T10:00'
    //   }
    // }

    try {
      setLoading(true)
      await patchConnections({
        connectionId: props.connectionId,
        body: body,
      })
      showToast({
        label: `Your connection details, has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the connection: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Update Connection Details'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => UpdateConnection(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <Input
          width='100%'
          label='Supplier #'
          inputProps={{
            name: 'supplierId',
            value: retailerIdentifier,
            onChange: ({ target: { value } }) => {
              setRetailerIdentifier(value)
            },
            placeholder: 'Supplier #',
          }}
        />
        <div className='divider-4'></div>
        <Dropdown
          width='100%'
          options={ConnectionLevelOptions}
          value={connectionLevel}
          placeholder='Select level'
          label='Connection Level'
          onChange={(option: any) => {
            setConnectionLevel(option)
          }}
        />
        <div className='divider-4'></div>
        <Dropdown
          width='100%'
          options={IntegrationTypeOptions}
          value={integrationType}
          placeholder='Select integration'
          label='Integration'
          onChange={(option: any) => {
            setIntegrationType(option)
          }}
        />
        <div className='divider-4'></div>
        <Dropdown
          width='100%'
          options={CatalogImportMethodOptions}
          value={importType}
          placeholder='Select integration'
          label='Integration'
          onChange={(option: any) => {
            setImportType(option)
          }}
        />
      </Modal>
    </>
  )
}

export default UpdateDetailsModal
