import { FC } from 'react'
import Link from 'atoms/link/link'

interface TrackingNumberProps {
  tracking_url: string
  tracking_number: string
}

const TrackingNumberLink: FC<TrackingNumberProps> = ({
  tracking_url,
  tracking_number,
}) => {
  return (
    <Link
      onClick={() => window.open(tracking_url, '_blank')}
      label={tracking_number}
      variant='primary'
    />
  )
}

export default TrackingNumberLink
