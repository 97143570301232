import { Badge } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import { FC, useMemo } from 'react'

interface AcceptInvoiceStatusProps {
  acknowledged_at: any
}

const AcceptInvoiceStatusPresenter: FC<AcceptInvoiceStatusProps> = ({
  acknowledged_at,
}) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: 'Ready',
      status: 'info',
      tooltipText: 'Ready to be accepted',
    }

    if (acknowledged_at != null) {
      presenter.label = 'Skip'
      presenter.tooltipText = 'Invoice has already been accepted'
      presenter.status = 'alert'
    }
    return presenter
  }, [acknowledged_at])

  return (
    <ToolTip text={presenter.tooltipText}>
      <div className='hover-cursor'>
        <Badge
          label={presenter.label}
          variant='primary'
          status={presenter.status}
        />
      </div>
    </ToolTip>
  )
}

export default AcceptInvoiceStatusPresenter
