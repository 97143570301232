import React, { useEffect, useState } from 'react'
import { patchConnectionSla } from 'api/connection'
import { InputStepper, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'

type formDataTypes = {
  retailer_identifier: string
  level: string
  integration_type: string
  options: any
  status: string
  connection_slas: any
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const UpdateFulfillmentSlaModal: React.FC<ChildProps> = ({
  initialValues,
  ...props
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [fulfillmentValue, setFulfillmentValue] = useState<number>(2)

  useEffect(() => {
    const value = initialValues.connection_slas?.brand_fulfillment_hours / 24
    setFulfillmentValue(value)
  }, [initialValues])

  const UpdateConnection = async () => {
    try {
      setLoading(true)
      await patchConnectionSla({
        connectionId: props.connectionId,
        slaId: initialValues.connection_slas?.id,
        body: {
          brand_fulfillment_hours: fulfillmentValue * 24,
        },
      })
      showToast({
        label: `Your connection SLAs, has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the connection: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Update Connection SLAs'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => UpdateConnection(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <InputStepper
          label='Fulfillment SLA (Business Days)'
          width='100%'
          max={100}
          min={1}
          onValueChange={(newVal) => {
            const parsedValue =
              typeof newVal === 'string' ? parseInt(newVal) : newVal
            setFulfillmentValue(parsedValue)
          }}
          stepSize={1}
          value={fulfillmentValue}
        />
      </Modal>
    </>
  )
}

export default UpdateFulfillmentSlaModal
