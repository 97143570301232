import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import Banner from 'atoms/banner/banner'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const ArchiveProposalModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)

  const showToast = useToast()

  const archiveProposal = async () => {
    try {
      setLoading(true)
      await patchProposal({
        id: props.proposal?.id,
        body: {
          status: 'archived',
        },
      })
      showToast({
        label: `Your proposal has been archived successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: `Error archiving the proposal: ${error}`,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  const description = useMemo(() => {
    let copy = 'Are you sure you want to archive the proposal'

    if (props.proposal?.name) {
      return `${copy} for ${props.proposal.name} ?`
    } else return copy + '?'
  }, [props.proposal])

  return (
    <>
      <Modal
        headerText='Are you sure you want to archive the proposal?'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: "No, Don't Archive",
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => archiveProposal(),
            text: 'Yes, Archive',
            variant: VARIANTS.DESTRUCTIVE,
            icon: 'Trash',
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <Banner
            label='This proposal will be canceled. This action cannot be undone.'
            variant='error'
          />
          <p>{description}</p>
        </>
      </Modal>
    </>
  )
}

export default ArchiveProposalModal
