import React, { useEffect, useRef, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProposalFilters from '../components/filters/list'
import SearchBar from 'molecules/search/searchBarTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import { PAGE_TYPE } from 'lib/constants'
import { getProposalSummary, getProposals } from 'api/proposals'
import ProposalsTable from '../components/table/list'
import { resetAllFilters, setSelected, setSearch } from 'store/proposalsSlice'
import Tab from 'atoms/tab/tab'
import { PRODUCTS } from '../../constants/products'
import { keyBy } from 'lodash'
import PageHeaderComponent from 'molecules/PageHeader'
import { getTenantType } from 'api/helper'
import { ProductType, breadcrumbs } from 'lib/constants/breadcrumbsHandler'

const ProposalList: React.FC = () => {
  const isInitialRender = useRef(true)
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  const [loadingProposalSummary, setLoadingProposalSummary] = useState(true)
  const [draftCount, setDraftCount] = useState(0)
  const [proposedCount, setProposedCount] = useState(0)
  const [revisedCount, setRevisedCount] = useState(0)
  const [publishingCount, setPublishingCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)

  const params = useSelector((state: RootState) => state.proposals.params)
  const selected = useSelector((state: RootState) => state.proposals.selected)
  const selectedTabPreset = useSelector(
    (state: RootState) => state.proposals.selectedTabPresetIndex
  )

  const [activeTabIndex, setActiveTabIndex] = useState(selectedTabPreset)

  const dispatch = useDispatch()
  const { isRetailer } = getTenantType()

  useEffect(() => {
    ;(async () => {
      setLoadingProposalSummary(true)
      const { data } = await getProposalSummary({})
      const statuses = keyBy(data, (o) => o.status)

      let proposedCount = 0
      let draftCount = 0
      let revisedCount = 0
      let completed_unacknowledgedCount = 0
      let completed_acknowledgedCount = 0

      for (const key in statuses) {
        const { status, count } = statuses[key]

        switch (status) {
          case 'proposed':
            proposedCount += count
            break
          case 'draft':
            draftCount += count
            break
          case 'approved':
            proposedCount += count
            break
          case 'pricing_approved':
            proposedCount += count
            break
          case 'revised':
            revisedCount += count
            break
          case 'completed_unacknowledged':
            completed_unacknowledgedCount += count
            break
          case 'completed_acknowledged':
            completed_acknowledgedCount += count
            break
        }
      }

      setDraftCount(draftCount)
      setProposedCount(proposedCount)
      setRevisedCount(revisedCount)
      setPublishingCount(completed_unacknowledgedCount)
      setCompletedCount(completed_acknowledgedCount)
      setLoadingProposalSummary(false)
    })()

    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          isRetailer
            ? breadcrumbs.supplierProposals()
            : breadcrumbs.products({ type: ProductType.Proposals })
        ),
      0
    )
  }, [])

  useEffect(() => {
    // skip the first load
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    const tabPresetsMapping = isRetailer
      ? {
          0: {},
          1: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.PROPOSED },
          2: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.RETURNED },
          3: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.PUBLISHING },
          4: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.COMPLETED },
        }
      : {
          0: {},
          1: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.DRAFT },
          2: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.PROPOSED },
          3: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.RETURNED },
          4: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.PUBLISHING },
          5: { tabPresets: PRODUCTS.PROPOSAL_TAB_PRESETS.COMPLETED },
        }

    const resetAllFiltersPayload = tabPresetsMapping[activeTabIndex] || {}
    dispatch(resetAllFilters(resetAllFiltersPayload))
  }, [activeTabIndex])

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        dispatch(setSelected([]))
        const { data: data } = await getProposals({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  const TabHeaders = [
    { label: PRODUCTS.PROPOSAL_TAB_PRESETS.ALL },
    isRetailer
      ? null
      : {
          label:
            PRODUCTS.PROPOSAL_TAB_PRESETS.DRAFT +
            (loadingProposalSummary ? '' : ` (${draftCount})`),
        },
    {
      label:
        PRODUCTS.PROPOSAL_TAB_PRESETS.PROPOSED +
        (loadingProposalSummary ? '' : ` (${proposedCount})`),
    },
    {
      label:
        PRODUCTS.PROPOSAL_TAB_PRESETS.RETURNED +
        (loadingProposalSummary ? '' : ` (${revisedCount})`),
    },
    {
      label:
        PRODUCTS.PROPOSAL_TAB_PRESETS.PUBLISHING +
        (loadingProposalSummary ? '' : ` (${publishingCount})`),
    },
    {
      label:
        PRODUCTS.PROPOSAL_TAB_PRESETS.COMPLETED +
        (loadingProposalSummary ? '' : ` (${completedCount})`),
    },
  ].filter((tab) => tab !== null)

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeaderComponent
            dataTestid='proposals-header'
            h1Text='Proposals'
            primaryButtons={
              !isRetailer && [
                {
                  onClick: () =>
                    console.log('redirect to submit proposal page here'),
                  text: 'Submit new proposal',
                  icon: '',
                  variant: 'primary',
                },
              ]
            }
            customClassName='mb-4'
          />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol md={12}>
          <Tab
            data={TabHeaders}
            setLabel={setActiveTabIndex}
            activeIndex={activeTabIndex}
            component={
              <Box width='100%'>
                <SearchBar
                  placeholder='Search by Proposal name'
                  setSearch={setSearch}
                  className='mt-4'
                  dataTestid='proposals-search-bar'
                />
                <div className='mt-3 mb-0'>
                  <ProposalFilters params={params} />
                </div>
                <BulkActionsBar
                  loading={loading}
                  //TODO: flip to true once Bulk Archive Proposals is available on BE https://yottadv.atlassian.net/browse/MKP-3354 and implemented on FE
                  hasBulkActions={false}
                  selectedItems={selected}
                  totalRows={totalRows}
                  currentPage={params?.page}
                  pageType={PAGE_TYPE.PROPOSALS}
                />
                <ProposalsTable
                  data={tableData}
                  loading={loading}
                  totalRows={totalRows}
                  currentPage={params?.page}
                  selected={selected}
                />
              </Box>
            }
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ProposalList
