import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import FilterChips from 'organisms/filterChips'
import {
  setFilters,
  setStatusOptions,
  setUpdatedAt,
  resetAllFilters,
} from 'store/proposalDepartmentsSlice'
import {
  handleCheckboxFilterChange,
  handleDateFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { DEPARTMENT } from '../constants'

type ChildProps = {
  params: any
}

const DepartmentFilter: React.FC<ChildProps> = ({ params }) => {
  const [statusCounter, setStatusCounter] = useState(0)
  const [updatedAtCounter, setUpdatedAtCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.proposalDepartments.statusOptions
  )

  const updatedAt = useSelector(
    (state: RootState) => state.proposalDepartments.updatedAt
  )

  const isAFilterApplied = useMemo(() => {
    return statusCounter + updatedAtCounter > 0
  }, [statusCounter, updatedAtCounter])

  // Define respective filterKey fns
  const filterActions = {
    is_active: {
      action: setStatusOptions,
      options: statusCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setStatusCounter,
      chipName: DEPARTMENT.STATUS.NAME,
      useLabelForChip: true,
    },
    updated_at_gte: {
      setFilterCount: setUpdatedAtCounter,
      dateRange: updatedAt,
      action: setUpdatedAt,
      filterType: FILTERS.TYPES.DATE,
      chipName: DEPARTMENT.UPDATED_AT.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${DEPARTMENT.STATUS.NAME}-filter`}
          label={DEPARTMENT.STATUS.NAME}
          data={statusCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: DEPARTMENT.STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={statusCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: DEPARTMENT.STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${DEPARTMENT.UPDATED_AT.NAME}-filter`}
          label={DEPARTMENT.UPDATED_AT.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: DEPARTMENT.UPDATED_AT.PARAM_START,
              date,
            })
          }}
          defaultValue={updatedAt}
          filterCount={updatedAtCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: DEPARTMENT.UPDATED_AT.PARAM_START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default DepartmentFilter
