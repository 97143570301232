import { FC, useEffect, useState } from 'react'
import { CardProps } from 'lib/utils/props/cardProps'
import { formatDate } from 'lib/utils/date/formatDate'
import Card2Col from 'molecules/cards/2Col'
import VariantItem from 'molecules/lineItem/variantItem'
import { Badge, Table } from 'atoms'
import Box from 'atoms/box/box'

type ChildProps = {
  cancellation: any
}

const CancellationDetail: FC<ChildProps> = ({ cancellation }) => {
  const [loading, setLoading] = useState(false)
  const [cancellationDetailData, setCancellationDetailData] = useState<
    CardProps[]
  >([])
  const [returnLinesData, setReturnLinesData] = useState<CardProps[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      formatCancellationDetailData(cancellation)
      formatReturnLinesData(cancellation?.cancel_lines)
      setLoading(false)
    })()
  }, [cancellation])

  const formatCancellationDetailData = (cancellation) => {
    const formattedCancellationDetail = [
      {
        label: 'Item ID',
        value: cancellation?.id,
      },
      {
        label: 'PO #',
        value: cancellation?.purchase_order_number,
      },
      {
        label: 'Reason',
        value: cancellation?.reason,
      },
      {
        label: 'Cancel received',
        value: formatDate({ date: cancellation?.canceled_at }),
      },
      {
        label: 'Acknowledged',
        value: cancellation?.acknowledged_at
          ? formatDate({ date: cancellation.acknowledged_at })
          : {
              onRender: () => (
                <Badge
                  label='Not acknowledged'
                  variant='primary'
                  status='alert'
                />
              ),
            },
      },
    ]
    setCancellationDetailData(formattedCancellationDetail)
  }

  const returnLinesColumns = [
    // setting definitive width to columns to prevent horizontal scroll inside a modal
    {
      sortable: false,
      title: 'Item',
      width: 150,
    },
    {
      sortable: false,
      title: 'Qty',
      width: 30,
    },
  ]

  const formatReturnLinesData = (data: any) => {
    const formattedData = data.map(({ id, variant, quantity }) => {
      return {
        id,
        data: {
          Item: {
            onRender: () => <VariantItem variant={variant} />,
          },
          Qty: { value: quantity },
        },
      }
    })
    setReturnLinesData(formattedData)
  }

  return (
    <Box flex={{ flexDirection: 'column' }} gap={6}>
      <Card2Col
        loading={loading}
        showCollapse={false}
        header='Cancel Details'
        data={cancellationDetailData}
      />
      <Box flex={{ flexDirection: 'column' }} gap={2}>
        <span className='h5'>Return line items</span>
        <Table
          columns={returnLinesColumns}
          data={returnLinesData}
          loading={loading}
          activePage={1}
          selectable={false}
          totalRecords={returnLinesData?.length}
        />
      </Box>
    </Box>
  )
}

export default CancellationDetail
