import { DatePicker as DatePickerUI } from '@teamfabric/copilot-ui'
import React from 'react'

type DateProps = {
  label: string
  disabled?: boolean
  minDate: Date
  maxDate?: Date
  defaultValue: Date
  format: string
  value?: Date
  onSelect: (date: Date) => void
  isOpen?: boolean
  inputWidth?: string
  contentWidth?: string
}

const SingleDatePicker: React.FC<DateProps> = ({ ...props }) => {
  return <DatePickerUI {...props} />
}

export default SingleDatePicker
