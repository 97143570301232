import React from 'react'
import {
  Card,
  GridRow,
  GridCol,
  Button,
  HorizontalProgressStepper,
} from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'

type ChildProps = {
  loading?: boolean
  header: string
  data: any[]
  stepperSteps?: any[]
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  customClassName?: string
  noResultsText?: string
  headerWithButtons?: boolean
  headerButtonText?: string
  onEdit?: () => void
}

const defaultProps: ChildProps = {
  header: '',
  data: [],
  showCollapse: false,
}

const CardWithHorizontalStepper: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return (
      <h5 className='h5 grey-500 textAlignCenter'>
        {props.noResultsText ? props.noResultsText : 'You have no results'}
      </h5>
    )
  }

  const StepperContent = () => {
    return (
      <HorizontalProgressStepper
        connected={true}
        steps={props?.stepperSteps}
        labelGap='32px'
      />
    )
  }

  const RowContent = () => {
    return (
      <GridRow padding={false}>
        {props.data?.map(({ label, value }, key) => (
          <GridCol sm={3} key={key}>
            <p className='grey-700 label'>{label}</p>
            {value?.onRender ? (
              value.onRender()
            ) : (
              <p className='grey-1000 body1-regular'>{value}</p>
            )}
          </GridCol>
        ))}
      </GridRow>
    )
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          open={props.open}
        />
      ) : props.headerWithButtons ? (
        <Card
          headerContent={
            <div className='cardHeaderWithButton'>
              <div className='heading'>{props.header}</div>
              <Button
                onClick={() => props.onEdit()}
                text={props.headerButtonText ? props.headerButtonText : 'Edit'}
                variant='secondary'
              />
            </div>
          }
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            props?.data.length ? <RowContent /> : <NoResultContent />
          }
        />
      ) : (
        <Card
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={true}
          bodyContent={
            <>
              {!!props?.stepperSteps.length && (
                <>
                  <div className='p-2 pb-5'>
                    <StepperContent />
                  </div>
                  <div className='card-divider-line mb-4'></div>
                </>
              )}
              {props?.data.length ? <RowContent /> : <NoResultContent />}
            </>
          }
        />
      )}
    </div>
  )
}

CardWithHorizontalStepper.defaultProps = defaultProps

export default CardWithHorizontalStepper
