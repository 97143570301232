import { useToast } from '@teamfabric/copilot-ui'

export const useToastUtils = () => {
  const showToast = useToast()

  const showSuccessToast = (label: string) => {
    showToast({
      label,
      isDismissable: true,
      id: '1',
    })
  }

  const showErrorToast = (label: string) => {
    showToast({
      label,
      variant: 'error',
      isDismissable: true,
      id: '2',
    })
  }

  return { showSuccessToast, showErrorToast }
}
