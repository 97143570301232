import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from 'atoms/card/card'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCall,
  setCurrentStep,
  setNextAvaiableStep,
} from 'store/onboardingSlice'
import { ONBOARDING_TASKS } from '../../constants'
import { putCompleteTask } from 'api/onboarding'
import { useToast } from '@teamfabric/copilot-ui'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import { PATHS } from 'routes/pageRoutes'

type SkipCompletedProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const SkipCallCompleted: React.FC<SkipCompletedProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()
  const state = useSelector((state: RootState) => state)
  const call = state.onboarding.call

  // First loaded
  useEffect(() => {
    setIsNextButtonDisabled(false)
  }, [])

  const updateSlices = () => {
    dispatch(
      setCurrentStep({
        value: ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME,
        parent: ONBOARDING_TASKS.INFO.VALUE,
      })
    )
    dispatch(
      setCall({
        taskId: call.taskId,
        processNum: 1,
        completed: true,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.INFO.VALUE))
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      try {
        if (!isNextClicked) {
          return
        }
        setIsNextClicked(false)
        await putCompleteTask({ taskId: call.taskId })
        updateSlices()
        navigate(ONBOARDING_TASKS.INFO.LINK)
      } catch (error) {
        showToast({
          label: "Couldn't save",
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      }
    })()
  }, [isNextClicked])

  // onboardingProgress save and exit button clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      await putCompleteTask({ taskId: call.taskId })
      updateSlices()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <Card
      open={true}
      showCollapse={false}
      showDivider={false}
      headerContent={
        <CardHeader
          h1Text='Onboarding Call Complete'
          h2Text='You have completed your onboarding call with fabric. Complete the rest
        of the onboarding tasks to connect your account with (fabric merchant)
        and being receiving orders.'
        />
      }
    />
  )
}

export default SkipCallCompleted
