import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import ReturnsDetail from '../../modal/return/returnDetail'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import GenerateRMA from '../../modal/return/generateRMA'
import ReturnIDCol from './parts/ReturnIDCol'
import ReturnGetCol from './parts/ReturnGetCol'
import Badge from 'atoms/badge/badge'
import { getBadgeStatusByOrderReturnStatus } from '../../../utils/returns'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/returnsSlice'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const ReturnsTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [showLabelModal, setShowLabelModal] = useState(false)
  const [id, setId] = useState('')
  const [orderId, setOrderId] = useState('')
  const [supplier, setSupplier] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [label, setLabel] = useState('')
  const [submitBtn, setSubmitBtn] = useState('')
  const [nextContent, setNextContent] = useState('')
  const [cancelBtn, setCancelBtn] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setModalContent('generate')
    setLabel(`Generate RMA #${id}`)
    setSubmitBtn('Yes, Generate')
    setNextContent('create')
    setCancelBtn('Cencel')
  }, [])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      sortable: false,
      title: 'ID',
    },
    {
      sortable: false,
      title: 'RMA #',
    },
    {
      sortable: false,
      title: 'Merchant',
    },
    {
      sortable: false,
      title: 'Reason',
    },
    {
      sortable: false,
      title: 'Status',
    },
    {
      sortable: false,
      title: 'Registered',
    },
    {
      sortable: false,
      title: 'Acknowledged',
    },
    {
      sortable: false,
      title: 'Labels',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        order_id,
        rma_number,
        brand,
        retailer,
        reason,
        status,
        rmad_at,
        acknowledged_at,
      }) => {
        return {
          id,
          data: {
            'ID': {
              onRender: () => (
                <ReturnIDCol
                  id={id}
                  onClick={() => {
                    setShowDetailModal(true)
                    setId(id)
                  }}
                />
              ),
            },
            'RMA #': {
              onRender: () => (
                <p className='grey-400'>{rma_number ? rma_number : 'None'}</p>
              ),
            },
            'Merchant': { value: retailer?.name },
            'Reason': { value: reason },
            'Status': {
              onRender: () => (
                <Badge
                  label={status}
                  variant='primary'
                  status={getBadgeStatusByOrderReturnStatus(status)}
                />
              ),
            },
            'Registered': { value: formatDate({ date: rmad_at }) },
            'Acknowledged': { value: formatDate({ date: acknowledged_at }) },
            'Labels': {
              onRender: () => (
                <ReturnGetCol
                  onClick={() => {
                    setOrderId(order_id)
                    setShowLabelModal(true)
                    setSupplier(brand.name)
                    setId(id)
                  }}
                />
              ),
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setContent = (content: string) => {
    switch (content) {
      case 'generate':
        setLabel(`Generate RMA #${id}`)
        setSubmitBtn('Yes, Generate')
        setNextContent('create')
        setCancelBtn('Cancel')
        break
      case 'create':
        setLabel('Create return label')
        setSubmitBtn('Create label')
        setNextContent('label')
        setCancelBtn('Back')
        break
      case 'label':
        setLabel('Return label')
        setSubmitBtn('Close')
        setNextContent('back')
        setCancelBtn('Go Back')
        break
      default:
        setLabel('')
        setSubmitBtn('')
    }
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <div>
      {/* Modal Inspect Return */}
      <Modal
        headerText='Inspect Return'
        description=''
        onClose={() => setShowDetailModal(false)}
        isVisible={showDetailModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => {},
            text: 'Reject return',
            variant: 'destructive',
          },
          {
            dataTestid: '',
            onClick: () => {},
            text: 'Approve return',
            variant: 'primary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={() => {
          setShowDetailModal(false)
        }}
      >
        <ReturnsDetail id={id} />
      </Modal>
      {/* Modal Labels GET */}
      <Modal
        headerText={label}
        description=''
        onClose={() => {
          setShowLabelModal(false)
        }}
        isVisible={showLabelModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => {
              setModalContent('generate')
              setContent('generate')
              setShowLabelModal(false)
            },
            text: cancelBtn,
            variant: 'secondary',
          },
          {
            dataTestid: '',
            onClick: () => {
              setModalContent(nextContent)
              setContent(nextContent)
            },
            text: submitBtn,
            variant: 'primary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={() => {
          setShowLabelModal(false)
        }}
      >
        <GenerateRMA
          rmaId={id}
          orderId={orderId}
          content={modalContent}
          supplier={supplier}
        />
      </Modal>
      <Table
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        selectable={false}
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </div>
  )
}

export default ReturnsTable
