import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/shopifySlice'
import Box from 'atoms/box/box'
import { Badge, Icon, Link } from 'atoms'
import InspectWebhookModal from './inspectModal'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const WebhooksTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showWebhookDetailsModal, setShowWebhookDetailsModal] = useState(false)
  const [selectedWebhook, setSelectedWebhook] = useState(null)

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.data])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: 120,
        sortable: true,
      },
      {
        title: 'Topic',
        width: '25%',
      },
      {
        title: 'Received',
        width: '25%',
      },
      {
        title: 'Has Messages?',
        width: '20%',
      },
      {
        title: 'Status',
        width: '10%',
      },
    ]
    return columns
  }, [])

  const statusMapping = [
    {
      code: 'success',
      label: 'successful',
      status: 'success',
    },
    {
      code: 'fail',
      label: 'failed',
      status: 'error',
    },
    {
      code: 'skip',
      label: 'skipped',
      status: 'alert',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((webhook, id) => {
      return {
        id,
        data: {
          'ID': {
            onRender: () => (
              <Link
                label={webhook.id}
                variant='primary'
                onClick={() => handleIdClick(webhook)}
                mode='inline'
                dataTestid='webhook-id-cell'
              />
            ),
          },
          'Topic': { value: webhook.topic },
          'Received': {
            value: formatDate({ date: new Date(webhook.created_at) }),
          },
          'Has Messages?':
            webhook.errors.length === 0
              ? { value: '--' }
              : {
                  onRender: () => <Icon iconName='Check' size={16} />,
                },
          'Status': {
            onRender: () => (
              <Badge
                label={
                  statusMapping.find((el) => el.code === webhook.status).label
                }
                status={
                  statusMapping.find((el) => el.code === webhook.status).status
                }
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const handleIdClick = (data) => {
    setShowWebhookDetailsModal(true)
    setSelectedWebhook(data)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const closeModal = () => {
    setSelectedWebhook(null)
    setShowWebhookDetailsModal(false)
  }

  return (
    <>
      {showWebhookDetailsModal && (
        <InspectWebhookModal
          webhookDetails={selectedWebhook}
          showModal={showWebhookDetailsModal}
          closeModal={closeModal}
        />
      )}
      <Box dataTestid='webhooks-history-table' margin={{ bottom: 7 }}>
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
        />
      </Box>
    </>
  )
}

export default WebhooksTable
