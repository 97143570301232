import React from 'react'
import { FilterSidebar as FilterSidebarUI } from '@teamfabric/copilot-ui'

type FilterSidebarProps = {
  header: React.ReactNode | string
  variant?: any
  maxHeight?: string
  checkboxTree: any
  footer: any
  onChange?: (id: string, selected?: boolean) => void
  onClear?: () => void
  serverSideFiltering?: any
  isFlat?: boolean
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({ ...props }) => {
  return <FilterSidebarUI {...props} />
}

export default FilterSidebar
