import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { PageHeader } from 'atoms'
import Tag from 'atoms/tag/tag'
import TagModal from 'molecules/modals/tag/tagModal'
import { addOrderTag, deleteTagItems } from 'api/tags'
import { useToast } from '@teamfabric/copilot-ui'
import CardLoading from 'molecules/loading/cardLoading'

type OrderTagProps = {
  loading: boolean
  details: any
}

const OrderTag: React.FC<OrderTagProps> = ({ details, loading }) => {
  const PAGE_TITLE = 'Order Tags'
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const showToast = useToast()

  useEffect(() => {
    if (!loading) {
      const formatted = details?.tags.map(({ id, tag }) => ({ id, label: tag }))
      setSelectedTags(formatted)
    }
  }, [loading, details])

  const addTags = async (tag: string) => {
    try {
      const {
        data: { id },
      } = await addOrderTag({ orderId: details.id, body: { tag } })
      showToast({
        label: 'Tag added to the orders successfully',
        isDismissable: true,
        id: 'add-tag',
      })
      return { id }
    } catch (error) {
      showToast({
        label: 'Error Add Tags',
        isDismissable: true,
        id: 'error-add-tags',
        variant: 'error',
      })
    }
  }

  const removeTags = async (tagId: number) => {
    try {
      await deleteTagItems({ tagId })
      showToast({
        label: 'Tag removed to the orders successfully',
        isDismissable: true,
        id: 'remove-tag',
      })
    } catch (error) {
      showToast({
        label: 'Error Remove Tags',
        isDismissable: true,
        id: 'error-remove-tags',
        variant: 'error',
      })
    }
  }

  return (
    <div className='mb-4'>
      {loading && <CardLoading label='Order Tags' />}
      {selectedTags && (
        <TagModal
          isBulk={false}
          setDisplayModal={setDisplayModal}
          isVisible={displayModal}
          onClick={() => setDisplayModal(false)}
          onAdd={addTags}
          onRemove={removeTags}
          headerText='Manage Tags'
          inputLabel='Add or remove order tags'
          addTagButtonLabel='Save'
          values={selectedTags}
          setUserSelectedTags={(tags) => {
            setSelectedTags(tags)
          }}
        />
      )}
      {!loading && (
        <Card
          headerContent={
            <div style={{ width: '100%' }}>
              <PageHeader
                h1Text={<h5 className='m-0 h5'>{PAGE_TITLE}</h5>}
                h2Text={
                  <p className='m-0 body2-regular grey-600'>
                    Add or remove order tags
                  </p>
                }
                primaryButtons={[
                  {
                    onClick: () => setDisplayModal(true),
                    text: 'Manage Tags',
                    variant: 'secondary',
                  },
                ]}
              />
            </div>
          }
          showDivider
          showCollapse={false}
          bodyContent={
            selectedTags?.length <= 0 ? (
              <h5 className='textAlignCenter grey-500 h5'>
                Tags have not been added yet. Click the 'Manage tags' button to
                add or edit tags.
              </h5>
            ) : (
              <div className='is-flex gap-2'>
                {selectedTags.map(({ id, label }) => (
                  <Tag id={id} onRemove={() => {}} primaryLabel={label} />
                ))}
              </div>
            )
          }
        />
      )}
    </div>
  )
}

export default OrderTag
