import React from 'react'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import CardLoading from 'molecules/loading/cardLoading'

type ChildProps = {
  loading?: boolean
  header?: any
  data: any[]
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  showDivider?: boolean
  customClassName?: string
}

const defaultProps: ChildProps = {
  open: true,
  data: [],
  skeletonRowsNumber: 3,
  showDivider: true,
}

const Card2Col: React.FC<ChildProps> = (props) => {
  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          open={props.open}
        />
      ) : (
        <Card
          showDivider={true}
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          bodyContent={props.data?.map(({ label, value }, key) => (
            <div key={key} className='mb-4'>
              <GridRow padding={false}>
                <GridCol lg={5} md={5} sm={5}>
                  <p className='grey-700 label'>{label}</p>
                </GridCol>
                <GridCol
                  lg={7}
                  md={7}
                  sm={7}
                  justifySelf='end'
                  alignSelf='center'
                >
                  {value?.onRender ? (
                    value.onRender()
                  ) : (
                    <p className='grey-1000 body1-regular'>{value}</p>
                  )}
                </GridCol>
              </GridRow>
            </div>
          ))}
        />
      )}
    </div>
  )
}

Card2Col.defaultProps = defaultProps

export default Card2Col
