import { getJobDetailsById } from 'api/jobs'
import { Card, Modal, Table } from 'atoms'
import { calculateRuntime, formatDate } from 'lib/utils/date/formatDate'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import React, { useEffect, useMemo, useState } from 'react'

type ChildProps = {
  jobId: number
  showModal: boolean
  closeModal: () => void
}

const InspectJobModal: React.FC<ChildProps> = ({ jobId, showModal, closeModal }) => {
  const [loading, setLoading] = useState(true)
  const [jobDetails, setJobDetails] = useState(null)

  const fetchJobDetails = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getJobDetailsById({ id: jobId })
    setJobDetails(data)
    setLoading(false)
  }

  useEffect(() => {
    if (jobId) {
      ; (async () => {
        await fetchJobDetails()
      })()
    }
  }, [jobId])

  const formattedJobDetails = useMemo(() => {
    let data = []
    if (!loading && jobDetails) {
      data = [
        { label: 'Id', value: jobId },
        { label: 'Job', value: jobDetails?.module_name },
        { label: 'Module', value: jobDetails?.module_name },
        { label: 'Started At', value: formatDate({ date: new Date(jobDetails?.start_time), withTime: true }) },
        { label: 'Completed At', value: formatDate({ date: new Date(jobDetails?.finish_time), withTime: true }) },
        { label: 'Run time', value: calculateRuntime(jobDetails?.start_time, jobDetails?.finish_time) },
        { label: 'Success', value: jobDetails?.total_success || '--' },
        { label: 'Errors', value: jobDetails?.error_count || '--' },
      ]
    }
    return data
  }, [loading, jobDetails])

  const errorsTableData = useMemo(() => {
    let data = []
    if (!loading && jobDetails) {
      data = jobDetails?.error_logs.map((error, id) => {
        return {
          id,
          data: {
            'Message': { value: error?.message },
            'Count': { value: error?.count }
          }
        }
      })
    }
    return data
  }, [!loading, jobDetails])

  return (
    <Modal
      headerText={'Inspect Job'}
      onClose={() => closeModal()}
      dataTestid={'inspect-job-modal'}
      onBackdropClick={() => closeModal()}
      isVisible={showModal}
      variant='normal'
    >
      <ModalCard2Col
        header='Job Details'
        open={true}
        showCollapse={false}
        data={formattedJobDetails}
        loading={loading}
        addGap
      />
      {!loading && jobDetails.error_count > 0 && (
        <div className='mt-4'>
          <Card
            headerContent={`Errors (${jobDetails.error_count})`}
            customBodyPadding='0 0 0 0'
            showCollapse={false}
            showDivider
            bodyContent={
              <div style={{ maxWidth: '100%' }}>
                <Table
                  columns={[{ title: 'Message', width: '85%' }, { title: 'Count', width: '15%' }]}
                  data={errorsTableData}
                  disableTableBorder />
              </div>}
          />
        </div>
      )}
    </Modal>
  )

}

export default InspectJobModal