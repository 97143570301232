import { FilterSidebarTreeChild } from '@teamfabric/copilot-ui'

export const transformSelectedCheckboxOptions = (
  optionName: string,
  options: FilterSidebarTreeChild[]
) => {
  // Property 'value' does not exist on type 'FilterSidebarTreeChild'
  const result: any[] = []
  const selectedFilterOptions: string[] = []

  options.forEach((option) => {
    if (option.id === optionName) {
      result.push({
        ...option,
        selected: !option.selected,
      })
    } else {
      result.push(option)
    }
  })
  const numOfSelectedOptions = result.reduce((acc, currentValue) => {
    if (currentValue.selected) {
      acc++
      selectedFilterOptions.push(currentValue.value)
    }

    return acc
  }, 0)

  return {
    checkboxStateResult: result,
    numOfSelectedOptions,
    selectedFilterOptions,
  }
}

export const resetCheckboxSelections = (
  options: FilterSidebarTreeChild[]
): FilterSidebarTreeChild[] => {
  const result: FilterSidebarTreeChild[] = []

  options.forEach((option) => {
    result.push({
      ...option,
      selected: false,
    })
  })

  return result
}
