import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from 'atoms'
import PostCommentInput from 'molecules/comments/postComment'
import PostedComment from 'molecules/comments/postedComment'
import {
  createProposalComment,
  addProposalCommentReaction,
  closeAProposalComment,
  deleteProposalCommentReaction,
  getProposalUsers,
  createProposalProductComment,
  closeAProposalProductComment,
  getProposalMemos,
} from 'api/proposals'
import { useToastUtils } from 'lib/utils/toast'
import CommentLoading from 'molecules/loading/commentLoading'

type BaseCommentProps = {
  proposal: any
  isProductDrawer?: boolean
}

type ProductDrawerProps = {
  productMemos: any[]
  proposalProductId: string
  loadProductMemos: (quiet?: boolean) => void
  loadingProductMemos: boolean
}

type CommentProps = BaseCommentProps &
  (
    | ({ isProductDrawer: true } & ProductDrawerProps)
    | { isProductDrawer?: false }
  )

const Comments: React.FC<CommentProps> = ({
  proposal,
  isProductDrawer,
  ...rest
}) => {
  // Destructure product-related props if isProductDrawer is true
  const {
    productMemos,
    proposalProductId,
    loadProductMemos,
    loadingProductMemos,
  } = rest as ProductDrawerProps
  const { id } = useParams()
  const [comments, setComments] = useState([])
  const [saving, setSaving] = useState(false)
  const [loadingMemos, setLoadingMemos] = useState(false)
  const [userOptions, setUserOptions] = useState([])
  const { showSuccessToast, showErrorToast } = useToastUtils()
  // fetch users
  const fetchProposalUsers = useMemo(
    () => async () => {
      const { data } = await getProposalUsers({ id })
      const activeUsers = data.filter(
        (user) => user.status === 'active' && user.type === 'standard'
      )
      const parsedUserOptions = activeUsers.map((el) => ({
        id: el.id,
        label: el?.name ? el?.name : `${el?.first_name} ${el?.last_name || ''}`,
        suffix: el?.email,
      }))
      setUserOptions(parsedUserOptions)
    },
    [id]
  )

  // fetch proposal memos
  const fetchProposalMemos = async (quiet?: boolean) => {
    if (!quiet) setLoadingMemos(true)
    const { data } = await getProposalMemos({ id })
    setLoadingMemos(false)
    setComments(data?.results)
  }

  useEffect(() => {
    fetchProposalUsers()
    if (!isProductDrawer) {
      setLoadingMemos(true)
      fetchProposalMemos()
    }
  }, [])

  useEffect(() => {
    if (isProductDrawer) {
      setComments(productMemos)
    }
  }, [productMemos, isProductDrawer])

  const loadMemos = useMemo(
    () => (isProductDrawer ? loadProductMemos : fetchProposalMemos),
    [isProductDrawer]
  )

  const onPostComment = async (memoText, taggedUsers, parentId) => {
    const body: { text: string; tagged_users?: number[]; parent?: number } = {
      text: memoText,
    }
    // If taggedUsers is not empty, add the "tagged_users" array with the ids to the body
    if (taggedUsers && taggedUsers.length > 0) {
      body.tagged_users = taggedUsers.map((user) => user.id)
    }

    // If parentId is not null, add the "parent" to the body
    if (parentId && parentId !== null) {
      body.parent = parentId
    }

    try {
      setSaving(true)
      // Conditional spreading for productId only if it's a product drawer
      const params = {
        id,
        body,
        ...(isProductDrawer && { productId: proposalProductId }), // Only include productId if isProductDrawer is true
      }
      const createComment = isProductDrawer
        ? createProposalProductComment
        : createProposalComment

      const { data } = await createComment(params)
      if (data?.id) {
        loadMemos(true)
        showSuccessToast('Comment added successfully!')
      }
    } catch (error) {
      showErrorToast('Error occurred, please try again.')
    } finally {
      setSaving(false)
    }
  }

  const handleReactionClick = async (reactionType, commentId) => {
    if (['like', 'dislike'].includes(reactionType)) {
      await onAddReaction(reactionType, commentId)
    } else if (reactionType === 'close') {
      await onCloseComment(commentId)
    }
  }

  const onAddReaction = async (reactionType, memoId) => {
    const body = {
      reaction_type: reactionType,
    }
    try {
      await addProposalCommentReaction({ id, memoId, body })
      loadMemos(true)
      showSuccessToast('Reaction saved successfully!')
    } catch (error) {
      showErrorToast('Error occurred, please try again.')
    }
  }

  const onDeleteReaction = async (reactionType, memoId) => {
    const body = {
      reaction_type: reactionType,
    }
    try {
      await deleteProposalCommentReaction({ id, memoId, body })
      loadMemos(true)
      showSuccessToast('Reaction updated successfully!')
    } catch (error) {
      showErrorToast('Error occurred, please try again.')
    }
  }

  const onCloseComment = async (memoId) => {
    try {
      const closeComment = isProductDrawer
        ? closeAProposalProductComment
        : closeAProposalComment
      const params = {
        id,
        memoId,
        ...(isProductDrawer && { productId: proposalProductId }),
      }
      const { data } = await closeComment(params)
      if (data?.id) {
        loadMemos(true)
        showSuccessToast('Comment closed successfully!')
      }
    } catch (error) {
      showErrorToast('Error occurred, please try again.')
    }
  }

  // Function to parse and add replies to the corresponding parent memos
  const parseMemosWithReplies = (data) => {
    const memos = structuredClone(data)
    // Create a copy of the memos to prevent direct mutation
    const mainMemos = memos.filter((memo) => memo.parent === null)
    // Iterate over memos and find those with a parent
    memos.forEach((memo) => {
      if (memo.parent !== null) {
        // Find the parent memo to attach this reply
        const parentMemo = mainMemos.find((m) => m.id === memo.parent)
        if (parentMemo) {
          // If the parent memo doesn't already have a replies field, initialize it
          if (!parentMemo.replies) {
            parentMemo.replies = []
          }
          // Append the reply to the parent's replies array
          parentMemo.replies.push(memo)
        }
      }
    })

    return mainMemos
  }

  const updatedMemos = useMemo(() => {
    return parseMemosWithReplies(comments)
  }, [comments])

  return (
    <Box
      width={'100%'}
      padding={{ top: 5, bottom: 4 }}
      dataTestid='proposal-comments-content'
    >
      <PostCommentInput
        onPost={(memo, taggedUsers) => onPostComment(memo, taggedUsers, null)}
        showSecondaryInfo
        saving={saving}
        userOptions={userOptions}
      />
      {(loadingProductMemos || loadingMemos) && <CommentLoading />}
      {updatedMemos.map((comment) => (
        <PostedComment
          key={comment?.id}
          customClassName='mb-4'
          comment={comment}
          showReactions
          saving={saving}
          onReactionClick={(type, commentId) =>
            handleReactionClick(type, commentId)
          }
          onReply={(memo, taggedUsers, parentId) =>
            onPostComment(memo, taggedUsers, parentId)
          }
          onRemoveReactionClick={(type, commentId) =>
            onDeleteReaction(type, commentId)
          }
          userOptions={userOptions}
          isProductDrawer={isProductDrawer}
        />
      ))}
    </Box>
  )
}

export default Comments
