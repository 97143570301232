import API, { API_URL, RETAILER_API_URL } from 'api'
// const url = 'https://api-staging.fabric.inc/v3/suppliers/connections/'
const baseUrl = 'https://stage.api.fabric.inc'

export const getMerchants = async ({ params = {} }) => {
  const url = baseUrl + '/v3/suppliers/connections/'
  // return await API.get(url, { params })
  return await API.get(API_URL.GET_MERCHANTS(), { params })
}

export const getConnections = async ({ params = {} }) => {
  return await API.get(API_URL.GET_CONNECTIONS(), { params })
}

export const getConnectionSummary = async () => {
  return await API.get(API_URL.CONNECTION_SUMMARY())
}

export const patchConnections = async ({ connectionId, body }) => {
  return await API.patch(API_URL.CONNECTIONS_DETAIL({ connectionId }), body)
}

export const patchConnectionSla = async ({ connectionId, slaId, body }) => {
  return await API.patch(API_URL.CONNECTION_SLA({ connectionId, slaId }), body)
}

export const patchConnectionFees = async ({ connectionId, feeId, body }) => {
  return await API.patch(API_URL.CONNECTION_FEES({ connectionId, feeId }), body)
}

export const getConnectionDetail = async ({ id }) => {
  return await API.get(API_URL.GET_CONNECT_BY_ID({ id }))
}

export const getRetailerConnections = async ({ params = {} }) => {
  return await API.get(RETAILER_API_URL.GET_CONNECTIONS(), { params })
}

export const getRetailerConnectionDetails = async ({ id }) => {
  return await API.get(RETAILER_API_URL.GET_CONNECTIONS_BY_ID({ id }))
}

export const getConnectionCredentials = async ({ params = {} }) => {
  return await API.get(API_URL.GET_CONNECTION_CREDENTIALS(), { params })
}

export const postConnectionCredential = async ({ connectionId, body }) => {
  return await API.post(
    API_URL.POST_CONNECTION_CREDENTIALS({ connectionId }),
    body
  )
}

export const patchConnectionCredential = async ({
  connectionId,
  credentialId,
  body,
}) => {
  return await API.patch(
    API_URL.PATCH_CONNECTION_CREDENTIALS({ connectionId, credentialId }),
    body
  )
}

export const deleteConnectionCredential = async ({
  connectionId,
  credentialId,
  body,
}) => {
  return await API.delete(
    API_URL.PATCH_CONNECTION_CREDENTIALS({ connectionId, credentialId }),
    body
  )
}

export const patchConnectionShippingAccount = async ({
  connectionId,
  body,
}) => {
  return await API.put(
    API_URL.PATCH_CONNECTION_SHIPPING_ACCOUNT({ connectionId }),
    body
  )
}

export const getShippingAccounts = async ({ params = { limit: 250 } }) => {
  return await API.get(API_URL.GET_SHIPPING_ACCOUNTS(), { params })
}

export const getConnectionInvites = async ({ params = {} }) => {
  return await API.get(API_URL.CONNECTION_INVITE(), { params })
}

export const resendConnectionInvite = async ({ connectionId }) => {
  return await API.post(API_URL.CONNECTION_INVITE_RESEND({ connectionId }))
}

export const postConnectionMemo = async ({ connectionId, body }) => {
  return await API.post(API_URL.CONNECTION_MEMO({ connectionId }), body)
}

export const getConnectionPlatformAccounts = async ({ connectionId }) => {
  return await API.get(API_URL.CONNECTION_PLATFORM_ACCOUNTS({ connectionId }))
}
