import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    short: 1,
    added_to_proposal: 1,
    ruleset_variant_summary: 1,
    is_rejected: 0,
  },
  selected: [],
  selectedProposalVariantIDs: [],
}
const proposalItemsSlice = createSlice({
  name: 'proposal_items',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setParams(state, action) {
      state.params = action.payload
    },
    removeParams(state, action) {
      delete state.params[action.payload]
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setIsRejected(state, action) {
      state.params.page = 1
      state.params.is_rejected = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setSelectedProposalVariantIds: (state, action) => {
      state.selectedProposalVariantIDs = action.payload
    },
  },
})

export const {
  setSorting,
  setSearch,
  setParams,
  removeParams,
  setCurrentPage,
  setIsRejected,
  setSelected,
  setSelectedProposalVariantIds,
} = proposalItemsSlice.actions

export default proposalItemsSlice.reducer
