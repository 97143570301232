import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, MultiLineTextArea, PageHeader } from 'atoms'
import _ from 'lodash'
import br2nl from '@derhuerst/br2nl'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const CustomPackingSlipText: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [value, setValue] = useState('')
  const [preference, setPreference] = useState(null)

  const defaultPackingSlipBottomText = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_packing_slip_bottom_text'])
    }
    return null
  }, [preferences, loading])

  useEffect(() => {
    if (typeof defaultPackingSlipBottomText !== 'undefined') {
      setPreference(defaultPackingSlipBottomText)
      if (defaultPackingSlipBottomText?.retailer_preference) {
        setValue(defaultPackingSlipBottomText?.retailer_preference?.value)
      }
    }
  }, [defaultPackingSlipBottomText])

  const handleUpdate = (bottomText) => {
    if (preference && !loading) {
      const json = {
        preference: { id: preference?.id },
        value: bottomText,
      }
      handleChange(json)
    }
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Custom Packing Slip Text</span>}
              h2Text={
                <span className='body2-regular'>
                  The default packing slip will include your contact email and
                  phone number. You can specify custom text copy below, and this
                  will be added at the bottom of the default packing slip.
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <MultiLineTextArea
            label='Default Custom Packing Slip Text'
            width='50%'
            rows={4}
            inputProps={{
              value: br2nl(value),
              onChange: ({ target: { value } }) => {
                setValue(value)
                handleUpdate(value)
              },
              placeholder: 'custom packing slip text...',
            }}
          />
        }
      ></Card>
    </Box>
  )
}

CustomPackingSlipText.defaultProps = defaultProps
export default CustomPackingSlipText
