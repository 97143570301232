import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledReturnDetail = styled.div`
  .is-flex {
    display: flex;
    gap: ${theme.space[3]};
  }
  .memoContainer {
    display: flex;
    align-items: center;
    gap: ${theme.space[5]};
  }
  .iconDiv div {
    display: block;
  }
`

export const StyledReturnItemCol = styled.div`
  .container {
    gap: ${theme.space[2]};
  }
  .variantName {
    width: 200px;
    word-wrap: break-word;
  }
`
