import { FC, useMemo } from 'react'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/productInventoryTemplatesSlice'
import { setCurrentPage as setExternalTemplateCurrentPage } from 'store/externalPlatformTemplates'
import Link from 'atoms/link/link'
import Box from 'atoms/box/box'
import { useNavigate, useParams } from 'react-router-dom'
import { capitalizeFirstLetter } from 'lib/utils'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
}

const TemplatesTable: FC<ChildProps> = ({
  data,
  loading,
  currentPage,
  totalRows,
}) => {
  const { platform } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const tableColumns = useMemo(
    () => [
      { title: 'Template name', width: '40%' },
      { title: 'Template ID', width: '15%' },
      { title: 'Data type', width: '15%' },
      { title: 'Direction', width: '15%' },
      { title: 'Mappings', width: '15%' },
    ],
    []
  )

  const tableData = useMemo(() => {
    return data.map((template: any, id: number) => ({
      id,
      data: {
        'Template name': {
          onRender: () => (
            <Link
              onClick={() => navigate(`${template.id}`)}
              label={template?.name}
              variant='primary'
              mode='inline'
            />
          ),
        },
        'Template ID': { value: template.id },
        'Data type': { value: capitalizeFirstLetter(template?.data_type) },
        'Direction': { value: capitalizeFirstLetter(template?.direction) },
        'Mappings': {
          value:
            template?.mapping_field_count ||
            template?.template_mappings?.length,
        },
      },
    }))
  }, [!loading, data])

  const setPage = (page: number) => {
    if (platform) {
      const target =
        platform === 'shopify'
          ? 'shopifyTemplateParams'
          : 'fabricTemplateParams'
      dispatch(
        setExternalTemplateCurrentPage({
          target: target,
          page,
        })
      )
    } else {
      dispatch(setCurrentPage(page))
    }
  }

  return (
    <Box dataTestid='templates-table' margin={{ bottom: 7 }}>
      <Table
        columns={tableColumns}
        data={tableData}
        loading={loading}
        selectable={false}
        customPaginationProps={{
          handlePagination: setPage,
          activePageNumber: currentPage,
          perPage: 10,
          totalRecords: totalRows,
        }}
      />
    </Box>
  )
}

export default TemplatesTable
