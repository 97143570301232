import { useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import KeyDetailsCard from '../components/basicDetails/keyDetailsCard'
import BusinessAddressCard from '../components/basicDetails/businessAddress'
import { AddressFormDefaultValues } from 'molecules/address/constants'
import { useToast } from '@teamfabric/copilot-ui'
import { patchContextDetails } from 'api/context'
import { patchLocation } from 'api/locations'

const defaultValues = {
  name: '',
  website: '',
  connections_manager_id: '',
  merchandising_manager_id: '',
  transaction_manager_id: '',
  address: AddressFormDefaultValues,
  location: {
    address: AddressFormDefaultValues,
    id: null,
    nickName: '',
    type: '',
  },
}

const BasicDetails: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [formData, setFormData] = useState(defaultValues)
  const [initialValues, setInitialValues] = useState(defaultValues)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Basic Details' })
        ),
      0
    )
  }, [])

  const updateAddress = async () => {
    const { location } = initialValues
    if (!location.id) {
      return
    }
    // Updated address
    const body = {
      address: { ...formData.address },
      id: location.id,
      nickname: location.nickName,
      type: location.type,
    }
    await patchLocation({ id: location.id, body })
  }

  const isUpdated = () => {
    return (
      initialValues.connections_manager_id !==
        formData.connections_manager_id ||
      initialValues.merchandising_manager_id !==
        formData.merchandising_manager_id ||
      formData.transaction_manager_id !== formData.transaction_manager_id ||
      initialValues.name !== formData.name ||
      initialValues.website !== formData.website
    )
  }

  const updateContext = async () => {
    if (!isUpdated()) {
      return
    }
    await patchContextDetails({
      body: {
        connections_manager: { id: formData.connections_manager_id },
        merchandising_manager: { id: formData.merchandising_manager_id },
        transaction_manager: { id: formData.transaction_manager_id },
        name: formData.name,
        website: formData.website,
      },
    })
  }

  const onSave = async () => {
    try {
      setSaving(true)
      await Promise.all([updateContext(), updateAddress()])
      showToast({
        label: 'Details updated successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Details failed to update, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Basic Details'
        h2Text='Manage general information about your business.'
        primaryButtons={[
          {
            onClick: () => navigate('/settings'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving,
          },
        ]}
        customClassName='mb-4'
      />
      <KeyDetailsCard
        setFormData={setFormData}
        setInitialValues={setInitialValues}
      />
      <div className='divider-4' />
      <BusinessAddressCard
        setFormData={setFormData}
        setInitialValues={setInitialValues}
      />
    </Box>
  )
}

export default BasicDetails
