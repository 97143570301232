import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { getCommissionProfiles } from 'api/suppliers'
import { dropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  handleUpdate?: (data: any) => void
  params?: any
  selectedProfile?: any
  width?: string
}

const SelectCommissionProfile: React.FC<ChildProps> = ({
  handleUpdate,
  params,
  selectedProfile,
  width,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const queryParams = params
        ? params
        : { pagination: 0, short: 1, order_by: 'id' }
      const { data } = await getCommissionProfiles({
        params: { ...queryParams },
      })
      const options = []
      if (data.count > 0) {
        data.results.map((option) => {
          options.push({
            id: option.id,
            label: option.name,
            value: option.values[0].value,
          })
        })
      }
      if (selectedProfile) {
        const option = data.results.find((el) => el.id === selectedProfile.id)
        setSelectedOption(option)
      }
      setOptions(options)
      setLoading(false)
    })()
  }, [])

  return (
    <Dropdown
      required
      label='Commission Profile'
      width={width || '100%'}
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder='Select a profile'
      disabled={loading}
    />
  )
}

export default SelectCommissionProfile
