const BadgeStatusPresenter = (status: string) => {
  const presenter: { label: string; status: string } = { label: '', status: '' }

  switch (status) {
    case 'active':
      presenter.label = 'Active'
      presenter.status = 'success'
      break
    case 'onboarding':
      presenter.label = 'Onboarding'
      presenter.status = 'info'
      break
    case 'suspended':
      presenter.label = 'Suspended'
      presenter.status = 'alert'
      break
    default:
      presenter.label = 'Not Set'
      presenter.status = 'default'
  }

  return presenter
}

export default BadgeStatusPresenter
