import React, { useEffect, useState } from 'react'
import Card4Columns from 'molecules/cards/4Columns'
import { formatAddress } from 'modules/orders/utils'

type ShippingProps = {
  loading: boolean
  details: any
}

const Shipping: React.FC<ShippingProps> = ({ loading, details }) => {
  const [shippingDetails, setShippingDetails] = useState<any[]>([])

  useEffect(() => {
    if (!loading) {
      formatData()
    }
  }, [loading, details])

  const formatData = () => {
    const method =
      details?.shipping_method !== null &&
      details?.shipping_method?.name !== null
        ? details?.shipping_method?.generic_shipping_method?.name
        : details?.requested_shipping_method?.name
    let formattedDetails = [
      {
        label: 'Shipping method',
        value: method,
      },
      {
        label: 'Ship To',
        value: formatAddress(details?.ship_to),
      },
    ]
    setShippingDetails(formattedDetails)
  }

  return (
    <div>
      <Card4Columns
        header='Shipping'
        loading={loading}
        data={shippingDetails}
        customClassName='mb-4'
      />
    </div>
  )
}

export default Shipping
