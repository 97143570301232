import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { RootState } from 'store'
import { ONBOARDING_TASKS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setIntegration } from 'store/onboardingSlice'
import RadioGroup from 'atoms/input/radioGroup'
import { patchConnections } from 'api/connection'
import {
  deleteAssignTask,
  getOnboarding,
  postAssignTask,
  putCompleteTask,
} from 'api/onboarding'
import CardLoading from 'molecules/loading/cardLoading'
import LearnMoreModal from './modal/learnMoreModal'
import { useToast } from '@teamfabric/copilot-ui'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'routes/pageRoutes'

type IntegrationIntroProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  reseting: boolean
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const IntegrationIntro: React.FC<IntegrationIntroProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  reseting,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const connectionId = useSelector(
    (state: RootState) => state.onboarding.connectionId
  )
  const [selectedMethod, setSelectedMethod] = useState('')
  const [loading, setLoading] = useState(false)
  const [displayLearnMoreModal, setDisplayLearnMoreModal] = useState(false)
  const [conditionalTasks, setConditionalTasks] = useState([])

  const loadConditional = async () => {
    try {
      setLoading(true)
      const {
        data: { results },
      } = await getOnboarding({ params: { type: 'conditional' } })
      setConditionalTasks(results)
      setLoading(false)
    } catch (error) {
      showToast({
        label: "Couldn't load",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  // First loaded
  useEffect(() => {
    ;(async () => {
      setIsNextButtonDisabled(integration.selectedMethod === '')
      setSelectedMethod(integration.selectedMethod)
      await loadConditional()
    })()
  }, [])

  useEffect(() => {
    if (reseting) {
      setSelectedMethod('')
    }
  }, [reseting])

  const addAssignTask = async () => {
    const task = conditionalTasks.find(({ code }) => code === selectedMethod)
    try {
      let selectedMethodId = ''
      const taskIds = {
        'self-serve-edi': '',
        'bigcommerce': '',
        'shipstation': '',
      }
      if (typeof task !== 'undefined') {
        const {
          data: { id },
        } = await postAssignTask({
          body: {
            onboarding_task: { id: task.id },
          },
        })
        selectedMethodId = id
        taskIds[selectedMethod] = id
      }
      dispatch(
        setIntegration({
          taskId: integration.taskId,
          processNum: 1,
          introDone: true,
          completed: false,
          selectedIntegrationInfo: {
            taskIds,
            variants: [],
            orderIds: [],
          },
          selectedMethod: selectedMethod,
        })
      )
    } catch (error) {
      showToast({
        label: "Couldn't add assign task",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  const completeTask = async () => {
    try {
      await putCompleteTask({
        taskId: integration.taskId,
      })
    } catch (error) {
      showToast({
        label: "Couldn't complete task",
        variant: 'error',
        isDismissable: true,
        id: '3',
      })
    }
  }

  // method selected => next is available
  useEffect(() => {
    setIsNextButtonDisabled(selectedMethod === '')
  }, [selectedMethod])

  const setCurrentStepToNext = () => {
    let nextTask = ''
    switch (selectedMethod) {
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.EDI
        .TASK_NAME:
        nextTask = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_EDI.TASK_NAME
        break
      case ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.API
        .TASK_NAME:
        nextTask =
          ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.TASK_NAME
        break
      default:
        nextTask = selectedMethod
    }
    dispatch(
      setCurrentStep({
        value: nextTask,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      })
    )
  }

  const deleteOtherTasks = async () => {
    const removingKeys = Object.keys(
      integration.selectedIntegrationInfo.taskIds
    ).filter(
      (method) =>
        method !== selectedMethod &&
        integration.selectedIntegrationInfo.taskIds[method] !== ''
    )
    if (removingKeys.length > 0) {
      await deleteAssignTask({
        taskId: integration.selectedIntegrationInfo.taskIds[removingKeys[0]],
      })
    }
  }

  const saveConnectionAndTask = async () => {
    try {
      setLoading(true)
      const integration_type =
        selectedMethod ===
        ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD.METHODS.EDI
          .TASK_NAME
          ? ONBOARDING_TASKS.INTEGRATION.EDI_CODE
          : selectedMethod
      await Promise.all([
        deleteOtherTasks(),
        patchConnections({
          connectionId: connectionId,
          body: {
            integration_type,
          },
        }),
        addAssignTask(),
        completeTask(),
      ])
    } catch (error) {
      showToast({
        label: "Couldn't complete",
        variant: 'error',
        isDismissable: true,
        id: '4',
      })
    } finally {
      setLoading(false)
    }
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      saveConnectionAndTask()
      setCurrentStepToNext()
    })()
  }, [isNextClicked])

  // save and exit button clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      saveConnectionAndTask()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  const methodOptions = Object.keys(currentTask.METHODS).map((key) => ({
    'data-testid': '',
    'disabled': false,
    'id': currentTask.METHODS[key].TASK_NAME,
    'label': currentTask.METHODS[key].NAME,
    'value': currentTask.METHODS[key].TASK_NAME,
  }))

  return (
    <>
      {(loading || reseting) && (
        <CardLoading label={currentTask.NAME} num={6} />
      )}
      {!loading && !reseting && (
        <>
          <LearnMoreModal
            isVisible={displayLearnMoreModal}
            setIsDisplayModal={setDisplayLearnMoreModal}
          />
          <Card
            open={true}
            showCollapse={false}
            showDivider={true}
            headerContent={
              <div style={{ width: '70%' }}>
                <h1 className='h5 mb-2'>{currentTask.NAME}</h1>
                <p className='body1-regular mb-3'>
                  fabric supports multiple methods to trade inventory, order,
                  shipment, & invoice data. For low to medium order volumes, we
                  recommend using the fabric Supplier Portal (that includes
                  import/export tools to process orders in bulk). For higher
                  volumes, using EDI or the fabric API are likely more
                  efficient.
                </p>
                <p className='body1-regular'>
                  Which of the following options best describes how you would
                  prefer to integrate to fabric Marketplace?
                </p>
              </div>
            }
            bodyContent={
              <>
                <h2 className='kicker grey-500'>integration method</h2>
                <RadioGroup
                  label=''
                  name='level'
                  value={selectedMethod}
                  onChange={({ target: { value } }) => {
                    setSelectedMethod(value)
                  }}
                  required={true}
                  options={methodOptions}
                />
                <div className='is-flex'>
                  <p>Unsure which to use?</p>
                  <div
                    className='v-center body1-medium blue-500 ml-2 clickableCells'
                    onClick={() => setDisplayLearnMoreModal(true)}
                  >
                    Learn about our integration methods
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default IntegrationIntro
