import React, { useState, useEffect, useMemo } from 'react'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import {
  setFilters,
  setStatusOptions,
  setIntegrationOptions,
  resetAllFilters,
} from 'store/suppliersSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  handleCheckboxFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { FILTERS } from 'molecules/filter/constants'
import Box from 'atoms/box/box'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import { MERCHANTS } from 'modules/merchants/components/constants'

type ChildProps = {
  params: any
}

const SupplierFilter: React.FC<ChildProps> = ({ params }) => {
  const [statusCounter, setStatusCounter] = useState(0)
  const [integrationCounter, setIntegrationCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.suppliers.statusOptions
  )
  const integrationTypeOptions = useSelector(
    (state: RootState) => state.suppliers.integrationOptions
  )

  const isAFilterApplied = useMemo(() => {
    return statusCounter + integrationCounter > 0
  }, [statusCounter, integrationCounter])

  // Define respective filterKey fns
  const filterActions = {
    status: {
      action: setStatusOptions,
      options: statusCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setStatusCounter,
      chipName: MERCHANTS.STATUS.NAME,
    },
    integration_type: {
      action: setIntegrationOptions,
      options: integrationTypeOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setIntegrationCounter,
      chipName: MERCHANTS.INTEGRATION_TYPE.NAME_SHORT,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${MERCHANTS.STATUS.NAME}-filter`}
          label={MERCHANTS.STATUS.NAME}
          data={statusCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={statusCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <CheckboxFilter
          dataTestid={`${MERCHANTS.INTEGRATION_TYPE.NAME_SHORT}-filter`}
          label={MERCHANTS.INTEGRATION_TYPE.NAME}
          data={integrationTypeOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.INTEGRATION_TYPE.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={integrationCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.INTEGRATION_TYPE.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default SupplierFilter
