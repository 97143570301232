import React, { useState } from 'react'
import { MODALS } from 'molecules/modals/constants'
import { exportProducts } from 'api/products'
import Modal from 'atoms/modal/modal'
import { useToast } from '@teamfabric/copilot-ui'
import { BUTTONS, VARIANTS } from 'lib/constants'
import RadioGroup from 'atoms/input/radioGroup'
import { exportInventory } from 'api/inventory'
import ExportItems from './exportItems'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setExportType, setTemplate, setTransformer } from 'store/productsSlice'

type ProductExportsModalProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
}

const ProductExportsModal: React.FC<ProductExportsModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const [loading, setLoading] = useState(true)
  const [schedulingExport, setSchedulingExport] = useState(false)
  const [templateOptions, setTemplateOptions] = useState([])
  const [transformerOptions, setTransformerOptions] = useState([])
  const [
    hasFetchedTemplatesAndTransformers,
    setHasFetchedTemplatesAndTransformers,
  ] = useState(false)

  const exportType = useSelector(
    (state: RootState) => state.products.export_type
  )
  const transformer = useSelector(
    (state: RootState) => state.products.transformer
  )
  const template = useSelector((state: RootState) => state.products.template)
  const dispatch = useDispatch()

  const showToast = useToast()

  const scheduleExport = async () => {
    try {
      setLoading(true)
      setSchedulingExport(true)
      if (
        exportType === MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS[0].value
      ) {
        await exportProducts({
          params: {
            template_id: template?.id,
            transformer_id: transformer?.id,
          },
        })
      } else await exportInventory({})
      showToast({
        label: MODALS.EXPORT.TOAST.SUCCESS,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: MODALS.EXPORT.TOAST.FAILURE,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      closeModal()
      setLoading(false)
      setSchedulingExport(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    dispatch(
      setExportType(MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS[0].value)
    )
    dispatch(setTemplate(null))
    dispatch(setTransformer(null))
  }

  return (
    <Modal
      headerText={MODALS.EXPORT.TYPES.ITEMS.LABEL}
      description=''
      size='small'
      onClose={() => closeModal()}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: '',
          onClick: () => closeModal(),
          text: BUTTONS.CLOSE.LABEL,
          variant: VARIANTS.SECONDARY,
        },
        {
          dataTestid: 'export-button',
          onClick: () => scheduleExport(),
          text: BUTTONS.EXPORT.LABEL,
          variant: VARIANTS.PRIMARY,
          isDisabled:
            (exportType ===
              MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS[0].value &&
              (loading || !template)) ||
            schedulingExport,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => closeModal}
    >
      <RadioGroup
        label='Export Type'
        name='exportType'
        onChange={(e) => {
          dispatch(setExportType(e.target.value))
        }}
        options={MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS}
        defaultValue={exportType}
      />
      <p className='mb-4'>{MODALS.EXPORT.TYPES.ITEMS.COPY_1}</p>
      {exportType ===
        MODALS.EXPORT.TYPES.ITEMS.RADIO_GROUP_OPTIONS[0].value && (
        <ExportItems
          template={template}
          transformer={transformer}
          templateOptions={templateOptions}
          setTemplateOptions={setTemplateOptions}
          transformerOptions={transformerOptions}
          setTransformerOptions={setTransformerOptions}
          loading={loading}
          setLoading={setLoading}
          hasFetchedTemplatesAndTransformers={
            hasFetchedTemplatesAndTransformers
          }
          setHasFetchedTemplatesAndTransformers={
            setHasFetchedTemplatesAndTransformers
          }
        />
      )}
    </Modal>
  )
}

export default ProductExportsModal
