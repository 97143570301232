import { FC } from 'react'
import { SkeletonH5 } from 'atoms/skeleton'

interface AddressProps {
  address: any
  showEmail?: boolean
  showPhoneNumber?: boolean
  loading: boolean
}

const AddressCardPresenter: FC<AddressProps> = ({
  address,
  showEmail,
  showPhoneNumber,
  loading,
}) => {
  const { name1, street1, city, province, postal_code, country } = address
  let line3 = `${city}, ${province}, ${postal_code}, ${country}`
  if (showPhoneNumber) {
    line3 = `${city}, ${province}, ${postal_code}, ${country}, ${address.phone1}`
  }

  const LoadingSkeleton = () => {
    return (
      <>
        <SkeletonH5 width='50%' style={{ marginBottom: '12px' }} />
        <SkeletonH5 width='75%' style={{ marginBottom: '12px' }} />
      </>
    )
  }

  const AddressCard = () => {
    return (
      <>
        <p className='body1-regular m-0 grey-400'>{name1}</p>
        {address?.name2 && (
          <p className='body1-regular m-0 grey-400'> {address.name2} </p>
        )}
        <p className='body1-regular m-0 grey-400'>{street1}</p>
        {address?.street2 && (
          <p className='body1-regular m-0 grey-400'> {address.street2} </p>
        )}
        <p className='body1-regular m-0 grey-400'>{line3}</p>
        {showEmail && (
          <p className='body1-regular m-0 grey-400'>{address.email}</p>
        )}
      </>
    )
  }

  return <>{loading ? <LoadingSkeleton /> : <AddressCard />}</>
}

export default AddressCardPresenter
