import { useEffect, useMemo, useState } from 'react'
import { Box, EmptyPage } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { getPlatformAccounts } from 'api/platformAccount'
import ImageCardWithActions from 'molecules/cards/imageCardWithActions'
import AddIntegrationsModal from '../components/integrations/addIntegrationsModal'
import { Skeleton } from 'atoms/skeleton'

const usePlatformActions = (platformAccounts) => {
  const navigate = useNavigate()

  return useMemo(() => {
    return platformAccounts.map((account) => {
      const { platform, id } = account

      const commonActions = [
        {
          name: 'Configuration',
          handleClick: () => navigate(`${platform.code}/${id}`),
        },
        {
          name: `${platform.name} Templates`,
          handleClick: () => navigate(`${platform.code}/${id}/templates`),
        },
      ]

      const platformSpecificActions = {
        shopify: [
          ...commonActions,
          {
            name: 'Webhook History',
            handleClick: () =>
              navigate(`${platform.code}/${id}/webhook-history`),
          },
          {
            name: 'Utilities',
            handleClick: () => navigate(`${platform.code}/${id}/utilities`),
          },
        ],
        fabric: commonActions,
      }

      return {
        platform: platform.code,
        moreActions: platformSpecificActions[platform.code] || [],
      }
    })
  }, [platformAccounts, navigate])
}

const Integrations: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()

  const [platformAccounts, setPlatformAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Integrations' })
        ),
      0
    )
  }, [])

  const fetchPlatformAccounts = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getPlatformAccounts({})
    setPlatformAccounts(data.results)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await fetchPlatformAccounts()
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const platformActions = usePlatformActions(platformAccounts)

  const IntegrationsList = () => (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Integrations'
        customClassName='mb-4'
        dataTestid='integrations-page-header'
        primaryButtons={[
          {
            onClick: () => navigate('/settings'),
            text: 'Back',
            variant: 'secondary',
            dataTestid: 'back-button'
          },
          {
            onClick: () => setShowAddIntegrationModal(true),
            text: 'Add Integration',
            variant: 'primary',
            icon: 'Add',
            iconPosition: 'left',
            dataTestid: 'add-integration-button'
          },
        ]}
      />
      {platformActions.map((accountActions, index) => (
        <div key={index} className='mb-4'>
          <ImageCardWithActions
            logo_url={platformAccounts[index].platform?.logo_url}
            moreActions={accountActions.moreActions}
          />
        </div>
      ))}
    </Box>
  )

  return (
    <>
      <AddIntegrationsModal
        showModal={showAddIntegrationModal}
        setShowModal={setShowAddIntegrationModal}
        loadPlatformAccounts={() => fetchPlatformAccounts(true)}
        existingAccounts={platformAccounts}
      />
      {loading ? (
        <Box
          flex={{ flexDirection: 'column' }}
          gap={4}
          padding={{ left: 6, right: 6, top: 4 }}
        >
          <Skeleton width='100%' height='60px' />
          <Skeleton width='100%' height='60px' />
          <Skeleton width='100%' height='60px' />
        </Box>
      ) : !platformAccounts.length ? (
        <EmptyPage
          headerText='You currently have no integration added.'
          description='Click "Add Integration" below to add integration credentials.'
          buttons={[
            {
              onClick: () => setShowAddIntegrationModal(true),
              text: 'Add Integration',
              variant: 'primary',
              icon: 'Add',
              iconPosition: 'left',
              dataTestid: 'empty-page-add-integration-button'
            },
          ]}
          customClassName='p-6'
        />
      ) : (
        <IntegrationsList />
      )}
    </>
  )
}

export default Integrations
