import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage, setSorting } from 'store/cancellationsSlice'
import { ORDERS } from 'modules/orders/constants'
import ItemDetail from '../../modal/ItemDetail'
import { getTenantType } from 'api/helper'
import Link from 'atoms/link/link'
import { BUTTONS } from 'lib/constants'
import { VARIANTS } from 'lib/constants/index'
import CancellationDetail from '../../modal/cancellations/cancellationDetail'
import Box from 'atoms/box/box'
import CancellationsActionsMenu from './parts/cancellationsActionsMenu'
import AcknowledgeCancellationsModal from 'modules/orders/components/modal/cancellations/acknowledgeCancellationsModal'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
  showAcknowledgeCancellationModal: boolean
  setShowAcknowledgeCancellationModal: any
}

const CancellationsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showCancelsDetailModal, setShowCancelsDetailModal] = useState(false)
  const [cancellation, setCancellation] = useState({})
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [orderNumber, setOrderNumber] = useState('')

  const { isRetailer } = getTenantType()

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        sortable: true,
      },
      {
        title: 'PO #',
      },
      {
        title: 'Originator',
      },
      {
        title: 'Reason',
      },
      {
        title: 'Registered',
        width: '13%',
      },
      {
        sortable: true,
        title: 'Acknowledged',
        width: '13%',
      },
      {
        title: 'Actions',
        width: '80',
      },
    ]
    if (!isRetailer) {
      columns.splice(2, 0, { title: 'Merchant' })
    }
    return columns
  }, [isRetailer])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((cancel, id) => {
      return {
        id,
        data: {
          'ID': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowCancelsDetailModal(true)
                  setCancellation(cancel)
                }}
                label={cancel?.id}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'PO #': {
            onRender: () => (
              <Link
                onClick={() => {
                  setShowOrderDetailModal(true)
                  setOrderId(cancel?.order_id)
                  setOrderNumber(cancel?.purchase_order_number)
                }}
                label={cancel?.purchase_order_number}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Merchant': { value: cancel?.retailer?.name },
          'Originator': {
            value:
              cancel?.canceled_by === 'retailer'
                ? cancel?.retailer?.name
                : cancel?.brand?.name,
          },
          'Reason': { value: cancel?.reason },
          'Registered': { value: formatDate({ date: cancel?.canceled_at }) },
          'Acknowledged': {
            value: formatDate({ date: cancel?.acknowledged_at }),
          },
          'Actions': {
            onRender: () => (
              <CancellationsActionsMenu
                isRetailer={isRetailer}
                cancellation={cancel}
                setShowAcknowledgeCancellationModal={
                  props.setShowAcknowledgeCancellationModal
                }
                setCancellation={setCancellation}
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const handleSort = (key: string) => {
    const mappedKey = ORDERS.SORTING_MAPS.CANCELLATIONS[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <>
      <Box dataTestid='cancellations-table' margin={{ bottom: 7 }}>
        {/* Modal Cancel Detail */}
        <Modal
          headerText={`Inspect Cancel`}
          description=''
          size='small'
          onClose={() => setShowCancelsDetailModal(false)}
          isVisible={showCancelsDetailModal}
          footerButtons={[
            {
              dataTestid: '',
              onClick: () => setShowCancelsDetailModal(false),
              text: BUTTONS.CLOSE.LABEL,
              variant: VARIANTS.PRIMARY,
            },
          ]}
          dataTestid={'cancellation-details-modal'}
          onBackdropClick={() => setShowCancelsDetailModal(false)}
        >
          <CancellationDetail cancellation={cancellation} />
        </Modal>
        {/* Modal Order Detail */}
        <Modal
          headerText={`Order Details`}
          description=''
          size='small'
          onClose={() => setShowOrderDetailModal(false)}
          isVisible={showOrderDetailModal}
          footerButtons={[
            {
              dataTestid: '',
              onClick: () => setShowOrderDetailModal(false),
              text: BUTTONS.CLOSE.LABEL,
              variant: VARIANTS.PRIMARY,
            },
          ]}
          dataTestid={'order-details-modal'}
          onBackdropClick={() => setShowOrderDetailModal(false)}
        >
          <ItemDetail orderId={orderId} orderNumber={orderNumber} />
        </Modal>
        <AcknowledgeCancellationsModal
          cancellation={cancellation}
          isRetailer={isRetailer}
          showAcknowledgeCancellationModal={
            props.showAcknowledgeCancellationModal
          }
          setShowAcknowledgeCancellationModal={
            props.setShowAcknowledgeCancellationModal
          }
        />
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          // TODO: flip to true once bulk actions for cancellations implemented
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
          onAscendingSort={(key: string) => handleSort(key)}
          onDescendingSort={(key: string) => handleSort(key)}
        />
      </Box>
    </>
  )
}

export default CancellationsTable
