import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { closeProposalIssue } from 'api/proposals'
import { useToast } from '@teamfabric/copilot-ui'
import { MultiLineTextArea } from 'atoms'
import { postIssueMemo } from 'api/memo'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  issue: any
  loadProposalIssue: () => void
  onComplete: () => void
}

const CloseIssueModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  proposal,
  issue,
  loadProposalIssue,
  onComplete,
}) => {
  const [saving, setSaving] = useState(false)
  const [memoText, setMemoText] = useState(undefined)

  const showToast = useToast()

  const addANote = async () => {
    await postIssueMemo({
      text: memoText,
      module: 'proposals',
      moduleId: proposal.id,
      issueId: issue.id,
    })
  }

  const closeIssue = async () => {
    await closeProposalIssue({
      proposalId: proposal.id,
      issueId: issue.id,
    })
  }

  const onSubmit = async () => {
    try {
      setSaving(true)
      if (memoText && memoText.length > 0) {
        await addANote()
        await closeIssue()
      } else {
        await closeIssue()
      }
      showToast({
        label: 'Issue Closed!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error closing the issue: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      onComplete()
      loadProposalIssue()
      setSaving(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Close Issue'
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        footerButtons={[
          {
            onClick: () => setShowModal(false),
            text: 'Cancel',
            variant: 'tertiary',
            isDisabled: saving,
          },
          {
            onClick: () => onSubmit(),
            text: 'Mark Issue Closed',
            variant: 'primary',
            isDisabled: saving,
          },
        ]}
        onBackdropClick={() => setShowModal(false)}
      >
        <p className='grey-600 body1-regular mb-4'>
          Are you sure you're ready to close this issue? Before doing so, please
          ensure your supplier partner has resolved the issue or it is no longer
          a concern.
        </p>
        <MultiLineTextArea
          label='Add a note'
          width='100%'
          limit={110}
          showCounter
          rows={3}
          inputProps={{
            value: memoText,
            onChange: (e) => {
              setMemoText(e.target.value)
            },
            placeholder: 'Add a note (optional)',
          }}
        />
      </Modal>
    </>
  )
}

export default CloseIssueModal
