import { getPackingSlip } from 'api/shipments'
import { FC, useEffect } from 'react'

type ChildProps = {
  shipmentId: string
}

const PackingSlip: FC<ChildProps> = (props) => {
  useEffect(() => {
    ;(async () => {
      const { data } = await getPackingSlip({ id: props.shipmentId })
    })()
  }, [])

  return <div> Missing Designs </div>
}

export default PackingSlip
