import React, { useEffect, useState } from 'react'
import { ONBOARDING_TASKS } from '../../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import CreateEdi from './components/createEdi'
import CreateSampleItems from './components/createSampleItems'
import {
  ediAccountInitData,
  ftpAccountInitData,
  getEdiProcessNum,
} from 'modules/onboarding/utils'
import UploadInventory from './components/uploadInventory'
import { getEdiAccounts } from 'api/edi'
import { getFtpAccounts } from 'api/ftp'
import CreateOrders from './components/createOrders'
import UploadFulfillment from './components/uploadFulfillment'
import UploadInvoice from './components/uploadInvoice'
import { getOrders } from 'api/orders'
import { patchAssignTask } from 'api/onboarding'
import { setCurrentStep, setIntegration } from 'store/onboardingSlice'
import { useToast } from '@teamfabric/copilot-ui'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'routes/pageRoutes'

type EdiProgressProps = {
  isNextClicked: boolean
  setIsNextButtonDisabled: (value: boolean) => void
  setIsNextClicked: (value: boolean) => void
  reseting: boolean
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const EdiProgress: React.FC<EdiProgressProps> = ({
  isNextClicked,
  setIsNextButtonDisabled,
  setIsNextClicked,
  reseting,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()
  const currentStep = useSelector(
    (state: RootState) => state.onboarding.currentStep
  )
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const EDI_TASKS = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS

  const [ediAccount, setEdiAccount] = useState(ediAccountInitData)
  const [ftpAccount, setFtpAccount] = useState(ftpAccountInitData)
  const [loadingOrders, setLoadingOrders] = useState(false)
  const [orders, setOrders] = useState([])

  const loadEdiAccounts = async () => {
    try {
      const {
        data: { results },
      } = await getEdiAccounts()
      if (results.length > 0) {
        setEdiAccount({
          qualifierId: results[0].qualifier,
          senderID: results[0].identifier,
        })
      }
    } catch (error) {
      showToast({
        label: "Couldn't load EDI accounts",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  const loadFtpAccounts = async () => {
    try {
      const {
        data: { results },
      } = await getFtpAccounts()
      if (results.length > 0) {
        setFtpAccount({
          username: results[0].username,
          password: results[0].password,
        })
      }
    } catch (error) {
      showToast({
        label: "Couldn't load FTP accounts",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    }
  }

  const loadOrders = async () => {
    try {
      setLoadingOrders(true)
      const params = {
        order_by: '-id',
        skip_attributes: 1,
        fields:
          'id,purchase_order_number,fulfill_by,fulfilled_at,order_lines,invoice_count,status,received_at,acknowledged_at,retailer',
      }
      const {
        data: { results },
      } = await getOrders({ params })
      setOrders(results)
    } catch (error) {
      showToast({
        label: "Couldn't load orders",
        variant: 'error',
        isDismissable: true,
        id: '3',
      })
    } finally {
      setLoadingOrders(false)
    }
  }

  // First loaded
  useEffect(() => {
    setIsNextButtonDisabled(true)
    ;(async () => {
      await Promise.all([loadEdiAccounts(), loadFtpAccounts(), loadOrders()])
    })()
  }, [])

  const getInfoField = () => {
    let info_field = ''
    switch (currentStep.value) {
      case EDI_TASKS.CREATE_EDI.TASK_NAME:
        info_field = EDI_TASKS.CREATE_EDI.CODE
        break
      case EDI_TASKS.UPLOAD_INVENTORY.TASK_NAME:
        info_field = EDI_TASKS.UPLOAD_INVENTORY.CODE
        break
      case EDI_TASKS.CREATE_ORDERS.TASK_NAME:
        info_field = EDI_TASKS.CREATE_ORDERS.CODE
        break
      case EDI_TASKS.UPLOAD_FULFILLMENT.TASK_NAME:
        info_field = EDI_TASKS.UPLOAD_FULFILLMENT.CODE
        break
      case EDI_TASKS.UPLOAD_INVOICE.TASK_NAME:
        info_field = EDI_TASKS.UPLOAD_INVOICE.CODE
        break
      default:
    }
    return info_field
  }

  const updateSlice = (body) => {
    dispatch(
      setIntegration({
        taskId: integration.taskId,
        processNum: getEdiProcessNum(integration.selectedIntegrationInfo),
        introDone: true,
        completed: false,
        selectedIntegrationInfo: body.info,
        selectedMethod: integration.selectedMethod,
      })
    )
    dispatch(
      setCurrentStep({
        value: EDI_TASKS[currentStep.value.toUpperCase()].NEXT_TASK,
        parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
      })
    )
  }

  const updateEdiProcesses = async () => {
    const info_field = getInfoField()
    if (Object.keys(integration.selectedIntegrationInfo).includes(info_field)) {
      dispatch(
        setCurrentStep({
          value: EDI_TASKS[currentStep.value.toUpperCase()].NEXT_TASK,
          parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
        })
      )
      return
    }
    try {
      const body = {
        info: {
          ...integration.selectedIntegrationInfo,
          [info_field]: true,
        },
      }
      await patchAssignTask({
        taskId:
          integration.selectedIntegrationInfo.taskIds[
            integration.selectedMethod
          ],
        body,
      })
      updateSlice(body)
    } catch (error) {
      showToast({
        label: "Couldn't update assign task",
        variant: 'error',
        isDismissable: true,
        id: '4',
      })
    }
  }

  // next button is clicked for EDI
  useEffect(() => {
    ;(async () => {
      // CREATE_SAMPLE_ITEMS have own next step
      if (
        !isNextClicked ||
        currentStep.value === EDI_TASKS.CREATE_SAMPLE_ITEMS.TASK_NAME
      ) {
        return
      }
      setIsNextClicked(false)
      await updateEdiProcesses()
    })()
  }, [isNextClicked])

  // save and exit button clicked
  useEffect(() => {
    ;(async () => {
      // CREATE_SAMPLE_ITEMS have own save and exit step
      if (
        !isSaveExitClicked ||
        currentStep.value === EDI_TASKS.CREATE_SAMPLE_ITEMS.TASK_NAME
      ) {
        return
      }
      setIsSaveExitClicked(false)
      await updateEdiProcesses()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_EDI.TASK_NAME && (
        <CreateEdi
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          account={{ ...ftpAccount, ...ediAccount }}
          reseting={reseting}
        />
      )}
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_SAMPLE_ITEMS
          .TASK_NAME && (
        <CreateSampleItems
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          isNextClicked={isNextClicked}
          setIsNextClicked={setIsNextClicked}
          reseting={reseting}
          isSaveExitClicked={isSaveExitClicked}
          setIsSaveExitClicked={setIsSaveExitClicked}
        />
      )}
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVENTORY.TASK_NAME && (
        <UploadInventory
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          account={{ ...ftpAccount, ...ediAccount }}
          reseting={reseting}
        />
      )}
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.CREATE_ORDERS.TASK_NAME && (
        <CreateOrders
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          reseting={reseting}
        />
      )}
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_FULFILLMENT.TASK_NAME && (
        <UploadFulfillment
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          account={{ ...ftpAccount, ...ediAccount }}
          orders={orders}
          loadOrders={loadOrders}
          loadingOrders={loadingOrders}
          reseting={reseting}
        />
      )}
      {currentStep.value ===
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS.UPLOAD_INVOICE.TASK_NAME && (
        <UploadInvoice
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          account={{ ...ftpAccount, ...ediAccount }}
          loadOrders={loadOrders}
          orders={orders}
          loadingOrders={loadingOrders}
          reseting={reseting}
        />
      )}
    </>
  )
}

export default EdiProgress
