import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUser } from 'api/auth'

const initialState = {
  user: null,
  isStaff: false,
  status: 'idle',
}

export const fetchUserData = createAsyncThunk('fetchUserData', async () => {
  const { data } = await getUser()
  return data
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    },
    setIsStaff(state, action) {
      state.isStaff = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.user = action.payload
        state.isStaff = action.payload && action.payload.is_revcascade
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { setUser, setIsStaff } = userSlice.actions
export default userSlice.reducer
