import { createSlice } from '@reduxjs/toolkit'
import {
  get30YearsAgo,
  get30YearsFromNow,
  getUnixTimestamp,
} from 'lib/utils/date/formatDate'
import { ORDERS } from 'modules/orders/constants'

const initialState = {
  params: {
    order_by: '-id',
    skip_attributes: 1,
    limit: 10,
    page: 1,
    fields:
      'id,purchase_order_number,brand_identifier,backordered_until,fulfill_by,order_lines,shipment_count,invoice_count,shipping_method,requested_shipping_method,status,memos_count,unread_memos_count,order_batches,received_at,acknowledged_at,platform_account_transactions,tags,platform_account_transactions,retailer,brands',
  },
  connectionOptions: [],
  tagOptions: [],
  statusOptions: ORDERS.STATUS.CHECKBOX_OPTIONS,
  supplierSkuOptions: [],
  retailerSkuOptions: [],
  dueOn: null,
  closedOn: null,
  backorderedUntil: null,
  receivedOn: null,
  selected: [],
  selectedTabPresetIndex: 0,
}
const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else {
        delete state.params['search']
        delete state.params[ORDERS.PURCHASE_ORDER_NUMBER.PARAM]
        delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER.PARAM]
        delete state.params[ORDERS.PURCHASE_ORDER_NUMBER_SW.PARAM]
        delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER_SW.PARAM]
      }
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.selected = []
        state.params = {
          ...initialState.params,
          [action.payload?.filter]: action.payload?.value,
        }
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setTagOptions(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.TAGS.PARAM]
        state.tagOptions = []
      } else {
        state.tagOptions = action.payload
      }
    },
    setStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.statusOptions = ORDERS.STATUS.CHECKBOX_OPTIONS
      } else {
        state.statusOptions = action.payload
      }
    },
    setSupplierSkuOptions(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.SUPPLIER_SKU.PARAM]
      } else {
        state.supplierSkuOptions = action.payload
      }
    },
    setRetailerSkuOptions(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.RETAILER_SKU.PARAM]
      } else {
        state.retailerSkuOptions = action.payload
      }
    },
    setDueOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.DUE_DATE.PARAMS.START]
        delete state.params[ORDERS.DUE_DATE.PARAMS.END]
        delete state.dueOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.DUE_DATE.PARAMS.START] = start
        state.params[ORDERS.DUE_DATE.PARAMS.END] = end
        state.dueOn = action.payload
      }
    },
    setClosedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.START]
        delete state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.END]
        delete state.closedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.START] = start
        state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.END] = end
        state.closedOn = action.payload
      }
    },
    setBackorderedUntil(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.START]
        delete state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.END]
        delete state.backorderedUntil
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.START] = start
        state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.END] = end
        state.backorderedUntil = action.payload
      }
    },
    setReceivedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.ORDER_RECEIVED.PARAMS.START]
        delete state.params[ORDERS.ORDER_RECEIVED.PARAMS.END]
        delete state.receivedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.ORDER_RECEIVED.PARAMS.START] = start
        state.params[ORDERS.ORDER_RECEIVED.PARAMS.END] = end
        state.receivedOn = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    resetAllFilters(state, action) {
      state.connectionOptions = []
      state.statusOptions = ORDERS.STATUS.CHECKBOX_OPTIONS
      state.tagOptions = []
      state.supplierSkuOptions = []
      state.retailerSkuOptions = []
      state.selectedTabPresetIndex = 0

      delete state.params[ORDERS.CONNECTION.PARAM]
      delete state.params[ORDERS.TAGS.PARAM]
      delete state.params[ORDERS.STATUS.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED.PARAM]
      delete state.params[ORDERS.BACKORDERED.PARAM]
      delete state.params[ORDERS.REQUIRES_FULFILLMENT.PARAM]
      delete state.params[ORDERS.REQUIRES_INVOICE.PARAM]
      delete state.params[ORDERS.UNREAD_MESSAGES.PARAM]
      delete state.params[ORDERS.SUPPLIER_SKU.PARAM]
      delete state.params[ORDERS.RETAILER_SKU.PARAM]
      delete state.params[ORDERS.DUE_DATE.PARAMS.START]
      delete state.params[ORDERS.DUE_DATE.PARAMS.END]
      delete state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.START]
      delete state.params[ORDERS.ORDER_CLOSED_DATE.PARAMS.END]
      delete state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.START]
      delete state.params[ORDERS.BACKORDERED_UNTIL.PARAMS.END]
      delete state.params[ORDERS.ORDER_RECEIVED.PARAMS.START]
      delete state.params[ORDERS.ORDER_RECEIVED.PARAMS.END]
      delete state.params[ORDERS.PURCHASE_ORDER_NUMBER.PARAM]
      delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER.PARAM]
      delete state.params[ORDERS.SEARCH.PARAM]
      delete state.params[ORDERS.PURCHASE_ORDER_NUMBER_SW.PARAM]
      delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER_SW.PARAM]

      delete state.dueOn
      delete state.closedOn
      delete state.backorderedUntil
      delete state.receivedOn

      const tabPresets = action.payload?.tabPresets
      if (tabPresets) {
        state.params.page = 1

        let status = ''
        // this is set only via dashboard
        if (tabPresets === ORDERS.TAB_PRESETS.ALL) {
          status = 'open,partial'
        } else if (tabPresets === ORDERS.TAB_PRESETS.CURRENT) {
          status = 'open,partial'

          const dateObject = {
            value: 'customDate',
            dateRange: {
              from: new Date(),
              to: get30YearsFromNow(),
            },
          }

          const start = getUnixTimestamp(dateObject.dateRange.from)
          const end = getUnixTimestamp(dateObject.dateRange.to)

          state.params[ORDERS.DUE_DATE.PARAMS.START] = start
          state.params[ORDERS.DUE_DATE.PARAMS.END] = end
          state.dueOn = dateObject
          state.selectedTabPresetIndex = 1
        } else if (tabPresets === ORDERS.TAB_PRESETS.PAST_SLA) {
          status = 'open,partial'
          state.params[ORDERS.REQUIRES_FULFILLMENT.PARAM] = '1'

          const dateObject = {
            value: 'customDate',
            dateRange: {
              from: get30YearsAgo(),
              to: new Date(),
            },
          }

          const start = getUnixTimestamp(dateObject.dateRange.from)
          const end = getUnixTimestamp(dateObject.dateRange.to)

          state.params[ORDERS.DUE_DATE.PARAMS.START] = start
          state.params[ORDERS.DUE_DATE.PARAMS.END] = end
          state.dueOn = dateObject
          state.selectedTabPresetIndex = 2
        } else if (tabPresets === ORDERS.TAB_PRESETS.REQUIRE_INVOICE) {
          status = 'open,partial'
          state.params[ORDERS.REQUIRES_INVOICE.PARAM] = '1'
          state.selectedTabPresetIndex = 3
        } else if (tabPresets === ORDERS.TAB_PRESETS.COMPLETED) {
          status = 'completed'
          state.selectedTabPresetIndex = 4
        }

        const updatedStatusOptions = ORDERS.STATUS.CHECKBOX_OPTIONS.map(
          (option) => {
            if (option.value === status) {
              return {
                ...option,
                selected: true,
              }
            }
            return option
          }
        )
        state.statusOptions = updatedStatusOptions
        state.params[ORDERS.STATUS.PARAM] = status
      }
    },
    setTabPresetIndex(state, action) {
      state.selectedTabPresetIndex = action.payload.value
    },
    setFields(state, action) {
      state.params.fields = action.payload
    },
    setAdvancedOptions(state, action) {
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
      // set purchase order number starts with
      if (action.payload.purchase_order_number_sw) {
        state.params = {
          ...(state.params as any),
          purchase_order_number_sw: action.payload.purchase_order_number_sw,
        }
      } else delete state.params[ORDERS.PURCHASE_ORDER_NUMBER_SW.PARAM]
      // set purchase order number contains
      if (action.payload.purchase_order_number) {
        state.params = {
          ...(state.params as any),
          purchase_order_number: action.payload.purchase_order_number,
        }
      } else delete state.params[ORDERS.PURCHASE_ORDER_NUMBER.PARAM]
      // set customer order number starts with
      if (action.payload.customer_order_number_sw) {
        state.params = {
          ...(state.params as any),
          customer_order_number_sw: action.payload.customer_order_number_sw,
        }
      } else delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER_SW.PARAM]
      // set customer order number contains
      if (action.payload.customer_order_number) {
        state.params = {
          ...(state.params as any),
          customer_order_number: action.payload.customer_order_number,
        }
      } else delete state.params[ORDERS.CUSTOMER_ORDER_NUMBER.PARAM]
      // set status
      if (action.payload.status) {
        let status = action.payload.status
        const updatedStatusOptions = ORDERS.STATUS.CHECKBOX_OPTIONS.map(
          (option) => {
            if (option.value === status) {
              return {
                ...option,
                selected: true,
              }
            }
            return option
          }
        )
        state.statusOptions = updatedStatusOptions
        state.params[ORDERS.STATUS.PARAM] = status
      } else delete state.params[ORDERS.STATUS.PARAM]
      // set connection
      if (action.payload?.connection_id) {
        state.params = {
          ...(state.params as any),
          connection_id: action.payload.connection_id,
        }
        const selectedConnection = [
          {
            id: action.payload.connection_id,
            label: action.payload.connection_label,
            selected: true,
          },
        ]
        state.connectionOptions = selectedConnection
      } else {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      }
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setConnectionOptions,
  setTagOptions,
  setStatusOptions,
  setSupplierSkuOptions,
  setRetailerSkuOptions,
  setDueOn,
  setBackorderedUntil,
  setClosedOn,
  setReceivedOn,
  setCurrentPage,
  setSelected,
  resetAllFilters,
  setFields,
  setTabPresetIndex,
  setAdvancedOptions,
} = orderSlice.actions

export default orderSlice.reducer
