import { FC, useEffect, useState } from 'react'
import { DataProps, useToast } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { PRODUCTS } from 'modules/products/constants'
import { setParams } from 'store/feedRequestSlice'
import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { getFeedRequests } from 'api/products'
import { Badge, Link } from 'atoms'
import { useNavigate } from 'react-router-dom'
import { getBadgeStatus } from 'modules/products/utils/products'
import BulkActionsBar from 'organisms/bulkActions'

const ImportRequestTable: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()
  const params = useSelector((state: RootState) => state.feedRequest.params)
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)
  const [totalNum, setTotalNum] = useState(0)

  const tableColumns = [
    {
      title: 'ID',
      sortable: true,
      width: '10%',
    },
    {
      title: 'Supplier',
      width: '27%',
    },
    {
      title: 'Template',
      width: '27%',
    },
    {
      title: 'Status',
      sortable: true,
    },
  ]

  const formatTableData = (data: any) => {
    return data.map(({ id, brand, template, status }, key) => {
      return {
        id: key,
        data: {
          ID: {
            onRender: () => (
              <Link
                label={id}
                variant='primary'
                mode='inline'
                onClick={() => navigate(`/products/import-requests/${id}`)}
              />
            ),
          },
          Supplier: { value: brand?.name },
          Template: { value: template?.name },
          Status: {
            onRender: () => (
              <Badge
                label={
                  PRODUCTS.FEED_REQUESTS.STATUS[status.toUpperCase()].LABEL
                }
                status={getBadgeStatus(status)}
              />
            ),
          },
        },
      }
    })
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const {
          data: { results, count },
        } = await getFeedRequests({ params })
        const formattedData = formatTableData(results)
        setTableData(formattedData)
        setTotalNum(count)
        setLoading(false)
      } catch (error) {
        showToast({
          label: "Couldn't load feed requests",
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      }
    })()
  }, [params])

  const updatePage = (page: number) => {
    dispatch(
      setParams({
        order_by: params.order_by,
        page,
        limit: 10,
      })
    )
  }

  const handleSort = (key: string) => {
    const sortName = PRODUCTS.FEED_REQUESTS.ORDER_BY[key.toUpperCase()]
    const order_by = params.order_by.startsWith('-') ? sortName : `-${sortName}`
    dispatch(
      setParams({
        order_by,
        page: 1,
        limit: 10,
      })
    )
  }

  return (
    <>
      <BulkActionsBar
        loading={loading}
        hasBulkActions={false}
        totalRows={totalNum}
        currentPage={params.page}
        dataName='requests'
      />
      <Table
        columns={tableColumns}
        data={tableData}
        loading={loading}
        customPaginationProps={{
          handlePagination: (page: number) => {
            updatePage(page)
          },
          activePageNumber: params.page,
          perPage: 10,
          totalRecords: totalNum,
        }}
        onAscendingSort={(key: string) => handleSort(key)}
        onDescendingSort={(key: string) => handleSort(key)}
      />
    </>
  )
}

export default ImportRequestTable
