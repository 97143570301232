import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { StyledWrapper } from '../../styles'
import PageHeaderComponent from 'molecules/PageHeader'
import Search from 'atoms/search/search'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'
import { GridCol, GridRow, InlineDatePicker } from 'atoms'
import OpenCardWithIcon from 'molecules/cards/openCardWithIcon'
import { resetAllFilters } from 'store/suppliersSlice'
import { SearchVariant } from '@teamfabric/copilot-ui'
import { getRetailerConnections } from 'api/connection'
import { Skeleton } from 'atoms/skeleton'
import {
  getXMonthAgo,
  getXMonthAhead,
  getUnixTimestamp,
} from 'lib/utils/date/formatDate'

const SuppliersDashboardTemplate: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loadingEvents, setLoadingEvents] = useState(true)
  const [searchData, setSearchData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [eventsData, setEventsData] = useState({})
  const [eventDetails, setEventDetails] = useState(null)

  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.SUPPLIERS_DASHBOARD)
    fetchEvents()
  }, [])

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      // Call searchSuppliers after a 2-second delay
      searchSuppliers()
    }, 2000)

    // Cleanup function to clear the timeout in case searchText changes before the 2 seconds
    return () => clearTimeout(delaySearch)
  }, [searchText])

  const handleClick = (key) => {
    dispatch(resetAllFilters({ statusPreset: key }))
  }

  const searchSuppliers = async () => {
    setLoading(true)
    const searchParams = {
      order_by: '-id',
      short: 1,
    }
    const {
      data: { results },
    } = await getRetailerConnections({
      params: { ...searchParams, search: searchText },
    })
    setSearchData(results)
    setLoading(false)
  }

  const fetchEvents = async () => {
    setLoadingEvents(true)
    const queryStartTime = getUnixTimestamp(getXMonthAgo(3))
    const queryEndTime = getUnixTimestamp(getXMonthAhead(3))
    const params = {
      pagination: 0,
      status: 'onboarding',
      onboarding_target_date_gte: queryStartTime,
      onboarding_target_date_lte: queryEndTime,
      intro_call_date_gte: queryStartTime,
      intro_call_date_lte: queryEndTime,
    }
    const {
      data: { results },
    } = await getRetailerConnections({
      params: { ...params },
    })
    let events = {}
    let eventsData = []
    let count = 1
    if (results && results.length > 0) {
      results.forEach((el) => {
        if (el.onboarding_target_date) {
          let targetDate = new Date(el.onboarding_target_date).getDate()
          if (!events[targetDate]) {
            events[targetDate] = [] // Initialize the array if it doesn't exist for the date
          }
          events[targetDate].push(count) // Add the event type to the array
          eventsData.push({
            id: count,
            label: 'Supplier Launch',
            eventName: 'Supplier Launch',
            secondaryText: el.brand.name,
          })
          count++
        }
        if (el.intro_call_date) {
          const introDate = new Date(el.intro_call_date).getDate()
          if (!events[introDate]) {
            events[introDate] = [] // Initialize the array if it doesn't exist for the date
          }
          events[introDate].push(count) // Add the event type to the array
          eventsData.push({
            id: count,
            label: 'Supplier Activation & Demo call',
            eventName: 'Supplier Activation & Demo call',
            secondaryText: el.brand.name,
          })
          count++
        }
      })
    }
    setEventsData(events)
    setEventDetails(eventsData)
    setLoadingEvents(false)
  }

  const getResults = () => {
    if (loading) {
      return (
        <div className='p-3 width-100'>
          <Skeleton height='20px' width='100%' />
        </div>
      )
    } else if (!loading && searchData.length > 0) {
      return searchData.map((supplier: any) => {
        return (
          <Link key={supplier.id} to={`/${supplier.id}/`}>
            <div className='searchResultListItem'>
              <div className='heading'>{supplier.brand?.name}</div>
              <div className='subHeading'>{supplier.status}</div>
            </div>
          </Link>
        )
      })
    } else {
      return <div className='label p-3'> No results found </div>
    }
  }

  return (
    <StyledWrapper>
      <PageHeaderComponent
        loading={false}
        h1Text='Suppliers'
        primaryButtons={[
          {
            onClick: () => navigate('/invite'),
            text: 'Invite Supplier',
            variant: 'primary',
          },
        ]}
      />
      <div className='mb-4'>
        <Search
          placeholder={'Search by supplier name'}
          variant={SearchVariant.FIXED_GLOBAL}
          width='433px'
          onChange={(searchText: string) => {
            setSearchText(searchText)
          }}
          onSearch={(searchText: string) => {
            setSearchText(searchText)
          }}
          results={getResults()}
        />
      </div>
      <GridRow padding={false}>
        <GridCol lg={8} md={8} sm={8}>
          <h5 className='h5 mb-4'>Browse Suppliers</h5>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <OpenCardWithIcon
                loading={false}
                title='Active suppliers'
                iconName='StatusCheck'
                path='/list'
                handleClick={() => handleClick('active')}
              />
            </GridCol>
            <GridCol lg={6} md={6} sm={6}>
              <OpenCardWithIcon
                loading={false}
                title='Onboarding'
                iconName='Activate'
                path='/list'
                handleClick={() => handleClick('onboarding')}
              />
            </GridCol>
            <GridCol lg={6} md={6} sm={6}>
              <OpenCardWithIcon
                loading={false}
                title='Suspended suppliers'
                iconName='Error'
                path='/list'
                handleClick={() => handleClick('suspended')}
              />
            </GridCol>
            <GridCol lg={6} md={6} sm={6}>
              <OpenCardWithIcon
                loading={false}
                title='View all suppliers'
                iconName='Preview'
                path='/list'
                handleClick={() => handleClick('')}
              />
            </GridCol>
          </GridRow>
          <h5 className='h5 mb-4'>New Suppliers</h5>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <OpenCardWithIcon
                loading={false}
                title='Invitations'
                iconName='File'
                path='/invitations'
              />
            </GridCol>
          </GridRow>
        </GridCol>
        <GridCol lg={4} md={4} sm={4}>
          <h5 className='h5 mb-4'>Supplier Calendar</h5>
          <InlineDatePicker
            label=''
            onSelect={(date: Date) => console.log('===date', date)}
            minDate={getXMonthAgo(3)}
            maxDate={getXMonthAhead(3)}
            {...(!loadingEvents && eventDetails !== null
              ? { events: eventsData, eventsLegend: eventDetails }
              : {})}
          />
        </GridCol>
      </GridRow>
    </StyledWrapper>
  )
}

export default SuppliersDashboardTemplate
