export const ORDERS = {
  TAB_PRESETS: {
    ALL: 'All',
    CURRENT: 'Current',
    PAST_SLA: 'Past Fulfillment SLA',
    REQUIRE_INVOICE: 'Require Invoice',
    COMPLETED: 'Completed',
  },
  CONNECTION: {
    NAME: 'Connection',
    PLACEHOLDER: 'type connection',
    PARAM: 'connection_id',
  },
  STATUS: {
    NAME: 'Status',
    PLACEHOLDER: '',
    PARAM: 'status',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'status',
        label: 'Open',
        value: 'open,partial',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'status',
        label: 'Completed',
        value: 'completed',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'status',
        label: 'Canceled',
        value: 'canceled',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'status',
        label: 'Closed',
        value: 'closed',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  TAGS: {
    NAME: 'Tags',
    PARAM: 'tags',
  },
  PURCHASE_ORDER_NUMBER: {
    NAME: 'Purchase Order Number',
    PARAM: 'purchase_order_number',
  },
  PURCHASE_ORDER_NUMBER_SW: {
    NAME: 'Purchase Order Number SW',
    PARAM: 'purchase_order_number_sw',
  },
  CUSTOMER_ORDER_NUMBER: {
    NAME: 'Customer Order Number',
    PARAM: 'customer_order_number',
  },
  CUSTOMER_ORDER_NUMBER_SW: {
    NAME: 'Customer Order Number SW',
    PARAM: 'customer_order_number_sw',
  },
  SEARCH: {
    NAME: 'Search',
    PARAM: 'search',
  },
  ACKNOWLEDGED: {
    NAME: 'Acknowledged',
    FILTER_MODAL_NAME: 'Order Acknowledged?',
    PLACEHOLDER: '',
    PARAM: 'is_acknowledged',
    VALUES: {
      YES: {
        NAME: 'Yes',
        VALUE: '1',
      },
      NO: {
        NAME: 'No',
        VALUE: '0',
      },
    },
  },
  BACKORDERED: {
    NAME: 'Backordered',
    PLACEHOLDER: '',
    PARAM: 'is_backordered',
  },
  BACKORDERED_ACKNOWLEDGED: {
    NAME: 'Backorder Acknowledged',
    PLACEHOLDER: '',
    PARAM: 'backorder_is_acknowledged',
  },
  REQUIRES_FULFILLMENT: {
    NAME: 'Requires Fulfilment',
    PLACEHOLDER: '',
    PARAM: 'not_fully_shipped',
  },
  REQUIRES_INVOICE: {
    NAME: 'Requires Invoice',
    PLACEHOLDER: '',
    PARAM: 'not_fully_invoiced',
  },
  UNREAD_MESSAGES: {
    NAME: 'Has Unread Messages',
    PLACEHOLDER: '',
    PARAM: 'has_unread_memos',
  },
  SUPPLIER_SKU: {
    NAME: 'Supplier SKU',
    PLACEHOLDER: 'type supplier SKU',
    PARAM: 'variant_identifier',
  },
  RETAILER_SKU: {
    NAME: 'Retailer SKU',
    PLACEHOLDER: 'type retailer SKU',
    PARAM: 'retailer_identifier',
  },
  DUE_DATE: {
    NAME: 'Due Date',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'fulfill_by_gte',
      END: 'fulfill_by_lte',
    },
  },
  ORDER_CLOSED_DATE: {
    NAME: 'Order Closed',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'closed_at_gte',
      END: 'closed_at_lte',
    },
  },
  BACKORDERED_UNTIL: {
    NAME: 'Backordered Until',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'backordered_until_gte',
      END: 'backordered_until_lte',
    },
  },
  ORDER_RECEIVED: {
    NAME: 'Order Received',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'created_at_gte',
      END: 'created_at_lte',
    },
  },
  SHIPPING_CREATED_ON: {
    NAME: 'Created On',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'shipped_at_gte',
      END: 'shipped_at_lte',
    },
  },
  RETURN_CREATED_ON: {
    NAME: 'Created On',
    PLACEHOLDER: '',
    PARAMS: {
      START: 'created_at_gte',
      END: 'created_at_lte',
    },
  },
  TRACKING_STATUS: {
    NAME: 'Status',
    PARAM: 'status',
    VALUES: {
      CLOSED: {
        NAME: 'Not Finalized',
        VALUE: 'closed',
      },
      OPEN: {
        NAME: 'Open',
        VALUE: 'open',
      },
    },
  },
  RETURN_REASONS_SUPPLIER: {
    NAME: 'Reason',
    PARAM: 'reason_id',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'reason_id',
        label: 'Customer Disposition',
        value: '1',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'reason_id',
        label: 'Item did not meet customer expectations',
        value: '2',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'reason_id',
        label: 'Item was damaged or defective',
        value: '3',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'reason_id',
        label: 'Delivery Problem (e.g. customer refused or undeliverable)',
        value: '4',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  RETURN_REASONS_MERCHANT: {
    NAME: 'Return Reason',
    PARAM: 'reason_id',
    CHECKBOX_OPTIONS_SUPPLIER: [
      {
        id: '0',
        key: 'reason_id',
        label: 'Customer Disposition',
        value: '5',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'reason_id',
        label: 'Item did not meet customer expectations',
        value: '6',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'reason_id',
        label: 'Item was damaged or defective',
        value: '7',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'reason_id',
        label: 'Delivery Problem (e.g. customer refused or undeliverable)',
        value: '8',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  RETURN_STATUS: {
    NAME: 'Status',
    PARAM: 'status',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'status',
        label: 'Approved',
        value: 'approved',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'status',
        label: 'Pending',
        value: 'pending',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'status',
        label: 'Declined',
        value: 'declined',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  CANCELLATION_REASONS: {
    NAME: 'Reason',
    PARAM: 'reason_id',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'reason_id',
        label: 'Retailer Requested Cancellation',
        value: '1',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'reason_id',
        label: 'Not Enough Inventory',
        value: '2',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '2',
        key: 'reason_id',
        label: "Can't Meet Fulfillment Deadline",
        value: '3',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '3',
        key: 'reason_id',
        label: 'Product Listing and/or Pricing Error',
        value: '4',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '4',
        key: 'reason_id',
        label: 'Unfulfillable Address',
        value: '5',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '5',
        key: 'reason_id',
        label: 'Suspected Fraud',
        value: '6',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '6',
        key: 'reason_id',
        label: 'Price Discrepancy',
        value: '8',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '7',
        key: 'reason_id',
        label: 'Product Discontinued',
        value: '9',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  CANCELLED_BY: {
    NAME: 'Canceled By',
    PARAM: 'canceled_by',
    CHECKBOX_OPTIONS: [
      {
        id: '0',
        key: 'canceled_by',
        label: 'Supplier',
        value: 'brand',
        selected: false,
        expanded: false,
        children: [],
      },
      {
        id: '1',
        key: 'canceled_by',
        label: 'Retailer',
        value: 'retailer',
        selected: false,
        expanded: false,
        children: [],
      },
    ],
  },
  INVOICE_SUBMITTED: {
    NAME: 'Submitted',
    PARAMS: {
      START: 'created_at_gte',
      END: 'created_at_lte',
    },
  },
  INVOICE_PAID: {
    NAME: 'Marked as Paid',
    PARAM: 'is_paid',
  },
  INVOICE_DUE: {
    NAME: 'Invoice Due',
    PARAMS: {
      START: 'terms_date_due_gte',
      END: 'terms_date_due_lte',
    },
  },
  ACKNOWLEDGED_ON: {
    NAME: 'Acknowledged On',
    PARAMS: {
      START: 'acknowledged_at_gte',
      END: 'acknowledged_at_lte',
    },
  },
  INVOICE_PAID_ON: {
    NAME: 'Marked as Paid Date',
    PARAMS: {
      START: 'paid_at_gte',
      END: 'paid_at_lte',
    },
  },
  CANCEL_REGISTERED: {
    NAME: 'Registered',
    PARAMS: {
      START: 'created_at_gte',
      END: 'created_at_lte',
    },
  },
  SORTING_MAPS: {
    ORDERS: {
      'Order ID': 'id',
      'Ordered': 'ordered_at',
    },
    INVOICES: {
      Submitted: 'ordered_at',
      Acknowledged: 'acknowledged_at',
      Subtotal: 'subtotal',
      Total: 'due_amount',
    },
    CANCELLATIONS: {
      ID: 'id',
      Acknowledged: 'acknowledged_at',
    },
  },
  FILTERS: {
    ACKNOWLEDGED: 'is_acknowledged',
    DATE_OPTIONS: {
      TODAY: 'today',
      LAST_7: 'last7day',
      LAST_30: 'lastMonth',
      CUSTOM: 'customDate',
    },
    STATUS_OPTIONS: [
      {
        id: 'current',
        label: 'Current',
      },
      {
        id: 'late',
        label: 'Late',
      },
      {
        id: 'criticallyLate',
        label: 'Critically Late',
      },
    ],
  },
  FILTER_MODAL: {
    ITEM_TYPES: {
      CHECKBOX: 'Checkbox',
      DATE: 'Date',
      RADIO: 'Radio',
    },
    STATUS_CHECKBOX_OPTIONS: [
      {
        expanded: false,
        hasChildren: false,
        id: '0',
        label: 'Current',
      },
      {
        expanded: false,
        hasChildren: false,
        id: '1',
        label: 'Late',
      },
      {
        expanded: false,
        hasChildren: false,
        id: '2',
        label: 'Critically late',
      },
    ],
  },
}
