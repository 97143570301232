import React from 'react'
import {
  formatDate,
  getHoursAndMinutesFromDate,
} from 'lib/utils/date/formatDate'

type ChildProps = {
  date: any
}

const DateAndTimeOnTwoLines: React.FC<ChildProps> = ({ date }) => {
  return (
    <div className='flex-column-start'>
      <span>{formatDate({ date: date })}</span>
      <span className='grey-400'>
        {getHoursAndMinutesFromDate({ date: date })}
      </span>
    </div>
  )
}

export default DateAndTimeOnTwoLines
