import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import FilterChips from 'organisms/filterChips'
import {
  setFilters,
  setContentTypeOptions,
  setUpdatedAt,
  resetAllFilters,
} from 'store/productRulesetsSlice'
import {
  handleCheckboxFilterChange,
  handleDateFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { PRODUCT_RULESETS } from '../constants'
import DateFilter from 'molecules/filter/singleFilter/genericDate'

type ChildProps = {
  params: any
}

const RulesetsFilter: React.FC<ChildProps> = ({ params }) => {
  const [contentTypeCounter, setContentTypeCounter] = useState(0)
  const [updatedAtCounter, setUpdatedAtCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const contentTypeOptions = useSelector(
    (state: RootState) => state.productRulesets.contentTypeOptions
  )

  const updatedAt = useSelector(
    (state: RootState) => state.productRulesets.updatedAt
  )

  const isAFilterApplied = useMemo(() => {
    return contentTypeCounter + updatedAtCounter > 0
  }, [contentTypeCounter, updatedAtCounter])

  // Define respective filterKey fns
  const filterActions = {
    content_type: {
      action: setContentTypeOptions,
      options: contentTypeOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setContentTypeCounter,
      chipName: PRODUCT_RULESETS.CONTENT_TYPE.NAME,
    },
    updated_at_gte: {
      setFilterCount: setUpdatedAtCounter,
      dateRange: updatedAt,
      action: setUpdatedAt,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCT_RULESETS.UPDATED_AT.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${PRODUCT_RULESETS.CONTENT_TYPE.NAME}-filter`}
          label={PRODUCT_RULESETS.CONTENT_TYPE.NAME}
          data={contentTypeOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PRODUCT_RULESETS.CONTENT_TYPE.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={contentTypeCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: PRODUCT_RULESETS.CONTENT_TYPE.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${PRODUCT_RULESETS.UPDATED_AT.NAME}-filter`}
          label={PRODUCT_RULESETS.UPDATED_AT.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCT_RULESETS.UPDATED_AT.PARAM_START,
              date,
            })
          }}
          defaultValue={updatedAt}
          filterCount={updatedAtCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCT_RULESETS.UPDATED_AT.PARAM_START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default RulesetsFilter
