import Button from 'atoms/button/button'

type ReturnGetColProps = {
  onClick: () => void
}

const ReturnGetCol: React.FC<ReturnGetColProps> = ({ onClick }) => {
  return <Button variant='secondary' text='Get' onClick={() => onClick()} />
}

export default ReturnGetCol
