import { InteractiveIcon as InteractiveIconUI } from '@teamfabric/copilot-ui'
import React from 'react'

type InteractiveIconProps = {
  iconName: string
  color?: string
  size?: number
  variant?: any
  disabled?: boolean
  onClick?: any
}

const InteractiveIcon: React.FC<InteractiveIconProps> = ({ ...props }) => {
  return <InteractiveIconUI {...props} />
}

export default InteractiveIcon
