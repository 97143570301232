import React, { useState } from 'react'
import Search from 'atoms/search/search'
import { SearchVariant } from '@teamfabric/copilot-ui'
import { debounce } from 'lodash'

type ChildProps = {
  placeholder?: string
  params: any
  fetchDataCall: (params: any) => any
  variant?: SearchVariant
  showResult?: boolean
  resultsFormatter: (results: any) => any
  width?: string
  dataTestId?: string
}

const SearchBarServerSide: React.FC<ChildProps> = (props) => {
  const [results, setResults] = useState([])
  const [loadingResults, setLoadingResults] = useState(false)

  const search = async (value) => {
    if (value) {
      setLoadingResults(true)
      const { data: data } = await props.fetchDataCall({
        params: { search: value, ...props.params },
      })
      setResults(data?.results)
      setLoadingResults(false)
    }
  }

  return (
    <Search
      placeholder={props.placeholder}
      onSearch={(searchText) => search(searchText)}
      onClear={() => setResults([])}
      variant={props.variant}
      onChange={debounce((searchText) => search(searchText), 1000)}
      showResult={true}
      results={!loadingResults && props.resultsFormatter(results)}
      width={props.width}
      showResultOnFocus
      dataTestid={props.dataTestId}
    />
  )
}

export default SearchBarServerSide
