export const FILTERS = {
  RADIO_GROUP: {
    YES_NO_OPTIONS: [
      {
        'id': '1',
        'label': 'No',
        'value': '0',
        'data-testid': 'label-yes',
        'disabled': false,
      },
      {
        'id': '2',
        'label': 'Yes',
        'value': '1',
        'data-testid': 'label-no',
        'disabled': false,
      },
    ],
  },
  TEXT: {
    RESET_ALL: 'Reset all',
  },
  TYPES: {
    CHECKBOX_WITH_SEARCH: 'checkbox_with_search',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    DATE: 'date',
  },
}
