import React, { useEffect, useState } from 'react'
import { StyledWrapper } from '../../styles'
import PageHeaderComponent from 'molecules/PageHeader'
import BasicDetailsCard from '../components/invite/basicDetails'
import AdvancedSettingsCard from '../components/invite/advancedSettings'
import { useToast } from '@teamfabric/copilot-ui'
import { postConnectionInvite } from 'api/suppliers'
import { useNavigate } from 'react-router-dom'
import {
  breadcrumbs,
  SupplierPageTypes,
} from 'lib/constants/breadcrumbsHandler'

const InviteSupplier: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [supplierName, setSupplierName] = useState<any>()
  const [supplierNumber, setSupplierNumber] = useState<any>()
  const [targetDate, setTargetDate] = useState<any>(new Date())
  const [firstName, setFirstName] = useState<any>()
  const [lastName, setLastName] = useState<any>()
  const [emailAddress, setEmailAddress] = useState<any>()
  const [messageVisibility, setMessageVisibility] = useState<any>()
  const [connectionNote, setConnectionNote] = useState<any>()
  const [connectionLevel, setConnectionLevel] = useState<any>()
  const [fulfillmentSla, setFulfillmentSla] = useState<any>()
  const [paymentTerms, setPaymentTerms] = useState<any>()
  const [netPriceMethod, setNetPriceMethod] = useState<any>()
  const [commissionRate, setCommissionRate] = useState<any>()
  const [commissionProfile, setCommissionProfile] = useState<any>()
  const [returnsAllowance, setReturnsAllowance] = useState<any>()
  const [catalogImportMethod, setCatalogImportMethod] = useState<any>()

  const showToast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.supplierPages({ type: SupplierPageTypes.Invite })
        ),
      0
    )
  }, [])

  useEffect(() => {
    if (supplierName && firstName && lastName && emailAddress) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [supplierName, firstName, lastName, emailAddress])

  interface ConnectionInviteJsonType {
    company_name: any
    first_name: any
    last_name: any
    email: any
    memos: any[]
    slas: { [key: string]: any }
    adjustments: any[]
    options: { [key: string]: any }
    retailer_identifier?: any
    onboarding_target_date?: string
    level?: string
    fees?: any[]
    commission_profile?: any
  }

  const createConnectionInviteJson = () => {
    const json: ConnectionInviteJsonType = {
      company_name: supplierName,
      first_name: firstName,
      last_name: lastName,
      email: emailAddress,
      memos: [],
      slas: {},
      adjustments: [],
      options: {},
    }

    if (supplierNumber) {
      json.retailer_identifier = supplierNumber
    }
    if (targetDate) {
      json.onboarding_target_date = targetDate.toISOString()
    }

    json.level =
      connectionLevel.id === 'products_only'
        ? 'products_only'
        : 'transactions_and_products'

    if (fulfillmentSla) {
      json.slas.brand_fulfillment_hours = fulfillmentSla * 24
    }

    if (connectionNote && messageVisibility) {
      json.memos = [
        {
          text: connectionNote,
          visibility: messageVisibility.id,
        },
      ]
    }

    json.fees = [
      {
        context: 'retailer',
        net_price_method: 'specified_in_order',
        payment_terms: paymentTerms ? paymentTerms.id : 'net30',
      },
      {
        context: 'brand',
        net_price_method: 'specified_in_order',
        payment_terms: paymentTerms ? paymentTerms.id : 'net30',
      },
    ]

    if (netPriceMethod.id === 'permit_cost') {
      json.fees[0].net_price_method = 'permit_cost'
      json.fees[1].net_price_method = 'permit_cost'
    }

    if (netPriceMethod.id === 'revshare' && commissionRate) {
      const commission = parseFloat(commissionRate) / 100
      if (commission > 0 && commission < 1) {
        json.fees[0].net_price_method = 'revshare'
        json.fees[0].revshare_fraction = commission
        json.fees[1].net_price_method = 'revshare'
        json.fees[1].revshare_fraction = 1 - commission
      }
    }

    if (netPriceMethod.id === 'commission_profile' && commissionProfile) {
      json.fees[0].net_price_method = null
      json.fees[1].net_price_method = null
      json.commission_profile = {
        id: commissionProfile.id,
      }
    }

    if (returnsAllowance) {
      const allowance = parseFloat(returnsAllowance)
      if (allowance > 0 && allowance < 10) {
        json.adjustments.push({
          context: 'retailer',
          description: 'Returns Allowance',
          adjustment_type: 'allowance',
          adjustment_amount_type: 'percent',
          adjustment_amount: allowance,
          adjustment_code: 'returns_allowance',
        })
      }
    }

    if (catalogImportMethod) {
      if (catalogImportMethod.id === 'proposals') {
        json.options.merchandise_via_proposals = true
      } else if (catalogImportMethod.id === 'import_request') {
        json.options.merchandise_via_import_request = true
      }
    }

    return json
  }

  const sendInvitation = async () => {
    setLoading(true)
    try {
      const body = createConnectionInviteJson()
      await postConnectionInvite(body)
    } catch (error) {
      showToast({
        label: 'Error Sending Invite: ' + error,
        isDismissable: true,
        id: 'error-sending-invite',
        variant: 'error',
      })
    } finally {
      setLoading(false)
      navigate('/invitations')
    }
  }

  return (
    <StyledWrapper>
      <PageHeaderComponent
        loading={false}
        h1Text='Invite Supplier'
        primaryButtons={[
          {
            onClick: () => {
              navigate('/')
            },
            text: 'Cancel',
            variant: 'secondary',
          },
          {
            onClick: () => {
              sendInvitation()
            },
            text: 'Send Invitation',
            variant: 'primary',
            isDisabled: disabled,
          },
        ]}
      />
      <br />
      <BasicDetailsCard
        setSupplierName={setSupplierName}
        setSupplierNumber={setSupplierNumber}
        targetDate={targetDate}
        setTargetDate={setTargetDate}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmailAddress={setEmailAddress}
        messageVisibility={messageVisibility}
        setMessageVisibility={setMessageVisibility}
        setConnectionNote={setConnectionNote}
      />
      <br />
      <AdvancedSettingsCard
        loading={loading}
        setLoading={setLoading}
        connectionLevel={connectionLevel}
        setConnectionLevel={setConnectionLevel}
        setFulfillmentSla={setFulfillmentSla}
        fulfillmentSla={fulfillmentSla}
        paymentTerms={paymentTerms}
        setPaymentTerms={setPaymentTerms}
        netPriceMethod={netPriceMethod}
        setNetPriceMethod={setNetPriceMethod}
        commissionRate={commissionRate}
        setCommissionRate={setCommissionRate}
        commissionProfile={commissionProfile}
        setCommissionProfile={setCommissionProfile}
        returnsAllowance={undefined}
        setReturnsAllowance={setReturnsAllowance}
        catalogImportMethod={catalogImportMethod}
        setCatalogImportMethod={setCatalogImportMethod}
      />
    </StyledWrapper>
  )
}

export default InviteSupplier
