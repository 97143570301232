import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { acknowledgeProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const AddToProductListModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)

  const showToast = useToast()

  const completeProposal = async () => {
    try {
      setLoading(true)
      await acknowledgeProposal({
        id: props.proposal?.id,
      })
      showToast({
        label: `Your proposal has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error updating the proposal: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Add to Product List'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Close',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => completeProposal(),
            text: 'Add To Products',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <div className='body1-regular grey-600'>
            Are you sure you want to add this proposal’s products to your
            Marketplace Product List?
          </div>
        </>
      </Modal>
    </>
  )
}

export default AddToProductListModal
