/* 
  calling this genericDate for now until we establish if we can toss the other custom-made date filter
*/

import React, { useMemo } from 'react'
import Filter from 'atoms/filter/filter'
import FilterContentDate from 'atoms/filter/innerContent/date'
import Box from 'atoms/box/box'
import FilterFooter from '../filterFooter'

type FilterProps = {
  dataTestid: string
  label: string
  defaultValue?: any
  onChange: (date: any) => void
  wrapperClass?: string
  filterCount?: number
  disableLink?: boolean
  onReset?: () => void
}

const DateFilter: React.FC<FilterProps> = ({ ...props }) => {
  // parse timstamps if default value read from params
  const defaultProposedOnValue = useMemo(() => {
    let parsedDefaultValue = {
      value: null,
      dateRange: {
        from: null,
        to: null,
      },
    }
    parsedDefaultValue.value = props?.defaultValue?.value
    parsedDefaultValue.dateRange.from = new Date(
      props?.defaultValue?.dateRange?.from
    )
    parsedDefaultValue.dateRange.to = new Date(
      props?.defaultValue?.dateRange?.to
    )
    return parsedDefaultValue
  }, [props?.defaultValue])

  return (
    <div className={props.wrapperClass}>
      <Filter
        label={props.label}
        dataTestid={props.dataTestid}
        filterCount={props.filterCount ? props.filterCount.toString() : ''}
        renderResults={
          <>
            <Box padding={[3]}>
              <FilterContentDate
                onChange={props.onChange}
                name={props.dataTestid}
                defaultValue={defaultProposedOnValue}
              />
            </Box>
            {props.filterCount ? (
              <Box padding={[3]}>
                <FilterFooter
                  linkText={'Reset'}
                  disableLink={props.disableLink}
                  onLinkClick={() => props.onReset()}
                />
              </Box>
            ) : (
              ''
            )}
          </>
        }
      />
    </div>
  )
}

export default DateFilter
