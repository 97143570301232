const formatOptionsData = ({ data, label }) => {
  return data.map((obj: any) => ({
    disabled: false,
    id: obj.id,
    isError: false,
    label: label.length === 1 ? obj[label[0]] : obj[label[0]][label[1]],
  }))
}

export { formatOptionsData }
