import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import ProposalItemFilters from '../filters/items'
import SearchBar from 'molecules/search/searchBarTable'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getProposalVariants } from 'api/proposals'
import { setSearch } from 'store/proposalItemsSlice'
import ProposalItemsTable from '../table/items'
import { useParams } from 'react-router'
import { useManageSideDrawer } from 'modules/proposals/utils/drawer'
import ProposalProductDrawer from '../drawer'
import { Box } from 'atoms'

type ProposalItemsProps = {
  proposal: any
  isRetailer: boolean
  loadProposal: (quiet?: boolean) => void
}

const ProposalItems: React.FC<ProposalItemsProps> = ({
  proposal,
  isRetailer,
  loadProposal,
}) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  const params = useSelector((state: RootState) => state.proposalItems.params)

  const { id } = useParams()

  useEffect(() => {
    if (proposal?.id && params) {
      ;(async () => {
        await fetchProposalVariants()
      })()
    }
  }, [proposal, params])

  const fetchProposalVariants = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data: data } = await getProposalVariants({
      id: id,
      params: { ...params },
    })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  const { selectedLogForSideDrawer, handleOpenDrawer, handleCloseDrawer } =
    useManageSideDrawer({ eventLogsData: tableData })

  return (
    <Box
      width='100%'
      padding={{ top: 5, bottom: 4 }}
      dataTestid='proposal-items-content'
    >
      <SearchBar
        placeholder='Search by Item name or Supplier SKU'
        setSearch={setSearch}
      />
      <ProposalItemFilters params={params} />
      <div className='divider-4' />
      <ProposalItemsTable
        data={tableData}
        loading={loading}
        totalRows={totalRows}
        currentPage={params.page}
        currentSortBy={params.order_by}
        proposal={proposal}
        isRetailer={isRetailer}
        params={params}
        handleOpenDrawer={(id) => handleOpenDrawer(id)}
      />
      {selectedLogForSideDrawer && (
        <ProposalProductDrawer
          selectedLogData={selectedLogForSideDrawer}
          handleCloseDrawer={handleCloseDrawer}
          type='variant'
          loadProposal={loadProposal}
        />
      )}
    </Box>
  )
}

export default ProposalItems
