import React, { useMemo, useState } from 'react'
import Box from 'atoms/box/box'
import RemoveProposalProductModal from 'modules/proposals/components/modals/removeProposalProduct'
import AddToOpenIssueModal from 'modules/proposals/components/modals/addToOpenIssue'
import CreateIssueModal from 'modules/proposals/components/modals/createIssue'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getTenantType } from 'api/helper'
import {
  RenderAddToOpenIssueWithTooltip,
  RenderApproveButton,
  RenderCreateIssue,
  RenderRemoveButtonWithTooltip,
} from './commonActions'
import { approveProposalProducts } from 'api/proposals'
import { useToastUtils } from 'lib/utils/toast'

type BulkActionsProposalProductsProps = {
  loadProducts?: (quiet?: boolean) => void
  loadProposal?: (quiet?: boolean) => void
}

const BulkActionsProposalProducts: React.FC<
  BulkActionsProposalProductsProps
> = ({ loadProducts, loadProposal }) => {
  const [showRemoveProductsModal, setShowRemoveProductsModal] = useState(false)
  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false)
  const [showAddToOpenIssue, setShowAddToOpenIssue] = useState(false)

  const { isRetailer } = getTenantType()
  const { showSuccessToast } = useToastUtils()

  const selected = useSelector(
    (state: RootState) => state.proposalProducts.selected
  )

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const canAddToOpenIssue = useMemo(() => {
    if (proposalDetails?.issues_summary.open > 0) return true
    return false
  }, [proposalDetails?.issueSummary])

  const canRemoveItems = useMemo(() => {
    if (!isRetailer) {
      const allowed = ['draft', 'revised']
      if (allowed.includes(proposalDetails?.status)) {
        return true
      }
    } else {
      const allowed = ['proposed']
      if (allowed.includes(proposalDetails?.status)) {
        return true
      }
    }
    return false
  }, [proposalDetails?.status])

  const onApproveProducts = async () => {
    const json = []
    selected.forEach((productId) => {
      json.push({ product: { id: productId } })
    })
    const { data } = await approveProposalProducts({
      id: proposalDetails.id,
      body: json,
    })
    if (data.total_success > 0) {
      showSuccessToast('Products, has been approved successfully.')
      loadProducts?.()
    } else {
      showSuccessToast(
        'Products has not been approved, please verify status and try again.'
      )
    }
  }

  return (
    <>
      {/* bulk delete products */}
      <RemoveProposalProductModal
        showModal={showRemoveProductsModal}
        setShowModal={setShowRemoveProductsModal}
        loadProducts={loadProducts}
      />
      {/* Add to open issue modal */}
      <AddToOpenIssueModal
        count={selected?.length}
        isProduct
        showModal={showAddToOpenIssue}
        setShowModal={setShowAddToOpenIssue}
        loadProposal={loadProposal}
      />
      {/* Create new issue modal */}
      <CreateIssueModal
        count={selected?.length}
        isProduct
        showModal={showCreateIssueModal}
        setShowModal={setShowCreateIssueModal}
        loadProposal={loadProposal}
      />
      <Box flex={{ alignItems: 'center' }}>
        <RenderAddToOpenIssueWithTooltip
          canAddToOpenIssue={canAddToOpenIssue}
          setShowAddToOpenIssue={() => setShowAddToOpenIssue(true)}
        />
        <RenderCreateIssue
          setShowCreateIssueModal={() => setShowCreateIssueModal(true)}
        />
        <RenderRemoveButtonWithTooltip
          canRemoveItems
          setShowRemoveModal={() => setShowRemoveProductsModal(true)}
        />
        <RenderApproveButton onApprove={onApproveProducts} />
      </Box>
    </>
  )
}

export default BulkActionsProposalProducts
