import { FC, useEffect, useMemo, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { Banner, Box, Dropdown, Input } from 'atoms'
import { getDepartmentById, patchDepartment } from 'api/departments'
import { useNavigate, useParams } from 'react-router-dom'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectTemplates from 'molecules/selectDropdowns/selectTemplates'
import SelectRuleset from 'molecules/selectDropdowns/selectRuleset'
import SelectUser from 'molecules/selectDropdowns/selectUser'
import { useToast } from '@teamfabric/copilot-ui'
import { getProposals } from 'api/proposals'
import { tenantDetails } from 'api/helper'

const StatusOptions = [
  {
    id: '0',
    label: 'Inactive',
  },
  {
    id: '1',
    label: 'Active',
  },
]
const ProposalDepartmentDetails: FC = () => {
  const { id } = useParams()
  const { id: tenantId } = tenantDetails()
  const navigate = useNavigate()
  const showToast = useToast()

  const [initialData, setInitialData] = useState<any>({})
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [departmentName, setDepartmentName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(dropDownInitialObj)
  const [templateURL, setTemplateURL] = useState('')
  const [selectedSupplierRuleset, setSelectedSupplierRuleset] =
    useState(dropDownInitialObj)
  const [selectedMerchantRuleset, setSelectedMerchantRuleset] =
    useState(dropDownInitialObj)
  const [selectedMerchandiserUser, setSelectedMerchandiserUser] =
    useState(dropDownInitialObj)
  const [selectedManagerUser, setSelectedManagerUser] =
    useState(dropDownInitialObj)
  const [status, setStatus] = useState(dropDownInitialObj)
  const [showProposalExistsBanner, setShowProposalExistsBanner] =
    useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () => window.updateBreadcrumb(breadcrumbs.proposalDepartmentDetails()),
      0
    )
  }, [])

  useEffect(() => {
    ;(async () => {
      const { data } = await getDepartmentById({ departmentId: id })
      setInitialData(data)
      formatData({ data })
      setLoading(false)
    })()
  }, [])

  const formatData = ({ data }) => {
    setDepartmentName(data?.name)
    setSelectedTemplate({
      id: data?.template?.id,
      label: data?.template?.name,
    })
    setSelectedSupplierRuleset({
      id: data?.brand_ruleset?.id,
      label: '',
    })
    setSelectedMerchantRuleset({
      id: data?.retailer_ruleset?.id,
      label: '',
    })
    setSelectedMerchandiserUser({
      id: data?.owner_user?.id,
      label: `${data?.owner_user?.first_name} ${data?.owner_user?.last_name}`,
    })
    setTemplateURL(data?.sample_template_url)
    setSelectedManagerUser({
      id: data?.manager_user?.id,
      label: data?.manager_user?.name,
    })
    setStatus({
      id: data?.is_active ? '1' : '0',
      label: data?.is_active ? 'Active' : 'Inactive',
    })
  }

  const validateProposals = useMemo(() => {
    const initialStatus = initialData.is_active ? 1 : 0
    if (initialStatus === 1 && parseInt(status.id) === 0) {
      return true
    } else return false
  }, [status])

  const updateDepartment = async () => {
    const body = {
      name: departmentName,
      template_id: selectedTemplate?.id,
      brand_ruleset_id: selectedSupplierRuleset?.id,
      retailer_ruleset_id: selectedMerchantRuleset?.id,
      sample_template_url: templateURL,
      owner_user_id: selectedMerchandiserUser?.id,
      manager_user_id: selectedManagerUser?.id,
      description: null,
      is_active: status?.id,
    }
    try {
      await patchDepartment({ departmentId: id, body })
      showToast({
        label: 'Proposal Department saved!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const onSave = async () => {
    setShowProposalExistsBanner(false)
    setSaving(true)
    // if validate proposals check for any open proposals else patch the department
    if (validateProposals) {
      const {
        data: { count },
      } = await getProposals({
        params: {
          retailer_id: tenantId,
          status: 'proposed,draft,revised',
          department_id: id,
        },
      })
      if (count > 0) {
        setShowProposalExistsBanner(true)
        setSaving(false)
      } else {
        await updateDepartment()
      }
    } else {
      await updateDepartment()
    }
  }

  const isReady = useMemo(() => {
    return (
      departmentName !== '' &&
      !!selectedTemplate.id &&
      !!selectedMerchantRuleset.id &&
      !!selectedSupplierRuleset.id &&
      !!selectedMerchandiserUser.id &&
      !selectedManagerUser.id
    )
  }, [
    departmentName,
    selectedTemplate,
    selectedMerchantRuleset,
    selectedSupplierRuleset,
    selectedMerchandiserUser,
    selectedManagerUser,
  ])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Departments Settings'
        h2Text='Manage proposal department template and ruleset settings.'
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/proposal-departments'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            text: 'Save',
            variant: 'primary',
            onClick: () => onSave(),
            isDisabled: isReady || saving,
          },
        ]}
      />
      {showProposalExistsBanner && (
        <div className='mb-4'>
          <Banner
            label={`There are active proposals in your account under ${departmentName}. Please accept/reject all such proposals to change the status.`}
            variant='alert'
            onClose={() => setShowProposalExistsBanner(false)}
          />
        </div>
      )}
      <Box
        width='100%'
        border={{ width: 1, radius: 2, color: '#E7E7E7' }}
        padding={{ top: 4, bottom: 4, left: 4, right: 4 }}
      >
        <Box width='50%' flex={{ flexDirection: 'column' }} gap={4}>
          <Input
            width='100%'
            label='Department Name'
            required
            inputProps={{
              value: departmentName,
              onChange: ({ target: { value } }) => setDepartmentName(value),
            }}
          />
          <SelectTemplates
            handleUpdate={(option) => setSelectedTemplate(option)}
            selectedTemplate={selectedTemplate}
            direction='import'
            fieldLabel='Product Import Template'
          />
          <SelectRuleset
            handleUpdate={(option) => setSelectedSupplierRuleset(option)}
            selectedRuleset={selectedSupplierRuleset}
            fieldLabel='Supplier Ruleset'
            fieldPlaceholder='Select the attribute validations that suppliers must meet'
          />
          <SelectRuleset
            handleUpdate={(option) => setSelectedMerchantRuleset(option)}
            selectedRuleset={selectedMerchantRuleset}
            fieldLabel='Merchant Ruleset'
            fieldPlaceholder='Select the attribute validations that you must meet'
          />
          <Input
            width='100%'
            label='Sample template URL'
            inputProps={{
              value: templateURL,
              onChange: ({ target: { value } }) => setTemplateURL(value),
            }}
          />
          <SelectUser
            handleUpdate={(option) => setSelectedMerchandiserUser(option)}
            selectedUser={selectedMerchandiserUser}
            fieldLabel='Department Merchandiser'
          />
          <SelectUser
            handleUpdate={(option) => setSelectedManagerUser(option)}
            selectedUser={selectedManagerUser}
            fieldLabel='Department Manager'
          />
          <Dropdown
            required
            label='Status'
            width='100%'
            value={status}
            onChange={(option: any) => {
              setStatus(option)
            }}
            options={StatusOptions}
            placeholder='Select status'
            disabled={loading}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProposalDepartmentDetails
