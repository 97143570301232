import { ORDERS } from '../../constants'

/**
 * get Badge status
 * @param status string return order status
 * @returns status
 */
const getBadgeStatusByOrderTrackingStatus = (status: string) => {
  switch (status) {
    case ORDERS.TRACKING_STATUS.VALUES.OPEN.VALUE:
      return 'success'
    case ORDERS.TRACKING_STATUS.VALUES.CLOSED.VALUE:
      return 'alert'
    case ORDERS.TRACKING_STATUS.VALUES.CLOSED.VALUE:
      return 'error'
    default:
      return 'info'
  }
}

export { getBadgeStatusByOrderTrackingStatus }
