import React, { useEffect, useState } from 'react'
import { getRetailerProducts } from 'api/products'
import { SearchVariant } from '@teamfabric/copilot-ui'
import SearchBarServerSide from 'molecules/search/searchBarServerSide'

type ChildProps = {
  handleSelection: (data: any) => void
  brandId: number
}

const VariantSearch: React.FC<ChildProps> = ({ handleSelection, brandId }) => {
  const [selectedVariant, setSelectedVariant] = useState({
    id: '',
    label: '',
  })

  useEffect(() => {
    if (selectedVariant) {
      handleSelection(selectedVariant)
    }
  }, [selectedVariant])

  const formatServerSideSearchResults = (data) => {
    return (
      <div>
        {data.map((item) => (
          <div
            className='clickableCard pl-3 pr-3 pt-2 pb-2'
            onClick={() => setSelectedVariant(item)}
            key={item.id}
          >
            <p className='m-0 mb-1'>{item?.name}</p>
            <p className='m-0 label grey-600'>ID: {item?.id}</p>
          </div>
        ))}
      </div>
    )
  }

  return (
    <SearchBarServerSide
      params={{ order_by: -1, brand_id: brandId, fields: 'id,name' }}
      variant={SearchVariant.FIXED_GLOBAL}
      fetchDataCall={getRetailerProducts}
      resultsFormatter={formatServerSideSearchResults}
      width='497px'
      placeholder={
        selectedVariant ? selectedVariant.label : 'Search for variant'
      }
    />
  )
}

export default VariantSearch
