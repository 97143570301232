import { Dropzone as DropzoneUI } from '@teamfabric/copilot-ui'
import { IntRange } from '@teamfabric/copilot-ui/dist/shared/types/utility.type'
import React from 'react'

declare type Accept = {
  [key: string]: string[]
}

export type DropzoneProps = {
  accept: Accept
  dataTestId: any
  maxFiles: number
  onDrop: any
  onRemove: any
  onReplace: any
  onRetry: any
  progress?: IntRange<100>
  secondaryLink?: any
}

const Dropzone: React.FC<DropzoneProps> = ({ ...props }) => {
  return <DropzoneUI {...props} />
}

export default Dropzone
