import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import {
  SHOPIFY_WEBHOOK_HISTORY,
  SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS,
} from 'modules/settings/components/constants'
import createGenericSlice from './genericSlice'
import { createAction } from '@reduxjs/toolkit'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  statusOptions: SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.STATUS_OPTIONS,
  createdAt: null,
  topicOptions: SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.TOPIC_OPTIONS,
}

const extraReducers = {
  setStatusOptions(state, action) {
    if (action.payload.type === 'reset') {
      state.statusOptions =
        SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.STATUS_OPTIONS
    } else {
      state.statusOptions = action.payload
    }
  },
  setTopicOptions(state, action) {
    if (action.payload.type === 'reset') {
      state.topicOptions = SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.TOPIC_OPTIONS
    } else {
      state.topicOptions = action.payload
    }
  },
  setCreatedAt(state, action) {
    state.params.page = 1
    if (action.payload.type === 'reset') {
      delete state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_START]
      delete state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_END]
      delete state.createdAt
    } else {
      const start = getUnixTimestamp(action.payload?.dateRange?.from)
      const end = getUnixTimestamp(action.payload?.dateRange?.to)

      state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_START] = start
      state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_END] = end
      state.createdAt = action.payload
    }
  },
  resetAllFilters(state) {
    state.params.page = 1
    state.statusOptions = SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.STATUS_OPTIONS
    state.topicOptions = SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS.TOPIC_OPTIONS
    delete state.params[SHOPIFY_WEBHOOK_HISTORY.STATUS.PARAM]
    delete state.params[SHOPIFY_WEBHOOK_HISTORY.TOPIC.PARAM]
    delete state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_START]
    delete state.params[SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_END]
  },
}

const shopifySlice = createGenericSlice('shopify', initialState, extraReducers)

// Manually create action creators for extra reducers
export const setStatusOptions = createAction('shopify/setStatusOptions')
export const setTopicOptions = createAction('shopify/setTopicOptions')
export const setCreatedAt = createAction('shopify/setCreatedAt')
export const resetAllFilters = createAction('shopify/resetAllFilters')

export const { setSearch, setFilters, setCurrentPage } = shopifySlice.actions

export default shopifySlice.reducer
