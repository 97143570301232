import React, { ReactNode } from 'react'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import CardLoading from 'molecules/loading/cardLoading'
type ChildProps = {
  loading?: boolean
  header?: ReactNode
  data: any[]
  showDivider?: boolean
  showCollapse?: boolean
  open?: boolean
}
const ModalCard1Col: React.FC<ChildProps> = (props) => {
  return (
    <>
      {props.loading ? (
        <CardLoading
          label={props.header}
          showDivider={props.showDivider}
          open={props.open}
        />
      ) : (
        <Card
          showCollapse={props.showCollapse}
          showDivider={props.showDivider}
          headerContent={props.header}
          open={props.open}
          bodyContent={props.data?.map((value) => (
            <div key={value}>
              <GridRow padding={false}>
                <GridCol
                  lg={12}
                  md={12}
                  sm={12}
                  justifySelf='start'
                  alignSelf='center'
                >
                  {value?.onRender ? (
                    value.onRender()
                  ) : (
                    <p className='grey-700'>{value}</p>
                  )}
                </GridCol>
              </GridRow>
            </div>
          ))}
        />
      )}
    </>
  )
}

export default ModalCard1Col
