import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { calculateRuntime, formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/jobsSlice'
import Link from 'atoms/link/link'
import Box from 'atoms/box/box'
import InspectJobModal from 'molecules/modals/job/inspectJob'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const JobsTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showJobDetailsModal, setShowJobDetailsModal] = useState(false)
  const [selectedJobId, setSelectedJobId] = useState(null)

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading, props?.data])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: 120,
        sortable: true,
      },
      {
        title: 'Job',
        width: '25%',
      },
      {
        title: 'Started At',
        width: '12%',
      },
      {
        title: 'Completed',
        width: '12%',
      },
      {
        title: 'Runtime',
        width: '15%',
      },
      {
        title: '#Success',
        width: '8%',
      },
      {
        title: '#Errors',
        width: '8%',
      },
    ]
    return columns
  }, [])

  const handleIdClick = (jobId) => {
    setShowJobDetailsModal(true)
    setSelectedJobId(jobId)
  }

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((job, id) => {
      return {
        id,
        data: {
          'ID': {
            onRender: () =>
              <Link
                label={job.id}
                variant='primary'
                onClick={() => handleIdClick(job.id)}
                mode='inline'
                dataTestid='job-id-cell'
              />
          },
          'Job': { value: job.module_name },
          'Started At': {
            onRender: () => (
              <div>
                {formatDate({ date: new Date(job.start_time) })}
                <br />
                <span className='body2-regular grey-700'>{new Date(job.start_time).toLocaleTimeString(['en-US'], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                </span>
              </div>
            )
          },
          'Completed': {
            onRender: () => (
              <div>
                {formatDate({ date: new Date(job.finish_time) })}
                <br />
                <span className='body2-regular grey-700'>{new Date(job.finish_time).toLocaleTimeString(['en-US'], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                </span>
              </div>
            )
          },
          'Runtime': { value: calculateRuntime(job?.start_time, job?.finish_time) },
          '#Success': { value: job.total_success || '--' },
          '#Errors': { value: job.error_count || '--' }
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const closeModal = () => {
    setSelectedJobId(null)
    setShowJobDetailsModal(false)
  }

  return (
    <>
      {showJobDetailsModal && (
        <InspectJobModal jobId={selectedJobId} showModal={showJobDetailsModal} closeModal={closeModal} />
      )}
      <Box dataTestid='jobs-table' margin={{ bottom: 7 }}>
        <Table
          columns={tableColumns}
          data={tableData}
          loading={props.loading}
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: props.currentPage,
            perPage: 10,
            totalRecords: props.totalRows,
          }}
        />
      </Box>
    </>
  )
}

export default JobsTable
