import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { useNavigate } from 'react-router-dom'
import { ONBOARDING_TASKS } from '../../constants'
import { getLocations, patchLocation, postLocation } from 'api/locations'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentStep } from 'store/onboardingSlice'
import CardLoading from 'molecules/loading/cardLoading'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
} from 'molecules/address/constants'
import { useToast } from '@teamfabric/copilot-ui'
import AddressForm2 from 'molecules/address/formWithStreet2'
import { completeTask, isAddressNoNeedToUpdate } from './utils'
import { PATHS } from 'routes/pageRoutes'

type BillingProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
  setClearForm: (value: boolean) => void
  clearForm: boolean
  reseting: boolean
}

const Billing: React.FC<BillingProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
  setClearForm,
  clearForm,
  reseting,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()

  const info = useSelector((state: RootState) => state.onboarding.info)
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(null)

  const [isReady, setIsReady] = useState(false)
  const [address, setAddress] = useState<AddressFormDataType>(
    AddressFormDefaultValues
  )
  const ADDRESS_TYPE = 'billing'
  const DEFAULT_NICKNAME = 'Business Address'
  const KEY = 'BILLING'

  const updateInfo = {
    processNum: 1,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: true,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: false,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: false,
    completed: false,
  }

  const [initValues, setInitValues] = useState({
    name1: '',
    street1: '',
    street2: '',
    postal_code: '',
    city: '',
    province: '',
    country: '',
  })

  const loadBillingLocation = async () => {
    try {
      setLoading(true)
      const { data } = await getLocations({ params: { type: ADDRESS_TYPE } })
      setLocation(data?.results[0])
      if (data?.results[0]) {
        setInitValues({
          name1: data.results[0].address.name1,
          street1: data.results[0].address.street1,
          street2: data.results[0].address.street2,
          postal_code: data.results[0].address.postal_code,
          city: data.results[0].address.city,
          province: data.results[0].address.province,
          country: data.results[0].address.country,
        })
      }
    } catch (_) {
      showToast({
        label: "Couldn't load",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setLoading(false)
    }
  }

  const submitBusinessAddress = async () => {
    try {
      if (isAddressNoNeedToUpdate({ initValues, address }) || !isReady) {
        return
      }
      if (location?.id) {
        // Update address
        const updatedAddress = {
          ...address,
          email: location.address.email,
          phone1: location.address.phone1,
        }
        const body = {
          address: updatedAddress,
          id: location.id,
          nickname: location.nickname,
          type: location.type,
        }
        await patchLocation({ id: location.id, body })
      } else {
        // Create address
        const body = {
          address,
          id: null,
          nickname: DEFAULT_NICKNAME,
          type: ADDRESS_TYPE,
        }
        await postLocation({ body })
      }
      showToast({
        label: 'Saved location',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      showToast({
        label: "Couldn't save",
        variant: 'error',
        isDismissable: true,
        id: '3',
      })
    }
  }

  const resetForm = () => {
    setClearForm(false)
    setInitValues({
      name1: '',
      street1: '',
      street2: '',
      postal_code: '',
      city: '',
      province: '',
      country: '',
    })
  }

  useEffect(() => {
    setIsNextButtonDisabled(true)
    ;(async () => {
      if (clearForm) {
        resetForm()
      } else {
        await loadBillingLocation()
      }
    })()
  }, [])

  useEffect(() => {
    if (!clearForm) {
      return
    }
    resetForm()
  }, [clearForm])

  useEffect(() => {
    setIsNextButtonDisabled(!isReady)
  }, [address])

  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      if (!isReady) {
        navigate(PATHS.Onboarding)
      }
      await Promise.all([
        submitBusinessAddress(),
        completeTask({
          info,
          key: KEY,
          dispatch,
          updateInfo,
        }),
      ])
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      await Promise.all([
        submitBusinessAddress(),
        completeTask({
          info,
          key: KEY,
          dispatch,
          updateInfo,
        }),
      ])
      dispatch(
        setCurrentStep({
          value: ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME,
          parent: ONBOARDING_TASKS.INFO.VALUE,
        })
      )
    })()
  }, [isNextClicked])

  const customLabels = [
    '',
    'Country',
    'Business Name',
    'Street Address',
    'City',
    'State / Province',
    'Zip / Postal Code',
  ]

  return (
    <>
      {(loading || reseting) && (
        <CardLoading
          label={ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.NAME}
          num={6}
        />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.NAME}
              h2Text='Enter the billing address that would normally appear on invoices
            sent to your retail partners.'
            />
          }
          bodyContent={
            <div style={{ width: '70%' }}>
              {!loading && (
                <AddressForm2
                  initialValues={initValues}
                  handleUpdate={(data) => setAddress(data)}
                  showEmail={false}
                  showPhone={false}
                  handleIsReady={(value) => setIsReady(value)}
                  type={ADDRESS_TYPE}
                  showNickName={false}
                  customLabels={customLabels}
                />
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default Billing
