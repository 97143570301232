import Box from 'atoms/box/box'
import FilterChip from 'molecules/filter/filterChip'
import { handleFilterChipDeletion } from 'lib/utils/filter/singleFilters'

type FilterChipsProps = {
  filterChips: any[]
  filterActions: any
  setFilters: any
}

const FilterChips: React.FC<FilterChipsProps> = ({
  filterChips,
  filterActions,
  setFilters,
}) => {
  return (
    <Box flex={{ flexWrap: 'wrap' }}>
      {filterChips.length > 0 &&
        filterChips.map(({ key, label, value }, index) => (
          <div key={index} className='mr-3 mt-3'>
            <FilterChip
              primaryLabel={label}
              value={value}
              onRemove={() => {
                handleFilterChipDeletion(filterActions, key, setFilters, value)
              }}
            />
          </div>
        ))}
    </Box>
  )
}

export default FilterChips
