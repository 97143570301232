import React, { useState, useMemo } from 'react'
import { Box, Input, Modal, MultiLineTextArea } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { postRuleset } from 'api/rulesets'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  loadRulesets: (quiet?: boolean) => void
}

const CreateRulesetModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  loadRulesets,
}) => {
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [rulesetName, setRulesetName] = useState('')
  const [description, setDescription] = useState('')

  const onCreate = async () => {
    const body = {
      name: rulesetName,
      description: description,
    }
    try {
      setSaving(true)
      await postRuleset({ body })
      setShowModal(false)
      loadRulesets()
      showToast({
        label: 'Ruleset created successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error : ${errorMessage}`
          : 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      resetForm()
    }
  }

  const resetForm = () => {
    setRulesetName('')
    setDescription('')
  }

  const isReady = useMemo(() => {
    return rulesetName !== ''
  }, [rulesetName])

  return (
    <Modal
      headerText='Create Ruleset'
      description=''
      size='small'
      onClose={() => {
        setShowModal(false)
        resetForm()
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            setShowModal(false)
            resetForm()
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onCreate(),
          text: 'Create',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => {
        setShowModal(false)
        resetForm()
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <Input
          width='100%'
          label='Name'
          required
          inputProps={{
            value: rulesetName,
            onChange: ({ target: { value } }) => setRulesetName(value),
            placeholder: 'Name',
          }}
        />
        <MultiLineTextArea
          label='Description'
          width='100%'
          inputProps={{
            value: description,
            onChange: ({ target: { value } }) => setDescription(value),
            placeholder: 'Description',
          }}
        />
      </Box>
    </Modal>
  )
}

export default CreateRulesetModal
