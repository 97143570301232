import React, { useState, useEffect } from 'react'
import Card2Col from 'molecules/cards/2Col'
import Link from 'atoms/link/link'

type ItemAttributesProps = {
  label: string
  attributes: any
}

const ItemAttributes: React.FC<ItemAttributesProps> = ({
  label,
  attributes,
}) => {
  const [data, setData] = useState<any[]>([])
  const [header, setHeader] = useState(<>{label}</>)

  useEffect(() => {
    if (label === 'Image Attributes') {
      formatImageData(attributes)
    } else formatData(attributes)
    formatHeader(label)
  }, [])

  const formatImageData = (attributes) => {
    let formattedData = []
    attributes.forEach((attribute) => {
      formattedData.push({
        label: attribute.name,
        value: {
          onRender: () => (
            <Link
              label={'External URL'}
              onClick={() => window.open(attribute.values[0]?.value, '_blank')}
            />
          ),
        },
      })
    })
    setData(formattedData)
  }

  const formatData = (attributes) => {
    let formattedData = []
    attributes.forEach((attribute) => {
      formattedData.push({
        label: attribute.name,
        value: `${attribute.values[0]?.value}
                ${
                  attribute.values[0].unit
                    ? ` ${attribute.values[0].unit.standard_unit}`
                    : ''
                }`,
      })
    })
    setData(formattedData)
  }

  const formatHeader = (label) => {
    setHeader(
      <span>
        {label}
        <span className='grey-400'> ({attributes.length})</span>
      </span>
    )
  }

  return (
    <div className='mt-3 mb-3 width-49'>
      <Card2Col
        showCollapse={true}
        open={false}
        header={header}
        data={data}
        skeletonRowsNumber={7}
      />
    </div>
  )
}

export default ItemAttributes
