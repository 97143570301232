import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { PRODUCTS } from 'modules/products/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  connectionOptions: [],
  updatedOn: '',
}
const permitsSlice = createSlice({
  name: 'permits',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.CONNECTIONS.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setUpdatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[PRODUCTS.UPDATED_ON.PARAM_START]
        delete state.params[PRODUCTS.UPDATED_ON.PARAM_END]
        delete state.updatedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[PRODUCTS.UPDATED_ON.PARAM_START] = start
        state.params[PRODUCTS.UPDATED_ON.PARAM_END] = end
        state.updatedOn = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []

      delete state.params[PRODUCTS.CONNECTIONS.PARAM]
      delete state.params[PRODUCTS.ACKNOWLEDGED.PARAM]
      delete state.params[PRODUCTS.UPDATED_ON.PARAM_START]
      delete state.params[PRODUCTS.UPDATED_ON.PARAM_END]
      delete state.updatedOn
    },
  },
})

export const {
  setSorting,
  setSearch,
  setFilters,
  setConnectionOptions,
  setCurrentPage,
  setUpdatedOn,
  resetAllFilters,
} = permitsSlice.actions

export default permitsSlice.reducer
