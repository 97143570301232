import { FC, useMemo } from 'react'
import { startCase } from 'lodash'

interface PackingSlipProps {
  connection: {
    packing_slip_template: string
  }
}

const PackingSlipPresenter: FC<PackingSlipProps> = ({ connection }) => {
  const presenter = useMemo(() => {
    const presenter: { label: string } = { label: 'Default Packing Slip' }
    if (connection?.packing_slip_template) {
      presenter.label = startCase(
        connection.packing_slip_template.replace('_', ' ').replace('.html', '')
      )
    }
    return presenter
  }, [connection])

  return <span>{presenter.label}</span>
}

export default PackingSlipPresenter
