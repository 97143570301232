import React, { useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Input, Button } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { shopifySyncVariant } from 'api/shopify'

type ChildProps = {
}

const SyncVariantsByVariantIdCard: React.FC<ChildProps> = ({
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [job, setJob] = useState(null)
  const [variantId, setVariantId] = useState(null)

  const onSync = async () => {
    setSaving(true)
    const body = {
      variant_id: parseInt(variantId)
    }
    try {
      const { data } = await shopifySyncVariant({ platformAccountId: id, body })
      setJob(data)
      showToast({
        label: 'Sync scheduled!',
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'Sync failed!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  return (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Sync Variant</span>}
            h2Text={
              <span className='body2-regular'>
                Attempt to sync variants between Shopify and fabric using a fabric Item ID.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            This utility uses a fabric Item ID and searches your Shopify s tore for any items
            matching using the Merchant SKU, Vendor SKU, and/or UPC.
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <Input
                width='100%'
                label='fabric Item ID'
                required
                inputProps={{
                  name: 'variantId',
                  value: variantId,
                  placeholder: 'fabric item Id',
                  onChange: ({ target: { value } }) => {
                    setVariantId(value)
                  },
                }}
                maskOptions={{
                  regex: '\\d*(\\.)?\\d*',
                }}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onSync()}
                text='Run Sync'
                variant='secondary'
                isDisabled={
                  saving || !variantId
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default SyncVariantsByVariantIdCard
