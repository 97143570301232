import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, PageHeader, Table } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import { theme } from '@teamfabric/copilot-ui'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const InfoTable = [
  {
    id: 0,
    orderPlaced:
      'Monday (before 1 PM ET), Friday (after 1PM ET), Saturday, Sunday',
    shipBy: 'Tuesday',
  },
  {
    id: 1,
    orderPlaced: 'Monday (after 1 PM ET)',
    shipBy: 'Wednesday',
  },
  {
    id: 2,
    orderPlaced: 'Wednesday (after 1 PM ET), Thursday (before 1PM ET)',
    shipBy: 'Friday',
  },
  {
    id: 3,
    orderPlaced: 'Thursday (after 1 PM ET), Friday (before 1PM ET)',
    shipBy: 'Monday',
  },
]

// Mapping function
const getShipByValue = (originalShipBy, selectedValue) => {
  const mapping = {
    24: {
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Friday: 'Thursday',
      Monday: 'Tuesday',
    },
    48: {
      Tuesday: 'Wednesday',
      Wednesday: 'Thursday',
      Friday: 'Monday',
      Monday: 'Tuesday',
    },
    72: {
      Tuesday: 'Thursday',
      Wednesday: 'Friday',
      Friday: 'Tuesday',
      Monday: 'Wednesday',
    },
  }

  return mapping[selectedValue]?.[originalShipBy] || originalShipBy
}

const FulfillmentSLA: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [preference, setPreference] = useState(null)

  const defaultFulfillmentSla = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_fulfillment_sla'])
    }
    return null
  }, [preferences, loading])

  const preferenceOptions = useMemo(() => {
    return (
      defaultFulfillmentSla?.options?.map((option) => ({
        id: option.id,
        label: option.name,
        value: option.value,
      })) || []
    )
  }, [defaultFulfillmentSla])

  useEffect(() => {
    if (defaultFulfillmentSla) {
      setPreference(defaultFulfillmentSla)
      setOptions(preferenceOptions)
      let selectedPreferenceOption = defaultFulfillmentSla?.default_option
      if (defaultFulfillmentSla.retailer_preference) {
        selectedPreferenceOption = preferenceOptions.find(
          (el) => el.value === defaultFulfillmentSla.retailer_preference.value
        )
      }

      setSelectedOption(selectedPreferenceOption)
    }
  }, [defaultFulfillmentSla, preferenceOptions])

  const tableColumns = [
    {
      title: 'Order Placed',
      width: '75%',
    },
    {
      title: 'Must Ship By',
      width: '25%',
    },
  ]

  // mutate the table data shipBy value based on selected option
  const tableData = useMemo(() => {
    return InfoTable.map(({ id, orderPlaced, shipBy }) => {
      const updatedShipBy = getShipByValue(shipBy, selectedOption?.value)
      return {
        id,
        data: {
          'Order Placed': { value: orderPlaced },
          'Must Ship By': { value: updatedShipBy },
        },
      }
    })
  }, [InfoTable, selectedOption])

  const handleUpdate = (option) => {
    const json = {
      preference: { id: preference.id },
      option: { id: option.id },
    }
    handleChange(json)
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Default Fulfillment SLA</span>}
              h2Text={
                <Box typography={theme.typography.body2.regular}>
                  The Fulfillment SLA governs how long suppliers have to ship
                  orders before they are considered late.
                </Box>
              }
            />
          </Box>
        }
        bodyContent={
          <>
            <Dropdown
              required
              label='Select duration'
              width='50%'
              value={selectedOption}
              onChange={(option: any) => {
                const filteredOption = preferenceOptions.find(
                  (el) => el.id === option.id
                )
                setSelectedOption(filteredOption)
                handleUpdate(option)
              }}
              options={options}
              placeholder='Select duration'
              disabled={loading}
            />
            <Table columns={tableColumns} data={tableData} disableTableBorder />
          </>
        }
      ></Card>
    </Box>
  )
}

FulfillmentSLA.defaultProps = defaultProps
export default FulfillmentSLA
