import API, { API_URL } from 'api'

export const postPushNotification = async ({ body }) => {
  return await API.post(API_URL.PUSH_NOTIFICATIONS(), body)
}
export const patchPushNotification = async ({ id, body }) => {
  return await API.patch(API_URL.PUSH_NOTIFICATIONS_DETAIL({ id }), body)
}

export const getPushNotification = async ({ params = {} }) => {
  return await API.get(API_URL.PUSH_NOTIFICATIONS(), { params })
}
