import React, { useState } from 'react'
import Banner from 'atoms/banner/banner'
import { Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import AddressForm from 'molecules/address/form'
import { updateOrderById } from 'api/orders'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
} from 'molecules/address/constants'

type ChangeShipToProps = {
  orderDetails: any
  setShowChangeShipToModal: (arg: boolean) => void
  showChangeShipToModal: boolean
  reloadOrder?: () => void
}

const ChangeShipToModal: React.FC<ChangeShipToProps> = ({
  orderDetails,
  setShowChangeShipToModal,
  showChangeShipToModal,
  reloadOrder,
}) => {
  const showToast = useToast()
  const [address, setAddress] = useState<AddressFormDataType>(
    AddressFormDefaultValues
  )
  const [isReady, setIsReady] = useState(false)

  const handleSubmit = async () => {
    const body = {
      ship_to: {
        name1: address.name1,
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        province: address.province,
        postal_code: address.postal_code,
        country: address.country,
        phone1: address.phone1,
      },
    }
    try {
      await updateOrderById({ id: orderDetails.id, body })
      showToast({
        label: 'Ship to address updated. Vendor will be notified.',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      let errorMessage = 'Please review your input and try again.'
      if (error.response && error.response.data.detail) {
        errorMessage = error.response.data.detail
      }
      showToast({
        label: errorMessage,
        isDismissable: true,
        variant: error,
        id: '1',
      })
    } finally {
      setShowChangeShipToModal(false)
      reloadOrder()
    }
  }

  const handleUpdate = (data: any) => {
    setAddress(data)
  }

  return (
    <>
      <Modal
        headerText='Update Ship To'
        size='medium'
        onClose={() => setShowChangeShipToModal(false)}
        isVisible={showChangeShipToModal}
        footerButtons={[
          {
            onClick: () => setShowChangeShipToModal(false),
            text: 'Close',
            variant: 'secondary',
          },
          {
            onClick: () => handleSubmit(),
            text: 'Update',
            variant: 'primary',
            isDisabled: !isReady,
          },
        ]}
        onBackdropClick={() => setShowChangeShipToModal(false)}
      >
        <>
          <div className='mb-4'>
            {/* NOTE: banner label has max length property which is not showing the complete label */}
            <Banner
              label="Complete the form below to update this order's 'Ship To' address. Suppliers will receive an email confirmation if they are subscribed to the notification. It is always a good idea to follow up with your supplier directly in case fulfillment is already underway."
              variant='info'
            />
          </div>
          <AddressForm
            initialValues={orderDetails.ship_to}
            handleUpdate={handleUpdate}
            showPhone
            showEmail
            handleIsReady={(value) => setIsReady(value)}
          />
        </>
      </Modal>
    </>
  )
}

export default ChangeShipToModal
