import React, { useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { getProducts } from 'api/products'
import ProductFilters from 'modules/products/components/filters/products/index'
import ProductsTable from '../components/table/productsTable'
import SearchBar from 'molecules/search/searchBarTable'
import Button from 'atoms/button/button'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import BulkActionsBar from 'organisms/bulkActions'
import { GridCol, GridRow, Box } from 'atoms'
import { BUTTONS, PAGE_TYPE, SEARCH } from 'lib/constants'
import { setSelected, setSearch } from 'store/productsSlice'
import ProductExportsModal from '../components/modals/productExports'
import PageHeaderComponent from 'molecules/PageHeader'

const ProductList: React.FC = () => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const params = useSelector((state: RootState) => state.products.params)
  const selected = useSelector((state: RootState) => state.products.selected)

  const dispatch = useDispatch()

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        dispatch(setSelected([]))
        const { data: data } = await getProducts({ params: { ...params } })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <ProductExportsModal
            showModal={showExportModal}
            setShowModal={setShowExportModal}
          />
          <PageHeaderComponent
            h1Text='All products'
            primaryButtons={[
              {
                onClick: () => setShowExportModal(true),
                text: BUTTONS.EXPORT.LABEL,
                icon: BUTTONS.EXPORT.LABEL,
                variant: 'primary',
              },
            ]}
            customClassName='mb-4'
          />
          <SearchBar
            placeholder={SEARCH.PLACEHOLDERS.PRODUCT}
            setSearch={setSearch}
            className='mt-4'
          />
          <div className='filterDiv'>
            <ProductFilters params={params} />
          </div>
          <BulkActionsBar
            loading={loading}
            hasBulkActions={true}
            selectedItems={selected}
            totalRows={totalRows}
            currentPage={params.page}
            pageType={PAGE_TYPE.PRODUCTS}
          />
          <ProductsTable
            data={tableData}
            loading={loading}
            totalRows={totalRows}
            currentPage={params.page}
            currentSortBy={params.order_by}
            selected={selected}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ProductList
