import { FC } from 'react'
import Message from 'molecules/message'
import { Modal } from 'atoms'

export type MemoDataProps = {
  orderId: number
  memos_count?: number
  memos?: any // if already have the array of memos => no need GET from orderId (e.g. order detail)
}

type ChildProps = {
  memoData: MemoDataProps
  modalClose: () => void
  isVisible: boolean
  updateMemoCount: (orderId: number) => void
}

const MemoModal: FC<ChildProps> = ({
  memoData,
  modalClose,
  isVisible,
  updateMemoCount,
}) => {
  return (
    <Modal
      headerText='Messages'
      description={
        <Message memoData={memoData} updateMemoCount={updateMemoCount} />
      }
      onClose={modalClose}
      isVisible={isVisible}
      footerButtons={[
        {
          onClick: () => modalClose(),
          text: 'Cancel',
          variant: 'secondary',
        },
      ]}
      size='large'
      onBackdropClick={modalClose}
      dataTestid={''}
    />
  )
}

export default MemoModal
