import React, { useEffect, useMemo, useState } from 'react'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxWithSearchFilterChange,
  setCountersAndChips,
  handleRadioFilterChange,
} from 'lib/utils/filter/singleFilters'
import { RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFilters,
  setConnectionOptions,
  setCreatedOn,
  setDueOn,
  setAcknowledgedOn,
  setPaidOn,
  resetAllFilters,
  setInitialCreatedOn,
} from 'store/invoicesSlice'
import Box from 'atoms/box/box'
import { FILTERS } from 'molecules/filter/constants'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import { ORDERS } from 'modules/orders/constants'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'
import MoreFiltersModal from 'organisms/allFiltersModal/invoices'
import { getDateObjectForLastMonth } from 'lib/utils/date/formatDate'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [paidCounter, setPaidCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)
  const [dueOnCounter, setDueOnCounter] = useState(0)
  const [acknowledgedOnCounter, setAcknowledgedOnCounter] = useState(0)
  const [paidOnCounter, setPaidOnCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const dispatch = useDispatch()

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.invoices.connectionOptions
  )
  const createdOn = useSelector((state: RootState) => state.invoices.createdOn)
  const initialCreatedOnSet = useSelector(
    (state: RootState) => state.invoices.initialCreatedOnValueSet
  )
  const dueOn = useSelector((state: RootState) => state.invoices.dueOn)
  const acknowledgedOn = useSelector(
    (state: RootState) => state.invoices.acknowledgedOn
  )
  const paidOn = useSelector((state: RootState) => state.invoices.paidOn)

  const isAFilterApplied = useMemo(() => {
    return (
      connectionsCounter +
        paidCounter +
        acknowledgedCounter +
        createdOnCounter +
        acknowledgedOnCounter +
        paidOnCounter +
        dueOnCounter >
      0
    )
  }, [
    connectionsCounter,
    paidCounter,
    acknowledgedCounter,
    createdOnCounter,
    acknowledgedOnCounter,
    paidOnCounter,
    dueOnCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: ORDERS.CONNECTION.NAME,
    },
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.ACKNOWLEDGED.NAME + '?',
      chipName: ORDERS.ACKNOWLEDGED.NAME,
    },
    is_paid: {
      setFilterCount: setPaidCounter,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.INVOICE_PAID.NAME + '?',
      chipName: ORDERS.INVOICE_PAID.NAME,
    },
    created_at_gte: {
      setFilterCount: setCreatedOnCounter,
      dateRange: createdOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.INVOICE_SUBMITTED.NAME,
    },
    terms_date_due_gte: {
      setFilterCount: setDueOnCounter,
      dateRange: dueOn,
      action: setDueOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.INVOICE_DUE.NAME,
    },
    acknowledged_at_gte: {
      setFilterCount: setAcknowledgedOnCounter,
      dateRange: acknowledgedOn,
      action: setAcknowledgedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.ACKNOWLEDGED_ON.NAME,
    },
    paid_at_gte: {
      setFilterCount: setPaidOnCounter,
      dateRange: paidOn,
      action: setPaidOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.INVOICE_PAID_ON.NAME,
    },
  }

  useEffect(() => {
    // only on the very first render, apply a preset to the invoice submitted (createdOn) filter
    if (!initialCreatedOnSet) {
      dispatch(setCreatedOn(getDateObjectForLastMonth()))
      dispatch(setInitialCreatedOn())
    }
  }, [])

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${ORDERS.CONNECTION.NAME}-filter`}
          label={ORDERS.CONNECTION.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <RadioGroupFilter
          dataTestid={`${ORDERS.ACKNOWLEDGED.NAME}-filter`}
          label={ORDERS.ACKNOWLEDGED.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              setFilters,
            })
          }}
          value={params?.is_acknowledged?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={
            acknowledgedCounter > 0 ? acknowledgedCounter?.toString() : ''
          }
        />
        <RadioGroupFilter
          dataTestid={`${ORDERS.INVOICE_PAID.NAME}-filter`}
          label={ORDERS.INVOICE_PAID.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: ORDERS.INVOICE_PAID.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: ORDERS.INVOICE_PAID.PARAM,
              setFilters,
            })
          }}
          value={params?.is_paid?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={paidCounter > 0 ? paidCounter?.toString() : ''}
        />
        <DateFilter
          dataTestid={`${ORDERS.INVOICE_SUBMITTED.NAME}-filter`}
          label={ORDERS.INVOICE_SUBMITTED.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.INVOICE_SUBMITTED.PARAMS.START,
              date,
            })
          }}
          defaultValue={createdOn}
          filterCount={createdOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.INVOICE_SUBMITTED.PARAMS.START,
              isReset: true,
            })
          }
        />
        <MoreFiltersModal params={params} />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
