import React, { useEffect, useState } from 'react'
import { getGenericShippingMethods } from 'api/shippingMethods'
import Dropdown from 'atoms/dropdown/dropdown'

type ChildProps = {
  methodType: string[]
  handleUpdate?: (data: any) => void
}

const GenericShippingMethodDropdown: React.FC<ChildProps> = ({
  methodType,
  handleUpdate,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState({
    id: '',
    label: '',
  })

  useEffect(() => {
    ;(async () => {
      await fetchOrderDetails()
    })()
  }, [])

  const fetchOrderDetails = async () => {
    const { data } = await getGenericShippingMethods({
      params: { order_by: -1, pagination: 0 },
    })
    setOptions(data.results)
    if (methodType) {
      setOptions(
        data.results
          .filter((el: { name: string }) => methodType.includes(el.name))
          .map((el) => ({ id: el.id, label: el.name }))
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!loading && selectedCarrier) {
      handleUpdate(selectedCarrier)
    }
  }, [selectedCarrier])

  return (
    <Dropdown
      required
      label='Carrier Service'
      width='100%'
      value={selectedCarrier}
      onChange={(option: any) => {
        setSelectedCarrier(option)
      }}
      options={options}
      placeholder='Select a carrier service'
    />
  )
}

export default GenericShippingMethodDropdown
