import React, { useEffect, useMemo, useState } from 'react'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleCheckboxWithSearchFilterChange,
  setCountersAndChips,
  handleDateFilterChange,
} from 'lib/utils/filter/singleFilters'
import { PRODUCTS } from 'modules/products/constants'
import Box from 'atoms/box/box'
import FilterChips from 'organisms/filterChips'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  setFilters,
  setConnectionOptions,
  setCreatedOn,
  resetAllFilters,
  setUpdatedOn,
  setRetailerSkuAssigned,
  setSupplierLastUpdate,
} from 'store/productsSlice'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import AllFiltersModal from 'organisms/allFiltersModal/products'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [updatedOnCounter, setUpdatedOnCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)
  const [retailerSkuAssignedCounter, setRetailerSkuAssignedCounter] =
    useState(0)
  const [inStockCounter, setInStockCounter] = useState(0)
  const [discontinuedCounter, setDiscontinuedCounter] = useState(0)
  const [supplierLastUpdateCounter, setSupplierLastUpdateCounter] = useState(0)
  const [isSyncedToShopifyCounter, setIsSyncedToShopifyCounter] = useState(0)
  const [isSyncedToBigCommerceCounter, setIsSyncedToBigCommerceCounter] =
    useState(0)

  const [filterChips, setFilterChips] = useState([])

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.products.connectionOptions
  )
  const updatedOn = useSelector((state: RootState) => state.products.updatedOn)

  const createdOn = useSelector((state: RootState) => state.products.createdOn)

  const retailerSkuAssigned = useSelector(
    (state: RootState) => state.products.retailerSkuAssigned
  )

  const supplierLastUpdate = useSelector(
    (state: RootState) => state.products.supplierLastUpdate
  )

  const isAFilterApplied = useMemo(() => {
    return (
      updatedOnCounter +
        createdOnCounter +
        connectionsCounter +
        retailerSkuAssignedCounter +
        supplierLastUpdateCounter +
        inStockCounter +
        discontinuedCounter +
        isSyncedToShopifyCounter +
        isSyncedToBigCommerceCounter >
      0
    )
  }, [
    updatedOnCounter,
    connectionsCounter,
    createdOnCounter,
    retailerSkuAssignedCounter,
    supplierLastUpdateCounter,
    inStockCounter,
    discontinuedCounter,
    isSyncedToShopifyCounter,
    isSyncedToBigCommerceCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: PRODUCTS.CONNECTIONS.NAME,
    },
    updated_at_gte: {
      setFilterCount: setUpdatedOnCounter,
      dateRange: updatedOn,
      action: setUpdatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.UPDATED_ON.NAME,
    },
    created_at_gte: {
      setFilterCount: setCreatedOnCounter,
      dateRange: createdOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.CREATED_ON.NAME,
    },
    retailer_identifier_created_at_gte: {
      setFilterCount: setRetailerSkuAssignedCounter,
      dateRange: retailerSkuAssigned,
      action: setRetailerSkuAssigned,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.RETAILER_SKU_ASSIGNED.NAME,
    },
    in_stock: {
      setFilterCount: setInStockCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: PRODUCTS.IN_STOCK.NAME,
    },
    is_discontinued: {
      setFilterCount: setDiscontinuedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: PRODUCTS.IS_DISCONTINUED.NAME,
    },
    brand_inventory_updated_at_gte: {
      setFilterCount: setSupplierLastUpdateCounter,
      dateRange: supplierLastUpdate,
      action: setSupplierLastUpdate,
      filterType: FILTERS.TYPES.DATE,
      chipName: PRODUCTS.SUPPLIER_LAST_UPDATE.NAME,
    },
    is_on_shopify: {
      setFilterCount: setIsSyncedToShopifyCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: PRODUCTS.IS_SYNCED_TO_SHOPIFY.NAME,
    },
    is_on_bigcommerce: {
      setFilterCount: setIsSyncedToBigCommerceCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: PRODUCTS.IS_SYNCED_TO_BIG_COMMERCE.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${PRODUCTS.CONNECTIONS.NAME}-filter`}
          label={PRODUCTS.CONNECTIONS.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: PRODUCTS.CONNECTIONS.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <DateFilter
          dataTestid={`${PRODUCTS.UPDATED_ON.NAME}-filter`}
          label={PRODUCTS.UPDATED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.UPDATED_ON.PARAM_START,
              date,
            })
          }}
          defaultValue={updatedOn}
          filterCount={updatedOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.UPDATED_ON.PARAM_START,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${PRODUCTS.CREATED_ON.NAME}-filter`}
          label={PRODUCTS.CREATED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.CREATED_ON.PARAM_START,
              date,
            })
          }}
          defaultValue={createdOn}
          filterCount={createdOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.CREATED_ON.PARAM_START,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${PRODUCTS.RETAILER_SKU_ASSIGNED.NAME}-filter`}
          label={PRODUCTS.RETAILER_SKU_ASSIGNED.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_START,
              date,
            })
          }}
          defaultValue={retailerSkuAssigned}
          filterCount={retailerSkuAssignedCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: PRODUCTS.RETAILER_SKU_ASSIGNED.PARAM_START,
              isReset: true,
            })
          }
        />
        <AllFiltersModal params={params} />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
