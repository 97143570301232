import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, InputStepper, PageHeader } from 'atoms'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const ReturnsAllowances: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [returnValue, setReturnValue] = useState<number>(0)
  const [preference, setPreference] = useState(null)

  const defaultReturnsAllowance = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_returns_allowance'])
    }
    return null
  }, [preferences, loading])

  useEffect(() => {
    if (typeof defaultReturnsAllowance !== 'undefined') {
      setPreference(defaultReturnsAllowance)
      if (defaultReturnsAllowance?.retailer_preference) {
        setReturnValue(
          parseFloat(defaultReturnsAllowance?.retailer_preference?.value)
        )
      }
    }
  }, [defaultReturnsAllowance])

  const handleUpdate = (parsedValue) => {
    if (preference && !loading) {
      const json = {
        preference: { id: preference?.id },
        value: parsedValue,
      }
      handleChange(json)
    }
  }

  const handleValueChange = (newVal) => {
    const parsedValue = typeof newVal === 'string' ? parseInt(newVal) : newVal
    if (parsedValue !== returnValue) {
      setReturnValue(parsedValue)
      handleUpdate(parsedValue)
    }
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={
                <span className='h5'>
                  Default Customer Service & Returns Allowance
                </span>
              }
              h2Text={
                <>
                  <span className='body2-regular'>
                    Setting a customer service & returns allowance on a
                    connection automatically applies an adjustment to all new
                    incoming supplier invoices, decreasing the amount fabric
                    Merchant owes to the supplier.
                  </span>
                  <span className='body2-regular'>
                    This allowance is typically applied to offset the costs of
                    returns (and other costs associated with servicing a
                    customer). To set a default returns allowance for any new
                    connections automatically, enter a value between 0 and 10%.
                  </span>
                </>
              }
            />
          </Box>
        }
        bodyContent={
          <InputStepper
            label='Default Allowance (%)'
            max={10}
            min={0}
            value={returnValue}
            onValueChange={(newVal) => handleValueChange(newVal)}
            stepSize={0.25}
          />
        }
      ></Card>
    </Box>
  )
}

ReturnsAllowances.defaultProps = defaultProps
export default ReturnsAllowances
