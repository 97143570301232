import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { patchProposal, getProposalReturnReasons } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import { Dropdown, MultiLineTextArea } from 'atoms'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const ReturnProposalModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectedReason, setSelectedReason] = useState<any>()
  const [reasonOptions, setReasonOptions] = useState(null)
  const [reasonMessage, setReasonMessage] = useState(undefined)

  const showToast = useToast()

  const fetchReasonsData = async () => {
    setLoading(true)
    const { data } = await getProposalReturnReasons()
    const options = []
    if (data.count > 0) {
      data.results.map((el) => {
        options.push({
          id: el.id,
          label: el.return_reason,
        })
      })
    }
    setReasonOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    if (props.showModal) {
      fetchReasonsData()
    }
  }, [props.showModal])

  const returnProposal = async () => {
    try {
      setLoading(true)
      await patchProposal({
        id: props.proposal?.id,
        body: {
          status: 'revised',
          return_reason_id: selectedReason.id,
          ...(selectedReason.label === 'Others' &&
          reasonMessage &&
          reasonMessage.length > 0
            ? { return_reason_detail: reasonMessage }
            : {}),
        },
      })
      showToast({
        label: `Your proposal has been returned successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: `Error returning the proposal: ${error}`,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Return Proposal'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Close',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => returnProposal(),
            text: 'Return Proposal',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading || !selectedReason,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <div className='body1-regular grey-600'>
            Are you sure you want to return this proposal?
          </div>
          <div className='mt-4'>
            <Dropdown
              label='Please select the reason for returning the proposal'
              width='100%'
              placeholder='Select a reason'
              required
              value={selectedReason}
              options={reasonOptions}
              onChange={(option: any) => setSelectedReason(option)}
            />
          </div>
          {selectedReason?.label === 'Others' ? (
            <div className='mt-4'>
              <MultiLineTextArea
                label='Return Reason'
                width='100%'
                inputProps={{
                  value: reasonMessage,
                  onChange: (e) => setReasonMessage(e.target.value),
                  placeholder: 'Reason (optional)...',
                }}
              />
            </div>
          ) : null}
        </>
      </Modal>
    </>
  )
}

export default ReturnProposalModal
