import React, { useEffect } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

type OnboardingCallIntroProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
}

const OnboardingCallIntro: React.FC<OnboardingCallIntroProps> = ({
  isNextClicked,
  setIsNextClicked,
}) => {
  const user = useSelector((state: RootState) => state.user)
  const context = useSelector((state: RootState) => state.context)
  const SCHEDULE_CALL_URL =
    'https://calendly.com/ks--55/fabric-marketplace-introductory-call-sandbox'

  /**
   * When Schedule Call clicked,
   * @param user data from redux
   * @param brand data from redux
   * @param services data from redux
   * @returns url.href
   */
  const getUrl = () => {
    const url = new URL(SCHEDULE_CALL_URL)
    // add easy fields
    url.searchParams.append('name', user.user.fullName)
    url.searchParams.append('email', user.user.email)

    // need to re-do this part - context has tenant details
    // url.searchParams.append('a1', context.brand.name)
    // construct the info field...
    let info = ''
    // if (
    //   context.brand.metadata !== null &&
    //   'ob_marketplace_experience' in context.brand.metadata
    // ) {
    //   info +=
    //     '- Experience: ' +
    //     context.brand.metadata.ob_marketplace_experience.values[0].value
    // }
    // if (
    //   context.brand.metadata !== null &&
    //   'ob_integration_plan' in context.brand.metadata
    // ) {
    //   info +=
    //     '\n- Integration: ' +
    //     context.brand.metadata.ob_integration_plan.values[0].value
    // }
    if (info) {
      url.searchParams.append('a2', info)
    }
    return url.href
  }

  useEffect(() => {
    if (!isNextClicked) {
      return
    }
    setIsNextClicked(false)
    window.open(getUrl(), '_self')
  }, [isNextClicked])

  return (
    <Card
      open={true}
      showCollapse={false}
      showDivider={false}
      headerContent={
        <CardHeader h1Text={ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_INTRO.NAME} />
      }
      bodyContent={
        <>
          <p className='body1-regular'>
            During our introductory call, we will discuss all aspects of the
            dropship lifecycle. In order to have the most efficient call, please
            include the following team members:
          </p>
          <ul>
            <li className='body1-regular'>
              A product data specialist to discuss product & inventory
              attributes
            </li>
            <li className='body1-regular'>
              A fulfillment specialist to discuss shipping
            </li>
            <li className='body1-regular'>
              An accounting specialist to discuss invoicing
            </li>
          </ul>
          <p className='body1-regular'>
            If you suspect you might being doing a more technical integration
            like EDI or API, please feel free to include any technical or
            operations representatives that might have questions!
          </p>
        </>
      }
    />
  )
}

export default OnboardingCallIntro
