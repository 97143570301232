import React, { useState, useEffect } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { GridCol, GridRow, Card, Box, Dropdown, Banner } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { deleteShopifyWebhook, registerShopifyWebhook } from 'api/shopify'
import { dropDownInitialObj } from 'lib/utils/initialValue'


type ChildProps = {
  loading: boolean
  loadWebhooks: (quiet?: boolean) => void
  webhooks: any
}

const ReceiveOrdersCard: React.FC<ChildProps> = ({
  loading,
  loadWebhooks,
  webhooks
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [toggleValue, setToggleValue] = useState('0')
  const [selectedTopic, setSelectedTopic] =
    useState(dropDownInitialObj)
  const [showSelectedTopic, setShowSelectedTopic] = useState(false)

  const topics = [{
    label: 'Order Paid (Recommended)',
    id: 'orders/paid',
    value: 'Push orders as soon as Shopify confirms the order is paid.'
  }, {
    label: 'Order Created',
    id: 'orders/create',
    value: 'Push orders as soon as they are created in Shopify.'
  }]

  useEffect(() => {
    if (!loading && webhooks.length > 0) {
      const selectedTopics = webhooks
        .filter(el => ['orders/paid', 'orders/create'].includes(el.topic))
        .map(webhook => ({
          id: webhook.id,
          label: webhook.topic === 'orders/paid' ? 'Order Paid' : 'Order Created',
          value: webhook.platform_identifier,
        }))
      if (selectedTopics.length > 0) {
        setToggleValue('1')
        setSelectedTopic(selectedTopics[0])
        setShowSelectedTopic(true)
      }
    }
  }, [!loading, webhooks])

  const updateNotification = async (updatedValue) => {
    try {
      const toastMessage = updatedValue === '1' ? 'Sync enabled successfully!' : 'Sync disabled successfully!'
      if (updatedValue === '1') {
        await enableWebhook()
      } else {
        await disableWebhook()
        setSelectedTopic(dropDownInitialObj)
        setShowSelectedTopic(false)
      }
      showToast({
        label: toastMessage,
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data ? response.data : 'An error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      loadWebhooks()
    }
  }

  const enableWebhook = async () => {
    const body = {
      webhook_topic: selectedTopic.id
    }
    await registerShopifyWebhook({ platformAccountId: id, body })
  }

  const disableWebhook = async () => {
    await deleteShopifyWebhook({ platformAccountId: id, webhookId: selectedTopic.id })
  }

  const SelectWebhook = () => (
    <div className='mt-4'>
      <GridRow padding={false}>
        <GridCol lg={12} md={12} sm={12}>
          <Dropdown
            width='100%'
            label='Select Webhook'
            placeholder='Select a topic'
            required={true}
            value={selectedTopic}
            onChange={(option) => setSelectedTopic(option)}
            options={topics}
          />
        </GridCol>
      </GridRow>
    </div>
  )

  const ShowSelectedWebhook = () => (
    <div className='mt-4'>
      <Banner
        label={`${selectedTopic.label} - This integration is currently enabled via webhook id ${selectedTopic.id} (Shopify ID: ${selectedTopic.value})`}
        variant='success'
      />
    </div>
  )

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Orders</span>}
            h2Text={
              <span className='body2-regular'>
                Configure how your Shopify store syncs orders with fabric.
              </span>
            }
            switchProps={{
              label: '',
              stateLabels: {
                OFF: 'Off',
                ON: 'On',
              },
              disabled: !selectedTopic.id,
              checked: toggleValue === '1',
              onChange: ({ target: { checked } }) => {
                const value = checked ? '1' : '0'
                setToggleValue(value)
                updateNotification(value)
              },
            }}
            dataTestid='shopify-orders-header'
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            When you enable a Shopify orders webhook, fabric will
            automatically import customer orders that contain items
            from your approved suppliers and distribute purchase orders to suppliers via
            their preferred integration method (Console, EDI, API, etc). When suppliers ship items, fulfillments and tracking numbers will be
            automatically synced back to the original customer order in your
            Shopify store account.
          </div>
          {!showSelectedTopic ? <SelectWebhook /> : <ShowSelectedWebhook />}
        </Box>
      }
    />
  )
}

export default ReceiveOrdersCard
