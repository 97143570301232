import API, { API_URL } from 'api'

export const getInventories = async ({ params }) => {
  return await API.get(API_URL.GET_INVENTORIES(), { params })
}

export const getInventory = async ({ variantId }) => {
  return await API.get(API_URL.GET_INVENTORY({ id: variantId }))
}

export const getInventoryHistory = async ({ variantId }) => {
  return await API.get(API_URL.GET_INVENTORY_HISTORY({ id: variantId }))
}

export const exportInventory = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_INVENTORY(), { params })
}

export const getInventorySummary = async ({ params = {} }) => {
  return await API.get(API_URL.GET_INVENTORY_SUMMARY(), { params })
}
