import React, { useState, useEffect } from 'react'
import { getTenantType } from 'api/helper'
import { Box, GridCol, GridRow, Table } from 'atoms'
import { getConnectionLocation } from 'api/connectionLocation'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'

type CarrierDetailsProps = {
  details: any
  loading: boolean
  reload?: () => {}
}

const LocationDetails: React.FC<CarrierDetailsProps> = ({
  details,
  loading,
  reload,
}) => {
  const [locationsTableData, setLocationsTableData] = useState<any[]>([])
  const [loadingLocations, setLoadingLocations] = useState(true)

  const { isRetailer } = getTenantType()
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      fetchLocations()
    }
  }, [id])

  const fetchLocations = async () => {
    const { data } = await getConnectionLocation({
      params: { connection_id: id, order_by: '-id', page: 1, limit: 12 },
    })
    if (data.count > 0) {
      formatData(data)
    }
    setLoadingLocations(false)
  }

  const formatData = ({ results }) => {
    if (isRetailer) {
      let tableData = results?.map((location) => ({
        id: location.id,
        data: {
          ID: { value: location.id },
          Supplier: { value: location?.brand?.name },
          Type: { value: capitalize(location?.type) },
          Address: {
            value: location?.address?.street2
              ? `${location?.address?.street1}, ${location?.address?.street2}`
              : location?.address?.street1,
          },
          City: { value: location?.address?.city },
          Province: { value: location?.address?.province },
          Country: { value: location?.address?.country },
        },
      }))
      setLocationsTableData(tableData)
    }
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'ID',
      width: '8%',
    },
    {
      sortable: false,
      title: 'Supplier',
      width: '20%',
    },
    {
      sortable: false,
      title: 'Type',
      width: '12%',
    },
    {
      sortable: false,
      title: 'Address',
      width: '20%',
    },
    {
      sortable: false,
      title: 'City',
      width: '10%',
    },
    {
      sortable: false,
      title: 'Province',
      width: '10%',
    },
    {
      sortable: false,
      title: 'Country',
      width: '10%',
    },
  ]

  return (
    <Box padding={{ top: 5, bottom: 4 }}>
      <GridRow padding={false}>
        <GridCol>
          <Table
            columns={tableColumns}
            data={locationsTableData}
            loading={loadingLocations}
            emptyTableText='No records found!'
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default LocationDetails
