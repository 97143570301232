import { createSlice } from '@reduxjs/toolkit'
import { ONBOARDING_TASKS } from 'modules/onboarding/constants'

const initialState = {
  connectionId: '',
  retailerName: '',
  currentStep: {
    value: ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.TASK_NAME,
    parent: ONBOARDING_TASKS.QUESTIONNAIRE.VALUE,
  },
  nextAvaiableStep: '',
  questionnaire: {
    taskId: '',
    processNum: 0,
    experienceLevel: '',
    integrationMethod: '',
    completed: false,
  },
  call: {
    taskId: '',
    processNum: 0,
    completed: false,
  },
  info: {
    taskIds: {
      [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: '',
      [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: '',
      [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: '',
    },
    processNum: 0,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: false,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: false,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: false,
    completed: false,
  },
  notifications: {
    taskId: '',
    processNum: 0,
    transactionDone: false,
    digestDone: false,
    completed: false,
  },
  integration: {
    taskId: '',
    selectedIntegrationInfo: {
      taskIds: {
        ['self-serve-edi']: '',
        bigcommerce: '',
        shipstation: '',
      },
      variants: [],
      orderIds: [],
    },
    processNum: 0,
    introDone: false,
    completed: false,
    selectedMethod: '',
  },
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setConnectionId(state, action) {
      state.connectionId = action.payload
    },
    setRetailerName(state, action) {
      state.retailerName = action.payload
    },
    setNextAvaiableStep(state, action) {
      state.nextAvaiableStep = action.payload
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setQuestionnaire(state, action) {
      state.questionnaire = action.payload
    },
    setCall(state, action) {
      state.call = action.payload
    },
    setInfo(state, action) {
      state.info = action.payload
    },
    setNotifications(state, action) {
      state.notifications = action.payload
    },
    setIntegration(state, action) {
      state.integration = action.payload
    },
  },
})

export const {
  setConnectionId,
  setRetailerName,
  setNextAvaiableStep,
  setCurrentStep,
  setQuestionnaire,
  setCall,
  setInfo,
  setNotifications,
  setIntegration,
} = onboardingSlice.actions

export default onboardingSlice.reducer
