import React, { useEffect, useState } from 'react'
import Badge from 'atoms/badge/badge'

type ChildProps = {
  status: string
  acknowledgedAt: string
}

const ProposalBadgeStatus: React.FC<ChildProps> = ({
  status,
  acknowledgedAt,
}) => {
  const [label, setLabel] = useState('')
  const [badgeStatus, setBadgeStatus] = useState('')

  useEffect(() => {
    setProposalStatus()
  }, [status, acknowledgedAt])

  const setProposalStatus = () => {
    if (status === 'completed') {
      if (acknowledgedAt === null) {
        setLabel('Ready to Add')
        setBadgeStatus('info')
      } else {
        setLabel('Completed')
        setBadgeStatus('success')
      }
      return
    }
    switch (status) {
      case 'proposed':
        setLabel('Proposed')
        setBadgeStatus('alert')
        return
      case 'revised':
        setLabel('Revised')
        setBadgeStatus('info')
        return
      case 'approved':
        setLabel('Approved')
        setBadgeStatus('alert')
        return
      case 'pricing_approved':
        setLabel('Pending Approval')
        setBadgeStatus('info')
        return
      case 'declined':
        setLabel('Declined')
        setBadgeStatus('error')
        return
      case 'archived':
        setLabel('Archived')
        setBadgeStatus('default')
        return
      default:
        setLabel('Draft')
        setBadgeStatus('default')
        return
    }
  }

  return <Badge label={label} variant='primary' status={badgeStatus} />
}

export default ProposalBadgeStatus
