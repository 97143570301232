import React, { useEffect, useState } from 'react'
import Badge from 'atoms/badge/badge'
import ToolTip from 'atoms/tooltip/tooltip'

type ChildProps = {
  acknowledged: boolean
}

const MerchantStatus: React.FC<ChildProps> = ({ acknowledged }) => {
  const [label, setLabel] = useState('')
  const [statusVariant, setStatusVariant] = useState('')
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    setMerchantStatus()
  })

  const setMerchantStatus = () => {
    let label = 'Sent'
    let badgeStatus = 'info'
    let tooltip = 'Merchant has access to this offer.'
    if (acknowledged) {
      label = 'Acknowledged'
      badgeStatus = 'success'
      tooltip = 'Merchant has acknowledged receipt of this offer.'
    }
    setLabel(label)
    setStatusVariant(badgeStatus)
    setTooltipText(tooltip)
  }
  return (
    <ToolTip text={tooltipText}>
      <div className='hover-cursor'>
        <Badge label={label} variant='primary' status={statusVariant} />
      </div>
    </ToolTip>
  )
}

export default MerchantStatus
