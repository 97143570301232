import { FC, useEffect, useState, useCallback } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from '../../../../../atoms/modal/modal'
import ShipmentDetail from '../../modal/orders/shipmentDetails'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import PackingSlip from '../../modal/tracking/packingSlip'
import ItemDetail from '../../modal/ItemDetail'
import TrackingCol from './parts/TrackingCol'
import POCol from '../order/parts/POCol'
import TrackCol from './parts/TrackCol'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/shipmentsSlice'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const TrackingTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [displayTrack, setDisplayTrack] = useState(false)
  const [displayPackingSlip, setPackingSlip] = useState(false)
  const [poModal, setPOModal] = useState(false)
  const [shipmentId, setShipmentId] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [hasValidPackingSlip, setHasValidPackingSlip] = useState(false)
  const [orderId, setOrderId] = useState('')

  const TrackingComponent = useCallback(
    ({ tracking_number, onClick }) => (
      <TrackingCol tracking_number={tracking_number} onClick={onClick} />
    ),
    []
  )
  const POComponent = useCallback(
    ({ purchase_order_number, onClick }) => (
      <POCol purchase_order_number={purchase_order_number} onClick={onClick} />
    ),
    []
  )
  const TrackComponent = useCallback(
    ({ tracking_url }) => <TrackCol tracking_url={tracking_url} />,
    []
  )

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props.loading])

  const getFooterButtons = (hasValidPackingSlip) => {
    // if no valid packing slip, only show the Close button
    let footerButtons = [
      {
        dataTestid: '',
        onClick: () => setDisplayTrack(false),
        text: 'Close',
        variant: 'secondary',
      },
    ]
    if (hasValidPackingSlip) {
      footerButtons.push({
        dataTestid: '',
        onClick: () => setPackingSlip(true),
        text: 'View packing slip',
        variant: 'primary',
      })
    }
    return footerButtons
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Service',
    },
    {
      sortable: true,
      title: 'Tracking #',
    },
    {
      sortable: false,
      title: 'PO #',
    },
    {
      sortable: true,
      title: 'Merchant',
    },
    {
      sortable: false,
      title: 'Registered',
    },
    {
      sortable: true,
      title: 'Acknowledged',
    },
    {
      sortable: false,
      title: 'Track',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        shipping_method,
        order_id,
        tracking_number,
        purchase_order_number,
        retailer,
        shipped_at,
        acknowledged_at,
        tracking_url,
        valid_packing_slip,
      }) => {
        return {
          id,
          valid_packing_slip,
          data: {
            'Service': { value: shipping_method?.name },
            'Tracking #': {
              onRender: () =>
                TrackingComponent({
                  tracking_number,
                  onClick: () => {
                    setDisplayTrack(true)
                    setShipmentId(id)
                    setHasValidPackingSlip(valid_packing_slip)
                  },
                }),
            },
            'PO #': {
              onRender: () =>
                POComponent({
                  purchase_order_number,
                  onClick: () => {
                    setOrderNumber(purchase_order_number)
                    setOrderId(order_id)
                    setPOModal(true)
                  },
                }),
            },
            'Merchant': { value: retailer?.name },
            'Registered': { value: formatDate({ date: shipped_at }) },
            'Acknowledged': { value: formatDate({ date: acknowledged_at }) },
            'Track': { onRender: () => TrackComponent({ tracking_url }) },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <div>
      {/* Modal Shipment Detail */}
      <Modal
        headerText='Shipment Details'
        description=''
        size='small'
        onClose={() => setDisplayTrack(false)}
        isVisible={displayTrack}
        footerButtons={getFooterButtons(hasValidPackingSlip)}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <ShipmentDetail shipmentId={shipmentId} />
      </Modal>
      {/* Modal PO */}
      <Modal
        headerText='Order Details'
        description=''
        size='small'
        onClose={() => setPOModal(false)}
        isVisible={poModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setPOModal(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <ItemDetail orderId={orderId} orderNumber={orderNumber} />
      </Modal>
      {/* Modal Packing Slip */}
      <Modal
        headerText='Packing Slip'
        size='small'
        description=''
        onClose={() => setPackingSlip(false)}
        isVisible={displayPackingSlip}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setPackingSlip(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <PackingSlip shipmentId={shipmentId} />
      </Modal>
      <Table
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        selectable={false}
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </div>
  )
}

export default TrackingTable
