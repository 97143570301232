import React from 'react'
import Search from 'atoms/search/search'
import { SearchVariant } from '@teamfabric/copilot-ui'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'

type ChildProps = {
  placeholder?: string
  setSearch: any
  variant?: SearchVariant
  showResult?: boolean
  className?: string
  value?: string
  width?: string
  dataTestid?: string
  target?: string
}

const SearchBar: React.FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  return (
    <div className={props?.className}>
      <Search
        placeholder={props.placeholder}
        onSearch={(searchText) => {
          if (props.target) {
            dispatch(
              props.setSearch({
                target: props.target,
                value: searchText,
              })
            )
          } else {
            dispatch(props.setSearch(searchText))
          }
        }}
        onClear={() => {
          if (props.target) {
            dispatch(
              props.setSearch({
                target: props.target,
                value: '',
              })
            )
          } else {
            dispatch(props.setSearch(''))
          }
        }}
        onChange={debounce((searchText) => {
          if (props.target) {
            dispatch(
              props.setSearch({
                target: props.target,
                value: searchText,
              })
            )
          } else {
            dispatch(props.setSearch(searchText))
          }
        }, 1000)}
        variant={props.variant || SearchVariant.FIXED_GLOBAL}
        showResult={props.showResult ? props.showResult : false}
        value={props.value}
        width={props.width || '433px'}
        dataTestid={props.dataTestid}
      />
    </div>
  )
}

export default SearchBar
