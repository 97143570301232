import { FC, useMemo } from 'react'

interface NetPriceMethodProps {
  connection: {
    connection_fees: {
      net_price_method: string
      revshare_fraction: string
    }
    commission_profile: {
      name: string
    } | null
  }
  showTooltip?: boolean
}

const NetPriceMethodPresenter: FC<NetPriceMethodProps> = ({ connection }) => {
  const revsharePct = useMemo(() => {
    if (connection?.connection_fees?.net_price_method === 'revshare') {
      return parseFloat(connection?.connection_fees?.revshare_fraction) * 100
    }
    return null
  }, [connection.connection_fees])

  const commissionProfileName = useMemo(() => {
    if (
      !connection?.connection_fees?.net_price_method &&
      connection.commission_profile
    ) {
      return connection?.commission_profile?.name
    }
    return null
  }, [connection.connection_fees, connection.commission_profile])

  const presenter = useMemo(() => {
    const presenter: { label: string; tooltip: string } = {
      label: '',
      tooltip: '',
    }
    const commissionProfile = connection?.commission_profile
    switch (connection?.connection_fees?.net_price_method) {
      case 'permit_cost':
        presenter.label = 'Permit Cost'
        presenter.tooltip =
          'Item cost is determined by the most recently accepted proposal.'
        break
      case 'revshare':
        presenter.label = `Commission: ${revsharePct}%`
        presenter.tooltip =
          'Item cost is determined by a commission applied to the retail price.'
        break
      default:
        if (
          !connection?.connection_fees?.net_price_method &&
          commissionProfile
        ) {
          presenter.label = `Commission: ${commissionProfileName}`
          presenter.tooltip =
            'Item cost is determined by a commission applied to the retail price.'
        } else {
          presenter.label = 'Specified on Order'
          presenter.tooltip =
            'The retailer is responsible for tracking and passing item costs for each purchase order.'
        }
    }

    return presenter
  }, [
    connection.connection_fees,
    revsharePct,
    connection.commission_profile,
    commissionProfileName,
  ])

  return <span>{presenter.label}</span>
}

export default NetPriceMethodPresenter
