import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/permitsSlice'
import VariantItem from 'molecules/lineItem/variantItem'
import { formatDate } from 'lib/utils/date/formatDate'
import InspectOffer from '../modals/inspectOffer'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const OffersTable: FC<ChildProps> = (props) => {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [displayInspectOffer, setDisplayInspectOffer] = useState(false)
  const [permitId, setPermitId] = useState('')

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      title: 'SKU',
    },
    {
      title: 'Merchant',
    },
    {
      title: 'Cost',
      width: '10%',
    },
    {
      title: 'Starts',
      width: '10%',
    },
    {
      title: 'Ends',
      width: '10%',
    },
    {
      title: 'Ack?',
      width: '10%',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      (
        {
          id,
          variant,
          retailer,
          retailer_cost,
          start_at,
          end_at,
          acknowledged_at,
        },
        key
      ) => {
        return {
          id: key,
          data: {
            'SKU': {
              onRender: () =>
                VariantItem({
                  variant,
                  onClick: () => {
                    setPermitId(id)
                    setDisplayInspectOffer(true)
                  },
                  hideImage: true,
                }),
            },
            'Merchant': { value: retailer?.name },
            'Cost': { value: retailer_cost ? retailer_cost : 'External' },
            'Starts': {
              value: start_at ? formatDate({ date: start_at }) : 'Now',
            },
            'Ends': { value: end_at ? formatDate({ date: end_at }) : 'Never' },
            'Ack?': { value: acknowledged_at ? 'Yes' : 'No' },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <>
      <Modal
        headerText='Inspect Offer'
        description=''
        size='small'
        onClose={() => setDisplayInspectOffer(false)}
        isVisible={displayInspectOffer}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setDisplayInspectOffer(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <InspectOffer permitId={permitId} />
      </Modal>
      <Table
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        selectable={false}
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
      />
    </>
  )
}

export default OffersTable
