import React, { useState, useEffect } from 'react'
import Modal from 'atoms/modal/modal'
import Banner from 'atoms/banner/banner'
import { Card, Table } from 'atoms'
import Card2Col from 'molecules/cards/2Col'
import { formatDate } from 'lib/utils/date/formatDate'
import { getShopifyLookupOrder } from 'api/shopify'
import { getTenantType } from 'api/helper'

type PlatformOrderModalProps = {
  isVisible: boolean
  setDisplaySkipModal: (value: boolean) => void
  loading: boolean
  platformAccounts: any
  details: any
}

const PlatformOrderModal: React.FC<PlatformOrderModalProps> = ({
  isVisible,
  setDisplaySkipModal,
  loading,
  platformAccounts,
  details,
}) => {
  const [orderShopifyDetails, setOrderShopifyDetails] = useState([])
  const [orderLines, setOrderLines] = useState([])
  const { isRetailer } = getTenantType()

  const setShopifyData = (platformOrder) => {
    const data = [
      {
        label: 'Platform',
        value: platformAccounts[0]?.platform?.name || '',
      },
      {
        label: 'Shopify Order ID',
        value: platformOrder?.id,
      },
      {
        label: 'Shopify Order Number',
        value: platformOrder?.order_number,
      },
      {
        label: 'Order Date',
        value: formatDate({ date: platformOrder?.created_at }),
      },
      {
        label: 'Tags',
        value: platformOrder?.tags?.map(({ tag }) => tag).join(', '),
      },
    ]
    setOrderShopifyDetails(data)
  }

  const orderLineColumns = [
    {
      sortable: false,
      title: 'Item',
    },
    {
      sortable: false,
      title: 'Qty',
      width: 120,
    },
    {
      sortable: false,
      title: 'Unit cost',
      width: 120,
    },
  ]

  const setOrderlineData = ({ line_items }) => {
    const formattedData = line_items?.map(
      ({ id, name, sku, fulfillable_quantity, variant_id, price }) => {
        return {
          id,
          data: {
            'Item': {
              onRender: () => (
                <>
                  <p className='mb-1'>{name}</p>
                  <p className='m-0 body2-regular grey-500 mb-1'>
                    SKU {sku || '- Not Set -'}
                  </p>
                  <p className='m-0 body2-regular grey-500 mb-1'>
                    Variant ID: {variant_id}
                  </p>
                  <p className='m-0 body2-regular grey-500 mb-2'>
                    Line ID: {id}
                  </p>
                </>
              ),
            },
            'Qty': {
              value: fulfillable_quantity,
            },
            'Unit cost': {
              value: price,
            },
          },
        }
      }
    )
    setOrderLines(formattedData)
  }

  const loadPlatformOrder = async () => {
    const orderNumber = isRetailer
      ? details?.retailer_order_number
      : details?.brand_identifier
    if (orderNumber) {
      const { data } = await getShopifyLookupOrder({
        id: platformAccounts[0]?.id,
        params: { order_number: orderNumber },
      })
      setShopifyData(data)
      setOrderlineData(data)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!loading) {
        if (platformAccounts !== undefined) {
          await loadPlatformOrder()
        }
      }
    })()
  }, [loading, platformAccounts])

  return (
    <Modal
      headerText='Platform Order'
      description={
        <>
          <Banner
            label='The data below represents this order as it exists in Shopify.'
            variant='info'
          />
          <Card2Col
            loading={loading}
            header='Order Details'
            data={orderShopifyDetails}
            skeletonRowsNumber={5}
            showCollapse={false}
            customClassName='mt-4 mb-4'
            open
          />
          <Table
            columns={orderLineColumns}
            data={orderLines}
            loading={loading}
            selectable={false}
          />
          {/* TODO Missing Design*/}
          <div className='mt-4 mb-4'>
            <Card headerContent='Fulfillments' />
          </div>
          {/* TODO Missing Design*/}
          <Card headerContent='Cancellations & Refunds' />
        </>
      }
      onClose={() => setDisplaySkipModal(false)}
      isVisible={isVisible}
      size='medium'
      footerButtons={[
        {
          onClick: () => setDisplaySkipModal(false),
          text: 'Close',
          variant: 'secondary',
        },
        {
          // TODO Missing Design
          onClick: () => {},
          text: 'Update Order Line',
          variant: 'primary',
        },
      ]}
    />
  )
}

export default PlatformOrderModal
