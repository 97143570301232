import React, { useState, useEffect, useMemo } from 'react'
import { Box, Input, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectAttribute from 'molecules/selectDropdowns/selectAttribute'
import { useParams } from 'react-router-dom'
import {
  createPlatformMappingsByFieldType,
  updatePlatformMappingsByFieldType,
} from 'api/templates'
import SelectTemplateMappingsTarget from 'molecules/selectDropdowns/selectTemplateMappingsTarget'
import SelectTemplateMetafieldType from 'molecules/selectDropdowns/selectTemplateMetafieldType'

interface BaseProps {
  showModal: boolean
  closeModal: () => void
  type: 'Edit' | 'Add'
  loadMappings: (quiet?: boolean) => void
  fieldType: 'mappings' | 'metafield-mappings' | 'tag-mappings'
}

type ChildProps = BaseProps &
  (
    | { type: 'Add'; selectedMapping?: never }
    | { type: 'Edit'; selectedMapping: any }
  )

const MappingModal: React.FC<ChildProps> = ({
  showModal,
  closeModal,
  loadMappings,
  type,
  selectedMapping,
  fieldType,
}) => {
  const { id, platform, templateId } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [selectedAttribute, setSelectedAttribute] = useState(dropDownInitialObj)
  const [shopifyAttributeName, setShopifyAttributeName] = useState('')
  const [prefixValue, setPrefixValue] = useState('')
  const [metafieldNamespace, setMetafieldNamespace] = useState('')
  const [metafieldKey, setMetafieldKey] = useState('')
  const [selectedTarget, setSelectedTarget] = useState(dropDownInitialObj)
  const [description, setDescription] = useState('')
  const [selectedMetafieldType, setSelectedMetafieldType] =
    useState(dropDownInitialObj)

  useEffect(() => {
    if (type === 'Edit' && selectedMapping) {
      setShopifyAttributeName(selectedMapping?.title)
      setSelectedAttribute(selectedMapping?.attribute)
      setPrefixValue(selectedMapping?.title)
      setMetafieldNamespace(selectedMapping?.namespace)
      setMetafieldKey(selectedMapping?.key)
      setDescription(selectedMapping?.description)
    }
  }, [selectedMapping, type])

  const onCreate = async () => {
    try {
      await createPlatformMappingsByFieldType({
        platformCode: platform,
        platformId: id,
        templateId: templateId,
        fieldType,
        body,
      })
      showToast({
        label: 'Attribute mapping created successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const onUpdate = async () => {
    const fieldTypeId = selectedMapping.id
    try {
      await updatePlatformMappingsByFieldType({
        platformCode: platform,
        platformId: id,
        templateId: templateId,
        fieldType,
        fieldTypeId,
        body,
      })
      showToast({
        label: 'Attribute mapping updated successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const onSave = async () => {
    setSaving(true)
    try {
      if (type === 'Edit') {
        await onUpdate()
      } else {
        await onCreate()
      }
    } finally {
      closeModal()
      loadMappings()
      resetFields()
    }
  }

  const resetFields = () => {
    setShopifyAttributeName('')
    setMetafieldNamespace('')
    setMetafieldKey('')
    setDescription('')
    setPrefixValue('')
  }

  const isReady = useMemo(() => {
    if (fieldType === 'mappings') {
      return shopifyAttributeName !== '' && selectedAttribute?.id
    } else if (fieldType === 'tag-mappings') {
      return prefixValue !== '' && selectedAttribute?.id
    } else if (fieldType === 'metafield-mappings') {
      return (
        selectedAttribute?.id &&
        metafieldNamespace !== '' &&
        metafieldKey !== '' &&
        selectedMetafieldType?.value &&
        selectedTarget?.value
      )
    }
  }, [
    shopifyAttributeName,
    selectedAttribute,
    fieldType,
    prefixValue,
    metafieldNamespace,
    metafieldKey,
    selectedMetafieldType,
    selectedTarget,
  ])

  const body = useMemo(() => {
    if (isReady) {
      let data = {
        attribute: { id: selectedAttribute.id },
        description: description,
      }
      if (fieldType === 'mappings') {
        data['title'] = shopifyAttributeName
      }

      if (fieldType === 'mappings' && platform === 'fabric') {
        data['title'] = shopifyAttributeName
        data['target'] = selectedTarget?.value
      }

      if (fieldType === 'tag-mappings') {
        data['title'] = prefixValue
      }
      if (fieldType === 'metafield-mappings') {
        data['namespace'] = metafieldNamespace
        data['key'] = metafieldKey
        data['type'] = selectedMetafieldType?.value
        data['target'] = selectedTarget?.value
      }
      return data
    }
  }, [
    isReady,
    description,
    shopifyAttributeName,
    selectedAttribute,
    fieldType,
    prefixValue,
    metafieldNamespace,
    metafieldKey,
    selectedMetafieldType,
    selectedTarget,
  ])

  return (
    <Modal
      headerText={`${type} Template Mapping`}
      description=''
      size='small'
      onClose={() => {
        closeModal()
        resetFields()
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            closeModal()
            resetFields()
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: 'Save',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ].filter(Boolean)}
      dataTestid={`template-${fieldType}-modal`}
      onBackdropClick={() => {
        resetFields()
        closeModal()
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <SelectAttribute
          handleUpdate={(option) => {
            setSelectedAttribute(option)
          }}
          fieldLabel='fabric Marketplace attribute'
          selectedAttributeId={selectedMapping?.attribute.id}
        />
        {fieldType === 'mappings' && (
          <Input
            width='100%'
            label='Shopify Attribute'
            required
            inputProps={{
              value: shopifyAttributeName,
              onChange: ({ target: { value } }) =>
                setShopifyAttributeName(value),
              dataTestid: 'shopify-attribute-name',
            }}
          />
        )}
        {fieldType === 'tag-mappings' && (
          <Input
            width='100%'
            label='Prefix'
            required
            inputProps={{
              value: prefixValue,
              onChange: ({ target: { value } }) => setPrefixValue(value),
              dataTestid: 'shopify-tag-mapping',
            }}
          />
        )}
        {fieldType === 'metafield-mappings' && (
          <Input
            width='100%'
            label='Metafield Namespace'
            required
            inputProps={{
              value: metafieldNamespace,
              onChange: ({ target: { value } }) => setMetafieldNamespace(value),
              dataTestid: 'metafield-mappings-namespace',
            }}
          />
        )}
        {fieldType === 'metafield-mappings' && (
          <Input
            width='100%'
            label='Metafield Key'
            required
            inputProps={{
              value: metafieldKey,
              onChange: ({ target: { value } }) => setMetafieldKey(value),
              dataTestid: 'metafield-mappings-key',
            }}
          />
        )}
        {fieldType === 'metafield-mappings' && (
          <SelectTemplateMetafieldType
            handleUpdate={(option) => {
              setSelectedMetafieldType(option)
            }}
            fieldLabel='Metafield Type'
            typeValue={selectedMapping?.type}
          />
        )}

        <Input
          width='100%'
          label='Description'
          inputProps={{
            value: description,
            onChange: ({ target: { value } }) => setDescription(value),
            dataTestid: 'mapping-description',
          }}
        />
        {['metafield-mappings', 'mappings'].includes(fieldType) && (
          <SelectTemplateMappingsTarget
            handleUpdate={(option) => {
              setSelectedTarget(option)
            }}
            isRequired={fieldType === 'metafield-mappings'}
            fieldLabel={
              fieldType === 'metafield-mappings' ? 'Metafield target' : 'Target'
            }
            targetValue={selectedMapping?.target}
          />
        )}
      </Box>
    </Modal>
  )
}

export default MappingModal
