import { getConnectionDetail } from 'api/connection'
import { FC, useEffect, useState } from 'react'
import Card2Col from 'molecules/cards/2Col'
import Card1Column from 'molecules/cards/1column'
import { formatDate } from 'lib/utils/date/formatDate'

type ChildProps = {
  connectionId: string
}

const ConnectionDetails: FC<ChildProps> = (props) => {
  const [connectionInfoData, setConnectionInfoData] = useState<any[]>([])
  const [connectionOptionsData, setConnectionOptionsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { data } = await getConnectionDetail({ id: props.connectionId })
      formatConnectionData(data)
      setLoading(false)
    })()
  }, [])

  const getIntegration = (integrationType) => {
    let integrationLabel = 'Console'
    if (integrationType === 'edi') {
      integrationLabel = 'EDI'
    } else if (integrationType === 'storefront') {
      integrationLabel = 'Storefront'
    } else if (integrationType === 'api') {
      integrationLabel = 'API'
    }
    return integrationLabel
  }

  const formatConnectionData = ({
    retailer,
    // brand,
    id,
    created_at,
    integration_type,
    options,
  }) => {
    // TODO: check if user supplier when context available, otherwise provide supplier object here
    const partner = {
      label: 'Merchant',
      id: retailer?.id,
      name: retailer?.name,
    }

    // TODO: use if user merchant
    // const partner = {
    //   label: 'Supplier',
    //   id: brand?.id,
    //   name: brand?.name
    // }

    const formattedInfoData = [
      {
        label: `${partner.label} Name`,
        value: partner.name,
      },
      {
        label: `${partner.label} ID`,
        value: partner.id,
      },
      {
        label: 'Connection ID',
        value: id,
      },
      {
        label: 'Integration',
        value: getIntegration(integration_type),
      },
      {
        label: 'Established',
        value: formatDate({ date: created_at }),
      },
    ]

    let formattedOptionsData = Object.values(options).map(
      (option: { name: string }) => option?.name
    )

    setConnectionInfoData(formattedInfoData)
    setConnectionOptionsData(formattedOptionsData)
  }

  return (
    <>
      <Card2Col
        loading={loading}
        showCollapse={false}
        header='Connection Info'
        data={connectionInfoData}
        customClassName='mb-4'
      />
      <Card1Column
        loading={loading}
        showCollapse={false}
        header='Connection Options'
        data={connectionOptionsData}
      />
    </>
  )
}

export default ConnectionDetails
