import React, { useEffect, useState } from 'react'
import SingleAvatar from 'atoms/avatar/singleAvater'
import { formatDate } from 'lib/utils/date/formatDate'
import { acknowledgeMemo } from 'api/memo'
import { useToast } from '@teamfabric/copilot-ui'
import { Link, Table } from 'atoms'

type PostedMessageProps = {
  memoData: any
  loading: boolean
  orderId: number
  setMemoData: (data: any) => void
  emptyTableText?: string
}

const PostedMessages: React.FC<PostedMessageProps> = ({
  memoData,
  loading,
  orderId,
  setMemoData,
  emptyTableText,
}) => {
  const showToast = useToast()
  const [tableData, setTableData] = useState([])
  const [saving, setSaving] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const tableColumns = [
    {
      sortable: false,
      title: 'User',
      width: 50,
    },
    {
      sortable: false,
      title: 'Created',
      width: 50,
    },
    {
      sortable: false,
      title: 'Note',
      width: 150,
      onRenderCell: (props) => {
        return <div className='longTextLine2'>{props?.value}</div>
      },
    },
    {
      sortable: false,
      title: 'Actions',
      width: 60,
    },
  ]

  const getActionOnRender = ({ id, acknowledged_at }) => {
    return acknowledged_at ? (
      <div>
        <p className='grey-md m-0 mb-1'>Acknowledged on</p>
        <p className='grey-md m-0'>{formatDate({ date: acknowledged_at })}</p>
      </div>
    ) : (
      <Link
        label='Acknowledge'
        onClick={() => acknowledge(id)}
        mode='inline'
        theme='light'
        variant='primary'
        padding={false}
      />
    )
  }

  const formatTableData = (data: any) => {
    const formattedData = data?.map(
      ({ id, created_at, created_by, text, acknowledged_at }) => ({
        id,
        data: {
          User: {
            onRender: () => (
              <SingleAvatar
                size={32}
                label={`${created_by.first_name} ${created_by.last_name}`}
              />
            ),
          },
          Created: {
            value: formatDate({ date: created_at }),
          },
          Note: {
            value: text,
          },
          Actions: {
            onRender: () => getActionOnRender({ id, acknowledged_at }),
          },
        },
      })
    )
    setTableData(formattedData)
  }

  const acknowledge = async (memoId) => {
    try {
      setSaving(true)
      const {
        data: { acknowledged_at },
      } = await acknowledgeMemo({
        body: {
          id: orderId,
          acknowledged_at: new Date().getTime(),
        },
        memo_id: memoId,
      })
      const updatedData = memoData.map((memo) =>
        memo.id === parseInt(memoId) ? { ...memo, acknowledged_at } : memo
      )
      setMemoData(updatedData)
      setSaving(false)
      showToast({
        label: 'Acknowledged Memo',
        isDismissable: true,
        id: 'success-acknowledge',
      })
    } catch (error) {
      showToast({
        label: 'Something went wrong',
        isDismissable: true,
        variant: error,
        id: 'error-acknowledge',
      })
    }
  }

  useEffect(() => {
    if (memoData?.length === 0) {
      return
    }
    formatTableData(memoData)
  }, [memoData])

  const getMemo = () => {
    return tableData.filter(
      (_, index) => index > currentPage * 10 - 11 && index < currentPage * 10
    )
  }

  return (
    <Table
      columns={tableColumns}
      data={getMemo()}
      loading={loading || saving}
      selectable={false}
      emptyTableText={emptyTableText ? emptyTableText : 'You have no results'}
      perPageRecords={10}
      showPagination
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setCurrentPage(pageNumber)
        },
        activePageNumber: currentPage,
        perPage: 10,
        totalRecords: tableData.length,
      }}
    />
  )
}

export default PostedMessages
