import React from 'react'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import { Skeleton } from 'atoms/skeleton'

type ChildProps = {
  loading?: boolean
  data: any[]
  customClassName?: string
  gridSize: number
}
const SelectableCardGrid: React.FC<ChildProps> = (props) => {
  const getLabelClass = (label: string) => {
    let className = 'body2-regular textAlignCenter'
    switch (label) {
      case 'Items Passed':
        className += ' green-700'
        break
      case 'Items Failed':
        className += ' red-700'
        break
      default:
        className += ' grey-700'
        break
    }
    return className
  }

  const RowContent = () => {
    return (
      <GridRow padding={false}>
        {props.data?.map(({ label, value, handleClick }, index) => (
          <GridCol
            sm={props.gridSize}
            md={props.gridSize}
            lg={props.gridSize}
            key={index}
          >
            <div className='hover-cursor' onClick={handleClick}>
              <Card
                showCollapse={false}
                headerContent={<div className='h5 cardTitles'>{value}</div>}
                bodyContent={
                  <div className={getLabelClass(label)}>{label}</div>
                }
              />
            </div>
          </GridCol>
        ))}
      </GridRow>
    )
  }

  const ContentLoading = () => {
    return (
      <GridRow padding={false}>
        {props.data?.map((el, index) => (
          <GridCol
            sm={props.gridSize}
            md={props.gridSize}
            lg={props.gridSize}
            key={index}
          >
            <Card
              showCollapse={false}
              headerContent={<Skeleton height='20px' width='100%' />}
              bodyContent={<Skeleton height='30px' width='100%' />}
            />
          </GridCol>
        ))}
      </GridRow>
    )
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? <ContentLoading /> : <RowContent />}
    </div>
  )
}

export default SelectableCardGrid
