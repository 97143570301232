import { useEffect, useState } from 'react'
import { Box, Button, Card, GridCol, GridRow, Input } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { getSerialKeys, postSerialKeys } from 'api/serialKeys'
import { getInvitePreferences } from 'api/suppliers'
import { postRetailerPreference } from 'api/retailers'

const RetailerSkuSettings: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [toggleValue, setToggleValue] = useState('0')
  const [prefix, setPrefix] = useState('')
  const [seedValue, setSeedValue] = useState(null)
  const [nextValue, setNextValue] = useState(null)
  const [initialSerialKeys, setInitialSerialKeys] = useState([])
  const [preference, setPreference] = useState(null)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Retailer SKU Settings' })
        ),
      0
    )
  }, [])

  const fetchSerialKeys = async () => {
    const { data } = await getSerialKeys()
    setInitialSerialKeys(data.results)
    const option = data.results[0]
    if (typeof option !== 'undefined') {
      setPrefix(option.prefix)
      setSeedValue(option.seed)
      setNextValue(option.next_key)
    }
  }

  const fetchInvitePreferences = async () => {
    const { data } = await getInvitePreferences({
      params: { grouping: 'misc' },
    })
    const option =
      data.results?.length > 0 &&
      data.results.find(
        (el) => el.code === 'automatically_generate_retailer_skus'
      )
    if (typeof option !== 'undefined') {
      setPreference(option)
      if (option.retailer_preference) {
        setToggleValue(option.retailer_preference.value)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await Promise.all([fetchSerialKeys(), fetchInvitePreferences()])
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const updatePreference = async (updatedToggleValue) => {
    const body = {
      preference: {
        id: preference?.id,
      },
      value: updatedToggleValue,
    }
    setSaving(true)
    try {
      await postRetailerPreference(body)
      showToast({
        label: 'Preference updated!',
        isDismissable: true,
        id: '2',
      })
      setSaving(false)
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  const onSave = async () => {
    const body = {
      target_content_type: 'retailer_identifier',
      seed: seedValue,
      prefix: prefix,
    }
    setSaving(true)
    try {
      await postSerialKeys(body)
      showToast({
        label: 'Preference updated!',
        isDismissable: true,
        id: '2',
      })
      setSaving(false)
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Retailer SKU Settings'
        h2Text={
          <>
            This settings configured on this page will be applied to new
            products only; existing products and
            <br />
            items will NOT be modified. Please read and consider each setting
            carefully.
          </>
        }
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving || loading,
          },
        ]}
      />
      <GridRow padding={false}>
        <GridCol lg={12} md={12} sm={12}>
          <Card
            showCollapse={false}
            open
            headerContent={
              <Box width='50%'>
                <PageHeaderComponent
                  h1Text={<span className='h5'>Retailer SKU Assignment</span>}
                  h2Text={
                    <span className='body2-regular'>
                      Enable Retailer SKU assignment if you would like the
                      fabric Marketplace platform to generate unique SKUs for
                      items as soon as they are approved by a member of the
                      fabric Merchant team.
                    </span>
                  }
                  switchProps={{
                    label: '',
                    stateLabels: {
                      OFF: 'Off',
                      ON: 'On',
                    },
                    checked: toggleValue === '1',
                    onChange: ({ target: { checked } }) => {
                      const value = checked ? '1' : '0'
                      setToggleValue(value)
                      updatePreference(value)
                    },
                    disabled: loading,
                  }}
                />
              </Box>
            }
          />
          <div className='divider-4' />
          <Card
            showCollapse={false}
            open
            headerContent={
              <Box width='50%'>
                <PageHeaderComponent
                  h1Text={<span className='h5'>Serial Key</span>}
                  h2Text={
                    <span className='body2-regular'>
                      If Retailer SKU Assignment is enabled, you can choose a
                      seed value for your SKUs.
                    </span>
                  }
                />
              </Box>
            }
            bodyContent={
              <Box width='50%'>
                <GridRow padding={false}>
                  <GridCol lg={3} md={3} sm={12}>
                    <Input
                      width='100%'
                      label='Prefix'
                      inputProps={{
                        placeholder: 'Prefix',
                        value: prefix,
                        onChange: ({ target: { value } }) => {
                          setPrefix(value)
                        },
                        // disabled if serialKey already exists
                        disabled: initialSerialKeys.length > 0,
                      }}
                    />
                  </GridCol>
                  <GridCol lg={3} md={3} sm={12}>
                    <Input
                      width='100%'
                      label='Seed Value'
                      inputProps={{
                        name: 'seedValue',
                        value: seedValue,
                        placeholder: 'Seed Value',
                        onChange: ({ target: { value } }) => {
                          setSeedValue(value)
                        },
                        // disabled if serialKey already exists
                        disabled: initialSerialKeys.length > 0,
                      }}
                      maskOptions={{
                        regex: '\\d*(\\.)?\\d*',
                      }}
                    />
                  </GridCol>
                  <GridCol lg={3} md={3} sm={12}>
                    <Input
                      width='100%'
                      label='Next Value'
                      inputProps={{
                        name: 'nextValue',
                        placeholder: 'Next Value',
                        value: nextValue,
                        // always disabled
                        disabled: true,
                      }}
                      maskOptions={{
                        regex: '\\d*(\\.)?\\d*',
                      }}
                    />
                  </GridCol>
                  <GridCol
                    lg={3}
                    md={3}
                    sm={12}
                    alignSelf='end'
                    justifySelf='auto'
                  >
                    <Button
                      onClick={() => onSave()}
                      text='Save'
                      variant='primary'
                      isDisabled={
                        saving || loading || initialSerialKeys.length > 0
                      }
                    />
                  </GridCol>
                </GridRow>
              </Box>
            }
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default RetailerSkuSettings
