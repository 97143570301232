type SettingOption = {
  Title: string
  Description: string
  path: string
  key: string
  iconName: string
}

const createSettingOption = (
  title: string,
  description: string,
  path: string,
  key: string,
  iconName: string
): SettingOption => ({
  Title: title,
  Description: description,
  path,
  key,
  iconName,
})

export const SETTING_OPTIONS: SettingOption[] = [
  createSettingOption(
    'Basic Details',
    'Manage general information about your business.',
    'basic-details',
    'basic-details',
    'Info'
  ),
  createSettingOption(
    'Default Onboarding Preferences',
    'Manage default onboarding settings for all new connections.',
    'onboarding',
    'onboarding',
    'Activate'
  ),
  createSettingOption(
    'Notifications',
    'Update the notifications you receive from the fabric Platform.',
    'notifications',
    'notifications',
    'NotificationsNone'
  ),
  // createSettingOption(
  //   'Payment Settings (Staff Only)',
  //   'Connect your bank account for automatic payments.',
  //   'payment',
  //   'payment-settings',
  //   'Pay'
  // ),
  // createSettingOption(
  //   'Inventory Settings',
  //   'Register and update fulfillment & inventory locations.',
  //   'inventory',
  //   'inventory-settings',
  //   'Inventory'
  // ),
  // createSettingOption(
  //   'User Management',
  //   'Invite new and manage existing users.',
  //   'users',
  //   'user-management',
  //   'Customers'
  // ),
  // createSettingOption(
  //   'API Clients',
  //   'Manage API keys for your account.',
  //   'api',
  //   'api-clients',
  //   'DynamicValues'
  // ),
  createSettingOption(
    'Integrations',
    'Manage integrations of your account (e.g. Shopify, fabric OMS).',
    'integrations',
    'integrations',
    'Swap'
  ),
  // createSettingOption(
  //   'Webhooks (Advanced)',
  //   'Configure webhooks and review webhook history.',
  //   'webhooks',
  //   'webhooks',
  //   'Webhook'
  // ),
  createSettingOption(
    'Proposal Departments',
    'Manage your proposal departments.',
    'proposal-departments',
    'proposal-departments',
    'File'
  ),
  // createSettingOption(
  //   'Attribute Value Transformers',
  //   'Manage your product attribute value transformations.',
  //   'attribute-transformers',
  //   'attribute-transformers',
  //   'Settings'
  // ),
  createSettingOption(
    'Product Rulesets',
    'Manage your product rulesets.',
    'rulesets',
    'rulesets',
    'Heirarchy'
  ),
  createSettingOption(
    'Product & Inventory Templates',
    'Manage your product and inventory templates.',
    'templates',
    'templates',
    'Logs'
  ),
  // createSettingOption(
  //   'Shipping Accounts',
  //   'Manage existing and add new Shipping Accounts.',
  //   'shipping-accounts',
  //   'shipping-accounts',
  //   'Orders'
  // ),
  // createSettingOption(
  //   'Terms of Service',
  //   'Review the Terms of Service from fabric Marketplace.',
  //   'terms-services',
  //   'terms-services',
  //   'List'
  // ),
  createSettingOption(
    'Retailer SKU Settings',
    'Configure auto-generation of Retailer SKU.',
    'retailer-sku',
    'retailer-sku',
    'Grid'
  ),
  createSettingOption(
    'Job Reports',
    'View your job statuses.',
    'jobs',
    'jobs',
    'List'
  ),
]
