import React, { useState, useEffect, useMemo } from 'react'
import { GridCol, GridRow, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { getPlatforms } from 'api/platformAccount'
import SelectableCard from 'atoms/card/selectableCard'
import { Skeleton } from 'atoms/skeleton'
import PlatformAccountModal from './platformAccountModal'

type ChildProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  loadPlatformAccounts: (quiet?: boolean) => void
  existingAccounts: any
}

const AddIntegrationsModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  loadPlatformAccounts,
  existingAccounts,
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(true)
  const [platforms, setPlatforms] = useState([])
  const [showSelectedAccountForm, setShowSelectedAccountForm] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const fetchPlatforms = async () => {
    const { data } = await getPlatforms({})
    setPlatforms(data.results.filter((el) => el.status === 'active'))
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        await fetchPlatforms()
      } catch (error) {
        showToast({
          label: 'Failed to fetch platforms.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const availablePlatforms = useMemo(() => {
    const integratedAccounts = existingAccounts.map(
      (platform) => platform.platform.code
    )
    return platforms.filter(
      (platform) => !integratedAccounts.includes(platform.code)
    )
  }, [platforms, existingAccounts])

  const onClose = () => {
    setShowModal(false)
    setSelectedAccount(null)
  }

  const SelectPlatform = () => (
    <Modal
      headerText={'Add Integration'}
      description=''
      size='small'
      onClose={onClose}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: onClose,
          text: 'Cancel',
          variant: 'secondary',
        },
        {
          dataTestid: 'continue-button',
          onClick: () => {
            setShowSelectedAccountForm(true)
            setShowModal(false)
          },
          text: 'Continue',
          variant: 'primary',
          isDisabled: !selectedAccount,
        },
      ]}
      dataTestid={'add-integration-modal'}
      onBackdropClick={onClose}
    >
      {loading ? (
        <Skeleton height='30px' width={'50%'} />
      ) : (
        <GridRow padding={false}>
          {availablePlatforms.map((account, index) => (
            <GridCol sm={6} key={index}>
              <SelectableCard
                text={account.name}
                assetType='image'
                imageUrl={account.logo_url}
                width={'100%'}
                selected={account.code === selectedAccount?.code}
                onChange={() => {
                  setSelectedAccount(account)
                }}
                disabled={!['Shopify', 'fabric'].includes(account.name)}
                dataTestid={`${account.code}-selectable-card`}
              />
            </GridCol>
          ))}
        </GridRow>
      )}
    </Modal>
  )

  return (
    <>
      {showSelectedAccountForm ? (
        <PlatformAccountModal
          showModal={showSelectedAccountForm}
          loadPlatformAccounts={loadPlatformAccounts}
          selectedPlatform={selectedAccount}
          onClose={() => {
            setShowSelectedAccountForm(false)
            setSelectedAccount(null)
          }}
        />
      ) : (
        <SelectPlatform />
      )}
    </>
  )
}

export default AddIntegrationsModal
