import { useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { getConnections } from 'api/connection'
import RetryOrderCard from 'modules/settings/components/integrations/shopify/utilities/retryOrder'
import TrackingNumberResetCard from 'modules/settings/components/integrations/shopify/utilities/trackingNumberReset'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import PushInventoryCard from 'modules/settings/components/integrations/shopify/utilities/pushInventory'
import SyncVariantsByVendorCard from 'modules/settings/components/integrations/shopify/utilities/syncVariantsByVendor'
import SyncVariantsByVariantIdCard from 'modules/settings/components/integrations/shopify/utilities/syncVariantByVariantId'
import SyncVariantsByDateCard from 'modules/settings/components/integrations/shopify/utilities/syncVariantsByDate'

const Utilities: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const isStaff = useSelector((state: RootState) => state.user.isStaff)
  const [loadingConnections, setLoadingConnections] = useState(true)
  const [connections, setConnections] = useState(null)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingIntegrations({ page: 'Shopify Utilities' })
        ),
      0
    )
  }, [])

  const fetchConnections = async () => {
    const connectionParams = {
      pagination: 0,
      short: 1,
      order_by: 'brand__name',
      status: 'active,onboarding',
    }
    const { data } = await getConnections({ params: connectionParams })
    setConnections(data.results)
  }

  useEffect(() => {
    ;(async () => {
      try {
        await fetchConnections()
      } catch (error) {
        showToast({
          label: 'Failed to fetch details.',
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoadingConnections(false)
      }
    })()
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Shopify Utilities'
        customClassName='mb-4'
        dataTestid='shopify-utilities-header'
        primaryButtons={[
          {
            onClick: () => navigate('/settings/integrations'),
            text: 'Back',
            variant: 'secondary',
            dataTestid: 'back-button'
          },
        ]}
      />
      <RetryOrderCard />
      {isStaff && (
        <>
          <div className='divider-4' />
          <TrackingNumberResetCard />
        </>
      )}
      <div className='divider-4' />
      <PushInventoryCard
        loading={loadingConnections}
        connections={connections}
      />
      <div className='divider-4' />
      <SyncVariantsByVendorCard />
      <div className='divider-4' />
      <SyncVariantsByDateCard />
      <div className='divider-4' />
      <SyncVariantsByVariantIdCard />
    </Box>
  )
}

export default Utilities
