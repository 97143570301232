import React from 'react'
import { Card, GridRow, GridCol, Button, ToolTip, Icon } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import Image from 'atoms/image/image'

type ChildProps = {
  loading?: boolean
  media: any[]
} & ({ onlyMedia: true; header?: any } | { onlyMedia?: false; header: any })

const ProductMediaCard: React.FC<ChildProps> = (props) => {
  const NoResultContent = () => {
    return (
      <h5 className='body2-regular grey-500 textAlignCenter'>
        You have no media.
      </h5>
    )
  }

  const RowContent = () => {
    return (
      <>
        <p className='body2-regular grey-600 mb-4'>
          Photos may be resized for consistency. Please review the native
          dimensions listed below each photo (WxH).
        </p>
        <GridRow padding={false}>
          {props.media?.map((image, key) => (
            <GridCol sm={3} md={3} lg={3} key={`${image.id}-${key}`}>
              <div className='textAlignCenter v-center'>
                <div className='ml-1'>
                  <Image alt='item' size='medium' src={image.url} />
                  <span className='grey-700 label'>
                    {image.width}x{image.height}
                  </span>
                </div>
              </div>
            </GridCol>
          ))}
        </GridRow>
      </>
    )
  }

  return (
    <div className='mt-4'>
      {props.loading ? (
        <CardLoading
          label={props?.header}
          num={3}
          showCollapse={false}
          open={true}
        />
      ) : props?.onlyMedia ? (
        <RowContent />
      ) : (
        <Card
          headerContent={props?.header}
          showCollapse
          open={true}
          showDivider={true}
          bodyContent={
            props?.media.length > 0 ? <RowContent /> : <NoResultContent />
          }
        />
      )}
    </div>
  )
}

export default ProductMediaCard
