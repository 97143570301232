import { Badge } from 'atoms'
import { FC, useMemo } from 'react'

interface InvoicePaidProps {
  paid_at: any
}

const InvoicePaidPresenter: FC<InvoicePaidProps> = ({ paid_at }) => {
  const presenter = useMemo(() => {
    let presenter = {
      label: '--',
      status: '',
      asset: '',
    }

    if (paid_at) {
      presenter.label = 'Paid'
      presenter.status = 'success'
      presenter.asset = 'bullet'
      presenter
    }
    return presenter
  }, [paid_at])

  return (
    <>
      {presenter.status === 'success' ? (
        <Badge
          label={presenter.label}
          variant='secondary'
          status={presenter.status}
          asset={presenter.asset}
        />
      ) : (
        presenter.label
      )}
    </>
  )
}

export default InvoicePaidPresenter
