import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { Card, Box, Link, Table } from 'atoms'
import PageHeader from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteRuleGroup, getRuleGroups } from 'api/rulesets'
import { formatDate } from 'lib/utils/date/formatDate'
import BulkActionsBar from 'organisms/bulkActions'
import CreateRuleGroupModal from './createRuleGroupModal'
import EditDeleteTableRow from 'molecules/table/editDeleteTableRow'
import ConfirmDeleteModal from 'molecules/modals/confirmDeleteModal'

export interface RuleGroupType {
  id: number
  name: string
  updated_at: string
  position: number | string
}

type ChildProps = {}

const RuleGroupsTable: FC<ChildProps> = ({}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [showMappingModal, setShowCreateModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [ruleGroups, setRuleGroups] = useState<RuleGroupType[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const fetchRuleGroups = async () => {
    const { data } = await getRuleGroups({
      params: { ruleset_id: id, order_by: '-id', limit: 10, page: currentPage },
    })
    setRuleGroups(data.results)
    setTotalCount(data.count)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      ;(async () => {
        await fetchRuleGroups()
      })()
    }
  }, [id, currentPage])

  useEffect(() => {
    if (!loading) {
      formatSetTableData({ data: ruleGroups })
    }
  }, [loading, ruleGroups])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: '10%',
      },
      {
        title: 'Name',
        width: '40%',
      },
      {
        title: 'Position',
        width: '15%',
      },
      {
        title: 'Updated At',
        width: '15%',
      },
      {
        title: '',
        width: '80',
      },
    ]
    return columns
  }, [loading, ruleGroups])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((ruleGroup, id) => {
      return {
        id,
        data: {
          'ID': { value: ruleGroup?.id },
          'Name': {
            onRender: () => (
              <Link
                onClick={() => {
                  navigate(`rulegroup/${ruleGroup?.id}`)
                }}
                label={ruleGroup?.name}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Position': { value: ruleGroup?.position || '--' },
          'Updated At': { value: formatDate({ date: ruleGroup.updated_at }) },
          '': {
            value: { ...ruleGroup },
            onRender: (data) => (
              <EditDeleteTableRow
                handleClick={(key) => handleActions(key, data.value)}
                hideEdit
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const handleActions = (key, data) => {
    setShowDeleteModal(true)
    setSelectedRow(data)
  }

  const onDelete = async () => {
    try {
      await deleteRuleGroup({ ruleGroupId: selectedRow.id })
    } finally {
      setSelectedRow(null)
      fetchRuleGroups()
    }
  }

  return (
    <>
      <Box dataTestid='rule-groups-table' margin={{ top: 4, bottom: 7 }}>
        {
          <CreateRuleGroupModal
            showModal={showMappingModal}
            setShowModal={setShowCreateModal}
            loadRuleGroups={() => {
              fetchRuleGroups()
              setSelectedRow(null)
            }}
            ruleGroups={ruleGroups}
          />
        }
        {
          <ConfirmDeleteModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            headerText={`Are you sure you want to delete rule group: '${selectedRow?.name}'?`}
            body1='This rule group will be deleted immediately. You can’t undo this action.'
            handleClick={onDelete}
          />
        }
        <Card
          showCollapse={false}
          headerContent={
            <Box width='100%'>
              <PageHeader
                h1Text={<span className='h5'>Rule Groups</span>}
                h2Text={
                  <span className='body1-regular grey-600'>
                    Rule groups collect similar attributes into logical groups
                    for better organization.
                  </span>
                }
                primaryButtons={[
                  {
                    onClick: () => {
                      setShowCreateModal(true)
                    },
                    text: 'Create Rule Group',
                    variant: 'tertiary',
                    icon: 'Add',
                    iconPosition: 'left',
                  },
                ]}
              />
            </Box>
          }
          bodyContent={
            <>
              <BulkActionsBar
                loading={loading}
                hasBulkActions={false}
                totalRows={totalCount}
                currentPage={currentPage}
              />
              <Table
                columns={tableColumns}
                data={tableData}
                loading={loading}
                disableTableBorder
                emptyTableText='Rule groups are not yet added to this ruleset!'
                customPaginationProps={{
                  handlePagination: (pageNumber: number) => {
                    setCurrentPage(pageNumber)
                  },
                  activePageNumber: currentPage,
                  perPage: 10,
                  totalRecords: totalCount,
                }}
              />
            </>
          }
        ></Card>
      </Box>
    </>
  )
}

export default RuleGroupsTable
