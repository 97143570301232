import { FC, useEffect, useState } from 'react'
import { Box, GridCol, GridRow } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { DataProps } from '@teamfabric/copilot-ui'
import { getPlatformTemplates } from 'api/templates'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNavigate, useParams } from 'react-router-dom'
import TemplatesTable from 'modules/settings/components/templates/listTable'
import { capitalizeFirstLetter } from 'lib/utils'

const ExternalPlatformTemplates: FC = () => {
  const navigate = useNavigate()
  const shopifyParams = useSelector(
    (state: RootState) => state.externalPlatformTemplates.shopifyTemplateParams
  )
  const fabricParams = useSelector(
    (state: RootState) => state.externalPlatformTemplates.fabricTemplateParams
  )
  const { id, platform } = useParams()
  const params = platform === 'shopify' ? shopifyParams : fabricParams
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingIntegrations({ page: `${platform} Templates` })
        ),
      0
    )
  }, [])

  const fetchTemplates = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    try {
      const response = await getPlatformTemplates({
        platformCode: platform,
        platformId: id,
        params: { ...params },
      })
      const { data } = response
      setTotalRows(data?.count)
      setTableData(data?.results)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchTemplates()
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text={`${capitalizeFirstLetter(platform)} Templates`}
        customClassName='mb-4'
        dataTestid={`${platform}-templates-header`}
        primaryButtons={[
          ...(platform === 'fabric'
            ? [
                {
                  onClick: () => navigate('create'),
                  text: 'Create Template',
                  variant: 'primary',
                  icon: 'Add',
                  iconPosition: 'left',
                  dataTestid: 'create-template-button',
                },
              ]
            : []),
        ]}
      />
      <GridRow padding={false}>
        <GridCol md={12}>
          <TemplatesTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ExternalPlatformTemplates
