import API, { API_URL } from 'api'

export const getCompatibilityRulesets = async ({ params }) => {
  return await API.get(API_URL.GET_COMPATIBILITY_RULESETS(), { params })
}

export const postRuleset = async ({ body }) => {
  return await API.post(API_URL.GET_COMPATIBILITY_RULESETS(), body)
}

export const patchRuleset = async ({ id, body }) => {
  return await API.patch(API_URL.GET_COMPATIBILITY_RULESET_BY_ID({ id }), body)
}

export const getCompatibilityRulesetById = async ({ id }) => {
  return await API.get(API_URL.GET_COMPATIBILITY_RULESET_BY_ID({ id }))
}

export const deleteRuleset = async ({ id }) => {
  return await API.delete(API_URL.GET_COMPATIBILITY_RULESET_BY_ID({ id }))
}

export const getCompatibilityRulesetRules = async ({ id, params }) => {
  return await API.get(API_URL.GET_COMPATIBILITY_RULESET_RULES({ id }), {
    params,
  })
}

export const postRule = async ({ id, body }) => {
  return await API.post(API_URL.GET_COMPATIBILITY_RULESET_RULES({ id }), body)
}

export const patchRule = async ({ id, ruleId, body }) => {
  return await API.patch(API_URL.RULESET_RULES_BY_RULE_ID({ id, ruleId }), body)
}

export const putRuleValidator = async ({ id, ruleId, body }) => {
  return await API.put(API_URL.RULESET_RULES_BY_RULE_ID({ id, ruleId }), body)
}

export const patchRuleValidator = async ({ id, ruleId, body }) => {
  return await API.patch(API_URL.RULESET_RULES_BY_RULE_ID({ id, ruleId }), body)
}

export const getRuleGroups = async ({ params }) => {
  return await API.get(API_URL.GET_RULE_GROUPS(), { params })
}

export const postRuleGroup = async ({ body }) => {
  return await API.post(API_URL.GET_RULE_GROUPS(), body)
}

export const getRuleGroupById = async ({ ruleGroupId }) => {
  return await API.get(API_URL.GET_RULE_GROUP_BY_ID({ ruleGroupId }))
}

export const patchRuleGroup = async ({ ruleGroupId, body }) => {
  return await API.patch(API_URL.GET_RULE_GROUP_BY_ID({ ruleGroupId }), body)
}

export const deleteRuleGroup = async ({ ruleGroupId }) => {
  return await API.delete(API_URL.GET_RULE_GROUP_BY_ID({ ruleGroupId }))
}
