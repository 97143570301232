import React from 'react'
import { formatDate } from 'lib/utils/date/formatDate'

type ChildProps = {
  date: any
  isEstimatedReturn?: boolean
}

const EstimatedAvailabilityDate: React.FC<ChildProps> = ({
  date,
  isEstimatedReturn,
}) => {
  const getLabel = (date) => {
    if (date) {
      return formatDate({ date: date, withTime: true })
    } else {
      return (
        <span className='grey-400'>
          {isEstimatedReturn ? 'Unknown' : 'Never'}
        </span>
      )
    }
  }

  return <span className='ui-card-content-style-match'>{getLabel(date)}</span>
}

export default EstimatedAvailabilityDate
