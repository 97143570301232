import { FC, useEffect, useMemo, useState } from 'react'
import { Box, Table } from 'atoms'
import { DataProps } from '@teamfabric/copilot-ui'
import VariantItem from 'molecules/lineItem/variantItem'

type ChildProps = {
  selected: any
}

const IssueProducts: FC<ChildProps> = ({ selected }) => {
  const [tableData, setTableData] = useState<DataProps[]>([])
  const products = useMemo(() => {
    if (selected?.elements?.length > 0) {
      return selected.elements.filter((element) => element.product)
    } else return []
  }, [selected])

  useEffect(() => {
    formatSetTableData({ data: products })
  }, [products])

  const tableColumns = [
    {
      title: 'Title',
      width: '100%',
    },
  ]

  const renderProduct = (product) =>
    VariantItem({
      variant: {
        parent_name: product.name,
        parent_identifier: product.identifier,
        media: product.media,
        primary_media: product.primary_media,
      },
      isProduct: true,
    })

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(({ id, product }) => {
      return {
        id,
        data: {
          Title: {
            onRender: () => renderProduct(product),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  return (
    <Box
      dataTestid='issues-product-details'
      border={{ radius: 1, width: '1px', color: '#E7E7E7' }}
      margin={{ top: 4 }}
      padding={{ left: 4, right: 4, top: 4, bottom: 4 }}
    >
      <Table
        columns={tableColumns}
        data={tableData}
        disableTableBorder
        dataTestidPrefix='proposal-issues-products-'
      />
    </Box>
  )
}

export default IssueProducts
