import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import { Link, PageHeader } from 'atoms'
import { getFeedRequestDetail } from 'api/products'
import Card4Columns from 'molecules/cards/4Columns'
import { getBadgeStatus } from 'modules/products/utils/products'
import { PRODUCTS } from 'modules/products/constants'
import { formatDate } from 'lib/utils/date/formatDate'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'

const initialData = {
  reference_number: null,
  retailer: {
    name: '',
  },
  status: 'pending',
}

const ImportRequestDetail: React.FC = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const showToast = useToast()
  const [requestInfo, setRequestInfo] = useState<any[]>([])
  const [fileInfo, setFileInfo] = useState<any[]>([])
  const [data, setData] = useState(initialData)

  const formatSetRequestInfo = (originalData: any) => {
    const formattedData = [
      {
        label: 'Item ID',
        value: originalData.id,
      },
      {
        label: 'Supplier',
        value: originalData.brand.name,
      },
      {
        label: 'Merchant',
        value: originalData.retailer.name,
      },
      {
        label: 'Submitted',
        value: formatDate({
          date: originalData.created_at,
          withTime: true,
        }),
      },
    ]
    setRequestInfo(formattedData)
  }

  const formatSetFileInfo = (originalData: any) => {
    const formattedData = [
      {
        label: 'File',
        value: {
          onRender: () => (
            <Link
              label={originalData.feed_file.filename}
              mode='inline'
              href={originalData.feed_file.file}
            />
          ),
        },
      },
      {
        label: 'Template',
        value: `${originalData.template.name} ID #${originalData.template.id}`,
      },
    ]
    setFileInfo(formattedData)
  }

  useEffect(() => {
    ;(async () => {
      try {
        window.updateBreadcrumb(BREADCRUMBS.IMPORT_REQUESTS_DETAILS)
        const { data } = await getFeedRequestDetail({ id })
        formatSetRequestInfo(data)
        formatSetFileInfo(data)
        setData(data)
        setLoading(false)
      } catch (error) {
        showToast({
          label: "Couldn't load feed request",
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      }
    })()
  }, [])

  return (
    <div className='container'>
      <PageHeader
        h1Text={`Import request #${data.reference_number || id}`}
        h2Text={data.retailer.name}
        loading={loading}
        badgeProps={{
          status: getBadgeStatus(data.status),
          label: PRODUCTS.FEED_REQUESTS.STATUS[data.status.toUpperCase()].LABEL,
        }}
      />
      <Card4Columns
        header='Request imformation'
        loading={loading}
        data={requestInfo}
        customClassName='mt-3 mb-3'
      />
      <Card4Columns
        header='File imformation'
        loading={loading}
        data={fileInfo}
      />
    </div>
  )
}

export default ImportRequestDetail
