import React, { useState, useEffect } from 'react'
import RadioGroup from 'atoms/input/radioGroup'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import Input from 'atoms/input/input'
import { patchContextDetails } from 'api/context'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentStep,
  setNextAvaiableStep,
  setQuestionnaire,
} from 'store/onboardingSlice'
import { useNavigate } from 'react-router-dom'
import CardLoading from 'molecules/loading/cardLoading'
import { useToast } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import CardHeader from 'molecules/cards/cardHeader'
import { patchAssignTask } from 'api/onboarding'
import { PATHS } from 'routes/pageRoutes'

type QuestionnaireProps = {
  isNextClicked: boolean
  setNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  setIsSaveExitClicked: (value: boolean) => void
  isSaveExitClicked: boolean
  reseting: boolean
}

const OnboardingQuestionnaire: React.FC<QuestionnaireProps> = ({
  isNextClicked,
  setNextClicked,
  setIsNextButtonDisabled,
  setIsSaveExitClicked,
  isSaveExitClicked,
  reseting,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showToast = useToast()
  const state = useSelector((state: RootState) => state)
  const currentStep = state.onboarding.currentStep
  const questionnaire = state.onboarding.questionnaire
  const isExperience =
    currentStep.value ===
    ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.TASK_NAME

  // experenece level
  const [experienceLevel, setExperienceLevel] = useState('')
  // integration method
  const [integrationMethod, setIntegrationMethod] = useState('')
  // open input field when "Others" clicked
  const [openInput, setOpenInput] = useState(false)
  const [saving, setSaving] = useState(false)

  /**
   * Call this function once Next button is clicked
   * Questionnaire Experience Level and Integration Plan
   * @param value string Experience Level or Integration Plan
   */
  const patchBrandTask = async ({ value }) => {
    const body = {
      metadata: [
        {
          name: isExperience
            ? 'ob_marketplace_experience'
            : 'ob_integration_plan',
          display_name: isExperience
            ? 'Marketplace Experience'
            : 'Integration Plan',
          type: 'string',
          value,
        },
      ],
    }
    await patchContextDetails({ body })
  }

  useEffect(() => {
    const isNextDisabled = isExperience
      ? questionnaire.experienceLevel === ''
      : questionnaire.integrationMethod === ''
    setIsNextButtonDisabled(isNextDisabled)
    setExperienceLevel(questionnaire.experienceLevel)
    setIntegrationMethod(questionnaire.integrationMethod)
  }, [currentStep])

  const questionnaireSave = async () => {
    try {
      setSaving(true)
      const currentlySelectedValue = isExperience
        ? experienceLevel
        : integrationMethod
      const alreadySelectedValue = isExperience
        ? questionnaire.experienceLevel
        : questionnaire.integrationMethod
      if (currentlySelectedValue === alreadySelectedValue) {
        return
      }
      const value = isExperience ? experienceLevel : integrationMethod
      const body = {
        info: {
          experienceLevel: isExperience
            ? experienceLevel
            : questionnaire.experienceLevel,
          integrationMethod: isExperience ? '' : integrationMethod,
          completedDate: isExperience ? '' : new Date().toJSON(),
        },
      }
      await patchBrandTask({ value })
      // Update assign-task info
      await patchAssignTask({
        taskId: questionnaire.taskId,
        body,
      })
      const sliceData = {
        ...body.info,
        taskId: questionnaire.taskId,
        completed: !isExperience,
        processNum: isExperience ? 1 : 2,
      }
      dispatch(setQuestionnaire(sliceData))
      showToast({
        label: 'Saved the task',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: "Couldn't save the task",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
    }
  }

  const updateSlices = () => {
    const nextTaskName = isExperience
      ? ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION
      : ONBOARDING_TASKS.CALL.SUB_TASKS.CALL_INTRO
    const nextParentTask = isExperience
      ? ONBOARDING_TASKS.QUESTIONNAIRE.VALUE
      : ONBOARDING_TASKS.CALL.VALUE
    dispatch(
      setCurrentStep({
        value: nextTaskName.TASK_NAME,
        parent: nextParentTask,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.CALL.VALUE))
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setNextClicked(false)
      setIsNextButtonDisabled(true)
      await questionnaireSave()
      updateSlices()
      if (!isExperience) {
        navigate(ONBOARDING_TASKS.CALL.LINK)
      }
    })()
  }, [isNextClicked])

  // onboardingProgress save and exit button clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      await questionnaireSave()
      updateSlices()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  const experienceOptions = Object.keys(
    ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.VALUES
  ).map((key) => ({
    'data-testid': '',
    'disabled': false,
    'id': key,
    'label':
      ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.VALUES[key].LABEL,
    'value':
      ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.VALUES[key].VALUE,
  }))

  const integrationOptions = Object.keys(
    ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.VALUES
  ).map((key) => ({
    'data-testid': '',
    'disabled': false,
    'id': key,
    'label':
      ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.VALUES[key].LABEL,
    'value':
      ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.VALUES[key].VALUE,
  }))

  return (
    <>
      {(saving || reseting) && (
        <CardLoading
          label={
            isExperience
              ? ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.NAME
              : ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.NAME
          }
          num={4}
        />
      )}
      {!saving && !reseting && isExperience && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.EXPERIENCE.NAME}
              h2Text='First, please tell us about your experience level working with
            other marketplaces or direct ship programs (e.g., Amazon,
            Wayfair, etc).'
            />
          }
          bodyContent={
            <div>
              <RadioGroup
                label='SELECT EXPERIENCE LEVEL'
                name='level'
                value={experienceLevel}
                onChange={({ target: { value } }) => {
                  setIsNextButtonDisabled(false)
                  setExperienceLevel(value)
                }}
                required={true}
                options={experienceOptions}
              />
            </div>
          }
        />
      )}
      {!saving && !reseting && !isExperience && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION.NAME}
              h2Text='fabric Marketplace supports a number of different methods to
            integrate and trade inventory, orders, and fulfillment data.
            Given the options below, which option do you think your
            organization will use or are you most interested in learning
            more about?'
            />
          }
          bodyContent={
            <>
              <RadioGroup
                label='SELECT INTEGRATION METHOD'
                name='integration'
                value={integrationMethod}
                onChange={({ target: { value } }) => {
                  if (value === 'Other') {
                    setOpenInput(true)
                  } else {
                    setIsNextButtonDisabled(false)
                    setOpenInput(false)
                    setIntegrationMethod(value)
                  }
                }}
                options={integrationOptions}
              />
              {openInput && (
                <div className='mt-3'>
                  <Input
                    label={
                      ONBOARDING_TASKS.QUESTIONNAIRE.SUB_TASKS.INTEGRATION
                        .VALUES.OTHER.LABEL
                    }
                    required={true}
                    inputProps={{
                      placeholder: '',
                      onChange: ({ target: { value } }) => {
                        setIntegrationMethod(value)
                        setIsNextButtonDisabled(false)
                      },
                    }}
                  />
                </div>
              )}
            </>
          }
        />
      )}
    </>
  )
}

export default OnboardingQuestionnaire
