import { formatCurrency } from 'lib/utils/currency/formatCurrency'
import Link from 'atoms/link/link'
import { getPercentage } from 'lib/utils/math'

const formatProposalType = (type: string) => {
  switch (type) {
    case 'new_product':
      return 'New Products'
    case 'image_update':
      return 'Image Update'
    case 'attribute_update':
      return 'Attribute Update'
    default:
      return 'Cost Update'
  }
}

const formatRule = ({ key, rule, attribute, result }) => {
  let lineItem = {
    label: rule?.attribute?.name,
    value: null,
  }

  const value = attribute?.values[0]?.value

  if (rule?.attribute?.type === 'boolean') {
    if (value == 1) lineItem.value = 'Yes'
    else if (value == 0) lineItem.value = 'No'
    else lineItem.value = '-Not Set-'
  } else if (value) {
    if (rule?.attribute?.type === 'money') {
      lineItem.value = formatCurrency(value)
    } else if (rule?.attribute?.type === 'media') {
      lineItem.value = {
        onRender: () => (
          <Link
            label={'External URL'}
            onClick={() => window.open(value, '_blank')}
          />
        ),
      }
    } else {
      lineItem.value = `${value}${
        attribute?.values[0]?.unit ? ` ${attribute?.values[0]?.unit?.standard_unit}` : ''
      }`
    }
  } else {
    lineItem.value = '-Not Set-'
  }

  return lineItem
}

const getRetailerCost = (row) => {
  let retailerCost = row.proposal_variant?.pricing?.retailer_cost?.values[0]
  if (row.proposal_variant?.pricing?.retailer_cost?.values.length > 1) {
    const values = row.proposal_variant.pricing.retailer_cost.values
    // fetch the last created value
    retailerCost = values.reduce((a, b) =>
      a.created_at > b.created_at ? a : b
    )
  }
  return formatCurrency(retailerCost?.value)
}

const getMargin = (pricing) => {
  function getValue(key) {
    let value = pricing[key].values[0].value
    if (pricing[key].values.length > 1) {
      const values = pricing[key].values
      // fetch the last created value
      value = values.reduce((a, b) =>
        a.created_at > b.created_at ? a : b
      ).value
    }
    return value
  }

  let cost = 0
  let price = 0

  if (pricing && 'retailer_cost' in pricing) {
    cost = parseFloat(getValue('retailer_cost'))
  }
  if (pricing && 'retailer_price' in pricing) {
    price = parseFloat(getValue('retailer_price'))
  }
  if (cost && price) {
    return getPercentage((price - cost) / price)
  } else return '--'
}

const proposalVariantPrice = (variant) => {
  let price = null
  if (
    variant?.proposal_variant?.attributes?.retailer_price
  ) {
    price = {
      value: variant.proposal_variant.attributes.retailer_price.values[0].value,
      currency: variant.proposal_variant.attributes.retailer_price.values[0],
    }
  }
  return price
}

const formatVariantAttributeData = (variant) => {
  let formattedData = [
    {
      label: 'Variant ID',
      value: variant?.id ? variant.id : '--',
    },
    {
      label: 'Proposal Variant ID',
      value: variant?.proposal_variant?.id
        ? variant?.proposal_variant.id
        : '--',
    },
  ]
  if (variant?.active_permit) {
    let value = `${variant?.active_permit.id}`
    if (variant?.permits?.length > 2) {
      value += `(${variant.permits.length} permits)`
    }
    formattedData.push({
      label: 'Active Permit ID',
      value: value,
    })
  }
  const cost = getRetailerCost(variant)
  if (cost !== null) {
    formattedData.push({
      label: 'Proposal Variant Retailer Cost',
      value: cost,
    })
  }
  const price = proposalVariantPrice(variant)
  if (price !== null) {
    formattedData.push({
      label: 'Proposal Variant Retailer Price',
      value: formatCurrency(price.value),
    })
  }
  return formattedData
}

const getBadgeStatus = (status) => {
  if (status === 'approved') {
    return {
      label: 'Approved',
      status: 'success',
    }
  } else if (status === 'pending') {
    return {
      label: 'Pending',
      status: 'alert',
    }
  } else if (status === 'rejected') {
    return {
      label: 'Rejected',
      status: 'error',
    }
  }
}

export {
  formatProposalType,
  getRetailerCost,
  formatRule,
  formatVariantAttributeData,
  getMargin,
  getBadgeStatus
}
