import React from 'react'
import { MultiSelect as MultiSelectUI } from '@teamfabric/copilot-ui'

type MultiSelectProps = {
  label?: string
  name?: string
  placeholder?: string
  onChange: (options: any) => void
  onClick?: () => void
  onClear: () => void
  options?: any
  width?: any
  value?: any
  disabled?: boolean
  serverSideFiltering?: any
  enableCommaSepartedValues?: boolean
  hideOptionList?: boolean
  enableDoubleClickTag?: boolean
}

const MultiSelect: React.FC<MultiSelectProps> = ({ ...props }) => {
  return <MultiSelectUI {...props} />
}

export default MultiSelect
