export const SORTING_MAPS = {
  MERCHANTS: {
    'ID': 'id',
    'Merchant': 'retailer__name',
    'Supplier #': 'brand__name',
    '# Orders (Last 7)': 'orders_last_7_days_count',
    'Status': 'status',
  },
}

export const MERCHANTS = {
  STATUS: {
    NAME: 'Status',
    PARAM: 'status',
  },
  INTEGRATION_TYPE: {
    NAME: 'Integration Type',
    NAME_SHORT: 'integration',
    PARAM: 'integration_type',
  },
}

export const FILTER_OPTIONS = {
  STATUS_OPTIONS: [
    {
      id: '0',
      key: 'status',
      label: 'Active',
      value: 'active',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'status',
      label: 'Pending',
      value: 'pending',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '2',
      key: 'status',
      label: 'Onboarding',
      value: 'onboarding',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '3',
      key: 'status',
      label: 'Suspended',
      value: 'suspended',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
  INTEGRATION_TYPE_OPTIONS: [
    {
      id: '0',
      key: 'integration_type',
      label: 'Web',
      value: 'console',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'integration_type',
      label: 'EDI',
      value: 'edi',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '2',
      key: 'integration_type',
      label: 'Shopify',
      value: 'storefront',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '3',
      key: 'integration_type',
      label: 'API',
      value: 'api',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
  INVITATION_STATUS_OPTIONS: [
    {
      id: '0',
      key: 'status',
      label: 'Accepted',
      value: 'accepted',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'status',
      label: 'Pending',
      value: 'pending',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
}
