import { Badge } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import { FC, useMemo } from 'react'

interface MarkInvoicePaidStatusProps {
  paid_at: any
}

const MarkInvoicePaidStatusPresenter: FC<MarkInvoicePaidStatusProps> = ({
  paid_at,
}) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: 'Ready',
      status: 'info',
      tooltipText: 'Ready to be mark as paid',
    }

    if (paid_at != null) {
      presenter.label = 'Skip'
      presenter.tooltipText = 'Invoice has already been mark as paid'
      presenter.status = 'success'
    }
    return presenter
  }, [paid_at])

  return (
    <ToolTip text={presenter.tooltipText}>
      <div className='hover-cursor'>
        <Badge
          label={presenter.label}
          variant='primary'
          status={presenter.status}
        />
      </div>
    </ToolTip>
  )
}

export default MarkInvoicePaidStatusPresenter
