import React from 'react'

type Dimensions = {
  width: number
  width_unit: { symbol: string; id: number }
  height: number
  height_unit: { symbol: string; id: number }
  length: number
  length_unit: { symbol: string; id: number }
  weight: number
  weight_unit: { standard_unit: string; id: number }
}

type Props = {
  dimensions: Dimensions
}

const CartonDetails: React.FC<Props> = ({ dimensions }) => {
  const calculateSize = () => {
    if (
      dimensions.width &&
      dimensions.height &&
      dimensions.length &&
      dimensions.weight &&
      dimensions.width_unit &&
      dimensions.height_unit &&
      dimensions.length_unit &&
      dimensions.weight_unit
    ) {
      if (
        dimensions.width_unit.id === dimensions.height_unit.id &&
        dimensions.width_unit.id === dimensions.length_unit.id
      ) {
        const sortedDimensions = [
          parseFloat(dimensions.height.toString()),
          parseFloat(dimensions.width.toString()),
          parseFloat(dimensions.length.toString()),
        ].sort((a, b) => b - a)

        //  size = height + girth, girth = 2(width + length), return size
        return (
          sortedDimensions[0] + 2 * (sortedDimensions[1] + sortedDimensions[2])
        )
      }
    }
    return '--'
  }

  const size = calculateSize()

  return (
    <div>
      {size !== '--' ? (
        <div className='mb-sm'>
          <p>
            <span className={size > 165 ? 'has-text-danger' : ''}>
              {size.toFixed(0) + dimensions.width_unit.symbol}
            </span>
            , {dimensions.weight} {dimensions.weight_unit.standard_unit}
          </p>
          <p className='is-size-8 has-text-grey'>
            {dimensions.width}
            {dimensions.width_unit.symbol} x {dimensions.height}
            {dimensions.height_unit.symbol} x {dimensions.length}
            {dimensions.height_unit.symbol}
          </p>
        </div>
      ) : (
        <p>Invalid Carton</p>
      )}
    </div>
  )
}

export default CartonDetails
