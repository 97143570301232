import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { archiveProposalIssue } from 'api/proposals'
import { useToast } from '@teamfabric/copilot-ui'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  issue: any
  loadProposalIssue: () => void
  onComplete: () => void
}

const ArchiveIssueModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  proposal,
  issue,
  loadProposalIssue,
  onComplete,
}) => {
  const [saving, setSaving] = useState(false)

  const showToast = useToast()

  const archiveIssue = async () => {
    try {
      setSaving(true)
      await archiveProposalIssue({
        proposalId: proposal.id,
        issueId: issue.id,
      })
      showToast({
        label: 'Issue Archived!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error archiving the issue: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      onComplete()
      loadProposalIssue()
      setSaving(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Are you sure you want to archive this issue?'
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        footerButtons={[
          {
            onClick: () => setShowModal(false),
            text: 'Cancel',
            variant: 'tertiary',
            isDisabled: saving,
          },
          {
            onClick: () => archiveIssue(),
            text: 'Archive',
            variant: 'primary',
            isDisabled: saving,
          },
        ]}
        onBackdropClick={() => setShowModal(false)}
      >
        <p className='grey-600 body1-regular'>
          Are you sure you want to archive this issue? This issue will be
          deleted and this action cannot be undone.
        </p>
      </Modal>
    </>
  )
}

export default ArchiveIssueModal
