import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Dropdown from 'atoms/dropdown/dropdown'
import Input from 'atoms/input/input'
import Button from 'atoms/button/button'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import { setAdvancedOptions as setOrdersAdvancedOptions } from 'store/orderSlice'
import { setAdvancedOptions as setInvoiceAdvanceOptions } from 'store/invoicesSlice'
import { setAdvancedOptions as setShipmentAdvancedOptions } from 'store/shipmentsSlice'
import Banner from 'atoms/banner/banner'
import SelectConnection from 'molecules/connections/selectConnectionDropdown'

type AdvanceSearchProps = {
  setAdvancedSearchValues: (value: any) => void
  resetSearch: () => void
}

export const initialParams = {
  order_by: '-id',
  limit: 10,
  page: 1,
}

export const identifierOptions = [
  {
    id: 'purchase_order_number_sw',
    label: 'PO Number (exact)',
  },
  {
    id: 'purchase_order_number',
    label: 'PO Number (contains)',
  },
  {
    id: 'customer_order_number_sw',
    label: 'Customer Order Number (exact)',
  },
  {
    id: 'customer_order_number',
    label: 'Customer Order Number (contains)',
  },
  {
    id: 'tracking_number',
    label: 'Tracking Number',
  },
  {
    id: 'invoice_number',
    label: 'Invoice Number',
  },
]

const AdvanceSearch: React.FC<AdvanceSearchProps> = ({
  setAdvancedSearchValues,
  resetSearch,
}) => {
  const dispatch = useDispatch()
  const [identifier, setIdentifier] = useState(dropDownInitialObj)
  const [purchaseOrderLabel, setPurchaseOrderLabel] = useState(
    'Purchase Order Number'
  )
  const [purchaseOrderNum, setPurchaseOrderNum] = useState('')
  const [orderStatus, setOrderStatus] = useState(dropDownInitialObj)
  const [selectedSupplier, setSelectedSupplier] = useState(dropDownInitialObj)

  const requiresConnection = [
    identifierOptions[1].id,
    identifierOptions[3].id,
    identifierOptions[4].id,
    identifierOptions[5].id,
  ]

  const requiresStatus = [
    identifierOptions[0].id,
    identifierOptions[1].id,
    identifierOptions[2].id,
    identifierOptions[3].id,
  ]

  const orderStatusOptions = [
    {
      id: 'any',
      label: 'Any',
    },
    {
      id: 'open,partial',
      label: 'Open',
    },
    {
      id: 'closed',
      label: 'Closed',
    },
  ]

  const updateOrderParams = (defaultParams: any) => {
    dispatch(setOrdersAdvancedOptions({ ...defaultParams }))
  }

  const updateTrackingParams = (defaultParams: any) => {
    dispatch(setShipmentAdvancedOptions({ ...defaultParams }))
  }

  const updateInvoiceParams = (defaultParams: any) => {
    dispatch(setInvoiceAdvanceOptions({ ...defaultParams }))
  }

  const onSearch = () => {
    const defaultParams = {
      ...initialParams,
      [identifier.id]: purchaseOrderNum,
    }
    if (selectedSupplier.id !== '') {
      defaultParams['connection_id'] = selectedSupplier.id
      defaultParams['connection_label'] = selectedSupplier.label
    }
    if (orderStatus.id !== '' && orderStatus.id !== 'any') {
      defaultParams['status'] = orderStatus.id
    }
    switch (identifier.id) {
      // orders
      case identifierOptions[0].id:
      case identifierOptions[1].id:
      case identifierOptions[2].id:
      case identifierOptions[3].id:
        updateOrderParams(defaultParams)
        break
      case identifierOptions[4].id:
        // tracking
        updateTrackingParams(defaultParams)
        break
      case identifierOptions[5].id:
        // invoices
        updateInvoiceParams(defaultParams)
        break
    }
    setAdvancedSearchValues({
      identifier: identifier.id,
      purchaseOrderNum,
      status: orderStatus.label,
    })
  }

  useEffect(() => {
    switch (identifier.id) {
      // orders
      case identifierOptions[0].id:
      case identifierOptions[1].id:
        setPurchaseOrderLabel('Purchase Order Number')
        break
      case identifierOptions[2].id:
      case identifierOptions[3].id:
        setPurchaseOrderLabel('Customer Order Number')
        break
      case identifierOptions[4].id:
        // tracking
        setPurchaseOrderLabel('Tracking Number')
        break
      case identifierOptions[5].id:
        // invoices
        setPurchaseOrderLabel('Invoice Number')
        break
    }
  }, [identifier])

  const handleUpdate = async (option) => {
    const parsedOption = {
      id: option.id,
      label: option.retailer.name,
    }
    setSelectedSupplier(parsedOption)
  }

  return (
    <div className='p-4'>
      <Banner
        variant='info'
        label='Exact match shows exact matches, while Contains shows partial matches. Use Exact match for faster results.'
      />
      <div className='mt-2'>
        <Dropdown
          label='Identifier'
          width='100%'
          value={identifier}
          options={identifierOptions}
          onChange={(e: any) => setIdentifier(e)}
          placeholder='Select identifier'
          required
        />
      </div>
      {requiresConnection.includes(identifier.id) && (
        <div className='mt-2'>
          <SelectConnection
            handleUpdate={handleUpdate}
            placeholder='Select connection'
            label='Connection'
          />
        </div>
      )}
      <div className='mt-2'>
        <Input
          label={purchaseOrderLabel}
          width='100%'
          required
          inputProps={{
            placeholder: purchaseOrderLabel,
            value: purchaseOrderNum,
            onChange: ({ target: { value } }) => {
              setPurchaseOrderNum(value)
            },
          }}
        />
      </div>
      {requiresStatus.includes(identifier.id) && (
        <div className='mt-2'>
          <Dropdown
            label='Order Status'
            width='100%'
            value={orderStatus}
            options={orderStatusOptions}
            onChange={(e: any) => setOrderStatus(e)}
            placeholder='Select order status'
          />
        </div>
      )}
      <div className='mt-6 flex-end'>
        <div className='mr-3'>
          <Button
            text='Reset All'
            variant='secondary'
            onClick={() => {
              setIdentifier(dropDownInitialObj)
              setPurchaseOrderNum('')
              setSelectedSupplier(dropDownInitialObj)
              setOrderStatus(dropDownInitialObj)
              resetSearch()
            }}
          />
        </div>
        <Button
          text='Search'
          isDisabled={identifier.id === '' || purchaseOrderNum === ''}
          variant='primary'
          onClick={onSearch}
        />
      </div>
    </div>
  )
}

export default AdvanceSearch
