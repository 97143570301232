// Migrate breadcrumbs to breadcrumbsHandler

import { APP_BREADCRUMB_LABELS, APP_PAGE_PATHS } from './constants'

const setPageBreadCrumbLabel = (pathname) => {
  return APP_BREADCRUMB_LABELS.MARKETPLACE
}

export const BREADCRUMBS = {
  ORDER_LISTING: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.ORDERS_LIST,
      url: APP_PAGE_PATHS.ORDERS_LIST,
    },
  ],
  ORDER_DETAILS: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.ORDERS_LIST,
      url: APP_PAGE_PATHS.ORDERS_LIST,
    },
    {
      label: 'ORDER DETAILS',
    },
  ],
  ORDER_CREATE: [
    {
      label: APP_BREADCRUMB_LABELS.OVERVIEW,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: 'CREATE PURCHASE ORDER',
    },
  ],
  INVOICE_LIST: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: 'INVOICES',
    },
  ],
  CANCELLATION_LIST: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: 'CANCELLATIONS',
    },
  ],
  CREDITS_LIST: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: 'CREDITS',
    },
  ],
  PROPOSALS_LIST: [
    {
      label: APP_BREADCRUMB_LABELS.PROPOSALS,
      url: APP_PAGE_PATHS.PROPOSALS_LIST,
    },
    {
      label: APP_BREADCRUMB_LABELS.LIST,
    },
  ],
  IMPORT_REQUESTS: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.IMPORT_REQUESTS,
    },
  ],
  IMPORT_REQUESTS_DETAILS: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.HOME,
    },
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.IMPORT_REQUESTS,
      url: APP_PAGE_PATHS.IMPORT_REQUESTS,
    },
    {
      label: 'DETAILS',
    },
  ],
  CONNECTION_LISTING: [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.MERCHANTS,
    },
  ],
  CONNECTION_DETAILS: [
    {
      label: APP_BREADCRUMB_LABELS.SUPPLIERS,
      url: APP_PAGE_PATHS.SUPPLIERS_LIST,
    },
    {
      label: APP_BREADCRUMB_LABELS.MERCHANTS,
      url: APP_PAGE_PATHS.MERCHANTS,
    },
    {
      label: 'VIEW FABRIC MERCHANT',
    },
  ],
  SETTING_DASHBOARD: [
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.SETTINGS,
    },
  ],
  SETTING_BASIC_DETAILS: [
    {
      label: APP_BREADCRUMB_LABELS.MARKETPLACE,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: APP_BREADCRUMB_LABELS.SETTINGS,
      url: APP_PAGE_PATHS.SETTINGS,
    },
    {
      label: 'BASIC DETAILS',
    },
  ],
  SUPPLIERS_DASHBOARD: [
    {
      label: APP_BREADCRUMB_LABELS.SUPPLIERS,
      url: APP_PAGE_PATHS.SUPPLIERS_LIST,
    },
  ],
  PRODUCT_DETAILS: [
    {
      label: APP_BREADCRUMB_LABELS.PRODUCTS,
      url: APP_PAGE_PATHS.PRODUCTS_LIST,
    },
    // {
    //   label: APP_BREADCRUMB_LABELS.DETAILS,
    // },
  ],
}

export const getBreadcrumbsForOrders = (pathname) => {
  return [
    {
      label: APP_BREADCRUMB_LABELS.HOME,
      url: APP_PAGE_PATHS.ORDERS_DASHBOARD,
    },
    {
      label: setPageBreadCrumbLabel(pathname),
      isDisabled: true,
      url: '',
    },
  ]
}
