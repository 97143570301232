import React from 'react'
import { Tab as TabUI, TabItem } from '@teamfabric/copilot-ui'

type TabProps = {
  data: any
  component: any
  setLabel: (num: number) => void
  activeIndex?: number
}

const Tab: React.FC<TabProps> = ({
  data,
  component,
  setLabel,
  activeIndex,
}) => {
  return (
    <TabUI
      tabChangeHandler={(num) => setLabel(num)}
      customSetActiveIndex={(num) => setLabel(num)}
      customActiveIndex={activeIndex}
    >
      {data.length > 0 &&
        data.map(({ label }) => (
          <TabItem label={label} key={label}>
            {component}
          </TabItem>
        ))}
    </TabUI>
  )
}

export default Tab
