export const formatAddress = (address) => {
  const { name1, name2, street1, city, province, postal_code, country } =
    address
  return (
    <>
      <p className='body1-regular m-0'>{name1}</p>
      {address?.name2 && <p className='body1-regular m-0'> {name2} </p>}
      <p className='body1-regular m-0'>{street1}</p>
      <p className='body1-regular m-0'>
        {city}, {province}, {postal_code}, {country}{' '}
      </p>
    </>
  )
}
