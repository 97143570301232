import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import FilterChips from 'organisms/filterChips'
import {
  setFilters,
  setStatusOptions,
  setTopicOptions,
  setCreatedAt,
  resetAllFilters,
} from 'store/shopifySlice'
import {
  handleCheckboxFilterChange,
  handleDateFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { SHOPIFY_WEBHOOK_HISTORY } from '../../../constants'

type ChildProps = {
  params: any
}

const WebhookFilter: React.FC<ChildProps> = ({ params }) => {
  const [statusCounter, setStatusCounter] = useState(0)
  const [topicCounter, setTopicCounter] = useState(0)
  const [createdAtCounter, setCreatedAtCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.shopify.statusOptions
  )

  const topicCheckboxOptions = useSelector(
    (state: RootState) => state.shopify.topicOptions
  )

  const createdAt = useSelector((state: RootState) => state.shopify.createdAt)

  const isAFilterApplied = useMemo(() => {
    return statusCounter + createdAtCounter + topicCounter > 0
  }, [statusCounter, createdAtCounter, topicCounter])

  // Define respective filterKey fns
  const filterActions = {
    status: {
      action: setStatusOptions,
      options: statusCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setStatusCounter,
      chipName: SHOPIFY_WEBHOOK_HISTORY.STATUS.NAME,
    },
    topic: {
      action: setTopicOptions,
      options: topicCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setTopicCounter,
      chipName: SHOPIFY_WEBHOOK_HISTORY.TOPIC.NAME,
    },
    created_at_gte: {
      setFilterCount: setCreatedAtCounter,
      dateRange: createdAt,
      action: setCreatedAt,
      filterType: FILTERS.TYPES.DATE,
      chipName: SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${SHOPIFY_WEBHOOK_HISTORY.STATUS.NAME}-filter`}
          label={SHOPIFY_WEBHOOK_HISTORY.STATUS.NAME}
          data={statusCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={statusCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <CheckboxFilter
          dataTestid={`${SHOPIFY_WEBHOOK_HISTORY.TOPIC.NAME}-filter`}
          label={SHOPIFY_WEBHOOK_HISTORY.TOPIC.NAME}
          data={topicCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.TOPIC.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={topicCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.TOPIC.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <DateFilter
          dataTestid={`${SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.NAME}-filter`}
          label={SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_START,
              date,
            })
          }}
          defaultValue={createdAt}
          filterCount={createdAtCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: SHOPIFY_WEBHOOK_HISTORY.CREATED_AT.PARAM_START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default WebhookFilter
