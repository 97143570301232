import React from 'react'
import { Card, Table, Button } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import { TableProps } from 'atoms/table/table'

type ChildProps = {
  loading?: boolean
  header: any
  tableProps: TableProps
  skeletonRowsNumber?: number
  showCollapse?: boolean
  open?: boolean
  customClassName?: string
  headerWithButtons?: boolean
  headerButtonText?: string
  onEdit?: () => void
}

const defaultProps: ChildProps = {
  open: true,
  skeletonRowsNumber: 3,
  header: undefined,
  tableProps: {
    data: [],
    columns: [],
  },
}

const CardWithTable: React.FC<ChildProps> = (props) => {
  const CardContent = () => {
    return <Table {...props.tableProps} />
  }

  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          num={props.skeletonRowsNumber}
          showCollapse={props.showCollapse}
          showDivider={false}
          open={props.open}
        />
      ) : props.headerWithButtons ? (
        <Card
          headerContent={
            <div className='cardHeaderWithButton'>
              <div className='heading'>{props.header}</div>
              <Button
                onClick={() => props.onEdit()}
                text={props.headerButtonText ? props.headerButtonText : 'Edit'}
                variant='secondary'
              />
            </div>
          }
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={false}
          bodyContent={<CardContent />}
        />
      ) : (
        <Card
          headerContent={props.header}
          showCollapse={props.showCollapse}
          open={props.open}
          showDivider={false}
          bodyContent={<CardContent />}
        />
      )}
    </div>
  )
}

CardWithTable.defaultProps = defaultProps

export default CardWithTable
