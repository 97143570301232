import React, { useEffect, useMemo, useState } from 'react'
import { postConnectionMemo } from 'api/connection'
import { MultiLineTextArea, Dropdown, Modal, CheckboxGroup } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type formDataTypes = {
  brand: any
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const AvailabilityOptions = [
  { id: '1', label: 'Retailer only', value: 'retailer' },
  { id: '2', label: 'Supplier only', value: 'brand' },
  { id: '3', label: 'Retailer & Supplier', value: 'any' },
]

const AddNoteModal: React.FC<ChildProps> = ({ initialValues, ...props }) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [noteMessage, setNoteMessage] = useState(undefined)
  const [visibilityType, setVisibilityType] = useState(AvailabilityOptions[0])
  const [notifyPeople, setNotifyPeople] = useState(true)

  const context = useSelector((state: RootState) => state.context.metadata)

  const UpdateConnection = async () => {
    try {
      setLoading(true)
      await postConnectionMemo({
        connectionId: props.connectionId,
        body: {
          visibility: visibilityType.value,
          text: noteMessage,
          notify_people: notifyPeople,
        },
      })
      showToast({
        label: 'Note saved!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error adding the note: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  const notifyLabel = () => {
    const { brand } = initialValues
    const partnerName = context?.tenant?.name
    return useMemo(() => {
      let label = `Send email confirmation to ${brand.name} & ${partnerName}`

      if (visibilityType.value === 'retailer') {
        label = `Send email confirmation to ${partnerName}`
      } else if (visibilityType.value === 'brand') {
        label = `Send email confirmation to ${brand.name}`
      }

      return label
    }, [visibilityType])
  }

  return (
    <>
      <Modal
        headerText='Add Note'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => UpdateConnection(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <MultiLineTextArea
          label='Message'
          width='100%'
          inputProps={{
            value: noteMessage,
            onChange: (e) => setNoteMessage(e.target.value),
            placeholder: 'Message...',
          }}
        />
        <div className='divider-4' />
        <Dropdown
          width='100%'
          options={AvailabilityOptions}
          value={visibilityType}
          placeholder='Select availability'
          label='Message Availability'
          onChange={(option: any) => {
            setVisibilityType(option)
          }}
        />
        <div className='divider-4' />
        <CheckboxGroup
          label={'Message Notification'}
          name='notification'
          options={[
            {
              id: 1,
              value: '1',
              label: notifyLabel(),
              checked: { notifyPeople },
            },
          ]}
          onChange={(e) => setNotifyPeople(e.target.checked)}
        />
      </Modal>
    </>
  )
}

export default AddNoteModal
