import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Modal from 'atoms/modal/modal'
import {
  exportProposalToFile,
  exportProposalProducts,
  exportProposalToFabric,
} from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import SelectTemplates from 'molecules/selectDropdowns/selectTemplates'
import RadioGroup from 'atoms/input/radioGroup'
import Dropdown from 'atoms/dropdown/dropdown'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
  platformAccounts: any
}

const exportMethodOptions = [
  {
    label: 'Export proposal to template file',
    value: 'file',
  },
  {
    label: 'Export proposal to platform',
    value: 'platform',
  },
]

const ExportProposalModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectedFileTemplate, setSelectedFileTemplate] = useState<any>()
  const [selectedPlatformTemplate, setSelectedPlatformTemplate] =
    useState<any>()
  const [exportMethod, setExportMethod] = useState('file')
  const [selectedPlatform, setSelectedPlatform] = useState<any>()

  const showToast = useToast()

  const exportProposal = async () => {
    try {
      setLoading(true)
      if (exportMethod === 'file') {
        await exportToFile()
      } else if (selectedPlatform.label === 'fabric') {
        await exportToFabricPlatform()
      } else {
        await exportToPlatform()
      }
    } catch (error) {
      showToast({
        label: `Error exporting the proposal, please try again!`,
        isDismissable: true,
        id: '1',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  const exportToFile = async () => {
    await exportProposalToFile({
      id: props.proposal.id,
      params: { template_id: selectedFileTemplate.id },
    })
    showToast({
      label: `Your proposal, "${props.proposal?.name}" export scheduled! Please allow 5-10 minutes for delivery.`,
      isDismissable: true,
      id: '1',
    })
  }

  const exportToPlatform = async () => {
    const requestBody = {
      proposal_id: props.proposal.id,
    } as {
      proposal_id: any
      shopify_template_id?: any
      bigcommerce_template_id?: any
    }
    if (selectedPlatform.label === 'shopify') {
      requestBody.shopify_template_id = selectedPlatformTemplate.id
    } else if (selectedPlatform.label === 'bigcommerce') {
      requestBody.bigcommerce_template_id = selectedPlatformTemplate.id
    }
    await exportProposalProducts({
      platformCode: selectedPlatform.label,
      platformId: selectedPlatform.id,
      body: requestBody,
    })
    showToast({
      label: `Your proposal, "${props.proposal?.name}" publish attempt scheduled! Please allow 5-10 minutes for delivery.`,
      isDismissable: true,
      id: '1',
    })
  }

  const exportToFabricPlatform = async () => {
    const requestBody = {
      proposal_id: props.proposal.id,
      fabric_template_id: selectedPlatformTemplate.id,
    }
    await exportProposalToFabric({
      platformId: selectedPlatform.id,
      body: requestBody,
    })
    showToast({
      label: `Your proposal, "${props.proposal?.name}" publish attempt scheduled! Please allow 5-10 minutes for delivery.`,
      isDismissable: true,
      id: '1',
    })
  }

  const FileExportDropdown = () => {
    return (
      <SelectTemplates
        handleUpdate={(option) => setSelectedFileTemplate(option)}
        selectedTemplate={selectedFileTemplate}
        direction='export'
      />
    )
  }

  const PlatformExportOptions = () => {
    return (
      <>
        <Dropdown
          required
          label='Platform'
          width='100%'
          value={selectedPlatform}
          onChange={(option: any) => {
            setSelectedPlatform(option)
          }}
          options={props.platformAccounts.map((el) => ({
            id: el.id,
            label: el.platform.code,
          }))}
          placeholder='Select Platform'
          disabled={loading}
        />
        <div className='divider-4' />
        <SelectTemplates
          handleUpdate={(option) => setSelectedPlatformTemplate(option)}
          selectedPlatform={selectedPlatform}
          selectedTemplate={selectedPlatformTemplate}
          direction='export'
        />
      </>
    )
  }

  const CompleteProposalExport = () => {
    return (
      <>
        <RadioGroup
          label='Export Method'
          name='exportMethod'
          onChange={(e) => {
            setExportMethod(e.target.value)
          }}
          options={exportMethodOptions}
          defaultValue={exportMethod}
        />
        <div className='divider-4' />
        {exportMethod === 'file' ? (
          <FileExportDropdown />
        ) : (
          <PlatformExportOptions />
        )}
      </>
    )
  }

  const isReady = useMemo(() => {
    if (exportMethod === 'file' && selectedFileTemplate) return true
    if (
      exportMethod === 'platform' &&
      selectedPlatform &&
      selectedPlatformTemplate
    )
      return true
  }, [
    exportMethod,
    selectedFileTemplate,
    selectedPlatform,
    selectedPlatformTemplate,
  ])

  return (
    <>
      <Modal
        headerText='Export proposal'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Cancel',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => exportProposal(),
            text: 'Export',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading || !isReady,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          {props.proposal.status === 'completed' ? (
            <CompleteProposalExport />
          ) : (
            <FileExportDropdown />
          )}
        </>
      </Modal>
    </>
  )
}

export default ExportProposalModal
