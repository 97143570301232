import React from 'react'
import { VerticalProgressStepper as ProgressStepperUI } from '@teamfabric/copilot-ui'

type VerticalProgressStepperProps = {
  steps: any
  id?: string
  dataTestid?: string
  aria?: any
}

const VerticalProgressStepper: React.FC<VerticalProgressStepperProps> = ({
  ...props
}) => {
  return <ProgressStepperUI {...props} />
}

export default VerticalProgressStepper
