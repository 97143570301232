import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataProps } from '@teamfabric/copilot-ui'
import { RootState } from 'store'
import { PageHeader } from 'atoms'
import SearchBar from 'molecules/search/searchBarTable'
import { getConnectionLocation } from 'api/connectionLocation'
import SupplierLocationsTable from '../components/table/locations'
import { setLocationsSearch } from 'store/suppliersSlice'
import { GridCol, GridRow, Box } from 'atoms'
import {
  SupplierPageTypes,
  breadcrumbs,
} from 'lib/constants/breadcrumbsHandler'

const SuppliersLocationsTemplate: FC = () => {
  const params = useSelector(
    (state: RootState) => state.suppliers.locationParams
  )

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [loading, setLoading] = useState(true)

  const [totalRows, setTotalRows] = useState(0)

  useEffect(() => {
    if (params) {
      ;(async () => {
        setLoading(true)
        const filteredParams = {}
        // skipping empty keys
        for (const key in params) {
          if (params[key] !== '') {
            filteredParams[key] = params[key]
          }
        }
        const { data: data } = await getConnectionLocation({
          params: { ...filteredParams },
        })
        setTotalRows(data?.count)
        setTableData(data?.results)
        setLoading(false)
      })()
    }
  }, [params])

  useEffect(() => {
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.supplierPages({ type: SupplierPageTypes.Invitations })
        ),
      0
    )
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeader h1Text='Supplier Locations' />
          <SearchBar
            placeholder='Search for a location by supplier name'
            setSearch={setLocationsSearch}
            className='mt-4'
          />
          <SupplierLocationsTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default SuppliersLocationsTemplate
