import React, { useState, ChangeEvent } from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import Input from 'atoms/input/input'
import SingleDatePicker from 'atoms/input/singleDatePicker'

interface ChildProps {
  handleEdit: (data: any) => void
}

const defaultValues = {
  purchaseOrderNumber: '',
  orderDate: new Date(),
  retailerOrderNumber: null,
  customerOrderNumber: null,
}

const OrderDetails: React.FC<ChildProps> = ({ handleEdit }) => {
  const [formData, setFormData] = useState(defaultValues)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
    handleEdit(formData)
  }

  const handleDateSelection = (date) => {
    setFormData({
      ...formData,
      orderDate: date,
    })
    handleEdit(formData)
  }

  const fieldsData = [
    {
      id: 1,
      label: 'Purchase Order Number',
      value: formData.purchaseOrderNumber,
      name: 'purchaseOrderNumber',
      placeholder: 'Purchase order number',
      required: true,
      type: 'number',
    },
    {
      id: 2,
      label: 'Order Date',
      value: formData.orderDate,
      name: 'orderDate',
      placeholder: 'Type or select a date...',
      required: true,
      type: 'date',
    },
    {
      id: 3,
      label: 'Retailer Order Number',
      value: formData.retailerOrderNumber,
      name: 'retailerOrderNumber',
      placeholder: 'Retailer order number',
      required: true,
      type: 'number',
    },
    {
      id: 4,
      label: 'Customer Order Number',
      value: formData.customerOrderNumber,
      name: 'customerOrderNumber',
      placeholder: 'Customer order number',
      required: false,
      type: 'number',
    },
  ]

  const NumberInput = (key) => {
    const fieldKeys = fieldsData.find((el) => el.name === key)
    return (
      <Input
        width='100%'
        label={fieldKeys.label}
        required={fieldKeys.required}
        inputProps={{
          name: fieldKeys.name,
          value: formData[fieldKeys.name],
          placeholder: fieldKeys.placeholder,
          onChange: handleChange,
        }}
        maskOptions={{
          regex: '\\d*(\\.)?\\d*',
        }}
      />
    )
  }

  const DatePickerInput = ({ label }) => {
    return (
      <SingleDatePicker
        label={label}
        minDate={new Date()}
        value={formData.orderDate}
        defaultValue={formData.orderDate}
        format='MM/dd/yyyy'
        onSelect={(date: Date) => handleDateSelection(date)}
      />
    )
  }

  return (
    <Card
      headerContent={
        <div className='styledCardHeader'>
          <h5 className='heading'>Order Details</h5>
          <div className='subHeading'>Enter information about your order.</div>
        </div>
      }
      showCollapse={false}
      open
      showDivider={false}
      bodyContent={
        <>
          <div className='mb-4'>
            <GridRow padding={false}>
              <GridCol lg={3} md={3} sm={3}>
                {NumberInput('purchaseOrderNumber')}
              </GridCol>
              <GridCol lg={3} md={3} sm={3}>
                <DatePickerInput {...fieldsData[1]} />
              </GridCol>
            </GridRow>
          </div>
          <GridRow padding={false}>
            <GridCol lg={3} md={3} sm={3}>
              {NumberInput('retailerOrderNumber')}
            </GridCol>
            <GridCol lg={3} md={3} sm={3}>
              {NumberInput('customerOrderNumber')}
            </GridCol>
          </GridRow>
        </>
      }
    />
  )
}

export default OrderDetails
