import React, { useState, useEffect, useMemo } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, Banner } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { updatePlatformAccounts } from 'api/platformAccount'
import SelectLocations from './selectLocation'
import { dropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  loading: boolean
  account: any
  loadAccount: (quiet?: boolean) => void
}

const LocationsCard: React.FC<ChildProps> = ({
  loading,
  account,
  loadAccount
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [location, setLocation] = useState(dropDownInitialObj)
  const [selectedLocation, setSelectedLocation] = useState(dropDownInitialObj)

  useEffect(() => {
    if (!loading && account.credentials) {
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'location_id' && credential[key].values[0].value) {
            const parsedLocation = {
              label: credential[key].label,
              id: credential[key].values[0].id,
              value: credential[key].values[0].value
            }
            setLocation(parsedLocation)
          }
        })
      })
    }
  }, [!loading, account])

  const credential = useMemo(() => {
    if (!loading && account.credentials) {
      if (account.credentials.length === 0) return null
      let found = null
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'location_id') {
            found = credential[key]
          }
        })
      })
      return found
    }
  }, [!loading, account])

  const updateLocation = async () => {
    const body = {
      credentials: [{
        credential: {
          code: 'location_id',
        },
        ...(credential !== null && { id: credential.id }),
        value: location.id
      }]
    }
    const toastMessage = 'Location updated successfully!'
    try {
      await updatePlatformAccounts({ id: id, body })
      showToast({
        label: toastMessage,
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data ? response.data : 'An error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      loadAccount(true)
    }
  }

  const ShowLocationBanner = () => (
    <div className='mt-4'>
      <Banner
        label={
          <>
            <p>Inventory is syncing with location - {location?.value}</p>
            <p>This integration is currently enabled.</p>
          </>
        }
        variant='success'
      />
    </div>
  )

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Locations</span>}
            h2Text={
              <span className='body2-regular'>
                Set which inventory location to use when syncing inventory.
              </span>
            }
            primaryButtons={[
              {
                onClick: () => updateLocation(),
                text: 'Save',
                variant: 'secondary',
              },
            ]}
            dataTestid='shopify-location-header'
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            Enable this service if you would like to inform your customers through Shopify whenever an order is shipped and tracking information is available.
          </div>
          {credential !== null && <ShowLocationBanner />}
          <div className='divider-4' />
          <SelectLocations handleUpdate={(option) => {
            setSelectedLocation(option)
          }} />
        </Box>
      }
    />
  )
}

export default LocationsCard
