import { useToast } from '@teamfabric/copilot-ui'
import { removeProposalVariants } from 'api/proposals'
import { Modal } from 'atoms'
import Banner from 'atoms/banner/banner'
import { ButtonVariants } from 'atoms/button/button'
import Dropdown from 'atoms/dropdown/dropdown'
import { ICONS } from 'lib/constants'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type RemoveItemsModalProps = {
  count: number
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  itemIdArray?: any
  reload: (quiet?: boolean) => void
}

const ReasonOptions = [
  {
    id: 'market',
    label: 'Market fit',
  },
  {
    id: 'pricing',
    label: 'Costs, pricing & margin concerns',
  },
  {
    id: 'inventory',
    label: 'Inventory concerns',
  },
]

const RemoveProposalItemsModal: React.FC<RemoveItemsModalProps> = ({
  count,
  showModal,
  setShowModal,
  itemIdArray,
  reload,
}) => {
  const [processing, setProcessing] = useState(false)
  const [reason, setReason] = useState({ id: '', label: '' })
  const getItemsCopy = () => {
    let copy = ''
    if (count === 1) {
      copy = 'the item'
    } else {
      copy = `${count} items`
    }
    return copy
  }

  const showToast = useToast()

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const stateSelected = useSelector(
    (state: RootState) => state.proposalItems.selected
  )

  const selected = stateSelected.length ? stateSelected : itemIdArray

  const removeItems = async () => {
    const body = []
    selected.forEach((itemId) => {
      if (proposalDetails?.status === 'draft') {
        body.push({ variant: { id: itemId }, delete: 1 })
      } else {
        body.push({
          variant: { id: itemId },
          reject_reason: reason.id,
          reject: 1,
        })
      }
    })

    try {
      setProcessing(true)
      await removeProposalVariants({ id: proposalDetails?.id, body: body })
      showToast({
        label: `Item removed!`,
        isDismissable: true,
        id: '1',
      })
      reload(true)
    } catch (error) {
      showToast({
        label: `This item cannot be removed (likely because of the status of the proposal).`,
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setShowModal(false)
      setProcessing(false)
    }
  }

  return (
    <>
      <Modal
        headerText={`Remove ${count === 1 ? 'item' : 'items'}`}
        description=''
        onClose={() => setShowModal(false)}
        isVisible={showModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowModal(false),
            text: "No, Don't remove",
            variant: ButtonVariants.TERTIARY_DARK,
          },
          {
            dataTestid: '',
            onClick: () => removeItems(),
            text: `Yes, Remove Item`,
            variant: ButtonVariants.DESTRUCTIVE,
            isDisabled: !reason.id || processing,
            icon: ICONS.TRASH,
          },
        ]}
        dataTestid={'remove-items-modal'}
        onBackdropClick={() => setShowModal(false)}
      >
        <p className='body1_regular grey-600 mb-4'>
          Are you sure you want to remove {getItemsCopy()}?
        </p>
        <Banner
          label={`If you remove the item from this proposal, they cannot be restored (and will have to be added in a different list).`}
          type='alert'
          variant='error'
        />
        <div className='divider-4' />
        <Dropdown
          label='Select reason'
          placeholder='Select reason'
          onChange={(e) => {
            setReason(e)
          }}
          options={ReasonOptions}
          value={reason}
          width='100%'
        />
      </Modal>
    </>
  )
}

export default RemoveProposalItemsModal
