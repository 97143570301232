import React, { useState, useMemo, useEffect } from 'react'
import {
  Box,
  Checkbox,
  Input,
  InputStepper,
  Modal,
  MultiLineTextArea,
} from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import { patchRule, postRule } from 'api/rulesets'
import { useParams } from 'react-router-dom'
import SelectAttribute from 'molecules/selectDropdowns/selectAttribute'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import SelectTemplateMappingsTarget from 'molecules/selectDropdowns/selectTemplateMappingsTarget'

type BaseProps = {
  showModal: boolean
  setShowModal: (show: boolean) => void
  reload: (quiet?: boolean) => void
  type: 'Create' | 'Edit'
  rules: any[]
}

type ChildProps = BaseProps &
  ({ type: 'Create'; selectedRow?: never } | { type: 'Edit'; selectedRow: any })

const RulesModal: React.FC<ChildProps> = ({
  showModal,
  setShowModal,
  reload,
  selectedRow,
  type,
  rules,
}) => {
  const { id, ruleGroupId } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [selectedAttribute, setSelectedAttribute] = useState(dropDownInitialObj)
  const [selectedTarget, setSelectedTarget] = useState(dropDownInitialObj)
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')
  const [description, setDescription] = useState('')
  const [isRequired, setIsRequired] = useState(false)

  const nextAvailablePosition = useMemo(() => {
    if (rules.length === 0) return 1
    const positions = rules.map((rule) => rule.position)
    const maxPosition = Math.max(...positions)
    return maxPosition + 1
  }, [rules])

  const onSave = async () => {
    const body = {
      name: name,
      description: description,
      position: position.length > 0 ? position : null,
      is_required: isRequired,
      attribute: {
        id: selectedAttribute.id,
      },
      rule_group: {
        id: ruleGroupId,
      },
      target: selectedTarget?.value,
    }
    try {
      setSaving(true)
      if (type === 'Create') {
        await postRule({ id: id, body })
      } else {
        await patchRule({ id: id, ruleId: selectedRow.id, body })
      }
      setShowModal(false)
      reload()
      showToast({
        label: 'Rule added successfully.',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error : ${errorMessage}`
          : 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setSaving(false)
      resetForm()
    }
  }

  const isReady = useMemo(() => {
    return (
      name !== '' &&
      selectedAttribute?.id &&
      selectedTarget?.id &&
      position.length > 0
    )
  }, [name, selectedAttribute, selectedTarget, position])

  const resetForm = () => {
    setName('')
    setDescription('')
    setPosition('')
    setSelectedAttribute(dropDownInitialObj)
    setSelectedTarget(dropDownInitialObj)
    setIsRequired(false)
  }

  const isUniquePosition = useMemo(() => {
    if (rules && rules.length > 0) {
      return !rules.some((rule) => {
        if (type === 'Edit' && selectedRow) {
          return (
            rule.position === parseInt(position) &&
            rule.position !== selectedRow.position
          )
        }
        return rule.position === parseInt(position)
      })
    } else return true
  }, [position])

  useEffect(() => {
    if (type === 'Edit' && selectedRow) {
      setName(selectedRow?.name)
      setPosition(selectedRow?.position)
      setDescription(selectedRow?.description)
      setIsRequired(selectedRow?.is_required)
    }
  }, [selectedRow, type])

  return (
    <Modal
      headerText={type === 'Create' ? 'Create Rule' : 'Update Rule'}
      description=''
      size='small'
      onClose={() => {
        resetForm()
        setShowModal(false)
      }}
      isVisible={showModal}
      footerButtons={[
        {
          dataTestid: 'close-modal-button',
          onClick: () => {
            resetForm()
            setShowModal(false)
          },
          text: 'Close',
          variant: 'secondary',
          isDisabled: saving,
        },
        {
          dataTestid: 'save-modal-button',
          onClick: () => onSave(),
          text: type === 'Create' ? 'Create' : 'Save',
          variant: 'primary',
          isDisabled: !isReady || saving,
        },
      ]}
      dataTestid={''}
      onBackdropClick={() => {
        resetForm()
        setShowModal(false)
      }}
    >
      <Box flex={{ flexDirection: 'column' }} gap={4}>
        <SelectAttribute
          handleUpdate={(option) => {
            setSelectedAttribute(option)
          }}
          fieldLabel='Select attribute'
          selectedAttributeId={selectedRow?.attribute.id}
        />
        <SelectTemplateMappingsTarget
          handleUpdate={(option) => {
            setSelectedTarget(option)
          }}
          fieldLabel='Select target'
          targetValue={selectedRow?.target}
        />
        <Input
          width='100%'
          label='Name'
          required
          inputProps={{
            value: name,
            onChange: ({ target: { value } }) => setName(value),
            placeholder: 'Name',
          }}
        />
        <InputStepper
          label='Position'
          width='50%'
          required
          max={100}
          min={type === 'Create' ? nextAvailablePosition : 1}
          onValueChange={(newVal) => {
            const parsedValue =
              typeof newVal === 'string' ? newVal : newVal.toString()
            setPosition(parsedValue)
          }}
          stepSize={1}
          value={parseInt(position)}
          messageType={!isUniquePosition ? 'error' : null}
          message={
            !isUniquePosition &&
            `A rule with position ${position} already exists on rulegroup_id: ${ruleGroupId}!`
          }
        />
        <MultiLineTextArea
          label='Description'
          width='100%'
          inputProps={{
            value: description,
            onChange: ({ target: { value } }) => setDescription(value),
            placeholder: 'Description',
          }}
        />
        <Checkbox
          label={'Mark as required?'}
          name={''}
          value={''}
          checked={isRequired}
          disabled={false}
          onChange={({ target: { checked } }) => {
            setIsRequired(checked)
          }}
        />
      </Box>
    </Modal>
  )
}

export default RulesModal
