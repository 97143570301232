import React, { useEffect, useState } from 'react'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import Input from 'atoms/input/input'

type ItemInfoProps = {
  item: any
  loading: boolean
  onSKUChange: (sku: string) => void
}

const UpdateSKU: React.FC<ItemInfoProps> = ({ item, loading, onSKUChange }) => {
  const [data, setItemInfoData] = useState<any[]>([])
  const [newSKU, setNewSKU] = useState('')

  useEffect(() => {
    formatProductDetailData(item)
  }, [loading])

  useEffect(() => {
    onSKUChange(newSKU)
  }, [newSKU])

  const formatProductDetailData = ({ id, brand, parent_identifier }) => {
    let formattedData = [
      {
        label: 'Item ID',
        value: id,
      },
      {
        label: 'Supplier',
        value: brand?.name,
      },
      {
        label: 'Supplier product SKU',
        value: parent_identifier ? parent_identifier : '---',
      },
    ]

    setItemInfoData(formattedData)
  }

  return (
    <>
      <div className='mb-4'>
        <ModalCard2Col loading={loading} header='Item details' data={data} />
        <br />
        <Input
          label={'New SKU'}
          width='100%'
          required={false}
          inputProps={{
            value: newSKU,
            onChange: (e) => setNewSKU(e.target.value),
          }}
        />
      </div>
    </>
  )
}

export default UpdateSKU
