import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, Card, Table } from 'atoms'
import SelectConnection from 'molecules/connections/selectConnectionDropdown'
import Button from 'atoms/button/button'
import OrderLineItemsModal from './orderLineItemsModal'
import EditDeleteTableRow from 'molecules/table/editDeleteTableRow'
import { getRetailerConnectionDetails } from 'api/connection'

interface ChildProps {
  handleEdit: (data: any) => void
}

enum ModalActionEnum {
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete',
}

const OrderLines: React.FC<ChildProps> = ({ handleEdit }) => {
  const [selectedOption, setSelectedOption] = useState<{
    id: number
    label: string
    brand: any
  } | null>(null)
  const [showOrderLineModal, setShowOrderLineModal] = useState(false)
  const [tableData, setTableData] = useState<Array<any>>([])
  const [orderLinesData, setOrderLinesData] = useState<Array<any>>([])
  const [netPriceIsRequired, setNetPriceIsRequired] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedLineItem, setSelectedLineItem] = useState(null)
  const [modalAction, setModalAction] = useState(ModalActionEnum.ADD)

  const handleUpdate = async (option) => {
    setLoading(true)
    if (orderLinesData.length === 0) {
      // set selected option and fetch details
      setSelectedOption(option)
      await fetchConnectionDetails(option.id)
    } else {
      // show modal to confirm, clear order lines and set option
      setOrderLinesData([])
      setTableData([])
      setSelectedOption(option)
      await fetchConnectionDetails(option.id)
    }
  }

  const fetchConnectionDetails = async (id) => {
    const { data } = await getRetailerConnectionDetails({ id })
    if (
      data.connection_fees &&
      data.connection_fees?.net_price_method === 'specified_in_order'
    ) {
      setNetPriceIsRequired(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    // parse order lines data as per request body
    const json = orderLinesData.map((line) => {
      return {
        quantity: line.quantity,
        price: line.price,
        net_price: line.itemCost,
        order_line_number: line.orderLineNo,
        variant: {
          id: line.variant.id,
        },
      }
    })
    handleEdit(json)
  }, [orderLinesData])

  const handleLineItems = (data) => {
    if (modalAction === 'Add') {
      addLineItem(data)
    } else if (modalAction === 'Edit') {
      editLineItem(data)
    } else if (modalAction === 'Delete') {
      deleteLineItem(data)
    }
  }

  // add line item
  const addLineItem = (data) => {
    setOrderLinesData((prevData) => [...prevData, data])
    setTableData((prevTableData) => [...prevTableData, createTableItem(data)])
  }

  // edit line item
  const editLineItem = (data) => {
    setOrderLinesData((prevData) =>
      prevData.map((line) =>
        line.randomUniqueId === data.randomUniqueId ? data : line
      )
    )
    setTableData((prevTableData) =>
      prevTableData.map((tableItem) =>
        tableItem.id === data.randomUniqueId
          ? {
              ...tableItem,
              data: createTableData(data),
            }
          : tableItem
      )
    )
  }

  // delete line item
  const deleteLineItem = (data) => {
    setOrderLinesData((prevData) =>
      prevData.filter((line) => line.randomUniqueId !== data.randomUniqueId)
    )
    setTableData((prevTableData) =>
      prevTableData.filter((tableItem) => tableItem.id !== data.randomUniqueId)
    )
  }

  const createTableItem = (data) => ({
    id: data.randomUniqueId,
    data: createTableData(data),
  })

  const createTableData = (data) => ({
    'Line No': { value: data.orderLineNo },
    'Item': {
      onRender: () => (
        <>
          <p className='m-0 body1-medium blue-500'>{data.variant.name}</p>
          <p className='m-0 grey-700'>Id {data.variant.id}</p>
        </>
      ),
    },
    'Quantity': { value: data.quantity },
    'Price': { value: data.price },
    'Net Price': { value: data.itemCost },
    'Actions': {
      value: { ...data },
      onRender: (data) => (
        <EditDeleteTableRow
          handleClick={(key) => handleActions(key, data.value)}
        />
      ),
    },
  })

  const handleActions = (key, data) => {
    setModalAction(key)
    setShowOrderLineModal(true)
    setSelectedLineItem(data)
  }

  const tableColumns = [
    {
      title: 'Line No',
      width: 120,
    },
    {
      title: 'Item',
    },
    {
      title: 'Quantity',
    },
    {
      title: 'Price',
    },
    {
      title: 'Net Price',
    },
    {
      title: 'Actions',
      width: 120,
    },
  ]

  return (
    <>
      <Card
        headerContent={
          <div className='styledCardHeader'>
            <h5 className='heading'>Order Lines</h5>
            <div className='subHeading'>
              Add variants, quantity and price information.
            </div>
          </div>
        }
        showCollapse={false}
        open
        showDivider={false}
        bodyContent={
          <>
            <div className='mb-4'>
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <SelectConnection handleUpdate={handleUpdate} />
                </GridCol>
              </GridRow>
            </div>
            <Button
              text='Add New Line'
              icon='Add'
              variant='secondary'
              isDisabled={loading}
              onClick={() => {
                setSelectedLineItem(null)
                setShowOrderLineModal(true)
              }}
            />
            <div className='mt-4'>
              <Table
                columns={tableColumns}
                data={tableData}
                emptyTableText='No lines has been registered yet.'
              />
            </div>
          </>
        }
      />
      {/* Modals */}
      {showOrderLineModal && (
        <OrderLineItemsModal
          brandID={selectedOption.brand.id}
          setShowOrderLineModal={setShowOrderLineModal}
          showOrderLineModal={showOrderLineModal}
          handleUpdate={(data) => handleLineItems(data)}
          netPriceIsRequired={netPriceIsRequired}
          initialValues={selectedLineItem}
          modalAction={modalAction}
        />
      )}
    </>
  )
}

export default OrderLines
