import { GridCol, GridRow } from 'atoms'
import { useEffect, useState } from 'react'
import PushNotification from './pushNotification'

interface AvailableNotification {
  action: string
  title: string
  text: string
  context: string[]
}

const availableNotifications: AvailableNotification[] = [
  {
    action: 'Shopify Order Skipped Due To Fraud Risk',
    title: 'Shopify Skipped Order',
    text: 'If you have any high/medium risk Shopify order that is skipped from importing, the platform will send notification to the distribution list specified below.',
    context: ['retailer'],
  },
]

type ChildProps = {
  pushNotifications: any
  fetchNotifications: () => {}
}

const ShopifyNotifications: React.FC<ChildProps> = ({
  pushNotifications,
  fetchNotifications,
}) => {
  const [retailerNotifications, setRetailerNotifications] = useState([])

  useEffect(() => {
    const merchantNotifications = availableNotifications.filter(
      (notification) => notification.context.includes('retailer')
    )
    setRetailerNotifications(merchantNotifications)
  }, [pushNotifications])

  return (
    <div>
      <GridRow padding={false}>
        <GridCol lg={6} md={6} sm={12}>
          {retailerNotifications.map((item) => (
            <PushNotification
              key={item.action}
              action={item.action}
              header={item.title}
              description={item.text}
              customClassName='mt-4 mb-4'
              pushNotifications={pushNotifications}
              reloadNotifications={fetchNotifications}
            />
          ))}
        </GridCol>
      </GridRow>
    </div>
  )
}

export default ShopifyNotifications
