import React from 'react'
import Card, { CardProps } from 'atoms/card/card'
import CardLoading from 'molecules/loading/cardLoading'
import Message from 'molecules/message'

type ChildProps = {
  loading?: boolean
  cardProps: CardProps
  memoData: any
  skeletonRowsNumber?: number
  customClassName?: string
  emptyTableText?: string
  showAddMemo?: boolean
  orderId: number
}

const CardWithMemos: React.FC<ChildProps> = ({
  showAddMemo = true,
  orderId,
  loading,
  skeletonRowsNumber = 3,
  customClassName,
  emptyTableText,
  cardProps,
  memoData,
}) => {
  return (
    <div className={customClassName}>
      {loading ? (
        <CardLoading
          label={cardProps.headerContent}
          num={skeletonRowsNumber}
          showCollapse={cardProps.showCollapse}
          showDivider={false}
          open={cardProps.open}
        />
      ) : (
        <Card
          {...cardProps}
          bodyContent={
            <Message
              memoData={{ orderId, memos: memoData }}
              emptyTableText={emptyTableText}
              showAddMemo={showAddMemo}
            />
          }
        />
      )}
    </div>
  )
}

export default CardWithMemos
