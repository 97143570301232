import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { removeProposalProduct, removeProposalProducts } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import Banner from 'atoms/banner/banner'
import Dropdown from 'atoms/dropdown/dropdown'
import { MODALS } from 'molecules/modals/constants'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useToastUtils } from 'lib/utils/toast'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  product?: any
  loadProducts?: (quiet?: boolean) => void
  updateProduct?: (data: any) => void
}

const RemoveProposalProductModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState({ id: null, label: null })
  const { id } = useParams()
  const { showSuccessToast, showErrorToast } = useToastUtils()

  const stateSelected = useSelector(
    (state: RootState) => state.proposalProducts.selected
  )

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const onRemove = async () => {
    setLoading(true)
    try {
      if (stateSelected.length > 0) {
        await removeProducts()
      } else if (props.product) {
        await removeProduct()
      }
    } catch (error) {
      showErrorToast(
        `Error removing the product: ${error?.response?.data?.detail}`
      )
    } finally {
      props.setShowModal(false)
      setLoading(false)
    }
  }

  const removeProducts = async () => {
    const json = []
    stateSelected.forEach((productId) => {
      if (proposalDetails?.status === 'draft') {
        json.push({ product: { id: productId }, delete: 1 })
      } else {
        json.push({ product: { id: productId }, reject_reason: reason.id })
      }
    })

    const { data } = await removeProposalProducts({
      id: id,
      body: json,
    })
    if (data.total_success > 0) {
      showSuccessToast('Products, has been removed successfully.')
      props?.loadProducts()
    } else {
      showSuccessToast(
        'Products has not been removed, please verify status and try again.'
      )
    }
  }

  const removeProduct = async () => {
    const { data } = await removeProposalProduct({
      id: id,
      productId: props.product?.id,
      body: {
        reject_reason: reason.id,
      },
    })
    if (data.id && data?.proposal_status?.id) {
      showSuccessToast(
        `Your product, "${props.product?.parent_name}", has been removed successfully.`
      )
      props?.updateProduct?.(data)
      props?.loadProducts()
    }
  }

  const closeModal = () => {
    props.setShowModal(false)
    setReason({ id: null, label: null })
  }

  return (
    <>
      <Modal
        headerText='Remove product'
        onClose={() => closeModal()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => closeModal(),
            text: "No, Don't Remove",
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => onRemove(),
            text: 'Yes, Remove Product',
            variant: VARIANTS.DESTRUCTIVE,
            icon: 'Trash',
            isDisabled: loading || !reason.id,
            dataTestid: 'yes-remove-product',
          },
        ]}
        onBackdropClick={() => closeModal()}
      >
        <>
          <p className='body1_regular grey-600 mb-4'>
            Are you sure you want to remove the product?
          </p>
          <div className='mt-4'>
            <Dropdown
              dataTestid='remove-product-dropdown'
              disabled={loading}
              options={MODALS.REMOVE_PROPOSAL_ITEMS.REASON_DROPDOWN.OPTIONS}
              placeholder={
                MODALS.REMOVE_PROPOSAL_ITEMS.REASON_DROPDOWN.PLACEHOLDER
              }
              label='Reason'
              value={reason}
              width='100%'
              onChange={(e: any) => {
                setReason(e)
              }}
            />
          </div>
        </>
      </Modal>
    </>
  )
}

export default RemoveProposalProductModal
