import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

type TableCaptionProp = {
  alignSelf?: any
}
export const StyledTableCaption = styled.div<TableCaptionProp>`
  ${theme.typography.body2.regular};
  color: ${theme.color.grey[600]};
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'auto')};
`

export const StyledTableSelectInfoText = styled.div`
  ${theme.typography.body1.regular}
  color: ${theme.color.grey[1000]};
`

export const StyledActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
`

export const StyledActionButton = styled.button`
  display: flex;
  border: none;
  width: 100%;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${theme.color.grey[0]};
  ${theme.typography.body1.regular};
  cursor: pointer;
  height: 40px;
  &:first-child {
    border-radius: 4px 4px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  &:hover {
    background: ${theme.color.blue[50]};
  }
`
