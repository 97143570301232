export const JOBS_LIST = {
  JOB: {
    NAME: 'Job Type',
    PARAM: 'module_name',
  },
  HAS_ERRORS: {
    NAME: 'Has Errors',
    PARAM: 'has_errors',
    FILTER_MODAL_NAME: 'Has Errors?',
    VALUES: {
      YES: {
        NAME: 'Yes',
        VALUE: '1',
      },
      NO: {
        NAME: 'No',
        VALUE: '0',
      },
    }
  },
  CREATED_AT: {
    NAME: 'Created At',
    PARAM_START: 'created_at_gte',
    PARAM_END: 'created_at_lte',
  },
}

export const JOBS_LIST_FILTER_OPTIONS = {
  MODULE_OPTIONS: [
    {
      id: 'import_feed',
      label: 'Import Products',
      value: 'import_feed',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'process_shipment_file_upload',
      label: 'Import Shipments',
      value: 'process_shipment_file_upload',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'process_invoice_file_upload',
      label: 'Import Invoices',
      value: 'process_invoice_file_upload',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'push_product',
      label: 'Publish Products',
      value: 'push_product',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'pull_products',
      label: 'Pull Products',
      value: 'pull_products',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'push_inventory',
      label: 'Push Inventory',
      value: 'push_inventory',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'push_order',
      label: 'Push Order',
      value: 'push_order',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'ingest_810',
      label: 'EDI: Import Invoice (810)',
      value: 'ingest_810',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'ingest_856',
      label: 'EDI: Import ASN (856)',
      value: 'ingest_856',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'ingest_846',
      label: 'EDI: Inventory (846)',
      value: 'ingest_846',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'ingest_855',
      label: 'EDI: Acknowledgement (855)',
      value: 'ingest_855',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'products/update',
      label: 'Shopify: Product Updated',
      value: 'products/update',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'orders/paid',
      label: 'Shopify: Order Import (Paid)',
      value: 'orders/paid',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'orders/create',
      label: 'Shopify: Order Import (Created)',
      value: 'orders/create',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'fulfillments/create',
      label: 'Shopify: Import Fulfillment (Created)',
      value: 'fulfillments/create',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'fulfillments/update',
      label: 'Shopify: Import Fulfillment (Updated)',
      value: 'fulfillments/update',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: 'orders/cancelled',
      label: 'Order Cancelled by Supplier',
      value: 'orders/cancelled',
      selected: false,
      expanded: false,
      children: [],
    },
  ]
}