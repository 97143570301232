import { getPermitDetail } from 'api/permits'
import { FC, useEffect, useState } from 'react'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import MerchantStatus from '../badges/merchantStatus'
import { formatDate } from 'lib/utils/date/formatDate'

type ChildProps = {
  permitId: string
}

const InspectOffer: FC<ChildProps> = (props) => {
  const [offerInfoData, setOfferInfoData] = useState<any[]>([])
  const [offerPricingData, setOfferPricingData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { data } = await getPermitDetail({ id: props.permitId })
      formatData(data)
      setLoading(false)
    })()
  }, [])

  const formatData = ({
    id,
    variant,
    retailer,
    is_acknowledged,
    retailer_cost,
    start_at,
    end_at,
  }) => {
    const formattedInfoData = [
      {
        label: `ID`,
        value: id,
      },
      {
        label: `Supplier`,
        value: variant?.brand?.name,
      },

      {
        label: 'Merchant',
        value: retailer?.name,
      },
      {
        label: 'Item ID',
        value: variant?.id,
      },
      {
        label: 'Supplier SKU',
        value: variant?.brand_identifier,
      },
      // TODO: add this if user Merchant
      // {
      //   label: 'Merchant Item Name',
      //   value: variant?.retailer_identifiers[0]?.name,
      // },
      {
        label: 'Supplier Item Name',
        value: variant?.name,
      },
      {
        label: 'Merchant Status',
        value: {
          onRender: () => <MerchantStatus acknowledged={is_acknowledged} />,
        },
      },
    ]

    const formattedPricingData = [
      {
        label: `Item Cost`,
        value: retailer_cost ? retailer_cost : 'Managed by merchant',
      },
      {
        label: `Effective Date`,
        value: start_at ? formatDate({ date: start_at }) : 'Now',
      },
      {
        label: `End Date`,
        value: end_at ? formatDate({ date: end_at }) : 'Never',
      },
    ]

    setOfferInfoData(formattedInfoData)
    setOfferPricingData(formattedPricingData)
  }

  return (
    <>
      <div className='mb-4'>
        <ModalCard2Col
          header={'Offer Info'}
          loading={loading}
          data={offerInfoData}
        />
      </div>
      <ModalCard2Col
        header={'Offer Pricing'}
        loading={loading}
        data={offerPricingData}
      />
    </>
  )
}

export default InspectOffer
