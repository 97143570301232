import React, { useEffect, useMemo, useState } from 'react'
import { patchConnectionFees, patchConnections } from 'api/connection'
import { Banner, Dropdown, InputStepper, Modal } from 'atoms'
import { useToast } from '@teamfabric/copilot-ui'
import SelectCommissionProfile from 'molecules/selectDropdowns/selectCommissionProfile'
import SelectPaymentTerms from 'molecules/selectDropdowns/selectPaymentTerms'

type formDataTypes = {
  retailer_identifier: string
  level: string
  integration_type: string
  options: any
  status: string
  connection_fees: any
  commission_profile: any
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const CostMethodOptions = [
  { id: '1', label: 'Specified in Order', value: 'specified_in_order' },
  { id: '2', label: 'Permit Cost', value: 'permit_cost' },
  { id: '3', label: 'Commission', value: 'revshare' },
  { id: '4', label: 'Commission Profile', value: 'commission_profile' },
]

const UpdateConnectionFees: React.FC<ChildProps> = ({
  initialValues,
  ...props
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [costMethodType, setCostMethodType] = useState(CostMethodOptions[0])
  const [commissionProfile, setCommissionProfile] = useState(null)
  const [paymentTerm, setPaymentTerm] = useState(null)
  const [commissionRate, setCommissionRate] = useState<number>(0)

  useEffect(() => {
    setDefaultValues()
  }, [initialValues])

  const setDefaultValues = () => {
    if (initialValues?.connection_fees?.net_price_method) {
      const costMethodOption = CostMethodOptions.find(
        (el) => el.value === initialValues.connection_fees.net_price_method
      )
      setCostMethodType(costMethodOption)
    }
    if (initialValues?.connection_fees?.payment_terms) {
      setPaymentTerm({ value: initialValues.connection_fees.payment_terms })
    }
    if (costMethodType.value === 'revshare') {
      const commissionPercent =
        parseFloat(initialValues?.connection_fees?.revshare_fraction) * 100
      setCommissionRate(commissionPercent)
    }
    if (initialValues?.commission_profile) {
      setCostMethodType(CostMethodOptions[3])
      setCommissionProfile({ id: initialValues.commission_profile.id })
    }
  }

  const onSave = async () => {
    try {
      setLoading(true)
      await UpdateConnectionFee()
      await UpdateConnection()
      showToast({
        label: `Your connection SLAs, has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the connection: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      props.reload()
      setLoading(false)
    }
  }

  const UpdateConnectionFee = async () => {
    const json: { [key: string]: any } = {}
    if (costMethodType.value === 'revshare') {
      json.net_price_method = costMethodType.value
      json.payment_terms = paymentTerm.value
      json.revshare_fraction = commissionRate / 100
    } else if (costMethodType.value === 'commission_profile') {
      json.net_price_method = null
      json.payment_terms = paymentTerm.value
    } else {
      json.net_price_method = costMethodType.value
      json.payment_terms = paymentTerm.value
      json.revshare_fraction = null
    }
    await patchConnectionFees({
      connectionId: props.connectionId,
      feeId: initialValues.connection_fees?.id,
      body: json,
    })
  }

  const UpdateConnection = async () => {
    await patchConnections({
      connectionId: props.connectionId,
      body: {
        commission_profile:
          costMethodType.value === 'commission_profile'
            ? {
                id: commissionProfile.id,
              }
            : null,
      },
    })
  }

  const isReady = useMemo(() => {
    if (!costMethodType?.value) return false
    if (!paymentTerm?.value) return false
    if (costMethodType?.value === 'revshare' && !commissionRate) return false
    if (
      costMethodType?.value === 'commission_profile' &&
      !commissionProfile?.value
    )
      return false
    return true
  }, [costMethodType, paymentTerm, commissionProfile, commissionRate])

  return (
    <>
      <Modal
        headerText='Update Connection Fees'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => {
              setDefaultValues()
              props.onClose()
            },
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: !isReady,
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        <Dropdown
          width='100%'
          options={CostMethodOptions}
          value={costMethodType}
          placeholder='Select cost method'
          label='Cost Method'
          onChange={(option: any) => {
            setCostMethodType(option)
          }}
        />
        <div className='divider-4' />
        {costMethodType?.value === 'commission_profile' && (
          <>
            <SelectCommissionProfile
              handleUpdate={(option) => setCommissionProfile(option)}
              selectedProfile={commissionProfile}
            />
            <div className='divider-4' />
          </>
        )}
        {costMethodType?.value === 'revshare' && (
          <>
            <InputStepper
              width='100%'
              label={'Commission rate'}
              max={99}
              min={0}
              onValueChange={(newVal) => {
                const parsedValue =
                  typeof newVal === 'string' ? parseInt(newVal) : newVal
                setCommissionRate(parsedValue)
              }}
              stepSize={0.25}
              value={commissionRate}
            />
            <div className='divider-4' />
            <Banner
              label='When you use the commission cost method, we apply the commission rate to the retail price to determine the appropriate cost price. A commission rate of % means 100% of the retail price will be reserved for the supplier.'
              variant='info'
            />
            <div className='divider-4' />
          </>
        )}
        <SelectPaymentTerms
          handleUpdate={(option) => setPaymentTerm(option)}
          initialOption={paymentTerm}
        />
      </Modal>
    </>
  )
}

export default UpdateConnectionFees
