import { ONBOARDING_TASKS } from 'modules/onboarding/constants'
import { getState } from 'modules/onboarding/utils'
import { setCurrentStep } from 'store/onboardingSlice'

const INTEGRATION_METHOD =
  ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.INTEGRATION_METHOD

/**
 * helper
 * EDI steps already started
 * @param integration onboardingSlice reducers
 */
const getEdiIntegrationSteps = ({ integration, dispatch, currentStep }) => {
  return Object.keys(ONBOARDING_TASKS.INTEGRATION.EDI_TASKS).map((key) => ({
    name: ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[key].NAME,
    selected:
      currentStep.value ===
      ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[key].TASK_NAME,
    onClick: () => {
      if (
        Object.keys(integration.selectedIntegrationInfo).includes(
          ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[key].CODE
        )
      ) {
        dispatch(
          setCurrentStep({
            value: ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[key].TASK_NAME,
            parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
          })
        )
      }
    },
    state: getState({
      isSubState: true,
      isCompleted: Object.keys(integration.selectedIntegrationInfo).includes(
        ONBOARDING_TASKS.INTEGRATION.EDI_TASKS[key].CODE
      ),
    }),
  }))
}

/**
 * helper
 * API, Shipstation, and BigCommerce steps already started
 * @param tasks platform tasks
 */
const getOtherIntegrationSteps = ({ integration, currentStep, dispatch }) => {
  switch (integration.selectedMethod) {
    case INTEGRATION_METHOD.METHODS.API.TASK_NAME:
      return []
    case INTEGRATION_METHOD.METHODS.BIGCOMMERCE.TASK_NAME:
      return [
        {
          name: INTEGRATION_METHOD.METHODS.BIGCOMMERCE.NAME,
          selected:
            currentStep.value ===
            INTEGRATION_METHOD.METHODS.BIGCOMMERCE.TASK_NAME,
          onClick: () => {},
          state: getState({
            isSubState: true,
            isCompleted: Object.keys(
              integration.selectedIntegrationInfo
            ).includes('bigcommerce'),
          }),
        },
      ]
    case INTEGRATION_METHOD.METHODS.SHIPSTATION.TASK_NAME:
      // TODO
      return []
    default:
      return []
  }
}

/**
 * if selectedMethod is not empty, update the VerticalProgressStepper
 * @param integration onboardingSlicer
 * @param currentStep current task onboardingSlicer
 * @param dispatch
 */
export const getIntegrationSubSteps = ({
  integration,
  currentStep,
  dispatch,
}) => {
  const results = []
  results[0] = {
    name: INTEGRATION_METHOD.NAME,
    selected: currentStep.value === INTEGRATION_METHOD.TASK_NAME,
    onClick: () => {
      dispatch(
        setCurrentStep({
          value: INTEGRATION_METHOD.TASK_NAME,
          parent: ONBOARDING_TASKS.INTEGRATION.VALUE,
        })
      )
    },
    state: getState({
      isSubState: true,
      isCompleted: integration.introDone,
      isNext: false,
    }),
  }
  const isEDISteps =
    integration.selectedMethod === INTEGRATION_METHOD.METHODS.EDI.TASK_NAME
  const integrationSteps = isEDISteps
    ? getEdiIntegrationSteps({
        integration,
        dispatch,
        currentStep,
      })
    : getOtherIntegrationSteps({
        integration,
        currentStep,
        dispatch,
      })
  results.push(...integrationSteps)
  results[results.length] = {
    name: ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.NAME,
    selected:
      currentStep.value ===
      ONBOARDING_TASKS.INTEGRATION.SUB_TASKS.COMPLETE_SETUP.TASK_NAME,
    onClick: () => {},
    state: getState({
      isSubState: true,
      isCompleted: integration.completed,
      isNext: false,
    }),
  }
  return results
}
