import React, { ChangeEvent } from 'react'
import { RadioGroup as RadioGroupUI } from '@teamfabric/copilot-ui'

type RadioGroupProps = {
  label: string
  name: string
  value?: string
  required?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: any
  defaultValue?: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({ ...props }) => {
  return <RadioGroupUI {...props} />
}

export default RadioGroup
