import Button from 'atoms/button/button'

type TrackColProps = {
  tracking_url: string
}

const TrackCol: React.FC<TrackColProps> = ({ tracking_url }) => {
  return (
    <Button
      isDisabled={tracking_url === null}
      variant='secondary'
      text='Track'
      onClick={() => window.open(tracking_url, '_blank')}
    />
  )
}

export default TrackCol
