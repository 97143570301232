import { Badge } from 'atoms'
import ToolTip from 'atoms/tooltip/tooltip'
import { FC, useMemo } from 'react'

interface AcknowledgeCancellationStatusProps {
  cancellation: any
  isRetailer: boolean
}

const AcknowledgeCancellationStatusPresenter: FC<
  AcknowledgeCancellationStatusProps
> = ({ cancellation, isRetailer }) => {
  const presenter = useMemo(() => {
    const presenter = {
      label: 'Ready',
      status: 'info',
      tooltipText: 'Ready to be acknowledged',
    }

    if (cancellation.acknowledged_at != null) {
      presenter.label = 'Skip'
      presenter.tooltipText = 'Cancellation has already been acknowledged'
      presenter.status = 'alert'
    } else if (isRetailer && cancellation.canceled_by === 'retailer') {
      presenter.tooltipText = 'Cancellation was created by your organization'
      presenter.status = 'alert'
    } else if (!isRetailer && cancellation.canceled_by === 'brand') {
      presenter.tooltipText = 'Cancellation was created by your organization'
      presenter.status = 'alert'
    }
    return presenter
  }, [cancellation, isRetailer])

  return (
    <ToolTip text={presenter.tooltipText}>
      <div className='hover-cursor'>
        <Badge
          label={presenter.label}
          variant='primary'
          status={presenter.status}
        />
      </div>
    </ToolTip>
  )
}

export default AcknowledgeCancellationStatusPresenter
