import React, { useEffect, useState } from 'react'
import { getRetailerConnections } from 'api/connection'
import Dropdown from 'atoms/dropdown/dropdown'
import { getTenantType } from 'api/helper'

type ChildProps = {
  handleUpdate?: (data: any) => void
  label?: string
  placeholder?: string
  isDisabled?: boolean
  width?: string
}

const SelectConnection: React.FC<ChildProps> = ({
  handleUpdate,
  label,
  placeholder,
  isDisabled,
  width,
}) => {
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [data, setData] = useState([])
  const [selectedConnection, setSelectedConnection] = useState({
    id: '',
    label: '',
  })

  const { isBrand } = getTenantType()

  useEffect(() => {
    ;(async () => {
      await fetchOrderDetails()
    })()
  }, [])

  const fetchOrderDetails = async () => {
    const { data } = await getRetailerConnections({
      params: { mini: 1, pagination: 0, status: 'active,onboarding' },
    })
    setData(data.results)
    // if brand show merchant name else show brand name
    if (isBrand) {
      setOptions(
        data.results.map((el) => ({ id: el.id, label: el.retailer.name }))
      )
    } else {
      setOptions(
        data.results.map((el) => ({ id: el.id, label: el.brand.name }))
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!loading && selectedConnection) {
      const connection = data.find((el) => el.id === selectedConnection.id)
      // sets the whole connection object
      handleUpdate(connection)
    }
  }, [selectedConnection])

  return (
    <Dropdown
      required
      label={label ? label : 'Supplier'}
      width={width ? width : '100%'}
      value={selectedConnection}
      onChange={(option: any) => {
        setSelectedConnection(option)
      }}
      options={options}
      placeholder={placeholder ? placeholder : 'Select supplier'}
      disabled={isDisabled}
    />
  )
}

export default SelectConnection
