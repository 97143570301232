import { createSlice } from '@reduxjs/toolkit'
import {
  TEMPLATE,
  TEMPLATE_FILTER_OPTIONS,
} from 'modules/settings/components/constants'

const initialState = {
  params: {
    exclude_ownerless: 1,
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  dataTypeOptions: TEMPLATE_FILTER_OPTIONS.DATATYPE_OPTIONS,
}

const productInventoryTemplatesSlice = createSlice({
  name: 'productInventoryTemplates',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['payload'] = action.payload
      } else delete state.params['payload']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setDataTypeOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.dataTypeOptions = TEMPLATE_FILTER_OPTIONS.DATATYPE_OPTIONS
      } else {
        state.dataTypeOptions = action.payload
      }
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.dataTypeOptions = TEMPLATE_FILTER_OPTIONS.DATATYPE_OPTIONS
      delete state.params[TEMPLATE.DATATYPE.PARAM]
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setCurrentPage,
  setDataTypeOptions,
  resetAllFilters,
} = productInventoryTemplatesSlice.actions

export default productInventoryTemplatesSlice.reducer
