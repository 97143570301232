import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  params: {
    limit: 10,
    page: 1,
  },
}
const inventorySummarySlice = createSlice({
  name: 'inventorySummary',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
  },
})

export const { setCurrentPage } = inventorySummarySlice.actions
export default inventorySummarySlice.reducer
