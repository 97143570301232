import React from 'react'
import Input, { InputProps } from 'atoms/input/input'
import Switch, { SwitchProps } from 'atoms/switch'

type InputWithSwitchProps = {
  switchProps: SwitchProps
  inputProps: InputProps
}

const InputWithSwitch: React.FC<InputWithSwitchProps> = ({
  switchProps,
  inputProps,
}) => {
  return (
    <div className='is-flex'>
      <Input {...inputProps} />
      <div className='mt-5 ml-3'>
        <Switch {...switchProps} />
      </div>
    </div>
  )
}

export default InputWithSwitch
