import React, { useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, GridRow, GridCol, Button } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { shopifyBulkUpdateTracking } from 'api/shopify'
import MultiSelect from 'atoms/select/multiSelect'

type ChildProps = {

}

const TrackingNumberResetCard: React.FC<ChildProps> = ({

}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [orderIds, setOrderIds] = useState([])

  const onUpdateTracking = async () => {
    setSaving(true)
    const idsArray = orderIds.map(el => el.label)
    const body = {
      order_ids: idsArray
    }
    try {
      const { data } = await shopifyBulkUpdateTracking({ platformAccountId: id, body })
      showToast({
        label: 'Tracking Numbers Updated!',
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data && response?.data?.detail
          ? response.data.detail
          : 'Order import failed!',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
      setOrderIds([])
    }
  }

  return (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Tracking Number Reset</span>}
            h2Text={
              <span className='body2-regular'>
                Re-push tracking numbers for orders to Shopify.
              </span>
            }
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            When provided with a list of fabric comma-separated order IDs, tracking numbers will be updated in Shopify.
          </div>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol lg={12} md={12} sm={12}>
              <MultiSelect
                placeholder={'Order IDs...'}
                name='orderIds'
                width='100%'
                onChange={(val) => setOrderIds(val)}
                onClear={async () => { }}
                options={orderIds}
                hideOptionList={true}
              />
            </GridCol>
          </GridRow>
          <div className='divider-4' />
          <GridRow padding={false}>
            <GridCol sm={12} md={4} lg={4}>
              <Button
                onClick={() => onUpdateTracking()}
                text='Update Tracking'
                variant='secondary'
                isDisabled={
                  saving || !orderIds.length
                }
              />
            </GridCol>
          </GridRow>
        </Box>
      }
    />
  )
}

export default TrackingNumberResetCard
