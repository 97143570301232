import React from 'react'
import { Image as ImageUI } from '@teamfabric/copilot-ui'

export type ImageProps = {
  src: string
  alt: string
  size: any
  aspectRatio?: any
  objectFit?: any
}

const Image: React.FC<ImageProps> = ({ ...props }) => {
  return <ImageUI {...props} />
}

export default Image
