import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../../../constants'
import Table from 'atoms/table/table'
import { postEdiTestOrders } from 'api/edi'
import Dropdown from 'atoms/dropdown/dropdown'
import { useToast } from '@teamfabric/copilot-ui'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { setIntegration } from 'store/onboardingSlice'
import { getConnections } from 'api/connection'
import CardLoading from 'molecules/loading/cardLoading'

type CreateOrdersProps = {
  setIsNextButtonDisabled: (value: boolean) => void
  reseting: boolean
}

const CreateOrders: React.FC<CreateOrdersProps> = ({
  setIsNextButtonDisabled,
  reseting,
}) => {
  const currentTask = ONBOARDING_TASKS.INTEGRATION.EDI_TASKS
  const showToast = useToast()
  const dispatch = useDispatch()
  const integration = useSelector(
    (state: RootState) => state.onboarding.integration
  )
  const [saving, setSaving] = useState(false)
  const [ediOrders, setEdiOrders] = useState([])
  const [disableCreateOrders, setDisableCreateOrders] = useState(false)
  const [ediRetailerConnection, setEdiRetailerConnection] = useState({
    id: '',
  })
  const [selectedOrderOption, setSelectedOrderOption] = useState({
    id: '',
    label: '',
  })

  const ordersInit = () => {
    if (integration.selectedIntegrationInfo.orderIds.length <= 0) {
      return
    }
    const orderIds = integration.selectedIntegrationInfo.orderIds
    const formattedOrders = orderIds.map(
      ({ sku, id, purchase_order_number }) => ({
        id,
        data: {
          'SKU': { value: sku },
          'Order ID': {
            value: purchase_order_number,
          },
        },
      })
    )
    setEdiOrders(formattedOrders)
  }

  const getOrderLines = (id) => {
    if (id === '1') {
      // single order item
      return [
        {
          quantity: 1,
          price: 25,
          variant: { id: integration.selectedIntegrationInfo.variants[0].id },
        },
      ]
    } else if (id === '2') {
      // multiple order items
      return integration.selectedIntegrationInfo.variants.map(({ id }) => {
        return {
          quantity: 1,
          price: 25,
          variant: { id },
        }
      })
    }
  }

  const createTestOrders = async (id: string) => {
    try {
      setSaving(true)
      const body = {
        requested_shipping_method_id: 2,
        connection: {
          id: ediRetailerConnection.id,
        },
        order_lines: getOrderLines(id),
      }
      const {
        data: { id: orderId, purchase_order_number, order_lines },
      } = await postEdiTestOrders({ body })
      const sku = order_lines
        .map(({ variant }) => variant.brand_identifier)
        .toString()
      const orderIds = [
        ...integration.selectedIntegrationInfo.orderIds,
        {
          purchase_order_number,
          sku,
          id: orderId,
        },
      ]
      setEdiOrders([
        ...ediOrders,
        {
          id: orderId,
          data: {
            'SKU': { value: sku },
            'Order ID': { value: purchase_order_number },
          },
        },
      ])
      dispatch(
        setIntegration({
          taskId: integration.taskId,
          selectedIntegrationInfo: {
            ...integration.selectedIntegrationInfo,
            createOrders: true,
            orderIds,
          },
          processNum: 5,
          introDone: true,
          completed: false,
          selectedMethod: integration.selectedMethod,
        })
      )
    } catch (error) {
      showToast({
        label: "Couldn't Create Test Orders",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  // first loaded
  useEffect(() => {
    ;(async () => {
      try {
        setIsNextButtonDisabled(false)
        const { data } = await getConnections({
          params: { order_by: 'brand__name', page: 1, limit: 12 },
        })
        if (data.count > 0) {
          const foundEdiRetailerConnection = data.results.find(
            ({ retailer }) => retailer && !retailer.is_onboarded
          )
          setEdiRetailerConnection(foundEdiRetailerConnection)
        }
        ordersInit()
      } catch (error) {
        showToast({
          label: "Couldn't load connections",
          variant: 'error',
          isDismissable: true,
          id: '2',
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (ediOrders.length > 5) {
      setDisableCreateOrders(true)
    }
  }, [ediOrders])

  const tableColumns = [
    {
      sortable: false,
      title: 'SKU',
    },
    {
      sortable: false,
      title: 'Order ID',
    },
  ]

  return (
    <>
      {reseting && (
        <CardLoading label={currentTask.CREATE_ORDERS.NAME} num={4} />
      )}
      {!reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={false}
          headerContent={
            <div>
              <h1 className='h5 mb-2'>{currentTask.CREATE_ORDERS.NAME}</h1>
              <p className='body1-regular mb-4'>
                Create and verify test orders for your sample items.
              </p>
              <p className='body1-regular'>1. Click Create Test Orders.</p>
              <p className='body1-regular'>
                2. Click Next when both sample items have an order ID.
              </p>
              <Dropdown
                width='80%'
                disabled={disableCreateOrders}
                options={[
                  { id: '1', label: 'Create order with single item' },
                  { id: '2', label: 'Create order with multiple items' },
                ]}
                value={selectedOrderOption}
                placeholder='Create Test Orders'
                label=''
                onChange={async (e: any) => {
                  setSelectedOrderOption(e)
                  await createTestOrders(e.id)
                }}
              />
            </div>
          }
          bodyContent={
            <Table
              columns={tableColumns}
              data={ediOrders}
              loading={saving}
              activePage={1}
              selectable={false}
              totalRecords={ediOrders.length}
            />
          }
        />
      )}
    </>
  )
}

export default CreateOrders
