import { createSlice } from '@reduxjs/toolkit'
import {
  FILTER_OPTIONS,
  MERCHANTS,
} from 'modules/merchants/components/constants'

const initialState = {
  params: {
    order_by: 'brand__name',
    limit: 10,
    page: 1,
  },
  statusOptions: FILTER_OPTIONS.STATUS_OPTIONS,
  integrationOptions: FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS,
  invitationParams: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
  invitationsStatusOptions: FILTER_OPTIONS.INVITATION_STATUS_OPTIONS,
  locationParams: {
    order_by: '-id',
    limit: 10,
    page: 1,
  },
}

const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      if (action.payload.page === 'invitations') {
        state.invitationParams.page = action.payload.currentPage
      } else if (action.payload.page === 'locations') {
        state.locationParams.page = action.payload.currentPage
      } else {
        state.params.page = action.payload
      }
    },
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.statusOptions = FILTER_OPTIONS.STATUS_OPTIONS
      } else {
        state.statusOptions = action.payload
      }
    },
    setIntegrationOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.integrationOptions = FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS
      } else {
        state.integrationOptions = action.payload
      }
    },
    resetAllFilters(state, action) {
      state.params.page = 1
      state.statusOptions = FILTER_OPTIONS.STATUS_OPTIONS
      state.integrationOptions = FILTER_OPTIONS.INTEGRATION_TYPE_OPTIONS

      delete state.params[MERCHANTS.STATUS.PARAM]
      delete state.params[MERCHANTS.INTEGRATION_TYPE.PARAM]

      const statusPreset = action.payload?.statusPreset

      if (statusPreset) {
        const updatedOptions = FILTER_OPTIONS.STATUS_OPTIONS.map((option) => {
          if (option.value === statusPreset) {
            return { ...option, selected: true }
          } else return option
        })

        state.statusOptions = updatedOptions
        state.params['status'] = statusPreset
      }
    },
    setInvitationsSearch(state, action) {
      if (action?.payload) {
        state.invitationParams['search'] = action.payload
      } else delete state.invitationParams['search']
      state.invitationParams.page = 1
      state.invitationParams.order_by = initialState.invitationParams.order_by
    },
    setInvitationStatusOptions(state, action) {
      if (action.payload.type === 'reset') {
        state.invitationsStatusOptions =
          FILTER_OPTIONS.INVITATION_STATUS_OPTIONS
      } else {
        state.invitationsStatusOptions = action.payload
      }
    },
    setInvitationsFilters(state, action) {
      state.invitationParams.page = 1
      if (!action.payload.value) {
        delete state.invitationParams[action.payload.filter]
      } else {
        state.invitationParams[action.payload?.filter] = action.payload?.value
      }
    },
    setLocationsSearch(state, action) {
      if (action?.payload) {
        state.locationParams['search_locations'] = action.payload
      } else delete state.locationParams['search_locations']
      state.locationParams.page = 1
      state.locationParams.order_by = initialState.locationParams.order_by
    },
  },
})

export const {
  setCurrentPage,
  setSorting,
  setSearch,
  setFilters,
  setStatusOptions,
  setIntegrationOptions,
  resetAllFilters,
  setInvitationStatusOptions,
  setInvitationsFilters,
  setInvitationsSearch,
  setLocationsSearch,
} = supplierSlice.actions

export default supplierSlice.reducer
