import React, { useState } from 'react'
import { bulkAddTags } from 'api/tags'
import { RootState } from 'store'
import { useSelector, useDispatch } from 'react-redux'
import { setSelected } from 'store/orderSlice'
import TagModal from 'molecules/modals/tag/tagModal'
import { useToast } from '@teamfabric/copilot-ui'

type BulkAddTagProps = {
  setDisplayModal: (value: boolean) => void
  isVisible: boolean
  addedTags: (isAdded: boolean) => void
}

const BulkAddTag: React.FC<BulkAddTagProps> = ({
  setDisplayModal,
  isVisible,
  addedTags,
}) => {
  const dispatch = useDispatch()
  const selected = useSelector((state: RootState) => state.orders.selected)
  const [selectedTags, setSelectedTags] = useState([])
  const showToast = useToast()

  const addTags = async () => {
    try {
      const body = {
        ids: selected,
        object_tags: selectedTags.map(({ label }) => label),
      }
      await bulkAddTags({ body })
      showToast({
        label: 'Tags added to the orders successfully',
        isDismissable: true,
        id: 'bulk-add-tags',
      })
      addedTags(true)
      dispatch(setSelected([]))
    } catch (error) {
      showToast({
        label: 'Error Bulk Adding Tags',
        isDismissable: true,
        id: 'error-add-tags',
        variant: 'error',
      })
    }
  }

  return (
    <TagModal
      isBulk
      setDisplayModal={setDisplayModal}
      isVisible={isVisible}
      onClick={addTags}
      setUserSelectedTags={(tags) => setSelectedTags(tags)}
    />
  )
}

export default BulkAddTag
