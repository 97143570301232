import React, { useEffect, useMemo, useState } from 'react'
import { FILTERS } from 'molecules/filter/constants'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxWithSearchFilterChange,
  handleRadioFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  resetAllFilters,
  setConnectionOptions,
  setFilters,
  setCreatedOn,
  setInitialCreatedOn,
} from 'store/shipmentsSlice'
import Box from 'atoms/box/box'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import { ORDERS } from 'modules/orders/constants'
import { getDateObjectForLastMonth } from 'lib/utils/date/formatDate'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const dispatch = useDispatch()

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.shipments.connectionOptions
  )

  const createdOn = useSelector((state: RootState) => state.shipments.createdOn)
  const initialCreatedOnSet = useSelector(
    (state: RootState) => state.shipments.initialCreatedOnValueSet
  )

  const isAFilterApplied = useMemo(() => {
    return connectionsCounter + acknowledgedCounter + createdOnCounter > 0
  }, [connectionsCounter, acknowledgedCounter, createdOnCounter])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: ORDERS.CONNECTION.NAME,
    },
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      chipName: ORDERS.ACKNOWLEDGED.NAME,
    },
    shipped_at_gte: {
      setFilterCount: setCreatedOnCounter,
      dateRange: createdOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.SHIPPING_CREATED_ON.NAME,
    },
  }

  useEffect(() => {
    // only on the very first render, apply a preset to the shipment createdOn filter
    if (!initialCreatedOnSet) {
      dispatch(setCreatedOn(getDateObjectForLastMonth()))
      dispatch(setInitialCreatedOn())
    }
  }, [])

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${ORDERS.CONNECTION.NAME}-filter`}
          label={ORDERS.CONNECTION.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <RadioGroupFilter
          dataTestid={`${ORDERS.ACKNOWLEDGED.NAME}-filter`}
          label={ORDERS.ACKNOWLEDGED.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              setFilters,
            })
          }}
          value={params?.is_acknowledged?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={
            acknowledgedCounter > 0 ? acknowledgedCounter?.toString() : ''
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.SHIPPING_CREATED_ON.NAME}-filter`}
          label={ORDERS.SHIPPING_CREATED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.SHIPPING_CREATED_ON.PARAMS.START,
              date,
            })
          }}
          defaultValue={createdOn}
          filterCount={createdOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.SHIPPING_CREATED_ON.PARAMS.START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
