import { FC, useEffect, useMemo, useState } from 'react'
import { Box, GridCol, GridRow } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/productInventoryTemplatesSlice'
import { DataProps } from '@teamfabric/copilot-ui'
import { getTemplates } from 'api/templates'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import TemplatesFilter from '../components/templates/filters'
import TemplatesTable from '../components/templates/listTable'
import { useNavigate } from 'react-router-dom'
import { getPlatformAccounts } from 'api/platformAccount'
import MoreActionButtonChildren from 'molecules/button/moreDropdownChildren'

const ProductInventoryTemplates: FC = () => {
  const params = useSelector(
    (state: RootState) => state.productInventoryTemplates.params
  )
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [platforms, setPlatforms] = useState([])
  const [platformCodes, setPlatformCodes] = useState([])

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Templates' })
        ),
      0
    )
  }, [])

  const fetchTemplates = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const response = await getTemplates({ params: { ...params } })
    const { data } = response
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  const fetchPlatforms = async (quiet?: boolean) => {
    const { data } = await getPlatformAccounts({})
    const filteredPlatforms = data?.results
      .filter((item) => ['fabric', 'shopify'].includes(item.platform.code))
      .map((item) => ({
        id: item.id,
        label: item.platform.name,
        code: item.platform.code,
      }))
    setPlatforms(filteredPlatforms)
    setPlatformCodes(filteredPlatforms?.map((item) => item.code) || [])
  }

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchTemplates()
        await fetchPlatforms()
      })()
    }
  }, [params])

  const hasExternalTemplates = useMemo(() => {
    return platformCodes.length > 0
  }, [platformCodes])

  const platformActionsMap = {
    shopify: (id) => ({
      name: 'View Shopify Templates',
      handleClick: () =>
        navigate(`/settings/integrations/shopify/${id}/templates`),
    }),
    fabric: (id) => ({
      name: 'View fabric Templates',
      handleClick: () =>
        navigate(`/settings/integrations/fabric/${id}/templates`),
    }),
    // Add more platforms as needed
  }

  const MoreActions = platformCodes
    .map((platform) => {
      const platformData = platforms.find((el) => el.code === platform)
      if (platformData && platformActionsMap[platform]) {
        return platformActionsMap[platform](platformData.id)
      }
      return null
    })
    .filter(Boolean)

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        dataTestid='templates-header'
        h1Text={'Templates'}
        customClassName='mb-4'
        primaryButtons={[
          ...(hasExternalTemplates
            ? [
                {
                  text: 'External Templates',
                  icon: 'ArrowDown',
                  iconPosition: 'right',
                  variant: 'secondary',
                  onClick: () => {},
                  dataTestid: 'external-templates-button',
                  popoverProps: {
                    children: (
                      <MoreActionButtonChildren actions={MoreActions} />
                    ),
                    placement: 'bottom-start',
                  },
                },
              ]
            : []),
          {
            onClick: () => navigate('create'),
            text: 'Create Template',
            variant: 'primary',
            icon: 'Add',
            iconPosition: 'left',
            dataTestid: 'create-template-button',
          },
        ]}
      />
      <GridRow padding={false}>
        <GridCol md={12}>
          <SearchBar
            placeholder='Search within results...'
            setSearch={setSearch}
            className='mt-4'
            dataTestid='templates-search-bar'
          />
          <div className='filterDiv'>
            <TemplatesFilter params={params} />
          </div>
          <TemplatesTable
            data={tableData}
            loading={loading}
            currentPage={params.page}
            totalRows={totalRows}
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default ProductInventoryTemplates
