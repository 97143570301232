import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Box } from 'atoms'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { FILTERS } from 'molecules/filter/constants'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import FilterChips from 'organisms/filterChips'
import {
  setFilters,
  setDataTypeOptions,
  resetAllFilters,
} from 'store/productInventoryTemplatesSlice'
import {
  handleCheckboxFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { TEMPLATE } from '../constants'

type ChildProps = {
  params: any
}

const TemplatesFilter: React.FC<ChildProps> = ({ params }) => {
  const [dataTypeCounter, setDataTypeCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const dataTypeOptions = useSelector(
    (state: RootState) => state.productInventoryTemplates.dataTypeOptions
  )

  const isAFilterApplied = useMemo(() => {
    return dataTypeCounter > 0
  }, [dataTypeCounter])

  // Define respective filterKey fns
  const filterActions = {
    data_type: {
      action: setDataTypeOptions,
      options: dataTypeOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setDataTypeCounter,
      chipName: TEMPLATE.DATATYPE.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${TEMPLATE.DATATYPE.NAME}-filter`}
          label={TEMPLATE.DATATYPE.NAME}
          data={dataTypeOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: TEMPLATE.DATATYPE.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={dataTypeCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: TEMPLATE.DATATYPE.PARAM,
              setFilters,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default TemplatesFilter
