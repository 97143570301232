import {
  ProgressStepper as ProgressStepperUI,
  ProgressStepperProps,
} from '@teamfabric/copilot-ui'

import React from 'react'

const ProgressStepper: React.FC<ProgressStepperProps> = ({ ...props }) => {
  return <ProgressStepperUI {...props} />
}

export default ProgressStepper
