import React, { useState, useEffect } from 'react'
import { getTenantType } from 'api/helper'
import Tag from 'atoms/tag/tag'
import Card4Columns from 'molecules/cards/4Columns'
import CardWithTable from 'molecules/cards/cardWithTable'
import ActionsMenu from 'molecules/presenters/actionMenu'
import { Box } from 'atoms'
import ConnectionCarrierAccountModal from '../modals/ConnectionCarrierAccount'

type CarrierDetailsProps = {
  details: any
  loading: boolean
  reload?: () => {}
}

const CarrierDetails: React.FC<CarrierDetailsProps> = ({
  details,
  loading,
  reload,
}) => {
  const [carriers, setCarriers] = useState<any[]>([])
  const [carriersTableData, setCarriersTableData] = useState<any[]>([])
  const [selectedRow, setSelectedRow] = useState(null)

  // edit modal states
  const [showEditCarrierModal, setShowEditCarrierModal] = useState(false)
  // const [showDeleteCarrierModal, setShowDeleteCarrierModal] = useState(false)

  const { isRetailer } = getTenantType()

  useEffect(() => {
    formatData(details)
  }, [loading, details])

  const formatData = ({ shipping_accounts }) => {
    if (isRetailer) {
      setSelectedRow(shipping_accounts?.[0])
      let tableData = shipping_accounts?.map((account) => ({
        id: account.id,
        data: {
          'Carrier': { value: account.carrier.name },
          'Account name': { value: account.nickname },
          'Owner': { value: account.owner.name },
          'Service levels': {
            onRender: () => (
              <Box
                flex={{ flexWrap: 'wrap' }}
                gap={1}
                padding={{
                  bottom: 3,
                  left: 0,
                  right: 0,
                  top: 3,
                }}
              >
                {account.shipping_methods.map((method) => (
                  <Tag
                    id={method.id}
                    primaryLabel={method.name}
                    key={method.id}
                  />
                ))}
              </Box>
            ),
          },
          'Actions': {
            onRender: () => (
              <ActionsMenu
                data={account}
                setShowEditModal={(val) => setShowEditCarrierModal(val)}
                setSelected={() => setSelectedRow(account)}
                showDelete={false}
              />
            ),
          },
        },
      }))
      setCarriersTableData(tableData)
    } else {
      const formattedCarriers = shipping_accounts?.map((account) => {
        let labelValuePairs = []
        if (account.carrier) {
          labelValuePairs.push({
            label: 'Carrier',
            value: account.carrier.name,
          })
        }
        if (account.nickname) {
          labelValuePairs.push({
            label: 'Account Name',
            value: account.nickname,
          })
        }
        if (account.owner) {
          labelValuePairs.push({ label: 'Owner', value: account.owner.name })
        }
        if (account.shipping_methods) {
          labelValuePairs.push({
            label: 'Service Levels',
            value: account.shipping_methods[0]?.name,
          })
        }
        return labelValuePairs
      })[0]
      setCarriers(formattedCarriers)
    }
  }

  const tableColumns = [
    {
      sortable: false,
      title: 'Carrier',
    },
    {
      sortable: false,
      title: 'Account name',
    },
    {
      sortable: false,
      title: 'Owner',
    },
    {
      sortable: false,
      title: 'Service levels',
    },
    {
      sortable: false,
      title: 'Actions',
      width: 80,
    },
  ]

  const RetailerContent = () => {
    return (
      <CardWithTable
        header='Carrier Accounts'
        loading={loading}
        tableProps={{
          emptyTableText: 'No Carrier Accounts Found',
          columns: tableColumns,
          data: carriersTableData,
        }}
        headerWithButtons={true}
        headerButtonText='Edit Carrier Account'
        onEdit={() => setShowEditCarrierModal(true)}
      />
    )
  }

  const SupplierContent = () => {
    return (
      <Card4Columns
        header='Carrier Accounts'
        loading={loading}
        data={carriers}
        customClassName='mb-4'
        noResultsText='No Carrier Accounts Found'
      />
    )
  }

  return (
    <div className='mt-5'>
      {isRetailer ? <RetailerContent /> : <SupplierContent />}
      {showEditCarrierModal && (
        <ConnectionCarrierAccountModal
          onClose={() => setShowEditCarrierModal(false)}
          showModal={showEditCarrierModal}
          connectionId={details.id}
          reload={() => reload()}
          action='edit'
          initialValues={selectedRow}
        />
      )}
    </div>
  )
}

export default CarrierDetails
