import React from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import GenericShippingMethodDropdown from 'molecules/genericShippingMethod'

interface ChildProps {
  handleEdit: (data: any) => void
}

const RequestedShippingMethod: React.FC<ChildProps> = ({ handleEdit }) => {
  const handleUpdate = (option) => {
    handleEdit(option)
  }

  return (
    <Card
      headerContent={
        <div className='styledCardHeader'>
          <h5 className='heading'>Requested Shipping Method</h5>
          <div className='subHeading'>Select a required shipping method.</div>
        </div>
      }
      showCollapse={false}
      open
      showDivider={false}
      bodyContent={
        <div className='mb-4'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <GenericShippingMethodDropdown
                methodType={['Expedited', 'Ground']}
                handleUpdate={handleUpdate}
              />
            </GridCol>
          </GridRow>
        </div>
      }
    />
  )
}

export default RequestedShippingMethod
