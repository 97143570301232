import { PageHeader } from 'atoms'
import Banner from 'atoms/banner/banner'
import React from 'react'

type CardHeaderProps = {
  h1Text: string
  h2Text?: string
  banner?: {
    label: string
    variant?: string
  }
  primaryButtons?: any
}

const CardHeader: React.FC<CardHeaderProps> = ({
  h1Text,
  h2Text,
  banner,
  primaryButtons,
}) => {
  return (
    <div>
      <div className='mb-4'>
        <PageHeader
          h1Text={h1Text}
          h2Text={h2Text ? h2Text : ''}
          primaryButtons={primaryButtons}
        />
      </div>
      {banner ? (
        <Banner
          label={banner.label}
          variant={banner.variant ? banner.variant : 'info'}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default CardHeader
