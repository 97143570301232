import React, { useState, useEffect, useMemo } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box } from 'atoms'
import CardLoading from 'molecules/loading/cardLoading'
import PageHeaderComponent from 'molecules/PageHeader'
import { useParams } from 'react-router-dom'
import { updatePlatformAccounts } from 'api/platformAccount'

type ChildProps = {
  loading: boolean
  account: any
  loadAccount: (quiet?: boolean) => void
}

const ShipmentNotificationsCard: React.FC<ChildProps> = ({
  loading,
  account,
  loadAccount
}) => {
  const { id } = useParams()
  const showToast = useToast()
  const [toggleValue, setToggleValue] = useState('0')

  useEffect(() => {
    if (!loading && account.credentials) {
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'notify_customer_on_fulfillment_create' && credential[key].values[0].value) {
            setToggleValue(credential[key].values[0].value)
          }
        })
      })
    }
  }, [!loading, account])

  const credential = useMemo(() => {
    if (!loading && account.credentials) {
      if (account.credentials.length === 0) return null
      let found = null
      account.credentials.forEach(credential => {
        Object.keys(credential).forEach(key => {
          if (key === 'notify_customer_on_fulfillment_create') {
            found = credential[key].values[0]
          }
        })
      })
      return found
    }
  }, [!loading, account])

  const updateNotification = async (updatedValue) => {
    const body = {
      credentials: [{
        credential: {
          code: 'notify_customer_on_fulfillment_create',
        },
        ...(credential !== null && { id: credential.id }),
        value: updatedValue
      }]
    }
    const toastMessage = updatedValue === '1' ? 'Sync enabled successfully!' : 'Sync disabled successfully!'
    try {
      await updatePlatformAccounts({ id: id, body })
      showToast({
        label: toastMessage,
        isDismissable: true,
        id: '1',
      })
    } catch ({ response }) {
      showToast({
        label: response?.status === 400 && response?.data ? response.data : 'An error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      loadAccount(true)
    }
  }

  return loading ? (
    <CardLoading num={1} width={'50%'} />
  ) : (
    <Card
      headerContent={
        <Box width='50%'>
          <PageHeaderComponent
            h1Text={<span className='h5'>Shipment Notifications</span>}
            h2Text={
              <span className='body2-regular'>
                Configure whether you would like Shopify to inform your customers when the order is shipped.
              </span>
            }
            switchProps={{
              label: '',
              stateLabels: {
                OFF: 'Off',
                ON: 'On',
              },
              checked: toggleValue === '1',
              onChange: ({ target: { checked } }) => {
                const value = checked ? '1' : '0'
                setToggleValue(value)
                updateNotification(value)
              },
            }}
            dataTestid='shopify-shipment-notification-header'
          />
        </Box>
      }
      showCollapse={false}
      showDivider={true}
      bodyContent={
        <Box width='50%'>
          <div className='body2-regular'>
            Enable this service if you would like to inform your customers through Shopify whenever an order is shipped and tracking information is available.
          </div>
        </Box>
      }
    />
  )
}

export default ShipmentNotificationsCard
