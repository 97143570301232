import Icon from 'atoms/icon/icon'
import { StyledReturnDetail } from '../../styles'

const OptionCol: React.FC = () => {
  return (
    <StyledReturnDetail>
      <div className='iconDiv'>
        <Icon iconName='MoreActions' size={32} />
      </div>
    </StyledReturnDetail>
  )
}

export default OptionCol
