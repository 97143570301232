import { Avatar } from '@teamfabric/copilot-ui'
import React from 'react'

type AvatarProps = {
  size: any
  label: string
  isLabelVisible?: boolean
}

const SingleAvatar: React.FC<AvatarProps> = ({ ...props }) => {
  return <Avatar {...props} />
}

export default SingleAvatar
