import React from 'react'
import { Filter as FilterUI } from '@teamfabric/copilot-ui'

type FilterProps = {
  label: string
  renderResults: React.ReactNode
  dataTestid?: string
  filterCount?: string
}

const Filter: React.FC<FilterProps> = ({ ...props }) => {
  return <FilterUI variant='primary' {...props} />
}

export default Filter
