import { FC, useEffect, useMemo, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/productRulesetsSlice'
import Link from 'atoms/link/link'
import Box from 'atoms/box/box'
import { useNavigate } from 'react-router-dom'
import EditDeleteTableRow from 'molecules/table/editDeleteTableRow'
import ConfirmDeleteModal from 'molecules/modals/confirmDeleteModal'
import { deleteRuleset } from 'api/rulesets'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
  loadRulesets: (quiet?: boolean) => void
}

const RulesetsTable: FC<ChildProps> = ({
  data,
  loading,
  currentPage,
  totalRows,
  loadRulesets,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    formatSetTableData({ data: data })
  }, [loading, data])

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        width: '10%',
      },
      {
        title: 'Ruleset name',
        width: '40%',
      },
      {
        title: 'Rule Groups',
        width: '25%',
      },
      {
        title: 'Updated At',
        width: '15%',
      },
      {
        title: '',
        width: '80',
      },
    ]
    return columns
  }, [])

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map((ruleset, id) => {
      return {
        id,
        data: {
          'ID': { value: ruleset.id },
          'Ruleset name': {
            onRender: () => (
              <Link
                onClick={() => navigate(`${ruleset.id}`)}
                label={ruleset?.name}
                variant='primary'
                mode='inline'
              />
            ),
          },
          'Rule Groups': { value: ruleset?.rule_groups.length },
          'Updated At': { value: formatDate({ date: ruleset.updated_at }) },
          '': {
            value: { ...ruleset },
            onRender: (data) => (
              <EditDeleteTableRow
                handleClick={(key) => {
                  setShowDeleteModal(true)
                  setSelectedRow(data.value)
                }}
                hideEdit
              />
            ),
          },
        },
      }
    })
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const onDelete = async () => {
    try {
      await deleteRuleset({ id: selectedRow?.id })
    } finally {
      setSelectedRow(null)
      loadRulesets()
    }
  }

  return (
    <>
      <Box dataTestid='rulesets-table' margin={{ bottom: 7 }}>
        {
          <ConfirmDeleteModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            headerText={`Are you sure you want to delete ruleset: ${selectedRow?.name}?`}
            body1='This ruleset will be deleted immediately. You can’t undo this action.'
            handleClick={onDelete}
          />
        }
        <Table
          columns={tableColumns}
          data={tableData}
          loading={loading}
          selectable={false}
          customPaginationProps={{
            handlePagination: (pageNumber: number) => {
              setPage(pageNumber)
            },
            activePageNumber: currentPage,
            perPage: 10,
            totalRecords: totalRows,
          }}
        />
      </Box>
    </>
  )
}

export default RulesetsTable
