import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'atoms/modal/modal'
import { applyTransformerToProposal } from 'api/proposals'
import { VARIANTS } from 'lib/constants'
import { useToast } from '@teamfabric/copilot-ui'
import SelectTransformer from 'molecules/selectDropdowns/selectTransformer'
import { Banner } from 'atoms'

type ChildProps = {
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  proposal: any
  loadProposal: (quiet?: boolean) => void
}

const ApplyTransformerModal: React.FC<ChildProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectedTransformer, setSelectedTransformer] = useState<any>()

  const showToast = useToast()

  const applyTransformer = async () => {
    try {
      setLoading(true)
      await applyTransformerToProposal({
        id: props.proposal?.id,
        body: {
          transformer_id: selectedTransformer.id,
        },
      })
      showToast({
        label: `Your proposal has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error updating the proposal: ${errorMessage}`
          : 'Error occurred while updating the proposal, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.setShowModal(false)
      props.loadProposal(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Apply Transformer'
        onClose={() => props.setShowModal(false)}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.setShowModal(false),
            text: 'Close',
            variant: VARIANTS.TERTIARY,
            isDisabled: loading,
          },
          {
            onClick: () => applyTransformer(),
            text: 'Apply',
            variant: VARIANTS.PRIMARY,
            isDisabled: loading,
          },
        ]}
        onBackdropClick={() => props.setShowModal(false)}
      >
        <>
          <Banner
            label='When you select and apply a transformer, all matching attribute values will be modified as per the transformer’s specifications.'
            variant='info'
          />
          <div className='mt-4'>
            <SelectTransformer
              handleUpdate={(option) => setSelectedTransformer(option)}
            />
          </div>
        </>
      </Modal>
    </>
  )
}

export default ApplyTransformerModal
