import React, { useMemo } from 'react'
import PageHeaderComponent from 'molecules/PageHeader'
import { ButtonVariants } from 'atoms/button/button'
import { formatProposalType } from '../../utils'
import { formatDate } from 'lib/utils/date/formatDate'
import { StyledActionButtonWrapper, StyledActionButton } from 'styles/common'
import ProposalStatus from '../presenters/proposalStatus'

type ProposalHeaderProps = {
  proposal: any
  loading: boolean
  setShowArchiveModal: (arg: boolean) => void
  setShowSubmitModal: (arg: boolean) => void
  setUpdateCostModal: (arg: boolean) => void
  setShowExportModal: (arg: boolean) => void
  setShowReturnModal: (arg: boolean) => void
  setShowAddToProductsListModal: (arg: boolean) => void
  setShowChangeOwnerModal: (arg: boolean) => void
  setShowApplyTransformerModal: (arg: boolean) => void
  setShowDeclineProposalModal: (arg: boolean) => void
  setShowManagerApproveModal: (arg: boolean) => void
  setShowApproveProductsModal: (arg: boolean) => void
  isRetailer: boolean
  requirePricingApproval: boolean
}

const ProposalHeader: React.FC<ProposalHeaderProps> = ({
  proposal,
  loading,
  setShowArchiveModal,
  setShowSubmitModal,
  setUpdateCostModal,
  setShowExportModal,
  setShowReturnModal,
  setShowAddToProductsListModal,
  setShowChangeOwnerModal,
  setShowApplyTransformerModal,
  setShowDeclineProposalModal,
  setShowManagerApproveModal,
  setShowApproveProductsModal,
  requirePricingApproval,
  isRetailer,
}) => {
  const retailerActions = [
    {
      name: 'Export',
      handleClick: setShowExportModal,
    },
    {
      name: 'Change Owner',
      handleClick: setShowChangeOwnerModal,
    },
    {
      name: 'Apply Transformers',
      handleClick: setShowApplyTransformerModal,
    },
    {
      name: 'Decline Proposal',
      handleClick: setShowDeclineProposalModal,
    },
  ]

  const children = (actions) => {
    return (
      <StyledActionButtonWrapper>
        {actions.map((action) => (
          <StyledActionButton
            onClick={() => action.handleClick(true)}
            data-testid={`${action.name}-dropdown-item`}
            key={action.name}
          >
            {action.name}
          </StyledActionButton>
        ))}
      </StyledActionButtonWrapper>
    )
  }

  const createButton = (onClick, text, variant, isDisabled?: boolean) => ({
    onClick,
    text,
    variant,
    isDisabled,
  })

  const getRetailerButtons = () => {
    const buttons = []

    switch (proposal.status) {
      case 'proposed':
        buttons.push(
          {
            text: 'More actions',
            icon: 'ArrowDown',
            iconPosition: 'right',
            variant: 'secondary',
            onClick: () => {},
            popoverProps: {
              children: children(retailerActions),
              placement: 'bottom-start',
            },
          },
          createButton(
            () => setShowReturnModal(true),
            'Return',
            ButtonVariants.SECONDARY
          ),
          createButton(
            () => setShowApproveProductsModal(true),
            'Finish Review',
            ButtonVariants.PRIMARY
          )
        )
        break

      case 'approved':
        if (
          'require_pricing_approval_on_proposals' in proposal.connection.options
        ) {
          buttons.push(
            createButton(
              () => setShowChangeOwnerModal(true),
              'Change Owner',
              ButtonVariants.TERTIARY
            ),
            createButton(
              () => setShowExportModal(true),
              'Export',
              ButtonVariants.SECONDARY
            ),
            createButton(
              () => setShowManagerApproveModal(true),
              'Approve Products',
              ButtonVariants.PRIMARY
            )
          )
        } else {
          buttons.push(
            createButton(
              () => setShowExportModal(true),
              'Export',
              ButtonVariants.SECONDARY
            ),
            createButton(
              () => setShowAddToProductsListModal(true),
              'Add to Product List',
              ButtonVariants.PRIMARY
            )
          )
        }
        break

      case 'pricing_approved':
        buttons.push(
          createButton(
            () => setShowExportModal(true),
            'Export',
            ButtonVariants.SECONDARY
          ),
          createButton(
            () => setShowAddToProductsListModal(true),
            'Add to Product List',
            ButtonVariants.PRIMARY
          )
        )
        break

      case 'completed':
        if (proposal.acknowledged_at === null) {
          buttons.push(
            createButton(
              () => setShowExportModal(true),
              'Export',
              ButtonVariants.SECONDARY
            ),
            createButton(
              () => setShowAddToProductsListModal(true),
              'Add to Product List',
              ButtonVariants.PRIMARY
            )
          )
        } else {
          buttons.push(
            createButton(
              () => setShowExportModal(true),
              'Export',
              ButtonVariants.PRIMARY
            )
          )
        }
        break

      case 'declined':
      case 'revised':
        buttons.push(
          createButton(
            () => setShowChangeOwnerModal(true),
            'Change Owner',
            ButtonVariants.SECONDARY
          ),
          createButton(
            () => setShowExportModal(true),
            'Export',
            ButtonVariants.PRIMARY
          )
        )
        break

      default:
        break
    }

    return buttons
  }

  const getSupplierButtons = () => {
    const buttons = []

    if (['draft', 'revised', 'proposed'].includes(proposal?.status)) {
      buttons.push(
        createButton(
          () => setShowArchiveModal(true),
          'Archive',
          ButtonVariants.SECONDARY
        )
      )
    }

    if (['draft', 'revised'].includes(proposal?.status)) {
      if (proposal.proposal_type === 'new_product') {
        buttons.unshift(
          createButton(
            () => setUpdateCostModal(true),
            'Update Cost Attributes',
            ButtonVariants.TERTIARY
          )
        )
      }
      buttons.push(
        createButton(
          () => setShowSubmitModal(true),
          proposal?.status === 'draft' ? 'Submit' : 'Resend',
          ButtonVariants.PRIMARY,
          true
        )
      )
    }

    return buttons
  }

  const headerButtons = useMemo(() => {
    return isRetailer ? getRetailerButtons() : getSupplierButtons()
  }, [
    isRetailer,
    proposal?.proposal_type,
    proposal?.status,
    proposal.acknowledged_at,
  ])

  const status = useMemo(() => {
    const status = proposal?.status
    if (['proposed', 'approved'].includes(status)) {
      return 'alert'
    } else if (['revised', 'pricing_approved'].includes(status)) {
      return 'info'
    } else if (status === 'completed') {
      return 'success'
    } else if (status === 'declined') {
      return 'error'
    } else return 'default'
  }, [proposal?.status])

  const type = useMemo(() => {
    return formatProposalType(proposal?.proposal_type)
  }, [proposal?.proposal_type])

  const description = useMemo(() => {
    const departmentName = proposal?.department?.name
    const itemCount =
      proposal?.item_count === 1 ? '1 item' : `${proposal?.item_count} items`
    return [departmentName, type, itemCount].join(', ')
  }, [proposal?.department, proposal?.item_count])

  return (
    <PageHeaderComponent
      loading={loading}
      h1Text={`Proposal #${proposal?.id}, ${proposal?.connection?.brand?.name}`}
      h2Text={description}
      badgeProps={{
        label: ProposalStatus({
          status: proposal.status,
          acknowledgedAt: proposal.acknowledged_at,
          requirePricingApproval,
        }),
        status: status,
      }}
      primaryButtons={headerButtons}
      ternaryDescription={`Last updated ${formatDate({
        date: proposal.updated_at,
        withYear: true,
        withTime: true,
      })}`}
      dataTestid='proposal-details-header'
    />
  )
}

export default ProposalHeader
