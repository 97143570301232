import React, { useState } from 'react'
import { Box, GridCol, GridRow, Icon, SingleAvatar, ToolTip } from 'atoms'
import { formatDate } from 'lib/utils/date/formatDate'
import Tag from 'atoms/tag/tag'
import PostCommentInput from './postComment'

type taggedUser = {
  first_name: string
  last_name: string
  id: string
  email: string
}

type Comment = {
  id: string
  text: string
  created_by: {
    first_name: string
    last_name: string
  }
  parent: string
  created_at: string
  closed_at: string
  tagged_users: taggedUser[]
  replies?: Comment[]
  reaction_counts: {
    like: number
    dislike: number
  }
}

type ChildProps = {
  comment: Comment
  showReactions?: boolean
  onReactionClick?: (type: string, commentId: string) => void
  onRemoveReactionClick?: (commentId: string, reactionId: string) => void
  customClassName?: string
  dataTestId?: string
  onReply?: (memo: string, taggedUsers: [], parentId: string) => void
  saving?: boolean
  userOptions?: any[]
  isProductDrawer?: boolean
}

const PostedComment: React.FC<ChildProps> = ({
  comment,
  showReactions,
  onReactionClick,
  customClassName,
  dataTestId,
  onReply,
  onRemoveReactionClick,
  saving,
  userOptions,
  isProductDrawer,
}) => {
  const [showReplyInput, setShowReplyInput] = useState(false)

  const Reactions = ({
    data,
    isReply,
  }: {
    data: Comment
    isReply?: boolean
  }) => {
    const hideReply = isReply || data?.replies?.length > 0
    const isLiked = data.reaction_counts.like > 0
    const isDisliked = data.reaction_counts.dislike > 0
    return (
      <div className='v-center gap-3 pl-7 mt-3'>
        {!hideReply && !showReplyInput && (
          <Box
            as='a'
            onClick={() => setShowReplyInput(true)}
            dataTestid='reply-memo-btn'
          >
            <ToolTip text='Reply'>
              <div>
                <Icon iconName='Return' size={16} />
              </div>
            </ToolTip>
          </Box>
        )}
        <Box
          as='a'
          onClick={() =>
            isDisliked
              ? onRemoveReactionClick('dislike', data.id)
              : onReactionClick?.('dislike', data.id)
          }
          dataTestid='dislike-memo-btn'
        >
          <ToolTip text='Dislike'>
            <div>
              <Icon
                iconName='ThumbsDown'
                size={16}
                variant={isDisliked ? 'fill' : 'outline'}
              />
            </div>
          </ToolTip>
        </Box>
        <Box
          as='a'
          onClick={() =>
            isLiked
              ? onRemoveReactionClick('like', data.id)
              : onReactionClick?.('like', data.id)
          }
          dataTestid='like-memo-btn'
        >
          <ToolTip text='Like'>
            <div>
              <Icon
                iconName='ThumbsUp'
                size={16}
                variant={isLiked ? 'fill' : 'outline'}
              />
            </div>
          </ToolTip>
        </Box>
        <Box
          as='a'
          onClick={() =>
            data?.closed_at === null && onReactionClick?.('close', data.id)
          }
          dataTestid='close-memo-btn'
        >
          <ToolTip text={data?.closed_at === null ? 'Close' : 'Closed'}>
            <div>
              {data?.closed_at === null ? (
                <Icon iconName='Check' size={16} />
              ) : (
                <span className='body2-regular grey-700'>Closed</span>
              )}
            </div>
          </ToolTip>
        </Box>
      </div>
    )
  }

  const UserTags = ({ data }: { data: Comment }) => (
    <div className='v-center gap-2 pl-7 mt-3'>
      {data.tagged_users.map((user) => (
        <div className='hover-cursor' key={user.id}>
          <Tag
            id={user.id}
            primaryLabel={`${user.first_name} ${user.last_name || ''}`}
            tooltipContent={user.email}
          />
        </div>
      ))}
    </div>
  )

  const RenderComment = ({
    data,
    isReply,
  }: {
    data: Comment
    isReply?: boolean
  }) => (
    <div>
      <div className='v-center' data-testid={`comment-${data.id}`}>
        <SingleAvatar
          size={32}
          label={`${data.created_by.first_name} ${data.created_by.last_name}`}
        />
        <span className='body2-regular grey-700 ml-3'>
          {data.created_at &&
            formatDate({ date: data.created_at, withTime: true })}
        </span>
      </div>
      <div className='pl-7 body1-regular'>{data.text}</div>
      {data.tagged_users.length > 0 && <UserTags data={data} />}
      {showReactions && <Reactions data={data} isReply={isReply} />}
    </div>
  )

  const RenderPostCommentInput = (parentId: string) => (
    <PostCommentInput
      onPost={(memo, taggedUsers) => {
        onReply?.(memo, taggedUsers, parentId)
        setShowReplyInput(false)
      }}
      placeholderText='Add a reply'
      saving={saving}
      userOptions={userOptions}
    />
  )

  const gridSize = isProductDrawer ? 4 : 5
  const replyGridSize = isProductDrawer ? 3 : 1
  return (
    <div className={customClassName} data-testid={dataTestId}>
      <RenderComment data={comment} />
      {showReplyInput && (
        <div className='pl-7 mt-3'>{RenderPostCommentInput(comment.id)}</div>
      )}
      {comment?.replies && (
        <div className='mt-4 ml-7'>
          <div className='mb-4'>
            <GridRow padding={false} alignItems='center'>
              <GridCol lg={gridSize} md={gridSize} sm={gridSize}>
                <div className='divider-line-1' />
              </GridCol>
              <GridCol
                lg={replyGridSize}
                md={replyGridSize}
                sm={replyGridSize}
                justifySelf='center'
              >
                <div className='body2-regular grey-600'>
                  {comment.replies.length === 1
                    ? '1 reply'
                    : `${comment.replies.length} replies`}{' '}
                </div>
              </GridCol>
              <GridCol lg={gridSize} md={gridSize} sm={gridSize}>
                <div className='divider-line-1' />
              </GridCol>
            </GridRow>
          </div>
          {comment.replies.map((reply) => (
            <RenderComment key={reply.id} data={reply} isReply />
          ))}
          <div className='mt-3'>{RenderPostCommentInput(comment.id)}</div>
        </div>
      )}
    </div>
  )
}

export default PostedComment
