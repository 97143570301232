import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    is_rejected: 0,
  },
  // this sets product ids
  selected: [],
  // this sets proposal products id
  selectedProposalProductIds: []
}
const proposalProductsSlice = createSlice({
  name: 'proposal_products',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    addParams(state, action) {
      state.params = {
        ...state.params,
        ...action.payload,
      }
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    removeParams(state, action) {
      delete state.params[action.payload]
    },
    setCurrentPage: (state, action) => {
      state.params.page = action.payload
    },
    setIsRejected(state, action) {
      state.params.page = 1
      state.params.is_rejected = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setSelectedProposalProductIds: (state, action) => {
      state.selectedProposalProductIds = action.payload
    }
  },
})

export const {
  setSorting,
  addParams,
  setSearch,
  removeParams,
  setCurrentPage,
  setIsRejected,
  setSelected,
  setSelectedProposalProductIds,
} = proposalProductsSlice.actions

export default proposalProductsSlice.reducer
