import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, InputStepper, Modal } from 'atoms'
import VariantSearch from './variantSearch'
import Input from 'atoms/input/input'

type OrderLineItemsProps = {
  brandID: number
  setShowOrderLineModal: (arg: boolean) => void
  showOrderLineModal: boolean
  handleUpdate?: (data) => void
  netPriceIsRequired: boolean
  initialValues: any
  modalAction: string
}

const defaultValues = {
  orderLineNo: 1,
  quantity: 1,
  price: 0,
  itemCost: 0,
}

const OrderLineItemsModal: React.FC<OrderLineItemsProps> = ({
  brandID,
  setShowOrderLineModal,
  showOrderLineModal,
  handleUpdate,
  netPriceIsRequired,
  initialValues,
  modalAction,
}) => {
  const [formData, setFormData] = useState(defaultValues)
  const [variant, setVariant] = useState(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (initialValues?.randomUniqueId) {
      setFormData(initialValues)
      setVariant(initialValues.variant)
    }
  }, [initialValues])

  const handleSubmit = async () => {
    let lineItem = {
      ...formData,
      variant,
    }
    if (modalAction === 'Add') {
      lineItem['randomUniqueId'] = Math.floor(Math.random() * 100 + 1)
    }
    handleUpdate(lineItem)
    setShowOrderLineModal(false)
  }

  const fieldsData = [
    {
      id: 1,
      label: 'Order Line Number (Numerical values only)',
      value: formData.orderLineNo,
      name: 'orderLineNo',
      required: true,
      width: '456',
    },
    {
      id: 2,
      label: 'Quantity',
      value: formData.quantity,
      name: 'quantity',
      required: false,
      width: '141',
    },
    {
      id: 3,
      label: 'Price',
      value: formData.price,
      name: 'price',
      required: false,
      width: '141',
    },
    {
      id: 4,
      label: 'Item Cost',
      value: formData.itemCost,
      name: 'itemCost',
      required: false,
      width: '141',
    },
  ]

  const handleChange = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const validateForm = () => {
    let valid =
      formData.orderLineNo && formData.quantity && formData.price && variant?.id
        ? true
        : false
    if (netPriceIsRequired) {
      valid = valid && formData.itemCost ? true : false
    }
    setIsReady(valid)
  }

  useEffect(() => {
    validateForm()
  }, [variant, formData])

  const NumberStepperInput = (key) => {
    const fieldKeys = fieldsData.find((el) => el.name === key)
    return (
      <InputStepper
        label={fieldKeys.label}
        onValueChange={(val) =>
          handleChange({ name: fieldKeys.name, value: val })
        }
        value={formData[fieldKeys.name]}
        min={0}
        max={100000}
        stepSize={1}
        width={fieldKeys.width}
      />
    )
  }

  const NumberInput = (key: string) => {
    const fieldKeys = fieldsData.find((el) => el.name === key)
    return (
      <Input
        width='100%'
        label={fieldKeys.label}
        required={fieldKeys.required}
        inputProps={{
          name: fieldKeys.name,
          value: formData[fieldKeys.name],
          placeholder: formData[fieldKeys.name],
          onChange: (e) => handleChange(e.target),
          disabled: key === 'itemCost' && !netPriceIsRequired,
        }}
        maskOptions={{
          regex: '\\d*(\\.)?\\d*',
        }}
      />
    )
  }
  const primaryButtonText =
    modalAction === 'Add'
      ? 'Add Order Line Items'
      : modalAction === 'Edit'
      ? 'Save'
      : 'Delete'

  return (
    <>
      <Modal
        headerText={`${modalAction} order line items`}
        size='small'
        onClose={() => setShowOrderLineModal(false)}
        isVisible={showOrderLineModal}
        footerButtons={[
          {
            onClick: () => setShowOrderLineModal(false),
            text: 'Close',
            variant: 'secondary',
          },
          {
            onClick: () => handleSubmit(),
            text: primaryButtonText,
            variant: 'primary',
            isDisabled: !isReady,
          },
        ]}
        onBackdropClick={() => setShowOrderLineModal(false)}
      >
        <>
          <div className='mb-4'>{NumberStepperInput('orderLineNo')}</div>
          <div className='mb-4'>
            <VariantSearch
              brandId={brandID}
              handleSelection={(option) => setVariant(option)}
            />
          </div>
          <GridRow padding={false}>
            <GridCol lg={4} md={4} sm={4}>
              {NumberStepperInput('quantity')}
            </GridCol>
            <GridCol lg={4} md={4} sm={4}>
              {NumberInput('price')}
            </GridCol>
            <GridCol lg={4} md={4} sm={4}>
              {NumberInput('itemCost')}
            </GridCol>
          </GridRow>
        </>
      </Modal>
    </>
  )
}

export default OrderLineItemsModal
