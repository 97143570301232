export const PAGE_TYPE = {
  ORDERS: 'orders',
  PRODUCTS: 'products',
  RETURNS: 'returns',
  SHIPPING: 'shipping',
  TRACKING: 'tracking',
  INVOICES: 'invoices',
  CANCELLATIONS: 'cancellations',
  PROPOSALS: 'proposals',
  PROPOSAL_PRODUCTS: 'proposal_products',
  PROPOSAL_PRODUCT_ITEMS: 'proposal_product_items',
  SUPPLIERS: 'suppliers',
  MERCHANTS: 'merchants',
  PROPOSAL_ITEMS: 'proposal_items',
}

export const BUTTONS = {
  CLOSE: {
    LABEL: 'Close',
  },
  EXPORT: {
    LABEL: 'Export',
  },
}

export const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DESTRUCTIVE: 'destructive',
}

export const SEARCH = {
  PLACEHOLDERS: {
    GENERIC: 'Search within results',
    ORDER: 'Search by order ID or customer name',
    ORDER_NUMBER: 'Search by order number',
    PRODUCT: 'Search by product title or UPC',
  },
}

export const ICONS = {
  TRASH: 'Trash',
}
