import { FC, useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import SearchBar from 'molecules/search/searchBarTable'
import { setSearch } from 'store/productRulesetsSlice'
import { DataProps } from '@teamfabric/copilot-ui'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getCompatibilityRulesets } from 'api/rulesets'
import RulesetsTable from '../components/rulesets/listTable'
import RulesetsFilter from '../components/rulesets/filters'
import CreateRulesetModal from '../components/rulesets/createRulesetModal'

const ProductRulesets: FC = () => {
  const params = useSelector((state: RootState) => state.productRulesets.params)

  const [tableData, setTableData] = useState<DataProps[]>([])
  const [totalRows, setTotalRows] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Product Rulesets' })
        ),
      0
    )
  }, [])

  const fetchRulesets = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data: data } = await getCompatibilityRulesets({
      params: { ...params },
    })
    setTotalRows(data?.count)
    setTableData(data?.results)
    setLoading(false)
  }

  useEffect(() => {
    if (params) {
      ;(async () => {
        await fetchRulesets()
      })()
    }
  }, [params])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <CreateRulesetModal
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        loadRulesets={() => fetchRulesets(true)}
      />
      <PageHeaderComponent
        h1Text='Product Rulesets'
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => setShowCreateModal(true),
            text: 'Create Ruleset',
            variant: 'primary',
            icon: 'Add',
            iconPosition: 'left',
          },
        ]}
      />
      <SearchBar placeholder='Search within results...' setSearch={setSearch} />
      <div className='filterDiv'>
        <RulesetsFilter params={params} />
      </div>
      <RulesetsTable
        data={tableData}
        loading={loading}
        currentPage={params.page}
        totalRows={totalRows}
        loadRulesets={() => fetchRulesets(true)}
      />
    </Box>
  )
}

export default ProductRulesets
