export enum APP_PAGE_PATHS {
  HOME = '/home/activity',
  ORDERS_DASHBOARD = '/suppliers/overview',
  SUPPLIERS_LIST = '/suppliers',
  SUPPLIERS_PROPOSALS = '/suppliers/proposals',
  ORDERS_LIST = '/suppliers/orders',
  MERCHANTS = '/suppliers/merchants',
  PRODUCTS_DASHBOARD = '/suppliers/products',
  PROPOSALS_LIST = '/suppliers/products/proposals',
  SETTINGS = '/suppliers/settings',
  SUPPLIERS = '/suppliers/suppliers',
  IMPORT_REQUESTS = '/suppliers/products/import-requests',
  PRODUCTS_LIST = '/suppliers/products/list',
}

export enum APP_BREADCRUMB_LABELS {
  HOME = 'HOME',
  MARKETPLACE = 'MARKETPLACE',
  ORDERS = 'ORDERS',
  ORDERS_LIST = 'LIST',
  MERCHANTS = 'MERCHANTS',
  PRODUCTS = 'PRODUCTS',
  PROPOSALS = 'PROPOSALS',
  SETTINGS = 'SETTINGS',
  OVERVIEW = 'OVERVIEW',
  SUPPLIERS = 'SUPPLIERS',
  LIST = 'LIST',
  IMPORT_REQUESTS = 'IMPORT REQUESTS',
  INVITE = 'INVITE',
  DETAILS = 'DETAILS',
}

export const DATEPICKER_FORMAT = 'MM/dd/yyyy'
