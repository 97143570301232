import React, { useEffect, useState } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import { getAttributes } from 'api/attributes'
import { DropDownInitialObj, dropDownInitialObj } from 'lib/utils/initialValue'

type ChildProps = {
  handleUpdate?: (data: any) => void
  params?: any
  fieldLabel?: string
  selectedAttributeId?: number
}

const SelectAttribute: React.FC<ChildProps> = ({
  handleUpdate,
  params,
  fieldLabel,
  selectedAttributeId,
}) => {
  const [loading, setLoading] = useState(true)
  const [attributes, setAttributes] = useState([])
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [initialEffectRun, setInitialEffectRun] = useState(false)

  const fetchAttributes = async () => {
    try {
      const { data } = await getAttributes({ params: { pagination: 0 } })
      setAttributes(data?.results)
      const formattedOptions: DropDownInitialObj[] = data?.results.map((el) => {
        return {
          id: el.id,
          label: el.name,
        }
      })
      setOptions(formattedOptions)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await fetchAttributes()
    })()
  }, [])

  useEffect(() => {
    if (
      !initialEffectRun &&
      !loading &&
      options.length > 0 &&
      selectedAttributeId
    ) {
      const attribute = options.find((el) => el.id === selectedAttributeId)
      setSelectedOption(attribute)
      handleUpdate(attribute)
      setInitialEffectRun(true)
    }
  }, [initialEffectRun, selectedAttributeId, options, loading])

  return (
    <Dropdown
      dataTestid='attribute-dropdown'
      required
      label={fieldLabel || ''}
      width='100%'
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option)
        handleUpdate(option)
      }}
      options={options}
      placeholder='Select Attribute'
      disabled={loading}
    />
  )
}

export default SelectAttribute
