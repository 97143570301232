import React, { useEffect, useState } from 'react'
import { Link, Modal } from 'atoms'
import Card4Columns from 'molecules/cards/4Columns'
import ConnectionDetails from 'modules/orders/components/modal/orders/connectionDetails'
import PlatformOrderModal from '../modals/platformOrderModal'
import OrderNumberPresenter from './orderNumberPresenter'

type IdentifierProps = {
  loading: boolean
  details: any
  platformAccounts: any
}

const OrderIdentifiers: React.FC<IdentifierProps> = ({
  loading,
  details,
  platformAccounts,
}) => {
  const [orderIdentifierDetails, setOrderIdentifierDetails] = useState<any[]>(
    []
  )
  const [showConnectionModal, setShowConnectionInfoModal] = useState(false)
  const [showPlatformOrderModal, setShowPlatformOrderModal] = useState(false)

  useEffect(() => {
    if (!loading && platformAccounts !== undefined) {
      formatData(details)
    }
  }, [loading, details, platformAccounts])

  const formatData = ({
    retailer,
    customer_order_number,
    purchase_order_number,
    retailer_order_number,
    brand_identifier,
    id,
  }) => {
    const formattedDetails = [
      {
        label: 'Merchant',
        value: (
          <Link
            label={retailer.name}
            onClick={() => setShowConnectionInfoModal(true)}
            padding={false}
            theme='light'
            mode='inline'
            variant='primary'
          />
        ),
      },
      {
        label: 'Customer Order ID#',
        value: customer_order_number ? customer_order_number : 'Not Set',
      },
      {
        label: 'Purchase Order #',
        value: purchase_order_number ? purchase_order_number : 'Not Set',
      },
      {
        label: 'Retailer Order #',
        value: (
          <OrderNumberPresenter
            isRetailerOrderNumberCol={true}
            retailer_order_number={retailer_order_number}
            brand_identifier={brand_identifier}
            platformAccounts={platformAccounts}
            setShowPlatformOrderModal={setShowPlatformOrderModal}
          />
        ),
      },
      {
        label: 'Supplier Order #',
        value: (
          <OrderNumberPresenter
            isRetailerOrderNumberCol={false}
            retailer_order_number={retailer_order_number}
            brand_identifier={brand_identifier}
            platformAccounts={platformAccounts}
            setShowPlatformOrderModal={setShowPlatformOrderModal}
          />
        ),
      },
      {
        label: 'ID',
        value: id,
      },
    ]
    setOrderIdentifierDetails(formattedDetails)
  }

  return (
    <>
      <Card4Columns
        header='Order Identifiers'
        loading={loading}
        data={orderIdentifierDetails}
        customClassName='mb-4'
      />
      {/* modals */}
      <Modal
        headerText={details?.retailer?.name}
        size='small'
        onClose={() => setShowConnectionInfoModal(false)}
        isVisible={showConnectionModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setShowConnectionInfoModal(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        onBackdropClick={() => setShowConnectionInfoModal(false)}
      >
        <ConnectionDetails connectionId={details?.connection_id} />
      </Modal>
      <PlatformOrderModal
        isVisible={showPlatformOrderModal}
        platformAccounts={platformAccounts}
        setDisplaySkipModal={setShowPlatformOrderModal}
        loading={loading}
        details={details}
      />
    </>
  )
}

export default OrderIdentifiers
