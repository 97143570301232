import React from 'react'

type POColProps = {
  purchase_order_number: string
  onClick: () => void
}

const POCol: React.FC<POColProps> = ({ purchase_order_number, onClick }) => {
  return (
    <div className='clickableCells' onClick={() => onClick()}>
      {purchase_order_number}
    </div>
  )
}

export default POCol
