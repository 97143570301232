import axios from 'axios'
import { baseURL } from '../index'

export const getUser = async () => {
  // return await API.get(API_URL.GET_USER())
  try {
    const token = sessionStorage.getItem('accessToken')
    const selectedTenantID = sessionStorage.getItem('accountId')
    const response = await axios({
      method: 'get',
      url: `${baseURL}v1/users/me/`,
      headers: {
        'authorization': `Bearer ${token}`,
        'x-fabric-tenant-id': selectedTenantID,
      },
    })
    return response
  } catch (error) {
    console.error('Error: ', error)
  }
}

export const authenticateIdV2 = async () => {
  try {
    const token = sessionStorage.getItem('accessToken')
    // const tenant = sessionStorage.getItem('tenantDetail')
    const selectedTenantID = sessionStorage.getItem('accountId')
    // const tenantDetails = JSON.parse(tenant)
    const response = await axios({
      method: 'get',
      url: `${baseURL}v3/suppliers/`,
      headers: {
        'authorization': `Bearer ${token}`,
        'x-fabric-tenant-id': selectedTenantID,
      },
    })
    const mkpTenantDetails = {
      type: response.data.tenant_type,
      id: response.data.tenant.id,
      fabric_tenant_id:
        response.data.tenant.fabric_tenant_id || selectedTenantID,
    }
    const mkpTenantDetailsString = JSON.stringify(mkpTenantDetails)
    sessionStorage.setItem('mkp_tenant', mkpTenantDetailsString)
    return response.data
  } catch (error) {
    console.error('Error:', error)
  }
}
