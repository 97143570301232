import React, { useState, useEffect, useMemo } from 'react'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import {
  setInvitationsFilters,
  setInvitationStatusOptions,
} from 'store/suppliersSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  handleCheckboxFilterChange,
  setCountersAndChips,
} from 'lib/utils/filter/singleFilters'
import { FILTERS } from 'molecules/filter/constants'
import Box from 'atoms/box/box'
import FilterChips from 'organisms/filterChips'
import { MERCHANTS } from 'modules/merchants/components/constants'

type ChildProps = {
  params: any
}

const SupplierInvitationsFilter: React.FC<ChildProps> = ({ params }) => {
  const [statusCounter, setStatusCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.suppliers.invitationsStatusOptions
  )

  // Define respective filterKey fns
  const filterActions = {
    status: {
      action: setInvitationStatusOptions,
      options: statusCheckboxOptions,
      filterType: FILTERS.TYPES.CHECKBOX,
      setFilterCount: setStatusCounter,
      chipName: MERCHANTS.STATUS.NAME,
    },
  }

  // Update filter chips
  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxFilter
          dataTestid={`${MERCHANTS.STATUS.NAME}-filter`}
          label={MERCHANTS.STATUS.NAME}
          data={statusCheckboxOptions}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.STATUS.PARAM,
              setFilters: setInvitationsFilters,
              selectedFilterOptions,
            })
          }
          initialSelectedOptions={statusCounter}
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: MERCHANTS.STATUS.PARAM,
              setFilters: setInvitationsFilters,
              isReset: true,
            })
          }
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setInvitationsFilters}
      />
    </>
  )
}

export default SupplierInvitationsFilter
