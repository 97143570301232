import React, { useEffect, useState } from 'react'
import Card4Columns from 'molecules/cards/4Columns'
import { formatDate } from 'lib/utils/date/formatDate'

type KeyDateProps = {
  loading: boolean
  details: any
}

const KeyDates: React.FC<KeyDateProps> = ({ loading, details }) => {
  const [keyDatesDetails, setKeyDatesDetails] = useState<any[]>([])

  useEffect(() => {
    if (!loading) {
      formatData(details)
    }
  }, [loading, details])

  const getDate = ({ date, noDateString }) => {
    if (details.status === 'canceled') {
      return 'Order Canceled'
    }
    return date !== null ? formatDate({ date }) : noDateString
  }

  const formatData = ({
    received_at,
    fulfill_by,
    backorder_acknowledged_at,
    closed_at,
  }) => {
    let formattedDetails = [
      {
        label: 'Received',
        value: formatDate({ date: received_at }),
      },
      {
        label: 'Due',
        value: formatDate({ date: fulfill_by }),
      },
      {
        label: 'Accepted',
        value: getDate({
          date: backorder_acknowledged_at,
          noDateString: 'Not Accepted Yet',
        }),
      },
      {
        label: 'Closed',
        value: getDate({
          date: closed_at,
          noDateString: 'Not Closed Yet',
        }),
      },
    ]
    setKeyDatesDetails(formattedDetails)
  }
  return (
    <Card4Columns
      header='Key Dates'
      loading={loading}
      data={keyDatesDetails}
      customClassName='mb-4'
    />
  )
}

export default KeyDates
