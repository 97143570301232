import React, { useState, useEffect } from 'react'
import { truncate } from 'lodash'
import Card2Col from 'molecules/cards/2Col'

type ItemInfoProps = {
  item: any
  loading: boolean
}

const ItemInfo: React.FC<ItemInfoProps> = ({ item, loading }) => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    formatData(item)
  }, [loading])

  const formatData = ({
    id,
    brand,
    parent_identifier,
    parent_name,
    brand_identifier,
    name,
    upc,
  }) => {
    let formattedData = [
      {
        label: 'Item ID',
        value: id,
      },
      {
        label: 'Supplier',
        value: brand?.name,
      },
      {
        label: 'Supplier product SKU',
        value: parent_identifier ? parent_identifier : '---',
      },
      {
        label: 'Supplier product name',
        value: parent_name ? truncate(parent_name, { length: 64 }) : '---',
      },
      {
        label: 'Supplier SKU',
        value: brand_identifier,
      },
      {
        label: 'Supplier item name',
        value: name,
      },
      {
        label: 'UPC',
        value: upc,
      },
    ]
    setData(formattedData)
  }

  return (
    <div className='mt-3 mb-3 width-49'>
      <Card2Col
        loading={loading}
        header='Item Info'
        data={data}
        skeletonRowsNumber={7}
        showCollapse={false}
        open={true}
      />
    </div>
  )
}

export default ItemInfo
