import API, { API_URL } from 'api'

export const getInvitePreferences = async ({ params = {} }) => {
  return await API.get(API_URL.GET_INVITE_PREFERENCES(), { params })
}

export const postConnectionInvite = async (body) => {
  return await API.post(API_URL.CONNECTION_INVITE(), body)
}

export const getCommissionProfiles = async ({ params = {} }) => {
  return await API.get(API_URL.GET_COMMISSION_PROFILE(), { params })
}
