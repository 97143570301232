import React from 'react'
import { Skeleton, SkeletonAvatar } from 'atoms/skeleton'
import { AvatarSize } from '@teamfabric/copilot-ui'

type LoadingProps = {
  num?: number
}

const CommentLoading: React.FC<LoadingProps> = ({
  // number of Skeleton component lines, default to 3
  num = 3,
}) => {
  return new Array(num).fill('').map((_, index) => (
    <div
      data-testid='comment-loading'
      className='v-center mb-2'
      key={`comment-loading-${index}`}
    >
      <SkeletonAvatar size={AvatarSize.small} style={{ marginRight: '8px' }} />
      <Skeleton height='20px' width='50%' />
    </div>
  ))
}

export default CommentLoading
