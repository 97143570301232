import React, { useState, useEffect, ChangeEvent } from 'react'
import Dropdown from 'atoms/dropdown/dropdown'
import Input from 'atoms/input/input'
import { getProvinces } from 'api/province'
import GridRow from 'atoms/grid/gridRow'
import GridCol from 'atoms/grid/gridCol'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
  CountryOptions,
} from './constants'

interface AddressFormProps {
  initialValues?: AddressFormDataType
  handleUpdate?: (data: any) => void
  showEmail?: boolean
  showPhone?: boolean
  handleIsReady?: (data: boolean) => void
  type?: string
  showNickName?: boolean
  customLabels?: string[]
  isEmailReqired?: boolean
  isPhoneRequired?: boolean
}

const AddressForm2: React.FC<AddressFormProps> = ({
  initialValues,
  showEmail,
  showPhone,
  handleUpdate,
  handleIsReady,
  type,
  showNickName,
  customLabels,
  isEmailReqired,
  isPhoneRequired,
}) => {
  const [formData, setFormData] = useState(
    initialValues || AddressFormDefaultValues
  )
  const [selectedCountry, setSelectedCountry] = useState(CountryOptions[0])
  const [selectedProvince, setSelectedProvince] = useState({
    id: '',
    name: '',
    label: '',
    disabled: false,
  })
  const [loading, setLoading] = useState(true)
  const [provincesData, setProvincesData] = useState(null)
  const [filteredProvincesData, setFilteredProvincesData] = useState(null)

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues)
      if (initialValues.country) {
        const foundCountry = CountryOptions.find(
          ({ name }) => name === initialValues.country
        )
        setSelectedCountry(foundCountry)
      }
    }
  }, [initialValues])

  useEffect(() => {
    ;(async () => {
      await loadProvinces()
    })()
  }, [])

  const loadProvinces = async () => {
    setLoading(true)
    const {
      data: { results },
    } = await getProvinces({
      params: {
        pagination: 0,
      },
    })
    // format provinces data
    const formattedData = results?.map(({ id, code, name, country }) => ({
      id,
      name: code,
      label: name,
      country,
    }))
    setProvincesData(formattedData)
    setLoading(false)
  }

  useEffect(() => {
    if (!loading) {
      // set country provinces list
      const countryProvinces = provincesData?.filter(
        (item) => selectedCountry.label === item.country
      )
      setFilteredProvincesData(countryProvinces)
      // set selectedProvince
      if (
        formData.province !== '' &&
        formData.province !== null &&
        !formData.province.length
      ) {
        const findProvince = countryProvinces.find(
          (el) => el.name === formData.province
        )
        setSelectedProvince(findProvince)
      } else {
        setSelectedProvince(countryProvinces[0])
      }
    }
  }, [loading, provincesData])

  const searchProvinces = (searchName) => {
    const filteredData = filteredProvincesData?.filter(({ label }) =>
      label.toLowerCase().startsWith(searchName.toLowerCase())
    )
    setFilteredProvincesData(filteredData)
  }

  const filterProvinces = () => {
    let list = []
    if (selectedCountry.label === 'United Kingdom / Great Britain') {
      list = provincesData?.filter((el) => el.country === 'United Kingdom')
    } else {
      list = provincesData?.filter((el) => el.country === selectedCountry.label)
    }
    setFilteredProvincesData(list)
    if (list.length > 0) {
      setSelectedProvince(list[0])
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  // update formData with selected country name
  useEffect(() => {
    if (!loading && selectedCountry) {
      const findSelectedCountryObj = CountryOptions.find(
        (el) => el.label === selectedCountry.label
      )
      setFormData({
        ...formData,
        country: findSelectedCountryObj.name,
      })
      filterProvinces()
    }
  }, [selectedCountry])

  // update formData with selected provinces name
  useEffect(() => {
    if (!loading && provincesData && selectedProvince) {
      const findSelectedProvinceObj = provincesData.find(
        (el) => el.label === selectedProvince.label
      )
      setFormData({
        ...formData,
        province: findSelectedProvinceObj.name,
      })
    }
  }, [selectedProvince])

  useEffect(() => {
    handleUpdate(formData)
    const isValid = handleValidateForm()
    if (!loading) {
      handleIsReady(isValid)
    }
  }, [formData])

  const handleValidateForm = () => {
    let valid = true
    let requiredFields = [
      'name1',
      'street1',
      'postal_code',
      'city',
      'province',
      'country',
    ]
    if (showEmail && isEmailReqired) requiredFields.push('email')
    if (showPhone && isPhoneRequired) requiredFields.push('phone1')
    for (const field of requiredFields) {
      if (
        !(field in formData) ||
        formData[field] === null ||
        formData[field] === ''
      ) {
        valid = false
      }
    }
    return valid
  }

  const getNameLabel = () => {
    if (customLabels) {
      return customLabels[2]
    }
    return type && type === 'business' ? 'Business Name' : 'Customer Name'
  }

  return (
    <>
      {showNickName && (
        <Input
          width='100%'
          label={
            showNickName && customLabels ? customLabels[0] : 'Location Nickname'
          }
          required
          inputProps={{
            name: 'nickname',
            value: formData.nickname,
            placeholder: 'Location Nickname',
            onChange: handleChange,
          }}
        />
      )}
      <div className='mt-6'>
        <Dropdown
          required
          width='100%'
          options={CountryOptions}
          value={selectedCountry}
          placeholder='Select Country'
          label={customLabels ? customLabels[1] : 'Country'}
          onChange={(option: any) => {
            setSelectedCountry(option)
          }}
        />
      </div>
      <div className='mt-6'>
        <Input
          width='100%'
          label={getNameLabel()}
          required
          inputProps={{
            name: 'name1',
            value: formData.name1,
            placeholder:
              type && type === 'business' ? 'Business Name' : 'Customer Name',
            onChange: handleChange,
          }}
        />
      </div>
      <div className='mt-6'>
        <Input
          width='100%'
          label={customLabels ? customLabels[3] : 'Address'}
          required
          inputProps={{
            name: 'street1',
            value: formData.street1,
            placeholder: 'Street Line 1',
            onChange: handleChange,
          }}
        />
        <div className='mt-2'>
          <Input
            width='100%'
            label=''
            inputProps={{
              name: 'street2',
              value: formData.street2,
              placeholder: 'Street Line 2 (optional)',
              onChange: handleChange,
            }}
          />
        </div>
      </div>
      <div className='mt-6'>
        <Input
          width='100%'
          label={customLabels ? customLabels[4] : 'City'}
          required
          inputProps={{
            name: 'city',
            value: formData.city,
            placeholder: 'City',
            onChange: handleChange,
          }}
        />
      </div>
      <div className='mt-6'>
        <GridRow padding={false}>
          <GridCol lg={6} md={6} sm={6}>
            <Dropdown
              required
              width='100%'
              value={selectedProvince}
              options={filteredProvincesData}
              serverSideFiltering={{
                loading,
                noSearchResults: {
                  show: loading,
                },
                onInputChange: (target) => {
                  if (
                    target.length > 0 &&
                    target !== selectedProvince.label.toLocaleLowerCase()
                  ) {
                    setInterval(() => {
                      searchProvinces(target)
                    }, 2000)
                  }
                },
              }}
              placeholder='State / Province'
              label={customLabels ? customLabels[5] : 'State / Province'}
              onChange={(option: any) => {
                setSelectedProvince(option)
              }}
            />
          </GridCol>
          <GridCol lg={6} md={6} sm={6}>
            <Input
              width='100%'
              label={customLabels ? customLabels[6] : 'Zip / Postal Code'}
              required
              inputProps={{
                name: 'postal_code',
                value: formData.postal_code,
                placeholder: 'Zip/Postal Code',
                onChange: handleChange,
              }}
            />
          </GridCol>
        </GridRow>
      </div>
      {showPhone && (
        <div className='mt-6'>
          <Input
            width='100%'
            required={isPhoneRequired}
            inputProps={{
              name: 'phone1',
              value: formData.phone1,
              onChange: handleChange,
              placeholder: 'Phone Number',
              tooltipInfo: {
                text: 'Contact phone numbers are often required by carriers.',
              },
            }}
            label={showPhone && customLabels ? customLabels[7] : 'Phone Number'}
            maskOptions={{
              inputFormat: '+1(999) 999-9999',
              mask: '+1(999) 999-9999',
            }}
          />
        </div>
      )}
      {showEmail && (
        <div className='mt-6'>
          <Input
            width='100%'
            required={isEmailReqired}
            inputProps={{
              name: 'email',
              value: formData.email || '',
              onChange: handleChange,
              placeholder: 'Email',
            }}
            label={showEmail && customLabels ? customLabels[7] : 'Email'}
            maskOptions={{
              alias: 'email',
            }}
          />
        </div>
      )}
    </>
  )
}

export default AddressForm2
