import API, { API_URL } from 'api'

export const getInvoices = async ({ params = {} }) => {
  return await API.get(API_URL.GET_INVOICES(), { params })
}

export const addInvoice = async ({ body }) => {
  return await API.post(API_URL.GET_INVOICES(), body)
}

export const exportInvoices = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_INVOICES(), { params })
}

export const acceptInvoice = async ({ id }) => {
  return await API.put(API_URL.ACCEPT_INVOICE({ id }))
}

export const markInvoicePaid = async ({ id }) => {
  return await API.put(API_URL.MARK_INVOICE_PAID({ id }))
}
