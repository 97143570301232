import { StyledReturnDetail } from '../../styles'
import SingleAvatar from 'atoms/avatar/singleAvater'
import { formatDate } from 'lib/utils/date/formatDate'

type MessageColProps = {
  created_by: any
  created_at: any
  text: string
}

const MessageCol: React.FC<MessageColProps> = ({
  created_by,
  created_at,
  text,
}) => {
  return (
    <StyledReturnDetail>
      <div className='memoContainer'>
        <div>
          <SingleAvatar
            size={32}
            label={`${created_by.first_name} ${created_by.last_name}`}
          />
        </div>
        <div>
          <p>
            On {formatDate({ date: created_at })}{' '}
            {`${created_by.first_name} ${created_by.last_name}`} added...
          </p>
          <p>{text}</p>
        </div>
      </div>
    </StyledReturnDetail>
  )
}

export default MessageCol
