import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useToast } from '@teamfabric/copilot-ui'
import { Card, Box, Link, Table, Input, GridRow, GridCol, Button } from 'atoms'
import PageHeader from 'molecules/PageHeader'
import EditDeleteTableRow from 'molecules/table/editDeleteTableRow'
import ConfirmDeleteModal from 'molecules/modals/confirmDeleteModal'
import MappingModal from './mappingModal'
import {
  deletePlatformMappingsByFieldType,
  getPlatformMappingsByFieldType,
  updatePlatformTemplateById,
} from 'api/templates'
import { useParams } from 'react-router-dom'

type ChildProps = {
  templateDetails: any
  fieldType: 'mappings' | 'metafield-mappings' | 'tag-mappings'
}

const MappingsTableCard: FC<ChildProps> = ({ templateDetails, fieldType }) => {
  const { id, platform, templateId } = useParams()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [results, setResults] = useState([])
  const [showMappingModal, setShowMappingModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [mappingModalType, setMappingModalType] = useState(null)
  const [tagDelimiterValue, setTagDelimiterValue] = useState('')
  const [saving, setSaving] = useState(false)

  const fetchData = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getPlatformMappingsByFieldType({
      platformCode: platform,
      platformId: id,
      templateId: templateId,
      fieldType,
      params: {
        pagination: 0,
      },
    })
    setResults(data.results)
    setTotalCount(data.count)
    setLoading(false)
  }

  useEffect(() => {
    if (fieldType) {
      ;(async () => {
        await fetchData()
      })()
    }
  }, [fieldType])

  const getTableColumns = useCallback(() => {
    if (fieldType === 'tag-mappings') {
      return [
        {
          title: 'fabric Marketplace Attribute',
          width: '45%',
        },
        {
          title: 'Prefix',
          width: '20%',
        },
        {
          title: 'Preview',
          width: '25%',
        },
        {
          title: '',
          width: '10%',
        },
      ]
    } else if (fieldType === 'mappings') {
      return [
        {
          title: 'fabric Marketplace Attribute',
          width: '45%',
        },
        {
          title:
            platform === 'shopify' ? 'Shopify Attribute' : 'fabric Attribute',
          width: platform === 'shopify' ? '45%' : '30%',
        },
        ...(platform === 'fabric'
          ? [
              {
                title: 'Target',
                width: '15%',
              },
            ]
          : []),
        {
          title: '',
          width: '10%',
        },
      ]
    } else if (fieldType === 'metafield-mappings') {
      return [
        {
          title: 'fabric Marketplace Attribute',
          width: '20%',
        },
        {
          title: 'Namespace',
          width: '20%',
        },
        {
          title: 'Key',
          width: '15%',
        },
        {
          title: 'Type',
          width: '20%',
        },
        {
          title: 'Target',
          width: '15%',
        },
        {
          title: '',
          width: '10%',
        },
      ]
    }
  }, [fieldType])

  const tableColumns = useMemo(() => getTableColumns(), [getTableColumns])

  const tagDelimiter =
    tagDelimiterValue.length > 0
      ? tagDelimiterValue
      : templateDetails?.tag_delimiter

  const tableData = useMemo(() => {
    return results.map((mapping, id) => {
      const data = {}
      // Common field
      data['fabric Marketplace Attribute'] = {
        onRender: () => (
          <Link
            onClick={() => {
              setShowMappingModal(true)
              setMappingModalType('Edit')
              setSelectedRow(mapping)
            }}
            label={mapping?.attribute?.name}
            variant='primary'
            mode='inline'
            dataTestid={`${fieldType}-marketplace-attribute`}
          />
        ),
      }

      if (fieldType === 'tag-mappings') {
        // Fields specific to tag-mapping
        data['Prefix'] = { value: mapping?.title }
        data['Preview'] = {
          value: `${mapping?.title} ${tagDelimiter} ${mapping?.attribute?.name}`,
        }
      } else if (fieldType === 'mappings') {
        // Fields specific to mappings
        data['Shopify Attribute'] = { value: mapping?.title }
        data['fabric Attribute'] = { value: mapping?.title }
        if (platform === 'fabric') {
          data['Target'] = { value: mapping?.target }
        }
      } else if (fieldType === 'metafield-mappings') {
        data['Namespace'] = { value: mapping?.namespace }
        data['Key'] = { value: mapping?.key }
        data['Type'] = { value: mapping?.type }
        data['Target'] = { value: mapping?.target }
      }

      // Common field for actions (edit/delete)
      data[''] = {
        value: { ...mapping },
        onRender: (data) => (
          <EditDeleteTableRow
            handleClick={() => handleActions(data.value)}
            hideEdit
            dataTestId={fieldType}
          />
        ),
      }

      return { id, data }
    })
  }, [fieldType, results, tagDelimiter])

  const handleActions = (data) => {
    setShowDeleteModal(true)
    setSelectedRow(data)
  }

  const onDelete = async () => {
    const fieldTypeId = selectedRow.id
    try {
      await deletePlatformMappingsByFieldType({
        platformCode: platform,
        platformId: id,
        templateId: templateId,
        fieldType,
        fieldTypeId,
        body: {},
      })
      setShowDeleteModal(false)
    } finally {
      fetchData(true)
      setSelectedRow(null)
    }
  }

  const getHeading = useCallback(() => {
    if (fieldType === 'tag-mappings') {
      return {
        title: 'Tag Mappings',
        subTitle: 'Map fabric Marketplace attribute values to Shopify tags.',
      }
    } else if (fieldType === 'mappings') {
      return {
        title: 'Primary Attribute Mappings',
        subTitle:
          'Map fabric Marketplace attributes to primary attributes on Shopify products & variants.',
      }
    } else if (fieldType === 'metafield-mappings') {
      return {
        title: 'Metafield Mappings',
        subTitle: 'Map fabric Marketplace attribute values to Shopify tags.',
      }
    }
  }, [fieldType])

  const cardHeading = useMemo(() => getHeading(), [getHeading])

  const updateDelimiter = async () => {
    const body = {
      tag_delimiter: tagDelimiterValue,
    }
    try {
      setSaving(true)
      await updatePlatformTemplateById({
        platformCode: platform,
        platformId: id,
        templateId: templateId,
        body,
      })
      showToast({
        label: 'Tag Delimiter updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  return (
    <Box dataTestid={`${fieldType}-card`} margin={{ top: 4, bottom: 7 }}>
      {
        <ConfirmDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          headerText={`Delete Template Mapping: '${selectedRow?.title || selectedRow?.namespace}'?`}
          body1='Are you sure you want to delete the mapping?'
          handleClick={() => onDelete()}
          dataTestId={`${fieldType}-delete-modal`}
        />
      }
      {
        <MappingModal
          type={mappingModalType}
          fieldType={fieldType}
          showModal={showMappingModal}
          closeModal={() => {
            setShowMappingModal(false)
            setSelectedRow(null)
          }}
          selectedMapping={selectedRow}
          loadMappings={() => {
            fetchData(true)
            setSelectedRow(null)
          }}
        />
      }
      <Card
        showCollapse={false}
        headerContent={
          <Box width='100%'>
            <PageHeader
              dataTestid={`${fieldType}-heading`}
              h1Text={<span className='h5'>{cardHeading.title}</span>}
              h2Text={
                <span className='body1-regular grey-600'>
                  {cardHeading.subTitle}
                </span>
              }
              primaryButtons={[
                {
                  onClick: () => {
                    setShowMappingModal(true)
                    setMappingModalType('Add')
                  },
                  text: 'Add Mapping',
                  variant: 'primary',
                  dataTestid: `add-${fieldType}-button`,
                },
              ]}
            />
          </Box>
        }
        bodyContent={
          <>
            {fieldType === 'tag-mappings' && (
              <GridRow padding={false} alignItems='end'>
                <GridCol sm={6} md={4} lg={4}>
                  <Input
                    width='100%'
                    label='Tag Delimiter'
                    inputProps={{
                      value: tagDelimiterValue,
                      onChange: ({ target: { value } }) =>
                        setTagDelimiterValue(value),
                      tooltipInfo: {
                        text: 'When adding tags to Shopify, it can be useful to separate a tag into a key:value format separated by a delimiter (e.g. ":" or "=>"). You can configure your delimiter below (or just leave it blank)',
                      },
                      dataTestid: 'template-tag-delimiter',
                    }}
                  />
                </GridCol>
                <GridCol sm={4} md={3} lg={3}>
                  <Button
                    onClick={() => updateDelimiter()}
                    text='Update Delimiter'
                    variant='secondary'
                    isDisabled={saving}
                    dataTestid='update-delimiter-button'
                  />
                </GridCol>
              </GridRow>
            )}
            <Table
              dataTestidPrefix={fieldType}
              columns={tableColumns}
              data={tableData}
              showPagination
              disableTableBorder
              totalRecords={totalCount}
              perPageRecords={10}
              loading={loading}
            />
          </>
        }
      ></Card>
    </Box>
  )
}

export default MappingsTableCard
