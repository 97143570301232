// TODO: differentiate between supplier nad merchant return reasons
import React, { useEffect, useMemo, useState } from 'react'
import DateFilter from 'molecules/filter/singleFilter/genericDate'
import CheckboxWithSearchFilter from 'molecules/filter/singleFilter/checkboxWithSearch'
import {
  handleDateFilterChange,
  handleCheckboxFilterChange,
  handleCheckboxWithSearchFilterChange,
  setCountersAndChips,
  handleRadioFilterChange,
} from 'lib/utils/filter/singleFilters'
import CheckboxFilter from 'molecules/filter/singleFilter/checkboxFilter'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import {
  setFilters,
  setConnectionOptions,
  setStatusOptions,
  setReturnReasonOptions,
  setCreatedOn,
  resetAllFilters,
} from 'store/returnsSlice'
import Box from 'atoms/box/box'
import { FILTERS } from 'molecules/filter/constants'
import FilterChips from 'organisms/filterChips'
import ResetAllFiltersButton from 'molecules/filter/resetAllFilters'
import { ORDERS } from 'modules/orders/constants'
import RadioGroupFilter from 'molecules/filter/singleFilter/radioGroup'

type ChildProps = {
  params: any
}

const Filters: React.FC<ChildProps> = ({ params }) => {
  const [connectionsCounter, setConnectionsCounter] = useState(0)
  const [statusCounter, setStatusCounter] = useState(0)
  const [reasonCounter, setReasonCounter] = useState(0)
  const [acknowledgedCounter, setAcknowledgedCounter] = useState(0)
  const [createdOnCounter, setCreatedOnCounter] = useState(0)
  const [filterChips, setFilterChips] = useState([])

  const connectionCheckboxOptions = useSelector(
    (state: RootState) => state.returns.connectionOptions
  )
  const statusCheckboxOptions = useSelector(
    (state: RootState) => state.returns.statusOptions
  )
  const reasonCheckboxOptions = useSelector(
    (state: RootState) => state.returns.reasonOptions
  )
  const createdOn = useSelector((state: RootState) => state.returns.createdOn)

  const isAFilterApplied = useMemo(() => {
    return (
      statusCounter +
        reasonCounter +
        acknowledgedCounter +
        createdOnCounter +
        connectionsCounter >
      0
    )
  }, [
    statusCounter,
    reasonCounter,
    connectionsCounter,
    acknowledgedCounter,
    createdOnCounter,
  ])

  // Define respective filterKey fns
  const filterActions = {
    connection_id: {
      action: setConnectionOptions,
      options: connectionCheckboxOptions,
      setFilterCount: setConnectionsCounter,
      filterType: FILTERS.TYPES.CHECKBOX_WITH_SEARCH,
      chipName: ORDERS.CONNECTION.NAME,
    },
    status: {
      options: statusCheckboxOptions,
      action: setStatusOptions,
      setFilterCount: setStatusCounter,
      filterType: FILTERS.TYPES.CHECKBOX,
      chipName: ORDERS.RETURN_STATUS.NAME,
    },
    reason_id: {
      options: reasonCheckboxOptions,
      action: setReturnReasonOptions,
      setFilterCount: setReasonCounter,
      filterType: FILTERS.TYPES.CHECKBOX,
      chipName: ORDERS.RETURN_REASONS_SUPPLIER.NAME,
      useLabelForChip: true,
    },
    is_acknowledged: {
      setFilterCount: setAcknowledgedCounter,
      filterType: FILTERS.TYPES.RADIO,
      label: ORDERS.ACKNOWLEDGED.NAME + '?',
      chipName: ORDERS.ACKNOWLEDGED.NAME,
    },
    created_at_gte: {
      setFilterCount: setCreatedOnCounter,
      dateRange: createdOn,
      action: setCreatedOn,
      filterType: FILTERS.TYPES.DATE,
      chipName: ORDERS.RETURN_CREATED_ON.NAME,
    },
  }

  useEffect(() => {
    setCountersAndChips(filterActions, params, setFilterChips)
  }, [params])

  return (
    <>
      <Box flex gap={2}>
        <CheckboxWithSearchFilter
          dataTestid={`${ORDERS.CONNECTION.NAME}-filter`}
          label={ORDERS.CONNECTION.NAME}
          onChange={(id) =>
            handleCheckboxWithSearchFilterChange({
              id,
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
            })
          }
          onClear={() =>
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              isReset: true,
            })
          }
          onOptionClick={(selectedOption) => {
            handleCheckboxWithSearchFilterChange({
              filterActions,
              filterKey: ORDERS.CONNECTION.PARAM,
              setFilters,
              checkboxOptions: connectionCheckboxOptions,
              selectedOption,
              isHandleAdd: true,
            })
          }}
          checkboxTree={connectionCheckboxOptions}
          loading={false}
          filterCount={
            connectionsCounter > 0 ? connectionsCounter.toString() : ''
          }
        />
        <CheckboxFilter
          dataTestid={`${ORDERS.RETURN_STATUS.NAME}-filter`}
          label={ORDERS.RETURN_STATUS.NAME}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_STATUS.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_STATUS.PARAM,
              setFilters,
              isReset: true,
            })
          }
          data={statusCheckboxOptions}
          initialSelectedOptions={statusCounter}
        />
        <CheckboxFilter
          dataTestid={`${ORDERS.RETURN_REASONS_SUPPLIER.NAME}-filter`}
          label={ORDERS.RETURN_REASONS_SUPPLIER.NAME}
          onChangeHandler={(selectedFilterOptions) =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_REASONS_SUPPLIER.PARAM,
              setFilters,
              selectedFilterOptions,
            })
          }
          onResetHandler={() =>
            handleCheckboxFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_REASONS_SUPPLIER.PARAM,
              setFilters,
              isReset: true,
            })
          }
          data={reasonCheckboxOptions}
          initialSelectedOptions={reasonCounter}
        />
        <RadioGroupFilter
          dataTestid={`${ORDERS.ACKNOWLEDGED.NAME}-filter`}
          label={ORDERS.ACKNOWLEDGED.NAME}
          onChange={(e) =>
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              value: e.target.value,
              setFilters,
            })
          }
          onClear={() => {
            handleRadioFilterChange({
              filterKey: ORDERS.ACKNOWLEDGED.PARAM,
              setFilters,
            })
          }}
          value={params?.is_acknowledged?.toString()}
          options={FILTERS.RADIO_GROUP.YES_NO_OPTIONS}
          filterCount={
            acknowledgedCounter > 0 ? acknowledgedCounter?.toString() : ''
          }
        />
        <DateFilter
          dataTestid={`${ORDERS.RETURN_CREATED_ON.NAME}-filter`}
          label={ORDERS.RETURN_CREATED_ON.NAME}
          onChange={(date) => {
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_CREATED_ON.PARAMS.START,
              date,
            })
          }}
          defaultValue={createdOn}
          filterCount={createdOnCounter}
          onReset={() =>
            handleDateFilterChange({
              filterActions,
              filterKey: ORDERS.RETURN_CREATED_ON.PARAMS.START,
              isReset: true,
            })
          }
        />
        <ResetAllFiltersButton
          isAFilterApplied={isAFilterApplied}
          resetAllFilters={resetAllFilters}
        />
      </Box>
      <FilterChips
        filterChips={filterChips}
        filterActions={filterActions}
        setFilters={setFilters}
      />
    </>
  )
}

export default Filters
