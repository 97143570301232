import React, { useState, useEffect } from 'react'
import { GridCol, GridRow, Card, InputStepper } from 'atoms'
import Input from 'atoms/input/input'
import Checkbox from 'atoms/input/checkbox'
import Dropdown from 'atoms/dropdown/dropdown'
import { getCommissionProfiles, getInvitePreferences } from 'api/suppliers'

type AdvancedSettingsProps = {
  loading: boolean
  setLoading: (e: any) => void
  connectionLevel: any
  setConnectionLevel: (e: any) => void
  fulfillmentSla: any
  setFulfillmentSla: (e: any) => void
  paymentTerms: any
  setPaymentTerms: (e: any) => void
  netPriceMethod: any
  setNetPriceMethod: (e: any) => void
  commissionRate: any
  setCommissionRate: (e: any) => void
  commissionProfile: any
  setCommissionProfile: (e: any) => void
  returnsAllowance: any
  setReturnsAllowance: (e: any) => void
  catalogImportMethod: any
  setCatalogImportMethod: (e: any) => void
}

const AdvancedSettingsCard: React.FC<AdvancedSettingsProps> = ({
  loading,
  setLoading,
  connectionLevel,
  setConnectionLevel,
  fulfillmentSla,
  setFulfillmentSla,
  paymentTerms,
  setPaymentTerms,
  netPriceMethod,
  setNetPriceMethod,
  commissionRate,
  setCommissionRate,
  commissionProfile,
  setCommissionProfile,
  returnsAllowance,
  setReturnsAllowance,
  catalogImportMethod,
  setCatalogImportMethod,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [catalogImportOptions, setCatalogImportOptions] = useState([])
  const [commissionProfileOptions, setCommissionProfileOptions] = useState([])

  useEffect(() => {
    const loadPreferences = async () => {
      setLoading(true)
      const inviteParams = {
        grouping: 'onboarding',
      }
      try {
        const response = await getInvitePreferences({
          params: { ...inviteParams },
        })
        const preferencesData = response.data.results

        const level = preferencesData.find(
          (item) => item.code === 'default_connection_level'
        )
        const levelOption = level?.default_option
        setConnectionLevel({
          id: levelOption.value,
          label: levelOption.name,
        })

        const sla = preferencesData.find(
          (item) => item.code === 'default_fulfillment_sla'
        )
        const slaOption =
          sla?.retailer_preference?.value || sla?.default_option?.value
        setFulfillmentSla(slaOption ? slaOption / 24 : null)

        const payment = preferencesData.find(
          (item) => item.code === 'default_payment_terms'
        )
        const paymentOption = payment?.default_option

        setPaymentTerms({
          id: paymentOption.value,
          label: paymentOption.name,
        })

        const netPrice = preferencesData.find(
          (item) => item.code === 'default_net_price_method'
        )
        const netPriceOption = netPrice?.default_option
        setNetPriceMethod({
          id: netPriceOption.value,
          label: netPriceOption.name,
        })

        if (netPriceMethod?.id === 'revshare') {
          const commissionRateData = preferencesData.find(
            (item) => item.code === 'default_commission_rate'
          )
          if (commissionRateData && commissionRateData.retailer_preference) {
            setCommissionRate(
              parseFloat(commissionRateData.retailer_preference.value)
            )
          }
        } else if (netPriceMethod?.id === 'commission_profile') {
          const commissionProfileData = preferencesData.find(
            (item) => item.code === 'default_commission_rate'
          )
          if (
            commissionProfileData &&
            commissionProfileData.retailer_preference
          ) {
            setCommissionProfile({
              id: parseInt(commissionProfileData.retailer_preference.value, 10),
            })
          }
        }

        const returnsAllowanceData = preferencesData.find(
          (item) => item.code === 'default_returns_allowance'
        )
        if (returnsAllowanceData && returnsAllowanceData.retailer_preference) {
          setReturnsAllowance(
            parseFloat(returnsAllowanceData.retailer_preference.value)
          )
        }

        // Process catalogImportMethod
        const importMethodsData = preferencesData.find(
          (item) => item.code === 'default_catalog_import_method'
        )
        if (importMethodsData) {
          const options = importMethodsData.options.map((option) => ({
            id: option.id,
            name: option.name,
            value: option.value,
          }))
          setCatalogImportOptions(options)
          const option = importMethodsData?.default_option
          setCatalogImportMethod({
            id: option.id,
            name: option.name,
            value: option.value,
          })
          const optionsParams = {
            grouping: 'onboarding',
          }
          const response = await getCommissionProfiles({
            params: { ...optionsParams },
          })
          const optionsData = response.data.results
          if (optionsData && optionsData.length > 0) {
            const options = optionsData.map((option) => ({
              id: option.id,
              name: option.name,
              value: option.values[0].value,
            }))
            setCommissionProfileOptions(options)
          }
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    loadPreferences()
  }, [])

  return (
    <Card
      headerContent={
        <div className='styledCardHeader'>
          <h5 className='heading'>Advanced Settings</h5>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <div className='subHeading'>
                The following settings are preconfigured using your
                organization's default settings. You may override the default
                settings, but contact your administrator if you have any
                questions.
              </div>
            </GridCol>
          </GridRow>
        </div>
      }
      showCollapse={false}
      bodyContent={
        <>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Checkbox
                label={'I want to override the settings below:'}
                name={''}
                value={''}
                checked={disabled}
                disabled={disabled}
                onChange={(e) => setDisabled(e.target.checked)}
              />
            </GridCol>
          </GridRow>
          <br />
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Dropdown
                width='100%'
                label={'Connection Level'}
                value={connectionLevel}
                onChange={(option) => setConnectionLevel(option)}
                disabled={!disabled}
                tooltipInfo={{
                  text: "Select Transactions & Products if you plan to onboard this supplier with full transactional capabilities (i.e. products, inventory, orders, fulfillments, e.tc). If you're only using fabric Marketplace for product onboarding, select Products Only.",
                }}
                options={[
                  {
                    id: 'transactions_and_products',
                    label: 'Transactions & Products',
                  },
                  { id: 'products_only', label: 'Products Only' },
                ]}
              />
            </GridCol>
          </GridRow>
          <br />
          {/* Fulfillment SLA and Payment Terms only if connectionLevel is 'transactions_and_products' */}
          {connectionLevel?.id === 'transactions_and_products' && (
            <>
              <GridRow padding={false}>
                <GridCol lg={3} md={3} sm={3}>
                  <Input
                    width='100%'
                    label='Fulfillment SLA (Business Days)'
                    inputProps={{
                      disabled: !disabled,
                      value: fulfillmentSla,
                      onChange: ({ target: { value } }) => {
                        setFulfillmentSla(value)
                      },
                    }}
                  />
                </GridCol>
                <GridCol lg={3} md={3} sm={3}>
                  <Dropdown
                    width='100%'
                    label={'Payment Terms'}
                    value={paymentTerms}
                    onChange={(option) => setPaymentTerms(option)}
                    disabled={!disabled}
                    options={[
                      { id: 'net7', label: 'Net 7 Days' },
                      { id: 'net15', label: 'Net 15 Days' },
                      { id: 'net30', label: 'Net 30 Days' },
                      { id: 'net45', label: 'Net 45 Days' },
                      { id: 'net60', label: 'Net 60 Days' },
                    ]}
                  />
                </GridCol>
              </GridRow>
              <br />
            </>
          )}
          {/* Catalog Import Method */}
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <Dropdown
                width='100%'
                label={'Catalog Import Method'}
                value={catalogImportMethod}
                onChange={(option) => setCatalogImportMethod(option)}
                options={catalogImportOptions}
                disabled={!disabled}
              />
            </GridCol>
          </GridRow>
          <br />
          {/* Cost Model */}
          {connectionLevel?.id === 'transactions_and_products' && (
            <GridRow padding={false}>
              <GridCol lg={6} md={6} sm={6}>
                <Dropdown
                  width='100%'
                  label={'Cost Model'}
                  value={netPriceMethod}
                  disabled={!disabled}
                  onChange={(option) => setNetPriceMethod(option)}
                  options={[
                    { id: 'specified_on_order', label: 'Specified on Order' },
                    { id: 'permit_cost', label: 'Specified on Platform' },
                    { id: 'revshare', label: 'Commission' },
                    { id: 'commission_profile', label: 'Commission Profile' },
                  ]}
                />
              </GridCol>
            </GridRow>
          )}
          {netPriceMethod?.id === 'commission_profile' && (
            <>
              <br />
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <Dropdown
                    width='100%'
                    label={'Commission Profile'}
                    value={commissionProfile}
                    disabled={!disabled}
                    onChange={(option) => setCommissionProfile(option)}
                    options={commissionProfileOptions}
                  />
                </GridCol>
              </GridRow>
            </>
          )}
          {netPriceMethod?.id === 'revshare' && (
            <>
              <br />
              <GridRow padding={false}>
                <GridCol lg={6} md={6} sm={6}>
                  <InputStepper
                    width='100%'
                    label={'Rate (to Merchant)'}
                    max={99}
                    min={0}
                    onValueChange={(val) => setCommissionRate(val)}
                    stepSize={0.25}
                    value={commissionRate || 0}
                    isDisabled={!disabled}
                  />
                </GridCol>
              </GridRow>
            </>
          )}
          <br />
          {/* Returns Allowance */}
          {connectionLevel?.id === 'transactions_and_products' && (
            <GridRow padding={false}>
              <GridCol lg={3} md={3} sm={3}>
                <InputStepper
                  width='100%'
                  label={'Returns & Customer Service Allowance (%)'}
                  max={99}
                  min={0}
                  onValueChange={(val) => setReturnsAllowance(val)}
                  stepSize={0.25}
                  value={returnsAllowance || 0}
                  isDisabled={!disabled}
                />
              </GridCol>
            </GridRow>
          )}
        </>
      }
    />
  )
}

export default AdvancedSettingsCard
