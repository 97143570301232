import { FC } from 'react'

interface FulfillmentProps {
  connection: {
    connection_slas: {
      brand_fulfillment_hours: number
    }
  }
  showTooltip?: boolean
}

const FulfillmentSlaPresenter: FC<FulfillmentProps> = ({ connection }) => {
  const presenter = { label: '' }

  const computedPresenter = () => {
    const days = connection?.connection_slas?.brand_fulfillment_hours / 24
    switch (days) {
      case 1:
        presenter.label = '1 Business Day'
        break
      default:
        presenter.label = days + ' Business Days'
    }
  }

  computedPresenter()

  return <span>{presenter.label}</span>
}

export default FulfillmentSlaPresenter
