import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProposalVariantDetail } from 'api/proposals'
import { capitalize } from 'lodash'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import { formatRule, formatVariantAttributeData } from 'modules/proposals/utils'
import { Box, Link } from 'atoms'

type ChildProps = {
  selected: any
}

const DrawerItemAttributes: FC<ChildProps> = ({ selected }) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [variantDetails, setVariantDetails] = useState<any>({})
  const [collapseAllRuleset1, setCollapseAllRuleset1] = useState(false)
  const [collapseAllRuleset2, setCollapseAllRuleset2] = useState(false)

  const fetchVariantData = async (quiet?: boolean) => {
    if (!quiet) setLoading(true)
    const { data } = await getProposalVariantDetail({
      id: id || 10550,
      variantId: selected.id,
      params: { editor: 1 },
    })
    setVariantDetails(data)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await fetchVariantData()
    })()
  }, [selected])

  const rulesetLabels = useMemo(() => {
    let labels = []
    variantDetails?.ruleset_variants?.forEach((ruleset) =>
      labels.push(`${ruleset.ruleset?.name} (${capitalize(ruleset.status)})`)
    )
    return labels
  }, [variantDetails])

  const itemAttributesInfo = useMemo(() => {
    return formatVariantAttributeData(variantDetails)
  }, [variantDetails])

  const getRulesetResults = (ruleset) => {
    if (!ruleset?.results) return {}
    return ruleset.results.reduce((acc, result) => {
      acc[result.rule.attribute.code] = result
      return acc
    }, {})
  }

  const renderRulesetGroups = (ruleset, showOpen) => {
    const rulesetResults = getRulesetResults(ruleset)
    return ruleset?.ruleset?.rule_groups?.map((group) => {
      const formattedRulesData = group.rules.map((rule) => {
        const attribute =
          variantDetails?.attributes?.[rule.attribute.code] || null
        const result = rulesetResults?.[rule.attribute.code] || null
        return formatRule({
          key: rule.id,
          rule,
          attribute,
          result,
        })
      })
      return (
        <div key={group.id} className='mt-4'>
          <ModalCard2Col
            header={capitalize(group.name)}
            open={!showOpen}
            showCollapse={true}
            data={formattedRulesData}
            addGap
            loading={loading}
            dataTestid={`${group.name}-card`}
          />
        </div>
      )
    })
  }

  return (
    <>
      <Box margin={{ top: 3 }}>
        <ModalCard2Col
          header='Proposal Item Attributes'
          open
          showCollapse
          data={itemAttributesInfo}
          addGap
          loading={loading}
          dataTestid='item-attributes-info-card'
        />
        {!loading && rulesetLabels?.[0] && (
          <>
            <div className='body1-medium grey-700 mt-3 mb-3 space-between'>
              <span>{rulesetLabels?.[0]}</span>
              <Link
                dataTestid='collapse-ruleset-1-link'
                label={collapseAllRuleset1 ? 'Expand All' : 'Collapse All'}
                onClick={() => setCollapseAllRuleset1(!collapseAllRuleset1)}
              />
            </div>
            {renderRulesetGroups(
              variantDetails?.ruleset_variants?.[0],
              collapseAllRuleset1
            )}
          </>
        )}
      </Box>
      {!loading && rulesetLabels?.[1] && (
        <Box margin={{ top: 3 }}>
          <div className='body1-medium grey-700 mb-3 space-between'>
            <span>{rulesetLabels[1]}</span>
            <Link
              dataTestid='collapse-ruleset-2-link'
              label={collapseAllRuleset2 ? 'Expand All' : 'Collapse All'}
              onClick={() => setCollapseAllRuleset2(!collapseAllRuleset2)}
            />
          </div>
          {renderRulesetGroups(
            variantDetails?.ruleset_variants?.[1],
            collapseAllRuleset2
          )}
        </Box>
      )}
    </>
  )
}

export default DrawerItemAttributes
