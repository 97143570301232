import React, { useState } from 'react'
import { GridCol, GridRow, Card } from 'atoms'
import AddressForm from 'molecules/address/form'
import {
  AddressFormDataType,
  AddressFormDefaultValues,
} from 'molecules/address/constants'

interface ChildProps {
  handleEdit: (data: any) => void
}

const ShipTo: React.FC<ChildProps> = ({ handleEdit }) => {
  const [address, setAddress] = useState<AddressFormDataType>(
    AddressFormDefaultValues
  )
  const [isReady, setIsReady] = useState(false)

  const handleUpdate = (data: any) => {
    setAddress(data)
    if (isReady) {
      handleEdit(address)
    }
  }

  return (
    <Card
      headerContent={
        <div className='styledCardHeader'>
          <h5 className='heading'>Ship To</h5>
          <div className='subHeading'>Add your ship to address.</div>
        </div>
      }
      showCollapse={false}
      open
      showDivider={false}
      bodyContent={
        <div className='mb-4'>
          <GridRow padding={false}>
            <GridCol lg={6} md={6} sm={6}>
              <AddressForm
                handleUpdate={handleUpdate}
                showPhone
                showEmail
                handleIsReady={(value) => setIsReady(value)}
                type='billing'
              />
            </GridCol>
          </GridRow>
        </div>
      }
    />
  )
}

export default ShipTo
