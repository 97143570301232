import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import {
  setCurrentStep,
  setInfo,
  setNextAvaiableStep,
} from 'store/onboardingSlice'
import Button from 'atoms/button/button'
import { getUsers } from 'api/user'
import { getContextDetails, patchContextDetails } from 'api/context'
import InviteMemberModal from './InviteMemberModal'
import { RootState } from 'store'
import { useToast } from '@teamfabric/copilot-ui'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import { completeTask } from './utils'
import CardLoading from 'molecules/loading/cardLoading'
import { PATHS } from 'routes/pageRoutes'
import Dropdown from 'atoms/dropdown/dropdown'

type ContactProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
  reseting: boolean
}

const Contact: React.FC<ContactProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  isSaveExitClicked,
  setIsSaveExitClicked,
  reseting,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()
  const state = useSelector((state: RootState) => state)
  const info = state.onboarding.info

  const [isDisplayInviteModal, setIsDisplayInviteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [selectedPrimaryUser, setSelectedPrimaryUser] =
    useState(dropDownInitialObj)
  const [selectedMerchandiseUser, setSelectedMerchandiseUser] =
    useState(dropDownInitialObj)
  const [selectedFulfillmentUser, setSelectedFulfillmentUser] =
    useState(dropDownInitialObj)
  const [originalContactData, setOriginalContactData] = useState({
    connections_manager_id: '',
    merchandising_manager_id: '',
    shipping_manager_id: '',
  })

  const TASK_KEY = 'CONTACTS'
  const updateInfo = {
    processNum: 3,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: true,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: true,
    [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: true,
    completed: true,
  }

  const loadUsers = async () => {
    const {
      data: { results },
    } = await getUsers({
      params: {
        mini: 1,
        pagination: 0,
        order_by: 'first_name',
      },
    })
    const formattedUser = results.map(({ id, first_name, last_name }) => ({
      id,
      label: `${first_name} ${last_name}`,
    }))
    setUsers(formattedUser)
  }

  const loadSupplier = async () => {
    const { data } = await getContextDetails()
    setOriginalContactData({
      connections_manager_id: data.connections_manager.id,
      merchandising_manager_id: data.merchandising_manager.id,
      shipping_manager_id: data.shipping_manager.id,
    })
    if (data.connections_manager) {
      setSelectedPrimaryUser({
        id: data.connections_manager.id,
        label: `${data.connections_manager.first_name} ${data.connections_manager.last_name}`,
      })
    }
    if (data.merchandising_manager) {
      setSelectedMerchandiseUser({
        id: data.merchandising_manager.id,
        label: `${data.merchandising_manager.first_name} ${data.merchandising_manager.last_name}`,
      })
    }
    if (data.shipping_manager) {
      setSelectedFulfillmentUser({
        id: data.shipping_manager.id,
        label: `${data.shipping_manager.first_name} ${data.shipping_manager.last_name}`,
      })
    }
  }

  // First Loaded
  useEffect(() => {
    ;(async () => {
      try {
        setIsNextButtonDisabled(false)
        setLoading(true)
        await Promise.all([loadUsers(), loadSupplier()])
      } catch (error) {
        showToast({
          label: "Couldn't load",
          variant: 'error',
          isDismissable: true,
          id: '1',
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const updateSlices = () => {
    dispatch(
      setInfo({
        processNum: 3,
        [ONBOARDING_TASKS.INFO.SUB_TASKS.BILLING.TASK_NAME]: true,
        [ONBOARDING_TASKS.INFO.SUB_TASKS.LOCATIONS.TASK_NAME]: true,
        [ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.TASK_NAME]: true,
        completed: true,
      })
    )
    dispatch(
      setCurrentStep({
        value: ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.TASK_NAME,
        parent: ONBOARDING_TASKS.NOTIFICATIONS.VALUE,
      })
    )
    dispatch(setNextAvaiableStep(ONBOARDING_TASKS.NOTIFICATIONS.VALUE))
  }

  const isUpdated = () => {
    return (
      selectedPrimaryUser.id !== originalContactData.connections_manager_id ||
      selectedMerchandiseUser.id !==
        originalContactData.merchandising_manager_id ||
      selectedFulfillmentUser.id !== originalContactData.shipping_manager_id
    )
  }

  const saveContacts = async () => {
    try {
      if (!isUpdated()) {
        return
      }
      await patchContextDetails({
        body: {
          connections_manager: { id: selectedPrimaryUser.id },
          merchandising_manager: { id: selectedMerchandiseUser.id },
          shipping_manager: { id: selectedFulfillmentUser.id },
        },
      })
      showToast({
        label: 'Saved contact',
        isDismissable: true,
        id: '2',
      })
    } catch (error) {
      showToast({
        label: "Couldn't save",
        variant: 'error',
        isDismissable: true,
        id: '3',
      })
    }
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      setLoading(true)
      await saveContacts()
      await completeTask({
        info,
        key: TASK_KEY,
        dispatch,
        updateInfo,
      })
      setLoading(false)
      updateSlices()
      navigate(ONBOARDING_TASKS.NOTIFICATIONS.LINK)
    })()
  }, [isNextClicked])

  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      setLoading(true)
      await saveContacts()
      await completeTask({
        info,
        key: TASK_KEY,
        dispatch,
        updateInfo,
      })
      setLoading(false)
      updateSlices()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  const addNewUser = (user: object) => {
    setUsers([...users, user])
  }

  return (
    <>
      <InviteMemberModal
        isVisible={isDisplayInviteModal}
        setIsDisplayInviteModal={setIsDisplayInviteModal}
        addNewUser={addNewUser}
      />
      {(reseting || loading) && (
        <CardLoading
          label={ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.NAME}
          num={3}
        />
      )}
      {!reseting && !loading && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={true}
          headerContent={
            <div style={{ width: '70%' }}>
              <h1 className='h5 mb-2'>
                {ONBOARDING_TASKS.INFO.SUB_TASKS.CONTACTS.NAME}
              </h1>
              <p className='body1-regular'>
                Select or invite team members for key point-of-contact roles.
                This will ensure relevant questions and inquiries are routed to
                the correct individuals.
              </p>
              <div className='is-flex'>
                <p className='body1-regular'>Need to add a team member?</p>
                <div className='v-center'>
                  <Button
                    text='Invite a new teammate'
                    variant='tertiary'
                    onClick={() => {
                      setIsDisplayInviteModal(true)
                    }}
                  />
                </div>
              </div>
            </div>
          }
          bodyContent={
            <>
              <div className='pb-5'>
                <h2 className='kicker upper'>Primary Business Contact</h2>
                <Dropdown
                  required
                  width='70%'
                  label='Key contact for all partner business inquiries'
                  value={selectedPrimaryUser}
                  onChange={(value) => setSelectedPrimaryUser(value)}
                  options={users}
                  placeholder='Select Team Member'
                />
              </div>
              <div className='border-line'></div>
              <div className='pb-5'>
                <h2 className='kicker'>Merchandising Contact</h2>
                <Dropdown
                  required
                  width='70%'
                  label='Key contact for providing product information'
                  value={selectedMerchandiseUser}
                  onChange={(value) => setSelectedMerchandiseUser(value)}
                  options={users}
                  placeholder='Select Team Member'
                />
              </div>
              <div className='border-line'></div>
              <div className='pb-5'>
                <h2 className='kicker'>Fulfillment Contact</h2>
                <Dropdown
                  required
                  width='70%'
                  label='Key contact for order fulfillment inquiries'
                  value={selectedFulfillmentUser}
                  onChange={(value) => setSelectedFulfillmentUser(value)}
                  options={users}
                  placeholder='Select Team Member'
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}

export default Contact
