import { Link as LinkUI } from '@teamfabric/copilot-ui'
import React from 'react'

type LinkProps = {
  dataTestid?: string
  label: string
  variant?: any
  icon?: string
  href?: string
  theme?: any
  mode?: any
  disabled?: any
  padding?: boolean
  onClick?: () => void
  size?: any
  iconPosition?: 'left' | 'right'
}

const Link: React.FC<LinkProps> = ({ ...props }) => {
  return props.href ? (
    <LinkUI
      label={props.label}
      variant={props.variant}
      href={props.href}
      mode={props.mode}
    />
  ) : (
    <LinkUI {...props} />
  )
}

export default Link
