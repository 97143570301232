type ReturnIDColProps = {
  id: number
  onClick: () => void
}

const ReturnIDCol: React.FC<ReturnIDColProps> = ({ id, onClick }) => {
  return (
    <div onClick={() => onClick()} className='clickableCells'>
      {id}
    </div>
  )
}

export default ReturnIDCol
