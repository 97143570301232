import React, { useEffect, useState } from 'react'
import { DataProps, theme } from '@teamfabric/copilot-ui'
import Modal from 'atoms/modal/modal'
import MemoModal from '../../modal/orders/memoModal'
import OrderLineItems from '../../modal/orders/orderlineItems'
import { formatDate } from 'lib/utils/date/formatDate'
import Table from 'atoms/table/table'
import { useDispatch, useSelector } from 'react-redux'
import PurchaseOrderNumCol from './parts/PurchaseOrderNumCol'
import { ORDERS } from '../../../constants'
import Link from 'atoms/link/link'
import { setCurrentPage, setSelected, setSorting } from 'store/orderSlice'
import { getTenantType } from 'api/helper'
import TagCol from 'molecules/tag/tagCol'
import { RootState } from 'store'
import { useNavigate } from 'react-router-dom'
import { Badge } from 'atoms'
import { getBadgeStatusByOrderStatus } from 'modules/orders/utils/orders'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  currentSortBy: string
  totalRows: number
}

const OrderTable: React.FC<ChildProps> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isRetailer } = getTenantType()
  const selectedOrders = useSelector(
    (state: RootState) => state.orders.selected
  )
  const [tableData, setTableData] = useState<DataProps[]>([])
  const [orderData, setOrderData] = useState([])
  const [displayMemo, setDisplayMemo] = useState(false)
  const [displayOrderLineItems, setDisplayOrderLineItems] = useState(false)
  const [selectedMemo, setSelectedMemo] = useState({
    orderId: 0,
    memos_count: 0,
  })
  const [orderLines, setOrderLines] = useState([])

  useEffect(() => {
    formatSetTableData({ data: props?.data })
    setOrderData(props.data)
  }, [props?.loading, props.data, selectedOrders])

  const tableColumns = [
    {
      sortable: true,
      title: 'Order ID',
    },
    {
      sortable: false,
      title: isRetailer ? 'Supplier' : 'Merchant',
    },
    {
      sortable: true,
      title: 'Ordered',
      width: 120,
    },
    {
      title: 'Due',
      width: 120,
    },
    {
      title: 'Fulfilled',
      width: 120,
    },
    {
      title: 'Service',
      width: 100,
    },
    {
      title: 'Tags',
      width: 100,
    },
    {
      title: 'Status',
      width: 80,
    },
    {
      title: 'Messages',
      width: 100,
    },
  ]

  const updateMemoCount = (orderId: number) => {
    const updatedMemoCount = orderData.map((data) =>
      data.id === orderId
        ? { ...data, memos_count: parseInt(data.memos_count) + 1 }
        : data
    )
    setOrderData(updatedMemoCount)
    formatSetTableData({ data: updatedMemoCount })
  }

  const getBrandCol = ({ brand, brands }) => {
    if (brand) {
      return brand?.name
    } else if (brands && brands.length > 0) {
      return brands[0]?.name
    }
    return 'Not Set'
  }

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      ({
        id,
        purchase_order_number,
        retailer,
        brands,
        brand,
        received_at,
        fulfill_by,
        order_lines,
        requested_shipping_method,
        status,
        tags,
        memos_count,
      }) => {
        return {
          id,
          selected: selectedOrders.includes(id),
          data: {
            'Order ID': {
              onRender: () => (
                <Link
                  label={purchase_order_number}
                  variant='primary'
                  onClick={() => navigate(`/orders/${id}`)}
                  mode='inline'
                />
              ),
            },
            [isRetailer ? 'Supplier' : 'Merchant']: {
              value: isRetailer
                ? getBrandCol({ brand, brands })
                : retailer?.name,
            },
            'Ordered': { value: formatDate({ date: received_at }) },
            'Due': { value: formatDate({ date: fulfill_by }) },
            'Fulfilled': {
              onRender: () => (
                <PurchaseOrderNumCol
                  lines={order_lines}
                  onClick={() => {
                    setDisplayOrderLineItems(true)
                    setOrderLines(order_lines)
                  }}
                />
              ),
            },
            'Service': { value: requested_shipping_method?.name },
            'Tags': {
              onRender: () => <TagCol tags={tags} />,
            },
            'Status': {
              onRender: () => (
                <Badge
                  label={status}
                  variant='primary'
                  status={getBadgeStatusByOrderStatus(status)}
                />
              ),
            },
            'Messages': {
              onRender: () => (
                <div
                  className={`flex-end mr-5 ${
                    memos_count === 0 ? 'grey-1000' : 'blue-500'
                  }`}
                  onClick={() => {
                    setDisplayMemo(true)
                    setSelectedMemo({
                      orderId: id,
                      memos_count,
                    })
                  }}
                >
                  {memos_count}
                </div>
              ),
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
    dispatch(setSelected([]))
  }

  //TODO: create custom hooks for selecting rows to handle this centrally?
  const handleSingleRowSelect = (e, rowDetails: any, selected: boolean) => {
    if (selected) {
      dispatch(setSelected([rowDetails.id, ...selectedOrders]))
    } else {
      const newSelectedItemsIds = selectedOrders.filter(
        (selectedItemId) => selectedItemId !== rowDetails.id
      )
      dispatch(setSelected(newSelectedItemsIds))
    }
  }

  const handleAllRowSelect = (e, allRowsSelected: boolean) => {
    if (!allRowsSelected) {
      dispatch(setSelected([]))
    } else {
      const allItemIds = tableData.map((item) => item.id)
      dispatch(setSelected(allItemIds))
    }
  }

  const handleSort = (key: string) => {
    const mappedKey = ORDERS.SORTING_MAPS.ORDERS[key]
    let newSortBy = ''
    // if sorting by the current sorting key, change sorting direction, otherwise sort descending by new key
    if (mappedKey.replace('-', '') == props.currentSortBy.replace('-', '')) {
      newSortBy =
        props.currentSortBy.charAt(0) == '-'
          ? props.currentSortBy.slice(1)
          : `-${props.currentSortBy}`
    } else {
      newSortBy = `-${mappedKey}`
    }
    dispatch(setSorting(newSortBy))
    dispatch(setCurrentPage(1))
  }

  return (
    <div>
      {/* Modal purchased id */}
      <Modal
        headerText='Order Line Items'
        description=''
        onClose={() => setDisplayOrderLineItems(false)}
        isVisible={displayOrderLineItems}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => setDisplayOrderLineItems(false),
            text: 'Close',
            variant: 'secondary',
          },
        ]}
        dataTestid={''}
        onBackdropClick={function (): void {
          throw new Error('Function not implemented.')
        }}
      >
        <OrderLineItems data={orderLines} />
      </Modal>
      {/* Modal comments */}
      <MemoModal
        memoData={selectedMemo}
        modalClose={() => setDisplayMemo(false)}
        updateMemoCount={updateMemoCount}
        isVisible={displayMemo}
      />
      <Table
        columns={tableColumns}
        data={tableData}
        loading={props.loading}
        selectable={true}
        onRowSelect={(e, rowDetails, selected) =>
          handleSingleRowSelect(e, rowDetails, selected)
        }
        onAllRowSelect={(e, allRowsSelected) =>
          handleAllRowSelect(e, allRowsSelected)
        }
        customPaginationProps={{
          handlePagination: (pageNumber: number) => {
            setPage(pageNumber)
          },
          activePageNumber: props.currentPage,
          perPage: 10,
          totalRecords: props.totalRows,
        }}
        onAscendingSort={(key: string) => handleSort(key)}
        onDescendingSort={(key: string) => handleSort(key)}
      />
    </div>
  )
}

export default OrderTable
