import React, { useState, useEffect } from 'react'
import { getShipmentDetail } from 'api/shipments'
import { formatDate } from 'lib/utils/date/formatDate'
import { Table, Badge } from 'atoms'
import { getBadgeStatusByOrderTrackingStatus } from 'modules/orders/utils/tracking'
import VariantItem from 'molecules/lineItem/variantItem'
import Card2Col from 'molecules/cards/2Col'
import TrackingNumberLink from 'modules/orders/detail/presenters/trackingNumberLink'
import AddressCardPresenter from 'modules/orders/detail/presenters/addressCard'
import { formatCurrency } from 'lib/utils/currency/formatCurrency'

type ChildProps = {
  shipmentId: string
}

const ShipmentDetails: React.FC<ChildProps> = (props) => {
  const [itemDetailData, setItemDetailData] = useState<any[]>([])
  const [lineItemDataTable, setLineItemDataTable] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [shipmentDetails, setShipmentDetails] = useState({
    ship_from: {},
    shipment_lines: [],
  })

  useEffect(() => {
    ;(async () => {
      const { data } = await getShipmentDetail({ id: props?.shipmentId })
      setShipmentDetails(data)
      formatItemDetailData(data)
      formatLineItemData(data.shipment_lines)
      setLoading(false)
    })()
  }, [])

  const formatItemDetailData = ({
    shipping_method,
    tracking_number,
    tracking_url,
    valid_shipping_label,
    ship_height,
    ship_length,
    ship_width,
    ship_girth,
    ship_weight,
    signature,
    id,
    purchase_order_number,
    status,
    acknowledged_at,
  }) => {
    const formattedData = [
      {
        label: 'Service',
        value: shipping_method.name,
      },
      {
        label: 'Tracking Number',
        value: {
          onRender: () => (
            <TrackingNumberLink
              tracking_url={tracking_url}
              tracking_number={tracking_number}
            />
          ),
        },
      },
      {
        label: 'Source',
        value: valid_shipping_label ? 'Internal' : 'External',
      },
      {
        label: 'Dimensions',
        value:
          ship_girth !== null
            ? `${ship_height}"x${ship_length}"x${ship_width}(${ship_girth}"),${ship_weight} lbs`
            : '- Unknown -',
      },
      {
        label: 'Signature',
        value: signature ? 'Required' : 'Not Required',
      },
      {
        label: 'ID',
        value: id,
      },
      {
        label: 'PO #',
        value: purchase_order_number,
      },
      {
        label: 'Status',
        value: {
          onRender: () => (
            <Badge
              label={status}
              variant='primary'
              status={getBadgeStatusByOrderTrackingStatus(status)}
            />
          ),
        },
      },
      {
        label: 'Acknowledged',
        value: acknowledged_at
          ? formatDate({ date: acknowledged_at })
          : 'Not acknowledged',
      },
    ]
    setItemDetailData(formattedData)
  }

  const lineItemsColumn = [
    {
      sortable: false,
      title: 'Item',
    },
    {
      sortable: false,
      title: 'Quantity',
    },
  ]

  const formatLineItemData = (data: any) => {
    const formattedData = data.map(
      ({ id, variant, quantity, locale_price }) => {
        return {
          id,
          data: {
            'Item': {
              onRender: () => <VariantItem variant={variant} />,
            },
            'Quantity': { value: quantity },
            'Unit Cost': { value: formatCurrency(locale_price) },
          },
        }
      }
    )
    setLineItemDataTable(formattedData)
  }

  return (
    <div>
      <Card2Col
        loading={loading}
        header='Item Details'
        data={itemDetailData}
        customClassName='mb-4'
      />
      <h5 className='h5 mt-4 mb-3'>Origin</h5>
      <AddressCardPresenter
        loading={loading}
        address={shipmentDetails.ship_from}
        showPhoneNumber
        showEmail
      />
      <h5 className='h5 mt-4 mb-3'>Line Items</h5>
      <Table
        columns={lineItemsColumn}
        data={lineItemDataTable}
        loading={loading}
        activePage={1}
        selectable={false}
        totalRecords={shipmentDetails?.shipment_lines.length}
      />
    </div>
  )
}

export default ShipmentDetails
