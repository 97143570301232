import API, { API_URL } from 'api'

export const getDepartments = async ({ params }) => {
  return await API.get(API_URL.DEPARTMENTS(), { params })
}

export const postDepartments = async ({ body }) => {
  return await API.post(API_URL.DEPARTMENTS(), body)
}

export const getDepartmentById = async ({ departmentId }) => {
  return await API.get(API_URL.DEPARTMENT_BY_ID({ departmentId }))
}

export const patchDepartment = async ({ departmentId, body }) => {
  return await API.patch(API_URL.DEPARTMENT_BY_ID({ departmentId }), body)
}
