import API, { API_URL } from 'api'

export const getPermits = async ({ params = {} }) => {
  return await API.get(API_URL.GET_PERMITS(), { params })
}

export const getPermitDetail = async ({ id }) => {
  return await API.get(API_URL.GET_PERMIT_DETAIL({ id }))
}

export const exportPermits = async ({ params = {} }) => {
  return await API.get(API_URL.EXPORT_PERMITS(), { params })
}
