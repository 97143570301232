import { SkeletonFieldLabel as SkeletonFieldLabelUI } from '@teamfabric/copilot-ui'

type SkeletonProps = {
  variant?: 'light'
  width: string
  style?: any
}

const SkeletonFieldLabel: React.FC<SkeletonProps> = ({ ...props }) => {
  return (
    <div style={props.style}>
      <SkeletonFieldLabelUI {...props} />
    </div>
  )
}

export default SkeletonFieldLabel
