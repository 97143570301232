import { FC, useEffect, useState } from 'react'
import { DataProps } from '@teamfabric/copilot-ui'
import Table from 'atoms/table/table'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/inventorySummarySlice'
import DateAndTimeOnTwoLines from 'molecules/dateAndTimeOnTwoLines'

type ChildProps = {
  data: any
  loading: boolean
  currentPage: number
  totalRows: number
}

const InventorySummaryTable: FC<ChildProps> = (props) => {
  const [tableData, setTableData] = useState<DataProps[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    formatSetTableData({ data: props?.data })
  }, [props?.loading])

  const tableColumns = [
    {
      title: 'Supplier',
    },
    {
      title: '# Items',
      width: '15%',
    },
    {
      title: '# In Stock',
      width: '15%',
    },
    {
      title: '# Out of Stock',
      width: '15%',
    },
    {
      title: 'Last Submission',
      width: '20%',
    },
  ]

  const formatSetTableData = ({ data }) => {
    const formattedData = data.map(
      (
        {
          id,
          name,
          item_count,
          in_stock,
          out_of_stock,
          max_inventory_last_submitted_at,
        },
        key
      ) => {
        return {
          id: key,
          data: {
            'Supplier': { value: name },
            '# Items': { value: item_count },
            '# In Stock': { value: in_stock },
            '# Out of Stock': { value: out_of_stock },

            'Last Submission': {
              onRender: () => {
                return max_inventory_last_submitted_at ? (
                  <DateAndTimeOnTwoLines
                    date={max_inventory_last_submitted_at}
                  />
                ) : (
                  <span className='grey-500'>Never</span>
                )
              },
            },
          },
        }
      }
    )
    setTableData(formattedData)
  }

  const setPage = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      loading={props.loading}
      selectable={false}
      customPaginationProps={{
        handlePagination: (pageNumber: number) => {
          setPage(pageNumber)
        },
        activePageNumber: props.currentPage,
        perPage: 10,
        totalRecords: props.totalRows,
      }}
    />
  )
}

export default InventorySummaryTable
