import { FC, useEffect } from 'react'
import PageHeaderComponent from 'molecules/PageHeader'
import { GridCol, GridRow, Box } from 'atoms'
import { BREADCRUMBS } from 'lib/constants/breadcrumbs'

const OrderCreditMemosTemplate: FC = () => {
  useEffect(() => {
    window.updateBreadcrumb(BREADCRUMBS.CREDITS_LIST)
  }, [])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4 }}>
      <GridRow>
        <GridCol md={12}>
          <PageHeaderComponent
            h1Text='Credits'
            primaryButtons={[]}
            customClassName='mb-4'
          />
        </GridCol>
      </GridRow>
    </Box>
  )
}

export default OrderCreditMemosTemplate
