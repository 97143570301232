import { useEffect, useState } from 'react'
import { Box } from 'atoms'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate } from 'react-router-dom'
import { getInvitePreferences } from 'api/suppliers'
import { postRetailerPreference } from 'api/retailers'
import ConnectionLevel from '../components/onboarding/connectionLevel'
import CostDetermination from '../components/onboarding/costDetermination'
import FulfillmentSLA from '../components/onboarding/fulfillmentSLA'
import PaymentTerms from '../components/onboarding/paymentTerms'
import CatalogImportMethod from '../components/onboarding/catalogImportMethod'
import PackingSlipTemplate from '../components/onboarding/packingSlipTemplate'
import CustomPackingSlipText from '../components/onboarding/customBottomText'
import ReturnsAllowances from '../components/onboarding/returns'
import { useToast } from '@teamfabric/copilot-ui'

const DefaultOnboardingSettings: React.FC = () => {
  const navigate = useNavigate()
  const showToast = useToast()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [preferences, setPreferences] = useState([])
  const [updatedPreferences, setUpdatedPreferences] = useState([])

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.settingDetails({ page: 'Onboarding preferences' })
        ),
      0
    )

    // fetch preferences
    fetchInvitePreferences()
  }, [])

  const fetchInvitePreferences = async () => {
    const { data } = await getInvitePreferences({
      params: { grouping: 'onboarding' },
    })
    setPreferences(data.results)
    setLoading(false)
  }

  const onSave = async () => {
    setSaving(true)
    try {
      await postRetailerPreference(updatedPreferences)
      showToast({
        label: 'Preference updated!',
        id: '2',
      })
      setSaving(false)
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        id: '1',
      })
    }
  }

  const handleChange = (preference) => {
    let newPreferences = [...updatedPreferences]
    const addPreference = (pref) => {
      // Remove any existing preference of the same type
      newPreferences = newPreferences.filter(
        (existingPref) => existingPref.preference.id !== pref.preference.id
      )
      // Add the new preference
      newPreferences.push(pref)
    }
    if (Array.isArray(preference)) {
      if (preference.length > 0) {
        preference.forEach((pref) => {
          addPreference(pref)
        })
      }
    } else {
      addPreference(preference)
    }
    setUpdatedPreferences(newPreferences)
  }

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        h1Text='Default Onboarding Preferences'
        h2Text={
          <Box width='60%'>
            The settings configured under this section will be applicable by
            default for all new connections. You can always override these
            settings when inviting a new connection.
          </Box>
        }
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate('/settings'),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !updatedPreferences.length,
          },
        ]}
      />
      <ConnectionLevel
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <CostDetermination
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <FulfillmentSLA
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <PaymentTerms
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <CatalogImportMethod
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <ReturnsAllowances
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <PackingSlipTemplate
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
      <CustomPackingSlipText
        loading={loading}
        preferences={preferences}
        handleChange={(preference) => handleChange(preference)}
      />
    </Box>
  )
}

export default DefaultOnboardingSettings
