import React, { useEffect, useState } from 'react'
import { formatDate } from '../../lib/utils/date/formatDate'
import Badge from 'atoms/badge/badge'

type ChildProps = {
  line: any
  hideDate?: boolean
}

type PresenterProps = {
  label: string
  status: string
}

const Status: React.FC<ChildProps> = ({ line, hideDate }) => {
  const [presenter, setPresenter] = useState<PresenterProps>({
    label: '',
    status: '',
  })

  const setPresenterLabel = () => {
    switch (line.status) {
      case 'partial':
        setPresenter({
          label: 'In Progress',
          status: 'alert',
        })
        break
      case 'canceled':
        setPresenter({
          label: 'Canceled',
          status: 'alert',
        })
        break
      case 'closed':
        setPresenter({
          label: 'Closed',
          status: 'info',
        })
        break
      case 'backordered':
        setPresenter({
          label: 'Backordered',
          status: 'error',
        })
        break
      default:
        setPresenter({
          label: 'Open',
          status: 'success',
        })
    }
  }

  useEffect(() => {
    setPresenterLabel()
  }, [])

  return (
    <div>
      <Badge label={presenter.label} status={presenter.status} />
      {line.status === 'backordered' && line.backordered_until && !hideDate && (
        <p>{formatDate({ date: line.backordered_until })}</p>
      )}
    </div>
  )
}

export default Status
