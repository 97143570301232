import React, { useEffect, useState } from 'react'
import { Modal } from 'atoms'
import { patchConnections } from 'api/connection'
import PageHeaderWithSwitch from 'molecules/PageHeader/PageHeaderWithSwitch'
import { useToast } from '@teamfabric/copilot-ui'
import { EditableSettingsOptions } from '../constants'

type formDataTypes = {
  retailer_identifier: string
  level: string
  integration_type: string
  options: any
  status: string
}

type ChildProps = {
  onClose: () => void
  showModal: boolean
  connectionId: string
  reload: () => void
  initialValues: formDataTypes
}

const UpdateSettingsModal: React.FC<ChildProps> = ({
  initialValues,
  reload,
  ...props
}) => {
  const showToast = useToast()
  const [loading, setLoading] = useState(false)
  const [optionValues, setOptionValues] = useState([])
  const [options, setOptions] = useState([])

  const setOptionValue = (code, value) => {
    setOptionValues((prevOptionValues) =>
      prevOptionValues.map((el) => (el.code === code ? { ...el, value } : el))
    )
  }

  useEffect(() => {
    const connectionOptions = initialValues.options
    const optionsArray = Object.values(connectionOptions)
    const result = EditableSettingsOptions.map((el) => {
      if (optionsArray.some((e: { code: string }) => e.code === el.code)) {
        el.value = '1'
      }
      return el
    })
    setOptions(result)
    const optionValuesResult = result.map((el) => {
      return {
        code: el.code,
        value: el.value,
      }
    })
    setOptionValues(optionValuesResult)
  }, [initialValues])

  const UpdateConnection = async () => {
    const body = {
      connection_options: optionValues.reduce((acc, { code, value }) => {
        acc[code] = value === '1'
        return acc
      }, {}),
    }
    try {
      setLoading(true)
      await patchConnections({
        connectionId: props.connectionId,
        body: body,
      })
      showToast({
        label: `Your connection settings, has been updated successfully.`,
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      let errorMessage = error?.response?.data?.detail
      if (error.response && error.response?.data?.non_field_errors) {
        errorMessage = error.response.data.non_field_errors[0]
      }
      showToast({
        label: errorMessage
          ? `Error updating the connection: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      props.onClose()
      reload()
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        headerText='Update Connection Settings'
        onClose={() => props.onClose()}
        isVisible={props.showModal}
        footerButtons={[
          {
            onClick: () => props.onClose(),
            text: 'Close',
            variant: 'secondary',
            isDisabled: loading,
          },
          {
            onClick: () => UpdateConnection(),
            text: 'Save',
            variant: 'primary',
            isLoading: loading,
          },
        ]}
        onBackdropClick={() => props.onClose()}
      >
        {options.map((option) => (
          <PageHeaderWithSwitch
            header={option.name}
            description={option.description}
            initialValue={option.value}
            onToggle={(value) => setOptionValue(option.code, value)}
            customClassName='mb-6'
          />
        ))}
      </Modal>
    </>
  )
}

export default UpdateSettingsModal
