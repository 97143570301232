import { FILTERS } from 'molecules/filter/constants'
import { store } from 'store'

interface Filter {
  setLocalState: (state: any) => void
  initialState: any
  setFilterCount: (count: number) => void
  filterType: string
}

const setInitialState = (filterActions: Record<string, Filter>) => {
  Object.values(filterActions).forEach((filter) => {
    const { setLocalState, initialState, setFilterCount, filterType } = filter
    setLocalState(initialState)
    if (
      [FILTERS.TYPES.CHECKBOX, FILTERS.TYPES.CHECKBOX_WITH_SEARCH].includes(
        filterType
      )
    ) {
      const count = initialState?.filter((option) => option.selected).length
      setFilterCount(count)
    } else if (filterType === FILTERS.TYPES.DATE) {
      if (initialState?.dateRange?.from && initialState?.dateRange?.to) {
        setFilterCount(1)
      } else setFilterCount(0)
    } else if (filterType === FILTERS.TYPES.RADIO) {
      if (initialState) {
        setFilterCount(1)
      } else setFilterCount(0)
    }
  })
}

const resetLocalState = (filterActions: Record<string, Filter>) => {
  Object.values(filterActions).forEach((filter) => {
    const { filterType, setLocalState, setFilterCount } = filter
    if ([FILTERS.TYPES.DATE, FILTERS.TYPES.RADIO].includes(filterType)) {
      setLocalState(null)
    } else {
      setLocalState([])
    }
    setFilterCount(0)
  })
}

interface Action {
  type: string
  payload?: any
}

interface FilterAction {
  action?: (payload: any) => Action
  filterType: string
  date?: { dateRange: { from: string; to: string } }
  options?: { selected: boolean; value: string }[]
  selectedValue?: string
}

const applyFilters = (
  filterActions: Record<string, FilterAction>,
  setFilters
) => {
  for (const [key, value] of Object.entries(filterActions)) {
    const { action, filterType, date, options, selectedValue } = value
    if (filterType === FILTERS.TYPES.DATE) {
      if (date?.dateRange?.to && date?.dateRange?.from) {
        store.dispatch(action(date))
      } else {
        store.dispatch(action({ type: 'reset' }))
      }
    } else if (filterType === FILTERS.TYPES.CHECKBOX) {
      const selectedOptions = []
      options.forEach((option) => {
        if (option.selected) {
          selectedOptions.push(option.value)
        }
      })
      if (selectedOptions.length) {
        const filteredData = {
          filter: key,
          value: selectedOptions.join(','),
        }
        store.dispatch(action(options))
        store.dispatch(setFilters(filteredData))
      } else {
        store.dispatch(action({ type: 'reset' }))
        store.dispatch(setFilters({ filter: key, value: '' }))
      }
    } else if (filterType === FILTERS.TYPES.CHECKBOX_WITH_SEARCH) {
      //TODO: add logic once clarified with ui and product teams
    } else if (filterType === FILTERS.TYPES.RADIO) {
      let value = ''
      if (selectedValue === 'Yes') value = '1'
      else if (selectedValue === 'No') value = '0'
      const filteredData = {
        filter: key,
        value,
      }
      store.dispatch(setFilters(filteredData))
    }
  }
}

export { setInitialState, resetLocalState, applyFilters }
