import React, { useEffect, useState } from 'react'
import Card from 'atoms/card/card'
import { ONBOARDING_TASKS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, setNotifications } from 'store/onboardingSlice'
import InputWithSwitch from 'molecules/input/inputWithSwitch'
import {
  getPushNotification,
  patchPushNotification,
  postPushNotification,
} from 'api/pushNotification'
import CardLoading from 'molecules/loading/cardLoading'
import CardHeader from 'molecules/cards/cardHeader'
import { RootState } from 'store'
import { useToast } from '@teamfabric/copilot-ui'
import { patchAssignTask } from 'api/onboarding'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'routes/pageRoutes'

export const NOTIFICATION_TYPES = {
  CONNECTION: {
    TITLE: 'connection notifications',
    NAME: 'Connection Memo Saved',
    LABEL: 'Address to receive connections & notes alerts',
  },
  ORDER_RECIEIVED: {
    TITLE: 'order received',
    NAME: 'Order Created',
    LABEL: 'Address to receive new order alerts',
  },
  ORDER_CHANGE_REQUESTS: {
    TITLE: 'order change requests',
    NAME: 'Order Ship To Address Updated',
    LABEL: 'Address to receive ship-to-address changed request alerts',
  },
  DIGEST: {
    TITLE: 'order digest',
    NAME: 'Orders Digest',
    LABEL:
      'A daily export and email of any new or open orders. Digests are sent at 5 AM Eastern.',
  },
}
export const STATUS_TYPES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
}

type TransactionProps = {
  isNextClicked: boolean
  setIsNextClicked: (value: boolean) => void
  setIsNextButtonDisabled: (value: boolean) => void
  setClearForm: (value: boolean) => void
  clearForm: boolean
  reseting: boolean
  isSaveExitClicked: boolean
  setIsSaveExitClicked: (value: boolean) => void
}

const TransactionNotification: React.FC<TransactionProps> = ({
  isNextClicked,
  setIsNextClicked,
  setIsNextButtonDisabled,
  setClearForm,
  clearForm,
  reseting,
  isSaveExitClicked,
  setIsSaveExitClicked,
}) => {
  const dispatch = useDispatch()
  const showToast = useToast()
  const navigate = useNavigate()

  const notifications = useSelector(
    (state: RootState) => state.onboarding.notifications
  )
  const [loading, setLoading] = useState(false)
  // connection notification
  const [connectionSwitchChecked, setConnectionSwitchChecked] = useState(false)
  const [connection, setConnection] = useState('')
  const [isConnectionUpdated, setIsConnectionUpdated] = useState(false)
  const [isConnectionNew, setIsConnectionNew] = useState(true)
  const [connectionId, setConnectionId] = useState('')

  // order received
  const [orderRecievedSwitchChecked, setOrderRecievedSwitchChecked] =
    useState(false)
  const [orderRecieved, setOrderRecieved] = useState('')
  const [isOrderRecievedChanged, setIsOrderRecievedChanged] = useState(false)
  const [isOrderRecievedNew, setIsOrderRecievedNew] = useState(true)
  const [orderRecievedId, setOrderRecievedId] = useState('')

  // order change regests
  const [orderChangeRequestsSwitchCheck, setOrderChangeRequestsSwitchCheck] =
    useState(false)
  const [orderChangeRequests, setOrderChangeRequests] = useState('')
  const [isOrderChangeRequestsChanged, setIsOrderChangeRequestsChanged] =
    useState(false)
  const [isOrderChangeRequestsNew, setIsOrderChangeRequestsNew] = useState(true)
  const [orderChangeRequestsId, setOrderChangeRequestsId] = useState('')

  const loadNotifications = async () => {
    try {
      setIsNextButtonDisabled(false)
      setLoading(true)
      const {
        data: { results },
      } = await getPushNotification({})
      if (results.length > 0) {
        results.forEach(({ id, action: { name }, recipient_email, status }) => {
          if (NOTIFICATION_TYPES.CONNECTION.NAME === name) {
            setConnection(recipient_email)
            setIsConnectionNew(false)
            setConnectionSwitchChecked(status === STATUS_TYPES.ENABLED)
            setConnectionId(id)
          }
          if (NOTIFICATION_TYPES.ORDER_RECIEIVED.NAME === name) {
            setOrderRecieved(recipient_email)
            setIsOrderRecievedNew(false)
            setOrderRecievedSwitchChecked(status === STATUS_TYPES.ENABLED)
            setOrderRecievedId(id)
          }
          if (NOTIFICATION_TYPES.ORDER_CHANGE_REQUESTS.NAME === name) {
            setOrderChangeRequests(recipient_email)
            setIsOrderChangeRequestsNew(false)
            setOrderChangeRequestsSwitchCheck(status === STATUS_TYPES.ENABLED)
            setOrderChangeRequestsId(id)
          }
        })
      }
      setLoading(false)
    } catch (error) {
      showToast({
        label: "Couldn't load",
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    }
  }

  const resetForm = () => {
    setClearForm(false)
    setConnection('')
    setOrderRecieved('')
    setOrderChangeRequests('')
  }

  // First loaded
  useEffect(() => {
    ;(async () => {
      if (clearForm) {
        resetForm()
      } else {
        await loadNotifications()
      }
    })()
  }, [])

  useEffect(() => {
    if (!clearForm) {
      return
    }
    resetForm()
  }, [clearForm])

  const createPushNotification = async ({
    id,
    name,
    recipient_email,
    checked,
    isNew,
  }) => {
    const body = {
      action: {
        name,
      },
      delivery_method: 'recipient',
      recipient_email,
      status: checked ? STATUS_TYPES.ENABLED : STATUS_TYPES.DISABLED,
    }
    if (isNew) {
      await postPushNotification({ body })
    } else {
      await patchPushNotification({ id, body })
    }
  }

  const updateNotificationSlices = () => {
    dispatch(
      setNotifications({
        taskId: notifications.taskId,
        processNum: 1,
        transactionDone: true,
        digestDone: false,
        completed: false,
      })
    )
  }

  const getAllCalls = () => {
    const promises = []
    if (isConnectionUpdated) {
      promises.push(
        createPushNotification({
          id: connectionId,
          name: NOTIFICATION_TYPES.CONNECTION.NAME,
          recipient_email: connection,
          checked: connectionSwitchChecked,
          isNew: isConnectionNew,
        })
      )
    }
    if (isOrderRecievedChanged) {
      promises.push(
        createPushNotification({
          id: orderRecievedId,
          name: NOTIFICATION_TYPES.ORDER_RECIEIVED.NAME,
          recipient_email: orderRecieved,
          checked: orderRecievedSwitchChecked,
          isNew: isOrderRecievedNew,
        })
      )
    }
    if (isOrderChangeRequestsChanged) {
      promises.push(
        createPushNotification({
          id: orderChangeRequestsId,
          name: NOTIFICATION_TYPES.ORDER_CHANGE_REQUESTS.NAME,
          recipient_email: orderChangeRequests,
          checked: orderChangeRequestsSwitchCheck,
          isNew: isOrderChangeRequestsNew,
        })
      )
    }
    return promises
  }

  const saveAllNotification = async () => {
    try {
      setLoading(true)
      const promises = getAllCalls()
      promises.push(
        patchAssignTask({
          taskId: notifications.taskId,
          body: { info: { transactionDone: true } },
        })
      )
      await Promise.all(promises)
      updateNotificationSlices()
    } catch (error) {
      showToast({
        label: "Couldn't save notification",
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      setLoading(false)
    }
  }

  // onboardingProgress next button clicked
  useEffect(() => {
    ;(async () => {
      if (!isNextClicked) {
        return
      }
      setIsNextClicked(false)
      await saveAllNotification()
      dispatch(
        setCurrentStep({
          value: ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.DIGEST.TASK_NAME,
          parent: ONBOARDING_TASKS.NOTIFICATIONS.VALUE,
        })
      )
    })()
  }, [isNextClicked])

  // save and exit clicked
  useEffect(() => {
    ;(async () => {
      if (!isSaveExitClicked) {
        return
      }
      setIsSaveExitClicked(false)
      await saveAllNotification()
      navigate(PATHS.Onboarding)
    })()
  }, [isSaveExitClicked])

  return (
    <>
      {(loading || reseting) && (
        <CardLoading
          label={ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.NAME}
          num={3}
        />
      )}
      {!loading && !reseting && (
        <Card
          open={true}
          showCollapse={false}
          showDivider={true}
          headerContent={
            <CardHeader
              h1Text={ONBOARDING_TASKS.NOTIFICATIONS.SUB_TASKS.TRANSACTION.NAME}
              h2Text='Subscribe to email notifications to receive real-time alerts
              related to transaction events.'
            />
          }
          bodyContent={
            <>
              <div className='pb-5'>
                <h2 className='kicker'>
                  {NOTIFICATION_TYPES.CONNECTION.TITLE}
                </h2>
                <InputWithSwitch
                  switchProps={{
                    label: '',
                    checked: connectionSwitchChecked,
                    value: '',
                    name: '',
                    onChange: () => {
                      setIsConnectionUpdated(true)
                      setConnectionSwitchChecked(!connectionSwitchChecked)
                    },
                  }}
                  inputProps={{
                    label: NOTIFICATION_TYPES.CONNECTION.LABEL,
                    required: false,
                    inputProps: {
                      value: connection,
                      placeholder: 'Email address or distribution list',
                      onChange: ({ target: { value } }) => {
                        setIsConnectionUpdated(true)
                        setConnectionSwitchChecked(value !== '')
                        setConnection(value)
                      },
                    },
                    width: '80%',
                    maskOptions: {
                      alias: 'email',
                    },
                  }}
                />
              </div>
              <div className='border-line'></div>
              <div className='pb-5'>
                <h2 className='kicker'>
                  {NOTIFICATION_TYPES.ORDER_RECIEIVED.TITLE}
                </h2>
                <InputWithSwitch
                  switchProps={{
                    label: '',
                    checked: orderRecievedSwitchChecked,
                    value: '',
                    name: '',
                    onChange: () => {
                      setIsOrderRecievedChanged(true)
                      setOrderRecievedSwitchChecked(!orderRecievedSwitchChecked)
                    },
                  }}
                  inputProps={{
                    label: NOTIFICATION_TYPES.ORDER_RECIEIVED.LABEL,
                    required: false,
                    inputProps: {
                      value: orderRecieved,
                      placeholder: 'email@business.com',
                      onChange: ({ target: { value } }) => {
                        setIsOrderRecievedChanged(true)
                        setOrderRecievedSwitchChecked(value !== '')
                        setOrderRecieved(value)
                      },
                    },
                    width: '80%',
                    maskOptions: {
                      alias: 'email',
                    },
                  }}
                />
              </div>
              <div className='border-line'></div>
              <div className='pb-5'>
                <h2 className='kicker'>
                  {NOTIFICATION_TYPES.ORDER_CHANGE_REQUESTS.TITLE}
                </h2>
                <InputWithSwitch
                  switchProps={{
                    label: '',
                    checked: orderChangeRequestsSwitchCheck,
                    value: '',
                    name: '',
                    onChange: () => {
                      setIsOrderChangeRequestsChanged(true)
                      setOrderChangeRequestsSwitchCheck(
                        !orderChangeRequestsSwitchCheck
                      )
                    },
                  }}
                  inputProps={{
                    label: NOTIFICATION_TYPES.ORDER_CHANGE_REQUESTS.LABEL,
                    required: false,
                    inputProps: {
                      value: orderChangeRequests,
                      placeholder: 'email@business.com',
                      onChange: ({ target: { value } }) => {
                        setIsOrderChangeRequestsChanged(true)
                        setOrderChangeRequestsSwitchCheck(value !== '')
                        setOrderChangeRequests(value)
                      },
                    },
                    width: '80%',
                    maskOptions: {
                      alias: 'email',
                    },
                  }}
                />
              </div>
            </>
          }
        />
      )}
    </>
  )
}

export default TransactionNotification
