import React from 'react'
import { Textarea as TextareaUI } from '@teamfabric/copilot-ui'

export type InputProps = {
  label: string
  inputProps: any
  width?: string
  message?: string
  limit?: number
  showCounter?: boolean
  messageType?: 'success' | 'error' | 'normal'
  tooltipInfo?: any
  rows?: number
  required?: boolean
}

const MultiLineTextArea: React.FC<InputProps> = ({ ...props }) => {
  return <TextareaUI {...props} />
}

export default MultiLineTextArea
