import { createSlice } from '@reduxjs/toolkit'
import { getUnixTimestamp } from 'lib/utils/date/formatDate'
import { ORDERS } from 'modules/orders/constants'
import { MODALS } from 'molecules/modals/constants'

const initialState = {
  params: {
    order_by: '-id',
    limit: 10,
    page: 1,
    skip_attributes: 1,
  },
  connectionOptions: [],
  createdOn: null,
  initialCreatedOnValueSet: false,
  dueOn: null,
  acknowledgedOn: null,
  paidOn: null,
  selected: [],
  export_type: MODALS.EXPORT.TYPES.INVOICES.RADIO_GROUP_OPTIONS[0].value,
}
const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setSorting(state, action) {
      state.params.order_by = action.payload
    },
    setParams(state, action) {
      state.params = action.payload
    },
    setSearch(state, action) {
      if (action?.payload) {
        state.params['search'] = action.payload
      } else delete state.params['search']
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
    },
    setFilters(state, action) {
      state.params.page = 1
      if (!action.payload.value) {
        delete state.params[action.payload.filter]
      } else {
        state.params[action.payload?.filter] = action.payload?.value
      }
    },
    setConnectionOptions(state, action) {
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      } else {
        state.connectionOptions = action.payload
      }
    },
    setCreatedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.START]
        delete state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.END]
        delete state.createdOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.START] = start
        state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.END] = end
        state.createdOn = action.payload
      }
    },
    setInitialCreatedOn(state) {
      state['initialCreatedOnValueSet'] = true
    },
    setDueOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.INVOICE_DUE.PARAMS.START]
        delete state.params[ORDERS.INVOICE_DUE.PARAMS.END]
        delete state.dueOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.INVOICE_DUE.PARAMS.START] = start
        state.params[ORDERS.INVOICE_DUE.PARAMS.END] = end
        state.dueOn = action.payload
      }
    },
    setAcknowledgedOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START]
        delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END]
        delete state.acknowledgedOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START] = start
        state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END] = end
        state.acknowledgedOn = action.payload
      }
    },
    setPaidOn(state, action) {
      state.params.page = 1
      if (action.payload.type === 'reset') {
        delete state.params[ORDERS.INVOICE_PAID_ON.PARAMS.START]
        delete state.params[ORDERS.INVOICE_PAID_ON.PARAMS.END]
        delete state.paidOn
      } else {
        const start = getUnixTimestamp(action.payload?.dateRange?.from)
        const end = getUnixTimestamp(action.payload?.dateRange?.to)

        state.params[ORDERS.INVOICE_PAID_ON.PARAMS.START] = start
        state.params[ORDERS.INVOICE_PAID_ON.PARAMS.END] = end
        state.paidOn = action.payload
      }
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    resetAllFilters(state) {
      state.params.page = 1
      state.connectionOptions = []

      delete state.params[ORDERS.CONNECTION.PARAM]
      delete state.params[ORDERS.ACKNOWLEDGED.PARAM]
      delete state.params[ORDERS.INVOICE_PAID.PARAM]
      delete state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.START]
      delete state.params[ORDERS.INVOICE_SUBMITTED.PARAMS.END]
      delete state.params[ORDERS.INVOICE_DUE.PARAMS.START]
      delete state.params[ORDERS.INVOICE_DUE.PARAMS.END]
      delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.START]
      delete state.params[ORDERS.ACKNOWLEDGED_ON.PARAMS.END]
      delete state.params[ORDERS.INVOICE_PAID_ON.PARAMS.START]
      delete state.params[ORDERS.INVOICE_PAID_ON.PARAMS.END]
      delete state.params[ORDERS.SEARCH.PARAM]
      delete state.createdOn
      delete state.dueOn
      delete state.acknowledgedOn
      delete state.paidOn
    },
    setExportType: (state, action) => {
      state.export_type = action.payload
    },
    setAdvancedOptions: (state, action) => {
      state.params.page = 1
      state.params.order_by = initialState.params.order_by
      // set invoice number
      if (action.payload?.invoice_number) {
        state.params['search'] = action.payload.invoice_number
      } else delete state.params['search']
      // set connection id
      if (action.payload?.connection_id) {
        state.params = {
          ...(state.params as any),
          connection_id: action.payload.connection_id,
        }
        const selectedConnection = [
          {
            id: action.payload.connection_id,
            label: action.payload.connection_label,
            selected: true,
          },
        ]
        state.connectionOptions = selectedConnection
      } else {
        delete state.params[ORDERS.CONNECTION.PARAM]
        state.connectionOptions = []
      }
    },
  },
})

export const {
  setSorting,
  setParams,
  setSearch,
  setFilters,
  setConnectionOptions,
  setCreatedOn,
  setInitialCreatedOn,
  setDueOn,
  setAcknowledgedOn,
  setPaidOn,
  setCurrentPage,
  setSelected,
  resetAllFilters,
  setExportType,
  setAdvancedOptions,
} = invoicesSlice.actions

export default invoicesSlice.reducer
