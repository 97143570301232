import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, PageHeader } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const PaymentTerms: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [preference, setPreference] = useState(null)

  const defaultPaymentTerms = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_payment_terms'])
    }
    return null
  }, [preferences, loading])

  const preferenceOptions = useMemo(() => {
    return (
      defaultPaymentTerms?.options?.map((option) => ({
        id: option.id,
        label: option.name,
        value: option.value,
      })) || []
    )
  }, [defaultPaymentTerms])

  useEffect(() => {
    if (defaultPaymentTerms) {
      setPreference(defaultPaymentTerms)
      setOptions(preferenceOptions)
      if (defaultPaymentTerms.retailer_preference) {
        const selectedPreferenceOption = preferenceOptions.find(
          (el) => el.value === defaultPaymentTerms.retailer_preference.value
        )
        setSelectedOption(selectedPreferenceOption)
      }
    }
  }, [defaultPaymentTerms, preferenceOptions])

  const handleUpdate = (option) => {
    const json = {
      preference: { id: preference.id },
      option: { id: option.id },
    }
    handleChange(json)
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Default Payment Terms</span>}
              h2Text={
                <span className='body2-regular'>
                  The Payment Terms reflect how long you have in credit days
                  before the amount is due to your supplier. For example, if set
                  to Net 15, you will need to remit the supplier within 15 days
                  of invoice receipt.
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <Dropdown
            required
            label='Duration'
            width='50%'
            value={selectedOption}
            onChange={(option: any) => {
              setSelectedOption(option)
              handleUpdate(option)
            }}
            options={options}
            placeholder='Select a payment term'
            disabled={loading}
          />
        }
      ></Card>
    </Box>
  )
}

PaymentTerms.defaultProps = defaultProps
export default PaymentTerms
