import React from 'react'
import { GridRow, GridCol } from 'atoms'

type ChildProps = {
  data: any[]
  defaultColSize?: number
  customClassName?: string
  col1Size?: number
  col2Size?: number
}

const GridLabelValuePair: React.FC<ChildProps> = ({
  data,
  defaultColSize,
  col1Size,
  col2Size,
  customClassName,
}) => {
  return (
    <div className={customClassName}>
      <GridRow padding={false}>
        {data?.map(({ label, value }, key) => (
          <GridCol
            sm={
              key === 0
                ? col1Size || defaultColSize
                : col2Size || defaultColSize
            }
            key={key}
          >
            <p className='grey-700'>{label}</p>
            <p className={value === 'Not Specified' ? 'grey-700' : 'grey-1000'}>
              {value}
            </p>
          </GridCol>
        ))}
      </GridRow>
    </div>
  )
}

export default GridLabelValuePair
