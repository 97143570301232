export const DEPARTMENT = {
  STATUS: {
    NAME: 'Status',
    PARAM: 'is_active',
  },
  UPDATED_AT: {
    NAME: 'Updated At',
    PARAM_START: 'updated_at_gte',
    PARAM_END: 'updated_at_lte',
  },
}

export const DEPARTMENT_FILTER_OPTIONS = {
  STATUS_OPTIONS: [
    {
      id: '0',
      key: 'is_active',
      label: 'Active',
      value: '1',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'is_active',
      label: 'Inactive',
      value: '0',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
}

export const SORTING_MAPS = {
  DEPARTMENT: {
    ID: 'id',
  },
}

export const TEMPLATE_FILTER_OPTIONS = {
  DATATYPE_OPTIONS: [
    {
      id: '0',
      key: 'data_type',
      label: 'Products',
      value: 'products',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '',
      key: 'data_type',
      label: 'Inventory',
      value: 'inventory',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
}

export const TEMPLATE = {
  DATATYPE: {
    NAME: 'Data Type',
    PARAM: 'data_type',
  },
}

export const PRODUCT_RULESETS = {
  CONTENT_TYPE: {
    NAME: 'Content Type',
    PARAM: 'content_type',
  },
  UPDATED_AT: {
    NAME: 'Updated At',
    PARAM_START: 'updated_at_gte',
    PARAM_END: 'updated_at_lte',
  },
}

export const PRODUCT_RULESET_FILTER_OPTIONS = {
  CONTENT_TYPE_OPTIONS: [
    {
      id: '0',
      key: 'content_type',
      label: 'Suppliers',
      value: 'brand',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '',
      key: 'content_type',
      label: 'Retailers',
      value: 'retailers',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
}

export const SHOPIFY_WEBHOOK_HISTORY = {
  STATUS: {
    NAME: 'Status',
    PARAM: 'status',
  },
  CREATED_AT: {
    NAME: 'Created At',
    PARAM_START: 'created_at_gte',
    PARAM_END: 'created_at_lte',
  },
  TOPIC: {
    NAME: 'Topic',
    PARAM: 'topic',
  },
}

export const SHOPIFY_WEBHOOK_HISTORY_FILTER_OPTIONS = {
  STATUS_OPTIONS: [
    {
      id: '0',
      key: 'status',
      label: 'Successful',
      value: 'success',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'status',
      label: 'Failed',
      value: 'fail',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '2',
      key: 'status',
      label: 'Skipped',
      value: 'skipped',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
  TOPIC_OPTIONS: [
    {
      id: '0',
      key: 'topic',
      label: 'Orders/Create',
      value: 'orders/create',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '1',
      key: 'topic',
      label: 'Products/Update',
      value: 'products/update',
      selected: false,
      expanded: false,
      children: [],
    },
    {
      id: '2',
      key: 'topic',
      label: 'Fulfillments/Create',
      value: 'fulfillments/create',
      selected: false,
      expanded: false,
      children: [],
    },
  ],
}
