import React, { useMemo } from 'react'
import PageHeaderComponent from 'molecules/PageHeader'
import { getBadgeStatusByOrderStatus } from 'modules/orders/utils/orders'
import { getTenantType } from 'api/helper'

type OrderDetailsHeaderProps = {
  details: any
  loading: boolean
  setShowCancelItemsModal?: (arg: boolean) => void
  setShowChangeShipToModal?: (arg: boolean) => void
}

const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({
  details,
  loading,
  setShowCancelItemsModal,
  setShowChangeShipToModal,
}) => {
  const { isRetailer } = getTenantType()

  const orderHasUnshippedItems = useMemo(() => {
    let outstanding = 0
    if (!loading && details) {
      details?.order_lines.forEach((line) => {
        outstanding += line.quantity_open
      })
    }
    return outstanding > 0
  }, [loading, details.order_lines])

  const packingSlipLink = useMemo(() => {
    if (!loading && details) {
      const fileObject = details?.attachments.find(
        ({ filename }) => filename === 'packing_slip.pdf'
      )
      return fileObject?.file || null
    }
    return null
  }, [loading, details.attachments])

  const headerButtons = useMemo(() => {
    let buttons = []
    if (orderHasUnshippedItems && isRetailer) {
      buttons.push(
        {
          onClick: () => setShowCancelItemsModal(true),
          text: 'Cancel Items',
          variant: 'secondary',
        },
        {
          onClick: () => setShowChangeShipToModal(true),
          text: 'Change Ship To',
          variant: 'primary',
        }
      )
    }
    if (!packingSlipLink) {
      buttons.push({
        onClick: () => console.log('==To Do=='),
        text: 'Add Packing Slip',
        variant: 'primary',
      })
    } else {
      buttons.push({
        onClick: () => window.open(packingSlipLink, '_blank'),
        text: 'Print Packing Slip',
        variant: 'primary',
      })
    }
    return buttons
  }, [orderHasUnshippedItems, packingSlipLink])

  return (
    <PageHeaderComponent
      loading={loading}
      badgeProps={{
        label: details.status,
        status: getBadgeStatusByOrderStatus(details.status),
      }}
      h1Text={`PO #${details?.purchase_order_number}`}
      h2Text={details?.retailer?.name}
      primaryButtons={headerButtons}
      customClassName='mb-4'
    />
  )
}

export default OrderDetailsHeader
