import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, Input, PageHeader } from 'atoms'
import Dropdown from 'atoms/dropdown/dropdown'
import { dropDownInitialObj } from 'lib/utils/initialValue'
import _ from 'lodash'

type ChildProps = {
  preferences: any[]
  loading: boolean
  handleChange: (preference) => void
}

const defaultProps: ChildProps = {
  preferences: [],
  loading: true,
  handleChange: () => {},
}

const CatalogImportMethod: React.FC<ChildProps> = ({
  preferences,
  loading,
  handleChange,
}) => {
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(dropDownInitialObj)
  const [preference, setPreference] = useState(null)

  const defaultCatalogImportMethod = useMemo(() => {
    if (!loading && preferences.length > 0) {
      return _.find(preferences, ['code', 'default_catalog_import_method'])
    }
    return null
  }, [preferences, loading])

  const preferenceOptions = useMemo(() => {
    return (
      defaultCatalogImportMethod?.options?.map((option) => ({
        id: option.id,
        label: option.name,
        value: option.value,
      })) || []
    )
  }, [defaultCatalogImportMethod])

  useEffect(() => {
    if (defaultCatalogImportMethod) {
      setPreference(defaultCatalogImportMethod)
      setOptions(preferenceOptions)

      let selectedPreferenceOption = preferenceOptions.find(
        (el) => el.value === 'transactions_and_products'
      )
      if (defaultCatalogImportMethod.retailer_preference) {
        selectedPreferenceOption = preferenceOptions.find(
          (el) =>
            el.value === defaultCatalogImportMethod.retailer_preference.value
        )
      }
      setSelectedOption(selectedPreferenceOption)
    }
  }, [defaultCatalogImportMethod, preferenceOptions])

  const handleUpdate = (option) => {
    const json = {
      preference: { id: preference.id },
      option: { id: option.id },
    }
    handleChange(json)
  }

  return (
    <Box width='100%' margin={{ bottom: 4 }}>
      <Card
        showCollapse={false}
        headerContent={
          <Box width='50%'>
            <PageHeader
              h1Text={<span className='h5'>Default Catalog Import Method</span>}
              h2Text={
                <span className='body2-regular'>
                  The catalog import method defines how suppliers will share
                  product data with you. Selecting 'Import Requests' will
                  require you to upload a spreadsheet on behalf of your
                  suppliers and subsequently seek the suppliers's approval to
                  publish the products. Selecting 'Proposals' will require
                  suppliers to submit products to you for approval.
                </span>
              }
            />
          </Box>
        }
        bodyContent={
          <Dropdown
            required
            label='Import Method'
            width='50%'
            value={selectedOption}
            onChange={(option: any) => {
              setSelectedOption(option)
              handleUpdate(option)
            }}
            options={options}
            placeholder='Select an import method'
            disabled={loading}
          />
        }
      ></Card>
    </Box>
  )
}

CatalogImportMethod.defaultProps = defaultProps
export default CatalogImportMethod
