import React from 'react'
import CardLoading from 'molecules/loading/cardLoading'
import { Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { theme } from '@teamfabric/copilot-ui'
import { StyledActionButton, StyledActionButtonWrapper } from 'styles/common'

type ChildProps = {
  loading?: boolean
  header?: any
  showDivider?: boolean
  showCollapse?: boolean
  open?: boolean
  skeletonRowsNumber?: number
  customClassName?: string
  description?: string
  logo_url?: string
  moreActions?: any[]
}

const defaultProps: ChildProps = {
  open: true,
  skeletonRowsNumber: 1,
  showDivider: false,
  showCollapse: false,
  moreActions: []
}

const children = (actions) => {
  return (
    <StyledActionButtonWrapper>
      {actions.map((action) => (
        <StyledActionButton
          onClick={() => action.handleClick(true)}
          data-testid={`${action.name}-dropdown-item`}
          key={action.name}
        >
          {action.name}
        </StyledActionButton>
      ))}
    </StyledActionButtonWrapper>
  )
}

const ImageCardWithActions: React.FC<ChildProps> = (props) => {
  return (
    <div className={props.customClassName}>
      {props.loading ? (
        <CardLoading
          label={props.header}
          showDivider={props.showDivider}
          open={props.open}
          num={props.skeletonRowsNumber}
        />
      ) : (
        <Box width='100%' padding={{ top: 4, bottom: 4, left: 4, right: 4 }} border={{ radius: 1, width: '1px', color: theme.color.grey[200]}}>
          <PageHeaderComponent
            h1Text={''}
            imageProps={{
              src: props.logo_url,
              alt: 'image',
              size: 'medium',
              aspectRatio: '16:9',
              objectFit: 'contain'
            }}
            primaryButtons={props.moreActions.length > 0 && [
              {
                onClick: () => {},
                text: 'More actions',
                icon: 'ArrowDown',
                iconPosition: 'right',
                variant: 'secondary',
                popoverProps: {
                  children: children(props.moreActions),
                  placement: 'bottom-start',
                },
              },
            ]}
          />
        </Box>
      )}
    </div>
  )
}

ImageCardWithActions.defaultProps = defaultProps

export default ImageCardWithActions
