import { FC, useEffect, useState } from 'react'
import { breadcrumbs } from 'lib/constants/breadcrumbsHandler'
import { Box } from 'atoms'
import PageHeaderComponent from 'molecules/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@teamfabric/copilot-ui'
import {
  getPlatformTemplateById,
  updatePlatformTemplateById,
} from 'api/templates'
import { capitalizeFirstLetter } from 'lib/utils'
import CardLoading from 'molecules/loading/cardLoading'
import BasicSettings from 'modules/settings/components/templates/basicDetails'
import MappingsTableCard from 'modules/settings/components/integrations/externalTemplates/mappingsTableCard'

const ExternalPlatformTemplateDetails: FC = () => {
  const { id, platform, templateId } = useParams()
  const navigate = useNavigate()
  const showToast = useToast()
  const [loading, setLoading] = useState(true)

  const [name, setName] = useState('')
  const [saving, setSaving] = useState(false)
  const [templateDetails, setTemplateDetails] = useState(null)

  useEffect(() => {
    // set breadcrumbs
    setTimeout(
      () =>
        window.updateBreadcrumb(
          breadcrumbs.shopifyTemplates({ page: `#${templateId}`, id: id })
        ),
      0
    )
  }, [])

  const onSave = async () => {
    const body = {
      name: name,
      direction: templateDetails.direction,
      data_type: templateDetails.data_type,
    }
    try {
      setSaving(true)
      await updatePlatformTemplateById({
        platformCode: platform,
        platformId: id,
        templateId: templateId,
        body,
      })
      showToast({
        label: 'Template updated!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      showToast({
        label: 'Please review your input and try again.',
        variant: 'error',
        isDismissable: true,
        id: '1',
      })
    } finally {
      setSaving(false)
    }
  }

  const fetchTemplate = async () => {
    const { data } = await getPlatformTemplateById({
      platformCode: platform,
      platformId: id,
      templateId: templateId,
    })
    setTemplateDetails(data)
    setName(data.name)
    setLoading(false)
  }

  useEffect(() => {
    if (templateId) {
      ;(async () => {
        await fetchTemplate()
      })()
    }
  }, [templateId])

  return (
    <Box width='100%' padding={{ top: 6, bottom: 4, left: 6, right: 6 }}>
      <PageHeaderComponent
        loading={loading}
        dataTestid={`${platform}-template-details-header`}
        h1Text={`${capitalizeFirstLetter(templateDetails?.name || '')} ${id}`}
        customClassName='mb-4'
        primaryButtons={[
          {
            onClick: () => navigate(-1),
            text: 'Back',
            variant: 'secondary',
            isDisabled: saving,
            dataTestid: 'back-button',
          },
          {
            onClick: () => onSave(),
            text: 'Save',
            variant: 'primary',
            isDisabled: saving || !name,
            dataTestid: 'save-button',
          },
        ]}
      />
      {loading ? (
        <CardLoading />
      ) : (
        <BasicSettings
          name={name}
          setName={setName}
          templateDetails={templateDetails}
          showCloneTemplate={false}
          showDownloadSample={false}
        />
      )}
      <MappingsTableCard
        templateDetails={templateDetails}
        fieldType='mappings'
      />
      {platform === 'shopify' && (
        <>
          <MappingsTableCard
            templateDetails={templateDetails}
            fieldType='tag-mappings'
          />
          <MappingsTableCard
            templateDetails={templateDetails}
            fieldType='metafield-mappings'
          />
        </>
      )}
    </Box>
  )
}

export default ExternalPlatformTemplateDetails
