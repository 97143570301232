import React from 'react'
import { Switch as SwitchUI } from '@teamfabric/copilot-ui'

export type SwitchProps = {
  label: string
  checked?: boolean
  value?: string
  name?: string
  onChange: () => void
}

const Switch: React.FC<SwitchProps> = ({ ...props }) => {
  return <SwitchUI {...props} />
}

export default Switch
