import { useToast } from '@teamfabric/copilot-ui'
import { createProposalIssue } from 'api/proposals'
import { Input, Modal, MultiLineTextArea } from 'atoms'
import { ButtonVariants } from 'atoms/button/button'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type CreateIssueModalProps = {
  count: number
  isProduct?: boolean
  showModal: any
  setShowModal: Dispatch<SetStateAction<boolean>>
  itemIdArray?: any
  loadProposal: (quiet?: boolean) => void | undefined
}

const CreateIssueModal: React.FC<CreateIssueModalProps> = ({
  count,
  showModal,
  setShowModal,
  itemIdArray,
  ...props
}) => {
  const [saving, setSaving] = useState(false)
  const [issueTitle, setIssueTitle] = useState(undefined)
  const [issueSummary, setIssueSummary] = useState(undefined)

  const showToast = useToast()

  const variantsSelected = useSelector(
    (state: RootState) => state.proposalItems.selectedProposalVariantIDs
  )

  const productsSelected = useSelector(
    (state: RootState) => state.proposalProducts.selectedProposalProductIds
  )

  const selectedArray = props?.isProduct ? productsSelected : variantsSelected
  // need to read proposal_variant.id
  const selected = selectedArray?.length ? selectedArray : itemIdArray

  const proposalDetails = useSelector(
    (state: RootState) => state.proposals.proposalDetails
  )

  const resetFields = () => {
    setIssueTitle(undefined)
    setIssueSummary(undefined)
  }

  const onCloseModal = () => {
    resetFields()
    setShowModal(false)
  }

  const createIssue = async () => {
    const body = {
      message: issueSummary,
      title: issueTitle,
      [props?.isProduct ? 'proposal_products' : 'proposal_variants']: [],
    }
    selected.forEach((itemId) => {
      const item = { id: itemId }
      if (props?.isProduct) {
        body.proposal_products.push(item)
      } else {
        body.proposal_variants.push(item)
      }
    })

    try {
      setSaving(true)
      await createProposalIssue({ proposalId: proposalDetails?.id, body: body })
      showToast({
        label: 'Issue created successfully!',
        isDismissable: true,
        id: '1',
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.detail
      showToast({
        label: errorMessage
          ? `Error creating the issue: ${errorMessage}`
          : 'Error occurred, please try again.',
        variant: 'error',
        isDismissable: true,
        id: '2',
      })
    } finally {
      onCloseModal()
      setSaving(false)
      props.loadProposal?.(true)
    }
  }

  return (
    <>
      <Modal
        headerText='Add issue'
        description=''
        onClose={() => onCloseModal()}
        isVisible={showModal}
        footerButtons={[
          {
            dataTestid: '',
            onClick: () => onCloseModal(),
            text: 'Close',
            variant: ButtonVariants.SECONDARY,
            isDisabled: saving,
          },
          {
            dataTestid: '',
            onClick: () => createIssue(),
            text: 'Add Issue',
            variant: ButtonVariants.PRIMARY,
            isDisabled: !issueTitle?.length || !issueSummary?.length || saving,
          },
        ]}
        dataTestid={'add-issue-modal'}
        onBackdropClick={() => onCloseModal()}
      >
        <p className='body1_regular grey-600 mb-4'>
          {count} item will be added when this issue is created.
        </p>
        <Input
          label='Issue Title'
          width='100%'
          required={true}
          inputProps={{
            placeholder: 'Issue Title',
            onChange: ({ target: { value } }) => {
              setIssueTitle(value)
            },
          }}
        />
        <div className='divider-4' />
        <MultiLineTextArea
          label='Issue Summary'
          width='100%'
          limit={2048}
          showCounter
          rows={5}
          inputProps={{
            required: true,
            value: issueSummary,
            onChange: (e) => {
              setIssueSummary(e.target.value)
            },
            placeholder: 'Add a note',
          }}
        />
      </Modal>
    </>
  )
}

export default CreateIssueModal
