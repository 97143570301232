export const PRODUCTS = {
  CONNECTIONS: {
    CODE: 'CONNECTIONS',
    NAME: 'Connection',
    PARAM: 'connection_id',
  },
  CREATED_AT: {
    NAME: 'Created At',
  },
  UPDATED_AT: {
    NAME: 'Updated At',
  },
  UPDATED_ON: {
    NAME: 'Updated On',
    PARAM_START: 'updated_at_gte',
    PARAM_END: 'updated_at_lte',
  },
  RETAILER_SKU_ASSIGNED: {
    NAME: 'Retailer SKU Assigned',
    PARAM_START: 'retailer_identifier_created_at_gte',
    PARAM_END: 'retailer_identifier_created_at_lte',
  },
  IN_STOCK: {
    NAME: 'In Stock',
    PARAM: 'in_stock',
  },
  IS_DISCONTINUED: {
    NAME: 'Is Discontinued',
    PARAM: 'is_discontinued',
  },
  SUPPLIER_LAST_UPDATE: {
    NAME: 'Supplier Last Update',
    PARAM_START: 'brand_inventory_updated_at_gte',
    PARAM_END: 'brand_inventory_updated_at_lte',
  },
  IS_SYNCED_TO_SHOPIFY: {
    NAME: 'Is Synced to Shopify',
    PARAM: 'is_on_shopify',
  },
  IS_SYNCED_TO_BIG_COMMERCE: {
    NAME: 'Is Synced to BigCommerce',
    PARAM: 'is_on_bigcommerce',
  },
  PROPOSED_ON: {
    NAME: 'Proposed On',
    PARAM_START: 'proposed_at_gte',
    PARAM_END: 'proposed_at_lte',
  },
  CREATED_ON: {
    NAME: 'Created On',
    PARAM_START: 'created_at_gte',
    PARAM_END: 'created_at_lte',
  },
  ACKNOWLEDGED: {
    NAME: 'Acknowledged',
    PARAM: 'is_acknowledged',
  },
  PROPOSAL_ITEM_STATUS: {
    NAME_PRODUCT: 'Product Status',
    RADIO_OPTIONS: [
      {
        'id': '1',
        'label': 'Included',
        'value': '0',
        'data-testid': 'label-included',
        'disabled': false,
      },
      {
        'id': '2',
        'label': 'Removed',
        'value': '1',
        'data-testid': 'label-removed',
        'disabled': false,
      },
    ],
  },
  PROPOSAL_TAB_PRESETS: {
    ALL: 'All',
    DRAFT: 'Draft',
    PROPOSED: 'Proposed',
    RETURNED: 'Returned',
    PUBLISHING: 'Publishing',
    COMPLETED: 'Completed',
  },
  SORTING_MAPS: {
    PROPOSALS: {
      Created: 'id',
    },
  },
  FEED_REQUESTS: {
    STATUS: {
      APPROVED: {
        VALUE: 'approved',
        LABEL: 'Approved',
      },
      REJECTED: {
        VALUE: 'rejected',
        LABEL: 'Declined',
      },
      PENDING: {
        VALUE: 'pending',
        LABEL: 'Pending',
      },
    },
    ORDER_BY: {
      ID: 'id',
      SUPPLIER: 'brand_name',
      TEMPLATE: 'template__template_name',
      STATUS: 'status',
    },
  },
}
