import React from 'react'
import Card from 'atoms/card/card'
import GridCol from 'atoms/grid/gridCol'
import GridRow from 'atoms/grid/gridRow'
import CardLoading from 'molecules/loading/cardLoading'
type ChildProps = {
  loading?: boolean
  header?: any
  data: any[]
  showDivider?: boolean
  open?: boolean
  showCollapse?: boolean
  addGap?: boolean
  customClassName?: string
  dataTestid?: string
}
const ModalCard2Col: React.FC<ChildProps> = ({
  loading,
  header,
  data,
  showDivider = true,
  open = true,
  showCollapse = false,
  addGap = false,
  customClassName,
  dataTestid
}) => {
  return (
    <div className={customClassName}>
      {loading ? (
        <CardLoading label={header} open={open} />
      ) : (
        <Card
          showCollapse={showCollapse}
          open={open}
          showDivider={showDivider}
          headerContent={header}
          dataTestid={dataTestid}
          bodyContent={data?.map(({ label, value }, index) => (
            <div key={label} className={addGap && index > 0 && 'mt-4'}>
              <GridRow padding={false}>
                <GridCol lg={7} md={7} sm={7}>
                  <p className='grey-700'>{label}</p>
                </GridCol>
                <GridCol
                  lg={5}
                  md={5}
                  sm={5}
                  justifySelf='end'
                  alignSelf='center'
                >
                  {value?.onRender ? (
                    value.onRender()
                  ) : (
                    <p className='grey-700'>{value}</p>
                  )}
                </GridCol>
              </GridRow>
            </div>
          ))}
        />
      )}
    </div>
  )
}

export default ModalCard2Col
