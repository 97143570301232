import React, { useEffect, useMemo, useState } from 'react'
import { Box, Link, ProgressBar } from 'atoms'
import ModalCard2Col from 'molecules/modals/cards/modalCard2Col'
import { capitalize, truncate } from 'lodash'
import UpdateCostsStatusPresenter from './updateCostsStatusPresenter'
import { calculateDuration, formatDate } from 'lib/utils/date/formatDate'

type UpdateFinishedProps = {
  feed: any
}

const UpdateFinished: React.FC<UpdateFinishedProps> = ({ feed }) => {
  const [importResults, setImportResults] = useState<any[]>([])
  const [importProcessDetails, setImportProcessDetails] = useState<any[]>([])

  const handleDownload = (url, filename) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const formatImportResultsData = (feed) => {
    let formattedData = [
      {
        label: '# of Items Updated',
        value: feed?.num_updated,
      },
      {
        label: '# of Rows',
        value: feed?.num_items,
      },
      {
        label: '# of Items Created',
        value: feed?.num_created,
      },
      {
        label: '# of Warnings',
        value: feed?.num_warnings,
      },
    ]
    setImportResults(formattedData)
  }

  const formatImportProcessDetails = (feed) => {
    let formattedData = [
      {
        label: 'ID',
        value: feed?.id,
      },
      {
        label: 'Feed type',
        value: `${capitalize(feed?.direction)} ${capitalize(feed?.data_type)}`,
      },
      {
        label: 'File name',
        value: (
          <Link
            label={`${truncate(feed?.original_filename, { length: 24 })}`}
            onClick={() =>
              handleDownload(feed?.cloud_filename, feed?.original_filename)
            }
          />
        ),
      },
      {
        label: 'Template',
        value: feed?.template?.name,
      },
      {
        label: 'Status',
        value: <UpdateCostsStatusPresenter updateStatus={feed?.status} />,
      },
      {
        label: 'Started at',
        value: feed?.processed_at
          ? formatDate({ date: feed?.processed_at })
          : 'Not started',
      },
      {
        label: 'Completed at',
        value: feed?.completed_at
          ? formatDate({ date: feed?.completed_at })
          : 'Not completed',
      },
      {
        label: 'Wait time',
        value: calculateDuration(feed?.created_at, feed?.processed_at),
      },
      {
        label: 'Processing time',
        value: calculateDuration(feed?.processed_at, feed?.completed_at),
      },
    ]
    setImportProcessDetails(formattedData)
  }

  useEffect(() => {
    formatImportResultsData(feed)
    formatImportProcessDetails(feed)
  }, [])

  const label = useMemo(() => {
    return `${feed.num_processed} of ${feed.num_items} ${
      feed.num_items === 1 ? 'row' : 'rows'
    } processed`
  }, [feed])

  return (
    <Box flex={{ flexDirection: 'column' }} gap={6}>
      <div className='mb-4 mt-4'>
        <ProgressBar label={label} progress={100} fullWidth />
      </div>
      <ModalCard2Col
        loading={false}
        header='Import Results'
        data={importResults}
      />
      <ModalCard2Col
        loading={false}
        header='Import Process Details'
        data={importProcessDetails}
      />
    </Box>
  )
}

export default UpdateFinished
